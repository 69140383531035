import React, {Component}   from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import { connect }                      from 'react-redux';
import {
    commonStyles,
    colors
}                                       from 'styles/design.js';

import {
    LoadingIndicator
}                                       from 'components';
class PrintButton extends Component {

    static propTypes = {
        label: PropTypes.string,
        style: PropTypes.any,
        download: PropTypes.func.isRequired
    };

    render() {
        const { t, submitting, loading } = this.props;
        return (
            <a onClick={ this.props.download }
               style = { [ commonStyles.buttonLink, {backgroundColor: colors.lightBlue}, this.props.style ? this.props.style : {}] }>
                <LoadingIndicator visible = { submitting || loading } />
                { this.props.label ? this.props.label : t('print_agreement') }
            </a>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.userProfile
    };
}
const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(PrintButton)));