import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';

import { isFrameAgreementEditable } from 'helpers/agreement';

import {
    loadFrameAgreement,
    shouldReloadFrameAgreement,
    saveFrameAgreement
} from 'redux/modules/frameagreement.js';
import {
    loadCountryDepartments,
    shouldLoadCountryDepartments,
} from 'redux/modules/department.js';
import {
    loadCountry,
    isCountryLoaded
} from 'redux/modules/country.js';

import {
    loadContractInfo,
    isContractInfoLoaded
} from 'redux/modules/contractInfo';

import {
    StandardPage,
    BreadcrumbBar,
    Header,
    BackLink
} from 'components';
import {deepCopy} from 'helpers/objectHelper.js';

import {calculatePercentage} from 'helpers/calculation.js';

import FrameAgreementDiscipline from 'components/FrameAgreement/FrameAgreementDiscipline';
import FrameAgreementDisciplineForm from 'components/FrameAgreement/FrameAgreementDisciplineForm';
import FrameAgreementAvailableDisciplineSelection from 'components/FrameAgreement/FrameAgreementAvailableDisciplineSelection';

import _ from 'lodash';

import './FrameAgreement.css';

class FrameAgreementView extends Component {
    state = {};

    componentWillMount() {
        const {match: {params}} = this.props;
        if (!isContractInfoLoaded(this.props, params.contractInfoid)) {
            this.props.loadContractInfo(params.contractInfoId, this.props.profile.accessToken).then((res) => {
                this.loadCountryAndDepartments()
            });
        } else {
            this.loadCountryAndDepartments();
        }
        if (shouldReloadFrameAgreement(this.props, params.frameAgreementId)) {
            this.props.loadFrameAgreement(params.frameAgreementId, this.props.profile.accessToken);
        }

    }

    loadCountryAndDepartments() {
        const contractInfo = this.props.contractInfo.contractInfo || null;
        if (!this.props.countries.loading && !isCountryLoaded(this.props, contractInfo.salesArea)) {
            this.props.loadCountry(contractInfo.salesArea, this.props.profile.accessToken);
        }
        if (!this.props.department.loading && shouldLoadCountryDepartments(this.props, contractInfo.salesArea)) {
            this.props.loadCountryDepartments(contractInfo.salesArea, this.props.profile.accessToken);
        }
    }

    submitDisciplines = (idx, values) => {
        const frameAgreement = this.props.frameAgreement.agreement;
        frameAgreement.disciplines[idx] = values;
        this.save(frameAgreement);

    };
    save = (frameAgreement) => {
        return this.props.saveFrameAgreement(frameAgreement, this.props.profile.accessToken).then((res) => {
            if (res.result) {
                this.props.loadFrameAgreement(this.props.match.params.frameAgreementId, this.props.profile.accessToken).then((res) => {
                    this.setState({editing: null});
                })
            }
        })
    }

    toggleEditDiscipline = (category) => {
        this.setState((state) => ({...state, editing: category}))
    };
    addDiscipline = (disciplineCategory) => {
        const discipline = _.find(_.get(this.props, 'countries.selectedCountry.frameAgreementConfig.disciplines', []), {disciplineCategory});
        if (discipline) {
            const frameAgreement = deepCopy(_.get(this.props, 'frameAgreement.agreement', {}));
            if (_.find(frameAgreement.disciplines, {disciplineCategory})) {
                alert('discipline exists');
            } else {
                if(!frameAgreement.disciplines) {
                    frameAgreement.disciplines = [];
                }
                frameAgreement.disciplines.push(discipline);
                this.save(frameAgreement);
            }

        } else {
            alert('Selected discipline doesn\'t exist');
        }
    };

    addAllDisciplines = () => {
        const frameAgreement = _.get(this.props, 'frameAgreement.agreement', {});
        const countryDisciplines = _.get(this.props, 'countries.selectedCountry.frameAgreementConfig.disciplines', []);
        const selectedDisciplines = frameAgreement.disciplines || [];
        const availableDisciplines = _.filter(countryDisciplines, (discipline) => !_.find(selectedDisciplines, {disciplineCategory: discipline.disciplineCategory}));
        if(!frameAgreement.disciplines) {
            frameAgreement.disciplines = [];
        }
        frameAgreement.disciplines = frameAgreement.disciplines.concat(availableDisciplines);
        this.save(frameAgreement);
    };

    deleteDiscipline = (deleteIndex) => {
        const frameAgreement = this.props.frameAgreement.agreement;
        frameAgreement.disciplines = _.filter(frameAgreement.disciplines, (dis, idx) => idx !== deleteIndex);
        this.save(frameAgreement);
    };

    render() {
        const { t, language } = this.props,
            agreement = this.props.frameAgreement.agreement || {},
            country = this.props.countries.selectedCountry || {},
            contractInfo = this.props.contractInfo.contractInfo || {},
            disciplines = agreement.disciplines || [];
        const { params } = this.props.match;

        const isEditable = isFrameAgreementEditable(agreement);
        const basePath = `/client/${params.clientId}/contractInfo/${params.contractInfoId}/frameagreement/${agreement.id}`;
        return (
            <StandardPage withBreadcrumb
                          loaderVisible={this.props.frameAgreement.loading || this.props.frameAgreement.saving}>
                <BreadcrumbBar page="frameagreement" agreement={agreement} contractInfo={ contractInfo } />

                {
                    agreement &&
                    <BackLink
                        title={t('Back to agreement')}
                        to={`${basePath}/summary`}
                    />
                }
                <Header title={t('disciplines')}/>
                <div>
                    <div className="ContentWrapper">
                        {
                            country.frameAgreementConfig &&
                            <div>
                                <h3>{t('Overtime addendum')}:</h3>
                                {
                                    t('frameagreement_overtime_addendum',
                                        calculatePercentage(country.frameAgreementConfig.overtime50multiplier),
                                        calculatePercentage(country.frameAgreementConfig.overtime100multiplier))}
                            </div>
                        }
                    </div>
                    {
                        disciplines.length === 0 &&
                        <div id="FrameAgreementDisciplinesView_empty"><h3>{t('Disciplines not set')}</h3></div>
                    }
                    {
                        disciplines.length > 0 &&
                        _.map(disciplines, (discipline, idx) =>
                            this.state.editing && this.state.editing === discipline.disciplineCategory ?
                                <FrameAgreementDisciplineForm
                                    t={t}
                                    index={idx}
                                    discipline={discipline}
                                    country={country}
                                    toggleEdit={this.toggleEditDiscipline}
                                    onSubmit={(values) => this.submitDisciplines(idx, values)}
                                    deleteDiscipline={() => this.deleteDiscipline(idx)}
                                />
                                :
                                <FrameAgreementDiscipline
                                    toggleEdit={this.toggleEditDiscipline}
                                    index={idx}
                                    key={'discipline_' + idx}
                                    country={country}
                                    discipline={discipline}
                                    t={t}
                                    isEditable={isEditable}
                                    language={language}
                                />
                        )
                    }
                    {
                        isEditable && !this.state.editing &&
                        <FrameAgreementAvailableDisciplineSelection
                            addAllDisciplines={this.addAllDisciplines}
                            country={country}
                            disciplines={disciplines}
                            t={t}
                            addDiscipline={this.addDiscipline}
                        />
                    }
                </div>
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        department: state.department,
        countries: state.countries,
        frameAgreement: state.frameagreement,
        profile: state.userProfile,
        contractInfo: state.contractInfo,
        language: state.translations.language,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadContractInfo: (id, accessToken) => dispatch(loadContractInfo(id, accessToken)),
        loadCountry: (salesArea, accessToken) => dispatch(loadCountry(salesArea, accessToken)),
        loadCountryDepartments: (salesArea, accessToken) => dispatch(loadCountryDepartments(salesArea, accessToken)),
        loadFrameAgreement: (agreementId, accessToken) => dispatch(loadFrameAgreement(agreementId, accessToken)),
        saveFrameAgreement: (agreement, accessToken) => dispatch(saveFrameAgreement(agreement, accessToken))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(FrameAgreementView));

