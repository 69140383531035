import React from 'react';

import {
    Button,
    SFInputField,
    ConfirmableButton,
    SFTextareaField
} from 'components';

import {
    layout,
    measures,
    colors
} from 'styles/design';

const ServiceCarForm = ({t, serviceCar, removeField, addField}) => {
    return (
        <div style={{padding: measures.standard}}>
            {
                serviceCar.map((option, idx) => {
                    //const nameExists = _.find(this.props.fields.additionalFees, (fee, feeIdx) => fee.name.value === additionalFeeOption.name.value && idx !== feeIdx);
                    return (
                        <div key={'servicecar_' + idx}>
                            <div>
                                <div style={{...layout.inlineBlock, width: '30%'}}>
                                    <SFInputField
                                        fieldName={t('name')}
                                        formProperty={option.name}/>
                                </div>
                                <div style={{
                                    ...layout.inlineBlock,
                                    width: '30%',
                                    marginLeft: measures.standard
                                }}>
                                    <SFInputField
                                        fieldName={t('value')}
                                        formProperty={option.value}/>
                                </div>
                                <div style={{
                                    ...layout.inlineBlock,
                                    width: '20%',
                                    marginLeft: measures.standard
                                }}>
                                    <SFInputField
                                        fieldName={t('unit')}
                                        formProperty={option.valueType}/>
                                </div>
                                <div style={{
                                    ...layout.inlineBlock,
                                    width: '5%',
                                    marginLeft: measures.standard
                                }}>
                                    <ConfirmableButton
                                        style={{backgroundColor: colors.brightRed}}
                                        buttonIcon={'fa fa-trash'}
                                        action={() => removeField(idx)}
                                        confirmText="Removing / updating option doesn't automatically remove / update it on existing frame agreements. Are you sure?"
                                    />
                                </div>
                            </div>
                            <SFTextareaField fieldName={t('description')}
                                             formProperty={option.description}/>
                        </div>
                    );
                })
            }
            <Button onClick={addField}>
                <i className="fa fa-plus"/> {t('add_pricing_row')}
            </Button>
        </div>
    );
}

export default ServiceCarForm;