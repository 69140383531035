import React, {Component}    from 'react';
import translations from 'decorators/translations.js';
import {connect}          from 'react-redux';
import configuredRadium  from 'configuredRadium.js';

import {
    StandardPage,
    BuildingInformation,
    EditBuildingButton,
    BreadcrumbBar,
    CloseButton,
    LoadingIndicator
}                       from 'components';

import {
    commonStyles,
    layout,
    measures
} from 'styles/design.js';

import { loadCountry } from 'redux/modules/country.js';
import {
    loadClient,
    isClientLoaded
}   from 'redux/modules/client.js';
import { loadBuildings } from 'redux/modules/building.js';
import { loadAgreements } from 'redux/modules/agreement.js';

import { isBuildingEditable } from 'helpers/agreement.js';
import { loadBillingIndexes }           from 'redux/modules/billingIndex';

import {withRouter} from 'react-router-dom';

import _ from 'lodash';

class BuildingDetailsView extends Component {

    componentWillMount() {
        const dispatch = this.props.dispatch,
            accessToken = this.props.profile.accessToken,
            params = this.props.match.params,
            promises = [];
        if(!this.props.billingIndex.loaded) {
            promises.push(dispatch(loadBillingIndexes(accessToken)));
        }

        if(!this.props.agreement.loaded || this.props.agreement.agreements.length === 0) {
            promises.push(dispatch(loadAgreements(params.clientId, accessToken)));
        }
        if(!isClientLoaded({client: this.props.clientState}, params.clientId)) {
            const { contractInfoId } = params;
            promises.push(dispatch(loadClient(params.clientId, accessToken)).then((result) => {
                const contractInfo = result && result.result && result.result.contractInfos && _.find(result.result.contractInfos, ['id', +contractInfoId]);
                if (contractInfo && contractInfo.salesArea) {
                    dispatch(loadCountry(contractInfo.salesArea, accessToken));
                }
            }));
        }
        if(!this.props.building.loaded || this.props.building.buildings.length === 0 || this.props.building.reload) {
            promises.push(dispatch(loadBuildings(params.clientId, accessToken)));
        }
        return Promise.all(promises).then(() => {
            this.setState({dataLoaded: true});
        })
    }

    render() {
        const {
            t,
            match: {
                params: {
                    clientId,
                    buildingId,
                    contractInfoId
                }
            }
        } = this.props;
        const agreementsContractInfo = _.find(this.props.agreement.byContractInfo, { id: +contractInfoId });
        const agreements = agreementsContractInfo && agreementsContractInfo.agreements;
        const buildingsContractInfo = _.find(this.props.building.byContractInfo, { id: +contractInfoId });
        const buildings = buildingsContractInfo && buildingsContractInfo.buildings;
        const selectedBuilding = _.find(buildings, (building) => { return building.id.toString() === this.props.match.params.buildingId;}) || {};
        const isEditable = isBuildingEditable(agreements, selectedBuilding);
        const buildingAgreement = _.find(agreements, {id: selectedBuilding.agreementId}) || {};
        const client = this.props.clientState.client;
        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar building={ selectedBuilding }/>
                <header>
                    <h1 style={ commonStyles.pageTitle }>{ this.props.t('building_details') }</h1>
                </header>
                <div style={ layout.floatRight }>
                    {
                        (() => {
                            if(!this.props.building.loading && !this.props.clientState.loading) {
                                if (!isEditable) {
                                    return t('error_edit_disabled_agreement_pending_approval');
                                } else if(client && selectedBuilding) {
                                    return (
                                        <EditBuildingButton
                                            clientId={client.id}
                                            contractInfoId={selectedBuilding.contractInfoId}
                                            buildingId={selectedBuilding.id}
                                        />
                                    );
                                }
                            }
                        })()
                    }

                </div>
                {
                    (() => {
                        if(!this.state.dataLoaded) {
                            return <LoadingIndicator visible={true} />;
                        }
                        return (
                            <BuildingInformation
                                contractInfo={ buildingsContractInfo }
                                client={ client }
                                building={ selectedBuilding }
                                billingIndexes={this.props.billingIndex.all}
                                agreement={buildingAgreement}
                            />
                        );
                    })()
                }
                <div style={{ ...layout.floatRight, ...layout.inlineBlock }}>
                    <span style={{ marginRight : measures.standard }}>
                        <CloseButton
                            id="BuildingDetailsView_close"
                            path={ '/client/' + clientId + '/contractInfo/' + contractInfoId + '/building/' + buildingId + '/'}
                        />
                    </span>
                </div>
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        agreement: state.agreement,
        building: state.building,
        clientState: state.client,
        profile: state.userProfile,
        billingIndex: state.billingIndex,
    };
}

function mapDispatchToProps() {
    return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(withRouter(connector(configuredRadium(BuildingDetailsView))));
