import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import { connect }                  from 'react-redux';
import {
    measures
}           from 'styles/design.js';
import {
    loadAgreement,
    loadConfig
}                               from 'redux/modules/cccProcess';

import {
    ProcessContainer
}                   from 'components';

class LinkCccProcessView extends Component {

    componentWillMount() {
        const promises = [];
        console.log('this.props.location', this.props);
        promises.push(this.props.dispatch(loadAgreement(this.props.cookies.uuidToken)));
        promises.push(this.props.dispatch(loadConfig(this.props.cookies.uuidToken)));
        return Promise.all(promises);
    }

    render() {
        const { process } = this.props;
        if(process.loading) {
            return (<div>Loading...</div>);
        } else if (process.error) {
            console.log('process error', process.error);
            return (<div>{process.error.status && process.error.status === 401 ? 'Token expired' : 'Something went wrong'}</div>);
        }

        return (
            <div style={{margin: measures.double}}>
                <ProcessContainer
                    agreement={process.data.agreement}
                    uuidToken={this.props.cookies.uuidToken}
                    currentStep={process.data.step}
                    stepsConfig={process.config}
                    stepsWithData={this.props.process.data.steps}
                    type="link"
                />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        userProfile: state.userProfile,
        process: state.cccProcess,
        cookies: state.cookies
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(LinkCccProcessView));
