import React, {Component}           from 'react';
import translations                 from 'decorators/translations.js';
import PropTypes                    from 'prop-types';
import configuredRadium             from 'configuredRadium.js';
import _                            from 'lodash';
import { reduxForm, initialize }    from 'redux-form';
import { withRouter }         from 'react-router-dom';

import {
    CostNumber,
    DisciplineIcon,
    EditCostsButton,
    SFTextareaField,
    SFInputField,
    SaveButton,
    CancelButton,
    SFRadioField,
    Button,
} from 'components';

import {
    layout,
    colors,
    measures,
} from 'styles/design.js';

import {
    updateDisciplineWithCustomIncome,
    updateSubDisciplineWithCustomIncome
} from 'redux/modules/discipline.js';

import {
    loadWithIncludes
} from 'redux/modules/agreement.js';

import './CostCalculation.css';

const containerStyle = [
    layout.media,
    {
        paddingTop: measures.standard
    }
];

const iconContainerStyle = [
    layout.floatLeft,
    {
        width: '5em',
        minHeight: '1em'
    }
];

const calculationContainerStyle = [
    layout.media,
    {
        marginLeft: '1em'
    }
];

const reduxFormObject = {
    form: 'CostMarginAdjustmentForm',
    fields: [
        'adjustedIncome',
        'adjustedIncomeComment',
    ]
};

// Guess if user has edited discipline level vs subdiscipline level
// We guess the subdiscipline by checking if:
// - All the comments are not same
// - There are integer numbers in subdiscipline level totalIncome
// - Some subdisciplines have custom income set and some do not
const isDisciplineCategoryLevelSelected = disciplines => {
    if (!disciplines || !disciplines.length || disciplines.some(discipline => !discipline.fullCostCalculation)) {
        return true;
    }
    if (disciplines.some(discipline => discipline.fullCostCalculation.customIncomeComment !== disciplines[0].fullCostCalculation.customIncomeComment)) {
        return false;
    };
    if (disciplines.some(discipline => _.isNumber(discipline.fullCostCalculation.totalIncome) && _.isInteger(discipline.fullCostCalculation.totalIncome)) && disciplines.some(discipline => discipline.fullCostCalculation.customIncomeDifference !== 0)) {
        return false;
    }
    if (disciplines.some(discipline => discipline.fullCostCalculation.customIncomeDifference) && disciplines.some(discipline => !discipline.fullCostCalculation.customIncomeDifference)) {
        return false;
    }
    return true;
};

const filterDisciplines = (disciplines, disciplineType) => _.filter(disciplines, { disciplineCategory: disciplineType });

class CostCalculation extends Component {
    state = {
        customIncomeLevel: 'disciplineCategory',
        adjustedIncomeBySubDiscipline: {},
        editableSubDiscipline: 0,
    }

    static propTypes = {
        calculation: PropTypes.object.isRequired,
        disciplineType: PropTypes.string,
        title: PropTypes.string.isRequired,
        clientId: PropTypes.number,
        agreementId: PropTypes.number,
        disciplineAnchorName: PropTypes.string
    };

    componentDidMount() {
        if(this.props.editMode) {
            this.props.initializeForm({
                adjustedIncome: this.props.calculation.totalIncome,
                adjustedIncomeComment: this.props.calculation.customIncomeComment
            });
        }
        if (this.props.disciplines && this.props.disciplines.length) {
            this.setSubDisciplineModel();
            const filteredDisciplines = filterDisciplines(this.props.disciplines, this.props.disciplineType);
            if (!isDisciplineCategoryLevelSelected(filteredDisciplines)) {
                this.setState({
                    customIncomeLevel: 'discipline'
                });
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.disciplines !== prevProps.disciplines) {
            this.setSubDisciplineModel();
            const filteredDisciplines = filterDisciplines(this.props.disciplines, this.props.disciplineType);
            if (!isDisciplineCategoryLevelSelected(filteredDisciplines)) {
                this.setState({
                    customIncomeLevel: 'discipline'
                });
            }
        }
    }

    setSubDisciplineModel = disciplines => {
        const subDisciplineModel = this.props.disciplines.reduce((acc, current) => {
            if (current && current.fullCostCalculation) {
                acc[current.id] = {
                    adjustedIncome: current.fullCostCalculation.totalIncome,
                    adjustedIncomeComment: current.fullCostCalculation.customIncomeComment || '',
                };
            }
            return acc;
        }, {});
        this.setState({
            adjustedIncomeBySubDiscipline: subDisciplineModel
        });
    };

    getLinkUrl() {
        const { clientId, contractInfoId, agreementId } = this.props;
        return `/client/${clientId}/contractInfo/${contractInfoId}/agreement/${agreementId}/costs`;
    }

    handleSubmit = (event) => {
        event.preventDefault();

        const payload = {
            agreementId: parseInt(this.props.agreementId, 10),
            disciplineCategory: this.props.disciplineType,
            customIncome: parseFloat(this.props.fields.adjustedIncome.value),
            customIncomeComment: this.props.fields.adjustedIncomeComment.value
        };
        if(payload.customIncomeComment === '') {
            this.setState({invalidComment: true});
        } else {
            this.props.updateDisciplineWithCustomIncome(payload, this.props.profile.accessToken).then((result) => {
                if(result.result) {
                    this.props.loadWithIncludes(this.props.agreementId, this.props.profile.accessToken).then(() => {
                        this.props.history.push(this.getLinkUrl());
                    });
                }
            });
        }
    }

    handleSubDisciplineCustomIncomeSubmit = (event, disciplineType, disciplineId) => {
        const {
            adjustedIncomeBySubDiscipline
        } = this.state;
        event.preventDefault();
        const model = adjustedIncomeBySubDiscipline[disciplineId];
        const adjustedIncome = model.adjustedIncome;
        const adjustedIncomeComment = model.adjustedIncomeComment;

        const payload = {
            disciplineId: parseInt(disciplineId, 10),
            customIncome: parseFloat(adjustedIncome),
            customIncomeComment: adjustedIncomeComment
        };
        if(payload.customIncomeComment === '') {
            this.setState({invalidComment: true});
        } else {
            this.props.updateSubDisciplineWithCustomIncome(payload, this.props.profile.accessToken).then((result) => {
                if(result.result) {
                    this.props.loadWithIncludes(this.props.agreementId, this.props.profile.accessToken).then(() => {
                        this.props.history.push(this.getLinkUrl());
                    });
                }
            });
        }
    }

    handleCancel = (event) => {
        this.props.history.push(this.getLinkUrl());
        return event.preventDefault();
    }

    isValidType(type) {
        return type !== 'oldIncome' &&
            type !== 'customIncome' &&
            type !== 'customIncomeComment' &&
            type !== 'customIncomeDifference' &&
            type !== 'customProjectMarginComment' &&
            type !== 'customProjectMarginDifference';
    }

    toggleMode = e => {
        this.setState({
            customIncomeLevel: e.target.value
        });
    };

    handleEditSubDiscipline = (e, index) => {
        e.preventDefault();
        this.setState({
            editableSubDiscipline: index,
            invalidComment: false
        });
    };

    handleSubDisciplineFormChange = (property, disciplineId, value) => {
        const {
            adjustedIncomeBySubDiscipline
        } = this.state;
        this.setState({
            adjustedIncomeBySubDiscipline: {
                ...adjustedIncomeBySubDiscipline,
                [disciplineId]: {
                    ...adjustedIncomeBySubDiscipline[disciplineId],
                    [property]: value
                }
            }
        });
    };

    renderIncomeEditMode = () => {
        const {
            fields: {
                adjustedIncome,
                adjustedIncomeComment,
            },
            t,
            updating,
            disciplines,
            disciplineType,
        } = this.props;
        const {
            customIncomeLevel,
            adjustedIncomeBySubDiscipline,
            editableSubDiscipline,
        } = this.state;

        const filteredDisciplines = filterDisciplines(disciplines, disciplineType);

        return (
            <div>
                <div style={{ display: 'flex', flexFlow: 'row nowrap' }}>
                    <div style={{ marginRight: '1em' }}>
                        <SFRadioField
                            formProperty={{
                                value: customIncomeLevel,
                                onChange: this.toggleMode
                            }}
                            label={t('Discipline')}
                            value="disciplineCategory"
                            checked={customIncomeLevel === 'disciplineCategory'}
                            name="customIncomeLevel"
                        />
                    </div>
                    {filteredDisciplines && filteredDisciplines.length &&
                        <SFRadioField
                            formProperty={{
                                value: customIncomeLevel,
                                onChange: this.toggleMode
                            }}
                            label={t('Subdisciplines')}
                            value="discipline"
                            checked={customIncomeLevel === 'discipline'}
                            name="customIncomeLevel"
                        />
                    }
                </div>
                {customIncomeLevel === 'disciplineCategory' &&
                    <div>
                        <div style={{ overflow: 'hidden', padding: '0.5em 0' }}>
                            <SFTextareaField
                                mandatory
                                fieldName={ t('comment') }
                                formProperty={ adjustedIncomeComment }
                                rows={ 4 }
                            />
                            <div style={[this.state.invalidComment ? layout.block : layout.none, {color: 'red'}]}>
                                {t('invalid_comment')}
                            </div>
                        </div>
                        <div style = {{ height: '3em', marginTop: measures.standard }}>
                            <CostNumber style = {[ layout.floatRight, {width: '6em'}]}>{ this.props.calculation.oldIncome }</CostNumber>
                            <div style = { [layout.inlineBlock, layout.floatLeft, { fontWeight: 'bold'}] } >{ t('original_income') }</div>
                        </div>
                        <div style = {{ height: '3em', marginTop: measures.standard }}>
                            <CostNumber style = {[ layout.floatRight, {width: '6em'}]}>{ this.props.calculation.totalIncome }</CostNumber>
                            <div style = { [layout.inlineBlock, layout.floatLeft, { fontWeight: 'bold'}] } >{ t('totalIncome') }</div>
                        </div>
                        <div style = {{ height: '3em', marginTop: measures.standard }}>
                            <div style={[ layout.floatRight, { width: '6em', marginTop: '-1em' }]}>
                                <SFInputField
                                    mandatory
                                    oneline
                                    labelStyle={{ marginBottom: 0, width: '6em', display: 'block' }}
                                    formProperty={ adjustedIncome }
                                />
                            </div>
                            <div style = { [layout.inlineBlock, layout.floatLeft, { fontWeight: 'bold'}] } >{ t('adjusted_income') }</div>
                        </div>
                        <div style={{ overflow: 'hidden', marginTop: '0.5em' }}>
                            <div style = {{ display: 'inline-block', float: 'right' }}><SaveButton onClick={ this.handleSubmit } disabled={ updating } submitting={ updating }/></div>
                            <div style = {{ display: 'inline-block', float: 'right' }}><CancelButton cancelAction = { this.handleCancel } /></div>
                        </div>
                    </div>
                }
                {customIncomeLevel === 'discipline' &&
                    <div style={{ margin: '1em 0' }}>
                        {filteredDisciplines.map((discipline, index) => {
                            if (discipline && discipline.fullCostCalculation) {
                                return <div key={discipline.disciplineType} style={{ padding: '1em 0', borderTop: index !== 0 ? `1px solid ${colors.lightGray}` : '', overflow: 'hidden' }}>
                                    <div className={ `cost-calculation-subdiscipline${editableSubDiscipline === index ? ' is-open' : ''}` }>
                                        {t(`${discipline.disciplineType}`)} {editableSubDiscipline !== index && <div style={{ float: 'right' }}>
                                            <Button size="small" variant="secondary" onClick={e => this.handleEditSubDiscipline(e, index)}>{t('edit')}</Button>
                                        </div>}
                                    </div>
                                    {editableSubDiscipline !== index ?
                                        <div style={{ marginTop: '1.5em' }}>
                                            <div style = {{ overflow: 'hidden', marginTop: measures.standard }}>
                                                <CostNumber style = {[ layout.floatRight, {width: '6em'}]}>{ discipline.fullCostCalculation.oldIncome }</CostNumber>
                                                <div style = { layout.floatLeft } >{ t('original_income') }</div>
                                            </div>
                                            <div style = {{ overflow: 'hidden', marginTop: measures.standard }}>
                                                <CostNumber style = {[ layout.floatRight, {width: '6em'}]}>{ discipline.fullCostCalculation.totalIncome }</CostNumber>
                                                <div style = { layout.floatLeft } >{ t('totalIncome') }</div>
                                            </div>
                                        </div>
                                    :
                                    <div>
                                        <div style={{ overflow: 'hidden', padding: '0.5em 0' }}>
                                        <SFTextareaField
                                            mandatory
                                            fieldName={ t('comment') }
                                            onChange={event => this.handleSubDisciplineFormChange('adjustedIncomeComment', discipline.id, event.target.value)}
                                            value={adjustedIncomeBySubDiscipline[discipline.id].adjustedIncomeComment}
                                            rows={ 4 }
                                            defaultInput
                                        />
                                        <div style={[this.state.invalidComment ? layout.block : layout.none, {color: 'red'}]}>
                                            {t('invalid_comment')}
                                        </div>
                                        </div>
                                        <div style = {{ height: '3em', marginTop: measures.standard }}>
                                            <CostNumber style = {[ layout.floatRight, {width: '6em'}]}>{ discipline.fullCostCalculation.oldIncome }</CostNumber>
                                            <div style = {{ ...layout.inlineBlock, ...layout.floatLeft }} >{ t('original_income') }</div>
                                        </div>
                                        <div style = {{ height: '3em', marginTop: measures.standard }}>
                                            <CostNumber style = {[ layout.floatRight, {width: '6em'}]}>{ discipline.fullCostCalculation.totalIncome }</CostNumber>
                                            <div style = {{ ...layout.inlineBlock, ...layout.floatLeft }} >{ t('totalIncome') }</div>
                                        </div>
                                        <div style = {{ height: '3em', marginTop: measures.standard }}>
                                            <div style={[ layout.floatRight, { width: '6em', marginTop: '-1em' }]}>
                                                <SFInputField
                                                    mandatory
                                                    oneline
                                                    labelStyle={{ marginBottom: 0, width: '6em', display: 'block' }}
                                                    defaultInput
                                                    onChange={event => this.handleSubDisciplineFormChange('adjustedIncome', discipline.id, event.target.value)}
                                                    value={adjustedIncomeBySubDiscipline[discipline.id].adjustedIncome}
                                                />
                                            </div>
                                            <div style = {{ ...layout.inlineBlock, ...layout.floatLeft }} >{ t('adjusted_income') }</div>
                                        </div>
                                        <div style={{ overflow: 'hidden', marginTop: '0.5em', marginBottom: '0.5em' }}>
                                            <div style = {{ display: 'inline-block', float: 'right' }}><SaveButton onClick={ event => this.handleSubDisciplineCustomIncomeSubmit(event, discipline.disciplineType, discipline.id) } disabled={ updating } submitting={ updating }/></div>
                                            <div style = {{ display: 'inline-block', float: 'right' }}><CancelButton cancelAction = { this.handleCancel } /></div>
                                        </div>
                                    </div>
                                    }
                                </div>;
                            }
                            return null;
                        })}
                    </div>
                }
            </div>
        );
    }

    renderIncome = () => {
        const {
            calculation,
            t,
            editable,
            clientId,
            contractInfoId,
            agreementId,
            disciplineAnchorName,
            isEditable,
            disciplines,
        } = this.props;

        const filteredDisciplines = _.filter(disciplines, { disciplineCategory: this.props.disciplineType });

        return (
            <div>
                { (() => {
                    const hasCustomMargin = calculation.customIncomeDifference && calculation.customIncomeDifference !== 0;
                    if(editable) {
                        return (
                            <div style={[{ overflow: 'hidden', minHeight: '3em' }, hasCustomMargin ? { padding: '0 0 0 1em', borderLeft: '4px solid ' + colors.orange} : {} ]}>
                                <div style={[ hasCustomMargin ? { overflow: 'hidden', marginTop: measures.standard, height: '2em' } : { display: 'none' }]}>
                                    <CostNumber style={[ layout.floatRight, calculation.customIncomeDifference < 0 ? { color: colors.red } : {} ]}>{ (calculation.totalIncome + (0 - calculation.customIncomeDifference)) }</CostNumber>
                                    <b style={{ lineHeight: '2em' }}>{ t('original_income') }</b>
                                </div>
                                <div style={[ hasCustomMargin ? { overflow: 'hidden', marginTop: measures.standard, height: '2em' } : { display: 'none' }]}>
                                    <CostNumber style={[ layout.floatRight, calculation.customIncomeDifference < 0 ? { color: colors.red } : {} ]}>{ calculation.customIncomeDifference }</CostNumber>
                                    <b style={{ lineHeight: '2em' }}>{ t('custom_income_difference') }</b>
                                </div>
                                <div>
                                {filteredDisciplines.map(discipline => {
                                        if (discipline && discipline.fullCostCalculation && discipline.fullCostCalculation.oldIncome !== discipline.fullCostCalculation.totalIncome) {
                                            return <div key={discipline.disciplineType} style={{ overflow: 'hidden', marginTop: measures.standard, marginBottom: measures.standard, borderTop: `1px solid ${colors.lightGray}`, paddingTop: measures.standard }}>
                                                <div style={{ fontWeight: 'bold', marginBottom: measures.standard }}>{t(`${discipline.disciplineType}`)}</div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                                    <div>{ t('original_income') }</div>
                                                    <CostNumber>{ discipline.fullCostCalculation.oldIncome }</CostNumber>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                                    <div>{ t('totalIncome') }</div>
                                                    <CostNumber>{ discipline.fullCostCalculation.totalIncome }</CostNumber>
                                                </div>
                                                <div>
                                                    <div style={{ fontStyle: 'italic' }}>{discipline.fullCostCalculation.customIncomeComment}</div>
                                                </div>
                                            </div>;
                                        }
                                        return null;
                                    }
                                )}
                                </div>
                                <div style={{ borderTop: `1px solid ${colors.lightGray}`, paddingTop: measures.standard, display: 'flex', justifyContent: 'space-between' }}>
                                    <div style = {{ fontWeight: 'bold', lineHeight: '2em'}} >{ t('totalIncome') }</div>
                                    <CostNumber>{ calculation.totalIncome }</CostNumber>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                                    <div style={ isEditable ? layout.block : layout.none }>
                                        <EditCostsButton
                                            agreementId={ agreementId }
                                            clientId={ clientId }
                                            contractInfoId={ contractInfoId }
                                            disciplineAnchorName= { disciplineAnchorName }
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    }
                    return (
                        <div style={[{ overflow: 'hidden', minHeight: '3em' }, hasCustomMargin ? { padding: '0 0 0 1em', borderLeft: '4px solid ' + colors.orange} : {} ]}>
                            <div style = {{ height: '2em', marginTop: measures.standard }}>
                                <CostNumber style = {[ layout.floatRight]}>{ calculation.totalIncome }</CostNumber>
                                <div style = { [layout.inlineBlock, layout.floatLeft, { fontWeight: 'bold', lineHeight: '2em'}] } >{ t('totalIncome') }</div>
                            </div>
                        </div>
                    );
                })()}
            </div>
        );
    }
    renderKitpackRows = () => {
        const {
            kitpackCosts, t, calculation
        } = this.props;
        if(kitpackCosts && kitpackCosts.kitpackCosts > 0) {
            return (
                <div>
                    <div style={{height: '2em', marginTop: measures.double}}>
                        <CostNumber style={[layout.floatRight, { fontWeight: 'normal'}]}>{ kitpackCosts.kitpackCosts}</CostNumber>
                        <div style={ [layout.inlineBlock, layout.floatLeft] }>{ t('kitpack_costs') }</div>
                    </div>
                    <div style={[{marginTop: measures.standard, height: '2em'}]}>
                        <CostNumber style={[layout.floatRight, { fontWeight: 'normal'}]}>{kitpackCosts.kitpackIncome}</CostNumber>
                        <div style = { [layout.inlineBlock, layout.floatLeft] } >{ t('kitpack_income') }</div>
                    </div>
                    <div style={[{overflow: 'hidden', marginTop: measures.standard, height: '2em'}]}>
                        <CostNumber style={[layout.floatRight]}>{kitpackCosts.kitpackIncome + calculation.totalIncome}</CostNumber>
                        <div style = { [layout.inlineBlock, layout.floatLeft, { fontWeight: 'bold'}] } >{ t('total_including_kitpack') }</div>
                    </div>
                </div>
            );
        }
    }

    render() {
        const {
            calculation, title, t, disciplineType, editMode, clientNet, clientNetTotalPrice, clientNetCustomPrice
        } = this.props;
        const weightedTypes = ['actualProjectMargin', 'totalIncome', 'totalCosts'];
        return (
            <div style = { calculationContainerStyle }>
                <div style = { iconContainerStyle }>
                    <DisciplineIcon diciplineType = { disciplineType } />
                </div>
                <div style={ containerStyle }>
                    <h2 style = {{ marginBottom: measures.double }} >{ t(title) }</h2>
                    { (() => {
                        return _.without(_.keys(calculation), ...weightedTypes).map((type, index) => {
                            if(this.isValidType(type)) {
                                if(type !== 'workingHours') {
                                    return (
                                        <div key={ index } style={{height: '2em'}}>
                                            <div style={[layout.floatRight, {fontWeight: 'normal', width: '40%', lineHeight: '2em'}]}>
                                                {
                                                    (() => {
                                                        if(type === 'blueCollar' && calculation.workingHours) {
                                                            return (
                                                                <div style={[layout.floatLeft]}>
                                                                    { Math.round(parseFloat(calculation.workingHours) * 100) / 100 } {t('hours_unit')}
                                                                </div>
                                                            );
                                                        }
                                                    })()
                                                }
                                                <CostNumber style={[layout.floatRight]}>
                                                    { calculation[type] }
                                                </CostNumber>
                                            </div>
                                            <div style={ [layout.inlineBlock, layout.floatLeft, {lineHeight: '2em'}] }>
                                                { t(type) }
                                            </div>
                                            <div style={{clear: 'both'}} />
                                        </div>
                                    );
                                }
                            }
                        });
                    })()}
                    <div style = {{ height: '2em', marginTop: measures.standard }}>
                        <CostNumber style = {[ layout.floatRight]}>{ calculation.totalCosts }</CostNumber>
                        <div style = { [layout.inlineBlock, layout.floatLeft] } >{ t('totalCosts') }</div>
                    </div>
                    <div style = {{ height: '2em', marginTop: measures.standard }}>
                        <div style = {[ layout.floatRight, { fontWeight: 'bold'}]}>{ calculation.actualProjectMargin ? calculation.actualProjectMargin : 0 } % </div>
                        <div style = { [layout.inlineBlock, layout.floatLeft, { fontWeight: 'bold'}] } >{ t('actualProjectMargin') }</div>
                    </div>
                    { ((renderIncomeEditMode, renderIncome) => {
                        if(editMode) {
                            return renderIncomeEditMode();
                        }
                        return renderIncome();
                    })(this.renderIncomeEditMode, this.renderIncome)}
                    {
                        ((renderKitpackRows) => {
                            return renderKitpackRows();
                        })(this.renderKitpackRows)
                    }
                    { clientNet && clientNetCustomPrice != null &&
                        <div>
                            <div style={{height: '2em', marginTop: measures.double}}>
                                <CostNumber style={[layout.floatRight, { fontWeight: 'normal'}]}>{ clientNetTotalPrice }</CostNumber>
                                <div style={ [layout.inlineBlock, layout.floatLeft] }>{ t('SmartView income') }</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client,
        params: state.router.params,
        profile: state.userProfile,
        discipline: state.discipline,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        initializeForm: (data, fields) => {
            dispatch(initialize(data, fields));
        },
        updateDisciplineWithCustomIncome: (payload, accessToken) => {
            return dispatch(updateDisciplineWithCustomIncome(payload, accessToken));
        },
        updateSubDisciplineWithCustomIncome: (payload, accessToken) => {
            return dispatch(updateSubDisciplineWithCustomIncome(payload, accessToken));
        },
        loadWithIncludes: (agreementId, accessToken) => {
            return dispatch(loadWithIncludes(agreementId, accessToken));
        }
    };
}

export default withRouter(translations(reduxForm(reduxFormObject, mapStateToProps, mapDispatchToProps)(configuredRadium(CostCalculation))));
