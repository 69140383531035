import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {connect} from 'react-redux';
import translations from 'decorators/translations.js';
import {
    loadAgreement,
    deleteAgreement,
} from 'redux/modules/agreement.js';
import { loadKitPacksForAgreement } from 'redux/modules/kitpack.js';
import { loadBuildings } from 'redux/modules/building.js';
import {
    Header,
    Section,
    BackLink,
    StandardPage,
    AgreementDeleteActions,
    AgreementDeleteContents,
} from 'components';
import { loadContractInfo } from 'redux/modules/contractInfo';

class AgreementDeleteView extends Component {
    state = {
        deleting: false,
    };

    componentDidMount() {
        this.loadContents();
    }

    loadContents = () => {
        const {
            match,
            profile: {
                accessToken
            },
            loadKitPacksForAgreement,
            loadAgreement,
            loadBuildings,
            loadContractInfo,
        } = this.props;
        const agreementId = _.get(match, 'params.agreementId');
        const clientId = _.get(match, 'params.clientId');
        const contractInfoId = _.get(match, 'params.contractInfoId');

        loadKitPacksForAgreement(agreementId, accessToken);
        loadAgreement(agreementId, contractInfoId, accessToken)
            .then(() => {
                loadBuildings(clientId, accessToken);
            });
        loadContractInfo(contractInfoId, accessToken);
    };

    deleteAgreement = () => {
        const {
            agreementState,
            deleteAgreement,
            history,
            match,
            profile: {
                accessToken
            }
        } = this.props;
        const clientId = _.get(match, 'params.clientId');
        const agreementId = _.get(agreementState, 'agreement.id', {});
        if (agreementId) {
            this.setState({
                deleting: true
            });
            deleteAgreement(agreementId, accessToken).then(res => {
                if (res.result) {
                    return history.push(`/client/${clientId}`);
                }
            });
        }
    };

    render() {
        const {
            loading,
            agreementState,
            t,
            match,
            kitpack,
            contractInfo,
        } = this.props;
        const agreementId = _.get(match, 'params.agreementId');
        const clientId = _.get(match, 'params.clientId');
        const contractInfoId = _.get(match, 'params.contractInfoId');
        const agreement = _.get(agreementState, 'agreement', {});

        return <StandardPage
            withBreadcrumb
            loaderVisible={ loading }
        >
            {
                clientId && agreementId && <BackLink
                    title={t('Back to agreement')}
                    to={'/client/' + clientId + '/contractInfo/' + contractInfoId + '/agreement/' + agreementId + '/summary'}
                />
            }
            <Header title={t('Delete agreement')}/>
            {!loading && (_.isEmpty(agreement)
                ? <div>{t('Something went wrong. Agreement not available.')}</div>
                : <Section>
                    <AgreementDeleteContents
                        agreement={ agreement }
                        kitpack={ kitpack }
                        t={ t }
                        loading={ loading }
                        contractInfo={ contractInfo }
                    />
                    <AgreementDeleteActions
                        agreementId={ agreement.id }
                        onDelete={ this.deleteAgreement }
                        t={ t }
                    />
                </Section>
            )}
        </StandardPage>;
    }
}

AgreementDeleteView.defaultProps = {
    loading: false,
};

AgreementDeleteView.propTypes = {
    agreementState: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    kitpack: PropTypes.object.isRequired,
    profile: PropTypes.object.isRequired,
    contractInfo: PropTypes.object.isRequired,
    loadKitPacksForAgreement: PropTypes.func.isRequired,
    loadAgreement: PropTypes.func.isRequired,
    loadBuildings: PropTypes.func.isRequired,
    loadContractInfo: PropTypes.func.isRequired,
    deleteAgreement: PropTypes.func.isRequired,
    loading: PropTypes.bool,
};

const mapStateToProps = state => {
    const loading = state.agreement.loading
        || state.kitpack.loading
        || state.contractInfo.loading
        || state.agreement.deletingAgreement;
    return {
        contractInfo: state.contractInfo.contractInfo,
        agreementState: state.agreement,
        profile: state.userProfile,
        kitpack: state.kitpack,
        loading
    };
};

const mapDispatchToProps = dispatch => ({
    loadAgreement: (agreementId, contractualInfoId, accessToken) => dispatch(loadAgreement(agreementId, contractualInfoId, accessToken)),
    loadKitPacksForAgreement: (agreementId, accessToken) => dispatch(loadKitPacksForAgreement(agreementId, accessToken)),
    loadBuildings: (clientId, accessToken) => dispatch(loadBuildings(clientId, accessToken)),
    loadContractInfo: (id, token) => dispatch(loadContractInfo(id, token)),
    deleteAgreement: (agreementId, accessToken) => dispatch(deleteAgreement(agreementId, accessToken)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(AgreementDeleteView));
