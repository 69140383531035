import {createReducerFromMapping} from 'redux/utils/index.js';
const AGREEMENT_REPORT       = 'ServiFlex/Report/AGREEMENT_REPORT',
    AGREEMENT_REPORT_SUCCESS = 'ServiFlex/Report/AGREEMENT_REPORT_SUCCESS',
    AGREEMENT_REPORT_FAIL    = 'ServiFlex/Report/AGREEMENT_REPORT_FAIL';

const initialState = {
    loading: false,
    agreement: {},
    loaded: false,
    reload: true,
    saving: false,
};

export default createReducerFromMapping({
    [AGREEMENT_REPORT]: (state) => ({
            ...state,
            loading: true
    }),
    [AGREEMENT_REPORT_SUCCESS]: (state, action) => ({

            ...state,
            loading: false,
            loaded: true,
            reload: false,
            agreement: action.result
    }),
    [AGREEMENT_REPORT_FAIL]: (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: false,
            error: action.error
        };
    }
}, initialState);

export function agreementReport(data, accessToken) {
    return {
        types: [AGREEMENT_REPORT, AGREEMENT_REPORT_SUCCESS, AGREEMENT_REPORT_FAIL],
        promise: (client) => {
            const options = {data, accessToken};
            return client.post('/report/agreements', options);
        }
    };
}

