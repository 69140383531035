import React, {Component }   from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import { commonStyles }                 from 'styles/design.js';
import { connect }                      from 'react-redux';
import { Link } from 'react-router-dom';
import { Section } from 'components';

const containerStyle = {
    padding: '1em 2em 1em 2em',
    borderColor: '#e0e0e0',
    borderStyle: 'solid',
    borderWidth: '4px',
    width: '100%',
};

const titleStyle = {
    marginBottom: '1em'
};

const buttonsContainerStyle = {
    marginTop: '2em'
};

const mapStateToProps = (state) => ({
    user: state.userProfile.user,
    profile: state.userProfile
});

const mapActionsToProps = () => ({
});
class AgreementApprovalBar extends Component {

    static propTypes = {
        agreement: PropTypes.object.isRequired,
    };

    render() {
        const {
          agreement,
          user,
          t,
          clientId
        } = this.props;

        const canApprove = (agreement.agreementStatus === 'sent_for_approval' && (user.isApprover || user.isAdmin || user.email === agreement.approver));
        const canReject = (agreement.agreementStatus === 'sent_for_approval' && (user.isApprover || user.isAdmin || user.email === agreement.approver));

        const shouldDisplay = (visible) => {
            return (visible ? { display: 'block'} : { display: 'none'});
        };

        return (
            <Section style={ shouldDisplay( canApprove || canReject) }>
                <div style={containerStyle}>
                    <h2 style={titleStyle}>{ t('agreement_approval') }</h2>
                    <div style={buttonsContainerStyle}>
                        <Link
                            style = { commonStyles.button }
                            to={'/client/' + clientId + '/contractInfo/' + agreement.contractInfoId + '/agreement/' + agreement.id + '/approve'}
                        >
                            {t('review')}
                        </Link>
                        {/*<button onClick = { this.approve.bind(this) } style = { [approvalButtonStyle, shouldDisplay(canApprove)] }>
                            {t('approve')}
                            <LoadingIndicator visible = { this.state.approving } />
                        </button>
                        <button onClick = { this.reject.bind(this) } style = { [approvalButtonStyle, shouldDisplay(canReject)] }>
                            {t('reject')}
                            <LoadingIndicator visible = { this.state.rejecting } />
                        </button>*/}
                    </div>
                </div>
            </Section>
        );
    }
}

const connector = connect(mapStateToProps, mapActionsToProps);
export default translations(connector(configuredRadium(AgreementApprovalBar)));
