
import React                    from 'react';
import translations             from 'decorators/translations.js';
import configuredRadium         from 'configuredRadium.js';
import { localeDateString }     from 'helpers/stringTemplates';
import _                        from 'lodash';
import {
    colors
}                               from 'styles/design.js';
import {
    DocumentListItem
}                               from 'components';

const agreementTableStyle = {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'transparent'
};

const statusColor = (status) => {
    return {
        draft: colors.lightBlue,
        rejected: colors.basicRed,
        approved: colors.blue,
        sent_for_approval: colors.blue,
    }[status] || colors.blue;
};

const AddOnStatus = (props) => {
    const { t, agreement, verbose, current } = props;

    const isSigned   = agreement.agreementStatus === 'signed';
    const isApproved = agreement.agreementStatus === 'approved' && agreement.approvedBy;
    const isModified = agreement.created !== agreement.modified;

    const show = {
        created         : { latest: 3, visible: true },
        modified        : { latest: 2, visible: isModified },
        approved        : { latest: 1, visible: isApproved },
        signed          : { latest: 0, visible: isSigned },
    };

    if (!verbose) {
        _.map(
            _.tail(
                _.filter(
                    _.orderBy(
                        _.values(show),
                        'latest'),
                    (val) => { return val.visible; })),
            (val) => { val.visible = false; });
    }

    return (
        <div style={ [agreementTableStyle, current === agreement.id ? { borderColor: colors.gray } : { borderColor: 'transparent' }] }>
            <DocumentListItem
                icon="file-text-o"
                iconColor={ statusColor(agreement.agreementStatus) }
                iconLabel={ t(agreement.agreementStatus) }
                title={ t('addon') + ' ' + props.index }
            >
                <p style={ show.created.visible ? {} : {display:'none'} }>
                    <span>{ t('created') }:</span> <br/> { localeDateString( agreement.created, { showTimeZone:true }) }
                </p>
                <p style={ show.modified.visible ? {} : {display:'none'} }>
                    <span>{ t('modified') }:</span> <br/>{ localeDateString(agreement.modified, { showTimeZone: true }) }
                </p>
                <p style={ show.approved.visible ? {} : {display:'none'} }>
                    <span>{ t('approved') }:</span> <br/> { localeDateString( agreement.approvalTime, {showTimeZone: true}) } <span style={ [verbose ? {} : { display: 'none' }] }>{ t('by') + ' ' + agreement.approvedBy }</span>
                </p>
                <p style={ show.signed.visible ? {} : {display:'none'} }>
                    <span>{ t('signed') }:</span> <br/> { localeDateString( agreement.signedTime, {showTimeZone: true}) } <span style={ [verbose ? {} : { display: 'none' }] }>{ t('by') + ' ' + agreement.signedBy }</span>
                </p>
            </DocumentListItem>
        </div>
    );
};

export default translations(configuredRadium(AddOnStatus));
