import React, { Component } from 'react';
import translations         from 'decorators/translations.js';
import configuredRadium     from 'configuredRadium.js';
import { connect }          from 'react-redux';
import { Link }             from 'react-router-dom';

import { searchAll }        from 'redux/modules/client.js';
import { localeDateString }         from 'helpers/stringTemplates.js';

import {
    commonStyles,
    colors
} from 'styles/design.js';

import {
    StandardPage,
    LoadingIndicator
} from 'components';

const styles = {
    alphabetsContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between'
    },
    alphabets: {
        borderWidth: 0,
        borderStyle: 'solid',
        borderColor: colors.orange,
        padding: '3px',
        cursor: 'pointer',
        textTransform: 'uppercase'
    },
    listContainer: {
        display: 'flex'
    },
    listSelected: {
        fontSize: '1.5em',
        textTransform: 'uppercase',
        width: '3em',
        padding: '0.75em 0 0',
        fontFamily: 'Sanchez-Regular'
    },
    list: {
        flex: 1
    }
};

class ClientList extends Component {

    componentWillMount() {
        this.setState({selected: 'a'});
        this.props.dispatch(searchAll('a', this.props.profile.accessToken));
    }
    clickValue = (value) => {
        this.setState({selected: value});
        return this.props.dispatch(searchAll(value, this.props.profile.accessToken));
    };
    render() {
        const t = this.props.t;
        const alphabets = 'abcdefghijklmnopqrstuvwxyzø'.split('');
        return (
            <div>
                <StandardPage>
                    <header style={{ marginBottom: '1em' }}>
                        <h1 style={ commonStyles.pageTitle }>{ t('clients') }</h1>
                    </header>
                    <div style={ styles.alphabetsContainer }>
                    {
                        (() => {
                            return alphabets.map((value, idx) => {
                                return (<div key={ 'alphabet_' + idx } style={ [ styles.alphabets, {borderBottomWidth: this.state && this.state.selected === value ? '2px' : '0'} ] } onClick={() => this.clickValue(value)}>{value}</div>);
                            });
                        })()
                    }
                    </div>
                    <div style={{marginTop: '3em'}}>
                        <div style={ styles.listContainer }>
                            <div style={ styles.listSelected }>{this.state.selected}</div>
                            <div style={ styles.list }>
                                {
                                    (() => {
                                        if(this.props.client.loading) {
                                            return <LoadingIndicator visible={true} />;
                                        }
                                        return this.props.client.clients.map((client, idx) => {
                                            var row = [];
                                            row.push(client.name);
                                            row.push(t(client.salesArea));
                                            if(client.createdBy) {
                                                row.push(client.createdBy);
                                            }
                                            if(client.created) {
                                                row.push(localeDateString(client.created));
                                            }
                                            return (
                                                <div key={ 'client_item_' + idx } style={{padding: '0.75em 0.5em', cursor: 'pointer', backgroundColor: idx % 2 ? colors.lightGray2 : colors.white}}>
                                                    <Link to={ '/client/' + client.id }>
                                                        {row.join(' - ')}
                                                    </Link>
                                                </div>
                                            );
                                        });
                                    })()
                                }
                            </div>
                        </div>
                    </div>
                </StandardPage>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.userProfile.user,
        client: state.client,
        profile: state.userProfile,
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(ClientList)));
