import React, { Component }                 from 'react';
import smoothscrollPolyfill                 from 'smoothscroll-polyfill';

smoothscrollPolyfill.polyfill();

class ScrollToComponent extends Component {
    
    componentDidMount() {
        this.scrollToComponent();
    }

    scrollToComponent() {
        // Offset from top minus navigation and breadcrumb
        const scrollAmount = this.scrollNode.offsetTop - 112;

        window.scroll({
            top: scrollAmount,
            left: 0,
            behavior: 'smooth'
        });
    }

    render() {
        return <div ref={ (node) => { this.scrollNode = node } }></div>;
    }
}

export default ScrollToComponent;
