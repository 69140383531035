import React, {Component}   from 'react';
import translations from 'decorators/translations.js';
import configuredRadium     from 'configuredRadium.js';
import { connect }          from 'react-redux';
import _                    from 'lodash';

import {
    commonStyles,
    colors,
    layout,
    measures,
    fonts,
    size
} from 'styles/design.js';

const notificatorBarStyle = [
    layout.fixed,
    layout.textCenter,
    fonts.arial,
    size.normal,
    {

        borderColor: colors.basicGreen,
        backgroundColor: colors.basicGreen,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '1.5em',
        width: '100%',
        bottom: 0,
        lineHeight: '1.5',
        transition: 'transform 1s, opacity 1s, visibility 1s',
        color: colors.white
    }
];

const msgNotificationBarStyle = (msg) => {
    if (!msg) return { visibility: 'hidden', opacity: 0, transform: 'translateZ(0) translateY(100%)' };
    return {
        visibility: msg.hide ? 'hidden' : 'visible',
        opacity: msg.hide ? 0 : 1,
        transform: msg.hide ? 'translateZ(0) translateY(100%)' : 'translateZ(0) translateY(0)',
        backgroundColor: msg.error ? colors.basicRed : colors.basicGreen,
        borderColor: msg.error ? colors.basicRed : colors.basicGreen,
    };
};

const iconStyle = {width: '1.5em', verticalAlign: 'middle'};


class Notificator extends Component {

    componentWillMount() {
        //Hackish way to use the Notificator to show the error message if there are any from the clientMiddleware.js
        if(this.props.error && this.props.error.type === 'networkError') {
            this.showMessage(this.props.networkError);
        }
    }

    componentDidUpdate(prevProps) {
        const t = this.props.t;
        _.forEach(Notificator.registeredProps, (prop) => {
            let message = null;
            const error = this.props[prop].error && this.props[prop].error.error ? this.props[prop].error.error : this.props[prop].error;
            if (prevProps[prop].saving && !this.props[prop].saving) {
                if (!this.props[prop].error) {
                    message = { success: t('save_success') };
                } else {
                    message = { error: t('save_failed'), message: t(error.message) };
                }
            }
            if (prevProps[prop].uploading && !this.props[prop].uploading) {
                if (!this.props[prop].error) {
                    message = { success: t('upload_success') };
                } else {
                    message = { error: t('upload_failed'), message: t(error.message) };
                }
            }
            if (prevProps[prop].deleting && !this.props[prop].deleting) {
                if (!this.props[prop].error) {
                    message = { success: t('delete_success') };
                } else {
                    message = { error: t('delete_failed'), message: t(error.message) };
                }
            }
            if (prevProps[prop].loading && !this.props[prop].loading && !this.props[prop].loaded && this.props[prop].error) {
                message = { error: t('loading_failed'), message: t(error.message) };
            }
            if(prop === 'searchResults') {
                if (prevProps[prop].searching && !this.props[prop].searching && !this.props[prop].searched && this.props[prop].error) {
                    message = { error: t('loading_failed'), message: t(error.message)};
                }
            }
            if(prop === 'agreement') {
                if(prevProps[prop].signing && !this.props[prop].signing && this.props[prop].signed) {
                    message = {success: t('agreement_signed'), signed: true};
                }
            }
            if(message) {
                this.showMessage(message);
            }
        });
    }

    static registeredProps = [
        'clientData',
        'buildingData',
        'disciplineData',
        'countriesData',
        'disciplineConfig',
        'frameagreement',
        'contractInfo',
        'agreement',
        'files',
        'department',
        'searchResults',
        'download',
        'attachment',
        'system',
        'signature',
        'copying'
    ];

    showMessage(message) {
        const msg = Object.assign({}, message);
        if (msg) {
            this.setState({ msg });
            if(msg.signed) {
                this.setState({signed: true});
                //Hide message after 20 seconds
                setTimeout( () => {
                    this.hideMessage();
                    setTimeout( () => {
                        this.setState({signed: false});
                    }, 2000);
                }, 20000);
            } else if (msg.success) {
                setTimeout( () => {
                    this.hideMessage();
                }, 3000);
            } else {
                setTimeout( () => {
                    this.hideMessage();
                }, 10000);
            }
        }
    }

    handleAckError = (event) => {
        this.hideMessage();
        event.preventDefault();
        return false;
    };

    hideMessage = () => {
        const msg = Object.assign({}, this.state.msg);
        msg.hide = true;
        this.setState({
            msg
        });
    };

    render() {
        const { t } = this.props;
        const msg = this.state.msg;
        return (
            <div id="notificator" style = { [notificatorBarStyle, msgNotificationBarStyle(msg)] } >
            { (() => {
                if (msg && msg.error) {
                    return (
                        <div>
                            <div style={[layout.inlineBlock, {verticalAlign: 'middle'}]}>
                                <img style={iconStyle} alt="error" src={require('images/icons/Icon_Warning.svg')} />
                            </div>
                            <div style={[layout.inlineBlock, {marginLeft: measures.standard, verticalAlign: 'middle'} ]}>
                                <span>{ msg.error } </span>
                                <span style={ [size.tiny] }> { msg.message }</span>
                                <a style={ [commonStyles.buttonLink, { backgroundColor: colors.lightGray, color: colors.blue, marginLeft: '1em', padding: '0.25em 0.75em' }] } onClick={ this.handleAckError }>{ this.props.t('OK') }</a>
                                <a href="/" style={ [commonStyles.buttonLink, { backgroundColor: colors.lightGray, color: colors.blue, marginLeft: '1em', padding: '0.25em 0.75em' }] }>{ this.props.t('reload') }</a>
                            </div>
                        </div>
                    );

                } else if(this.state.signed) {
                    //const agreement = this.props.agreement.signedAgreement;
                    return (
                        <span>
                            <div>
                                <img style={iconStyle} alt="ok" src={require('images/icons/Icon_OK.svg')} />
                                {t('agreement_signed')}
                                <div style={[layout.inline, {marginLeft: measures.standard, verticalAlign: 'middle'} ]}>
                                    <a style={ [commonStyles.buttonLink, { backgroundColor: colors.lightGray, color: colors.blue, marginLeft: '1em', padding: '0.25em 0.75em' }] } onClick={ this.handleAckError }>{ this.props.t('OK') }</a>
                                </div>
                            </div>
                        </span>
                    );
                } else if (msg && msg.success) {
                    return (
                        <span>
                            <img style={iconStyle} alt="ok" src={require('images/icons/Icon_OK.svg')} /> { msg.success }
                        </span>
                    );
                }
                return (<span />);
            })() }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        clientData: state.client,
        buildingData: state.building,
        disciplineData: state.discipline,
        countriesData: state.countries,
        disciplineConfig: state.disciplineConfig,
        agreement: state.agreement,
        networkError: state.networkError,
        department :state.department,
        searchResults: state.searchResults,
        files: state.files,
        download: state.download,
        attachment: state.attachment,
        frameagreement: state.frameagreement,
        system: state.system,
        contractInfo: state.contractInfo,
        signature: state.signature,
        copying: state.copying
    };
}
const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(Notificator)));
