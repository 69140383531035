import React from 'react';
import LoadingIndicator from 'components/Loading/LoadingIndicator';
import './Button.css';
import { Link } from 'react-router-dom';

const action = (e, disabled, onClick) => {
    e.preventDefault();
    if (!disabled) {
        onClick(e);
    }
};

const Button = (props) => {
    const {
        disabled,
        className,
        onClick,
        variant, 
        children,
        loading,
        size,
        dropdownButton,
        isButton,
        href,
        ...other
    } = props;

    let buttonProps = {};
    let ButtonElement = isButton ? `button` : Link;

    if (isButton) {
        buttonProps.type = 'button';
    } else {
        buttonProps.to = href ? href : '/';
        buttonProps.role = 'button';
    }
    if (typeof onClick === 'function') {
        buttonProps.onClick = e => action(e, disabled, onClick);
    }

    return (
        <ButtonElement
            className={
                (variant ? 'ct-button ct-button-' + variant : 'ct-button ct-button-primary') 
                + (dropdownButton ? ' ct-button-dropdown' : '')
                + (className ? ' ' + className : '')
                + (size ? ' ct-button-size-' + size : '')
            }
            {...buttonProps}
            {...other}
        >
            <LoadingIndicator visible={loading}/>
            <span>{children}</span>
        </ButtonElement>
    );
}

export default Button;