import React, {Component}   from 'react';
import translations from 'decorators/translations.js';
import { connect }                      from 'react-redux';
import configuredRadium     from 'configuredRadium.js';
import {
    save as saveAgreement,
    loadWithIncludes as loadAgreement
}                           from 'redux/modules/agreement.js';
import {
    isEditable
}                                   from 'helpers/agreement.js';

import { loadContractInfo, isContractInfoLoaded } from 'redux/modules/contractInfo';
import { loadCountry } from 'redux/modules/country.js';
import { loadWithType } from 'redux/modules/translations.js';

import {
    SLAInformation,
    SLAForm,
    StandardPage,
    BreadcrumbBar,
    LoadingIndicator,
    BackLink,
    Header
}                           from 'components';

import _ from 'lodash';

const slaTranslatableMap = {
    slaResponseTimeDescription: 'mainagreement_sla_terms_response_time_description',
    slaYearlyMeetingDescription: 'mainagreement_sla_terms_yearly_meeting_description',
    slaSupport247description: 'mainagreement_sla_terms_support_247_description'
};

class SLAView extends Component {

    componentWillMount() {
        const promiseArr = [];
        const params = this.props.match.params;
        const token = this.props.profile.accessToken;
        if(!isContractInfoLoaded(this.props, params.contractInfoId)) {
            promiseArr.push(this.props.dispatch(loadContractInfo(params.contractInfoId, token)));
        }
        if(!this.props.agreement.loaded || this.props.agreement.agreement.length === 0 || !this.props.agreement.agreement[0] || this.props.agreement.agreement[0].id !== this.props.match.params.agreementId) {
            promiseArr.push(this.props.dispatch(loadAgreement(params.agreementId, token)));
        }
        return Promise.all(promiseArr).then(async () => {
            const contractInfo = this.props.contractInfo.contractInfo;
            let country;
            if (contractInfo) {
                country = await this.props.dispatch(loadCountry(contractInfo.salesArea, token));
            }
            const defaultLanguage = country && country.result && country.result.defaultLanguage;
            const languages = ['en'];
            const lang = defaultLanguage || 'en';
            if (lang && lang !== 'en') {
                languages.push(lang);
            }
            await this.props.dispatch(loadWithType(2, languages.join(','), country.id, token));
            this.setState({
                isLoading: false,
                language: lang
            });
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isSaving && nextProps.agreement.reload) {
            const params = this.props.match.params;
            this.props.dispatch(loadAgreement(params.agreementId, this.props.profile.accessToken));
            this.setState({isSaving: false});
            this.setState({isLoading: true});
            window.scrollTo(0,0);
        } else if (this.state.isLoading && nextProps.agreement.loaded) {
            this.setState({isLoading: false, editSLA: false});
        }
    }

    editSLA = (value) => {
        this.setState({ editSLA: value });
        if (!value) {
            window.scrollTo(0,0);
        }
    };

    handleSubmitAgreement = (data) => {
        const agreementId = this.props.match.params.agreementId;
        const clientId = this.props.match.params.clientId;
        data.clientId = clientId;
        data.id = agreementId; // must exist
        const dispatchSave = this.props.dispatch(saveAgreement(data, this.props.profile.accessToken));
        this.setState({isSaving: true});
        return dispatchSave;
    };

    handleCancel() {
        this.editSLA( false );
    }

    getAgreementLanguage = () => {
        const {
            language,
            agreement: {
                agreement
            },
            countries: {
                selectedCountry
            }
        } = this.props;
        if (agreement.language) {
            return agreement.language;
        }
        if (language === selectedCountry.defaultLanguage) {
            return language;
        }
        return 'en';
    };

    getSlaDescription = key => {
        const {
            translatables,
            countries: {
                selectedCountry
            }
        } = this.props;
        if (!translatables) {
            return null;
        }
        const language = this.getAgreementLanguage();
        if (
            translatables &&
            slaTranslatableMap[key] &&
            translatables[slaTranslatableMap[key]] &&
            translatables[slaTranslatableMap[key]][language]
        ) {
            return translatables[slaTranslatableMap[key]][language].value;
        }
        else if (selectedCountry && selectedCountry[key]) {
            return selectedCountry[key];
        }
        else {
            return '';
        }
    };

    render() {
        const { t, client } = this.props;
        const params = this.props.match.params;
        const agreement = this.props.agreement.agreement;
        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar
                    page="agreement"
                    agreement={agreement}
                    contractInfo={this.props.contractInfo.contractInfo}
                    actionUrl = { `/client/${params.clientId}/contractInfo/${params.contractInfoId}/details`}
                    linkName = { t('show_client_details') }
                    lockedText = { !isEditable(agreement) ? t('locked') : null}
                />
                <BackLink
                    title={t('Back to agreement')}
                    to={ `/client/${params.clientId}/contractInfo/${params.contractInfoId}/agreement/${params.agreementId}/summary`}
                    id="SLAView_Back"
                />
                <Header title={ t('SLA') } id="SLAView_Header" />
                { (() => {
                    if(_.isEmpty(agreement)) {
                        return <LoadingIndicator visible={true} />;
                    } else if(this.state.editSLA) {
                        return (
                            <SLAForm
                                cancelAction={ () => { this.editSLA(false); } }
                                agreement={ agreement }
                                onSubmit={ this.handleSubmitAgreement }
                                client={ client }
                                editing = { this.state.editSLA }
                                countryConfig = { this.props.countries.selectedCountry }
                                submitting={ (this.state.isSaving || this.state.isLoading) }
                                getSlaDescription={this.getSlaDescription}
                            />
                        );
                    }
                    return (
                        <SLAInformation
                            agreement={agreement}
                            client={ client }
                            countryConfig = { this.props.countries.selectedCountry }
                            isEditable={isEditable(agreement)}
                            editClicked={ () => { this.editSLA(true); }}
                            getSlaDescription={this.getSlaDescription}
                        />
                    );
                })()}
            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        agreement: state.agreement,
        agreements: state.agreement.agreements,
        agreementsShouldReload: state.agreement.reload,
        agreementsError: state.agreement.error,
        userProfile: state.userProfile.user,
        client: state.client,
        countries: state.countries,
        profile: state.userProfile,
        contractInfo: state.contractInfo,
        translatables: state.translations.translatables,
        language: state.translations.language,
    };
}

const connector = connect(mapStateToProps);

export default translations(connector(configuredRadium(SLAView)));
