import React        from 'react';

import './Section.css';

const Section = (props) => {
    const {title, children, divider, className, margin, ...other} = props;

    return (
        <section 
            className={ 'section'
            + (divider ? ' section-with-divider' : '')
            + (margin ? ' section-margin-' + margin : '')
            + (className ? ' ' + className : '') } 
            {...other}
        >
            { title && <h2 className="section-subtitle">{ title }</h2> }
            { children }
        </section>
    );
};

export default Section;