import React, {Component}    from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import { connect }                      from 'react-redux';
import { measures, size }       from 'styles/design.js';

const tableCell = [{
    padding: measures.half
}];

const tableCellHeader = [
    tableCell,
    size.small,
    {
        fontWeight: 'bold',
        minWidth: '5em'
    }
];

class SystemComponentList extends Component {

    static propTypes = {
        locations: PropTypes.array.isRequired,
        calculation: PropTypes.array
    };

    static defaultProps = {
        locations: [],
    };

    render() {
        const { components } = this.props;
        return (
            (() => {
                    if(components && components.length > 0) {
                        return (
                            <div>
                                <table>
                                    <thead>
                                    <tr>
                                        <td style={[tableCellHeader]}>{this.props.t('component')}</td>
                                        <td style={[tableCellHeader]}>{this.props.t('description')}</td>
                                        <td style={[tableCellHeader]}>{this.props.t('amount')}</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {(() => {
                                        return components.map((component, index) => {
                                            return (
                                                <tr key={'location_row_' + index}>
                                                    <td style={tableCell}>{component.location}</td>
                                                    <td style={tableCell}>{component.description}</td>
                                                    <td style={tableCell}>{component.capacity}</td>
                                                </tr>
                                            );
                                        });
                                    })()}
                                    </tbody>
                                </table>
                            </div>
                        );
                    }
                return (<div />);
            })()
        );
    }
}


function mapStateToProps(state) {
    return {
        discipline: state.discipline.discipline
    };
}
const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(SystemComponentList)));
