import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {StyleRoot} from 'radium';
import Helmet from 'react-helmet';
import {connect} from 'react-redux';
import { Cookies } from 'utils/index.js';

import {
    loadToken,
    refreshToken,
    load as loadProfile
} from 'redux/modules/userProfile.js';

import {
    load as loadTranslations
} from 'redux/modules/translations.js';

import Navigation from './Navigation/Navigation.js';
import Loading from './Loading/Loading.jsx';
import ErrorHandler from './ErrorHandler/ErrorHandler.js';
import {
    Notificator
} from 'components';

import './Application.css';

let deferredShow;

class Application extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loadingUserProfile: true,
            showLoadingIndicator: false
        };
    }

    componentWillMount() {
        deferredShow = setTimeout(() => {
            this.setState({showLoadingIndicator: true});
        }, 1000);

        // TODO: too frequent. Refresh it when it is about to expire.
        setInterval(() => {
            this.props.refreshToken().then((response) => {
                //Session timeout
                if (response.error) {
                    return window.location = '/auth/start';
                }
            });
        }, 1000 * 120); // token refresh once / minute

        this.props.loadToken().then((response) => {
            if (isNotFound(response)) {
                return window.location = '/auth/start';
            }
            this.props.loadProfile(this.props.profile.accessToken).then((response) => {
                if (isUnauthorized(response)) {
                    this.props.refreshToken().then((response) => {
                        if (isBadRequest(response)) {
                            return window.location = '/auth/start';
                        }
                        this.props.loadProfile(this.props.profile.accessToken).then((response) => {
                            if (!isUnauthorized(response)) {this.setState({loadingUserProfile: false});
                            } else {
                                return window.location = '/auth/start';
                            }
                        });
                    });
                } else if (isInternalServerError(response)) {
                    // TODO: move to error page with retry link?
                    this.setState({loadingUserProfile: false});
                    return window.location = '/auth/start';
                } else {
                    this.setState({loadingUserProfile: false});
                }
                const langMapper = {
                    finland: 'fi',
                    industria: 'fi_industria',
                    norway: 'no',
                    sweden: 'sv',
                    denmark: 'da',
                    germany: 'de',
                    lithuania: 'lt',
                    estonia: 'et',
                };
                const userProfile = this.props.profile.user || {};
                const defaultSalesArea = userProfile.salesAreas && userProfile.salesAreas.length > 0 ? userProfile.salesAreas[0] : false;
                const defaultCountryLanguage = langMapper[defaultSalesArea];
                const language = this.props.translations.language || Cookies.getItem('selectedLanguage') || defaultCountryLanguage || 'en';
                this.props.loadTranslations(language, this.props.profile.accessToken);
                clearTimeout(deferredShow);
            });
        });
    }


    render() {
        if (this.state.loadingUserProfile) {
            if (this.state.showLoadingIndicator) {
                return (
                    <Loading/>
                );
            }
            // For the first 1000 milliseconds, show white page
            return null;
        }
        return (
            <StyleRoot>
                <ErrorHandler/>
                <Helmet titleTemplate="%s - Caverion Contract tool"/>
                <Navigation client={this.props.client.client} user={this.props.profile.user} />
                {this.props.children}
                <Notificator />
            </StyleRoot>
        );
    }
}

function isInternalServerError(response) {
    return response && response.error && response.error.statusCode === 500;
}

function isBadRequest(response) {
    return response.error && response.error.status === 400;
}

function isNotFound(response) {
    return response.error && response.error.status === 404;
}

function isUnauthorized(response) {
    return response && response.error && (response.error.status === 401 || (response.error.error && response.error.error.code === 'Authentication_ExpiredToken'));
}

function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        client: state.client,
        translations: state.translations,
        cookies: state.cookies
    };
}
function mapDispatchToProps(dispatch) {
    return {
        loadToken: () => {
            return dispatch(loadToken());
        },
        refreshToken: () => {
            return dispatch(refreshToken());
        },
        loadProfile: (accessToken) => {
            return dispatch(loadProfile(accessToken));
        },
        loadTranslations: (language, accessToken) => {
            return dispatch(loadTranslations(language, accessToken, true));
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default translations(connector(Application));