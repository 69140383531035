import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';
import _ from 'lodash';
import {deepCopy} from 'helpers/objectHelper.js';

import {
    DisciplineIcon,
    FrameAgreementPricingConfigForm,
    FrameAgreementPricingConfigInformation,
    TranslationForm
} from 'components';
import {
    fonts,
    size,
    layout,
    measures,
    colors,
    commonStyles
} from 'styles/design.js';

const disciplineNameStyle = [
    fonts.sanches,
    size.zeta,
    layout.inline,
    {
        paddingLeft: measures.standard,
        paddingRight: measures.quarter
    }
];

const disciplineIconStyle = [
    {margin: measures.half}
];

class FrameAgreementDisciplineList extends Component {
    state = {expanded: {}};

    static defaultProps() {
        return {
            disabled: [],
            existing: []
        };
    }

    componentWillMount() {
        this.getGroupedDisciplines(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.getGroupedDisciplines(nextProps);
    }

    getGroupedDisciplines(props) {
        const groupedDisciplines = props.disciplines;
        const conf = props.countryConfig.frameAgreementConfig;
        _.keys(groupedDisciplines).map((disciplineGroupKey) => {
            if (conf && conf.disciplines) {
                conf.disciplines.map((discipline) => {
                    if (discipline.disciplineCategory === disciplineGroupKey) {
                        discipline.pricing.map((price, idx) => {
                            price.overtime50 = this.calculateOvertime(price.basicCharge, conf.overtime50multiplier);
                            price.overtime100 = this.calculateOvertime(price.basicCharge, conf.overtime100multiplier);
                            discipline.pricing[idx] = price;
                        });
                        groupedDisciplines[disciplineGroupKey].pricing = discipline.pricing;
                        groupedDisciplines[disciplineGroupKey].description = discipline.description;
                    }
                });
            }
        });
        this.setState({groupedDisciplines: groupedDisciplines});
    }

    disciplineName(discipline) {
        const amount = discipline.disciplineSystems ? discipline.disciplineSystems.length : 0;
        if (discipline.id) return `${this.props.t(discipline.disciplineType)}, ${this.props.t('systems')}: ${amount}`;
        return this.props.t(discipline.disciplineType);
    }

    toggleGroup = (key) => {
        const expanded = this.state.expanded;
        if(expanded[key]) {
            expanded[key] = !expanded[key];
        } else {
            expanded[key] = true;
        }
        this.setState((state) => ({...state, expanded}));
    }

    toggleAll = () => {
        const expanded = {};
        const categories = _.keys(this.state.groupedDisciplines);
        _.map(categories, (category) => {expanded[category] = true});
        this.setState((state) => ({...state, expanded}));
    };

    editPricing = (category) => {
        this.setState({editPricing: category});
    };

    cancelEdit = () => {
        this.setState({editPricing: null});
    };

    calculateOvertime(price, multiplier) {
        const result = Math.round(price * (multiplier ? multiplier : 1) * 100) / 100;
        return result;
    }

    handleSubmit = (conf) => {
        this.setState({submitting: true});
        const countryConfig = this.props.countryConfig || {};
        const existingConf = deepCopy(countryConfig.frameAgreementConfig) || {};
        conf.pricing.map((price, idx) => {
            price.overtime50 = this.calculateOvertime(price.basicCharge, existingConf.overtime50multiplier);
            price.overtime100 = this.calculateOvertime(price.basicCharge, existingConf.overtime100multiplier);
            conf.pricing[idx] = price;
        });

        const existingIndex = _.findIndex(existingConf.disciplines, {disciplineCategory: conf.disciplineCategory});
        if (existingIndex !== -1) {
            existingConf.disciplines[existingIndex] = conf;
        } else {
            existingConf.disciplines.push(conf);
        }
        this.props.saveConfig(existingConf).then((res) => {
            if (res.result) {
                this.cancelEdit();
            } else {
                console.error('Unable to save config', res);
            }
            this.setState({submitting: false});
        })
    };

    getTranslationObject = translation => {
        return {
            value: translation,
            disabled: false,
        }
    };

    render() {
        const {t, translatables, countryConfig} = this.props;
        const groupedDisciplines = this.state.groupedDisciplines;
        const submitting = this.state.submitting;

        if (!countryConfig || !translatables || !groupedDisciplines) {
            return null;
        }

        return (
            <div>
                {_.keys(groupedDisciplines).map((disciplineGroupKey) => {
                    const discipline = {
                        disciplineCategory: disciplineGroupKey,
                        pricing: groupedDisciplines[disciplineGroupKey].pricing,
                        description: groupedDisciplines[disciplineGroupKey].description
                    };
                    const list = {};
                    const translationKey = `fa_discipline_${disciplineGroupKey}`;
                    const language = countryConfig.defaultLanguage;
                    list[translationKey] =  translatables[translationKey] || {};
                    if (_.isEmpty(list[translationKey].en)) {
                        list[translationKey].en = this.getTranslationObject(groupedDisciplines[disciplineGroupKey].description);
                    }
                    if (_.isEmpty(list[translationKey][language])) {
                        list[translationKey][language] = this.getTranslationObject(groupedDisciplines[disciplineGroupKey].description);
                    }
                    const localizables = [translationKey];
                    return (
                        <div key={'discipline-selection-' + disciplineGroupKey}
                                style={[commonStyles.bottomDivider, {
                                    padding: measures.standard,
                                    marginBottom: measures.standard,
                                    marginTop: measures.standard,
                                    backgroundColor: colors.lightGray
                                }]}>
                            <div onClick={() => this.toggleGroup(disciplineGroupKey)}
                                    style={[layout.verticalMiddle, {cursor: 'pointer'}]}>
                                <div style={[layout.floatLeft, {width: '30px'}]}>
                                    <DisciplineIcon style={disciplineIconStyle}
                                                    diciplineType={disciplineGroupKey}/>
                                </div>
                                <div
                                    style={[{
                                        fontSize: '1em',
                                        paddingTop: measures.quarter,
                                        paddingBottom: measures.quarter
                                    }]}>
                                    <h2 style={disciplineNameStyle}>{t(disciplineGroupKey)} </h2>
                                </div>
                            </div>
                            <div style={this.state.expanded[disciplineGroupKey] || (this.state.editPricing && this.state.editPricing === disciplineGroupKey) ? layout.inline : layout.none}>
                                {
                                    (() => {
                                        if (this.state.editPricing && this.state.editPricing === disciplineGroupKey) {
                                            return (
                                                <FrameAgreementPricingConfigForm
                                                    discipline={discipline}
                                                    onSubmit={this.handleSubmit}
                                                    countryConfig={this.props.countryConfig}
                                                    cancelAction={() => this.cancelEdit()}
                                                    submittingPricing={submitting}
                                                    t={this.props.t}
                                                />
                                            );
                                        }
                                        return (
                                            <FrameAgreementPricingConfigInformation
                                                editing={this.state.editPricing}
                                                editClicked={() => this.editPricing(disciplineGroupKey)}
                                                countryConfig={this.props.countryConfig}
                                                discipline={discipline}
                                            />
                                        );
                                    })()
                                }
                                <TranslationForm
                                    localizables={localizables}
                                    country={countryConfig}
                                    type={3}
                                    richText={true}
                                    list={list}
                                />
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default translations(configuredRadium(FrameAgreementDisciplineList));
