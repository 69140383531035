import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import {RichTextView} from 'components/index.js';
import {
    SFRadioFields,
    SFCheckBox,
    SFInputField,
    SFTextareaField,
    SaveButton,
    CancelButton,
    SFMonthSelector
} from 'components';
import {
    commonStyles,
    layout,
    measures
} from 'styles/design.js';
import './SLAForm.css';

const buttonHeaderStyle = {
    ...layout.floatRight,
};

const mapStateToProps = (state) => ({
    responseTimes: state.agreement.responseTimes,
    supportTimes: state.agreement.supportTimes
});

const mapActionsToProps = (/*dispatch*/) => ({});

const reduxFormObject = {
    form: 'SLAForm',
    fields: [
        'responseTime',
        'responseTimeCustomText',
        'responseTimeComment',
        'yearlyMeeting',
        'yearlyMeetingVisitPlan',
        'supportTime247',
        'supportTime247CustomPrice',
        'supportTime247CustomText',
        'supportTime247Comment'
    ]
};

class SLAForm extends Component {

    static propTypes = {
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        cancelAction: PropTypes.func.isRequired
    };

    componentWillMount() {
        const agreement = this.props.agreement;
        if (this.props.agreement && !this.props.agreement.yearlyMeetingVisitPlan) {
            agreement.yearlyMeetingVisitPlan = [false, false, false, false, false, false, false, false, false, false, false, false];
        }
        this.props.initializeForm(agreement);
    }

    componentWillUnmount() {
        this.props.destroyForm();
    }

    handleCancel = (event) => {
        this.props.initializeForm(this.props.agreement);
        this.props.cancelAction(event);
    }
    isValidData = () => {
        const fields = this.props.fields;
        return !fields.yearlyMeeting.value || (fields.yearlyMeeting.value && fields.yearlyMeetingVisitPlan.value.indexOf(true) !== -1);
    };

    render() {
        const {
            fields: {
                responseTime,
                responseTimeCustomText,
                responseTimeComment,
                yearlyMeeting,
                yearlyMeetingVisitPlan,
                supportTime247,
                supportTime247CustomPrice,
                supportTime247CustomText,
                supportTime247Comment
            },
            handleSubmit,
            submitting,
            t,
            countryConfig,
            getSlaDescription
        } = this.props;
        let responseTimes = this.props.responseTimes; //Coming from agreement redux module
        responseTimes = responseTimes.map((val) => {
            if (val === 'custom_response') {
                return {
                    value: val,
                    custom: true,
                    customValue: val + '_text',
                    label: t('response_time_' + val)
                };
            }
            if (val === 'response_none') {
                return {
                    value: val,
                    label: t('none')
                };
            }

            return {
                value: val,
                label: t('response_time_' + val)
            };
        });

        let supportTimes = this.props.supportTimes; //Coming from agreement redux module
        supportTimes = supportTimes.map((val) => {
            if (val === 'custom_support') {
                return {
                    value: val,
                    custom: true,
                    customValue: val + '_text', label: t('support_time_' + val)
                };
            }
            if (val === 'support_none') {
                return {
                    value: val,
                    label: t('none')
                };
            }

            return {
                value: val,
                label: t('support_time_' + val)
            };
        });

        return (
            <div>
                <div style={{marginBottom: '3em'}}>
                    <h2 style={commonStyles.pageSubTitle}>
                        {t('responseTime')}
                    </h2>

                    <div style={{padding: '1em 0.5em'}}>
                        <SFRadioFields
                            formProperty={responseTime}
                            customFormProperty={responseTimeCustomText}
                            name="responseTime"
                            options={responseTimes}
                        />
                    </div>

                    {
                        (() => {
                            if (responseTime.value === 'custom_response') {
                                return (
                                    <SFTextareaField
                                        fieldName={t('response_time_comment')}
                                        formProperty={responseTimeComment}
                                        rows={4}
                                    />
                                );
                            }
                            if (getSlaDescription('slaResponseTimeDescription')) {
                                return (
                                    <div style={{...layout.inlineBlock, marginTop: measures.standard}}>

                                        <RichTextView
                                            labelStyle={{marginLeft: measures.standard}}
                                            value={getSlaDescription('slaResponseTimeDescription')}
                                        />
                                    </div>
                                );
                            }
                        })()
                    }
                </div>

                <div style={{marginBottom: '3em'}}>
                    <h2 style={commonStyles.pageSubTitle}>
                        {t('support_time')}
                    </h2>

                    <div style={{padding: '1em 0.5em'}}>
                        <SFRadioFields
                            formProperty={supportTime247}
                            customFormProperty={supportTime247CustomText}
                            name="supportTime"
                            options={supportTimes}
                        />
                    </div>

                    {
                        (() => {
                            if (supportTime247.value === 'custom_support') {
                                return (
                                    <div>
                                        <SFInputField
                                            fieldName={t('support_time_24/7_price')}
                                            formProperty={supportTime247CustomPrice}
                                            type="number"
                                        />
                                        <SFTextareaField
                                            fieldName={t('support_time_24/7_comment')}
                                            formProperty={supportTime247Comment}
                                            rows={4}
                                        />
                                    </div>
                                );
                            }

                            return (
                                <div style={{...layout.inlineBlock, marginTop: measures.standard}}>
                                    <div style={{marginLeft: measures.standard, marginBottom: measures.standard}}>
                                        {t('price')}: {countryConfig.slaSupport247price} {countryConfig.currency}
                                    </div>

                                    <RichTextView
                                        labelStyle={{marginLeft: measures.standard}}
                                        value={getSlaDescription('slaSupport247description')}
                                    />
                                </div>
                            );
                        })()
                    }
                </div>
                <div style={{marginBottom: '3em'}}>
                    <h2 style={commonStyles.pageSubTitle}>
                        {t('key_customer_services')}
                    </h2>

                    <div style={{padding: '1em 0.5em'}}>
                        <div style={layout.inlineBlock}>
                            <SFCheckBox formProperty={yearlyMeeting}/>
                        </div>

                        <div style={layout.inlineBlock}>
                            {t('yearly_meeting')}
                        </div>
                    </div>

                    <div style={layout.inlineBlock}>
                        <RichTextView
                            labelStyle={{marginLeft: measures.standard}}
                            value={getSlaDescription('slaYearlyMeetingDescription')}
                        />
                    </div>

                    <div style={{padding: '1em 0.5em'}}>
                        <SFMonthSelector
                            formProperty={yearlyMeetingVisitPlan}
                            editState={this.props.editing}
                        />
                    </div>
                </div>

                <div className="sla-form--close" style={buttonHeaderStyle}>
                    <div className="sla-form--close-button"
                         style={{float: 'right'}}>
                        <SaveButton
                            onClick={ () => {
                                if(this.isValidData()) {
                                    handleSubmit()
                                }}
                            }
                            disabled={submitting || this.props.saving}
                            submitting={submitting || this.props.saving }
                            id="SLAForm_SaveButton"
                        />
                    </div>
                    {
                        yearlyMeeting.value && yearlyMeetingVisitPlan.value.indexOf(true) === -1 &&
                        <div className="sla-form--close-tooltip">
                            <div className="highlight">{t('no_visit_plan_for_yearly_meeting')}</div>
                        </div>
                    }

                    <div style={{float: 'right'}}>
                        <CancelButton cancelAction={this.handleCancel}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default translations(reduxForm(reduxFormObject, mapStateToProps, mapActionsToProps)(SLAForm));