import React, {Component}               from 'react';
import translations from 'decorators/translations.js';
import { connect }                      from 'react-redux';
import configuredRadium                 from 'configuredRadium.js';
import { setDiscipline,
         save as saveDiscipline,
         calculateDisciplineCosts,
         resetSubDisciplineCalculations }  from 'redux/modules/discipline.js';

import { loadCountry } from 'redux/modules/country.js';
import {
    updateDisciplineWithCustomIncome,
    loadDiscipline,
    updateSubDisciplineWithCustomIncome
}                                       from 'redux/modules/discipline.js';

import { loadContractInfo } from 'redux/modules/contractInfo';

import {
    saveSystem,
    deleteSystem
} from 'redux/modules/system';

import { loadBuilding }                from 'redux/modules/building.js';
import { loadAgreement }               from 'redux/modules/agreement.js';
import {
    isEditable
}                                       from 'helpers/agreement.js';
import {
    calculateOldIncomeForDisciplineCategory
}                                       from 'helpers/calculation.js';
import _                                from 'lodash';
import {
    StandardPage,
    SubDisciplineForm,
    BreadcrumbBar
}                                       from 'components';
import { loadConfig } from 'redux/modules/disciplineConfig.js';
import {
    loadFiles,
}                               from 'redux/modules/files.js';
import { getLanguage } from 'helpers/languageHelper';

class DisciplineView extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        const params = this.props.match.params;
        const accessToken = this.props.profile.accessToken;
        const promises = [];
        promises.push(this.props.dispatch(loadFiles('agreementfiles', 'disciplineterms', accessToken)));
        promises.push(this.props.dispatch(loadContractInfo(params.contractInfoId, accessToken)));
        promises.push(this.props.dispatch(loadDiscipline(params.disciplineId, accessToken)));
        promises.push(this.props.dispatch(loadBuilding(params.buildingId, accessToken)));
        this.setState({canRender: false});
        Promise.all(promises)
            .then(() => {
                this.props.dispatch(loadAgreement(this.props.discipline.agreementId, params.contractInfoId, accessToken))
                    .then( () => {
                        const agreement = this.props.agreement.agreement;
                        this.setState({
                            isEditable: agreement ? isEditable(agreement) : false,
                            agreement,
                            canRender: true
                        });
                    })
                this.props.dispatch(loadConfig(
                    this.props.contractInfo.salesArea,
                    this.props.discipline.disciplineCategory,
                    this.props.discipline.disciplineType,
                    this.props.profile.accessToken ));
                this.props.dispatch(loadCountry(this.props.contractInfo.salesArea, this.props.profile.accessToken));
                if(this.props.discipline) {
                    this.props.dispatch(calculateDisciplineCosts(this.props.discipline, this.props.profile.accessToken)).then(() => {
                        this.setState({canRender: true})
                    });
                }
            });
    }

    saveSystem = (system) => {
        if(this.state.isEditable) {
            system.disciplineId = this.props.match.params.disciplineId;
            return this.props.saveSystem(system, this.props.profile.accessToken).then( res => {
                if(res.result) {
                    this.props.dispatch(loadDiscipline(this.props.match.params.disciplineId, this.props.profile.accessToken))
                        .then( loadRes => {
                            this.props.dispatch(calculateDisciplineCosts(this.props.discipline, this.props.profile.accessToken));
                        });
                }
            })
        }
    };
    deleteSystem = (id) => {
        this.props.deleteSystem(id, this.props.profile.accessToken).then( res => {
            if(res.result) {
                this.props.dispatch(loadDiscipline(this.props.match.params.disciplineId, this.props.profile.accessToken))
                    .then( loadRes => {
                        this.props.dispatch(resetSubDisciplineCalculations({
                            disciplineId: this.props.discipline.id,
                        }, this.props.profile.accessToken));
                        this.props.dispatch(calculateDisciplineCosts(this.props.discipline, this.props.profile.accessToken));
                    });
            }
        })
    };

    handleSubmit = (discipline) => {
        if(this.state.isEditable) {
            const data = Object.assign({}, discipline);
            if(this.props.calculatedDiscipline && this.props.calculatedDiscipline.fullCostCalculation) {
                data.fullCostCalculation = this.props.calculatedDiscipline.fullCostCalculation;
            }
            return this.props.dispatch(saveDiscipline(data, this.props.profile.accessToken)).then((res) => {
                this.props.dispatch(loadDiscipline(this.props.match.params.disciplineId, this.props.profile.accessToken))
                    .then( loadRes => {
                        window.scrollTo(0, 0);
                        if(res && !res.error) {
                            this.props.dispatch(resetSubDisciplineCalculations({
                                disciplineId: this.props.discipline.id,
                            }, this.props.profile.accessToken));
                        }
                    })

            });
        }
        this.setState({editError: this.props.t('error_discipline_not_editable')});
    };

    resetCustomIncome = discipline => {
        //calculatedDisciplineCosts
        const currentDiscipline = this.props.discipline;
        const agreement = this.props.agreement.agreement;
        const oldIncome = _.first(
            _.map(
                _.groupBy(
                    _.filter(
                        agreement.disciplines,
                        {disciplineCategory: this.props.discipline.disciplineCategory}
                    ),
                    'disciplineCategory'
                ), (row) => {
                    return calculateOldIncomeForDisciplineCategory(row);
                }
            )
        );
        let payload;
        let updateAction;
        if (discipline && discipline.id != null && discipline.fullCostCalculation && discipline.fullCostCalculation.oldIncome != null) {
            payload = {
                disciplineId: parseInt(discipline.id, 10),
                customIncome: discipline.fullCostCalculation.oldIncome,
                customIncomeComment: '-'
            };
            updateAction = this.props.updateSubDisciplineWithCustomIncome;
        }
        else {
            payload = {
                agreementId: parseInt(this.props.discipline.agreementId, 10),
                disciplineCategory: this.props.discipline.disciplineCategory,
                customIncome: oldIncome,
                customIncomeComment: '-'
            };
            updateAction = this.props.updateDisciplineWithCustomIncome;
        }
        this.setState({canRender: false});
        updateAction(payload, this.props.profile.accessToken).then(() => {
            this.props.dispatch(loadDiscipline(currentDiscipline.id, this.props.profile.accessToken)).then((res) => {
                const resultDiscipline = res.result;
                if (resultDiscipline) {
                    this.props.dispatch(calculateDisciplineCosts(resultDiscipline, this.props.profile.accessToken)).then((result) => {
                        const calculatedDiscipline = result.result;
                        this.props.dispatch(setDiscipline(calculatedDiscipline)).then(() => {
                            this.setState({canRender: true})
                        });
                    });
                }

            });
        })
    };

    hasCustomTerms = files => {
        const { contractInfo, agreement } = this.props;
        const lang = (agreement && agreement.agreement && agreement.agreement.language) || getLanguage(this.props.translations.language);
        const country = contractInfo && contractInfo.salesArea;
        const name = `terms_and_conditions_discipline_${this.props.discipline.disciplineCategory}_${lang}_${country}.pdf`;
        return _.some(files, { name });
    };

    render() {
        const {
            disciplineState,
            discipline,
            system,
            match: {
                params
            },
            contractInfo,
            files
        } = this.props;
        const selectedBuilding = this.props.building.building;

        const isCustomTermsAvailable = files && files.files && this.hasCustomTerms(files.files);

        return (
            <StandardPage
                withBreadcrumb
                loaderVisible={
                    disciplineState.loading || !this.state.canRender || system.loading
                }
            >
                <BreadcrumbBar
                    page="building"
                    contractInfo={ contractInfo }
                    building = { selectedBuilding }
                    selectedDiscipline={ discipline }
                />
                {
                    this.state.editError && <div className="highlight" style={{marginBottom: '2em', marginLeft: '2em', fontSize: '1.5em'}}>{this.state.editError}</div>
                }
                {
                    (() => {
                        //canRender is helper state which will be changed to true on componentWillMount after selected discipline is calculated
                        if(disciplineState.loading || !this.state.canRender) {
                            return <div />;
                        } else if(!disciplineState.loading && disciplineState.error && !discipline) {
                            return this.props.t('discipline_not_found');
                        }
                        return (
                            <SubDisciplineForm
                                handleSubmit = { this.handleSubmit }
                                saveSystem = { this.saveSystem }
                                deleteSystem = { this.deleteSystem }
                                discipline={ discipline }
                                agreement = {this.state.agreement}
                                clientId={params.clientId}
                                contractInfoId={params.contractInfoId}
                                isEditable = {this.state.isEditable}
                                resetCustomIncome = {this.resetCustomIncome}
                                disciplineConfig={this.props.disciplineConfig}
                                isCustomTermsAvailable={isCustomTermsAvailable}
                            />
                        );
                    })()
                }

            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client,
        contractInfo: state.contractInfo.contractInfo,
        building: state.building,
        disciplines: state.discipline.disciplines,
        discipline: state.discipline.discipline,
        disciplineState: state.discipline,
        calculatedDiscipline: state.discipline.calculatedDiscipline,
        profile: state.userProfile,
        agreement: state.agreement,
        clientState: state.client,
        system: state.system,
        disciplineConfig: state.disciplineConfig.config,
        files: state.files,
        translations: state.translations,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateDisciplineWithCustomIncome: (data, accessToken) => dispatch(updateDisciplineWithCustomIncome(data, accessToken)),
        updateSubDisciplineWithCustomIncome: (data, accessToken) => dispatch(updateSubDisciplineWithCustomIncome(data, accessToken)),
        saveSystem: (system, accessToken) => dispatch(saveSystem(system, accessToken)),
        deleteSystem: (id, accessToken) => dispatch(deleteSystem(id, accessToken))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(configuredRadium(DisciplineView)));