import React from 'react';
import translations from 'decorators/translations.js';

import {
    commonStyles,
    measures,
    colors,
    layout,
} from 'styles/design.js';

import _ from 'lodash';

const labelStyle = {
    ...layout.block,
    width: '100%',
    marginBottom: measures.standard
};

const validityIndicatorStyle = {
    ...layout.inlineBlock,
    ...layout.floatRight,
    ...layout.absolute,
    ...layout.verticalMiddle,
    color: colors.basicGreen,
    lineHeight: '1.5em',
    fontSize: '150%',
    marginTop: '0.5em',
    marginLeft: '-1.25em'
};
const invalidityIndicatorStyle = {
    ...layout.inlineBlock,
    ...layout.floatRight,
    ...layout.absolute,
    ...layout.verticalMiddle,
    color: colors.basicRed,
    lineHeight: '1.5em',
    fontSize: '150%',
    marginTop: '0.5em',
    marginLeft: '-1.25em'
};

const formInputStyle = {
    ...commonStyles.input,
    marginTop: '0.5em'
};

const mandatoryInputStyle = {
    borderWidth: '2px',
};

const validationErrorStyle = {
    color: colors.basicRed
};

const onelineInputStyle = {
    ...layout.floatRight,
    display: 'inline-block',
    marginTop: '0',
};

const twoRowsInputStyle = {
    width: '100%',
    display: 'inline'
};

const onelineLabelStyle = {
    ...layout.verticalMiddle,
    display: 'inline-block',
    lineHeight: '2.5em',
    height: '2.5em',
};

const twoRowsLabelStyle = {
    paddingTop: measures.standard
};

const SFInputField = (properties) => {
    const props = Object.assign({}, properties);
    if (!props.formProperty) {
        props.formProperty = {};
    }
    const labelStyles = Object.assign({}, labelStyle);
    if(props.labelStyle) {
        Object.assign(labelStyles, props.labelStyle);
    } else {
        Object.assign(labelStyles, props.oneline ? onelineLabelStyle : twoRowsLabelStyle);

    }
    return (
        <label style={labelStyles}>
            {(() => {
                if (props.error || (props.formProperty && props.formProperty.error && !props.showErrorBelow)) {
                    return <span style={validationErrorStyle}>{props.formProperty.error || props.error}</span>;
                }
                return props.fieldName;
            })()}
            {
                (() => {
                    const hasValidityIndicator =
                        (props.formProperty && props.formProperty.error && !props.showErrorBelow) ||
                        (props.formProperty && props.formProperty.touched && props.mandatory && !_.isEmpty(props.formProperty.value));
                    const inputStyle = Object.assign({}, formInputStyle, hasValidityIndicator ? { paddingRight: '1.75em'} : {});
                    if (props.mandatory) Object.assign(inputStyle, mandatoryInputStyle);
                    if (props.oneline) {
                        Object.assign(inputStyle, onelineInputStyle);
                    } else {
                        Object.assign(inputStyle, twoRowsInputStyle);
                    }
                    if (props.maxLength) inputStyle.width = (parseInt(props.maxLength, 10) + 2) + 'em';

                    const inputProps = {
                        style: inputStyle,
                        placeholder: props.placeholder || props.fieldName,
                        maxLength: props.maxLength,
                        id: props.id,
                        disabled: props.disabled,
                        type: props.type || 'text',
                        min: props.min,
                        max: props.max
                    };

                    const formPropertyProps = {
                        onChange: props.formProperty.onChange,
                        value: props.formProperty.value
                    };

                    if (props.defaultInput) {
                        return (
                            <input
                                value={props.value}
                                onChange={props.onChange}
                                {...inputProps}
                            />
                        );
                    } else {
                        return (
                            <input
                                {...inputProps}
                                {...formPropertyProps}
                            />
                        );
                    }

                })()
            }

            {(() => {
                if (props.formProperty && props.formProperty.error && !props.showErrorBelow) {
                    return <span style={invalidityIndicatorStyle}><i className="fa fa-exclamation"/></span>;
                } else if (props.formProperty && props.formProperty.touched && props.mandatory && !_.isEmpty(props.formProperty.value)) {
                    return <span style={validityIndicatorStyle}><i className="fa fa-check"/></span>;
                }
            })()}

            {(() => {
                if (props.formProperty && props.formProperty.error && props.showErrorBelow) {
                    return (
                        <div style={validationErrorStyle}>{props.formProperty.error}</div>
                    );
                }
            })()}
        </label>
    );
};
export default translations(SFInputField);
