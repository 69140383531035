import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import configuredRadium             from 'configuredRadium.js';
import _                            from 'lodash';
import { localeDateString }         from 'helpers/stringTemplates';
import {
    ListTitle,
    ListManager
}                                   from 'components';

import {
    colors
}                                   from 'styles/design.js';

const styles = {
    listContainer: {
        margin: '0 0 4em 0'
    },
    noData: {
        margin: '1em 0 4em'
    }
};

class ReportList extends Component {
    render() {
        const {t, agreementData} = this.props;

        const statusOrder = {
            'signed'            : 1,
            'approved'          : 2,
            'sent_for_approval' : 3,
            'rejected'          : 4,
            'draft'             : 5
        };
        const sortByTitleFunction = (a, b) => {
            if (!a.title || !b.title) {
                return -1;
            }
            const titleA = a.title.toUpperCase();
            const titleB = b.title.toUpperCase();
            if (titleA < titleB) {
                return -1;
            } else if (titleA > titleB) {
                return 1;
            }
            return 0;
        };
        const sortByStatusFunction = (a, b) => {
            if (statusOrder[a.status] === statusOrder[b.status]) {
                return 0;
            }
            return statusOrder[a.status] < statusOrder[b.status] ? -1 : 1;
        };
        const mapStatusColor = (status) => {
            let color;
            switch (status) {
                case 'signed':
                    color = colors.blue;
                    break;
                case 'approved':
                    color = colors.blue;
                    break;
                case 'sent_for_approval':
                    color = colors.orange;
                    break;
                case 'rejected':
                    color = colors.basicRed;
                    break;
                case 'draft':
                    color = colors.lightBlue;
                    break;
                default:
                    color = colors.blue;
            }
            return color;
        };
        const createListElement = (agreement) => {
            const newData = {};
            const contractInfo = (agreement && agreement.contractInfo) || {};
            const client = contractInfo.client || {};
            newData.title = client.name;
            if (agreement.agreementType === 'addon') {
                newData.title += ', ' + t('addon');
            } else if (agreement.agreementType === 'main') {
                newData.title += ', ' + t('mainagreement');
            }

            newData.subtitle = t(agreement.agreementStatus) + ' ' + localeDateString(agreement.modified);
            newData.agreementType = agreement.agreementType;
            newData.agreementStatus = agreement.agreementStatus;
            newData.href = '/client/' + client.id;
            newData.totals = agreement.totals;
            return newData;
        };
        const modifyAgreementsByStatus = (agreements) => {
            const newData = {};
            agreements.forEach((agreement) => {
                const color = mapStatusColor(agreement.agreementStatus);
                if (newData[agreement.agreementStatus]) {
                    newData[agreement.agreementStatus].data.push(agreement);
                    newData[agreement.agreementStatus].size++;
                } else {
                    newData[agreement.agreementStatus] = {
                        title: t(agreement.agreementStatus),
                        data: [agreement],
                        size: 1
                    };
                }
                newData[agreement.agreementStatus].color = color;
                newData[agreement.agreementStatus].icon = 'file-pdf-o';
                newData[agreement.agreementStatus].status = agreement.agreementStatus;
            });
            _.keys(newData).forEach((key) => {
                newData[key].title += ' (' + newData[key].size + ')';
                newData[key].data.sort(sortByTitleFunction);
            });
            return newData;
        };

        let agreements;
        let frameAgreements;

        if (agreementData.agreements && agreementData.agreements.length > 0) {
            agreements = agreementData.agreements.map((agreement) => {
                return createListElement(agreement);
            });

            agreements = modifyAgreementsByStatus(agreements);
        }

        if (agreementData.frameAgreements && agreementData.frameAgreements.length > 0) {
            frameAgreements = agreementData.frameAgreements.map((agreement) => {
                return createListElement(agreement);
            });

            frameAgreements = modifyAgreementsByStatus(frameAgreements);
        }

        return (
            <div>
                <div style={ styles.listContainer }>
                    <ListTitle title={ t('frame_agreements') } />
                    {
                        (() => {
                            if (frameAgreements) {
                                return (
                                    <ListManager listData={ _.values(frameAgreements).sort(sortByStatusFunction) } />
                                );
                            }
                            return <div style={ styles.noData }>{ t('no_agreements_available') }</div>;
                        })()
                    }
                </div>
                <div style={ styles.listContainer }>
                    <ListTitle title={ t('serviflex_agreements') } />
                    {
                        (() => {
                            if (agreements) {
                                return (
                                    <ListManager listData={ _.values(agreements).sort(sortByStatusFunction) } />
                                );
                            }
                            return <div style={ styles.noData }>{ t('no_agreements_available') }</div>;
                        })()
                    }
                </div>
            </div>
        );
    }
}
export default translations(configuredRadium(ReportList));
