import React from 'react';
import './ReportTable.css';

const ReportTableMulti = (props) => {
    return (
        <table className="report-table report-table-multi">
            
            {props.data.map((d, i) => {
                return (
                    <tbody key={'tableset' + i}>
                        <tr className="report-table-row">
                            <td className="report-table-heading" colSpan="2">{d.label}</td>
                        </tr>
                        { d.values.map((item, index) => {
                            return (
                                <tr key={'row2' + index} className="report-table-row report-table-row-multi">
                                    <td className="report-table-cell">{item.label}</td>
                                    <td className="report-table-cell">{item.valueText || item.value}</td>
                                </tr>
                            )})
                        }
                    </tbody>
                )
            })}
        </table>
    )
};

export default ReportTableMulti;