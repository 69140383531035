import React, { Component } from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';
import PropTypes from 'prop-types';
import { connect }                     from 'react-redux';
import _                               from 'lodash';
import {
    DisciplineIcon,
    SubDiciplineItems
}                                      from 'components';
import {
    fonts,
    size,
    layout,
    measures,
    commonStyles
}                                      from 'styles/design.js';

const disciplineNameStyle = [
    fonts.sanches,
    size.zeta,
    layout.inline,
    {
        paddingLeft: measures.standard,
        paddingRight: measures.standard
    }
];

const disciplineIconStyle = [
    {
        margin: measures.standard,
        maxWidth: '50px'
    }
];
const subDisciplinesContainerStyle = {
    '@media only screen and (max-width: 425px)': {
        marginLeft: '0', // < Mobile L
    }
};

class DisciplineList extends Component {

    static propTypes = {
        building: PropTypes.object.isRequired,
        disciplines: PropTypes.array.isRequired,
        agreement: PropTypes.object.isRequired
    };

    render() {
        const { t } = this.props;
        const groupedDisciplines = _.groupBy(this.props.disciplines, (discipline) => {
            return discipline.disciplineCategory;
        });

        return (
                <div>
                { (() => {
                    if (_.keys(groupedDisciplines).length > 0) {
                        return _.keys(groupedDisciplines).map((disciplineGroupKey) => {
                            return (
                            <div key = { disciplineGroupKey } style = {{ ...commonStyles.bottomDivider, marginBottom: measures.standard, marginTop: measures.standard }}>
                                <div style = { layout.verticalMiddle } >
                                    <div style = {{ ...layout.floatLeft, width: '50px'}}>
                                        <DisciplineIcon style = { disciplineIconStyle } diciplineType = { disciplineGroupKey } />
                                    </div>
                                    <div style = { {fontSize: '1.3em', paddingTop:'12px', paddingBottom: '12px' }}>
                                        <h2 style = { disciplineNameStyle }>{ t(disciplineGroupKey) }</h2>
                                        <span style = { {fontSize: '0.7em' }}>
                                            { t('subdisciplines') } ({ groupedDisciplines[disciplineGroupKey].length })
                                        </span>
                                    </div>

                                </div>
                                <div style = {{ marginLeft: measures.quadruple, marginTop: measures.standard, ...subDisciplinesContainerStyle}}>
                                    <SubDiciplineItems
                                        subdisciplines = { groupedDisciplines[disciplineGroupKey] }
                                        handleDisciplineDelete = { this.props.handleDisciplineDelete }
                                        deletingDiscipline = { this.state && this.state.deletingDiscipline ? this.state.deletingDiscipline : null }
                                        agreements = {this.props.agreements}
                                        buildingId = {this.props.building.id}
                                        contractInfoId = { this.props.contractInfo.id }
                                        clientId = { _.get(this.props, 'contractInfo.client.id', '') }
                                    />
                                </div>
                            </div>
                            );
                        });
                    }
                    return (<div id="DisciplineList_noDisciplines" style = { [layout.textCenter] }>{ t('no_disciplines_added_to_building') }</div>);
                })()}
          </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        contractInfo: state.contractInfo.contractInfo,
        profile: state.userProfile
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(DisciplineList)));
