import React from 'react';
import translations from 'decorators/translations.js';
import ReportSection from './ReportSection.js';
import {
    ListManager,
    Section
} from 'components';

const ByPerson = ({
    agreementDataByPerson,
    agreementListDataByPerson,
    frameAgreementDataByPerson,
    frameAgreementListDataByPerson,
    chartSize,
    t
}) => {
    if ((agreementDataByPerson && agreementDataByPerson.length > 0)
        ||(frameAgreementDataByPerson && frameAgreementDataByPerson.length > 0)) {
        return (
            <div>
            { frameAgreementDataByPerson && frameAgreementDataByPerson.length > 0 &&
                <ReportSection title={ t('Frame agreements by person')}>
                    <ListManager listData={ frameAgreementListDataByPerson } />
                </ReportSection>
            }
            { agreementDataByPerson && agreementDataByPerson.length > 0 &&
                <ReportSection title={ t('Serviflex agreements by person')}>
                    <ListManager listData={ agreementListDataByPerson } />
                </ReportSection>
            }
            </div>
        );
    }
    return (
        <Section>
            <p>{ t('no_agreements_available') }</p>
        </Section>
    )
}

export default translations(ByPerson);