import React from 'react';
import _ from 'lodash';
import {
    Cards,
    Card,
    ActionCard
} from 'components';

const AgreementBuildings = ({t, buildings, agreement, colors, params, isEditable}) => {
    return (
        <Cards id="AgreementBuildings">
            {
                buildings.map(building => {
                    const buildingDisciplines = _.filter(agreement.disciplines, _.matchesProperty('buildingId', building.id));
                    const disciplineCategories = _.groupBy(buildingDisciplines, 'disciplineCategory');
                    const cardTitle = <span><i className="fa fa-building" /> {building.name}</span>

                    return (
                        <Card
                            key={building.id}
                            title={ cardTitle }
                            to={`/client/${params.clientId}/contractInfo/${params.contractInfoId}/building/${building.id}`}
                            ctas={[{
                                title: t('Show building')
                            }]}
                            id={`building_${building.id}`}
                        >
                            <div style={{textAlign: 'left'}}>
                            {
                                Object.keys(disciplineCategories).map(key => {
                                    return (
                                        <div key={'discipline_' + key} style={{marginBottom: '0.5em'}}>{ t(key) } <span style={{color: colors.gray}}>({ disciplineCategories[key].length })</span></div>
                                    );
                                })
                            }
                            </div>
                        </Card>
                    )
                })
            }
            {isEditable &&
            <ActionCard to={`/client/${params.clientId}/contractInfo/${params.contractInfoId}/agreement/${params.agreementId}/createBuilding`}>
                {t('add_new')}
            </ActionCard>}

        </Cards>
    );
}

export default AgreementBuildings;