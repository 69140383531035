import React from 'react';
import translations from 'decorators/translations.js';

import {
    Button,
    SystemSegmentEditButton,
    SystemFormWrapper,
    SystemFormBody,
    SystemFormActions
} from 'components';

import {
    initializeNewKey,
    initializeExistingKey,
    getValues
}                                   from 'helpers/formHelper.js';

import OperatingHoursDetails from '../OperatingHours/OperatingHoursDetails';
import OperatingHoursForm from '../OperatingHours/OperatingHoursForm';

import './SystemOperatingHours.css';

const idPrefix = "SystemOperatingHours";


class SystemOperatingHours extends React.Component {
    state = {form: {}};

    componentDidMount() {
        this.init(this.props.system);
    }

    getForm() {
        return Object.assign({}, this.state.form);
    }

    cancel = () => {
        this.props.handleCancel();
    };

    handleSave = () => {
        const system = this.props.system;
        system.operatingHours = getValues(this.state.form);
        this.props.handleSave(system);
    };


    init(system) {
        const keys = [
            'workingDayStart',
            'workingDayEnd',
            'saturdayStart',
            'saturdayEnd',
            'sundayStart',
            'sundayEnd'
        ];
        const form = initializeExistingKey({keys, values: system.operatingHours || {}, changeValue: this.changeValue});
        this.setState({form});
    }
    changeValue = (value, index, key) => {
        const form = this.getForm();
        form[key].value = value;
        this.setState({form});
    };

    renderHoursEdit = ({editable}) => {
        const { submitting } = this.props;
        return (
            <div>
                <OperatingHoursForm
                    t={this.props.t}
                    editable={editable}
                    form={this.state.form}
                />
                <SystemFormActions
                    submitting={ submitting }
                    handleSave={ this.handleSave }
                    handleCancel={ this.cancel }
                    idPrefix={ idPrefix }
                />
            </div>
        );
    };
    renderHours = ({editable}) => {
        const { t, showEditButton, isEditable } = this.props;
        return (
            <div>
                <SystemSegmentEditButton
                    onClick = {this.props.systemSegmentEditAction}
                    showEditButton = {showEditButton}
                    editText = {t('edit')}
                    isEditable = {isEditable}
                />
                <OperatingHoursDetails
                    t={this.props.t}
                    editable={editable}
                    hours={this.props.system.operatingHours || {}}
                />
            </div>
        );
    };

    render() {
        const {t} = this.props;
        const editMode = this.props.editMode;
        const expandThis = editMode ? true : this.state.expandThis;

        return (
            <SystemFormWrapper
                id={ idPrefix + '_expand_' + this.props.index}
                isVisible={ expandThis }
                handleToggle={ () => {
                    this.setState({expandThis: !this.state.expandThis});
                } }
                title={ t('operating_hours') }
                isValid={ this.props.system.operatingHours && this.props.system.operatingHours.workingDayStart !== '' }
            >
                <SystemFormBody
                    notEditableBody={ this.renderHours({editable: false}) }
                    editableBody={ this.renderHoursEdit({editable: true}) }
                    isEditable={ editMode }
                />
            </SystemFormWrapper>
        );
    }
}

export default translations(SystemOperatingHours);