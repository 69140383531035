import React, {Component}    from 'react';
import translations from 'decorators/translations.js';
import configuredRadium    from 'configuredRadium.js';

import {layout, colors, commonStyles} from 'styles/design.js';
import _ from 'lodash';

import {
    KeyValueDiv,
    AddressInformation
}                           from 'components';

const infoContainerStyle = [
    {
        padding: '2em',
        borderBottom: '1px solid',
        borderColor: colors.lightGray
    }
];

const infoContainerTitle = [
    commonStyles.pageSubTitle,
    layout.inlineBlock,
    layout.verticalTop,
    {
        height : '100%',
        paddingTop: '0.75em',
        '@media only screen and (max-width: 660px)': {
            width: '100%',
            marginBottom: '1em'
        }
    }
];
const valueStyle = {
    fontWeight: 'bold'
};
class ClientInformation extends Component {

    render() {
        const { t, client } = this.props;
        let billingIndex = '';
        if(this.props.billingIndexes && this.props.client.billingIndexId) {
            const index = _.find(this.props.billingIndexes, {id: this.props.client.billingIndexId});
            if(index) {
                billingIndex = index.key;
            }
        }

        return (
        <div>
            <div style = { infoContainerStyle }>
                <h2 id="ClientInformation_title" style = { infoContainerTitle }>{ t('client_info') } { client.testClient && '(' + t('test_client') + ')' }</h2>
                <KeyValueDiv
                    id="ClientInformation_companyName"
                    name={ t('company_name')}
                    value={ client.name}
                    withColon={true}
                    rightStyles={valueStyle}
                />
                {/*
                <KeyValueDiv
                    id="ClientInformation_salesArea"
                    name={ t('caverion_sales_area')}
                    value={ client.salesArea}
                    withColon={true}
                    rightStyles={{fontWeight: 'bold', textTransform: 'capitalize'}}
                />
                <KeyValueDiv
                    id="ClientInformation_department"
                    name={ t('department')}
                    value={ this.props.department.name}
                    withColon={true}
                    rightStyles={valueStyle}
                />
                */}
                <KeyValueDiv
                    id="ClientInformation_companyEmail"
                    name={ t('email')}
                    value={ client.email}
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_companyPhone"
                    name={ t('client_phone')}
                    value={ client.clientPhone}
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_clientInfo"
                    name={ t('client_info_field')}
                    value={ client.clientInfo}
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <AddressInformation type="ClientInformation_clientAddress" t={t} styling="clientInfo" address={client.clientAddress} />

            </div>
            {/*
            <div style = { infoContainerStyle }>
                <h2 style = { infoContainerTitle }>{ t('contact_info') }</h2>

                <KeyValueDiv
                    id="ClientInformation_contactName"
                    name={ t('name')}
                    value={ client.contactName}
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_contactEmail"
                    name={ t('email')}
                    value={ client.contactEmail}
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv

                    id="ClientInformation_contactPhone"
                    name={ t('phone')}
                    value={ client.contactPhone}
                    withColon={true}
                    rightStyles={valueStyle}
                />
            </div>
            */}
            <div style = { infoContainerStyle }>
                <h2 style = { infoContainerTitle }>{ t('billing_info') }</h2>
                {/*
                <KeyValueDiv
                    id="ClientInformation_billingCompany"
                    name={ t('billing_company')}
                    value={ client.billingCompany}
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_billingIndex"
                    name={ t('billing_index')}
                    value={ t(billingIndex) }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_electronicInvoiceNumber"
                    name={ t('electronic_invoice_number')}
                    value={ client.electronicInvoiceNumber}
                    withColon={true}
                    rightStyles={valueStyle}
                />
                */}
                <KeyValueDiv
                    id="ClientInformation_vatNumber"
                    name={ t('vat_number')}
                    value={ client.vatNumber }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_businessId"
                    name={ t('business_id')}
                    value={ client.businessID }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                {/*
                <KeyValueDiv
                    id="ClientInformation_billingRef"
                    name={ t('billing_ref')}
                    value={ client.billingRef }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_paymentTerm"
                    name={ t('payment_term') }
                    value={ (client.paymentTerm || 14) + ' ' + t('days') }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <AddressInformation type="ClientInformation_billingAddress" t={t} styling="clientInfo" address={client.billingAddress || client.clientAddress} />
                */}
            </div>
            {/*
            <div style = { infoContainerStyle }>
                <h2 style = { infoContainerTitle }>{ t('caverion_info') }</h2>

                <KeyValueDiv
                    id="ClientInformation_keyAccountManager"
                    name={ t('key_account_manager')}
                    value={ client.keyAccountManager }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_caverionEmail"
                    name={ t('email')}
                    value={ client.caverionEmail }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    name={ t('phone')}
                    id="ClientInformation_caverionPhone"
                    value={ client.caverionPhone }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <AddressInformation type="ClientInformation_caverionAddress" t={t} styling="clientInfo" address={client.caverionAddress} />
                <KeyValueDiv
                    name={ t('branch_office_phone')}
                    id="ClientInformation_branchOfficePhone"
                    value={ client.branchOfficePhone }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_localDutyPhone"
                    name={ t('local_duty_phone')}
                    value={ client.localDutyPhone }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_nationalServiceCenterTelephone"
                    name={ t('national_service_center_telephone')}
                    value={ client.nationalServiceCenterTelephone }
                    withColon={true}
                    rightStyles={valueStyle}
                />

            </div>
            */}
        </div>
        );
    }
}

export default translations(configuredRadium(ClientInformation));