import React            from 'react';

const radioContainerStyle = {
    marginTop: '1.5em'
};
const radioLabelStyle = {
    fontFamily: 'Sanchez-Regular',
    cursor: 'pointer'
};

const SFRadioField = (props) => {
    let styles = radioContainerStyle;
    if(props.disabled) styles = Object.assign({}, styles, {color: 'silver'});
    if(props.style) styles = Object.assign({}, styles, props.style);
    return (
        <div style={styles} className="radio">
            <input
                id={props.id ? props.id : props.name ? `${props.value}_${props.name}` : props.value}
                type="radio"
                name={props.name}
                value={props.value}
                onChange={props.formProperty ? props.formProperty.onChange : props.onChange}
                onClick = {props.onUpdate}
                checked={(props.formProperty ? props.formProperty.value : props.selectedValue) === props.value}
                readOnly={ props.formProperty ? props.formProperty.readOnly : props.readOnly }
                disabled={props.disabled}
            />
            <label
                id={'show_' + (props.id ? props.id : props.name ? `${props.value}_${props.name}` : props.value)}
                style={radioLabelStyle}
                htmlFor={props.id ? props.id : props.name ? `${props.value}_${props.name}` : props.value}
            >
                {props.label}
            </label>
        </div>
    );
};
export default SFRadioField;

