import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import configuredRadium from 'configuredRadium.js';
import _ from 'lodash';
import {
    PrintAgreementButton,
    SaveButton,
    CancelButton,
    SFTextareaField,
} from 'components/index.js';
import {
    size,
    colors,
    commonStyles,
    measures
} from 'styles/design.js';

import {
    download
} from 'redux/modules/download.js';
import { isEditable } from 'helpers/agreement.js';

const appendixTitleStyle = {
    marginLeft: measures.standard
};

const appendixSectionStyle = {
    margin: '1em 1em 2em 1em'
};

const addCommentButtonStyle = commonStyles.tinyButton;

const addMainCommentButtonStyle = commonStyles.tinyButton;

const appendixTitleContainerStyle = {
    fontSize: '1em',
    fontWeight: 'bold',
    marginBottom: measures.standard
};

const previewButtonContainerStyle = {
    float: 'right',
    marginTop: '0.75em',
    fontWeight: 'normal',
};

const previewButtonStyle = {
    backgroundColor: colors.lightBlue,
    ...size.small
};
const buttonContainerStyle = {
    marginTop: measures.standard
};

const textAreaStyle = {
    ...commonStyles.input,
    paddingRight: measures.double,
    marginTop: '0.5em',
    width: '100%'
};

const appendixCommentContainerStyle = {marginLeft: '2.5em'};
const commentValueStyle = {marginBottom: measures.standard, whiteSpace: 'pre-wrap', ...size.small};
const saveButtonStyle = {...size.small, backgroundColor: colors.orange};
const commentSectionDesc = {paddingTop: measures.standard};
const commentSectionButton = {float: 'right', paddingTop: measures.standard};
const commentStyle = {fontSize: '0.8em', margin: measures.standard, whiteSpace: 'pre-wrap'};
const reduxFormObject = {
    form: 'agreementCommentsForm',
    fields: [
        'id',
        'comments.main',
        'comments.disciplines',
        'comments.sla',
        'comments.visitplan',
        'comments.pricelist',
        'comments.nu15',
        'comments.mainSections[].section',
        'comments.mainSections[].comment'

    ],
};

class AgreementContents extends Component {

    static propTypes = {
        agreement: PropTypes.object.isRequired,
        editAppendix: PropTypes.string,
        cancelEdit: PropTypes.func.isRequired,
        beginEdit: PropTypes.func.isRequired,
    };

    componentWillMount() {
        this.props.initializeForm(this.props.agreement);
    }

    toggleMainSection = (val) => {
        this.setState({editingMain: val});
    };

    beginMainEdit = (section) => {
        this.setState({editingMainSection: section, editedComment: ''});
    };
    cancelMainEdit = () => {
        this.setState({editingMainSection: null, editedComment: ''});
    };
    submitMainChanges = () => {
        const agreement = this.props.agreement;
        let comments = agreement.comments;
        if (!comments) {
            comments = {
                mainSections: []
            };
        }
        if (this.state.editingMainSection === 'main') {
            comments.main = this.state.editedComment;
            this.cancelMainEdit();
            agreement.comments = comments;
            this.props.handleSubmitComments(agreement);
        } else {
            if (comments && comments.mainSections) {
                const existingIndex = _.findIndex(comments.mainSections, {section: this.state.editingMainSection});

                if (existingIndex !== -1) {
                    if (this.state.editedComment === '') {
                        comments.mainSections.splice(existingIndex, 1);
                    } else {
                        const existing = comments.mainSections[existingIndex];
                        existing.comment = this.state.editedComment;
                    }
                } else {
                    comments.mainSections.push({
                        section: this.state.editingMainSection,
                        comment: this.state.editedComment
                    });
                }
                this.cancelMainEdit();
                agreement.comments = comments;
                this.props.handleSubmitComments(agreement);
            }
        }
    }

    commentChanged(event) {
        this.setState({editedComment: event.target.value});
    }

    downloadFile = (event, downloadType, type, id, appendix, lang) => {
        event.preventDefault();
        this.props.downloadFile(downloadType, type, id, appendix, lang, this.props.profile.accessToken);
        return false;
    };
    getTranslation = (options) => {
        return (options[this.props.language] || options['en-master']).value;
    };

    render() {
        const {t, agreement, fields, submitting, handleSubmit, editAppendix, beginEdit, cancelEdit, sections} = this.props;
        const comments = agreement.comments || {};
        const contents = agreement && agreement.agreementType === 'addon' ? ['disciplines', 'pricelist'] : ['disciplines', 'sla', 'pricelist', 'nu15'];
        const isAgreementEditable = isEditable(agreement);
        return (
            <div>
                <section style={appendixSectionStyle}>
                    <div style={appendixTitleContainerStyle}>
                        <i className="fa fa-2x fa-file-text-o"/>
                        <span style={appendixTitleStyle}>{t('main_agreement')}</span>
                        <div style={{...previewButtonContainerStyle, display: editAppendix ? 'none' : 'inline'}}>
                            <PrintAgreementButton loading={this.props.download.loading} open={this.downloadFile}
                                                  agreementId={agreement.id} appendix={'main'}
                                                  style={previewButtonStyle} label={t('preview')}
                                                  agreement={agreement}
                            />
                        </div>
                    </div>
                    <div style={[appendixCommentContainerStyle]}>
                        <a style={{...addCommentButtonStyle, display: this.state.editingMain ? 'inline' : 'none'}}
                           onClick={() => {
                               this.toggleMainSection(false);
                           }}>{t('hide_main_agreement_comments')}</a>
                        {
                            (() => {
                                if (this.state.editingMain) {
                                    return (
                                        <div>
                                            <div style={buttonContainerStyle}>
                                                {isAgreementEditable && <div style={commentSectionButton}>
                                                    <a style={[
                                                        addCommentButtonStyle,
                                                        this.state.editingMainSection === 'main' ? {display: 'none'} : {}
                                                    ]}
                                                       onClick={() => {
                                                           this.beginMainEdit('main');
                                                       }}
                                                    >
                                                        {t(comments.main && comments.main !== '' ? 'edit_comment' : 'add_comment')}
                                                    </a>
                                                </div>}
                                                <div style={commentSectionDesc}>
                                                    <p>{t('main_comment')}</p>
                                                    {
                                                        (() => {
                                                            const comment = comments && comments.main ? comments.main : '';
                                                            if (this.state.editingMainSection && this.state.editingMainSection === 'main') {
                                                                return (
                                                                    <div>
                                                                        <textarea
                                                                            onChange={(event) => {
                                                                                this.commentChanged(event);
                                                                            }}
                                                                            style={textAreaStyle}
                                                                            defaultValue={comment}
                                                                        />

                                                                        <div style={{textAlign: 'right'}}>
                                                                            <SaveButton onClick={this.submitMainChanges}
                                                                                        style={saveButtonStyle}
                                                                                        submitting={submitting}/>
                                                                            <CancelButton
                                                                                cancelAction={this.cancelMainEdit}
                                                                                disabled={submitting}
                                                                                style={[size.small]}/>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }
                                                            return (<div style={commentStyle}>{comment}</div>);
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                            {
                                                (() => {
                                                    return sections && sections.length > 0 && sections.map((section, idx) => {
                                                        const commentObj = comments ? _.find(comments.mainSections, {section: section.id}) : {};
                                                        const comment = commentObj && commentObj.comment ? commentObj.comment : '';
                                                        return (
                                                            <div key={'main_section_comment' + idx}
                                                                 style={buttonContainerStyle}>
                                                                {isAgreementEditable && <div style={commentSectionButton}>
                                                                    <a style={[
                                                                        addMainCommentButtonStyle,
                                                                        this.state.editingMainSection === section.id ? {display: 'none'} : {}
                                                                    ]}
                                                                       onClick={() => {
                                                                           this.beginMainEdit(section.id);
                                                                       }}
                                                                    >
                                                                        {comment && comment !== '' ? t('edit_comment') : t('add_comment')}
                                                                    </a>
                                                                </div>}
                                                                <div style={commentSectionDesc}>
                                                                    <p>
                                                                        {
                                                                            section.id + '. ' + this.getTranslation(section.translations)
                                                                        }
                                                                    </p>

                                                                    {
                                                                        (() => {
                                                                            if (this.state.editingMainSection && this.state.editingMainSection === section.id) {
                                                                                return (
                                                                                    <div>
                                                                                        <textarea
                                                                                            onChange={(event) => {
                                                                                                this.commentChanged(event);
                                                                                            }}
                                                                                            style={textAreaStyle}
                                                                                            defaultValue={comment}
                                                                                        />

                                                                                        <div
                                                                                            style={{textAlign: 'right'}}>
                                                                                            <SaveButton
                                                                                                onClick={() => {
                                                                                                    this.submitMainChanges();
                                                                                                }}
                                                                                                style={saveButtonStyle}
                                                                                                submitting={submitting}
                                                                                            />
                                                                                            <CancelButton
                                                                                                cancelAction={() => {
                                                                                                    this.cancelMainEdit();
                                                                                                }}
                                                                                                disabled={submitting}
                                                                                                style={size.small}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                            return (<div
                                                                                style={commentStyle}>{comment || ''}</div>);
                                                                        })()
                                                                    }

                                                                </div>

                                                            </div>
                                                        );
                                                    });
                                                })()
                                            }
                                        </div>
                                    );
                                }
                            })()
                        }
                        <a style={[
                            addCommentButtonStyle,
                            this.state.editingMain ? {display: 'none'} : {}
                        ]}
                           onClick={() => {
                               this.toggleMainSection(true);
                           }}
                        >
                            {t('comments')}
                        </a>
                    </div>
                </section>
                {
                    (() => {
                        return contents.map((section, index) => {
                            let contentTitle = '';
                            switch (section) {
                                case 'disciplines':
                                    contentTitle = 'disciplines_and_visitplan';
                                    break;
                                case 'nu15':
                                    contentTitle = 'terms_and_conditions';
                                    break;
                                default:
                                    contentTitle = section;
                            }
                            return (
                                <section key={'section_index_' + index} style={appendixSectionStyle}>
                                    <div style={appendixTitleContainerStyle}>
                                        <i className="fa fa-2x fa-file-text-o"/>
                                        <span
                                            style={appendixTitleStyle}>{t('appendix')} {index + 1}: {t(contentTitle)}</span>
                                        <div
                                            style={[previewButtonContainerStyle, editAppendix ? {display: 'none'} : {}]}>
                                            <PrintAgreementButton loading={this.props.download.loading}
                                                                  open={this.downloadFile} agreementId={agreement.id}
                                                                  appendix={section} style={previewButtonStyle}
                                                                  agreement={agreement}
                                                                  label={t('preview')}/>
                                        </div>
                                    </div>
                                    <div style={appendixCommentContainerStyle}>
                                        {(() => {
                                            if (editAppendix === section) {
                                                return (
                                                    <div>
                                                        <SFTextareaField
                                                            formProperty={fields.comments[section]}
                                                            placeholder={t('add_comment')}
                                                            rows={4}/>
                                                        <div style={{textAlign: 'right'}}>
                                                            <SaveButton onClick={handleSubmit} style={saveButtonStyle}
                                                                        submitting={submitting}/>
                                                            <CancelButton cancelAction={cancelEdit}
                                                                          disabled={submitting} style={[size.small]}/>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                            return (
                                                <div style={commentValueStyle}>{fields.comments[section].value}</div>
                                            );
                                        })()}
                                        {isAgreementEditable && <a style={[
                                            addCommentButtonStyle,
                                            editAppendix === section ? {display: 'none'} : {}
                                        ]}
                                           onClick={beginEdit(section)}>{t(fields.comments[section].value !== '' ? 'edit_comment' : 'add_comment')}</a>}
                                    </div>
                                </section>
                            );
                        });
                    })()
                }
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        translations: state.translations,
        language: state.translations.language,
        profile: state.userProfile,
        download: state.download
    };
}

function mapDispatchToProps(dispatch) {
    return {
        downloadFile: (downloadType, type, id, appendix, lang, accessToken) => {
            return dispatch(download({downloadType, type, id, appendix, lang}, accessToken));
        }
    }
}

export default translations(reduxForm(reduxFormObject, mapStateToProps, mapDispatchToProps)(configuredRadium(AgreementContents)));

