import React, { Component } from 'react';
import translations         from 'decorators/translations.js';
import configuredRadium     from 'configuredRadium.js';
import { Link }             from 'react-router-dom';
import { connect }          from 'react-redux';

import { withRouter } from 'react-router-dom';
import { BreadCrumb }       from 'components/index.js';

import './BreadcrumbBar.css';

class BreadcrumbBar extends Component {

    openDetails = (event) => {
        this.props.history.push(this.props.actionUrl);
        event.preventDefault();
    };

    renderAdminCrumbs() {
        const { t, path } = this.props;

        if(this.props.page === 'admin' && path) {
            const pathSplits = path.split('/');
            const crumbs = [];
            let fullpath = '';

            pathSplits.map((split, idx) => {
                fullpath += (idx > 0 ? '/' : '') + split;
                if (idx > 0) {
                    crumbs.push(<BreadCrumb key={ 'breadcrumb_' + idx } path={ fullpath } name={ t(split) }  />);
                }
            });

            return crumbs;
        } else if(this.props.page === 'globaladmin') {
            const crumbs = [];
            crumbs.push(<BreadCrumb key={'breadcrumb_admin'} path={'/admin'} name={t('admin')} /> );
            crumbs.push(<BreadCrumb key={'breadcrumb_globaladmin'} path={'/globaladmin'} name={t('Global')} /> );
            return crumbs;

        }
    }

    renderCrumbs() {
        const { t, building, selectedDiscipline, agreement, lockedText, contractInfo, page } = this.props;
        const client = contractInfo ? contractInfo.client : this.props.client || {};
        const buildingTitle = building ? t('building') + ': ' + building.name : '';
        const disciplineTitle = selectedDiscipline ?
            t('discipline') + ': ' + (selectedDiscipline.disciplineName || t(selectedDiscipline.disciplineType) || '') : '';
        const crumbs = [];
        if (!client && !contractInfo) {
            return;
        }
        if(this.props.page !== 'admin' && this.props.page !== 'globaladmin' && client) {
            crumbs.push(<BreadCrumb key={ 'breadcrumb_0' } path={ `/client/${client.id}`} name={ client.name || 'N/A' }  />);
            if(this.props.path && this.props.path.indexOf('/details') !== -1) {
                crumbs.push(<BreadCrumb key={ 'breadcrumb_1' } path={'/client/' + client.id + '/details' } name={ t('details') } />)
            }
            if (page === 'agreement' && agreement) {
                const contractInfoId = agreement ? agreement.contractInfoId : '';
                const cInfoBasePath = `/client/${client.id}/contractInfo/${ contractInfoId }`;

                if(this.props.page === 'agreement' && agreement && agreement.mainAgreementId) {
                    const agreementBasePath = `${cInfoBasePath}/agreement/${ agreement.mainAgreementId}`;
                    crumbs.push(<BreadCrumb key={ 'breadcrumb_2' } path={`${agreementBasePath}/summary`} name={  t('main_agreement')} locked={ !!lockedText } />)
                }
                if(this.props.page === 'agreement' && agreement) {
                    const agreementBasePath = `${cInfoBasePath}/agreement/${ agreement.id}`;
                    crumbs.push(<BreadCrumb key={ 'breadcrumb_3' } path={`${agreementBasePath}/summary`} name={ t(agreement.agreementType === 'main' ? 'main_agreement' : agreement.agreementType)} locked={ !!lockedText } /> )
                }
            }
            if(page === 'frameagreement' && agreement) {
                const cInfoBasePath = `/client/${client.id}/contractInfo/${ agreement.contractInfoId }`;
                crumbs.push(<BreadCrumb key={ 'breadcrumb_4' } path={`${cInfoBasePath}/frameagreement/${agreement.id}/summary`} name={  t('frame_agreement') } locked={ !!lockedText } /> )
            }
            if(building) {
                const cInfoBasePath = `/client/${client.id}/contractInfo/${ building.contractInfoId }`;
                crumbs.push(<BreadCrumb key={ 'breadcrumb_5' } path={`${cInfoBasePath}/building/${building.id}`} name={ buildingTitle} open={this.openBreadcrumbBar } />)
            }
            if(selectedDiscipline && building) {
                const cInfoBasePath = `/client/${client.id}/contractInfo/${ building.contractInfoid }`;
                crumbs.push(<BreadCrumb key={ 'breadcrumb_6' } path={`${cInfoBasePath}/building/${building.id}/discipline/${selectedDiscipline.id}`} name={ disciplineTitle}  />);
            }

            return crumbs;
        }

    }

    renderDetailsLink() {
        const { actionUrl, linkName, linkId } = this.props;

        if (actionUrl && linkName) {
            return (
                <div className="breadcrumb-details-link">
                    <Link to={ actionUrl } id={ linkId }>
                        { linkName }
                    </Link>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="breadcrumb-container">
                { this.renderDetailsLink() }
                <ul className="breadcrumb-list">
                    { this.renderAdminCrumbs() }
                    { this.renderCrumbs() }
                </ul>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(withRouter(BreadcrumbBar))));
