import React                from 'react';
import translations         from 'decorators/translations.js';
import waitForFinalEvent    from 'helpers/waitForFinalEvent.js';
import { Link }             from 'react-router-dom';

import {
    commonStyles,
    measures,
} from 'styles/design.js';

import {
    SearchAgreementsResults
} from 'components';

const searchStyle = {
    footer: {
        color: 'gray',
        fontSize: '1em',
        textAlign: 'center',
        marginTop: '25px'
    }
};

const SearchAgreementsComponent = (props) => {

    const searchChanged = (event) => {
        const searchString = event.target.value;
        waitForFinalEvent(
            ()=> {
                props.fireSearch({ waitForInput: true });
            },
            () => {
                if (searchString.length < 2) {
                    props.fireSearch({ clear: true });
                    return false;
                }
                props.fireSearch(searchString);
            },
            500,
            'searchAgreements'
        );
        return false;
    };

    return (
        <div style={ { marginTop: measures.standard } }>
            <input style = { commonStyles.input }
                type="text" onChange= { searchChanged }
                placeholder={ props.t('search_placeholder') }
            />

            <SearchAgreementsResults searchResults={ props.searchResults } t={ props.t } />

            <p style={ searchStyle.footer }>
                { (() => {
                    if ( props.searchResults.searching ) {
                        return props.t('searching...');
                    } else if (props.searchResults.searched && props.searchResults.results.length === 0) {
                        return (
                            <span>
                                { props.t('no_results') }
                                <Link to={ '/createClient' }>
                                    { props.t('create_new_client') }?
                                </Link>
                            </span>
                        );
                    }
                })() }
            </p>
        </div>
    );
};
export default translations(SearchAgreementsComponent);
