import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';
import _ from 'lodash';
import {
    saveFrameAgreement
} from 'redux/modules/frameagreement';

import {
    loadContractInfo,
    isContractInfoLoaded
} from 'redux/modules/contractInfo';

import {
    loadFrameAgreement,
    shouldReloadFrameAgreement,
} from 'redux/modules/frameagreement.js';
import {
    loadCountry,
    isCountryLoaded
} from 'redux/modules/country.js';

import {loadWithType} from 'redux/modules/translations.js';

import {
    StandardPage,
    BreadcrumbBar,
    BackLink,
    Button,
    Header
} from 'components';

import {
    commonStyles,
    measures
} from 'styles/design.js';

const buttonContainerStyle = {
    marginTop: measures.standard,
    minHeight: '50px'
};

const textAreaStyle = {
    ...commonStyles.input,
    paddingRight: measures.double,
    marginTop: '0.5em',
    width: '80%'
};

const commentSectionDesc = {paddingTop: measures.standard};
const commentSectionButton = {float: 'right', marginRight: '5em', paddingTop: measures.standard};
const commentStyle = {fontSize: '0.8em', margin: measures.standard, whiteSpace: 'pre-wrap'};

class FrameAgreementCommentsView extends Component {
    state = {};

    componentWillMount() {
        const {match: {params}} = this.props;
        if (!isContractInfoLoaded(this.props, params.contractInfoId)) {
            this.props.loadContractInfo(params.contractInfoId, this.props.profile.accessToken).then((res) => {
                this.loadCountryAndDepartments()
            });
        } else {
            this.loadCountryAndDepartments();
        }
        if (shouldReloadFrameAgreement(this.props, params.frameAgreementId)) {
            this.props.loadFrameAgreement(params.frameAgreementId, this.props.profile.accessToken);
        }

    }

    loadCountryAndDepartments() {
        const contractInfo = this.props.contractInfo.contractInfo || null;
        if (!this.props.countries.loading && !isCountryLoaded(this.props, contractInfo.salesArea)) {
            this.props.loadCountry(contractInfo.salesArea, this.props.profile.accessToken).then((res) => {
                if (res.result) {
                    this.loadTranslations();
                }
            })
        } else {
            this.loadTranslations();
        }
    }

    loadTranslations() {
        const country = this.props.countries.selectedCountry;
        if (country) {
            const languages = ['en'];
            const countryLang = country.defaultLanguage || 'en';
            if (countryLang !== 'en') languages.push(countryLang);

            this.props.loadTranslationsWithType(2, languages.join(','), country.id, this.props.profile.accessToken).then((transRes) => {
                if (transRes && transRes.result) {
                    const data = transRes.result;
                    const sections = _.map(_.filter(Object.keys(data), (key) => key.indexOf('frameagreement_s') !== -1 && key.indexOf('_header') !== -1), (row, idx) => {
                        return {key: row, id: (idx + 1), translations: data[row]};
                    });
                    this.setState({sections});
                }
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        const promises = [];
        if (this.state && this.state.loadingFrameAgreement && this.props.frameAgreement.loading && nextProps.frameAgreement.loaded) {
            this.setState({loadingFrameAgreement: false});
            this.cancelMainEdit();
        }
        return Promise.all(promises);
    }

    beginMainEdit = (section) => {
        this.setState({editingMainSection: section, editedComment: null});
    }

    cancelMainEdit = () => {
        this.setState({editingMainSection: null, editedComment: null});
    }

    submitMainChanges = () => {
        const agreement = this.props.frameAgreement.agreement;
        let comments = agreement.comments;
        if (!comments) {
            comments = {
                mainSections: []
            };
        }
        if (comments.mainSections) {
            const existingIndex = _.findIndex(comments.mainSections, {section: this.state.editingMainSection});

            if (existingIndex !== -1) {
                if (this.state.editedComment === '') {
                    comments.mainSections.splice(existingIndex, 1);
                } else {
                    const existing = comments.mainSections[existingIndex];
                    existing.comment = this.state.editedComment || existing.comment;
                }
            } else {
                comments.mainSections.push({
                    section: this.state.editingMainSection,
                    comment: this.state.editedComment
                });
            }
            agreement.comments = comments;
            this.setState((state) => ({...state, editingMainSection: false}));
            this.handleSubmitComments(agreement);
        }
    }

    commentChanged = (event) => {
        this.setState({editedComment: event.target.value});
    }

    handleSubmitComments = (agreement) => {
        return this.props.dispatch(saveFrameAgreement(agreement, this.props.profile.accessToken))
            .then(() => {
                return this.props.dispatch(loadFrameAgreement(this.props.match.params.frameAgreementId, this.props.profile.accessToken));
            });
    }

    getTranslation(translations) {
        const defaultLang = this.props.translations.language;
        const trans = translations[defaultLang] || translations['en'];
        return trans && trans.value ? trans.value : '-';
    }

    render() {
        const {t} = this.props,
            comments = this.props.frameAgreement.agreement.comments,
            contractInfo = this.props.contractInfo.contractInfo || {},
            agreement = this.props.frameAgreement.agreement || {};
        const { params } = this.props.match;
        const basePath = `/client/${params.clientId}/contractInfo/${params.contractInfoId}/frameagreement/${agreement.id}`;
        return (
            <StandardPage withBreadcrumb
                          loaderVisible={this.props.translations.loading || this.props.frameAgreement.loading || this.props.frameAgreement.saving}>
                <BreadcrumbBar
                    page="frameagreement"
                    agreement={agreement}
                    contractInfo={ contractInfo }
                />
                {
                    agreement &&
                    <BackLink title={t('Back to agreement')}
                              to={basePath}/>
                }
                <Header title={t('Comments')}/>
                {
                    (() => {
                        if (this.state && this.state.sections) {
                            return this.state.sections.map((section, idx) => {
                                const commentObj = comments ? _.find(comments.mainSections, {section: section.id}) : null;
                                const comment = _.get(commentObj, 'comment', '');
                                return (
                                    <div key={'main_section_comment' + idx} style={buttonContainerStyle}>
                                        <div style={commentSectionButton}>
                                            {
                                                this.state.editingMainSection !== section.id &&
                                                <Button
                                                    onClick={() => this.beginMainEdit(section.id)}
                                                >
                                                    <i className="fa fa-edit"/>
                                                </Button>
                                            }
                                        </div>
                                        <div style={commentSectionDesc}>
                                            <p>
                                                {section.id + '. ' + this.getTranslation(section.translations)}
                                            </p>

                                            {
                                                (() => {
                                                    if (this.state.editingMainSection && this.state.editingMainSection === section.id) {
                                                        return (
                                                            <div>
                                                                <textarea
                                                                    onChange={this.commentChanged}
                                                                    style={textAreaStyle}
                                                                    defaultValue={comment || ''}
                                                                />

                                                                <div>
                                                                    <Button
                                                                        variant="link"
                                                                        id="FrameAgreementCommentsView_comment_cancel"
                                                                        onClick={this.cancelMainEdit}
                                                                    >
                                                                        {t('cancel')}
                                                                    </Button>
                                                                    <Button
                                                                        id="FrameAgreementCommentsView_comment_save"
                                                                        onClick={this.submitMainChanges}>
                                                                        {t('save')}
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return (<div style={commentStyle}>{comment}</div>);
                                                })()
                                            }

                                        </div>

                                    </div>
                                );
                            });
                        }
                    })()
                }
            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        department: state.department,
        client: state.client,
        contractInfo: state.contractInfo,
        countries: state.countries,
        frameAgreement: state.frameagreement,
        profile: state.userProfile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadContractInfo: (id, token) => dispatch(loadContractInfo(id, token)),
        loadCountry: (salesArea, accessToken) => dispatch(loadCountry(salesArea, accessToken)),
        loadFrameAgreement: (agreementId, accessToken) => dispatch(loadFrameAgreement(agreementId, accessToken)),
        saveFrameAgreement: (agreement, accessToken) => dispatch(saveFrameAgreement(agreement, accessToken)),
        loadTranslationsWithType: (type, languages, salesArea, accessToken) => dispatch(loadWithType(type, languages, salesArea, accessToken))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(FrameAgreementCommentsView));
