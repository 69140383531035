import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';
import {getLanguage} from 'helpers/languageHelper';
import {Link} from 'react-router-dom';

import {
    update as updateAgreement,
    unApproveAgreement,
    loadAgreements,
    loadWithIncludes as loadAgreement,
    getDiff,
    updateAgreementLost,
    revokeApproval as revokeApprovalRequest,
    sendForApproval as sendApprovalRequest,
} from 'redux/modules/agreement.js';

import {
    saveSignature,
    deleteSignature
} from 'redux/modules/signature';

import { copyAgreement } from 'redux/modules/copying';

import { loadBuildings } from 'redux/modules/building.js';
import { loadCountries } from 'redux/modules/country.js';

import {
    calculatedDisciplineCosts
} from 'helpers/calculation.js';

import {
    updateAttachment,
    deleteAttachment
} from 'redux/modules/attachment.js';

import {
    download,
} from 'redux/modules/download.js';

import {
    uploadFile
} from 'redux/modules/files.js';

import {
    loadContractInfo,
    isContractInfoLoaded,
    loadContractInfos,
} from 'redux/modules/contractInfo';

import {loadCountry} from 'redux/modules/country.js';
import {loadCountryDepartments} from 'redux/modules/department.js';

import {loadKitPacksForAgreement} from 'redux/modules/kitpack.js';

import {
    isEditable
} from 'helpers/agreement.js';

import {
    loadClientContractInfos
} from 'redux/modules/client';

import {
    AgreementTimestampList,
    StandardPage,
    BreadcrumbBar,
    AgreementApprovalBar,
    SendAgreementToApproval,
    SignAgreementButton,
    ConfirmableButton,
    AgreementAttachments,
    PrintDropdown,
    AgreementContentList,
    Header,
    AddonDropdown,
    BackLink,
    Section,
    AgreementBuildings,
    CopyAgreementComponent,
    ButtonGroup,
} from 'components/index.js';
import { PrintingView } from 'containers';

import {
    commonStyles,
    colors
} from 'styles/design.js';

import {loadWithType} from 'redux/modules/translations.js';
import _ from 'lodash';

import AgreementDetails from './Agreement/AgreementDetails';
import AgreementDetailsForm from './Agreement/AgreementDetailsForm';
import AgreementSignatures from 'components/AgreementSignatures/AgreementSignatures';

import './AgreementView.css';

class AgreementView extends Component {
    state = {};

    componentDidMount() {
        this.loadContents();
    }

    componentDidUpdate(prevProps) {
        const params = this.props.match.params;
        const prevParams = prevProps.match.params;
        if (params.agreementId !== prevParams.agreementId) {
            this.loadContents();
        }
    }

    loadContents = () => {
        const params = this.props.match.params,
            accessToken = this.props.profile.accessToken,
            dispatch = this.props.dispatch;

        dispatch(loadKitPacksForAgreement(params.agreementId, accessToken));
        dispatch(loadCountries(this.props.profile.accessToken));

        this.props.loadAgreement(params.agreementId, accessToken, true)
            .then(() => {
                dispatch(getDiff(params.agreementId, accessToken));
            })
            .then(() => {
                dispatch(loadBuildings(params.clientId, accessToken));
            });

        new Promise((resolve, reject) => {
            dispatch(loadClientContractInfos(params.clientId, accessToken));

            if (!isContractInfoLoaded({client: this.props.clientState}, params.contractInfo)) {
                dispatch(loadContractInfo(params.contractInfoId, accessToken)).then((result) => {
                    if (result) {
                        resolve(result.result);
                    } else {
                        reject(result.error);
                    }
                })
            } else {
                resolve(this.props.contractInfo.contractInfo);
            }
        }).then((contractInfo) => {
            return new Promise((resolve, reject) => {
                if (this.props.selectedCountry && this.props.selectedCountry.id === contractInfo.salesArea) {
                    resolve(this.props.selectedCountry);
                } else {
                    return this.props.loadCountry(contractInfo.salesArea, accessToken)
                        .then(res => {
                            if (res.result) {
                                resolve(res.result);
                            } else {
                                reject(res ? res.error : '');
                            }
                        });
                }
            });
        }).then((country) => {
            const languages = ['en'];
            const lang = country.defaultLanguage || 'en';
            if (lang && lang !== 'en') {
                languages.push(lang);
            }
            return dispatch(loadWithType(2, languages.join(','), country.id, accessToken));
        }).catch(() => {
            this.setState({errorState: true});
        });
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.translationsLoaded && Object.keys(nextProps.translations.translatables).length > 0) {
            const data = nextProps.translations.translatables;
            if (data) {
                const sections = _.map(_.filter(Object.keys(data), (key) => key.indexOf('mainagreement_s') !== -1 && key.indexOf('_header') !== -1), (row, idx) => {
                    return {key: row, id: (idx + 1), translations: data[row]};
                });
                this.setState({translationsLoaded: true, sections});
            }
        }
    }

    calculateMainDisciplineCosts(disciplines) {
        const costs = _.transform(_.groupBy(disciplines, 'disciplineCategory'), (result, value, key) => {
            result.push({
                disciplineCategory: key,
                calculation: calculatedDisciplineCosts(value)
            });
        }, []);
        return costs;
    }

    refreshAgreement = () => {
        const params = this.props.match.params,
            accessToken = this.props.profile.accessToken,
            dispatch = this.props.dispatch;
        dispatch(loadAgreement(params.agreementId, accessToken, true))
            .then(() => {
                dispatch(getDiff(params.agreementId, accessToken));
            });
    };

    handleSubmitComments = (data) => {
        return this.props.dispatch(updateAgreement(data, this.props.profile.accessToken))
            .then(() => {
                this.setState({editAppendix: null});
            });
    };

    unApproveAgreement = () => {
        this.props.dispatch(unApproveAgreement(this.props.match.params.agreementId, this.props.profile.accessToken))
            .then((res) => {
                if (res) {
                    this.setState({update: true});
                }
            });
    };

    downloadFile = (event, downloadType, type, id, printoutType, printoutLanguage) => {
        event.preventDefault();
        this.openPrintingDialog({ printoutType, printoutLanguage });
    };

    uploadFile = (file, filename) => {
        return this.props.uploadFile(
            'attachments',
            'agreement-' + this.props.match.params.agreementId,
            file,
            filename,
            this.props.profile.accessToken
        );
    };

    changeValue = (value, key) => {
        const form = this.state.form;
        form[key] = value;
        this.setState({form});
    };
    toggleEdit = () => {
        this.setState({editAgreement: !this.state.editAgreement});
    };
    cancelEdit = () => {
        this.setState({editAgreement: false});
    };

    getAgreement(agreements, strId) {
        const id = parseInt(strId, 10);
        return _.find(agreements, {id});
    };

    submitAgreement = (values) => {
        const agreementId = this.props.agreementState &&
            this.props.agreementState.agreement &&
            this.props.agreementState.agreement.id;
        if (!agreementId) {
            return;
        }
        const agreement = { id: agreementId };
        agreement.name = values.name;
        agreement.customIndex = values.customIndex;
        agreement.startingDate = values.startingDate;
        agreement.language = values.language;
        this.setState({submitting: true});
        return this.props.dispatch(updateAgreement(agreement, this.props.profile.accessToken))
            .then(() => {
                this.refreshAgreement();
                this.setState({editAgreement: null, form: null, submitting: false});
            });
    };
    submitAttachments = (attachments) => {
        const agreement = this.props.agreementState.agreement;
        agreement.attachments = attachments;
        const promises = [];
        attachments.map((attachment) => {
            attachment.agreementId = parseInt(this.props.match.params.agreementId, 10);
            promises.push(this.props.updateAttachment(attachment, this.props.profile.accessToken));
        });
        return Promise.all(promises);
    };
    deleteAttachment = (attachment) => {
        return this.props.deleteAttachment(attachment, this.props.profile.accessToken);
    };

    updateAttachment = (attachment) => {
        attachment.agreementId = parseInt(this.props.match.params.agreementId, 10);
        return this.props.updateAttachment(attachment, this.props.profile.accessToken);
    };

    renderPrintDropdown = (agreementId, lang) => {
        return (
            <PrintDropdown
                style={{marginLeft: '0.5em'}}
                action={this.downloadFile}
                lang={lang}
                agreementId={agreementId}
            >
                <i className="fa fa-print"/> {this.props.t('print')}
            </PrintDropdown>
        );
    };
    updateAgreementLost = () => {
        const agreement = this.props.agreementState.agreement;
        this.props.updateAgreementLost(agreement.id, agreement.agreementStatus !== 'lost', this.props.profile.accessToken);
    };

    sendApprovalRequest = (email) => {
        const {
            match: {
                params
            }
        } = this.props;
        const agreementId = _.get(this.props, 'match.params.agreementId', null);
        if (agreementId) {
            return this.props.sendApprovalRequest(agreementId, email, this.props.profile.accessToken).then((res) => {
                if (res.result) {
                    return this.props.loadAgreement(agreementId, this.props.profile.accessToken, true);
                }
            })
        }
    };
    revokeApprovalRequest = () => {
        const {
            match: {
                params
            }
        } = this.props;
        const agreementId = _.get(this.props, 'match.params.agreementId', null);
        if (agreementId) {
            return this.props.revokeApprovalRequest(agreementId, this.props.profile.accessToken).then((res) => {
                if (res.result) {
                    return this.props.loadAgreement(agreementId, this.props.profile.accessToken, true);
                }
            })
        }
    };

    saveSignature = signature => {
        const agreement = this.props.agreementState.agreement;
        return this.props.saveSignature({ ...signature, agreementId: agreement.id }, this.props.profile.accessToken);
    }
    deleteSignature = signatureId => {
        this.props.deleteSignature(signatureId, this.props.profile.accessToken)
            .then( res => {
                if (!res.error) {
                    this.refreshAgreement();
                }
            });
    }

    copyAgreement = targetContractInfoId => {
        const agreementId = _.get(this.props, 'match.params.agreementId', null);
        this.props.copyAgreement(agreementId, targetContractInfoId, this.props.profile.accessToken)
            .then( res => {
                const contractInfos = _.get(this.props, 'contractInfo.filteredContractInfos');
                const targetContractInfo = _.find(contractInfos, { 'id': targetContractInfoId });
                const targetClient = targetContractInfo && targetContractInfo.clientId;
                if (res.result) {
                    return this.props.history.push(`/client/${targetClient}`)
                }
            })
    };

    openPrintingDialog = ({ printoutLanguage, printoutType }) => {
        this.setState({
            printoutLanguage: printoutLanguage ? printoutLanguage : 'en',
            printoutType: printoutType ? printoutType : '',
            printingDialog: true,
        });
    };

    closePrintingDialog = () => {
        this.setState({
            printoutLanguage: '',
            printoutType: '',
            printingDialog: false,
        });
    };

    loadCountryDepartments = id => {
        return this.props.loadCountryDepartments(id, this.props.profile.accessToken);
    };
    loadContractInfos = filters => {
        return this.props.loadContractInfos(filters, this.props.profile.accessToken);
    };

    render() {
        const {
            t,
            clientState,
            agreementState,
            countryState,
            match: {
                params
            },
            userProfile,
        } = this.props;
        const agreement = this.props.agreementState.agreement;
        let agreementLanguage = null;
        if (agreement) {
            agreementLanguage = agreement.agreementType === 'addon' ? _.get(agreement, 'parent.language') : agreement.language;
        }
        const lang = agreementLanguage || getLanguage(this.props.translations.language);
        const selectedId = parseInt(this.props.match.params.agreementId, 10);

        const contractInfo = this.props.contractInfo.contractInfo;
        const clientId = contractInfo && contractInfo.client && contractInfo.client.id;
        const contractInfoId = contractInfo && contractInfo.id;
        const agreementId = agreement && agreement.id;
        const agreementCountry = agreement && agreement.contractInfo && agreement.contractInfo.salesArea;

        let agreementDisciplines;
        let totalFullCostcalculation = {};

        if (agreement && agreement.disciplines) {
            agreementDisciplines = agreement.disciplines;
            if (agreement.agreementStatus && agreement.agreementStatus.search('approved|sent_for_approval|signed') > -1) {
                agreementDisciplines = agreement.approvedDisciplines;
            }
            totalFullCostcalculation = agreementDisciplines && agreementDisciplines.length > 0 ? calculatedDisciplineCosts(agreementDisciplines) : [];
        }


        if (agreementState.loading || clientState.loading || countryState.loading) {
            return (
                <StandardPage
                    withBreadcrumb
                    withSubNavigation
                    loaderVisible={true}
                >
                    <div/>
                </StandardPage>
            );
        } else if (!agreement) {
            return (
                <div style={{marginLeft: 'auto', marginRight: 'auto', width: '20em'}}>
                    <h2>
                        {t('agreement_not_found')}
                    </h2>
                </div>
            );
        }

        const buildings = agreement.buildings || [];

        //const mainAgreement = agreement.mainAgreementId ? _.find(agreements, {id: parseInt(agreement.mainAgreementId, 10)}) : agreement;
        const addons = agreement.addons || [];//agreement.mainAgreementId ? _.filter(agreements, {mainAgreementId: agreement.mainAgreementId}) : _.filter(agreements, {mainAgreementId: agreement.id});
        const verboseInformation = true;
        const headerTitleText = !agreement.mainAgreementId ? t('main_agreement') : t('addon')
        const headerTitle = addons.length > 0 ?
            <AddonDropdown addons={addons}>{headerTitleText}</AddonDropdown> : headerTitleText;

        const isAgreementEditable = isEditable(agreement);
        const customerName = _.get(contractInfo, 'client.name');
        const caverionSupplierName = _.get(countryState, 'selectedCountry.caverionSupplierName');
        const caverionName = 'Caverion ' + caverionSupplierName;

        const filteredAddons = _.filter(addons, (addon) => {
            return addon.id > agreement.id;
        });
        const canRevoke = agreement.agreementStatus === 'sent_for_approval' && (userProfile.isAdmin || userProfile.email === agreement.sentForApprovalBy);
        const canUnapprove = userProfile.isAdmin || _.toLower(agreement.createdBy) === _.toLower(userProfile.email);

        return (
            <StandardPage
                withBreadcrumb
            >
                <BreadcrumbBar
                    page="agreement"
                    agreement={agreement}
                    contractInfo={contractInfo}
                    actionUrl={'/client/' + params.clientId + '/details'}
                    linkName={t('show_client_details')}
                    lockedText={!isEditable(agreement) ? t('locked') : null}
                />
                {
                    params.clientId && agreement && agreement.mainAgreementId
                    ? <BackLink title={t('Back to main agreement')}
                        to={'/client/' + params.clientId + '/contractInfo/' + agreement.contractInfoId + '/agreement/' + agreement.mainAgreementId + '/summary'}/>
                    : <BackLink title={t('Back to client')}
                        to={'/client/' + params.clientId }/>
                }

                <div style={{float: 'right', display: 'flex'}}>
                    {this.renderPrintDropdown(agreement.id, lang)}
                </div>
                <Header title={headerTitle} badge={{type: agreement.agreementStatus}}>
                    <AgreementTimestampList
                        showComment={true}
                        agreement={agreement}
                        t={t}
                        verbose={verboseInformation}
                        current={selectedId}
                    />
                </Header>

                <AgreementApprovalBar agreement={agreement} clientId={contractInfo.clientId} />

                <Section>
                    {this.state.editAgreement
                        ? <AgreementDetailsForm
                            cancel={this.cancelEdit}
                            disabled={this.state.submitting}
                            submitting={this.state.submitting}
                            submit={this.submitAgreement}
                            agreement={agreement}
                            selectedCountry={this.props.selectedCountry}
                            t={t}
                        />
                        : <AgreementDetails
                            toggleEdit={this.toggleEdit}
                            t={t}
                            agreement={agreement}
                            isEditable={isAgreementEditable}
                        />}
                </Section>
                <Section>
                    <AgreementContentList
                        agreement={agreement}
                        params={this.props.match.params}
                        kitpacks={this.props.kitpack}
                        totalIncome={totalFullCostcalculation.totalIncome}
                        totalCosts={totalFullCostcalculation.totalCosts}
                        projectMargin={totalFullCostcalculation.actualProjectMargin}
                    />
                </Section>

                <Section title={t('Buildings')}>
                    <AgreementBuildings
                        buildings={buildings}
                        agreement={agreement}
                        params={this.props.match.params}
                        colors={colors}
                        t={t}
                        isEditable={isAgreementEditable || agreement.agreementStatus === 'signed' }
                    />
                </Section>

                <Section title={ t('Attachments') }>
                    <AgreementAttachments
                        getAgreements={this.refreshAgreement}
                        agreement={agreement}
                        submitAttachments={this.submitAttachments}
                        uploadFile={this.uploadFile}
                        deleteAttachment={this.deleteAttachment}
                        updateAttachment={this.updateAttachment}
                        isEditable={isAgreementEditable}
                    />
                </Section>
                <Section title={ t('Signatures') }>
                    <AgreementSignatures
                        signatures={agreement.signatures}
                        t={ t }
                        isEditable={isAgreementEditable}
                        deleteSignature={this.deleteSignature}
                        saveSignature={this.saveSignature}
                        saving={this.props.signature.saving}
                        error={this.props.signature.error}
                        refreshAgreement={this.refreshAgreement}
                        caverionName={caverionName}
                        customerName={customerName}
                    />
                </Section>
                <Section title={ t('Actions') }>
                    {canRevoke && <div style={{ marginBottom: '1em' }}>
                        <label>{t('agreement_has_been_sent_to_email')}
                            "{agreement.approver}" {t('for_approval')} </label>
                    </div>}
                    <ButtonGroup>
                        <CopyAgreementComponent
                            agreement={agreement}
                            copyAgreement={this.copyAgreement}
                            error={this.props.copying.error}
                            countries={this.props.countryState}
                            userProfile={this.props.userProfile}
                            departments={this.props.department}
                            loadCountryDepartments={this.loadCountryDepartments}
                            loadContractInfos={this.loadContractInfos}
                            contractInfos={this.props.contractInfo.filteredContractInfos}
                            agreementCountry={agreementCountry}
                        />
                        {agreement.agreementStatus.search('draft|sent_for_approval|rejected') > -1 &&
                            <SendAgreementToApproval
                                agreement={agreement}
                                revoke={this.revokeApprovalRequest}
                                send={this.sendApprovalRequest}
                            />
                        }
                        <SignAgreementButton agreement={agreement} />
                        {(agreement.agreementStatus === 'approved' || agreement.agreementStatus === 'signed')
                            && (filteredAddons.length > 0 || !canUnapprove
                            ? <button
                                style={{...commonStyles.grayButton, width: 'auto', marginRight: '0.5em'}}
                                disabled
                                title={t('unapprove_disabled_new')}
                            >
                                {t('unapprove_agreement')}
                            </button>
                            : <ConfirmableButton
                                style={{backgroundColor: colors.brightRed}}
                                action={this.unApproveAgreement}
                                confirmText={t('confirm_unapprove')}
                                buttonText={t('unapprove_agreement')}
                            />)
                        }
                        {
                            agreement.agreementStatus === 'lost' &&
                            <ConfirmableButton
                                style={{backgroundColor: colors.orange}}
                                action={this.updateAgreementLost}
                                confirmText={t('confirm_unset_lost')}
                                buttonText={t('unset_lost')}
                                showLoading={this.props.agreementState.updating}
                            />
                        }
                        {
                            agreement.agreementStatus !== 'lost' &&
                            <ConfirmableButton
                                style={{backgroundColor: colors.orange}}
                                disabled={agreement.agreementStatus === 'signed'}
                                action={this.updateAgreementLost}
                                confirmText={t('confirm_set_lost')}
                                buttonText={t('set_lost')}
                                showLoading={this.props.agreementState.updating}
                            />
                        }
                    </ButtonGroup>
                </Section>
                {agreement.agreementStatus === 'draft' && <Section title={ t('delete') }>
                    <div style={{marginTop: '2em'}}>
                        <Link
                            to={`/client/${clientId}/contractInfo/${contractInfoId}/agreement/${agreementId}/delete`}
                            style={{ color: colors.basicRed }}
                        >
                            { t('Delete agreement') }
                        </Link>
                    </div>
                </Section>}
                {this.state.printingDialog &&
                    <PrintingView
                        agreementId={ agreement.id}
                        closeAction={ this.closePrintingDialog }
                        lang={ this.state.printoutLanguage }
                        printoutType={ this.state.printoutType }
                    />
                }
            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        agreementState: state.agreement,
        userProfile: state.userProfile.user,
        clientState: state.client,
        client: state.client.client,
        profile: state.userProfile,
        countryState: state.countries,
        selectedCountry: state.countries.selectedCountry,
        translations: state.translations,
        download: state.download,
        files: state.files,
        kitpack: state.kitpack,
        building: state.building,
        contractInfo: state.contractInfo,
        signature: state.signature,
        copying: state.copying,
        department: state.department,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadContractInfo: (id, token) => dispatch(loadContractInfo(id, token)),
        loadCountry: (salesArea, token) => dispatch(loadCountry(salesArea, token)),
        loadAgreements: (clientId, accessToken) => dispatch(loadAgreements(clientId, accessToken)),
        loadAgreement: (agreementId, contractualInfoId, accessToken) => dispatch(loadAgreement(agreementId, contractualInfoId, accessToken)),
        downloadFile: (downloadType, type, id, appendix, lang, accessToken, clientName) => dispatch(download({
            downloadType,
            type,
            id,
            appendix,
            lang,
            clientName
        }, accessToken)),
        uploadFile: (share, dir, file, filename, accessToken) => dispatch(uploadFile(share, dir, file, filename, accessToken)),
        updateAttachment: (attachment, accessToken) => dispatch(updateAttachment(attachment, accessToken)),
        deleteAttachment: (attachment, accessToken) => dispatch(deleteAttachment(attachment, accessToken)),
        updateAgreementLost: (id, setLost, accessToken) => dispatch(updateAgreementLost(id, setLost, accessToken)),
        revokeApprovalRequest: (id, accessToken) => dispatch(revokeApprovalRequest(id, accessToken)),
        sendApprovalRequest: (id, email, accessToken) => dispatch(sendApprovalRequest(id, email, accessToken)),
        saveSignature: (signature, accessToken) => dispatch(saveSignature(signature, accessToken)),
        deleteSignature: (id, accessToken) => dispatch(deleteSignature(id, accessToken)),
        copyAgreement: (id, targetContractInfoId, accessToken) => dispatch(copyAgreement(id, targetContractInfoId, accessToken)),
        loadCountryDepartments: (id, accessToken) => dispatch(loadCountryDepartments(id, accessToken)),
        loadContractInfos: (filters, accessToken) => dispatch(loadContractInfos(filters, accessToken)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(AgreementView));