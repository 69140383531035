import React, {Component }   from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import { commonStyles }                 from 'styles/design.js';

class CloseButton extends Component {

    static propTypes = {
        closeAction: PropTypes.func.isRequired
    };

    render() {
        return (
        <a style={ commonStyles.cancelButtonLink } onClick={ this.props.closeAction }>{ this.props.t('close') }</a>
    );}
}

export default translations(configuredRadium(CloseButton));