import React, { Component }                 from 'react';
import PropTypes                            from 'prop-types';

import './Dropdown.css';

class DropdownMenu extends Component {
    
    static propTypes = {
        isVisible: PropTypes.bool
    };
    render() {
        return (
            <div className={ this.props.isVisible ? 'dropdown-menu' : 'dropdown-menu is-hidden' } style={ this.props.style }>
                { this.props.children }
            </div>
        );}
}

export default DropdownMenu;
