import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import { connect }                  from 'react-redux';
import configuredRadium             from 'configuredRadium.js';
import _ from 'lodash';
import { loadClient }   from 'redux/modules/client.js';
import { loadWithIncludes as loadAgreement } from 'redux/modules/agreement.js';
import { loadCountry } from 'redux/modules/country.js';

import {
    ProcessContainer,
    StandardPage,
    AgreementSubNavigation,
    BreadcrumbBar,
    SFTextareaField
}                   from 'components';
import {
    isEditable
}                                   from 'helpers/agreement.js';

import {
    commonStyles,
    layout
}                                       from 'styles/design.js';

import {
    initNewProcess
}                                       from 'redux/modules/agreement.js';

class CccProcessView extends Component {

    componentWillMount() {
        const params = this.props.match.params;
        const accessToken = this.props.profile.accessToken;
        const promises = [];
        promises.push(this.props.dispatch(loadClient(params.clientId, accessToken)));
        if(_.isEmpty(this.props.selectedCountry) && this.props.client && this.props.client.salesArea) {
            promises.push(loadCountry(this.props.client.client.salesArea, accessToken));
        }
        promises.push(this.props.dispatch(loadAgreement(params.agreementId, accessToken)));
        this.setState({});
        return Promise.all(promises);
    }

    componentWillReceiveProps(nextProps) {
        const promises = [];
        if(_.isEmpty(nextProps.selectedCountry) && nextProps.client.client && nextProps.client.salesArea) {
            promises.push(this.props.dispatch(loadCountry(nextProps.client.salesArea, this.props.profile.accessToken)));
        }
        return Promise.all(promises);
    }
    startProcess = () => {
        this.setState({submitting: true});
        this.props.dispatch(initNewProcess({comment: this.state.processComment}, this.props.match.params.agreementId, this.props.profile.accessToken)).then(() => {
            this.props.dispatch(loadAgreement(this.props.match.params.agreementId, this.props.profile.accessToken));
            this.setState({submitting: false, processStarted: true});
        });
    };

    openProcess = (event) => {
        const {
            history,
            match: {
                params: {
                    clientId,
                    contractInfoId,
                    agreementId
                }
            }
        } = this.props;
        history.push('/client/' + clientId + '/contractInfo/' + contractInfoId + '/agreement/' + agreementId + '/process');
        event.preventDefault();
    };

    render() {
        const { t, client } = this.props;
        const agreement = this.props.agreement;
        if(_.isEmpty(agreement)) {
            return (<div>Loading....</div>);
        }
        return (
            <StandardPage withBreadcrumb withSubNavigation>
                <BreadcrumbBar
                    page="agreement"
                    agreement={agreement}
                    actionUrl = { '/client/' + client.id + '/details' }
                    linkName = { t('show_client_details') }
                    lockedText = { !isEditable(agreement) ? t('locked') : null}
                />
                <AgreementSubNavigation urlParams={this.props.match.params}  activeMenu = {'process'} agreement={agreement} />
                <header>
                    <h1 style = { commonStyles.pageTitle } >{ t('process') }</h1>
                </header>

                {
                    (() => {
                        if(this.state.processStarted) {
                            return (<div>{t('process_started')}</div>);
                        } else if(agreement.processSteps && agreement.processSteps.length === 0) {
                            return (<div>{t('no_data_yet')}</div>);
                        }
                    })()
                }
                {
                    (() => {
                        const processStarted = this.state && this.state.processStarted;
                        if(agreement.agreementStatus === 'signed') {
                            return (<div>
                                <div style={[agreement.processSteps && agreement.processSteps.length === 0 && !processStarted ? layout.block : layout.none, {margin: '1em'}]}>
                                    <p>
                                        {t('help_send_for_masterdata')}
                                    </p>
                                    <div>
                                        <SFTextareaField
                                            formProperty={{
                                                value: this.state.processComment,
                                                onChange: (event) => this.setState({processComment: event.target.value})
                                            }}
                                            fieldName={t('comment')}
                                        />
                                    </div>
                                    <a
                                        disabled={this.state.submitting}
                                        style={[commonStyles.button, {width: '250px'}]}
                                        onClick={this.startProcess}
                                    >
                                        {t('send_for_masterdata')}
                                    </a>
                                </div>
                            </div>);
                        } else if(!agreement.processSteps || agreement.processSteps.length === 0) {
                            return (<div>{t('agreement_not_signed')}</div>);
                        }
                    })()
                }
                {
                    (() => {
                        if(agreement && agreement.processSteps && agreement.processSteps.length > 0) {
                            return (
                                <ProcessContainer
                                    agreement={agreement}
                                    stepsWithData={agreement.processSteps}
                                    stepsConfig={agreement.processSteps}
                                    uuidToken={this.props.cookies.uuidToken}
                                    accessToken={this.props.profile.accessToken}
                                    currentStep={this.props.match.params.step}
                                    type="user"
                                />
                            );
                        }
                    })()
                }
            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        userProfile: state.userProfile,
        agreement: state.agreement.agreement,
        selectedCountry: state.countries.selectedCountry,
        client: state.client.client
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(CccProcessView)));

