import React from 'react';
import { Section, ListTitle } from 'components';

const ReportSection = ({title, children}) => {
    return (
        <Section margin="large">
            <ListTitle 
                title={title} 
            />
            {children}
        </Section>
    );
}

export default ReportSection;
