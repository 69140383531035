import _ from 'lodash';

const LOAD          = 'ServiFlex/Department/LOAD',
    LOAD_SUCCESS    = 'ServiFlex/Department/LOAD_SUCCESS',
    LOAD_FAIL       = 'ServiFlex/Department/LOAD_FAIL',
    LOAD_ONE          = 'ServiFlex/Department/LOAD_ONE',
    LOAD_ONE_SUCCESS    = 'ServiFlex/Department/LOAD_ONE_SUCCESS',
    LOAD_ONE_FAIL       = 'ServiFlex/Department/LOAD_ONE_FAIL',
    LOAD_ALL        = 'ServiFlex/Department/LOAD_ALL',
    LOAD_ALL_SUCCESS        = 'ServiFlex/Department/LOAD_ALL_SUCCESS',
    LOAD_ALL_FAIL        = 'ServiFlex/Department/LOAD_ALL_FAIL',
    SAVE                = 'ServiFlex/Department/SAVE',
    SAVE_SUCCESS        = 'ServiFlex/Department/SAVE_SUCCESS',
    SAVE_FAIL           = 'ServiFlex/Department/SAVE_FAIL';

const initialState = {
    countryDepartments: [],
    loading: false,
    departments: [],
    department: {},
    loaded: false,
    reload: true,
    saving: false,
    selectedDepartment: null,
    error: false
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true,
                error: null
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                countryDepartments: action.result
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_ONE:
            return {
                ...state,
                loading: true
            };
        case LOAD_ONE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                department: action.result
            };
        case LOAD_ONE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_ALL:
            return {
                ...state,
                loading: true
            };
        case LOAD_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                departments: action.result
            };
        case LOAD_ALL_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SAVE:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                error: action.error,
                selectedDepartment: action.result.id
            };
        case SAVE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };
        default:
            return state;
    }
}

export function loadAllDepartments(accessToken) {
    return {
        types: [LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_FAIL],
        promise: (client) => client.get(
            '/departments', {accessToken}
        )
    };
}

export function loadDepartment(id, accessToken) {
    return {
        types: [LOAD_ONE, LOAD_ONE_SUCCESS, LOAD_ONE_FAIL],
        promise: (client) => client.get(
            '/departments/' + id, {accessToken}
        )
    };
}

export function loadCountryDepartments(countryId, accessToken) {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client) => client.get(
            '/CountryConfigs/' + countryId + '/departments', {accessToken}
        )
    };
}
export function addDepartment(config, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.post(
            '/departments/', {data: config, accessToken }
        )
    };
}

export function saveDepartment(config, accessToken) {
    if(!config.id) {
        return addDepartment(config, accessToken);
    }
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.patch('/departments/' + config.id, {data: config, accessToken }
        )
    };
}
export function shouldLoadCountryDepartments(props, country) {
    const countryDepartments = props.department.countryDepartments;
    if(country) {
        return !countryDepartments || countryDepartments.length === 0 || _.first(countryDepartments).countryConfigId !== country;
    } else {
        return !countryDepartments || countryDepartments.length === 0;
    }
}