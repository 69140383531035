import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';
import configuredRadium from 'configuredRadium.js';
import _ from 'lodash';
import {
    StandardPage,
    AdminSubNavigation,
    BreadcrumbBar,
    SFInputField,
    Button
} from 'components';

import {RichTextView} from 'components/index';


import {
    measures,
    commonStyles,
    layout,
    colors
} from 'styles/design.js';

import {
    loadCountry,
    saveCountryKitpackPricing
} from 'redux/modules/country.js';

import {
    loadKitpackCountryPricing
} from 'redux/modules/kitpack';

class CountryCCCView extends Component {
    state = {errors: {}};

    componentDidMount() {
        const {match: {params}, profile: {accessToken}} = this.props;
        Promise.all([
            this.props.loadCountry(params.countryId, accessToken),
            this.props.loadKitpackCountryPricing(params.countryId, accessToken)])
            .then((results) => {
                const country = results[0].result;
                const kitpackPricing = country.kitpackPricing;
                const kitpackBundlePrices = results[1].result;
                this.setState((state) => ({...state, bundles: kitpackBundlePrices, ...kitpackPricing}));
            })
            .catch((error) => {
                console.error('Unable to fetch data', error);
            })
    }

    setValue = (key, value) => {
        const newValues = {};
        newValues[key] = value;
        this.setState((state) => ({...state, ...newValues}))
    };

    submit = () => {
        const country = this.props.countries.selectedCountry;
        country.kitpackPricing = {
            branchOfficeHourlyPrice: this.state.branchOfficeHourlyPrice,
            remoteCenterHourlyPrice: this.state.remoteCenterHourlyPrice,
            batteryCost: this.state.batteryCost,
            projectMargin: this.state.projectMargin,
        };
        country.kitPackBundlePrices = this.state.bundles;
        this.props.saveCountryKitpackPricing(country, _.get(this.props, 'profile.accessToken', null));
    };

    setBundleValue = (idx, key, value) => {
        const bundles = this.state.bundles;
        if (!bundles[idx]) {
            bundles[idx] = {};
        }
        bundles[idx][key] = value;
        this.setState((state) => ({...state, bundles}));
    }

    render() {
        const {countries: {selectedCountry}} = this.props;
        return (
            <StandardPage withBreadcrumb withSideNavigation
                          loaderVisible={this.props.countries.loading || this.props.countries.saving}>
                <BreadcrumbBar
                    page="admin"
                    path={this.props.location.pathname}
                />
                <AdminSubNavigation params={this.props.match.params} activeNavi="termsAndConditionsConfig"/>
                <header>
                    <h1 style={commonStyles.pageTitle}>{this.props.t(this.props.match.params.countryId)}</h1>
                </header>

                <SFInputField
                    defaultInput={true}
                    showErrorBelow={true}
                    type="number"
                    error={this.state.errors['branchOfficeHourlyPrice']}
                    fieldName={this.props.t('branch_office_hourly_price') + ' ' + selectedCountry.currency}
                    onChange={(event) => this.setValue('branchOfficeHourlyPrice', event.target.value)}
                    value={this.state.branchOfficeHourlyPrice}
                />
                <SFInputField
                    defaultInput={true}
                    showErrorBelow={true}
                    type="number"
                    error={this.state.errors['remoteCenterHourlyPrice']}
                    fieldName={this.props.t('remote_center_hourly_price') + ' ' + selectedCountry.currency}
                    onChange={(event) => this.setValue('remoteCenterHourlyPrice', event.target.value)}
                    value={this.state.remoteCenterHourlyPrice}
                />

                <SFInputField
                    defaultInput={true}
                    showErrorBelow={true}
                    type="number"
                    error={this.state.errors['batteryCost']}
                    fieldName={this.props.t('battery_cost') + ' ' + selectedCountry.currency}
                    onChange={(event) => this.setValue('batteryCost', event.target.value)}
                    value={this.state.batteryCost}
                />

                <SFInputField
                    defaultInput={true}
                    showErrorBelow={true}
                    type="number"
                    error={this.state.errors['projectMargin']}
                    fieldName={this.props.t('project_margin')}
                    onChange={(event) => this.setValue('projectMargin', event.target.value)}
                    value={this.state.projectMargin}
                />

                <div style={{marginTop: '2em', marginBottom: '2em', padding: '1em', border: 'thin solid gray'}}>
                    <h3>Bundle configurations:</h3>
                    {
                        this.props.kitpack.countryPricing.map((row, idx) =>
                            <SFInputField
                                defaultInput={true}
                                showErrorBelow={true}
                                type="number"
                                fieldName={ row.name + ' ' + this.props.t('Initial cost') + ' ' + selectedCountry.currency}
                                onChange={(event) => this.setBundleValue(idx, 'upfrontCost', event.target.value)}
                                value={_.get(this.state, 'bundles[' + idx + '].upfrontCost', 0)}
                            />
                        )
                    }
                </div>
                <Button onClick={this.submit}>Save</Button>

            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        countries: state.countries,
        profile: state.userProfile,
        kitpack: state.kitpack
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadKitpackCountryPricing: (countryId, accessToken) => dispatch(loadKitpackCountryPricing(countryId, accessToken)),
        loadCountry: (id, accessToken) => dispatch(loadCountry(id, accessToken)),
        saveCountryKitpackPricing: (countryConfig, accessToken) => dispatch(saveCountryKitpackPricing(countryConfig, accessToken))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(configuredRadium(CountryCCCView)));
