import React, {Component} from 'react';

import {
    measures,
    colors,
    commonStyles
} from 'styles/design.js';

import {
    LoadingIndicator,
    SFInputField,
    SFTextareaField,
    SaveButton,
    CancelButton,
    ConfirmableButton,
    Card,
    Dialog
} from 'components';

import './Attachment.css';

class Attachment extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentWillMount() {
        if(this.props.attachment.isNew) {
            this.setState({edit: true});
        }
        this.setState({attachment: Object.assign({}, this.props.attachment)});
    }
    changeValue = (key, value) => {
        const attachment = this.state.attachment;
        attachment[key] = value;
        this.setState({attachment});
    };
    validate = () => {
        if (this.state.attachment.name && this.state.attachment.path) {
            return true;
        }
        return false;
    }
    updateAttachment = () => {
        if (this.validate()) {
            this.setState({validationError: false})
            this.setState({submitting: true});
            this.props.updateAttachment(this.props.idx, this.state.attachment).then(() => {
                this.setState({edit: false, submitting: false});
                this.props.getAgreements();
            })
        } else {
            this.setState({validationError: true})
        }
    };
    uploadFile = (event) => {
        const attachment = this.state.attachment;
        let file = event.target.files[0];
        let reader = new FileReader();
        this.setState({uploading: true});

        reader.onloadend = () => {
            this.props.uploadFile(file, file.name).then((results) => {
                if(results.result && results.result.name) {
                    const result = results.result;
                    attachment.path = result.share + '/' + result.directory + '/' + result.name;
                    attachment.data = file;
                    attachment.uploaded = true;
                    this.setState({attachment, uploading: false});
                } else {
                    this.setState({uploading: false});
                }
            });
        };
        if(file) {
            reader.readAsDataURL(file);
        }
    };
    handleCancel = () => {
        if(this.props.attachment.isNew) {
            this.props.deleteRow();
        } else {
            this.setState({edit: false, attachment: this.props.attachment});
        }
    };
    renderAttachmentEdit = ({isEditable, attachment, t, idx}) => {
        return (
            <div className="attachment-edit">
                <div>
                    <div className="attachmentRow">
                        <SFInputField
                            defaultInput={true}
                            value={attachment.name}
                            onChange={(event) => this.changeValue('name', event.target.value)}
                            fieldName={t('name')}
                        />
                    </div>
                    <div className="attachmentRow">
                    {
                        (() => {
                            if (this.state.uploading) {
                                return <div style={{marginTop: '54px'}}><LoadingIndicator big={true} visible={true}/></div>;
                            } else if ((attachment.id && attachment.path) || attachment.uploaded) {
                                return <label style={{ marginTop: '54px', display: 'block', fontSize: '0.75em' }}>{attachment.path}</label>;
                            }
                            return (
                                <div>
                                    <input
                                        id={'attachment_' + idx}
                                        type="file"
                                        className='inputfile'
                                        onChange={(event) => this.uploadFile(event, idx)}
                                        accept=".pdf"
                                        placeholder={t('browse')}
                                    />
                                    <label htmlFor={'attachment_' + idx}>
                                        <span style={{...commonStyles.button, padding: '0.5em 0.75em'}}>{t('browse')}</span>
                                    </label>
                                </div>
                            );
                        })()
                    }
                    </div>
                </div>
                <div>
                    <SFTextareaField
                        defaultInput={true}
                        value={attachment.description}
                        onChange={(event) => this.changeValue('description', event.target.value)}
                        fieldName={t('description')}
                    />
                </div>
                <div style={ this.state.validationError ? { display: 'block', marginBottom: measures.half, textAlign: 'right', color: colors.basicRed } : { display: 'none'} }>{ t('please_add_name_and_file') }</div>
                <CancelButton cancelAction={this.handleCancel} />
                <SaveButton
                    onClick={this.updateAttachment}
                    disabled={this.state.submitting || this.state.uploading}
                    submitting={this.state.submitting || this.state.uploading}
                />
            </div>
        );
    };

    render() {
        const {t, idx, isEditable} = this.props;
        const attachment = this.state.attachment;

        return (
            <Card
                title={attachment.name || t('name')}
            >
                <p>
                    { attachment.description
                        ? attachment.description
                        : <span className="attachment-no-description">{ t('No description')}</span>
                    }
                </p>
                {isEditable &&
                <div>
                    <div className="attachment-actions">
                        <span>
                            <ConfirmableButton
                                variant="delete"
                                buttonIcon = { 'fa fa-trash' }
                                confirmButtonText = { this.props.t('yes') }
                                confirmText = { this.props.t('confirm_delete_attachment') }
                                action = {this.props.deleteRow}
                            />
                        </span>
                        <a
                            className="attachment-button-edit"
                            href=""
                            onClick={(e) => { e.preventDefault(); this.setState({edit: true})}}
                        >
                            <i className="fa fa-pencil" />
                        </a>
                    </div>
                    {
                        this.state.edit &&
                        <Dialog variant="large">
                            { this.renderAttachmentEdit({isEditable, attachment, t, idx}) }
                        </Dialog>
                    }
                    </div>
                }
            </Card>
        );
    }
}

export default Attachment;