import React from 'react';


const LoadingIndicator = (props) => {
    const defaultStyles = {
        display: props.visible ? 'inline' : 'none',
        margin: props.big ? '1em' : '0',
        fontSize: props.big ? '1.5em' : '1em',
        paddingRight: '3px'
    };

    const styles = props.styles ? props.styles : defaultStyles;
    return (
        <span style={styles}>
            <i className="fa fa-spinner fa-spin" />
        </span>
    );
};
export default LoadingIndicator;
