import React from 'react';

import translations from 'decorators/translations.js';

import {
    SFInputField,
    SFSelectField,
    SFCheckBox,
    SFRadioFields,
    Button,
    Header
} from 'components/index';

import AHUTypeSelection from '../AHUTypeSelection/AHUTypeSelection';

import _ from 'lodash';

import SensorSystemSelection from './SensorSystemSelection';

import './SensorForm.css';

class SensorForm extends React.Component {
    state = {showAllDisciplines: 0};

    componentWillMount() {
        if (this.props.sensor) {
            this.setState({
                ...this.props.sensor
            })
        }
    }

    setValue = (key, value) => {
        if (value !== null) {
            this.setState((state) => {
                state[key] = value;
                return {
                    ...state
                }
            });
        }
    };
    clearType = () => {
        //Reser sensor type specific values
        this.setState((state) => ({
            ...state,
            temperatureLimits: null,
            mainSensor: null,
            areaCovered: null
        }));
    };
    submit = () => {
        const values = this.state;
        this.props.submit(values);
        if (this.props.isNew) {
            if (this.props.lastStep) {
                this.props.onClose();
            } else {
                this.props.nextStep();
            }
        }
    };

    setLimits = (type, value) => {
        const temperatureLimits = this.state.temperatureLimits || {};
        temperatureLimits[type] = value;
        this.setState((state) => ({...state, temperatureLimits}));
    };

    toggleShowAllDisciplines = (event) => {
        const value = parseInt(event.target.value, 10);
        this.setState((state) => ({
            ...state,
            showAllDisciplines: value,
            disciplineCategory: null,
            disciplineType: null,
            systemId: null
        }))
    }

    render() {
        const {t} = this.props;

        const disciplines = this.state.showAllDisciplines ? this.props.disciplines : this.props.agreementDisciplines;
        return (
            <div className="sensor-form-wrapper">
                {!this.props.isNew &&
                    <Header title={t('Edit sensor')}/>
                }
                <h3>{t('sensor_type_' + this.state.type)}</h3>

                <SFInputField
                    id="SensorForm_description"
                    defaultInput={true}
                    fieldName={this.props.t('description_sensor')}
                    onChange={(event) => this.setValue('description', event.target.value)}
                    value={this.state.description}
                />
                {
                    ['ventilation', 'electricity'].indexOf(this.state.type) !== -1 &&
                        <div>
                            <div className="FlexContainer sensor-discipline-selection">
                                <div className="FlexChild">
                                    <SensorSystemSelection
                                        options={this.props.systemsByDiscipline ?
                                            _.filter(this.props.systemsByDiscipline[this.state.type],
                                                (system => system.available || system.id === this.state.disciplineSystemId)
                                            ) :
                                            []
                                        }
                                        value={this.state.disciplineSystemId}
                                        onChange={(event) => this.setValue('disciplineSystemId', event.target.value)}
                                        disabled={false}
                                        agreement={this.props.agreement}
                                        label={t('system_id')}
                                        t={t}
                                    />
                                </div>
                            </div>
                        </div>
                }
                {
                    (() => {
                        switch (this.state.type) {
                            case 'ventilation':
                                return (
                                    <div>
                                        <AHUTypeSelection
                                            types={this.props.ahuTypes}
                                            setValue={this.setValue}
                                            ahuType={this.state.ahuType}
                                            ahuSpeed={this.state.ahuSpeed}
                                        />
                                        <SFInputField
                                            type="number"
                                            id="SensorForm_servingArea"
                                            defaultInput={true}
                                            fieldName={t('Serving area') + ' (m²)'}
                                            value={this.state.areaCovered}
                                            onChange={(event) => this.setValue('areaCovered', event.target.value)}
                                        />
                                    </div>
                                );

                            case 'electricity':
                                return (
                                    <div>
                                        <SFCheckBox
                                            id="SensortForm_mainSensor"
                                            defaultInput={true}
                                            value={this.state.mainSensor || false}
                                            onChange={(val) => this.setValue('mainSensor', val)}
                                            rightLabel={t('Is main sensor')}
                                        />
                                        <SFInputField
                                            id="SensorForm_areaCovered"
                                            defaultInput={true}
                                            fieldName={t('Area Covered') + ' (m²)'}
                                            value={this.state.areaCovered}
                                            onChange={(event) => this.setValue('areaCovered', event.target.value)}
                                        />
                                    </div>
                                );

                            case 'temperature':
                                return (
                                    <div>
                                        <div className="FlexContainer">
                                            <div className="FlexChild">
                                                <SFInputField
                                                    id="SensorForm_temperatureMin"
                                                    defaultInput={true}
                                                    fieldName={t('Min temperature')}
                                                    value={this.state.temperatureLimits && this.state.temperatureLimits.min ? this.state.temperatureLimits.min : ''}
                                                    onChange={(event) => this.setLimits('min', event.target.value)}
                                                />
                                            </div>
                                            <div className="FlexChild">
                                                <SFInputField
                                                    id="SensorForm_temperatureMax"
                                                    defaultInput={true}
                                                    fieldName={t('Max temperature')}
                                                    value={this.state.temperatureLimits && this.state.temperatureLimits.max ? this.state.temperatureLimits.max : ''}
                                                    onChange={(event) => this.setLimits('max', event.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            default:
                                return;
                        }
                    })()

                }
                <div className="sensor-actions">
                    {((!this.props.firstStep && this.props.prevStep) || !this.props.isNew) &&
                        <Button
                            id="SensorForm_cancel"
                            variant="link"
                            onClick={this.props.prevStep || this.props.cancel}
                        >
                            {this.props.isNew ? t('Back') : t('close')}
                        </Button>
                    }
                    <Button
                        id="SensorForm_save"
                        onClick={this.submit}
                        variant={this.props.lastStep ? "primary" : "secondary"}
                    >
                        { (this.props.nextStep || this.props.prevStep)
                            ? this.props.lastStep ? t('Save and close') : t('Save and next')
                            : t('save')
                        }
                    </Button>
                </div>
            </div>
        );

    }
}

export default translations(SensorForm);