import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium from 'configuredRadium.js';
import {RichTextView} from 'components/index';

import {DisciplineIcon} from 'components';

import {
    commonStyles,
    layout,
    colors,
    fonts,
    size,
    measures
} from 'styles/design.js';

const disciplineNameStyle = {
    ...fonts.sanches,
    ...size.zeta,
    ...layout.inline,
    paddingLeft: measures.standard,
    paddingRight: measures.standard
};

const disciplineIconStyle = {
    margin: measures.standard,
    maxWidth: '50px'
};

const formTitleStyle = {
    fontSize: size.normal.fontSize,
    fontFamily: fonts.arial.fontFamily
};

const infoDivStyle = {
    padding: '1em 1em 1em 0'
};

const dataDivStyle = {
    padding: '1em'
};

const dataFloatRightDiv = {
    float: 'right'
};

class DisciplineTypeInformation extends Component {
    static propTypes = {
        disciplineConfig: PropTypes.object.isRequired
    };

    render() {
        const {
            t,
            disciplineConfig
        } = this.props;

        const terms = disciplineConfig.terms || {};

        return (
            <div>
                <DisciplineIcon style={disciplineIconStyle} diciplineType={disciplineConfig.disciplineType}/>

                <div style={[layout.floatRight, layout.inlineBlock]}>
                    <button
                        type="button"
                        onClick={this.props.editClicked}
                        style={[commonStyles.button, {backgroundColor: colors.lightBlue}]}>
                        {t('edit')}
                    </button>
                </div>

                <h2 style={disciplineNameStyle}>
                    {t(disciplineConfig.disciplineType)}
                </h2>

                <div style={{marginTop: '1em', marginLeft: '50px', paddingLeft: '1em'}}>
                    <div style={infoDivStyle}>
                        <div style={infoDivStyle}>
                            <div style={dataFloatRightDiv}>
                                {(disciplineConfig.projectMargin ? disciplineConfig.projectMargin : 0)}
                            </div>

                            <p>{t('project_margin')} %</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translations(configuredRadium(DisciplineTypeInformation));
