import React, {Component} from 'react';

import {
  StandardPage
} from 'components';

class NotFound extends Component {
    render() {
        return (
            <StandardPage>
                <h1>Doh! 404!</h1>
                <p>These are <em>not</em> the droids you are looking for!</p>
            </StandardPage>
        );
    }
}

export default NotFound;