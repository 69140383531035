import React, { Component } from 'react';
import PropTypes from 'prop-types';
import translations         from 'decorators/translations.js';
import configuredRadium     from 'configuredRadium.js';
import _                    from 'lodash';
import { Link }             from 'react-router-dom';

import {
    DisciplineIcon,
} from 'components';
import {
    fonts,
    size,
    layout,
    measures,
    commonStyles,
    colors
} from 'styles/design.js';

const disciplineNameStyle = [
    fonts.sanches,
    size.zeta,
    layout.inline,
    {
        paddingLeft: measures.standard,
        paddingRight: measures.quarter
    }
];

const disciplineIconStyle = [
    {margin: measures.standard}
];

const subDiciplineItemStyle = {
    display: 'block',
    backgroundColor: colors.lightGray,
    margin: measures.half,
    padding: measures.standard
};

class DisciplineSelectionList extends Component {

    static propTypes = {
        contractInfoId: PropTypes.number.isRequired,
        buildingId: PropTypes.number.isRequired,
        clientId: PropTypes.number.isRequired
    };

    static defaultProps() {
        return {
            disabled: [],
            existing: []
        };
    }

    componentWillMount() {
        this.getGroupedDisciplines();
    }

    getGroupedDisciplines() {
        const groupedDisciplines = _.groupBy(this.props.disciplines, 'disciplineCategory');
        this.setState({groupedDisciplines: groupedDisciplines});

    }

    disciplineName(discipline) {
        const amount = discipline.disciplineSystems ? discipline.disciplineSystems.length : 0;
        if (discipline.id) {
            return `${this.props.t(discipline.disciplineType)}, ${this.props.t('systems')}: ${amount}`;
        }
        return this.props.t(discipline.disciplineType);
    }

    toggleGroup = (key) => {
        const dis = this.state.groupedDisciplines;
        dis[key].visible = !this.state.groupedDisciplines[key].visible;
        this.setState({groupedDisciplines: dis});
    }

    toggleAll = () => {
        const val = !!this.refs.showAll.checked;
        const dis = this.state.groupedDisciplines;

        _.forEach(dis, (value, key) => {
            dis[key].visible = val;
        });

        this.setState({groupedDisciplines: dis});
    }

    subdisciplineCount(subdisciplines, existing) {
        let i = '';
        subdisciplines.map((discipline) => {
            const existingDiscipline = _.find(existing, (dis) => { return dis.disciplineType === discipline.disciplineType && dis.disciplineCategory === discipline.disciplineCategory; });
            if(existingDiscipline) {
                i += this.props.t(existingDiscipline.disciplineType) + ', ';
                //i += parseInt(existingDiscipline.systems.length, 10);
            }
        });
        if(i !== '') {
            return '(' + i.substring(0, i.length - 2) + ')';
        }
        return '';

    }

    getLink(params) {
        const {contractInfoId, clientId, buildingId, disciplineCategory, disciplineType, existingDiscipline} = params;
        let link = '/client/' + clientId + '/contractInfo/' + contractInfoId + '/building/' + buildingId + '/disciplines/add/' + disciplineCategory + '/' + disciplineType;
        if (existingDiscipline && existingDiscipline.id) {
            link = '/client/' + clientId + '/contractInfo/' + contractInfoId + '/building/' + buildingId + '/discipline/' + existingDiscipline.id;
        }
        return link;
    }

    render() {
        const { t, existing, params, countryId } = this.props;
        const groupedDisciplines = this.state.groupedDisciplines;
        return (
                <div>
                    <div style = { [ layout.floatRight, {marginBottom: '-20px'}] }>
                        <label style = {{cursor: 'pointer'}} ><input onClick={ this.toggleAll } type="checkbox" ref="showAll" /> {t('show_all')}</label>
                    </div>
                { (() => {
                    if (groupedDisciplines) {
                        return _.keys(groupedDisciplines).map((disciplineGroupKey) => {
                            return (
                            <div
                                key={ 'discipline-selection-' + disciplineGroupKey }
                                style = { [ commonStyles.bottomDivider, { marginBottom: measures.standard, marginTop: measures.standard, cursor: 'pointer' } ] }
                            >
                                <div
                                    onClick={ () => this.toggleGroup(disciplineGroupKey) }
                                    style = { layout.verticalMiddle }
                                >
                                    <div style = { [layout.floatLeft, {width: '50px'}] }>
                                        <DisciplineIcon style = { disciplineIconStyle } diciplineType = { disciplineGroupKey } />
                                    </div>
                                    <div style = { [{fontSize: '1.3em', paddingTop:'12px', paddingBottom: '12px' }]}>
                                        <h2 id={'DisciplineSelectionList_' + disciplineGroupKey} style = { disciplineNameStyle }>{ t(disciplineGroupKey) } </h2>
                                    </div>
                                </div>
                                <div style = { [ (groupedDisciplines[disciplineGroupKey].visible ? {display: 'block'} : {display: 'none'}), { marginLeft: measures.quadruple, marginTop: measures.standard } ] }>
                                    {
                                        (() => {
                                            return groupedDisciplines[disciplineGroupKey].map((availableDiscipline) => {
                                                const existingDiscipline = _.find(existing, (dis) => {
                                                    return dis.disciplineType === availableDiscipline.disciplineType && dis.disciplineCategory === availableDiscipline.disciplineCategory;
                                                });
                                                if (existingDiscipline) {
                                                    return (
                                                        <Link
                                                            id={'DisciplineSelectionList_' + disciplineGroupKey + '_' + availableDiscipline.disciplineType }
                                                            key={ 'subdiscipline-' + disciplineGroupKey + '-' + availableDiscipline.disciplineType }
                                                            to={ this.getLink({
                                                                    contractInfoId: params.contractInfoId,
                                                                    clientId: params.clientId,
                                                                    buildingId: params.buildingId,
                                                                    disciplineCategory: existingDiscipline.disciplineCategory,
                                                                    disciplineType: existingDiscipline.disciplineType,
                                                                    countryId,
                                                                    existingDiscipline
                                                            }) }
                                                            style={ subDiciplineItemStyle }
                                                        >
                                                            { this.disciplineName(existingDiscipline) }
                                                        </Link>
                                                    );
                                                }
                                                return (
                                                    <Link
                                                        id={'DisciplineSelectionList_' + disciplineGroupKey + '_' + availableDiscipline.disciplineType }
                                                        key={ 'subdiscipline-' + disciplineGroupKey + '-' + availableDiscipline.disciplineType }
                                                        to={ this.getLink({
                                                            contractInfoId: params.contractInfoId,
                                                            clientId: params.clientId,
                                                            buildingId: params.buildingId,
                                                            countryId,
                                                            disciplineCategory: availableDiscipline.disciplineCategory,
                                                            disciplineType: availableDiscipline.disciplineType
                                                        }) }
                                                        style={ subDiciplineItemStyle }
                                                    >
                                                        { this.disciplineName(availableDiscipline) }
                                                    </Link>
                                                );
                                            });
                                        })()
                                    }
                                </div>
                            </div>
                            );
                        });
                    }
                    return (<div>{ t('no_disciplines_available_for_adding') }</div>);
                })()}
            </div>
        );
    }
}

export default translations(configuredRadium(DisciplineSelectionList));
