import { Component, Children } from 'react';
import PropTypes from 'prop-types';

class TranslateProvider extends Component {
    constructor(props, context) {
        super(props, context);
        this.t = props.t;
        if (props.initialLanguage) {
            this.changeLanguage(props.initialLanguage);
        }
    }

    getChildContext() {
        return { t: this.t };
    }

    render() {
        const { children } = this.props;
        return Children.only(children);
    }
}

TranslateProvider.propTypes = {
    t: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired
};

TranslateProvider.childContextTypes = {
    t: PropTypes.func.isRequired
};

export default TranslateProvider;