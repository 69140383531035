import React from 'react';

import _ from 'lodash';
import {
    SFSelectField,
    Button
} from 'components';

class FrameAgreementAvailableDisciplineSelection extends React.Component {
    state = {};
    selectDiscipline = (event) => {
        const value = event.target.value;
        this.setState({discipline: value});
    };
    addDiscipline = () => {
        if (this.state.discipline) {
            this.props.addDiscipline(this.state.discipline);
        }
    }

    render() {
        const {t, country, disciplines} = this.props;
        const countryDisciplines = _.get(country, 'frameAgreementConfig.disciplines', []);
        const availableDisciplines = _.filter(countryDisciplines, (availableDiscipline) => !_.find(disciplines, {disciplineCategory: availableDiscipline.disciplineCategory}));
        if (disciplines && countryDisciplines.length > 0 && availableDisciplines.length > 0) {
            return (
                <div className="ContentWrapper">
                    <h3>{t('add_discipline')}</h3>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'baseline'}}>
                        <div>
                            <SFSelectField
                                id="FrameAgreementAvailableDisciplineSelection_discipline"
                                label={t('select_discipline')}
                                defaultInput={true}
                                value={this.state.discipline}
                                onChange={this.selectDiscipline}>
                                <option value="">{t('select')}</option>
                                {

                                    availableDisciplines.map((discipline, idx) =>
                                        <option id={'discipline_' + idx} value={discipline.disciplineCategory}>
                                            {t(discipline.disciplineCategory)}
                                        </option>)
                                }
                            </SFSelectField>
                        </div>
                        <div style={{marginLeft: '1em'}}>
                            <Button
                                id="FrameAgreementAvailableDisciplineSelection_add"
                                variant="add"
                                onClick={this.addDiscipline}
                            >
                                {t('Add')}
                            </Button>
                        </div>
                    </div>

                    <div>
                        <Button
                            id="FrameAgreementAvailableDisciplineSelection_addAll"
                            onClick={this.props.addAllDisciplines}
                        >
                            {t('Add all disciplines')}
                        </Button>
                    </div>
                </div>
            );
        }
        return <div/>;
    }
}

export default FrameAgreementAvailableDisciplineSelection;