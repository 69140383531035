import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import configuredRadium     from 'configuredRadium.js';
import { layout, measures }     from 'styles/design.js';

import {
    AgreementStatusInformation,
    SFCheckBox,
    PrintButton,
    SFRadioField,
    SaveButton,
    SFTextareaField
} from 'components';

class ApprovePage extends Component {

    toggleCheckbox = () => {
        this.setState({checkboxSelected: !this.state.checkboxSelected});
    };
    toggleApprove = (e) => {
        this.setState({approving: e.target.value});
    };
    submit = () => {
        this.props.handleSubmit({approving: (this.state.approving === 'approve'), comment: this.state.comment});
    };
    commentChange = (e) => {
        this.setState({comment: e.target.value});
    };
    render() {
        const {t, agreement} = this.props;
        if(!agreement) {
            return (<div>{t('invalid_agreement')}</div>);
        }
        const prop = {
            onChange: this.toggleCheckbox,
            value: this.state.checkboxSelected
        };
        const approveProp = {
            value: this.state.approving
        };
        const comment = {
            value: this.state.comment,
            onChange: this.commentChange
        };
        return (
            <div>
                <div style={[layout.floatRight]}>
                    <PrintButton
                        download={(event) => this.props.downloadFile(event, 'preview', (this.props.type === 'frameAgreement' ? 'frameagreement' : 'agreement'), agreement.id, null, this.props.language)}
                        loading={this.props.loadingFile}
                        label={t('preview')}
                    />
                </div>
                <AgreementStatusInformation agreement={agreement} />
                <div style={{marginTop: measures.double}}>
                {
                    (() => {
                        if(agreement.agreementStatus === 'sent_for_approval') {
                            return (
                                <div>
                                    <div style={{marginLeft: '5em', fontSize: '0.75em'}}>
                                        <SFCheckBox
                                            formProperty={prop}
                                            rightLabel={t('agreement_reviewed_confirmation')}
                                        />
                                    </div>
                                    <div style={{ marginLeft: measures.quadruple, marginTop: measures.double }}>
                                        <div style={[layout.inlineBlock]}>
                                            <SFRadioField name="approveStatus"
                                                          disabled={!this.state.checkboxSelected}
                                                          label={t('approve')}
                                                          onUpdate={this.toggleApprove}
                                                          checked={this.state.approving === 'approve'}
                                                          value={'approve'}
                                                          formProperty={approveProp}/>
                                        </div>
                                        <div style={[layout.inlineBlock, { marginLeft: measures.double }]}>
                                            <SFRadioField name="approveStatus"
                                                          disabled={!this.state.checkboxSelected}
                                                          label={t('reject')}
                                                          onUpdate={this.toggleApprove}
                                                          checked={this.state.approving === 'reject'}
                                                          value={'reject'}
                                                          formProperty={approveProp}/>
                                        </div>
                                    </div>
                                    <div style={[this.state.approving === 'approve' || this.state.approving === 'reject' ? layout.block : layout.none, {marginLeft: measures.quadruple}]}>
                                        <SFTextareaField
                                            fieldName={t('comment')}
                                            formProperty={comment}
                                        />
                                    </div>
                                    <div>
                                        <div style={[layout.floatRight, {marginTop: measures.triple, display: this.state.approving && this.state.checkboxSelected ? 'inline' : 'none'}]}>
                                            <SaveButton
                                                onClick={ this.submit }
                                                disabled={ this.props.submitting }
                                                submitting={ this.props.submitting }
                                                label={t('confirm_approval')}
                                                style={{width: '15em'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                        return (
                            <div>
                                {t('agreement_not_pending_approval')}
                            </div>
                        );
                    })()
                }
                </div>
            </div>
        );
    }
}

export default translations(configuredRadium(ApprovePage));
