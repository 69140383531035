import React        from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';
import { Link }                     from 'react-router-dom';

import {
    colors,
    measures
} from 'styles/design.js';

const navigationItemStyle = [
    {
        position: 'relative',
        left: 0,
        lineHeight: '2',
        fontSize: '150%',
        cursor: 'pointer',
        transitionProperty: 'transform, opacity, left',
        transitionDuration: '0.4s',
        transitionDelay: '0.15s, 0.15s, 0s',
        transitionTimingFunction: 'ease',
        ':hover': {
            left: '6px'
        }
    }

];

const navigationIcon = [
    {
        position: 'relative',
        display: 'inline-block',
        minWidth: '2.25em',
        paddingRight: measures.half,
        paddingLeft: measures.half,
        zIndex: 11002,
        color: colors.white
    }
];

const navigationLink = [
    {
        position: 'relative',
        display: 'inline-block',
        transition: 'margin 0.175s',
        zIndex: 10001,
        color: colors.white,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        maxWidth: '350px',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        fontFamily: 'Sanchez-Regular, sans-serif',
    }
];

const NavigationItem = (props) => {
    return (
        <Link to={props.to} onClick = { props.onClick } id={props.id}>
            <div style = { [ navigationItemStyle, props.openMenu ? { opacity: 1, transform: 'translateX(0)' } : { opacity: 0, transform: 'translateX(40px)' } ] }>
                <span style = { navigationIcon }><i className= { 'fa ' + props.icon } /></span>
                <span style = { [ navigationLink, props.openMenu ? { marginLeft: 0 } : { marginLeft: 0 } ]} href= { props.url }>{ props.text }</span>
            </div>
        </Link>
    );
};

export default translations(configuredRadium(NavigationItem));
