import React from 'react';

import _ from 'lodash';

import {
    SFInputField,
    Button,
    SFTextareaField,
    DepartmentSelection,
    SFSelectField,
    LoadingIndicator
} from 'components';

import {
    measures
} from 'styles/design';

class FrameAgreementDetailsForm extends React.Component {

    state = {form: {}, errors: {}};

    componentWillMount() {
        const {
            agreement,
            contractInfo
        } = this.props;
        const form = {};
        const fields = ['name', 'printoutName', 'defaultDiscount', 'description', 'departmentId', 'paymentTerm', 'language'];
        if (!_.isEmpty(agreement)) {
            _.each(fields, (field) => form[field] = agreement[field]);
        }
        else if (contractInfo && contractInfo.departmentId) {
            form.departmentId = contractInfo.departmentId;
        }
        this.setState((state) => ({...state, form}));
    }

    changeValue = (value, key) => {
        const form = this.state.form;
        const errors = this.state.errors;
        form[key] = value;
        if(value !== '') {
            errors[key] = null;
        }
        this.setState((state) => ({...state, form, errors}));
    };

    submit = () => {
        const form = this.state.form;
        const errors = {};
        let isError = false;
        if(!form.name || form.name === '') {
            errors.name = this.props.t('required');
            isError = true;
        }
        if(form.defaultDiscount == null || form.defaultDiscount === '') {
            errors.defaultDiscount = this.props.t('required');
            isError=true;
        }
        if(isError) {
            this.setState((state) => ({...state, errors}));
        } else {
            this.props.submitAgreement(this.state.form);
        }
    };

    render() {
        const {t, paymentTermOptions} = this.props;
        return (
            <div>
                <div style={{marginTop: measures.half, marginBottom: measures.half}}>
                    {
                        this.props.departmentsLoading &&
                        <LoadingIndicator big={true} visible={true}/>
                    }
                    {
                        !this.props.departmentsLoading &&
                        <DepartmentSelection
                            departments={this.props.departments}
                            value={this.state.form.departmentId}
                            onChange={(event) => this.changeValue(event.target.value, 'departmentId')}
                            t={t}
                            id="FrameAgreementDetailsForm_DepartmentId"
                        />
                    }

                </div>
                <SFSelectField
                    defaultInput={true}
                    id="FrameAgreementDetailsForm_paymentTerm"
                    value={this.state.form.paymentTerm}
                    onChange={(event) => this.changeValue(event.target.value, 'paymentTerm')}
                    fieldName={t('payment_term')}
                >
                    {paymentTermOptions.map((option) =>
                        <option key={option} value={option}>{option} {t('days')}</option>)}
                </SFSelectField>

                <SFInputField
                    defaultInput={true}
                    id="FrameAgreementDetailsForm_name"
                    error={this.state.errors.name}
                    value={this.state.form.name}
                    onChange={(event) => this.changeValue(event.target.value, 'name')}
                    fieldName={t('name')}
                />
                <SFInputField
                    id="FrameAgreementDetailsForm_printoutName"
                    defaultInput={true}
                    value={this.state.form.printoutName}
                    onChange={(event) => this.changeValue(event.target.value, 'printoutName')}
                    fieldName={t('printout_name')}
                />
                <p>{t('printed_on_agreement_frontpage')}</p>

                <SFTextareaField
                    defaultInput={true}
                    id="FrameAgreementDetailsForm_description"
                    value={this.state.form.description}
                    onChange={(event) => this.changeValue(event.target.value, 'description')}
                    fieldName={t('description')}
                />
                <SFInputField
                    defaultInput={true}
                    id="FrameAgreementDetailsForm_defaultDiscount"
                    error={this.state.errors.defaultDiscount}
                    value={this.state.form.defaultDiscount}
                    type="number"
                    min={0}
                    max={100}
                    onChange={(event) => this.changeValue(event.target.value, 'defaultDiscount')}
                    fieldName={t('default_discount')}
                />
                <SFSelectField
                    defaultInput={true}
                    id="FrameAgreementDetailsForm_language"
                    error={ this.state.errors.language }
                    value={ this.state.form.language }
                    fieldName={ t('Language') }
                    onChange={ e => this.changeValue(e.target.value, 'language')}
                >
                    <option value={null}>{t('select')}</option>
                    <option value={this.props.selectedCountry.defaultLanguage}>{ t(this.props.selectedCountry.defaultLanguage) }</option>
                    { this.props.selectedCountry.defaultLanguage !== 'en' && <option value="en">{t('en')}</option> }
                </SFSelectField>

                <div>

                    <Button
                        variant="link"
                        onClick={this.props.cancelEdit}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        id="FrameAgreementDetailsForm_submit"
                        onClick={this.submit}
                        disabled={this.props.saving}
                        submitting={this.props.saving}
                    >
                        {t('save')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default FrameAgreementDetailsForm;