import React from 'react';

import {
    Button,
    ConfirmableButton,
    RichTextEditor,
    LoadingIndicator
} from 'components';

import './AgreementContents.css';

class ContentRowForm extends React.Component {
    state = {};
    componentDidMount() {
        this.init();
    }
    init = () => {
        this.setState( state => ({ ...state, content: (this.props.editing ? this.props.content : '' )}));
    }
    submit = () => {
        this.props.submit(this.state.content);
    }
    delete = () => {
        this.props.delete(this.props.section);
    }
    changeField = (key, value) => {
        this.setState( state => ({...state, content: value}));
    }
    render() {
        const { t, section, cancel, editing } = this.props;
        if(this.state.content === null || this.state.content === undefined) {
            return <LoadingIndicator visible={true} />
        }
        return (
            <div className="agreement-contents-row">
                <div>
                    <p className="agreement-contents-row-title">
                        <i className="fa fa-2x fa-file-text-o" /> {section}: {this.props.header}
                    </p>
                    <RichTextEditor
                        onChange={this.changeField}
                        value={this.state.content}
                        label={t('mainagreement_s' + section + '_header')}
                    />
                </div>
                {
                    this.props.originalContent &&
                    <div className="agreement-original-content">
                        <p><b>{t('Original content')}:</b></p>
                        { this.props.originalContent.map( row => <p>{row}</p>) }
                    </div>
                }
                <div className="agreement-contents-edit-content-buttons">
                    {editing &&
                        <div className="agreement-content-edit-content-buttons-secondary">
                            <ConfirmableButton
                                variant="delete"
                                action={this.delete}
                                confirmButtonText={ t('yes') }
                                confirmText={ t('are_you_sure') }
                                buttonText={t('delete')}
                            />
                        </div>
                    }
                    <div className="agreement-content-edit-content-buttons-primary">
                        <Button variant="link" onClick={cancel}>{t('cancel')}</Button>
                        <Button variant="primary" onClick={this.submit}>{t('save')}</Button>
                    </div>
                </div>
            </div>
        );
    }
}


export default ContentRowForm;