import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Select from 'react-select';
import 'react-select/dist/react-select.css';

import {
    CancelButton,
    Dialog,
    Button,
    CopyAgreementFilters,
} from 'components';

import {
    layout,
} from 'styles/design.js';

const buttonContainerStyle = {
    display: 'block'
};
const descriptionContainerStyle = {
    display: 'block',
    textAlign: 'left',
};

const containerStyle = {
    padding: '0',
};

const sectionStyle = {
    margin: '2.5em 0'
};

class CopyAgreementComponent extends Component {
    static propTypes = {
        contractInfos: PropTypes.array.isRequired,
        agreement: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        const { agreementCountry } = this.props;
        this.state = {
            selectedSalesArea: {
                value: agreementCountry,
                label: props.t(agreementCountry)
            }
        };
    }

    componentDidMount() {
        const {
            loadContractInfos,
            loadCountryDepartments,
            agreementCountry
        } = this.props;
        loadContractInfos({ salesArea: agreementCountry });
        loadCountryDepartments(agreementCountry);
    }

    toggleCopyAgreement = () => {
        this.setState({ showCopyAgreementModal: !this.state.showCopyAgreementModal });
    }
    copyAgreement = () => {
        const { selectedContractInfo } = this.state;
        if (selectedContractInfo && !_.isNil(selectedContractInfo.value)) {
            this.setState({ submitting: true });
            this.props.copyAgreement(this.state.selectedContractInfo.value);
        }
    }
    componentWillReceiveProps(nextProps) {
        if (_.isEmpty(this.props.error) && nextProps.error) {
            this.setState({ submitting: false });
        }
    }
    cancel = () => {
        this.setState({ showCopyAgreementModal: false, targetContractInfoId: null });
    };

    handleClientChange = selectedClient => {
        this.setState({
            selectedClient,
            selectedContractInfo: null
        });
    };

    handleContractInfoChange = selectedContractInfo => {
        this.setState({ selectedContractInfo });
    };

    handleFilterChange = (filter, selected) => {
        const {
            loadCountryDepartments,
            loadContractInfos,
        } = this.props;
        const {
            selectedDepartment,
            selectedSalesArea,
            selectedPerson
        } = this.state;
        const query = {};
        if (filter === 'selectedSalesArea' && !_.isNil(selected)) {
            loadCountryDepartments(selected.value);
            _.set(query, 'salesArea', selected.value);
            if (selectedPerson) {
                _.set(query, 'createdBy', selectedPerson.value);
            }
        }
        else if (filter === 'selectedDepartment') {
            const value = !_.isNil(selected) ? selected.value : null;
            _.set(query, 'salesArea', selectedSalesArea.value);
            if (value) {
                _.set(query, 'department', value);
            }
            if (selectedPerson) {
                _.set(query, 'createdBy', selectedPerson.value);
            }
        }
        else if (filter === 'selectedPerson') {
            const value = !_.isNil(selected) ? selected.value : null;
            _.set(query, 'salesArea', selectedSalesArea.value);
            if (selectedDepartment) {
                _.set(query, 'department', selectedDepartment.value);
            }
            if (value) {
                _.set(query, 'createdBy', value);
            }
        }
        if (!_.isEmpty(query)) {
            loadContractInfos(query);
        }
        this.setState({
            [filter]: selected,
            selectedClient: null,
            selectedContractInfo: null
        });
    };

    resetFilter = filter => {
        this.setState({
            [filter]: null
        });
    }

    getContractInfoLabel = cinfo => {
        const { t, agreement } = this.props;
        const departmentName = _.get(cinfo, 'department.name');
        return `${t(cinfo.salesArea)}${departmentName ? `: ${departmentName}` : ''}${ agreement.contractInfoId === cinfo.id ? ` (${t('current')})` : '' }, id: ${cinfo.id}`;
    };

    render() {
        const {
            t,
            agreement,
            countries,
            userProfile,
            departments,
            contractInfos,
        } = this.props;
        const {
            selectedClient,
            selectedContractInfo,
            selectedDepartment,
            selectedSalesArea,
            selectedPerson,
            myClients
        } = this.state;
        const userCountries = countries && countries.countries;
        const uniqClients = contractInfos && contractInfos.length > 0
            ? _.sortBy(_.map(_.uniqBy(contractInfos, 'clientId'), cinfo => cinfo.client), 'name')
            : [];
        const clientOptions = uniqClients && uniqClients.length > 0
            ? _.map(uniqClients, client => ({
                value: client.id,
                label: `${client.name}, id: ${client.id}`
            }))
            : [];
        let contractInfoOptions = [];
        if (selectedClient) {
            const filteredOptions = _.filter(contractInfos, cinfo => cinfo.clientId === selectedClient.value && (userProfile.isAdmin || cinfo.createdBy === userProfile.email));
            contractInfoOptions = _.map(filteredOptions, cinfo => ({
                value: cinfo.id,
                label: this.getContractInfoLabel(cinfo, agreement)
            }));
        }
        const departmentOptions = departments && departments.countryDepartments && departments.countryDepartments.length > 0
            ? _.map(departments.countryDepartments, dep => ({
                value: dep.id,
                label: dep.name
            }))
            : [];

        return (
            <div style={containerStyle}>
                <div style={this.state.showCopyAgreementModal ? {display: 'block', width: '100%'} : {display: 'none'}}>
                    <Dialog size="xl" style={{ minHeight: '80vh' }}>
                        <div style={descriptionContainerStyle}>
                            <div style={{fontSize: '1.25rem', fontWeight: 'bold'}}>{t('Target contract info')}</div>
                            <div style={sectionStyle}>
                                <p style={{fontSize: '1rem', marginBottom: '0.5em'}}>
                                    1. { t('Filter clients') }
                                </p>
                                <CopyAgreementFilters
                                    handleFilterChange={ this.handleFilterChange }
                                    countries={ userCountries }
                                    selectedSalesArea={ selectedSalesArea }
                                    selectedDepartment={ selectedDepartment }
                                    selectedPerson={ selectedPerson }
                                    myClients={ myClients }
                                    userProfile={ userProfile }
                                    t={ t }
                                    departmentOptions={ departmentOptions }
                                    resetFilter={ this.resetFilter }
                                />
                            </div>
                            <div style={sectionStyle}>
                                <p style={{fontSize: '1rem', marginBottom: '0.5em'}}>
                                    2. { t('Select client') }
                                </p>
                                <Select
                                    options={ clientOptions }
                                    searchable
                                    value={ selectedClient }
                                    onChange={ this.handleClientChange }
                                    clearable={ false }
                                />
                            </div>
                            <div style={sectionStyle}>
                                <p style={{fontSize: '1rem', marginBottom: '0.5em'}}>
                                    3. { t('Select target contract info') }
                                </p>
                                <Select
                                    disabled={ _.isNil(selectedClient) }
                                    clearable={ false }
                                    searchable={ false }
                                    options={ contractInfoOptions }
                                    value={ selectedContractInfo }
                                    onChange={ this.handleContractInfoChange }
                                />
                            </div>
                        </div>
                        <div style={{...buttonContainerStyle, textAlign: 'right', fontSize: '1rem'}}>
                            <span style={layout.inlineBlock}>
                            <CancelButton
                                cancelAction={this.cancel}
                                disabled={this.state.submitting}
                                submitting={this.state.submitting}
                            />
                            </span>
                                <span style={{...layout.inlineBlock, marginLeft: '1em'}}>
                                <Button
                                    onClick={this.copyAgreement}
                                    loading={this.state.submitting}
                                    disabled={this.state.submitting || !selectedContractInfo}
                                >
                                    {this.props.t('Copy')}
                                </Button>
                            </span>
                            {
                                _.get(this.props, 'error.error.message') &&
                                <div>
                                    { t('Error copying the agreement') }: { _.get(this.props, 'error.error.message') }
                                </div>
                            }
                        </div>
                    </Dialog>
                </div>

                <div style={buttonContainerStyle}>
                    <Button onClick={this.toggleCopyAgreement}>
                        {t('Copy agreement')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default translations(CopyAgreementComponent);