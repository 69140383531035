import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {
    layout,
    measures,
    colors
} from 'styles/design.js';

const countryRowStyle = {
    display: 'block',
    margin: measures.standard,
    padding: measures.standard,
    backgroundColor: colors.lightGray,
    cursor: 'pointer'
};

class CountrySelectionList extends Component {

    static propTypes = {
        countries: PropTypes.object.isRequired
    };

    render() {
        return (
            <div>

                <Link id={'CountrySelectionList_country_globals'} to={'/globaladmin/'} style={countryRowStyle}>
                    {this.props.t('Globals')}
                </Link>
                {(() => {
                    if (this.props.countries.countries.length > 0) {
                        return this.props.countries.countries.map((country) => {
                            return (
                                <Link id={'CountrySelectionList_country_' + country.id} to={'/admin/' + country.id}
                                      key={country.id} style={countryRowStyle}>
                                    {this.props.t(country.id)}
                                </Link>
                            );
                        });
                    } else if (!this.props.countries.loading) {
                        return (<div style={layout.textCenter}>{this.props.t('no_countries_available')}</div>);
                    }

                })()}
            </div>
        );
    }
}

export default translations(CountrySelectionList);