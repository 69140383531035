import React from 'react';

import {
    commonStyles
} from 'styles/design';
import {
    Button
} from 'components';
import FrameAgreementMaterial from './FrameAgreementMaterial';
import FrameAgreementServiceCarInfo from './FrameAgreementServiceCarInfo';

const FrameAgreementPriceInfo = ({country, agreement, t, toggleEdit, isEditable}) => {
    return (
        <div>
            {
                isEditable &&
                <div style={commonStyles.dataParentDiv}>
                    <Button onClick={toggleEdit}>
                        <i className="fa fa-pencil"/> {t('edit')}
                    </Button>
                </div>
            }

            <FrameAgreementServiceCarInfo
                country={country}
                agreement={agreement}
                t={t}
            />

            <div style={{
                ...commonStyles.dataParentDiv,
                display: agreement.additionalFees && agreement.additionalFees.length > 0 ? 'block' : 'none'
            }}>
                <div style={commonStyles.dataContentHeaderDiv}>
                    {t('additional_fees')}:
                </div>
                {
                    agreement.additionalFees && agreement.additionalFees.map((additionalFee, index) =>
                        <div
                            key={'additional_fee_' + index}
                            className="ValueRow"
                            id={'FrameAgreementInfo_additionalFee_' + index}
                        >
                            {additionalFee.name} {additionalFee.value} {additionalFee.valueType}
                        </div>
                    )
                }
            </div>
            <div style={commonStyles.dataParentDiv}>
                <div style={commonStyles.dataContentHeaderDiv}>
                    {t('materials')}:
                </div>
                {
                    agreement.materials && agreement.materials.length > 0
                        ? agreement.materials.map((material, index) =>
                            <FrameAgreementMaterial
                                key={'material_' + index}
                                t={t}
                                material={material}
                                index={index}/>
                        )
                        : t('none_selected')
                }
            </div>
            <div style={commonStyles.dataParentDiv}>
                <div style={commonStyles.dataContentHeaderDiv}>
                    {t('subcontracting')}:
                </div>

                {
                    (() => {
                        if (!agreement.subcontracting || agreement.subcontracting.length === 0) {
                            return (<div>{t('none_selected')}</div>);
                        }
                        return (
                            <div className="ValueRow">
                                <div className="FlexParent">
                                    <div className="FlexChild width50">
                                        {t('name')}
                                    </div>
                                    <div className="FlexChild width50">
                                        {t('minimum_margin')}
                                    </div>
                                </div>
                                {
                                    agreement.subcontracting.map((row, index) =>
                                        <div key={'Subcontracting_idx_' + index} className="FlexParent">
                                            <div className="FlexChild width50">
                                                {row.name}
                                            </div>
                                            <div className="FlexChild width50">
                                                {row.margin}
                                            </div>
                                        </div>)
                                }
                            </div>
                        );
                    })()
                }
            </div>
        </div>
    );
};

export default FrameAgreementPriceInfo;