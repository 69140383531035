import React from 'react';
import { Badge } from 'components';

import './Header.css';

/**
 * Page header including page title (h1) 
 */
const Header = (props) => {
    const {badge, title, children, ...other} = props;

    return (
        <header className={ props.divider ? 'header header-with-divider' : 'header' } {...other}>
            { props.title && (
                <h1 className="header-title">
                    { title } {badge && <Badge type={badge.type} />}
                </h1>
            )}
            { props.children }
        </header>
    );
};

export default Header;