import React                from 'react';

const SystemFormBody = ({ isEditable, notEditableBody, editableBody }) => {

    return (
        <div>
            <div className="system-form-body-content" style={ !isEditable ? { display: 'block' } : { display: 'none' }}>
                { notEditableBody }
            </div>
            <div className="system-form-body-content" style={ isEditable ? { display: 'block' } : { display: 'none' }}>
                { editableBody }
            </div>
        </div>
    );
}

export default SystemFormBody;