import React, { Component } from 'react';
import translations         from 'decorators/translations.js';
import configuredRadium     from 'configuredRadium.js';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import {
    StandardPage,
    BreadcrumbBar,
    AdminSubNavigation,
    FrameAgreementConfigForm,
    FrameAgreementConfigInformation,
    TranslationForm
} from 'components';

import {
    commonStyles,
    measures,
    colors,
    fonts,
} from 'styles/design.js';

import { loadCountry, saveCountry } from 'redux/modules/country.js';

import { loadFiles, uploadFile, deleteFile } from 'redux/modules/files.js';

import { download } from 'redux/modules/download.js';
import { loadWithType } from 'redux/modules/translations.js';

const dataParentDiv = {
    paddingTop: measures.half,
    paddingLeft: measures.half,
    paddingRight: measures.half,
    marginTop: measures.double
};

const dataContentHeaderDiv = {
    backgroundColor: colors.lightBlue,
    padding: measures.half,
    marginTop: measures.standard,
    border: '1px solid ' + colors.lightGray,
    color: '#fff',
    ...fonts.plainsanches
};

const LOCALIZABLES = [
    'frameagreement_terms_clientnet_p1',
    'frameagreement_terms_p2',
];

class FrameAgreementConfigurationView extends Component {

    componentDidMount() {
        const { dispatch, match: {params}} = this.props;
        const promises = [];
        promises.push(this.props.dispatch(loadFiles('agreementfiles', 'frameterms', this.props.profile.accessToken)));
        if(!this.props.countries.loaded || this.props.countries.selectedCountry.id !== params.countryId) {
            promises.push(dispatch(loadCountry(params.countryId, this.props.profile.accessToken)));
        }
        return Promise.all(promises).then(() => {
            if(this.props.selectedCountry) {
                dispatch(loadWithType(2, 'en,' + this.props.selectedCountry.defaultLanguage, this.props.selectedCountry.id, this.props.profile.accessToken));
            }
        });
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.translations.saving && nextProps.translations.saved) {
            this.props.dispatch(loadWithType(2, 'en,' + this.props.selectedCountry.defaultLanguage, this.props.selectedCountry.id, this.props.profile.accessToken));
        }
    }
    checkExisting(files, selectedCountry) {
        const found = {};
        _.forEach(files, function check(value) {
            if(value.name === 'terms_and_conditions_en_' + selectedCountry.id + '.pdf') {
                found.en = value;
            }
            if(value.name === 'terms_and_conditions_' + selectedCountry.defaultLanguage + '_' + selectedCountry.id + '.pdf') {
                found.local = value;
            }
        });
        return found;
    }
    editCountry(value) {
        this.setState({editCountry: value});
    }
    handleCancel() {
        this.editCountry(false);
    }

    handleConfigSave = (countryConfiguration) => {
        const conf = this.props.selectedCountry;
        conf.frameAgreementConfig = countryConfiguration;
        const dispatchSave = this.props.dispatch(saveCountry(conf, this.props.profile.accessToken));
        this.setState({isSaving: true});
        return dispatchSave.then( res => {
            if (res.result) {
                this.setState({ isSaving: false, isLoading: true });
                const countryId = this.props.match.params.countryId;
                this.props.dispatch(loadCountry(countryId, this.props.profile.accessToken)).then(() => {
                    this.editCountry(false);
                });
            }
            return res;
        })
    };

    deleteFile = (event, filename) => {
        this.setState({ deletingFile: filename });
        this.props.deleteFile(
            'agreementfiles',
            'frameterms',
            filename,
            this.props.profile.accessToken
        ).then(res => {
            if (!res.error) {
                this.setState({deletingFile: false, isLoadingFiles: true});
                this.props.dispatch(loadFiles('agreementfiles', 'frameterms', this.props.profile.accessToken))
                    .then(res => {
                        if (res.result) {
                            this.updateFilesAfterLoad();
                        }
                    })
            } else {
                this.setState({ isUploading: false, error: res.error.error });
            }
        });

        return false;
    };
    downloadFile = (event, name) => {
        event.preventDefault();
        this.props.downloadFile('faTerms', name, 'faTerms', this.props.profile.accessToken);
    };
    updateFilesAfterLoad = () => {
        const locales = [];
        this.props.files.files.map((file) => {
            let f = file.name;
            f = f.split('_');
            if(f[3].length === 2) {
                locales.push(f[3]);
            }
        });
        const conf = this.props.selectedCountry;
        if(!conf.frameAgreementConfig) {
            conf.frameAgreementConfig = {};
        }
        conf.frameAgreementConfig.attachments = locales;
        this.props.dispatch(saveCountry(conf, this.props.profile.accessToken));
        this.setState({isLoadingFiles: false});
    }
    uploadFile = (file, filename) => {
        this.setState({isUploading: true});
        return this.props.uploadFile(
            'agreementfiles',
            'frameterms',
            file,
            filename,
            this.props.profile.accessToken
        ).then( res => {
            if (!res.error) {
                this.setState({isUploading: false, isLoadingFiles: true});
                this.props.dispatch(loadFiles('agreementfiles', 'frameterms', this.props.profile.accessToken)).then(() => this.updateFilesAfterLoad());
            } else {
                this.setState({ isUploading: false, error: res.error.error });
            }
        });
    };
    render() {
        const { t, selectedCountry, translations } = this.props;
        const filesFound = this.checkExisting(this.props.files.files, selectedCountry);
        const showLoader = this.state.isUploading || this.state.deletingFile || this.state.isLoadingFiles || this.props.download.loading;

        const list = {};
        if (translations && translations.translatables && selectedCountry && selectedCountry.frameAgreementConfig) {
            LOCALIZABLES.forEach(key => {
                if (key === 'frameagreement_terms_p2') {
                    const translatable = {
                        value: selectedCountry.frameAgreementConfig.termsAndConditionsComment,
                        disabled: false,
                    };
                    list[key] = translations.translatables[key] || {};
                    if (!list[key].en || (_.isEmpty(list[key].en) && list[key].en.disabled !== false)) {
                        list[key].en = translatable;
                    }
                    if (!list[key][selectedCountry.defaultLanguage] || (_.isEmpty(list[key][selectedCountry.defaultLanguage]) && list[key][selectedCountry.defaultLanguage].disabled !== false)) {
                        list[key][selectedCountry.defaultLanguage] = translatable;
                    }
                }
                else {
                    list[key] = translations.translatables[key];
                };
            })
        }

        return (
            <StandardPage
                withBreadcrumb
                withSideNavigation
                loaderVisible={this.props.countries.loading}
            >
                <BreadcrumbBar
                    page="admin"
                    path={ this.props.match.url }
                />
                <AdminSubNavigation params={this.props.match.params} activeNavi="frameAgreementConfig" />
                <header>
                    <h1 style = { commonStyles.pageTitle } >{t('frame_agreement')} - { this.props.t(this.props.match.params.countryId) }</h1>
                </header>
                {this.state.editCountry ? <FrameAgreementConfigForm
                    countryConfig={selectedCountry}
                    cancelAction={ () => { this.editCountry(false); } }
                    showLoader={showLoader}
                    filesFound={ filesFound }
                    onSubmit={ this.handleConfigSave}
                    deleteFile = {this.deleteFile}
                    uploadFile = {this.uploadFile}
                    downloadFile = {this.downloadFile}
                    deletingFile = {this.state.deletingFile}
                    serviceCarOptions={this.props.frameAgreement.serviceCarOptions}
                />
                : <div>
                    <FrameAgreementConfigInformation
                        editClicked={ () => {this.editCountry(true); }}
                        disciplines={this.props.disciplineConfig.frameAgreementConfigs}
                        saveConfig={this.handleConfigSave}
                        countryConfig={selectedCountry}
                        filesFound={ filesFound }
                        downloadFile = {this.downloadFile}
                    />
                    <div style={dataParentDiv}>
                        <div style={dataContentHeaderDiv}>
                            {t('terms_and_conditions_comment')}:
                        </div>
                        <TranslationForm
                            localizables={LOCALIZABLES}
                            country={selectedCountry}
                            type={2}
                            richText={true}
                            list={list}
                        />
                    </div>
                </div>}
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        countries: state.countries,
        selectedCountry: state.countries.selectedCountry,
        languages: state.translations.languages,
        profile: state.userProfile,
        files: state.files,
        discipline: state.discipline,
        department: state.department,
        translations: state.translations,
        download: state.download,
        frameAgreement: state.frameagreement,
        disciplineConfig: state.disciplineConfig,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        downloadFile: (downloadType, name, type, accessToken) => dispatch(download({downloadType, name, type}, accessToken)),
        uploadFile: (share, dir, file, filename, accessToken) => dispatch(uploadFile(share, dir, file, filename, accessToken)),
        deleteFile: (share, dir, filename, accessToken) => dispatch(deleteFile(share, dir, filename, accessToken))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(configuredRadium(FrameAgreementConfigurationView)));
