import React, {Component}           from 'react';
import translations from 'decorators/translations.js';

import {
    colors
}                                   from 'styles/design.js';

const styles = {
    listItem: {
        display: 'block',
        position: 'relative',
        padding: '1em',
        marginBottom: '0.125em',
        backgroundColor: colors.lightGray,
        cursor: 'pointer',
        fontWeight: 'bold',
        ':hover': {
            backgroundColor: colors.lightGray2
        }
    },
    icon: {
        position: 'absolute',
        left: '0.571em',
        top: '0.25em',
        fontSize: '1.75em'
    }
};

class ListItemHeader extends Component {
    render() {
        return (
            <li style={{ display: 'block' }}>
                <div style={{ ...styles.listItem, color: this.props.color, paddingLeft: this.props.iconStyle ? '3.5em' : '1em' }} onClick={ this.props.handler }>
                    {
                        (() => {
                            if (this.props.iconStyle) {
                                return <i style={ styles.icon } className={ 'fa fa-' + this.props.iconStyle } />;
                            }
                        })()
                    }
                    { this.props.title }
                    <span style={{ float: 'right' }}><i className={ this.props.collapsed ? 'fa fa-caret-down' : 'fa fa-caret-up' } /></span>
                </div>
                { this.props.children }
            </li>
        );
    }
}

export default translations(ListItemHeader);