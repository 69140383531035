
import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import {
    SFRadioField,
    SFCustomRadioField
} from 'components';

class SFRadioFields extends Component {

    render() {
        return (
            <div>
                { (() => {
                    return this.props.options.map((opt, index) => {
                        if(opt.custom) {
                            return (
                                <SFCustomRadioField
                                    key={ this.props.name + '-' + index}
                                    name={this.props.name}
                                    label={opt.label}
                                    value={opt.value}
                                    customValue={opt.customValue}
                                    formProperty={this.props.formProperty}
                                    customFormProperty={this.props.customFormProperty}
                                    disabled={this.props.formProperty.initialValue !== opt.value && this.props.disabled}
                                />
                            );
                        }
                        return (
                            <SFRadioField
                                id={opt.id}
                                key={ this.props.name + '-' + index}
                                name={this.props.name}
                                label={opt.label}
                                value={opt.value}
                                formProperty={this.props.formProperty}
                                disabled={this.props.formProperty.initialValue !== opt.value && this.props.disabled}
                            />
                        );
                    });
                })() }
            </div>
        );
    }
}

export default translations(SFRadioFields);
