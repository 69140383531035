import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {RichTextView} from 'components/index.js';

import {
    commonStyles,
    measures,
} from 'styles/design.js';

import {
    Button,
    SFRadioField,
    VisitPlanCalendar,
    LoadingIndicator,
    Section
} from 'components/index.js';

class SLAInformation extends Component {

    static propTypes = {
        agreement: PropTypes.object.isRequired,
        editClicked: PropTypes.func.isRequired,
        countryConfig: PropTypes.object.isRequired
    };

    render() {
        const {t, agreement, countryConfig, isEditable, getSlaDescription} = this.props;

        if (!agreement || !countryConfig) {
            return <LoadingIndicator visible={true}/>;
        }

        return (
            <div>

                { isEditable && (
                    <Section>
                        <Button id="SLAInformation_EditButton" onClick={this.props.editClicked}>
                            <i className="fa fa-pencil" /> {t('edit')}
                        </Button>
                    </Section>
                )}

                <Section title={t('response_time')}>

                    {
                        (() => {
                            if (agreement.responseTime) {
                                return (
                                    <div>
                                        {
                                            agreement.responseTime &&
                                            <SFRadioField
                                                readOnly={true}
                                                style={{marginTop: 0}}
                                                label={t(agreement.responseTime !== 'response_none' ? 'response_time_' + agreement.responseTime : 'none')}
                                                checked={true}/>
                                        }
                                        {
                                            (() => {
                                                if (agreement.responseTime === 'custom_response') {
                                                    return (
                                                        <div style={{marginTop: '1em'}}>
                                                            <h2 style={commonStyles.pageSubTitle}>{t('response_time_comment')}</h2>
                                                            <div style={{padding: '1em 0.5em'}}>
                                                                {agreement.responseTimeComment}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                if (getSlaDescription('slaResponseTimeDescription')) {
                                                    return (
                                                        <div style={{marginTop: measures.standard}}>
                                                            <RichTextView
                                                                labelStyle={{marginLeft: measures.standard}}
                                                                value={getSlaDescription('slaResponseTimeDescription')}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })()
                                        }
                                    </div>);
                            }
                            return (<div>{t('Response time not set')}</div>);
                        })()
                    }

                </Section>

                <Section title={t('support_time')}>

                    {
                        (() => {
                            if (agreement.supportTime247) {
                                const label = agreement.supportTime247CustomText
                                    ? agreement.supportTime247CustomText
                                    : t(agreement.supportTime247 !== 'support_none' ? ('support_time_' + agreement.supportTime247) : 'none');
                                return (
                                    <div>
                                        {
                                            agreement.supportTime247 &&
                                            <SFRadioField
                                                readOnly={true}
                                                style={{marginTop: 0}}
                                                label={label}
                                                checked={true}
                                            />
                                        }

                                        {
                                            (() => {
                                                if (agreement.supportTime247 === 'custom_support') {
                                                    return (
                                                        <div style={{marginTop: measures.standard}}>
                                                            <h2 style={commonStyles.pageSubTitle}>{t('support_time_24/7_comment')}</h2>

                                                            <div style={{margin: measures.half}}>
                                                                {t('price')}: {agreement.supportTime247CustomPrice} {countryConfig.currency}
                                                            </div>
                                                            <div style={{padding: '1em 0.5em'}}>
                                                                {agreement.supportTime247Comment}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                                if (getSlaDescription('slaSupport247description')) {
                                                    return (
                                                        <div style={{marginTop: measures.standard}}>
                                                            <div style={{margin: measures.half}}>
                                                                {t('price')}: {countryConfig.slaSupport247price} {countryConfig.currency}
                                                            </div>

                                                            <RichTextView
                                                                labelStyle={{marginLeft: measures.standard}}
                                                                value={getSlaDescription('slaSupport247description')}
                                                            />
                                                        </div>
                                                    );
                                                }
                                            })()
                                        }
                                    </div>
                                );
                            }
                            return (<div>{t('Support time 247 not set')}</div>);
                        })()
                    }

                </Section>

                <Section title={t('key_customer_services')}>
                    {
                        agreement.yearlyMeeting ?
                        <div>
                            <i style={{fontSize: '1.2em'}} className="fa fa-check-square-o"/>
                            <span style={{marginLeft: '1.5em'}}>
                                {t('yearly_meeting')}
                            </span>
                            <div style={{marginTop: measures.standard, marginBottom: measures.half}}>
                                <RichTextView
                                    labelStyle={{marginLeft: measures.standard}}
                                    value={getSlaDescription('slaYearlyMeetingDescription')}
                                />
                            </div>
                            <div style={{padding: '1em 0.5em'}}>
                                <VisitPlanCalendar visitPlan={agreement.yearlyMeetingVisitPlan}/>
                            </div>
                        </div>
                        :
                        <div style={{ margin: '1em 0' }}>
                            <i style={{fontSize: '1.2em'}} className="fa fa-square-o"/>
                            <span style={{marginLeft: '1.5em'}}>
                                {t('yearly_meeting')}
                            </span>
                        </div>
                    }
                </Section>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        responseTimes: state.agreement.responseTimes,
        supportTimes: state.agreement.supportTimes
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(SLAInformation));
