import React, { Component } from 'react';
import { stack } from 'd3-shape';

import Bar from './Bar.js';

class StackedBars extends Component {
    render() {
        const {data, keys, yScale, xScale, colorScale, xAxisKey, onMouseEnter, onMouseLeave} = this.props;

        const stackedData = stack().keys(keys)(data);

        const bars = stackedData.map((item, index) => {
            const fillColor = colorScale(keys[index]);

            return (
                <g
                    key={'stacked' + index}
                    fill={fillColor}
                >
                    { item.map((d, i) => {
                        const x = xScale(d.data[xAxisKey]);
                        const y = yScale(d[1]);
                        const barHeight = yScale(d[0]) - yScale(d[1]);
                        const barWidth = xScale.bandwidth();

                        return (
                            <Bar
                                key={'bar' + i}
                                x={x}
                                y={y}
                                height={barHeight}
                                width={barWidth}
                                onMouseEnter={(e) => onMouseEnter(index, e)}
                                onMouseLeave={onMouseLeave}
                            />
                        );
                    })}
                </g>
            )
        });

        return (
            <g
                ref={node => {this.node = node}}
                className="chart-stacked-bars"
            >
                {bars}
            </g>
        );
    }
}

export default StackedBars;