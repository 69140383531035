import React from 'react';

import translations from 'decorators/translations.js';

import {
    Button,
    SFRadioField
} from 'components/index';

import {
    measures
} from 'styles/design';

const speedOptions = [{
    value: '1',
    label: '1_speed_option'
}, {
    value: '2',
    label: '2_speed_option'
}, {
    value: 'variable',
    label: 'variable_speed_option'
}];

class AHUTypeSelection extends React.Component {
    state = {};
    componentDidMount() {
        this.setState((state) => ({...state, ahuType: this.props.ahuType, ahuSpeed: this.props.ahuSpeed}))
    }
    setSpeedOption = (event) => {
        this.props.setValue('ahuSpeed', event.target.value);
        this.setState({ahuSpeed: event.target.value});
    };
    toggleShowAHUs = () => {
        this.setState((state) => ({...state, showAllAHUs: !state.showAllAHUs}));

    };
    selectAHU = (type) => {
        this.props.setValue('ahuType', type);
        this.setState((state) => ({...state, ahuType: type, showAllAHUs: false}));
    };

    render() {
        const {t, types} = this.props;
        return (
            <div>
                <div>
                    <p style={{marginTop: measures.standard, fontSize: '1.2em'}}>
                        <Button id="AHUTypeSelection_show" onClick={this.toggleShowAHUs}>
                            <span>{t(this.state.showAllAHUs ? 'hide_ahu_options' : 'show_ahu_options')}
                                <i style={{marginLeft: measures.standard}} className={'fa ' + (this.state.showAllAHUs ? 'fa-compress' : 'fa-expand')}/>
                            </span>
                        </Button>
                    </p>
                    {
                        types.length === 0 && <div>{t('No AHU options available')}</div>
                    }
                    <div className="AirHandlingSystemTypes_container">
                    {
                        types.map((ahu, index) => {
                            if(this.state.showAllAHUs || this.state.ahuType === ahu.name) {
                                return (
                                    <div
                                        key={'ahuindex_' + index}
                                        className="AirHandlingSystemType_image"
                                        id={'AHUTypeSelection_type_' + index}
                                    >
                                        <SFRadioField
                                            name={'airHandlingSystemType'} label={ahu.name}
                                            value={ahu.name}
                                            selectedValue={this.state.ahuType}
                                            onUpdate={() => this.selectAHU(ahu.name)}
                                        />
                                        <img
                                            alt="ahu"
                                            onClick={() => this.selectAHU(ahu.name)}
                                            src={require('images/AHU/' + ahu.name.replace('. ', '-') + '.png')}
                                            width="100%"
                                        />
                                    </div>
                                );
                            }
                        })
                    }
                    </div>
                </div>
                <div className="FormContainer" style={{margin: '2em 0 1em'}}>
                    <p>{t('air_handling_unit_speed')}:</p>
                    {
                        speedOptions.map((opt, idx) =>
                            <div key={'speed_option_' + idx}>
                                <SFRadioField
                                    id={'AHUTypeSelection_speedOption_' + idx}
                                    key={'speed_option_' + idx}
                                    name="speedOptions"
                                    label={t(opt.label)}
                                    value={opt.value}
                                    selectedValue={this.state.ahuSpeed}
                                    onUpdate={this.setSpeedOption}
                                />
                            </div>)
                    }
                </div>
            </div>
        );
    }
}

export default translations(AHUTypeSelection);