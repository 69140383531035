import React, {Component }                  from 'react';
import PropTypes                            from 'prop-types';
import { Link }                             from 'react-router-dom';
import _                                    from 'lodash';

import './Dropdown.css';

class DropdownItem extends Component {
    
    static propTypes = {
        action: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.func
        ])
    };
    renderLink() {
        return (
            <Link id={ this.props.id } className="dropdown-item" to={ this.props.action } onClick={ (e) => this.props.onClick(e) }>
                { this.renderTitle() }
            </Link>
        )
    }
    renderAction = () => {
        return (
            <a id={ this.props.id } className="dropdown-item" onClick={ this.props.action }>
                { this.renderTitle() }
            </a>
        )
    }
    renderTitle = () => {
        return (
            <span>
                { this.props.children }
            </span>
        )
    }
    render() {
        if (_.isString(this.props.action)) {
            return this.renderLink();
        }
        return this.renderAction();
    }
}

export default DropdownItem;
