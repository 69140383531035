import React from 'react';
import { connect } from 'react-redux';

export default function translations(WrappedComponent) {
    const Translations = (props) => {
        const t = (key, ...params) => {
            const localization = props.translations;
            if(!localization) {
                return key;
            }
            let formatted = localization.translations[key] || key;
            for (let i = 0; i < params.length; i++) {
                const regexp = new RegExp('\\{' + i + '\\}', 'gi');
                formatted = formatted.replace(regexp, params[i]);
            }
            return formatted + (localization.showKeys ? ' (' + key +')' : '');
        };
        return <WrappedComponent { ...props } t={ t } />;
    };
    const mapStateToProps = (state) => {
        return {
            translations: state.translations
        };
    };
    return connect(mapStateToProps)(Translations);
}