import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './richTextEditorStyles.css';
let Editor;

class RichTextEditor extends Component {
    state = {};
    static propTypes = {
        onChange: PropTypes.func
    };
    componentDidMount() {
        Editor = require('react-rte').default;
        let value;
        if (this.props.value) {
            value = Editor.createValueFromString(this.props.value, 'html');
        } else {
            value = Editor.createEmptyValue();
        }
        this.setState({value});
    }
    onChange = (value) => {
        this.setState({value});
        if (this.props.onChange) {
            this.props.onChange(
                this.props.descriptionKey,
                value.toString('html')
            );
        }
    };

    render() {
        if(!Editor || !this.state.value) {
            return <div>-</div>;
        }
        return (
            <div>
                <Editor
                    value={this.state.value}
                    onChange={this.onChange}
                />
            </div>
        );
    }
}
export default RichTextEditor;