import React, {Component}                   from 'react';
import translations from 'decorators/translations.js';
import { connect }                          from 'react-redux';
import configuredRadium                     from 'configuredRadium.js';
import { StandardPage,
         SubDisciplineForm,
         BreadcrumbBar,
        LoadingIndicator
}                    from 'components';

import {
    loadContractInfo,
    isContractInfoLoaded
}   from 'redux/modules/contractInfo.js';

import { loadCountry } from 'redux/modules/country.js';
// import { loadDisciplineMetadata } from 'redux/modules/discipline.js';
import { loadAllDisciplineConfigs } from 'redux/modules/disciplineConfig';


import { loadBuilding } from 'redux/modules/building.js';

import { save as saveDiscipline,
         createNew as createDiscipline,
         calculateDisciplineCosts }      from 'redux/modules/discipline.js';
import _ from 'lodash';
import { loadConfig } from 'redux/modules/disciplineConfig.js';
import {
    loadFiles,
}                               from 'redux/modules/files.js';
import { getLanguage } from 'helpers/languageHelper';

class CreateSubDiciplineView extends Component {

    componentWillMount() {
        const { dispatch, match: {params}} = this.props;
        this.setState({canRender: false});
        const accessToken = this.props.profile.accessToken;
        const type = params.disciplineType;
        const category = params.disciplineCategory;
        const contractInfoId = parseInt(params.contractInfoId, 10);
        const buildingId = params.buildingId;
        const promises = [];
        promises.push(this.props.dispatch(loadFiles('agreementfiles', 'disciplineterms', accessToken)));
        if(!isContractInfoLoaded(this.prop, contractInfoId)) {
            promises.push(this.props.dispatch(loadContractInfo(params.contractInfoId, this.props.profile.accessToken)));
        }
        promises.push(this.props.dispatch(loadBuilding(params.buildingId, accessToken)));

        return Promise.all(promises)
            .then(() => {
            const contractInfo = this.props.contractInfo.contractInfo;
                if(contractInfo) {
                    this.props.dispatch(loadConfig(contractInfo.salesArea, category, type, this.props.profile.accessToken ));
                    this.props.dispatch(loadCountry(contractInfo.salesArea, this.props.profile.accessToken));
                    if(_.isEmpty(this.props.disciplineState.disciplineMetadata)) {
                        promises.push(this.props.dispatch(loadAllDisciplineConfigs(contractInfo.salesArea, null, this.props.profile.accessToken)));
                    }
                    this.props.dispatch(createDiscipline(category, type, buildingId, accessToken))
                        .then((res) => {
                            if(res && res.result && !_.isEmpty(this.props.disciplineState.discipline)) {
                                dispatch(calculateDisciplineCosts(this.props.disciplineState.discipline, this.props.profile.accessToken))
                                    .then(() => {
                                        this.setState({canRender: true});
                                    });
                            }
                        });
                }
            })
            .catch((error) => {
                console.error('Failure fetching data', error);
            })
    }

    handleSubmit = (discipline) => {
        discipline.id = this.props.disciplineState.discipline.id;
        const isFirstSave = !discipline.id;
        return this.props.dispatch(saveDiscipline(discipline, this.props.profile.accessToken)).then(() => {
            if (isFirstSave && this.props.disciplineState.discipline.id) {
                const buildingId = this.props.match.params.buildingId;
                const clientId = this.props.match.params.clientId;
                const disciplineId = this.props.disciplineState.discipline.id;
                const contractInfoId = this.props.match.params.contractInfoId;
                this.props.history.push('/client/' + clientId + '/contractInfo/' + contractInfoId + '/building/' + buildingId + '/discipline/' + disciplineId);
            }
        });
    };

    hasCustomTerms = files => {
        const { contractInfo: { contractInfo }, agreement, match: { params } } = this.props;
        const lang = (agreement && agreement.agreement && agreement.agreement.language) || getLanguage(this.props.translations.language);
        const country = contractInfo && contractInfo.salesArea;
        const category = params && params.disciplineCategory;
        const name = `terms_and_conditions_discipline_${category}_${lang}_${country}.pdf`;
        return _.some(files, { name });
    };

    render() {
        const {
            files
        } = this.props;
        const selectedBuilding = this.props.buildingState.building;
        const isCustomTermsAvailable = files && files.files && this.hasCustomTerms(files.files);
        return (
            <div>
                <StandardPage withBreadcrumb>
                    <BreadcrumbBar
                        page = "building"
                        contractInfo = { this.props.contractInfo.contractInfo }
                        building = { selectedBuilding }
                    />
                    {
                        (() => {
                            if(this.state.canRender && !_.isEmpty(this.props.disciplineState.discipline)) {
                                return (
                                    <SubDisciplineForm
                                        handleSubmit = { this.handleSubmit }
                                        discipline = {this.props.disciplineState.discipline}
                                        clientId={this.props.match.params.clientId}
                                        isEditable={true}
                                        isNewDiscipline={true}
                                        deleteSystem={() => {}}
                                        disciplineConfig={this.props.disciplineConfig}
                                        isCustomTermsAvailable={isCustomTermsAvailable}
                                    />
                                );
                            }
                            return <LoadingIndicator visible={true} big={true} />
                        })()
                    }

                </StandardPage>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        clientState: state.client,
        buildingState: state.building,
        disciplineState: state.discipline,
        calculatedDiscipline : state.discipline.calculatedDiscipline,
        disciplineMetadata: state.discipline.disciplineMetadata,
        profile: state.userProfile,
        contractInfo: state.contractInfo,
        disciplineConfig: state.disciplineConfig.config,
        files: state.files,
        translations: state.translations,
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(CreateSubDiciplineView)));