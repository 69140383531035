import React                from 'react';
import TabLabels            from '../Tabs/TabLabels';

import './VerticalNavigationBar.css';

const VerticalNavigationBar = ({children}) => {
    return (
        <nav className="vertical-navigation-bar">
            <TabLabels vertical>
                {children}
            </TabLabels>
        </nav>
    );
}

export default VerticalNavigationBar;
