import React, {Component}          from 'react';
import translations from 'decorators/translations.js';
import { connect }                 from 'react-redux';
import configuredRadium            from 'configuredRadium.js';
import { localeDateString }             from 'helpers/stringTemplates';
import { loadAllDisciplineConfigs }from 'redux/modules/disciplineConfig.js';
import { loadContractInfo }   from 'redux/modules/contractInfo.js';
import { loadBuildings } from 'redux/modules/building.js';
import { loadAgreements } from 'redux/modules/agreement.js';

import {
    StandardPage,
    DisciplineSelectionList,
    BreadcrumbBar,
    CloseButton
}                                  from 'components';

import {
    isEditable
}                                  from 'helpers/agreement.js';
import {
    commonStyles
}                                  from 'styles/design.js';

import _ from 'lodash';

class CreateDisciplineView extends Component {

    componentWillMount() {
        const params = this.props.match.params;
        const accessToken = this.props.profile.accessToken;
        const promises = [];

        promises.push(this.props.dispatch(loadBuildings(params.clientId, accessToken)));
        promises.push(this.props.dispatch(loadAgreements(params.clientId, accessToken)));
        promises.push(this.props.dispatch(loadContractInfo(params.contractInfoId, accessToken)));
        return Promise.all(promises).then( () => {
            const country = _.get(this.props.contractInfo, 'contractInfo.salesArea', null);
            if (country) {
                this.props.dispatch(loadAllDisciplineConfigs(country, null, accessToken));
            }
        })
    }

    render() {
        const {
            t,
            match: {params},
            disciplineConfig: {
                configs
            },
            selectedCountry,
        } = this.props;

        if(
            this.props.contractInfo.loading ||
            this.props.building.loading ||
            this.props.discipline.loading ||
            this.props.disciplineConfig.loadingAll ||
            (this.props.building.byContractInfo && this.props.building.byContractInfo.length === 0)
        ) {
            return <StandardPage withBreadCrumb={true} loaderVisible={true}><div /></StandardPage>;
        }
        const buildingId = parseInt(params.buildingId, 10);
        const contractInfo = this.props.contractInfo.contractInfo;
        const buildingContractInfo = _.find(this.props.building.byContractInfo, { id: parseInt(params.contractInfoId, 10) });
        const notFound = <div>Building not found.</div>;
        if (!buildingContractInfo) {
            // TODO: FIX this.
            return notFound;
        }
        const selectedBuilding = _.find(buildingContractInfo.buildings, {id: parseInt(params.buildingId, 10)});
        if (!selectedBuilding) {
            return notFound;
        }
        const disciplines = _.filter(this.props.disciplineConfig.configs, config => {
            if (!selectedCountry.showGlobalDisciplines && !!config.serviflex && !!config.categoryServiflex) {
                return true;
            }
            const hasGobalConfig = _.some(configs, c =>
                !!c.serviflex &&
                !!c.categoryServiflex &&
                c.global === true &&
                c.disciplineType === config.disciplineType &&
                config.disciplineCategory === c.disciplineCategory
            );
            if (hasGobalConfig && config.global !== true && !!config.serviflex && !!config.categoryServiflex) {
                return true;
            }
            return false;
        });
        const buildingAgreement = selectedBuilding && selectedBuilding.agreement;

        const draftAgreement = isEditable(buildingAgreement) ? buildingAgreement : null;
        const existingDisciplines = draftAgreement ? _.filter(selectedBuilding.disciplines, {agreementId: draftAgreement.id}) : [];
        const isSentForApprovalAgreement = buildingAgreement && ['sent_for_approval', 'approved'].indexOf(buildingAgreement.agreementStatus) !== -1;
        if(isSentForApprovalAgreement) {
            return (
                <StandardPage withBreadcrumb>
                    <BreadcrumbBar
                        page="building"
                        contractInfo={ contractInfo }
                        building = { selectedBuilding }
                    />
                    <div className="highlight" style={{marginBottom: '2em', marginLeft: '2em', fontSize: '1.5em'}}>
                        {t('building_locked')}
                    </div>
                    <div>
                        {t('building_locked_agreement_pending')}
                    </div>
                    <div style={{width: '100px'}}>
                        <CloseButton
                            path={'/client/' + params.clientId + '/contractInfo/' + params.contractInfoId + '/building/' + buildingId}
                        />
                    </div>
                </StandardPage>
            );
        }
        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar
                    page="building"
                    contractInfo={ contractInfo }
                    building = { selectedBuilding } />
                <header style = { commonStyles.bottomDivider }>
                    <h1 style = { commonStyles.pageTitle } >{ t('choose_discipline') }</h1>
                    {
                        (() => {
                            if(draftAgreement) {
                                return (<h2>{t('belongs_to_agreement')}: {draftAgreement.id} {t(draftAgreement.agreementType === 'main' ? 'main_agreement' : draftAgreement.agreementType)} {localeDateString(draftAgreement.created)}</h2>);
                            }
                            return <h2>{t('new_addon')}</h2>;
                        })()
                    }
                </header>
                { contractInfo && contractInfo.client &&
                    <DisciplineSelectionList
                        contractInfoId = { contractInfo.id }
                        clientId = { contractInfo.client.id }
                        buildingId = { selectedBuilding.id }
                        disciplines = { disciplines }
                        existing={ existingDisciplines }
                        params={params}
                    />
                }
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        agreement: state.agreement,
        building: state.building,
        discipline: state.discipline,
        profile: state.userProfile,
        contractInfo: state.contractInfo,
        disciplineConfig: state.disciplineConfig,
        selectedCountry: state.countries.selectedCountry,
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(CreateDisciplineView)));