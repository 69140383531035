import React from 'react';

const Legend = (props) => {
    const colorScale = props.colorScale;
    const selected = props.hoverIndex;
    const lineHeight = 1.5;
    const legend = props.items.map((item, index) => {
        const itemY = lineHeight * index * 16;
        const translate = 'translate(0,' + itemY + ')';
        return (
            <g 
                key={'legend' + index}
                className={'chart-legend-item' + (selected === index ? ' active' : '')}
                transform={translate}
            >
                <rect 
                    x={props.width - 20} 
                    y="0"
                    width="18"
                    height="18"
                    fill={colorScale(item.label)}
                    stroke={colorScale(item.label)}
                />
                <text
                    x={props.width - 30}
                    y="9"
                    dy="0.3em"
                >
                    {item.label}
                </text>
            </g>
        )
    });

    return (
        <g
            className="chart-legend"
            textAnchor="end"
            fontSize="10"
            transform={'translate(0,' + props.margin.top + ')'}
        >
            {legend}
        </g>
    );
}

export default Legend;