const LOAD                    = 'ServiFlex/FrameAgreement/LOAD',
    LOAD_SUCCESS              = 'ServiFlex/FrameAgreement/LOAD_SUCCESS',
    LOAD_FAIL                 = 'ServiFlex/FrameAgreement/LOAD_FAIL',
    LOAD_ALL                    = 'ServiFlex/FrameAgreement/LOAD_ALL',
    LOAD_ALL_SUCCESS              = 'ServiFlex/FrameAgreement/LOAD_ALL_SUCCESS',
    LOAD_ALL_FAIL                 = 'ServiFlex/FrameAgreement/LOAD_ALL_FAIL',
    LOAD_USER_AGREEMENTS           = 'ServiFlex/FrameAgreement/LOAD_USER_AGREEMENTS',
    LOAD_USER_AGREEMENTS_SUCCESS   = 'ServiFlex/FrameAgreement/LOAD_USER_AGREEMENTS_SUCCESS',
    LOAD_USER_AGREEMENTS_FAIL      = 'ServiFlex/FrameAgreement/LOAD_USER_AGREEMENTS_FAIL',
    LOAD_MY_AGREEMENTS           = 'ServiFlex/FrameAgreement/LOAD_MY_AGREEMENTS',
    LOAD_MY_AGREEMENTS_SUCCESS   = 'ServiFlex/FrameAgreement/LOAD_MY_AGREEMENTS_SUCCESS',
    LOAD_MY_AGREEMENTS_FAIL      = 'ServiFlex/FrameAgreement/LOAD_MY_AGREEMENTS_FAIL',
    LOAD_APPROVABLE_AGREEMENTS           = 'ServiFlex/FrameAgreement/LOAD_APPROVABLE_AGREEMENTS',
    LOAD_APPROVABLE_AGREEMENTS_SUCCESS   = 'ServiFlex/FrameAgreement/LOAD_APPROVABLE_AGREEMENTS_SUCCESS',
    LOAD_APPROVABLE_AGREEMENTS_FAIL      = 'ServiFlex/FrameAgreement/LOAD_APPROVABLE_AGREEMENTS_FAIL',
    SAVE                      = 'ServiFlex/FrameAgreement/SAVE',
    SAVE_SUCCESS              = 'ServiFlex/FrameAgreement/SAVE_SUCCESS',
    SAVE_FAIL                 = 'ServiFlex/FrameAgreement/SAVE_FAIL',
    DEL                      = 'ServiFlex/FrameAgreement/DEL',
    DEL_SUCCESS                      = 'ServiFlex/FrameAgreement/DEL_SUCCESS',
    DEL_FAIL                      = 'ServiFlex/FrameAgreement/DEL_FAIL',
    SEND_FOR_APPROVAL         = 'ServiFlex/FrameAgreement/SEND_FOR_APPROVAL',
    SEND_FOR_APPROVAL_SUCCESS = 'ServiFlex/FrameAgreement/SEND_FOR_APPROVAL_SUCCESS',
    SEND_FOR_APPROVAL_FAIL    = 'ServiFlex/FrameAgreement/SEND_FOR_APPROVAL_FAIL',
    APPROVE                   = 'ServiFlex/FrameAgreement/APPROVE',
    APPROVE_SUCCESS           = 'ServiFlex/FrameAgreement/APPROVE_SUCCESS',
    APPROVE_FAIL              = 'ServiFlex/FrameAgreement/APPROVE_FAIL',
    REJECT                    = 'ServiFlex/FrameAgreement/REJECT',
    REJECT_SUCCESS            = 'ServiFlex/FrameAgreement/REJECT_SUCCESS',
    REJECT_FAIL               = 'ServiFlex/FrameAgreement/REJECT_FAIL',
    REVOKE                    = 'ServiFlex/FrameAgreement/REVOKE',
    REVOKE_SUCCESS            = 'ServiFlex/FrameAgreement/REVOKE_SUCCESS',
    REVOKE_FAIL               = 'ServiFlex/FrameAgreement/REVOKE_FAIL';

const serviceCarOptions = ['price_per_km', 'price_per_hour', 'price_per_assignment', 'price_per_day', 'other']

const initialState = {
    loading: false,
    agreement: {},
    agreements: [],
    loaded: false,
    reload: true,
    saving: false,
    deleting: false,
    deleted: false,
    loadingUserAgreements: false,
    loadedUserAgreements: false,
    reloadUserAgreements: false,
    loadingMyAgreements: false,
    loadedMyAgreements: false,
    reloadMyAgreements: false,
    serviceCarOptions
};

export default function reducer(state = initialState, action = {}) {
    const agreement = state.agreement;
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                agreement: action.result
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_ALL:
            return {
                ...state,
                loading: true
            };
        case LOAD_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                byContractInfo: action.result,
            };
        case LOAD_ALL_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false
            };
        case LOAD_USER_AGREEMENTS:
            return {
                ...state,
                loadingUserAgreements: true,
                loading: true
            };
        case LOAD_USER_AGREEMENTS_SUCCESS:
            return {
                ...state,
                loadingUserAgreements: false,
                loadedUserAgreements: true,
                reloadUserAgreements: false,
                loading: false,
                loaded: true,
                userAgreements: action.result
            };
        case LOAD_USER_AGREEMENTS_FAIL:
            return {
                ...state,
                loadingUserAgreements: false,
                loadedUserAgreements: false,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_MY_AGREEMENTS:
            return {
                ...state,
                loadingMyAgreements: true,
                loading: true
            };
        case LOAD_MY_AGREEMENTS_SUCCESS:
            return {
                ...state,
                loadingMyAgreements: false,
                loadedMyAgreements: true,
                reloadMyAgreements: false,
                loading: false,
                loaded: true,
                myAgreements: action.result
            };
        case LOAD_MY_AGREEMENTS_FAIL:
            return {
                ...state,
                loadingMyAgreements: false,
                loadedMyAgreements: false,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_APPROVABLE_AGREEMENTS:
            return {
                ...state,
                loadingMyAgreements: true
            };
        case LOAD_APPROVABLE_AGREEMENTS_SUCCESS:
            return {
                ...state,
                loadingMyAgreements: false,
                loadedMyAgreements: true,
                reloadMyAgreements: false,
                approvableAgreements: action.result
            };
        case LOAD_APPROVABLE_AGREEMENTS_FAIL:
            return {
                ...state,
                loadingMyAgreements: false,
                loadedMyAgreements: false,
                error: action.error
            };
        case SAVE:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                reload: false,
                error: action.error
            };
        case SAVE_SUCCESS:
            Object.keys(action.result).map((key) => {
                agreement[key] = action.result[key];
            });
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                saved: true,
                agreement
            };
        case SAVE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                reload: false,
                error: action.error
            };
        case DEL:
            return {
                ...state,
                deleting: true,
                deleted: false,
                error: null
            };
        case DEL_FAIL:
            return {
                ...state,
                deleting: false,
                deleted: false,
                error: action.error
            };
        case DEL_SUCCESS:
            return {
                ...state,
                deleting: false,
                deleted: true,
                reload: true,
                result: action.result
            };
        case SEND_FOR_APPROVAL:
            return {
                ...state,
                loading: true,
                reload: false
            };
        case SEND_FOR_APPROVAL_SUCCESS:
            Object.keys(action.result).map((key) => {
                agreement[key] = action.result[key];
            });
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: true,
                agreement
            };
        case SEND_FOR_APPROVAL_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: true
            };

        case REVOKE_SUCCESS:
            Object.keys(action.result).map((key) => {
                agreement[key] = action.result[key];
            });
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: true,
                agreement
            };

        default:
            return state;
    }
}

export function loadFrameAgreements(clientId, accessToken) {
    return {
        types: [LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_FAIL],
        promise: (client) => client.get(
            '/clients/' + clientId + '/frameagreements', { accessToken }
        )
    };
}
export function loadFrameAgreement(agreementId, accessToken) {
    const includes = {
        include: ['signatures', 'attachments']
    };
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client) => client.get(
            `/frameagreements/${agreementId}?filter=${encodeURIComponent(JSON.stringify(includes))}`, { accessToken }
        )
    };
}

export function update(agreementObject, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.patch('/frameagreements/' + agreementObject.id, { data: agreementObject, accessToken }
        )
    };
}

export function saveFrameAgreement(agreement, accessToken) {
    if (agreement.id) {
        return update(agreement, accessToken);
    }
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.post(
            '/frameagreements', { data: agreement, accessToken }
        )
    };
}

export function deleteFrameAgreement(id, accessToken) {
    return {
        types: [DEL, DEL_SUCCESS, DEL_FAIL],
        promise: (client) => client.del('/frameagreements/' + id + '/deleteAgreement', {accessToken})
    };
}

export function sendForApproval(agreementId, email, accessToken) {
    return {
        types: [SEND_FOR_APPROVAL, SEND_FOR_APPROVAL_SUCCESS, SEND_FOR_APPROVAL_FAIL],
        promise: (client) => client.post(
            '/frameagreements/' + agreementId + '/sendForApproval', {data: {approver: email}, accessToken }
        )
    };
}

export function approve(agreementId, comment, accessToken) {
    return {
        types: [APPROVE, APPROVE_SUCCESS, APPROVE_FAIL],
        promise: (client) => client.post(
            '/frameagreements/' + agreementId + '/approve', { data: {comment}, accessToken }
        )
    };
}

export function reject(agreementId, comment, accessToken) {
    return {
        types: [REJECT, REJECT_SUCCESS, REJECT_FAIL],
        promise: (client) => client.post(
            '/frameagreements/' + agreementId + '/reject', { data: {comment}, accessToken }
        )
    };
}

export function revokeApproval(agreementId, accessToken) {
    return {
        types: [REVOKE, REVOKE_SUCCESS, REVOKE_FAIL],
        promise: (client) => client.post(
            '/frameagreements/' + agreementId + '/revoke', { accessToken }
        )
    };
}

export function loadUserFrameAgreements(accessToken) {
    return {
        types: [LOAD_USER_AGREEMENTS, LOAD_USER_AGREEMENTS_SUCCESS, LOAD_USER_AGREEMENTS_FAIL],
        promise: (client) => client.get(
            '/frameagreements/userAgreements', { accessToken }
        )
    };
}

export function loadMyFrameAgreements(accessToken) {
    return {
        types: [LOAD_MY_AGREEMENTS, LOAD_MY_AGREEMENTS_SUCCESS, LOAD_MY_AGREEMENTS_FAIL],
        promise: (client) => client.get(
            '/frameagreements/myAgreements', { accessToken }
        )
    };
}
export function loadApprovableFrameAgreements(accessToken) {
    return {
        types: [LOAD_APPROVABLE_AGREEMENTS, LOAD_APPROVABLE_AGREEMENTS_SUCCESS, LOAD_APPROVABLE_AGREEMENTS_FAIL],
        promise: (client) => client.get(
            '/frameagreements/approvableAgreements', { accessToken }
        )
    };
}

export function areFrameAgreementsLoaded(state) {
    if(state().frameagreement.loaded) {
        return true;
    }
    return false;
}
export function frameAgreementStatusUpdate(id, status, data, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.patch(
            '/frameagreements/' + id + '/status/' + status, {data, accessToken}
        )
    }
}

export function shouldReloadFrameAgreement(state, id) {
    return state.frameAgreement.reload ||
        !state.frameAgreement.loaded ||
        !state.frameAgreement.agreement ||
        !state.frameAgreement.agreement.id ||
        (id && state.frameAgreement.agreement.id !== parseInt(id, 10));
}
