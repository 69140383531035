import React, {Component }            from 'react';

const infoLabelCellStyle = [
    {
        padding: '0.5em 0.5em 0.5em 0em',
        width: '10em',
    }
];

const infoDataCellStyle = [
    {
        padding: '0.5em'
    }
];

class SystemDetailsDataContainerRow extends Component {

    render() {
        return (
            <tr>
                <td style = { infoLabelCellStyle }>{ this.props.rowLabel }:</td>
                <td style = { infoDataCellStyle }>{ this.props.rowValue }</td>
            </tr>
    );}
}
export default SystemDetailsDataContainerRow;