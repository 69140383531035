import React, {Component}    from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/translations.js';
import configuredRadium                 from 'configuredRadium.js';
import waitForFinalEvent                from 'helpers/waitForFinalEvent.js';
import { getDisciplineTotal, getOldTotalForDiscipline } from 'helpers/calculation.js';
import _                                from 'lodash';
import { calculateDisciplineCosts }     from 'redux/modules/discipline.js';
import { connect } from 'react-redux';
import {
    SystemContainer,
    ServiceLevelSelection,
    DisciplineVisitPlan,
    CostNumber,
    DisciplineIcon,
    CloseButton,
    DisciplineTitle,
    LoadingIndicator
}                                       from 'components';
import {
    measures,
    commonStyles,
    layout,
    fonts,
    size,
    colors,
    decorators
}                                       from 'styles/design.js';
import {
    deepCopy
} from 'helpers/objectHelper.js';

import "./SubDisciplineForm.css";

const disciplineNameStyle = [
    fonts.sanches,
    size.delta,
    layout.inline,
    {
        paddingLeft: measures.fatQuarter,
        paddingRight: measures.standard
    }
];

const divStyles = {
    //borderLeft: '0.01em solid ' + colors.blue,
    //borderRight: '0.01em solid ' + colors.blue,
    //borderTop: '0.01em solid ' + colors.blue,
    padding: measures.standard + ' 0',
    overflow: 'auto'
};



const mainContainerStyle = {
    padding: measures.standard + ' 0',
    overflow: 'hidden',
    borderBottom: decorators.coloredBorder(colors.lightGray)
};

const containerStyle = {
    backgroundColor: colors.lightGray,
    cursor: 'pointer',
    width: '100%',
    padding: measures.double
};

const editButtonStyle = [
    commonStyles.buttonLink,
    {
        display: 'block',
        backgroundColor: colors.lightBlue
    }
];

class SubDisciplineForm extends Component {

    static propTypes = {
        discipline: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        deleteSystem: PropTypes.func.isRequired,
    };

    componentWillMount() {
        if(!_.isEmpty(this.props.discipline) && _.isEmpty(this.state.discipline)) {
            this.setState({canEdit: true, discipline: deepCopy(this.props.discipline)});
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.state.reInit || (_.isEmpty(this.props.discipline) && !_.isEmpty(nextProps.discipline) && _.isEmpty(this.state.discipline)) ||
            (this.props.discipline && nextProps.discipline && this.props.discipline.id !== nextProps.discipline.id)
        ) {
            this.setState({canEdit: true, discipline: deepCopy(nextProps.discipline), reInit: false });
        }
        if(this.state.savingSystem) {
            this.setState({savingSystem: false, canEdit: true});
        }
    }

    handleSave = discipline => {
        this.setState({savingSystem: true, reInit: true});
        const savedDiscipline = _.cloneDeep(discipline);
        delete savedDiscipline.systems;
        delete savedDiscipline.systemsCostCalculation;
        delete savedDiscipline.fullCostCalculation;
        delete savedDiscipline.disciplineSystems;
        return this.props.handleSubmit(savedDiscipline)
            .then(() => {
                if(!this.props.isNewDiscipline) {
                    this.setState({
                        canEdit: true,
                        systemUnderEdit: undefined,
                        indexOfNewSystem: undefined
                    });
                }
            });
    };

    handleSystemSave = (idx, system) => {
        return this.props.saveSystem(system);
    };

    handleSystemAdd = () => {
        const discipline = this.state.discipline;
        const indexOfNewSystem = discipline.disciplineSystems.length;
        discipline.disciplineSystems.push({overrideCalculation: true});
        this.setState({
            canEdit: false,
            systemUnderEdit: indexOfNewSystem,
            showDetails: indexOfNewSystem,
            indexOfNewSystem,
            discipline
        });
    };

    handleCancel = () => {
        //Override state discipline with value from props
        this.setState({
            canEdit: true,
            discipline: deepCopy(this.props.discipline),
            systemUnderEdit: undefined
        });
        //this.props.calculateCosts(this.propss, this.props.profile.accessToken);
    };

    handleServiceLevelChange = (value) => {
        const discipline = this.state.discipline;
        discipline.serviceLevel = value;
        this.setState({discipline});
    };

    hasCustomIncome() {
        const calc = this.state.discipline.fullCostCalculation;
        return calc && calc.customIncomeDifference && calc.customIncomeDifference !== 0;
    };

    resetField = (fieldName) => {
        const discipline = this.state.discipline;
        discipline[fieldName] = this.props.discipline[fieldName];
        this.setState({discipline});
    };

    changeVisitPlanValue = (monthIndex) => {
        const discipline = this.state.discipline;
        discipline.visitPlan[monthIndex].name = !discipline.visitPlan[monthIndex];
        this.setState({discipline});
    };

    findEquipmentTypeOptions(discipline) {
        return _.find(this.props.disciplineMetadata, (disciplineMetadata) => {
            return disciplineMetadata.disciplineCategory === discipline.disciplineCategory && disciplineMetadata.disciplineType === discipline.disciplineType;
        }).equipmentTypeOptions;
    }

    editSystem = (index) => {
        this.setState({
            canEdit: false,
            systemUnderEdit: index
        });
    };

    expandSection = (field) => {
        if(this.state.expandThis === field) {
            this.setState({expandThis: null});
        } else {
            this.setState({expandThis: field});
        }
    };
    calculateCosts = (system, index) => {
        const discipline = deepCopy(this.state.discipline);
        discipline.disciplineSystems[index] = system;
        waitForFinalEvent(
            ()=> {
                // init
            },
            () => {
                this.props.calculateCosts(discipline, this.props.profile.accessToken);
            }, 500, 'disciplineUpdate');
    };
    calculateCostsAfterVisitplanChange = (isNeedbased) => {
        const discipline = Object.assign({}, this.state.discipline);
        discipline.needbased = isNeedbased;
        waitForFinalEvent(
            ()=> {
                // init
            },
            () => {
                this.props.calculateCosts(discipline, this.props.profile.accessToken);
            }, 500, 'disciplineUpdate');
    };
    //Validate if certain data is set for the discipline
    isVisitPlanSet() {
        return this.state.discipline.needbased || (this.state.discipline.visitPlan && this.state.discipline.visitPlan.indexOf(true) !== -1);
    }
    render() {
        const { t, isEditable, isCustomTermsAvailable } = this.props;
        const submitting = this.props.disciplineState.saving;
        const discipline = this.state.discipline;
        const disciplines = _.get(this.props.buildingState, 'building.disciplines', []);

        if(this.props.disciplineState.loading || _.isEmpty(discipline)) {
            return <LoadingIndicator visible={true} />;
        }

        const isCustomDiscipline = _.startsWith(discipline.disciplineType, 'custom_discipline');
        const canEdit = this.state.canEdit && !this.props.disciplineState.calculating && !this.hasCustomIncome() && isEditable;
        const expandThis = this.state.expandThis;
        const totalIncome = getDisciplineTotal({calculatedDiscipline: this.props.calculatedDiscipline, discipline: this.props.discipline});
        const oldIncome = getOldTotalForDiscipline(this.state.discipline);

        const incomeDifference = this.props.discipline.fullCostCalculation && this.props.discipline.fullCostCalculation.customIncomeDifference ? this.props.discipline.fullCostCalculation.customIncomeDifference : 0;
        return (
            <div style={{ marginBottom: '8em' }}>
                <header style = { [commonStyles.bottomDivider, { marginBottom: measures.standard}] }>
                    <div style={{ display: 'table', width: '100%'}}>
                        <div style = {{ display: 'table-cell' }} >
                            <div style = { [layout.floatLeft, {width: '70px'}] }>
                                <DisciplineIcon diciplineType={ discipline.disciplineCategory } />
                            </div>
                            <div style = { [{ paddingTop:'12px', paddingBottom: '12px' }]}>
                                <h1 style = { disciplineNameStyle }>
                                    { t(discipline.disciplineCategory) + ': ' }
                                    { (() => {
                                        if (isCustomDiscipline) {
                                            return discipline.disciplineName ? discipline.disciplineName : t('custom_discipline');
                                        }
                                        return t(discipline.disciplineType);
                                    })() }
                                </h1>
                            </div>
                        </div>
                    </div>
                </header>
                {
                    (() => {
                        if(this.hasCustomIncome()) {
                            return (
                                <div style={{ overflow: 'hidden' }}>
                                    <div className="highlight">{t('edit_disabled_custom_income_set')}</div>
                                    <div style={{ display: 'flex', marginTop: measures.standard }}>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <button id="SubDisciplineForm_resetCustomIncome" onClick={() => this.props.resetCustomIncome() } style={{...commonStyles.button}}>{t(`Reset custom income for {0}`, t(discipline.disciplineCategory))}</button>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', marginTop: measures.standard }}>
                                        <div style={{ marginLeft: 'auto' }}>
                                            <button id="SubDisciplineForm_resetSubdisciplineCustomIncome" onClick={() => this.props.resetCustomIncome(discipline)} style={{...commonStyles.button}}>{t(`Reset custom income for {0}`, t(discipline.disciplineType))}</button>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })()
                }
                <form>
                    <ServiceLevelSelection
                        canEdit = { canEdit }
                        submitting = { submitting }
                        handleCancel = { this.handleCancel }
                        handleSave = { this.handleSave }
                        handleServiceLevelChange = { this.handleServiceLevelChange }
                        expandThis = { expandThis }
                        expandSection = { this.expandSection }
                        isEditable = {isEditable}
                        discipline={discipline}
                        isNewDiscipline={this.props.isNewDiscipline}
                        countries={this.props.countries}
                        disciplineConfig={this.props.disciplineConfig}
                        isCustomTermsAvailable={isCustomTermsAvailable}
                    />

                    {
                        (() => {
                            if(discipline.id) {
                                return (
                                    <div>
                                        <DisciplineTitle
                                            canEdit = { canEdit }
                                            discipline = { discipline}
                                            handleCancel = { this.handleCancel }
                                            submitting = { submitting }
                                            handleSave = { this.handleSave }
                                        />
                                        <div style = { [divStyles ] }>
                                            <div>
                                                <h2 style = { [{ marginBottom: measures.standard, marginTop: measures.standard }, commonStyles.pageSubTitle] } >{ t('systems') }</h2>
                                            </div>
                                        </div>
                                        <div style={ mainContainerStyle }>
                                            {
                                                (() => {
                                                    if (discipline.disciplineSystems && discipline.disciplineSystems.length > 0) {
                                                        return discipline.disciplineSystems.map((system, index) => {
                                                            const underEdit = this.state.systemUnderEdit === index;
                                                            const showDetails = this.state.showDetails === index;
                                                            const calculatedDiscipline = this.props.calculatedDiscipline || {};
                                                            const calculatedSystem = _.find(calculatedDiscipline.disciplineSystems, {id: system.id});
                                                            const systemTotals = _.find(calculatedDiscipline.systemsCostCalculation, {id: system.id});
                                                            return (
                                                                <div key={ index }>
                                                                    <SystemContainer
                                                                        index={index}
                                                                        system={ system }
                                                                        calculatedSystem={ calculatedSystem || {}}
                                                                        canEdit={ canEdit }
                                                                        editable={ underEdit }
                                                                        showDetails={ showDetails }
                                                                        handleSave={ (sys) => this.handleSystemSave(index, sys) }
                                                                        handleCancel={ () => this.handleCancel(index) }
                                                                        deleteSystem={ () => this.props.deleteSystem(system.id) }
                                                                        submitting={ submitting }
                                                                        editAction={ () => this.editSystem(index) }
                                                                        serviceLevel={ discipline.serviceLevel }
                                                                        resetField={ this.resetField }
                                                                        systemIndex={ index }
                                                                        newSystem={ this.state.indexOfNewSystem === index }
                                                                        calculating={this.props.disciplineState.calculating}
                                                                        discipline={ this.state.discipline }
                                                                        calculateCosts={(system) => this.calculateCosts(system, index)}
                                                                        systemTotals={systemTotals}
                                                                        expandSystem={this.props.system.editingSystem === system.id}
                                                                    />
                                                                </div>
                                                            );
                                                        });
                                                    }
                                                    return (<div style={ containerStyle }>{ t('no_systems_added') }</div>);
                                                })()
                                            }
                                            <div style={[ this.state.systemUnderEdit !== undefined || !isEditable || !canEdit ? layout.none : { marginTop: measures.standard, minHeight: measures.double } ]}>
                                                <div style={ layout.floatRight }>
                                                    <a id="SubDisciplineForm_newSystem" disabled = { submitting } onClick = { this.handleSystemAdd } style = { editButtonStyle }>
                                                        { t('add_new_system') }
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <DisciplineVisitPlan
                                            submitting = { submitting }
                                            canEdit = { canEdit }
                                            startEditAction = { this.startEditAction }
                                            handleCancel = { this.handleCancel }
                                            handleSave = { this.handleSave }
                                            discipline={this.state.discipline}
                                            needbased = { discipline.needbased }
                                            changeValue = { this.changeVisitPlanValue }
                                            expandThis = { expandThis }
                                            expandSection = { this.expandSection }
                                            calculateCosts = { this.calculateCostsAfterVisitplanChange }
                                            disciplines = { disciplines }
                                        />
                                    </div>
                                );
                            }
                        })()
                    }
                    <div style = { [ commonStyles.bottomDivider, { paddingTop: measures.double, paddingBottom: measures.double, clear: 'left'}] }>
                        {(() => {
                            if(incomeDifference !== 0) {
                                return (
                                    <div>
                                        <div style={{display:'table', width: '100%'}}>
                                            <div style={{display:'table-cell'}}>
                                                <h2 style = { layout.inlineBlock }>{ t('original_income') }</h2>
                                            </div>
                                            <div style={{display:'table-cell', textAlign: 'right'}}>
                                                <CostNumber>{ oldIncome }</CostNumber>
                                            </div>
                                        </div>
                                        <div style={{display:'table', width: '100%'}}>
                                            <div style={{display:'table-cell'}}>
                                                <h2 style = { layout.inlineBlock }>{ t('custom_income_difference') }</h2>
                                            </div>
                                            <div style={{display:'table-cell', textAlign: 'right'}}>
                                                <CostNumber>{ incomeDifference }</CostNumber>
                                            </div>
                                        </div>

                                    </div>
                                );
                            }
                        })()}
                        <div style={{display:'table', width: '100%'}}>
                            <div style={{display:'table-cell'}}>
                                <h2 style = { layout.inlineBlock }>{ t('totalIncome') }</h2>
                            </div>
                            <div style={{display:'table-cell', textAlign: 'right'}}>
                                <CostNumber id="SubDisciplineForm_totalIncome">{ totalIncome }</CostNumber>
                            </div>
                        </div>
                    </div>
                    <div style = {{ marginTop: measures.double}}>
                    </div>
                    <div style = {layout.floatRight}>
                        <div className="sub-discipline-form--close">
                            <div className="sub-discipline-form--close-button">
                                <CloseButton id="SubDisciplineForm_close" path={ `/client/${this.props.clientId}/contractInfo/${this.props.contractInfoId}/building/${discipline.buildingId}`} />
                            </div>
                            {
                                !this.isVisitPlanSet() && <div className="sub-discipline-form--close-tooltip"><div className="highlight">{t('error_no_visit_plan')}</div></div>
                            }
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        buildingState: state.building,
        discipline: state.discipline.discipline,
        disciplineState: state.discipline,
        profile: state.userProfile,
        disciplineMetadata: state.discipline.disciplineMetadata,
        disciplineOnReload: state.discipline.reload,
        disciplineOnError: state.discipline.error,
        calculatedDiscipline: state.discipline.calculatedDiscipline,
        system: state.system,
        countries: state.countries
    };
}
function mapDispatchToProps(dispatch) {
    return {
        calculateCosts: (discipline, accessToken) => dispatch(calculateDisciplineCosts(discipline, accessToken))
    }
}
const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(configuredRadium(SubDisciplineForm)))
