import React from 'react';
import {
    SFSelectField
} from 'components/index';
import _ from 'lodash';

const SensorSystemSelection = ({agreement, onChange, value, t, label, id, disabled, options}) => {

    return (
        <SFSelectField
            defaultInput={true}
            id={id}
            value={value}
            onChange={onChange}
            fieldName={label}
            disabled={disabled}
        >
            <option value={''}>{t('select')}</option>
            {
                options.map( option => {
                    const building = _.find(_.get(agreement, 'buildings', []), {id: option.buildingId});
                    return (
                        <option key={'system_' + option.id} value={option.id}>{building.name + ' ' + option.systemID + ' ' + option.equipmentType}</option>
                    );
                })
            }
            {
                /* options.map((discipline, disciplineIdx) => {
                    return discipline.disciplineSystems.map((system, sysIdx) => {
                        const building = agreement && agreement.buildings ? _.find(agreement.buildings, {id: system.buildingId}) : {};
                        const namefield = [];
                        if (building) namefield.push(building.name);
                        if (system.systemID) namefield.push(system.systemId);
                        if (system.equipmentType) namefield.push(system.equipmentType);
                        return (
                            <option
                                key={'discipline_system_' + disciplineIdx + '_' + sysIdx}
                                value={system.id}
                            >
                                {namefield.join(' - ')}
                            </option>);
                    });
                })*/

            }
        </SFSelectField>
    );
}

export default SensorSystemSelection;