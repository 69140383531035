import React, { Component } from 'react';
import { connect }          from 'react-redux';
import translations from 'decorators/translations.js';
import _ from 'lodash';
import { statusColor, getAgreementLink, getAgreementName } from 'components/WelcomeView/Utils';
import {
    Header,
    StandardPage,
    LoadingIndicator,
    DocumentList,
    DocumentListItem,
    BackLink,
} from 'components';
import {
    loadUserAgreements,
} from 'redux/modules/agreement.js';
import {
    loadUserFrameAgreements,
} from 'redux/modules/frameagreement.js';
import { localeDateString } from 'helpers/stringTemplates';

const containerStyles = {
    marginBottom: '3em'
};

class UserAgreementsView extends Component {
    componentDidMount() {
        const { dispatch, userProfile: { accessToken } } = this.props;
        return Promise.all([
            dispatch(loadUserAgreements(accessToken)),
            dispatch(loadUserFrameAgreements(accessToken)),
        ]);
    }

    render() {
        const {
            t,
            agreements,
            frameAgreements,
            agreementState
        } = this.props;
        if ((agreementState && agreementState.loading) || !agreements || !frameAgreements) {
            return <StandardPage>
                    <LoadingIndicator visible styles={{ fontSize: '25px' }} />
                </StandardPage>;
        }
        return <StandardPage>
            <BackLink title={t('Back to overview')}
                to={'/search'}/>
            <div style={ containerStyles }>
                <Header title={t('My frame agreements')}></Header>
                <DocumentList variant="compact">
                    {frameAgreements.map((agreement, index) => {
                        const agreementName = getAgreementName(agreement.agreementType);
                        return <DocumentListItem
                            key={ index }
                            id={'MyFrameAgreements_agreement_' + index}
                            icon="file-text-o"
                            iconLabel={ t(agreement.agreementStatus) }
                            iconColor={ statusColor(agreement.agreementStatus) }
                            title={ t(agreementName) + ' ' + _.get(agreement, 'contractInfo.client.name', 'N/A') }
                            to={ getAgreementLink(agreement) }
                        >
                            <p>
                                { localeDateString(agreement.modified || agreement.created) }
                            </p>
                        </DocumentListItem>;
                    })}
                </DocumentList>
            </div>
            <div style={ containerStyles }>
                <Header title={t('My agreements')}></Header>
                <DocumentList variant="compact">
                    {agreements.map((agreement, index) => {
                        const agreementName = getAgreementName(agreement.agreementType);
                        return <DocumentListItem
                            key={ index }
                            id={'MyAgreements_agreement_' + index}
                            icon="file-text-o"
                            iconLabel={ t(agreement.agreementStatus) }
                            iconColor={ statusColor(agreement.agreementStatus) }
                            title={ t(agreementName) + ' ' + _.get(agreement, 'contractInfo.client.name', 'N/A') }
                            to={ getAgreementLink(agreement) }
                        >
                            <p>
                                { localeDateString(agreement.modified || agreement.created) }
                            </p>
                        </DocumentListItem>;
                    })}
                </DocumentList>
            </div>
        </StandardPage>
    }
}

const mapStateToProps = (state) => ({
    userProfile: state.userProfile,
    agreementState: state.agreement,
    agreements: state.agreement.userAgreements,
    frameAgreements: state.frameagreement.userAgreements,
    error: state.error,
    clientState: state.client,
    translations: state.translations
});

const connector = connect(mapStateToProps);
export default translations(connector(UserAgreementsView));
