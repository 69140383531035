import React, {Component }            from 'react';

const infoRowStyle = {
    display: 'flex',
    flexFlow: 'row nowrap'
};
const infoLabelCellStyle = {
    padding: '0.75em',
    width: '50%'
};
const infoDataCellStyle = {
    padding: '0.75em',
    width: '50%',
    fontWeight : 'bold',
};

class DetailsDataContainerRow extends Component {

    render() {
        return (
            <div id={this.props.id} style = { infoRowStyle }>
                <div className="label" style = { infoLabelCellStyle }>{ this.props.rowLabel }:</div>
                <div className="value" style = { infoDataCellStyle }>{ this.props.rowValue }</div>
            </div>
    );}
}
export default DetailsDataContainerRow;
