import React                        from 'react';
import translations from 'decorators/translations.js';
import configuredRadium             from 'configuredRadium.js';
import { buildingAddressLine }      from 'helpers/stringTemplates';
import { Link }                     from 'react-router-dom';
import _ from 'lodash';

import {
    measures,
    colors,
    layout,
} from 'styles/design.js';

const itemContainer = {width: '100%', padding: measures.standard};
const itemCell = {
    verticalAlign: 'middle',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    lineHeight: '1.5em'
};
const itemStyle = [
    layout.block,
    {
        backgroundColor : colors.lightGray,
        marginTop: measures.quarter
    }
];
const resultStyle = {
    clientName: {
        fontFamily: 'Sanchez-Regular',
        fontSize: '1.5em',
        marginBottom: '10px',
        color : colors.blue
    },
    buildingCount: {
        color : colors.blue
    },
    buildingName: {
        fontFamily: 'Sanchez-Regular',
        color : colors.blue,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    buildingAddress: {
        color : colors.blue,
        fontWeight: 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    arrow: {
        color: 'white',
        float: 'right',
        fontSize: '1.2em'
    },

    Building: [
        itemStyle,
        {
            marginLeft: '1em'
        }
    ],
    Client: [
        itemStyle
    ]
};


const SearchAgreementsResults = (props) => {

    const highlight = (substr, str = '') => {
        const r = new RegExp('(' + substr + ')', 'gi');
        const html = str.replace(r, '<mark>$1</mark>');
        return { __html: html };
    };

    return (
        <ul>
        {
            props.searchResults.results.map((client) => {
                const linkToClient = '/client/' + client.id;
                let buildings = [];
                if (client.contractInfos && client.contractInfos.length > 0) {
                    _.each(client.contractInfos, info => {
                        if (info.buildings && info.buildings.length > 0) {
                            buildings = _.concat(buildings, info.buildings);
                        }
                    });
                }
                let buildingInfo = buildings.length > 0 ? buildings.length.toString() + ' ' + props.t('nBuildings') : props.t('noBuildings');
                if (buildings.length === 1) buildingInfo = '1 ' + props.t('building');

                return (
                    <div key={ 'result-client-' + client.id }>
                        <li style={ resultStyle.Client } key={ 'result-' + client.id }>
                            <Link key={client.id} to={ linkToClient } title={client.name}>
                                <div style={itemContainer}>
                                    <div style={resultStyle.clientName} dangerouslySetInnerHTML={ highlight(props.searchResults.searchString, client.name) } />
                                    <div style={resultStyle.buildingCount}>{ buildingInfo }</div>
                                </div>
                            </Link>
                        </li>
                    { (() => {
                        return buildings.map((building) => {
                            const linkToBuilding = '/client/' + client.id + '/contractInfo/' + building.contractInfoId + '/building/' + building.id;
                            return (
                                <li style={ resultStyle.Building } key={ 'result-' + building.id }>
                                <Link to={ linkToBuilding } title={building.name}>
                                    <div style={itemContainer}>
                                        <div style={itemCell}>
                                            <span style={resultStyle.buildingName} dangerouslySetInnerHTML={ building.name ? highlight(props.searchResults.searchString, building.name) : {__html:'(' + props.t('untitled') + ')'} } />
                                        </div>
                                        <div style={itemCell}>
                                            <span style = {resultStyle.buildingAddress} dangerouslySetInnerHTML={ highlight(props.searchResults.searchString, buildingAddressLine(building)) } />
                                        </div>
                                    </div>
                                </Link>
                                </li>
                            );
                        });
                    })()}
                    </div>
                );
            })
        }
        </ul>
    );
};
export default translations(configuredRadium(SearchAgreementsResults));
