import React, {Component}       from 'react';
import translations             from 'decorators/translations.js';
import { connect }              from 'react-redux';
import { withRouter }           from 'react-router-dom';
import _ from 'lodash';

import {
    loadTypes as loadBuildingTypes,
    save as saveBuilding
} from 'redux/modules/building';

import {
    loadAgreements
} from 'redux/modules/agreement.js';

import {
    StandardPage,
    BuildingForm,
    BreadcrumbBar,
    LoadingIndicator,
    Section,
    Header,
} from 'components';

import { loadCountries }                from 'redux/modules/country.js';
import { loadBillingIndexes }           from 'redux/modules/billingIndex';
import { loadBuildings }                from 'redux/modules/building';
import { loadClient }   from 'redux/modules/client.js';
import { loadContractInfo } from 'redux/modules/contractInfo.js';

class CreateBuildingView extends Component {
    constructor(props) {
        super(props);
        this.state = {dataLoaded: false};
    }
    componentWillReceiveProps(nextProps) {
        if(this.props.building.saving && nextProps.building.reload) {
            const newbuilding = nextProps.building.building;
            const {
                clientId,
                contractInfoId
            } = this.props.match.params;
            this.props.history.push(
                `/client/${clientId}/contractInfo/${contractInfoId}/agreement/${newbuilding.agreementId}`
            );
        }
    }
    componentWillMount() {
        const {
            client,
            contractInfo,
            profile,
            dispatch,
            match: {
                params
            }
        } = this.props;
        const promises = [];
        if (client.id !== params.clientId) {
            promises.push(dispatch(loadClient(params.clientId, profile.accessToken)));
        }
        if (contractInfo.id !== params.contractInfoId) {
            promises.push(dispatch(loadContractInfo(params.contractInfoId, profile.accessToken)));
        }
        if (client.id !== params.clientId || !this.props.agreement.loaded) {
            promises.push(dispatch(loadAgreements(params.clientId, profile.accessToken)));
        }
        promises.push(dispatch(loadBuildingTypes(profile.accessToken)));
        promises.push(dispatch(loadCountries(profile.accessToken)));
        promises.push(dispatch(loadBillingIndexes(profile.accessToken)));
        promises.push(dispatch(loadBuildings(params.clientId, profile.accessToken)));
        return Promise.all(promises).then(() => {
            this.setState({dataLoaded: true});
        })
    }
    handleSubmit = (userData) => {
        const {
            clientId,
            contractInfoId
        } = this.props.match.params;
        const data = _.cloneDeep(userData);

        data.clientId = clientId;
        data.contractInfoId = contractInfoId;
        data.salesArea = this.props.contractInfo.salesArea;
        if(!data.name) {
            return;
        }
        if(data.agreementId === 'new') {
            data.agreementId = null;
        }
        const dispatchSave = this.props.dispatch(saveBuilding(data, this.props.profile.accessToken)).then(() => {
            this.props.dispatch(loadAgreements(this.props.match.params.clientId, this.props.profile.accessToken));
        });

        if(data.ownBillingDetails === '0') {
            //Reset all billing fields, just in case.
            const billingFields = ['billingCompany', 'billingAddress', 'billingIndexId', 'billingRef', 'businessID', 'electronicInvoiceNumber', 'vatNumber'];
            billingFields.map((field) => {
                data[field] = null;
            });
        }

        if(!data.specificLocationAddress || data.specificLocationAddress === 'client') {
            data.locationAddress = null;
        }
        return dispatchSave;
    };

    handleCancel = () => {
        this.props.history.push('/client/' + this.props.match.params.clientId);
    };

    render() {
        const {
            t,
            match: {
                params
            }
        } = this.props;
        const agreementId = params && params.agreementId;

        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar />
                <Header title={t('building_info')} />
                {
                    (() => {
                        if(!this.state.dataLoaded) {
                            return <LoadingIndicator visible={true} />
                        }
                        return (
                            <Section>
                                <BuildingForm
                                    cancelAction = { this.handleCancel }
                                    building={{}}
                                    onSubmit={ this.handleSubmit }
                                    client={this.props.client}
                                    contractInfo={this.props.contractInfo}
                                    newBuilding={true}
                                    newAgreement={!agreementId}
                                    agreementId={agreementId}
                                />
                            </Section>
                        );
                    })()
                }

            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        client: state.client.client,
        building: state.building,
        agreement: state.agreement,
        contractInfo: state.contractInfo.contractInfo
    };
}

const connector = connect(mapStateToProps);
export default translations(withRouter(connector(CreateBuildingView)));