import React, {Component}           from 'react';

import {
    colors,
} from 'styles/design.js';

const styles = {
    headerBar: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '3em',
        backgroundColor: colors.blue,
        textAlign: 'center'
    },
    heading: {
        color: colors.white,
        fontFamily: 'Sanchez-Regular',
        fontWeight: 'normal',
        fontSize: '1.25em',
        textTransform: 'uppercase'
    }
};

class ProcessHeader extends Component {
    render() {
        return (
            <div style={ styles.headerBar }>
                <h1 style={ styles.heading }>Caverion Condition Control</h1>
            </div>
        );
    }
}

export default ProcessHeader;