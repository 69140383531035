import React, {Component}               from 'react';
import translations from 'decorators/translations.js';
import { connect }                      from 'react-redux';
import { isEditable }                   from 'helpers/agreement.js';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {
    DisciplineIcon
} from 'components';

import {
    measures,
    colors
} from 'styles/design.js';

const subItemRow = {
    display: 'flex',
    alignItems: 'center',
    marginTop: measures.standard,
    marginBottom: measures.standard,
    marginLeft: measures.double,
    cursor: 'pointer'
};
const subItemArrowContainer = {
    position: 'relative',
    display: 'inline-block',
    height: '20px',
    width: '10px',
    marginRight: measures.standard
};
const subItemArrow1 = {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    width: '2px',
    backgroundColor: colors.blue
};
const subItemArrow2 = {
    position: 'absolute',
    left: 0,
    bottom: 0,
    width: '100%',
    height: '2px',
    backgroundColor: colors.blue
};
const subItemArrow3 = {
    position: 'absolute',
    display: 'inline-block',
    right: '-3px',
    bottom: '-4px',
    height: 0,
    width: 0,
    borderTop: '6px solid transparent',
    borderBottom: '6px solid transparent',
    borderLeft: '6px solid ' + colors.blue
};
const disciplineIconStyle = {
    maxWidth: '35px',
    maxHeight: '30px',
    marginRight: measures.standard
};

class RenderDiscipline extends Component {
    render() {
        const {
            t,
            type,
            discipline,
            agreement,
            clientId,
            contractInfoId,
        } = this.props;

        return (
            <Link style={{ color: colors.blue }} to={'/client/' + clientId + '/contractInfo/' + contractInfoId + '/building/' + discipline.buildingId + '/discipline/' + discipline.id}>
                <div style={subItemRow}>
                    <span style={subItemArrowContainer}>
                        <span style={subItemArrow1} />
                        <span style={subItemArrow2} />
                        <span style={subItemArrow3} />
                    </span>
                    <DisciplineIcon imgStyle={disciplineIconStyle} diciplineType={discipline.disciplineCategory} />
                    {
                        (() => {
                            if(_.startsWith(discipline.disciplineType, 'custom_discipline')) {
                                return t(discipline.disciplineCategory) + ': ' + (discipline.disciplineName ? discipline.disciplineName : t(discipline.disciplineType));
                            }
                            return t(discipline.disciplineType);
                        })()
                    }
                    { type === 'building' && !isEditable(agreement) ? <span style={{marginLeft: '1em'}}><i className="fa fa-lock" /></span> : ''}
                </div>
            </Link>
        );
    }
}

export default translations(RenderDiscipline);
