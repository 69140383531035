import React, { Component } from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import {
    measures,
    layout,
    formStyles
} from 'styles/design.js';

import {
    SaveButton,
    CancelButton,
    SFInputField,
    SFSelectField,
    AddressSelector,
    DummyInputField,
} from 'components';

import { paymentTermOptions } from 'redux/modules/client.js';
import _ from 'lodash';

const shouldNotBeEmpty = (formFieldName) => {
    return (data, props, errors) => {
        if (props.form && props.form[formFieldName] && props.form[formFieldName].touched && !data[formFieldName]) {
            errors[formFieldName] = 'Value cannot be empty';
        }
        return errors;
    };
};

const validate = (data, props) => {
    let errors = {};
    errors = shouldNotBeEmpty('name')(data, props, errors);
    errors = shouldNotBeEmpty('salesArea')(data, props, errors);
    return errors;
};

const reduxFormObject = {
    form: 'contractInfoForm',
    fields: [
        'contactName',
        'contactStreet', 'contactStreetNumber', 'contactZip', 'contactCity', 'contactCountry', 'contactPhone', 'contactEmail',
        'billingCompany', 'billingStreet', 'billingStreetNumber', 'billingZip', 'billingCity', 'billingCountry', 'billingIndexId',
        'electronicInvoiceNumber', 'billingRef',
        'keyAccountManager', 'nationalServiceCenterPhone', 'salesArea', 'caverionEmail', 'departmentId', 'caverionPhone',
        'localDutyPhone', 'streetAddress', 'branchOfficePhone', 'paymentTerm',
        'billingAddress.street', 'billingAddress.zip', 'billingAddress.city',
        'specificBillingAddress',
        'caverionAddress.street', 'caverionAddress.zip', 'caverionAddress.city',
        'specificContactInformation'
    ],
    validate
};

class ContractInfoForm extends Component {
    static propTypes = {
        client: PropTypes.object,
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        cancelAction: PropTypes.func.isRequired,
        countries: PropTypes.array.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        const defaultCountry = this.props.user.salesAreas.length === 1 ? this.props.user.salesAreas[0] : '';
        const defaultValues = {
            isKeyCustomer: true,
            billingCountry: defaultCountry,
            contactCountry: defaultCountry,
            specificBillingAddress: 'specific',
            specificContactInformation: 'specific',
        };
        let contractInfo = _.cloneDeep(this.props.contractInfo);
        let initCountry = '';
        if (contractInfo) {
            initCountry = contractInfo.salesArea;
            contractInfo.specificBillingAddress = contractInfo.billingAddress && !_.isEmpty(contractInfo.billingAddress.street) ? 'specific' : 'client';
            contractInfo.specificContactInformation = contractInfo.contactEmail || contractInfo.contactPhone ? 'specific' : 'client';
        } else if (defaultCountry) {
            initCountry = defaultCountry;
            const country = _.find(this.props.countries, {id: defaultCountry});
            const nationalServiceCenterPhone = country && country.nationalPhoneNumber ? country.nationalPhoneNumber : null;
            contractInfo = {
                ...defaultValues,
                salesArea: defaultCountry, nationalServiceCenterPhone
            };
        }
        this.props.initializeForm(contractInfo || defaultValues);
        this.initializeData(initCountry);

    }

    initializeData(country, props) {
        this.updateDepartments(country, props || this.props);
        this.updateBillingIndexes(country, props || this.props);
    }

    updateDepartments(countryId, props) {
        const deps = [];
        if (props.departments) {
            props.departments.map((dep) => {
                if (dep.countryConfigId === countryId) {
                    deps.push(dep);
                }
            });
        }
        this.setState({countryDepartments: deps, countryId: countryId, departmentId: false});
    }

    updateBillingIndexes(country, props) {
        const countryBillingIndexes = _.filter(props.billingIndexes, {countryId: country}) || [];
        const defaultIndex = _.find(countryBillingIndexes, 'default') || {};
        props.fields.billingIndexId.onChange(defaultIndex.id);
        this.setState({countryBillingIndexes: countryBillingIndexes});
    }

    selectCountryOption = (val) => {
        if(val !== '0') {
            const fields = this.props.fields;
            fields.contactCountry.onChange(val);
            fields.billingCountry.onChange(val);
            fields.departmentId.onChange(null);
            fields.salesArea.onChange(val);
            if (_.isEmpty(fields.nationalServiceCenterPhone.value)) {
                const country = _.find(this.props.countries, {id: val});
                if (country && country.nationalPhoneNumber) {
                    fields.nationalServiceCenterPhone.onChange(country.nationalPhoneNumber);
                }
            }
            this.updateBillingIndexes(val, this.props);
            this.updateDepartments(val, this.props);
        }
    };

    selectDepartmentOption = (val) => {
        const department = _.find(this.props.departments, {id: parseInt(val, 10)});
        const fields = this.props.fields;
        fields.departmentId.onChange(val);
        if(_.isEmpty(fields.branchOfficePhone.value)) {
            fields.branchOfficePhone.onChange(department.localPhoneNumber);
        }
        if(_.isEmpty(fields.caverionAddress.street.value)) {
            if(department.address) {
                fields.caverionAddress.street.onChange(department.address);
            }
            if(department.zip) {
                fields.caverionAddress.zip.onChange(department.zip);
            }
            if(department.city) {
                fields.caverionAddress.city.onChange(department.city);
            }
        }

        this.setState({departmentId: val, department});
    };

    render() {
        const {
            fields: {
                contactName,
                billingCompany,
                billingIndexId,
                electronicInvoiceNumber, billingRef, contactEmail, contactPhone,
                keyAccountManager, nationalServiceCenterPhone, salesArea, caverionEmail, caverionPhone,
                departmentId, branchOfficePhone, localDutyPhone, paymentTerm,
                caverionAddress, billingAddress,
                specificBillingAddress,
                specificContactInformation,
            },
            client,
            t,
            handleSubmit,
            submitting
        } = this.props;
        const departments = this.state.countryDepartments ? this.state.countryDepartments : [];
        if(this.state.departmentId) {
            departmentId.value = this.state.departmentId;
        }
        if(!billingCompany.touched && _.isEmpty(billingCompany.value) && client && client.name) {
            billingCompany.value = client.name;
        }
        const hasClientAddress = client && !_.isEmpty(client.clientAddress);

        return <div>
            <SFSelectField
                id="ContractInfoForm_selectSalesArea"
                formProperty={salesArea}
                fieldName={this.props.t('caverion_sales_area')}
                onChangeAction={(e) => this.selectCountryOption(e.target.value)}
                style={{minWidth: '10em'}}
                >
                {(() => {
                    let options = [];
                    const content = [];
                    //If user is admin, show all countries. Otherwise show only user's sales aera.
                    if (this.props.user && this.props.user.isAdmin) {
                        content.push(<option key="0" value="0">{this.props.t('select')}</option>);
                        options = this.props.countries;
                    } else {
                        //options[0] = {id: this.props.user.salesArea};
                        if (this.props.user.salesAreas.length > 1) {
                            content.push(<option key="0" value="">{this.props.t('select')}</option>);
                        }
                        this.props.user.salesAreas.map((area) => {
                            options.push({id: area});
                        });
                    }
                    options.map((country) => {
                        content.push(<option key={'country_' + country.id} value={country.id}>{ this.props.t(country.id) }</option>);
                    });
                    return content;
                })()}
            </SFSelectField>
            <div style={{marginBottom: measures.double}}>
                {(() => {
                    if (!departments || departments.length === 0) {
                        return (<div>{t('department_selection_select_country')}</div>);
                    } else if (this.props.departmentsError) {
                        return (<div>{t('updating_departments_error')}</div>);
                    }
                    return (
                        <SFSelectField
                            id="ContractInfoForm_selectDepartment"
                            formProperty={departmentId}
                            fieldName={this.props.t('department')}
                            selectWidth={10}
                            onChangeAction={(e) => this.selectDepartmentOption(e.target.value)}>
                            <option value="0">{t('select')}</option>
                            {(() => {
                                if (departments && departments.length > 0) {
                                    return departments.map((dep, idx) => {
                                        return (
                                            <option value={dep.id} key={'department_' + idx}>{dep.name}</option>
                                        );
                                    });
                                }
                            })()}
                        </SFSelectField>
                    );
                })()}
            </div>
            <h2 style={{
                ...formStyles.subHeader,
                marginTop: 0
            }}>{t('contact_info')}</h2>
            <SFInputField id="ContractInfoForm_contactName" formProperty={contactName} fieldName={t('name')}/>
            <AddressSelector
                id="ContractInfoForm_specificContactSelection"
                options={
                    [
                        {
                            label: t('Use client email and phone number'),
                            value: 'client'
                        },
                        {
                            label: t('Set specific contact information'),
                            value: 'specific'
                        }
                    ]
                }
                formProperty={specificContactInformation}
                name="specificContactInformation"
            />
            {specificContactInformation.value === 'specific' ?
                <div>
                    <SFInputField id="ContractInfoForm_contactEmail" formProperty={contactEmail} fieldName={t('email')}/>
                    <SFInputField id="ContractInfoForm_contactPhone" formProperty={contactPhone} fieldName={t('phone')}/>
                </div>
                : <div>
                    <DummyInputField
                        label={t('email')}
                        value={client && client.email}
                    />
                    <DummyInputField
                        label={t('phone')}
                        value={client && client.clientPhone}
                    />
                </div>
            }

            <h2 style={formStyles.subHeader}>{t('billing_info')}</h2>
            <SFInputField id="ContractInfoForm_billingCompanyName" formProperty={billingCompany}
                            fieldName={t('company_name')}/>
            <SFSelectField
                id="ContractInfoForm_billingIndex"
                disabled={!salesArea.value || salesArea.value === ''}
                selectStyle={{width: '100% '}}
                formProperty={billingIndexId}
                fieldName={t('billing_index')}>
                {
                    (() => {
                        if (!salesArea.value || salesArea.value === '') {
                            return (<option value="">{t('select_country')}</option>);
                        } else if (!this.state.countryBillingIndexes || this.state.countryBillingIndexes.length === 0) {
                            return (<option value="">{t('no_billing_indexes_set_for_country')}</option>);
                        }
                        const rows = [];
                        rows.push(<option key="not_selected" value="">{t('select')}</option>);
                        this.state.countryBillingIndexes.map((index) => {
                            rows.push(
                                <option key={'idx_' + index.id} value={index.id}>
                                    {index.default ? '(' + t('default') + ')' : ''} {t(index.key)}
                                </option>
                            );
                        });
                        return rows;
                    })()
                }
            </SFSelectField>
            <AddressSelector
                id="ContractInfoForm_specificBillingAddressSelection"
                options={
                    [
                        {
                            label: t('use_client_address'),
                            value: 'client'
                        },
                        {
                            label: t('set_specific_billing_address'),
                            value: 'specific'
                        }
                    ]
                }
                formProperty={specificBillingAddress}
                name="specificBillingAddress"
            />
            {specificBillingAddress.value === 'specific' ?
                <div style={{ overflow: 'hidden' }}>
                    <SFInputField
                        id="ContractInfoForm_billingAddressStreet"
                        formProperty={billingAddress.street}
                        fieldName={t('street')}
                    />
                    <div>
                        <div style={formStyles.colStyle('left', '39%')}>
                            <SFInputField
                                id="ContractInfoForm_billingAddressZip"
                                formProperty={billingAddress.zip}
                                fieldName={t('zip')}
                            />
                        </div>
                        <div style={formStyles.colStyle('right', '60%')}>
                            <SFInputField
                                id="ContractInfoForm_billingAddressCity"
                                formProperty={billingAddress.city}
                                fieldName={t('city')}
                            />
                        </div>
                    </div>
                </div>
                : <div style={{ overflow: 'hidden' }}>
                    <DummyInputField
                        label={t('street')}
                        value={hasClientAddress && client.clientAddress.street}
                    />
                    <div>
                        <div style={formStyles.colStyle('left', '39%')}>
                            <DummyInputField
                                label={t('zip')}
                                value={hasClientAddress && client.clientAddress.zip}
                            />
                        </div>
                        <div style={formStyles.colStyle('right', '60%')}>
                            <DummyInputField
                                label={t('city')}
                                value={hasClientAddress && client.clientAddress.city}
                            />
                        </div>
                    </div>
                </div>
            }
            <SFInputField
                id="ContractInfoForm_electronicInvoiceNumber"
                formProperty={electronicInvoiceNumber}
                fieldName={t('electronic_invoice_number')}
            />
            <SFInputField
                id="ContractInfoForm_billingRef"
                formProperty={billingRef}
                fieldName={t('billing_ref')}
            />
            <SFSelectField
                id="ContractInfoForm_paymentTerm"
                formProperty={paymentTerm}
                fieldName={t('payment_term')}
            >
                {paymentTermOptions.map((option) =>
                    <option key={'option_' + option} value={option}>{option} {t('days')}</option>)
                }
            </SFSelectField>

            <h2 style={formStyles.subHeader}>{t('caverion_info')}</h2>
            <SFInputField
                id="ContractInfoForm_keyAccountManager"
                formProperty={keyAccountManager}
                fieldName={t('key_account_manager')}
            />
            <SFInputField
                id="ContractInfoForm_caverionEmail"
                formProperty={caverionEmail}
                fieldName={t('email')}
            />
            <SFInputField
                id="ContractInfoForm_caverionPhone"
                formProperty={caverionPhone}
                fieldName={t('phone')}
            />
            <div>
                <SFInputField
                    id="ContractInfoForm_caverionAddressStreet"
                    formProperty={caverionAddress.street}
                    fieldName={t('street')}
                />
                <div>
                    <div style={formStyles.colStyle('left', '39%')}>
                        <SFInputField
                            id="ContractInfoForm_caverionAddressZip"
                            formProperty={caverionAddress.zip}
                            fieldName={t('zip')}
                        />
                    </div>
                    <div style={formStyles.colStyle('right', '60%')}>
                        <SFInputField
                            id="ContractInfoForm_caverionAddressCity"
                            formProperty={caverionAddress.city}
                            fieldName={t('city')}
                        />
                    </div>
                </div>
            </div>
            <SFInputField
                id="ContractInfoForm_branchOfficePhone"
                formProperty={branchOfficePhone}
                fieldName={t('branch_office_phone')}
            />
            <SFInputField
                id="ContractInfoForm_localDutyPhone"
                formProperty={localDutyPhone}
                fieldName={t('local_duty_phone')}
            />
            <SFInputField
                id="ContractInfoForm_nationalServiceCenterPhone"
                formProperty={nationalServiceCenterPhone}
                fieldName={t('national_service_center_telephone')}
            />

            <div style={layout.floatRight}>
                <span style={{marginRight: measures.standard}}>
                    <CancelButton
                        cancelAction={this.props.cancelAction}
                    />
                </span>
                <SaveButton
                    id="ContractInfoForm_submit"
                    onClick={handleSubmit}
                    buttonType={'submit'}
                    disabled={submitting}
                    submitting={submitting}
                />
            </div>

        </div>;
    }
}

const mapStateToProps = state => ({
    user: state.userProfile.user
});

export default translations(reduxForm(reduxFormObject, mapStateToProps)(ContractInfoForm));