import React, {Component}            from 'react';
import PropTypes from 'prop-types';
import { colors, decorators, measures } from 'styles/design.js';

const infoContainerStyle = {
        padding: '2em',
    };

const infoContainerTitle = {
    lineHeight : measures.double,
    paddingTop: '0.75em',
    borderBottom: decorators.coloredBorder(colors.black)
};

const infoContainerTable = {
    width : '100%'
};

class DetailsDataContainer extends Component {

    static propTypes = {
        title: PropTypes.node
    };

    render() {
        return (
            <div style = { infoContainerStyle }>
                {this.props.noTitle ? '' : (<h2 style = { infoContainerTitle }>{ this.props.title }</h2>)}
                <div style = { infoContainerTable }>
                    { this.props.children }
                </div>
            </div>
    );}
}

export default DetailsDataContainer;
