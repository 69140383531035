import React from 'react';

import './Bundle.css';
import ConfirmableButton from '../Buttons/ConfirmableButton';

const BundleTitle = (props) => {
    return (
        <div className="bundle-header">
            <div className="bundle-title">{props.title}</div>
            {
                props.allowDelete &&
                <div className="bundle-delete">
                    <ConfirmableButton
                        size="xsmall"
                        variant="delete"
                        confirmText={props.t('are_you_sure') + '?'}
                        action={props.handleBundleDelete}
                        buttonText={props.t('delete')}
                    />
                </div>
            }
        </div>
    );
};

export default BundleTitle;