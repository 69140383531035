import React from 'react';

import './Bundle.css';

const BundleItem = (props) => {
    const handleSensorEdit = (e) => {
        e.preventDefault();
        if (props.sensor && props.sensor.id) {
            props.handleSensorEdit(props.sensor.id);
        }
    };

    const checkSensorDetails = () => {
        const sensor = props.sensor;
        if (sensor) {
            switch (sensor.type) {
                case 'ventilation':
                    if(!sensor.ahuSpeed || !sensor.ahuType || !sensor.disciplineSystemId) {
                        return false;
                    }
                    break;
                case 'electricity':
                    if(!sensor.mainSensor) {
                        return false;
                    }
                    break;
                case 'temperature': 
                    if(!sensor.temperatureLimits) {
                        return false;
                    }
                    break;
                case 'presence':
                    break;
                default:
                    break;
            }
            return true;
        }
    };

    const classNames = checkSensorDetails() ? 'bundle-item is-completed' : 'bundle-item';
    const title = props.sensor ? props.sensor.name : '';

    return (
        <div className={classNames} onClick={props.isEditable && handleSensorEdit}>
            <div className="bundle-item-title">{ props.t(title) } {props.isEditable && <i className="fa fa-edit" />}</div>
        </div>
    );
};

export default BundleItem;