import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {reduxForm} from 'redux-form';
import _ from 'lodash';

import {
    SFInputField,
    RichTextEditor,
    FileDetails,
    Button,
    LoadingIndicator,
} from 'components';

import {
    layout,
    measures,
    colors,
    fonts
} from 'styles/design.js';

import AdditionalFeeForm from "./Forms/AdditionalFeeForm";
import ServiceCarForm from "./Forms/ServiceCarForm";

const dataParentDiv = {
    paddingTop: measures.half,
    paddingLeft: measures.half,
    paddingRight: measures.half,
    marginTop: measures.half
};
const dataContentHeaderDiv = {
    backgroundColor: colors.lightBlue,
    padding: measures.half,
    marginTop: measures.standard,
    border: '1px solid ' + colors.lightGray,
    color: '#fff',
    ...fonts.plainsanches
};

const reduxFormObject = {
    form: 'frameAgreementForm',
    fields: [
        'id',
        'serviceCar[].name',
        'serviceCar[].value',
        'serviceCar[].valueType',
        'serviceCar[].description',
        'pricePerHour',
        'pricePerKm',
        'overtime50multiplier',
        'overtime100multiplier',
        'maxDiscount',
        'minSubcontractingMargin',
        'totalLiability',
        'totalLiabilityComment',
        'termsAndConditionsComment',
        'disciplines[].disciplineCategory',
        'disciplines[].description',
        'disciplines[].pricing[].name',
        'disciplines[].pricing[].basicCharge',
        'disciplines[].pricing[].overtime50',
        'disciplines[].pricing[].overtime100',
        'disciplines[].pricing[].customValues[]',
        'additionalFees[].name',
        'additionalFees[].value',
        'additionalFees[].valueType',
        'additionalFees[].description'
    ]
};

class FrameAgreementConfigForm extends Component {

    componentWillMount() {
        this.initForm({ ...this.props.countryConfig.frameAgreementConfig });
        this.setState({canEdit: true});
    }

    handleCancel = () => {
        this.initForm({ ...this.props.countryConfig.frameAgreementConfig });
        this.props.cancelAction();
    };

    initForm(config) {
        const options = this.props.serviceCarOptions;
        options.map((option) => {
            const existing = _.find(config.serviceCar, {name: option});
            // If any of the default cases is missing, push it back to the options
            if (!existing && config.serviceCar && _.isArray(config.serviceCar)) {
                config.serviceCar.push({name: option, value: '', description: ''});
            }
        });
        this.props.initializeForm(config);
    }

    descriptionChanged = (key, value) => {
        this.props.fields[key].onChange(value);
    };

    render() {
        const {t, fields, filesFound, showLoader, countryConfig} = this.props;
        return (
            <div>
                { /*
                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('terms_and_conditions_comment')}:
                    </div>
                    <RichTextEditor
                        onChange={this.descriptionChanged}
                        descriptionKey={'termsAndConditionsComment'}
                        value={this.props.countryConfig.termsAndConditionsComment}
                        label={t('comment')}
                    />
                </div>
                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('service_car')} (Deprecated - to be used only with old frame agreements)
                    </div>
                    <div style={{padding: measures.standard}}>
                        {
                            this.props.fields.serviceCar.map((serviceCarOption, idx) => {
                                console.log('service car option', serviceCarOption);
                                if (serviceCarOption.name && serviceCarOption.name.value === 'other') {
                                    return (<SFInputField key={'service_car_' + idx}
                                                          fieldName={t(serviceCarOption.name.value)}
                                                          formProperty={serviceCarOption.description}/> );
                                } else if (serviceCarOption.name && serviceCarOption.value) {
                                    return (<SFInputField key={'service_car_' + idx}
                                                          fieldName={t(serviceCarOption.name.value)}
                                                          formProperty={serviceCarOption.value}/>);
                                }
                            })
                        }
                    </div>
                </div>
                */
                }

                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('service_car')}
                    </div>
                    <div style={{paddingLeft: '1em', paddingTop: '1em'}}>
                        Options defined here will be translated if translations are available. If translation is needed,&nbsp;
                        provide the name of the service car field to who takes care of the translations.
                    </div>

                    <ServiceCarForm
                        serviceCar={fields.serviceCar}
                        t={t}
                        addField={() => this.props.fields.serviceCar.addField()}
                        removeField={(idx) => this.props.fields.serviceCar.removeField(idx)}
                    />
                </div>
                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('additional_fees')}
                    </div>

                    <AdditionalFeeForm
                        additionalFees={fields.additionalFees}
                        t={t}
                        addField={() => this.props.fields.additionalFees.addField()}
                        removeField={(idx) => this.props.fields.additionalFees.removeField(idx)}
                    />
                </div>
                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('price_values')}:
                    </div>
                    <div style={{padding: measures.standard}}>
                        <input type="hidden" {...fields.id} />
                        <SFInputField fieldName={t('overtime50multiplier')} formProperty={fields.overtime50multiplier}/>
                        <SFInputField fieldName={t('overtime100multiplier')}
                                      formProperty={fields.overtime100multiplier}/>
                        <SFInputField fieldName={t('max_discount')} formProperty={fields.maxDiscount}/>
                        <SFInputField fieldName={t('min_subcontracting_margin')}
                                      formProperty={fields.minSubcontractingMargin}/>
                    </div>
                </div>
                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('liability')}:
                    </div>
                    <div style={{padding: measures.standard}}>
                        <SFInputField fieldName={t('total_liability')} formProperty={fields.totalLiability}/>
                        <SFInputField fieldName={t('total_liability_comment')}
                                      formProperty={fields.totalLiabilityComment} rows={2}/>
                    </div>
                </div>
                <div style={{marginTop: measures.standard}}>
                    <div style={dataParentDiv}>
                        <div style={dataContentHeaderDiv}>
                            {t('attachment')}:
                        </div>
                        <FileDetails
                            language={'en'}
                            filename={'terms_and_conditions_en_' + countryConfig.id + '.pdf'}
                            uploadFile={this.props.uploadFile}
                            downloadFile={this.props.downloadFile}
                            deleteFile={this.props.deleteFile}
                            exist={!_.isEmpty(filesFound.en)}
                            loading={showLoader}
                        />
                        {
                            (() => {
                                if (countryConfig.defaultLanguage !== 'en') {
                                    return (
                                        <FileDetails
                                            language={countryConfig.defaultLanguage}
                                            filename={'terms_and_conditions_' + countryConfig.defaultLanguage + '_' + countryConfig.id + '.pdf'}
                                            uploadFile={this.props.uploadFile}
                                            downloadFile={this.props.downloadFile}
                                            deleteFile={this.props.deleteFile}
                                            exist={!_.isEmpty(filesFound.local)}
                                            loading={showLoader}
                                        />
                                    );
                                }
                            })()
                        }

                    </div>
                </div>
                <div style={{...layout.floatRight, ...layout.inlineBlock, clear: 'both', marginBottom: '1em'}}>
                    <div style={{...layout.inlineBlock, marginRight: '1em'}}>
                        <Button variant="cancel" onClick={this.handleCancel}>
                            {t('cancel')}
                        </Button>
                    </div>
                    <div style={layout.inlineBlock}>
                        <Button onClick={this.props.handleSubmit} disabled={this.props.submitting}>
                            <LoadingIndicator visible={this.props.submitting}/>
                            {this.props.label ? this.props.label : this.props.t('save')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default translations(reduxForm(reduxFormObject)(FrameAgreementConfigForm));