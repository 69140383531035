import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium from 'configuredRadium.js';
import {
    commonStyles,
    decorators,
    measures,
    layout,
    colors
} from 'styles/design.js';
import {
    SFMonthSelector,
    SFTextareaField,
    SFCheckBox,
    SaveButton,
    CancelButton,
    CalendarMatrix
} from 'components/index.js';
import _ from 'lodash';
import './DisciplineVisitPlan.css';

const mainContainerStyle = {
    padding: measures.standard + ' 0',
    overflow: 'hidden',
    borderBottom: decorators.coloredBorder(colors.lightGray)
};

const editButtonStyle = [
    commonStyles.buttonLink,
    {
        backgroundColor: colors.lightBlue
    }
];

class DisciplineVisitPlan extends Component {

    static propTypes = {
        discipline: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            showPlans: false
        };
    }

    componentWillMount() {
        if (!_.isEmpty(this.props.discipline)) {
            this.init(this.props.discipline);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.editing && !_.isEmpty(nextProps.discipline)) {
            this.init(nextProps.discipline);
        }
    }

    init(discipline) {
        const defaultVisitPlan = [];
        for (let i = 0; i < 12; i++) {
            defaultVisitPlan.push(false);
        }
        const form = {
            visitPlan: {
                value: !_.isEmpty(discipline.visitPlan) ? discipline.visitPlan : defaultVisitPlan
            },
            visitPlanComment: {
                value: discipline.visitPlanComment
            },
            needbased: {
                value: discipline.needbased,
                onChange: (value) => this.changeValue('needbased', value)
            }
        };
        Object.keys(form).map((key) => {
            if(!form[key].hasOwnProperty('onChange')) {
                form[key].onChange = (event, value) => this.changeValue(key, !event ? value : event.target.value);
            }
        });
        this.setState({form});
    }

    changeValue = (key, value) => {
        if (this.state.editing) {
            const form = Object.assign({}, this.state.form);
            if (key === 'needbased') {
                this.props.calculateCosts(value);
            }
            form[key].value = value;
            this.setState({form});
        }
    };
    startEdit = () => {
        this.setState({editing: true});
    };

    save = () => {
        const discipline = this.props.discipline;
        Object.keys(this.state.form).map((key) => {
            discipline[key] = this.state.form[key].value;
        });
        this.props.handleSave(discipline)
            .then(() => {
                this.setState({editing: false});
            });
    };

    cancel = () => {
        this.init(this.props.discipline);
        this.props.handleCancel();
        this.setState({editing: false});
    };

    togglePlans = (e) => {
        e.preventDefault();
        this.setState({
            showPlans: !this.state.showPlans
        });
    }

    render() {
        const {t, submitting, canEdit, discipline} = this.props;
        const form = this.state.form;
        let actives = 0;
        return (
            <div style={mainContainerStyle}>
                <h2 style={[{
                    marginBottom: measures.double,
                    marginTop: measures.standard
                }, commonStyles.pageSubTitle]}
                >
                    {t('visit_plan')}
                </h2>
                <div style={{backgroundColor: colors.lightGray}}>
                    {
                        (() => {
                            if (form.visitPlan.value && form.visitPlan.value.length > 0) {
                                form.visitPlan.value.map((month) => {
                                    if (month) {
                                        actives++;
                                    }
                                });
                            }
                            if (actives === 0 && !discipline.needbased && !this.state.editing) {
                                return (
                                    <div style={{padding: measures.double}}>
                                        <span>
                                            {t('no_visit_plan')}
                                        </span>
                                    </div>
                                );
                            }
                        })()
                    }
                </div>
                <div className="visitplan" style={ this.state.editing ? layout.block : layout.none }>
                    <div style={{ marginBottom: measures.double }}>
                        <SFCheckBox
                            labelId="DisciplineVisitPlan_needbased"
                            rightLabel={t('needbased')}
                            {...form.needbased}
                        />
                    </div>
                    <div style={form.needbased.value === true ? layout.none : layout.block}>
                        <SFMonthSelector
                            id="DisciplineVisitPlan__monthSelector"
                            defaultInput={true}
                            {...form.visitPlan}
                            editState={this.state.editing}
                        />
                    </div>

                    <div>
                        <h3 style={{paddingBottom: '0.5em'}}>{t('special_terms')}</h3>
                        {(() => {
                            if (this.state.editing) {
                                return (
                                    <div>
                                        <SFTextareaField
                                            id="DisciplineVisitPlan_specialTerms"
                                            defaultInput={true}
                                            {...form.visitPlanComment}
                                            placeholder={t('special_terms')}
                                            rows={4}/>
                                    </div>
                                );
                            }
                            return (
                                <div>
                                    <p>{discipline.visitPlanComment || '-'}</p>
                                </div>
                            );
                        })()}
                        <div style={{height: measures.double, marginBottom: measures.double}}>
                            { this.props.disciplines.length > 0 &&
                                <a href="" onClick={ e => this.togglePlans(e) } style={{ color: colors.blue, fontSize: '0.85em' }}>
                                    { !this.state.showPlans
                                        ? t('show_other_visit_plans')
                                        : t('hide_other_visit_plans')
                                    }
                                </a>
                            }
                            <div style={[layout.floatRight, this.state.editing ? layout.inlineBlock : layout.none]}>
                                <CancelButton
                                    tyle={commonStyles.smallCancelButtonStyle}
                                    cancelAction={this.cancel}
                                    disabled={submitting}
                                />
                                <SaveButton
                                    style={[commonStyles.smallSaveButton]}
                                    onClick={this.save}
                                    disabled={submitting}
                                    submitting={submitting}
                                />
                            </div>
                        </div>
                        { this.state.showPlans && this.props.disciplines.length > 0 &&
                            <div className="visitplan-matrix">
                                <CalendarMatrix
                                    items={ this.props.disciplines
                                        .filter((item) => {
                                            return item.visitPlan && !item.needbased && item.id !== discipline.id;
                                        })
                                        .map((item) => {
                                            return {
                                                title: t(item.disciplineType),
                                                months: item.visitPlan
                                            }
                                        }
                                    )} 
                                />
                            </div>
                        }
                    </div>
                </div>
                <div
                    style={[(actives > 0 || discipline.needbased) && !this.state.editing ? layout.block : layout.none, {
                        backgroundColor: colors.lightGreen,
                        padding: measures.double,
                        cursor: 'pointer'
                    }]}>
                    <b>{t('visit_plan')}:</b>&nbsp;
                    {

                        (() => {
                            if (discipline.needbased === true) {
                                return t('needbased');
                            }
                            const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];
                            let str = '';
                            if (discipline.visitPlan) {
                                discipline.visitPlan.map((isSelected, idx) => {
                                    if (isSelected) {
                                        str += t(months[idx]) + ', ';
                                    }
                                });
                                return (<b> {str.substring(0, str.length - 2)} </b>);
                            }
                        })()
                    }
                </div>
                <div style={[canEdit && !this.state.editing ? {
                    marginTop: measures.standard,
                    minHeight: measures.double
                } : layout.none]}>
                    <div style={[layout.floatRight, {marginTop: measures.half}]}>
                        <a
                            id="DisciplineVisitPlan_edit"
                            disabled={submitting}
                            onClick={this.startEdit}
                            style={editButtonStyle}>
                            {t('edit')}
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

export default translations(configuredRadium(DisciplineVisitPlan));
