import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import {
    SFInputField,
    RichTextEditor,
    Button
} from 'components';

import {
    layout,
    commonStyles,
    measures
} from 'styles/design.js';

const reduxFormObject = {
    form: 'countryConfigForm',
    fields: [
        'id',
        'monitoring.yearlyRemoteCenterFee',
        'monitoring.installationHours',
        'monitoring.materialCost',
        'monitoring.margin',
        'slaKitpackDescription'
    ]
};

class CountryMonitoringConfigForm extends Component {

    static propTypes = {
        countryConfig: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        cancelAction: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        languages: PropTypes.array.isRequired
    };

    componentWillMount() {
        this.props.initializeForm(this.props.countryConfig);
    }

    componentWillUnmount() {
        this.props.destroyForm();
    }

    descriptionChanged = (key, value) => {
        this.props.fields[key].onChange(value);
    };

    handleCancel = () => {
        this.props.initializeForm(this.props.countryConfig);
        this.props.cancelAction();
    }

    render() {
        const {t, fields} = this.props;

        return (
            <div>
                <div>
                    <form onSubmit={this.props.handleSubmit}>
                        <input type="hidden" {...fields.id} />

                        <div style={commonStyles.dataParentDiv}>
                            <div style={commonStyles.dataContentHeaderDiv}>
                                {t('kitpack_basic')}:
                            </div>

                            <div style={{marginTop: measures.standard}}>
                                <SFInputField
                                    oneline
                                    formProperty={fields.monitoring.yearlyRemoteCenterFee}
                                    fieldName={t('monitoring_yearly_remotecenter_fee')}
                                    placeholder="0,00"
                                    maxLength="6"
                                    type="number"
                                />

                                <SFInputField
                                    oneline
                                    formProperty={fields.monitoring.installationHours}
                                    fieldName={t('monitoring_installation_hours')}
                                    placeholder="0,00"
                                    maxLength="6"
                                    type="number"
                                />

                                <SFInputField
                                    oneline
                                    formProperty={fields.monitoring.materialCost}
                                    fieldName={t('monitoring_material_cost')}
                                    placeholder="0,00"
                                    maxLength="6"
                                    type="number"
                                />

                                <SFInputField
                                    oneline
                                    formProperty={fields.monitoring.margin}
                                    fieldName={t('monitoring_margin')}
                                    placeholder="0,00"
                                    maxLength="6"
                                    type="number"
                                />

                                <div>
                                    {/* This is in fact translated "Caverion Condition Control description" */}
                                    <RichTextEditor
                                        onChange={this.descriptionChanged}
                                        descriptionKey={'slaKitpackDescription'}
                                        value={this.props.countryConfig.slaKitpackDescription}
                                        label={t('sla_kitpack_description')}
                                    />
                                </div>

                                <div style={{...layout.floatRight, ...layout.inlineBlock, clear: 'both'}}>

                                    <Button
                                        id="CountryMonitoringConfigForm_cancel"
                                        onClick={this.handleCancel}
                                        variant="cancel"
                                    >
                                        {t('cancel')}
                                    </Button>
                                    <Button
                                        id="CountryMonitoringConfigForm_save"
                                        onClick={this.props.handleSubmit}
                                        disabled={this.props.submitting}
                                        loading={this.props.submitting}
                                    >
                                        {t('save')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default translations(reduxForm(reduxFormObject)(CountryMonitoringConfigForm));
