import React, {Component}        from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import { CostNumber }                       from 'components';
import { measures, layout, size }           from 'styles/design.js';

const tableCell = {
    padding: measures.half
};
const tableCellHeader = {
    ...tableCell,
    ...size.small,
    fontWeight: 'bold'
};

const lastColumn = {
    width: '100%',
    textAlign: 'right'
};

class RepairTable extends Component {

    static propTypes = {
        repairs: PropTypes.array,
        calculation: PropTypes.array
    }

    static defaultProps = {
        materials: [],
        calculation: [],
    }

    render() {
        const { calculation, repairs } = this.props;
        return (
            <div>
                    {(() => {
                        if ( repairs && repairs.length > 0) {
                            let totalSum = 0;
                            this.props.calculation.map((repair) => { totalSum += (repair.cost || 0); });
                            return (
                                <div>
                                        <table>
                                        <thead>
                                            <tr>
                                                <td style= { tableCellHeader }>{ this.props.t('description') }</td>
                                                <td style= { tableCellHeader }>{ this.props.t('material_quantity') }</td>
                                                <td style= { tableCellHeader }>{ this.props.t('material_cost') }</td>
                                                <td style= { tableCellHeader }>{ this.props.t('hours') }</td>
                                                <td style= { tableCellHeader }>{ this.props.t('visits') }</td>
                                                <td style= {{ ...tableCellHeader, ...lastColumn }}></td>
                                            </tr>
                                        </thead>
                                        {(() => {
                                            return repairs.map((repairRow, index) => {
                                                const calc = calculation && calculation[index] ? calculation[index] : {cost:0};
                                                return (
                                                    <tbody key = {'repairs_' + index + '_tbody'}>
                                                        <tr>
                                                            <td style= { tableCell }>{ repairRow.description }</td>
                                                            <td style= { tableCell }>{ repairRow.materialQuantity}</td>
                                                            <td style= { tableCell }>{ repairRow.materialCost }</td>
                                                            <td style= { tableCell }>{ repairRow.hours }</td>
                                                            <td style= { tableCell }>{ repairRow.visits }</td>
                                                            <td style= {{ ...tableCell, ...lastColumn }}><CostNumber style = {{ fontWeight: 'normal' }}>{ !calc.cost ? 0 : calc.cost}</CostNumber></td>
                                                        </tr>
                                                        <tr>
                                                            <td style = { tableCellHeader }>{ this.props.t('hours_visits_repetition') }</td>
                                                            <td style = { tableCell } colSpan="3">
                                                                { this.props.t('visits_repetition_' + (repairRow.multiplier || 1 )) }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                );
                                            });
                                        })()}
                                        </table>
                                        <div style = {{ marginBottom: measures.double, marginLeft: measures.half, marginRight: measures.half, fontWeight: 'bold' }}>{ this.props.t('repair_total') }<CostNumber style = { layout.floatRight }>{ totalSum }</CostNumber></div>
                                </div>
                            );
                        }
                        return (<div style = {{ marginBottom: measures.double, marginLeft: measures.half, marginRight: measures.half }}>{ this.props.t('no_repairs_added') }</div>);
                    })()}
          </div>
        );
    }
}

export default translations(RepairTable);