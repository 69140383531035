import React from 'react';

const TabLabels = ({children, vertical, fixed, ...other}) => {
    return (
        <div 
            className={vertical ? 'tabs-labels tabs-labels-vertical' : 'tabs-labels tabs-labels-horizontal'}
            {...other}
        >
            {children}
        </div>
    );
};

export default TabLabels;