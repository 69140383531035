import React from 'react';
import {connect} from 'react-redux';

import translations from 'decorators/translations.js';

import { isFrameAgreementEditable } from 'helpers/agreement';

import {
    StandardPage,
    BackLink,
    Header,
} from 'components';

import FrameAgreementPriceInfo from 'components/FrameAgreement/FrameAgreementPriceInfo';
import FrameAgreementPriceForm from 'components/FrameAgreement/FrameAgreementPriceForm';

import {
    loadContractInfo,
    isContractInfoLoaded
} from 'redux/modules/contractInfo';

import {
    loadFrameAgreement,
    shouldReloadFrameAgreement,
    saveFrameAgreement
} from 'redux/modules/frameagreement.js';

import {
    loadCountryDepartments,
    shouldLoadCountryDepartments
} from 'redux/modules/department.js';

import {
    loadCountry,
    isCountryLoaded
} from 'redux/modules/country.js';

import './FrameAgreement.css';

class FrameAgreementPricesView extends React.Component {
    state = {};

    componentWillMount() {
        const {match: {params}} = this.props;
        if (!isContractInfoLoaded(this.props, params.contractInfoId)) {
            this.props.loadContractInfo(params.contractInfoId, this.props.profile.accessToken).then((res) => {
                this.loadCountryAndDepartments()
            });
        } else {
            this.loadCountryAndDepartments();
        }
        if (shouldReloadFrameAgreement(this.props, params.frameAgreementId)) {
            this.props.loadFrameAgreement(params.frameAgreementId, this.props.profile.accessToken);
        }

    }

    loadCountryAndDepartments() {
        const contractInfo = this.props.contractInfo.contractInfo || null;
        if (!this.props.countries.loading && !isCountryLoaded(this.props, contractInfo.salesArea)) {
            this.props.loadCountry(contractInfo.salesArea, this.props.profile.accessToken);
        }
        if (!this.props.department.loading && shouldLoadCountryDepartments(this.props, contractInfo.salesArea)) {
            this.props.loadCountryDepartments(contractInfo.salesArea, this.props.profile.accessToken);
        }
    }

    handleSubmitAgreement = (data) => {
        const agreementId = this.props.match.params.frameAgreementId;
        const clientId = this.props.match.params.clientId;
        const contractInfoId = this.props.match.params.contractInfoId;
        if(data.serviceCarType === 'none' || data.serviceCarType === '-1') {
            data.serviceCarType = null;
        }
        data.contractualInfoId = contractInfoId;
        data.id = agreementId; // must exist
        //New service car setting with array of selections.
        if(data.serviceCarInputSelection === 'new') {
            data.serviceCarType = null;
            data.serviceCarDescription = null;
            data.serviceCarValue = null;
        } else if(data.serviceCarInputSelection === 'old') {
            data.serviceCar = [];
        } else {
            //For new frameagreements type selection is not shown.
            data.serviceCarType = null;
            data.serviceCarDescription = null;
            data.serviceCarValue = null;
        }

        this.props.dispatch(saveFrameAgreement(data, this.props.profile.accessToken)).then((res) => {
            if (res.result) {
                this.props.history.push(`/client/${clientId}/contractInfo/${contractInfoId}/frameagreement/${agreementId}`);
            }
        });
    };

    render() {
        const {t} = this.props,
            agreement = this.props.frameAgreement.agreement || {};
        const params = this.props.match.params;
        const basePath = `/client/${params.clientId}/contractInfo/${params.contractInfoId}/frameagreement/${agreement.id}`;

        const isEditable = isFrameAgreementEditable(this.props.frameAgreement.agreement);

        return (
            <StandardPage loaderVisible={this.props.frameAgreement.loading || this.props.countries.loading}>
                {
                    agreement &&
                    <BackLink title={t('Back to agreement')}
                              to={`${basePath}/summary`}/>
                }
                <Header title={t('prices')}/>
                <div>
                    {
                        (() => {
                            if (this.state.editing) {
                                return <FrameAgreementPriceForm
                                    t={t}
                                    onSubmit={this.handleSubmitAgreement}
                                    cancel={() => this.setState((state) => ({...state, editing: false}))}
                                    agreement={agreement}
                                    contractInfo={this.props.contractInfo.contractInfo}
                                    country={this.props.countries.selectedCountry}
                                    saving={this.props.frameAgreement.saving}
                                />;
                            }
                            return <FrameAgreementPriceInfo
                                toggleEdit={() => this.setState((state) => ({...state, editing: true}))}
                                country={this.props.countries.selectedCountry}
                                agreement={agreement}
                                t={t}
                                isEditable={isEditable}
                            />
                        })()
                    }
                </div>
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        department: state.department,
        contractInfo: state.contractInfo,
        countries: state.countries,
        frameAgreement: state.frameagreement,
        profile: state.userProfile,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadContractInfo: (id, token) => dispatch(loadContractInfo(id, token)),
        loadCountry: (salesArea, accessToken) => dispatch(loadCountry(salesArea, accessToken)),
        loadCountryDepartments: (salesArea, accessToken) => dispatch(loadCountryDepartments(salesArea, accessToken)),
        loadFrameAgreement: (agreementId, accessToken) => dispatch(loadFrameAgreement(agreementId, accessToken)),
        saveFrameAgreement: (agreement, accessToken) => dispatch(saveFrameAgreement(agreement, accessToken))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(FrameAgreementPricesView));
