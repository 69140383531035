import React, { Component }         from 'react';
import {
    ScrollToTopOnMount,
    Loader
}       from 'components';

import './StandardPage.css';

class StandardPage extends Component {

    hasSubNavigation = (children) => {
        let element = children;
        if(Array.isArray(children)) {
            element = children[0];
        }
        if(element && element.type) {
            return element.type.displayName === 'SubNavigation' || element.type.displayName === 'BreadcrumbBar';
        }
    }

    renderScrollComponent(disableScrollToTop) {
        if (!disableScrollToTop) {
            return <ScrollToTopOnMount/>;
        }
    }

    render() {
        let classes = ['standard-page'];

        if(this.hasSubNavigation(this.props.children) || this.props.withBreadcrumb) {
            classes.push('with-breadcrumb');
        }

        if(this.props.withSubNavigation) {
            classes.push('with-subnavigation');
        }

        if(this.props.withSideNavigation) {
            classes.push('with-sidenavigation');
        }

        if(this.props.variant) {
            classes.push(this.props.variant);
        }

        const classNames = classes.join(' ');

        return (
            <article className={ classNames }>
                { this.renderScrollComponent(this.props.disableScrollToTop) }
                { this.props.children }
                <Loader isVisible={ this.props.loaderVisible } />
            </article>
        );
    }
}

export default StandardPage;
