import { createReducerFromMapping } from 'redux/utils/index.js';
import FileSaver                    from 'file-saver';
import moment from 'moment-timezone';
import _ from 'lodash';

const initialState = {
    loading: false,
    loaded: false,
    error: {},
    documents: {},
    exportingList: [],
    gettingStatus: false,
    gettingStatusError: null,
    exporting: false,
    exportingError: null,
    filename: ''
};


export const DOWNLOAD           = 'Serviflex/Download/DOWNLOAD';
export const DOWNLOAD_SUCCESS   = 'Serviflex/Download/DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAIL      = 'Serviflex/Download/DOWNLOAD_FAIL';

export function download({downloadType, type, id, appendix, lang, name, clientName}, accessToken) {
    let url = '';
    let prefix =  '';
    switch(downloadType) {
        case 'html':
            prefix = '/printHtml';
            break;
        case 'preview':
            prefix = '/printPreview';
            break;
        case 'terms':
        case 'faTerms':
        case 'disciplineTerms':
        case 'manual':
            prefix = '/files';
            break;
        default:
            prefix = '/print';
    }
    switch(type) {
        case 'agreement':
            if(appendix) {
                url = '/agreement/' + id + '/' + appendix + '/';
            } else {
                url = '/agreement/' + id + '/';
            }
            break;
        case 'frameagreement':
            url = '/frameagreement/' + id + '/';
            break;
        case 'kitpack':
            url = '/kitpack/' + id + '/';
            break;
        case 'terms':
            url = '/agreementfiles/mainterms/' + name;
            break;
        case 'faTerms':
            url = '/agreementfiles/frameterms/' + name;
            break;
        case 'disciplineTerms':
            url = '/agreementfiles/disciplineterms/' + name;
            break;
        case 'manual':
            url = '/manuals/contracttool/' + name;
            break;
        default:
            url = '';
    }
    url = prefix + url;
    if(lang) {
        url = url + lang;
    }
    let filename = '';
    if(downloadType === 'terms' || downloadType === 'faTerms' || downloadType === 'disciplineTerms') {
        filename = name;
    } else {
        filename = (clientName ? clientName : type)
            + (appendix ? '-' + appendix : '')
            + '_' + moment().format('YYYY-MM-DD')
            + '_' + id + '.pdf';
    }
    return {
        filename,
        types: [DOWNLOAD, DOWNLOAD_SUCCESS, DOWNLOAD_FAIL],
        promise: client => client.get(url, { accessToken, blob: true })
    };
}



export const START_EXPORT           = 'Serviflex/Download/START_EXPORT';
export const START_EXPORT_SUCCESS   = 'Serviflex/Download/START_EXPORT_SUCCESS';
export const START_EXPORT_FAIL      = 'Serviflex/Download/START_EXPORT_FAIL';
/**
 *
 * @param {*}
 *  printoutType = Empty if whole agreement, sla, disciplines etc if needed only partion
 *  type = type of the export. PDF or xls, etc.
 */
export function startExport({ agreementId = '', frameAgreementId = '', lang = 'en', printoutType = '', filetype = 'pdf' }, accessToken) {
    const queryString = `agreementId=${agreementId}&frameAgreementId=${frameAgreementId}&lang=${lang}&printoutType=${printoutType}&filetype=${filetype}`;
    const url = `/export/generate?${queryString}`;

    return {
        types: [START_EXPORT, START_EXPORT_SUCCESS, START_EXPORT_FAIL],
        promise: client => client.get(url, { accessToken }),
        agreementId,
        frameAgreementId,
    }
}

export const EXPORT_STATUS           = 'Serviflex/Download/EXPORT_STATUS';
export const EXPORT_STATUS_SUCCESS   = 'Serviflex/Download/EXPORT_STATUS_SUCCESS';
export const EXPORT_STATUS_FAIL      = 'Serviflex/Download/EXPORT_STATUS_FAIL';
export function exportStatus(id, accessToken) {
    return {
        types: [EXPORT_STATUS, EXPORT_STATUS_SUCCESS, EXPORT_STATUS_FAIL],
        promise: client => client.get(`/export/status/${id}`, { accessToken })
    };
}


export const EXPORT_DOWNLOAD           = 'Serviflex/Download/EXPORT_DOWNLOAD';
export const EXPORT_DOWNLOAD_SUCCESS   = 'Serviflex/Download/EXPORT_DOWNLOAD_SUCCESS';
export const EXPORT_DOWNLOAD_FAIL      = 'Serviflex/Download/EXPORT_DOWNLOAD_FAIL';
export function exportDownload(id, accessToken) {
    return {
        types: [EXPORT_DOWNLOAD, EXPORT_DOWNLOAD_SUCCESS, EXPORT_DOWNLOAD_FAIL],
        promise: client => client.get(`/export/download/${id}`, { accessToken, blob: true })
    };
}

export default createReducerFromMapping({
    [DOWNLOAD]: (state, action) => {
        return {
            ...state,
            loading: true
        };
    },
    [DOWNLOAD_SUCCESS]: (state, action) => {
        FileSaver.saveAs(action.result, action.filename);

        return {
            ...state,
            loading: false,
            loaded: true
        };
    },
    [DOWNLOAD_FAIL]: (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: false,
            error: action.error
        };
    },
    [START_EXPORT]: state => {
        return {
            ...state,
            exporting: true,
        }
    },
    [START_EXPORT_SUCCESS]: (state, action) => {
        const obj = Object.assign({}, action.result);
        if (action.agreementId) {
            obj.agreementId = action.agreementId;
        }
        if (action.frameAgreementId) {
            obj.frameAgreementId = action.frameAgreementId;
        }
        obj.created = new Date().toISOString();
        // Copy current list of exports.
        const exportingList = state.exportingList.slice(0);
        exportingList.push(obj);
        return {
            ...state,
            exporting: false,
            exported: true,
            exportingList,
        }
    },
    [START_EXPORT_FAIL]: (state, action) => {
        return {
            ...state,
            exportingError: action.error
        }
    },
    [EXPORT_STATUS]: state => {
        return {
            ...state,
            gettingStatus: true
        }
    },
    [EXPORT_STATUS_SUCCESS]: (state, action) => {
        const objIndex =_.findIndex(state.exportingList, row => row.agreementId === action.agreementId || row.frameAgreementId === action.frameAgreementId);
        const newlist = state.exportingList.slice(0);
        newlist[objIndex].status = action.result.status;
        return {
            ...state,
            gettingStatus: false,
            exportingList: newlist,
        }
    },
    [EXPORT_STATUS_FAIL]: (state, action) => {
        return {
            ...state,
            gettingStatus: false,
            gettingStatusError: action.error
        }
    },
    [EXPORT_DOWNLOAD]: (state, action) => {
        return {
            ...state,
            loading: true
        };
    },
    [EXPORT_DOWNLOAD_SUCCESS]: (state, action) => {
        const exportObject = _.last(_.get(state, 'exportingList'));
        const filename = exportObject ? exportObject.filename : 'agreement.pdf';
        FileSaver.saveAs(action.result, filename);

        return {
            ...state,
            loading: false,
            loaded: true
        };
    },
    [EXPORT_DOWNLOAD_FAIL]: (state, action) => {
        return {
            ...state,
            loading: false,
            loaded: false,
            error: action.error
        };
    },
}, initialState);
