import React from 'react';

import {
    SFInputField,
    Button
} from 'components';

import {
    commonStyles,
    measures,
} from 'styles/design';

const FrameAgreementSubcontractingForm = ({t, subcontracting, handleAddSubcontracting, handleRemoveSubcontracting}) => {
    return (
        <div style={commonStyles.dataParentDiv}>
            <div style={commonStyles.dataContentHeaderDiv}>
                {t('handling_of_subcontractor')}:
            </div>
            {
                subcontracting && subcontracting.length > 0 &&
                <div style={{
                    paddingTop: measures.standard,
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'no-wrap'
                }}>
                    <div style={{flexGrow: 4, marginRight: measures.half}}>{t('name')}</div>
                    <div style={{flexGrow: 2}}>{t('minimum_margin')}</div>
                    <div style={{flexGrow: 1}}/>
                </div>
            }
            {
                subcontracting.map((subcontract, index) => (
                    <div
                        key={'subcontracting_index_' + index}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'no-wrap'
                        }}>
                        <div style={{flexGrow: 4, marginRight: measures.half}}>
                            <SFInputField
                                formProperty={subcontract.name}
                                placeholder={t('name')}
                            />
                        </div>
                        <div style={{flexGrow: 2, marginRight: measures.half}}>
                            <SFInputField
                                formProperty={subcontract.margin}
                                showErrorBelow={true}
                            />
                        </div>
                        <div style={{flexGrow: 1}}>
                            <div style={{paddingTop: '1.5em'}}>
                                <Button variant="delete" onClick={() => handleRemoveSubcontracting(index)}><i
                                    className="fa fa-trash"/></Button>
                            </div>
                        </div>
                    </div>
                ))
            }
            <div style={{marginTop: measures.standard}}>
                <Button
                    variant="add"
                    id="FrameAgreementForm_buttonAddSubcontracting"
                    onClick={handleAddSubcontracting}
                >
                    {t('add_subcontracting')}
                </Button>
            </div>
        </div>
    );
}

export default FrameAgreementSubcontractingForm;