import React, {Component }   from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import { connect }                      from 'react-redux';
import {
    commonStyles,
    colors
}                                       from 'styles/design.js';

import {
  LoadingIndicator
}                                       from 'components';
import { getLanguage } from 'helpers/languageHelper';

class PrintAgreementButton extends Component {

    static propTypes = {
        agreementId: PropTypes.number.isRequired,
        allowPrint: PropTypes.func,
        label: PropTypes.string,
        style: PropTypes.any,
    };

    buttonClicked(event) {
        if (typeof(this.props.allowPrint) === 'function') {
            if (!this.props.allowPrint() ) {
                event.preventDefault();
                return false;
            }
        }
        return true;
    }

    render() {
        const { t, submitting, loading, agreementId, agreement, language } = this.props;
        const lang = language || agreement.language || getLanguage(this.props.translations.language);
        return (
            <a onClick={ (event) => this.props.open(event, 'print', 'agreement', agreementId, this.props.appendix, lang) }
            style = { [ commonStyles.buttonLink, {backgroundColor: colors.lightBlue}, this.props.style ? this.props.style : {}] }>
                <LoadingIndicator visible = { submitting || loading } />
                <i className="fa fa-print" /> { this.props.label ? this.props.label : t('print_agreement') }
            </a>
        );
    }
}

function mapStateToProps(state) {
    return {
        translations: state.translations,
        profile: state.userProfile,
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(PrintAgreementButton)));
