import React from 'react';
import translations from 'decorators/translations.js';

import './Badge.css';

const Badge = ({type, title, t}) => {
    const baseClass = "badge";
    let cssClasses = [baseClass];

    if (type) {
        cssClasses.push(baseClass + '-' + type);
    } else {
        cssClasses.push(baseClass + '-primary');
    }

    return (
        <span className={ cssClasses.join(' ') }>{ title ? title : t(type) }</span>
    );
};

export default translations(Badge);