import React, { Component }     from 'react';
import translations from 'decorators/translations.js';
import { connect }              from 'react-redux';
import configuredRadium         from 'configuredRadium.js';
import _                        from 'lodash';
import {
    StandardPage,
    AdminSubNavigation,
    BreadcrumbBar,
    FileDetails,
    TranslationForm
}                               from 'components';

import {
    commonStyles,
}                               from 'styles/design.js';

import {
    loadCountry,
    saveCountry
}                               from 'redux/modules/country.js';

import {
    loadFiles,
    uploadFile,
    deleteFile
}                               from 'redux/modules/files.js';
import { download } from 'redux/modules/download.js';
import {loadWithType} from 'redux/modules/translations.js';

const dataParentDiv = commonStyles.dataParentDiv;
const dataContentHeaderDiv = commonStyles.dataContentHeaderDiv;

const LOCALIZABLES = [
    'mainagreement_terms_clientnet_p1',
    'mainagreement_terms_p2',
];

class TermsAndConditionsConfigurationView extends Component {
    componentDidMount() {
        this.setState({ comment: this.props.selectedCountry.termsAndConditionsComment ? this.props.selectedCountry.termsAndConditionsComment : '' });
        const { dispatch, match: {params}} = this.props;
        return Promise.all([
            dispatch(loadFiles('agreementfiles', 'mainterms', this.props.profile.accessToken)),
            dispatch(loadCountry(params.countryId, this.props.profile.accessToken))
        ]).then(() => {
            if(this.props.selectedCountry) {
                dispatch(loadWithType(2, 'en,' + this.props.selectedCountry.defaultLanguage, this.props.selectedCountry.id, this.props.profile.accessToken));
            }
        });
    }

    componentWillReceiveProps(newprops) {
        if (this.state.isUploading) {
            if (newprops.files.uploaded) {
                this.props.dispatch(loadFiles('agreementfiles', 'mainterms', this.props.profile.accessToken));
                this.setState({ isUploading: false, isLoadingFiles: true });
            }
        }
        if (this.state.isLoadingFiles) {
            if (newprops.files.filesLoaded) {
                const locales = [];

                if (this.props.files && this.props.files instanceof Array) {
                    this.props.files.map((file) => {
                        let f = file.name;
                        f = f.split('_');
                        if (f[3].length === 2) {
                            locales.push(f[3]);
                        }
                    });
                }

                const conf = this.props.selectedCountry;
                if (!conf.frameAgreementConfig) {
                    conf.frameAgreementConfig = {};
                }
                conf.attachments = locales;

                this.props.dispatch(saveCountry(conf, this.props.profile.accessToken));
                this.setState({ isLoadingFiles: false });
            }
        }
        if (this.props.translations.saving && newprops.translations.saved) {
            this.props.dispatch(loadWithType(2, 'en,' + this.props.selectedCountry.defaultLanguage, this.props.selectedCountry.id, this.props.profile.accessToken));
        }
    }

    checkExisting(files, selectedCountry) {
        const found = {};
        _.forEach(files, function check(value) {
            if (value.name === 'terms_and_conditions_en_' + selectedCountry.id + '.pdf') {
                found.en = value;
            }
            if (value.name === 'terms_and_conditions_' + selectedCountry.defaultLanguage + '_' + selectedCountry.id + '.pdf') {
                found.local = value;
            }
        });
        return found;
    }

    toggleEdit(val) {
        this.setState({ editComment: val });
    }

    handleSubmit() {
        const conf = this.props.selectedCountry;
        conf.termsAndConditionsComment = this.state.comment;
        const dispatchSave = this.props.dispatch(saveCountry(conf, this.props.profile.accessToken));
        this.setState({ editComment: false });
        return dispatchSave;
    }

    handleChange(event) {
        this.setState({ comment: event.target.value });
    }

    descriptionChanged = (key, value) => {
        this.setState({ comment: value });
    };

    deleteFile = (event, filename) => {
        this.setState({ deletingFile: false });
        this.props.deleteFile(
            'agreementfiles',
            'mainterms',
            filename,
            this.props.profile.accessToken
        ).then( res => {
            if (!res.error) {
                this.props.dispatch(loadFiles('agreementfiles', 'mainterms', this.props.profile.accessToken));
                this.setState({ deletingFile: false, isLoadingFiles: true });
            } else {
                this.setState({ deletingFile: false, error: res.error.error });
            }
        })
        this.setState({ deletingFile: filename });
    }

    downloadFile = (event, name) => {
        event.preventDefault();
        this.props.downloadFile('terms', name, 'terms', this.props.profile.accessToken);
    };
    uploadFile = (file, filename) => {
        this.setState({ isUploading: true });
        this.props.uploadFile(
            'agreementfiles',
            'mainterms',
            file,
            filename,
            this.props.profile.accessToken
        ).then( res => {
            if (!res.error) {
                this.props.dispatch(loadFiles('agreementfiles', 'mainterms', this.props.profile.accessToken));
                this.setState({ isUploading: false, isLoadingFiles: true });
            } else {
                this.setState({ isUploading: false, error: res.error.error });
            }
        })
    };

    render() {
        const { t, selectedCountry, translations } = this.props;
        const filesFound = this.checkExisting(this.props.files.files, selectedCountry);


        const list = {};
        if (translations && translations.translatables && selectedCountry && selectedCountry.defaultLanguage) {
            LOCALIZABLES.forEach(key => {
                if (key === 'mainagreement_terms_p2') {
                    const translatable = {
                        value: selectedCountry.termsAndConditionsComment,
                        disabled: false
                    };
                    list[key] = translations.translatables[key] || {};
                    if (_.isEmpty(list[key].en)) {
                        list[key].en = translatable;
                    }
                    if (_.isEmpty(list[key][selectedCountry.defaultLanguage])) {
                        list[key][selectedCountry.defaultLanguage] = translatable;
                    }
                }
                else {
                    list[key] = translations.translatables[key];
                };
            })
        }

        return (
            <StandardPage withBreadcrumb withSideNavigation>
                <BreadcrumbBar
                    page="admin"
                    path={this.props.location.pathname}
                />
                <AdminSubNavigation params={this.props.match.params} activeNavi="termsAndConditionsConfig" />
                <header>
                    <h1 style={ commonStyles.pageTitle }>{ this.props.t(this.props.match.params.countryId) }</h1>
                </header>

                <p>{t('terms_files_description')}</p>

                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('attachment')}:
                    </div>

                    <FileDetails
                        language={'en'}
                        filename={'terms_and_conditions_en_' + selectedCountry.id + '.pdf'}
                        uploadFile={this.uploadFile}
                        downloadFile={this.downloadFile}
                        deleteFile={this.deleteFile}
                        exist={!_.isEmpty(filesFound.en)}
                        loading={this.props.files.loading || this.props.files.uploading || this.props.download.loading}
                    />
                    {
                        (() => {
                            if(selectedCountry.defaultLanguage !== 'en') {
                                return (
                                    <FileDetails
                                        language={selectedCountry.defaultLanguage}
                                        filename={'terms_and_conditions_' + selectedCountry.defaultLanguage + '_' + selectedCountry.id + '.pdf'}
                                        uploadFile={this.uploadFile}
                                        downloadFile={this.downloadFile}
                                        deleteFile={this.deleteFile}
                                        exist={!_.isEmpty(filesFound.local)}
                                        loading={this.props.files.loading || this.props.files.uploading || this.props.download.loading}
                                    />
                                );
                            }
                        })()
                    }
                </div>

                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('comment')}:
                    </div>

                    {/*
                        (() => {
                            if (!this.state.editComment) {
                                return (
                                    <div>
                                        <div style={{
                                            width: '100%',
                                            marginBottom: measures.standard,
                                            marginTop: measures.standard
                                        }}>
                                            <div style={ [layout.floatRight] }>
                                                <button type="button"
                                                    onClick={ this.toggleEdit.bind(this, true)}
                                                    style={ [
                                                        commonStyles.button,
                                                        { backgroundColor: colors.lightBlue }
                                                    ] }>
                                                    { t('edit') }
                                                </button>
                                            </div>
                                        </div>

                                        <RichTextView
                                            labelStyle={{
                                                padding: measures.standard,
                                                marginRight: measures.quadruple,
                                                border: 'thin solid ' + colors.lightGray
                                            }}
                                            value={selectedCountry.termsAndConditionsComment}
                                        />
                                    </div>
                                );
                            }

                            return (
                                <div>
                                    <RichTextEditor
                                        onChange={ this.descriptionChanged }
                                        descriptionKey={'termsAndConditionsComment'}
                                        value={ selectedCountry.termsAndConditionsComment }
                                        label={t('comment')}
                                    />
                                    <div style={ [layout.floatRight, layout.inlineBlock, { clear: 'both' }] }>
                                        <SaveButton
                                            onClick={ this.handleSubmit.bind(this)}
                                            disabled={ this.props.submitting }
                                            submitting={ this.props.submitting }
                                        />
                                        <div style={{ display: 'inline-block', float: 'right' }}>
                                            <CancelButton cancelAction={ this.toggleEdit.bind(this, false)} />
                                        </div>
                                    </div>
                                </div>
                            );
                        })()
                    */}
                    <div style={{ marginTop: '2em' }}>
                        <TranslationForm
                            localizables={LOCALIZABLES}
                            country={selectedCountry}
                            type={2}
                            richText = {true}
                            list={list}
                        />
                    </div>
                </div>
            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        countries: state.countries.countries,
        clientError: state.client.error,
        selectedCountry: state.countries.selectedCountry,
        languages: state.translations.languages,
        profile: state.userProfile,
        files: state.files,
        download: state.download,
        translations: state.translations,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        downloadFile: (downloadType, name, type, accessToken) => {
            return dispatch(download({downloadType, name, type}, accessToken));
        },
        uploadFile: (share, dir, file, filename, accessToken) => {
            return dispatch(uploadFile(share, dir, file, filename, accessToken))
        },
        deleteFile: (share, dir, filename, accessToken) => {
            return dispatch(deleteFile(share, dir, filename, accessToken))
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(configuredRadium(TermsAndConditionsConfigurationView)));
