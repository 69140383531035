import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';
import {
    saveFrameAgreement
} from 'redux/modules/frameagreement';

import {
    paymentTermOptions
} from 'redux/modules/client.js';
import {
    isCountryLoaded,
    loadCountry
} from 'redux/modules/country';
import {
    loadContractInfo,
    isContractInfoLoaded,
} from 'redux/modules/contractInfo';

import {
    loadCountryDepartments,
    shouldLoadCountryDepartments
} from 'redux/modules/department.js';

import {
    StandardPage,
    BreadcrumbBar
} from 'components';

import {
    commonStyles
} from 'styles/design.js';

import FrameAgreementDetailsForm from "components/FrameAgreement/FrameAgreementDetailsForm";
import _ from 'lodash';

class CreateFrameAgreementView extends Component {
    state={form: {}};
    componentWillMount() {
        const {match: {params}} = this.props;
        if (!isContractInfoLoaded(this.props, params.contractInfoId)) {
            this.props.loadContractInfo(params.contractInfoId, this.props.profile.accessToken).then((res) => {
                this.loadCountryAndDepartments()
            });
        } else {
            this.loadCountryAndDepartments();
        }
    }

    loadCountryAndDepartments() {
        const contractInfo = this.props.contractInfo.contractInfo || null;
        if (!this.props.countries.loading && !isCountryLoaded(this.props, contractInfo.salesArea)) {
            this.props.loadCountry(contractInfo.salesArea, this.props.profile.accessToken);
        }
        if (!this.props.department.loading && shouldLoadCountryDepartments(this.props, contractInfo.salesArea)) {
            this.props.loadCountryDepartments(contractInfo.salesArea, this.props.profile.accessToken);
        }
    }

    handleSubmit = (data) => {
        const params = this.props.match.params;
        const clientId = params.clientId;
        const contractInfoId = params.contractInfoId;
        data.contractInfoId = contractInfoId;
        this.props.saveFrameAgreement(data, this.props.profile.accessToken).then((res) => {
            if (res.result) {
                this.props.history.push(`/client/${clientId}/contractInfo/${contractInfoId}/frameagreement/${res.result.id}`);
            }
        })
    };

    handleCancel = () => {
        this.props.history.push(`/client/${this.props.match.params.clientId}`);
    };

    changeValue = (value, key) => {
        const form = this.state.form;
        form[key] = value;
        this.setState((state) => ({...state, form}));
    };

    render() {
        const {
            t,
            contractInfo,
            frameAgreement,
            department
        } = this.props;
        return (
            <StandardPage
                withBreadcrumb
            >
                <BreadcrumbBar contractInfo={ contractInfo.contractInfo } />
                <header>
                    <h1 style={commonStyles.pageTitle}>{t('New Frame agreement')}</h1>
                </header>

                <div>
                    { contractInfo && !_.isEmpty(contractInfo.contractInfo) &&
                        <FrameAgreementDetailsForm
                            cancelEdit={this.handleCancel}
                            submitAgreement={this.handleSubmit}
                            saving={frameAgreement.saving}
                            agreement={{}}
                            t={t}
                            contractInfo={ contractInfo.contractInfo }
                            paymentTermOptions={paymentTermOptions}
                            departments={department.countryDepartments}
                            departmentsLoading={department.loading}
                            selectedCountry={this.props.countries.selectedCountry}
                        />
                    }
                </div>
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        contractInfo: state.contractInfo,
        countries: state.countries,
        frameAgreement: state.frameagreement,
        profile: state.userProfile,
        department: state.department,
        download: state.download,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadContractInfo: (id, accessToken) => dispatch(loadContractInfo(id, accessToken)),
        loadCountry: (salesArea, accessToken) => dispatch(loadCountry(salesArea, accessToken)),
        saveFrameAgreement: (agreement, accessToken) => dispatch(saveFrameAgreement(agreement, accessToken)),
        loadCountryDepartments: (salesArea, accessToken) => dispatch(loadCountryDepartments(salesArea, accessToken)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(CreateFrameAgreementView));
