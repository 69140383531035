import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';
import _ from 'lodash';
import {Link} from 'react-router-dom';

import {
    DisciplineIcon,
    FileDetails,
} from 'components';
import {
    fonts,
    size,
    layout,
    measures,
    commonStyles,
    colors
} from 'styles/design.js';

const disciplineNameStyle = {
    ...fonts.sanches,
    ...size.zeta,
    ...layout.inline,
    paddingLeft: measures.standard,
    paddingRight: measures.quarter
};

const disciplineIconStyle = {
    margin: measures.standard
};

const subDiciplineItemStyle = {
    display: 'block',
    backgroundColor: colors.lightGray,
    margin: measures.half,
    padding: measures.standard,
    cursor: 'pointer',
    color: 'gray'
};
const alreadyAddedStyle = {
    color: 'black'
}

class AdminDisciplineSelectionList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            groupedDisciplines: [],
            groupedUniqueDisciplines: [],
        };
    }

    static defaultProps() {
        return {
            disabled: [],
            existing: []
        };
    }

    componentWillMount() {
        this.getGroupedDisciplines(this.props);
    }

    componentWillReceiveProps(nextProps) {
        if ((_.isEmpty(this.state.groupedDisciplines) && nextProps.disciplines.length > 0) || (this.props.disciplines !== nextProps.disciplines)) {
            this.getGroupedDisciplines(nextProps);
        }
    }

    getGroupedDisciplines(props) {
        const groupedDisciplines = _.groupBy(_.filter(props.disciplines, discipline => !!discipline.categoryServiflex), 'disciplineCategory');
        this.setState({groupedDisciplines: groupedDisciplines});

        const groupedUniqueDisciplines = {};
        for (const groupName in groupedDisciplines) {
            groupedUniqueDisciplines[groupName] = this.getUniqueDisciplineTypesForCategory(groupedDisciplines[groupName]);
        }
        this.setState({groupedUniqueDisciplines: groupedUniqueDisciplines});
    }

    disciplineName(discipline) {
        return this.props.t(discipline.disciplineType);
    }

    toggleGroup(key) {
        return () => {
            const dis = this.state.groupedDisciplines;
            dis[key].visible = !this.state.groupedDisciplines[key].visible;
            this.setState({groupedDisciplines: dis});
        };
    }

    toggleAll() {
        return () => {
            const val = !!this.refs.showAll.checked;
            const dis = this.state.groupedDisciplines;

            _.forEach(dis, (value, key) => {
                dis[key].visible = val;
            });

            this.setState({groupedDisciplines: dis});
        };
    }

    getLink(disciplineCategory, disciplineType) {
        return '/admin/' + this.props.countryId + '/disciplines/' + disciplineCategory + '/' + disciplineType;
    }

    /**
     * Get unique discipline types for a category.
     * If the discipline is enabled for the current country, the "global" property will have the value false.
     * @param {array} disciplinesForCategory 
     */
    getUniqueDisciplineTypesForCategory(disciplinesForCategory) {
        const localDisciplines = _.filter(disciplinesForCategory, { global: false });
        const globalDisciplines = _.filter(disciplinesForCategory, { global: true });
        return [
            ...localDisciplines,
            ...globalDisciplines.filter(globalDiscipline => !localDisciplines.some(localDiscipline => localDiscipline.disciplineType === globalDiscipline.disciplineType))
        ];
    }

    render() {
        const {t, checkExisting, selectedCountry } = this.props;
        const groupedDisciplines = this.state.groupedDisciplines;
        const groupedUniqueDisciplines = this.state.groupedUniqueDisciplines;
        return (
            <div>
                <div style={{...layout.floatRight, marginBottom: '-20px'}}>
                    <label style={{cursor: 'pointer'}}>
                        <input
                            onChange={this.toggleAll()}
                            type="checkbox"
                            ref="showAll"/> {t('show_all')}
                    </label>
                </div>
                {(() => {
                    if (groupedDisciplines) {
                        return _.keys(groupedDisciplines).map((disciplineGroupKey) => {
                            const filesFound = checkExisting(this.props.files.files, selectedCountry, disciplineGroupKey);
                            const hasEnabled = _.some(this.props.disciplines, { disciplineCategory: disciplineGroupKey, categoryServiflex: 1 });
                            if (!hasEnabled) {
                                return null;
                            }
                            return (
                                <div key={'discipline-selection-' + disciplineGroupKey}
                                     style={{
                                         ...commonStyles.bottomDivider,
                                         marginBottom: measures.standard,
                                         marginTop: measures.standard,
                                         cursor: 'pointer'
                                     }}>
                                    <div onClick={this.toggleGroup(disciplineGroupKey)} style={layout.verticalMiddle}>
                                        <div style={{...layout.floatLeft, width: '50px'}}>
                                            <DisciplineIcon style={disciplineIconStyle}
                                                            diciplineType={disciplineGroupKey}/>
                                        </div>
                                        <div style={{fontSize: '1.3em', paddingTop: '12px', paddingBottom: '12px'}}>
                                            <h2 style={disciplineNameStyle}>{t(disciplineGroupKey)} </h2>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            display: (groupedDisciplines[disciplineGroupKey].visible ? 'block' : 'none'),
                                            marginLeft: measures.quadruple,
                                            marginTop: measures.standard
                                        }}>
                                        {(() => {
                                            return groupedUniqueDisciplines[disciplineGroupKey].map(discipline => {
                                                const existForCountry = !discipline.global;
                                                if (!discipline.serviflex) {
                                                    return null;
                                                }
                                                return (
                                                    <Link
                                                        key={'subdiscipline-' + discipline.id +'-' + disciplineGroupKey + '-' + discipline.disciplineType}
                                                        to={this.getLink(discipline.disciplineCategory, discipline.disciplineType)}
                                                        style={ Object.assign({}, subDiciplineItemStyle, existForCountry ? alreadyAddedStyle : {}) }
                                                    >
                                                        {this.disciplineName(discipline)}
                                                    </Link>
                                                );
                                            });
                                        })()}
                                        {disciplineGroupKey === 'cooling' &&
                                            <div>
                                                <div style={{ marginTop: '2em' }}>
                                                    <FileDetails
                                                        language={'en'}
                                                        filename={`terms_and_conditions_discipline_${disciplineGroupKey}_en_${selectedCountry.id}.pdf`}
                                                        uploadFile={this.props.uploadFile}
                                                        downloadFile={this.props.downloadFile}
                                                        deleteFile={this.props.deleteFile}
                                                        exist={!_.isEmpty(filesFound.en)}
                                                        loading={this.props.files.loading || this.props.files.uploading || this.props.download.loading}
                                                    />
                                                </div>
                                                {selectedCountry.defaultLanguage !== 'en' && (
                                                    <FileDetails
                                                        language={selectedCountry.defaultLanguage}
                                                        filename={`terms_and_conditions_discipline_${disciplineGroupKey}_${selectedCountry.defaultLanguage}_${selectedCountry.id}.pdf`}
                                                        uploadFile={this.props.uploadFile}
                                                        downloadFile={this.props.downloadFile}
                                                        deleteFile={this.props.deleteFile}
                                                        exist={!_.isEmpty(filesFound.local)}
                                                        loading={this.props.files.loading || this.props.files.uploading || this.props.download.loading}
                                                    />
                                                )}
                                            </div>
                                        }
                                    </div>
                                </div>
                            );
                        });
                    }
                    return (<div>{t('no_disciplines_available_for_adding')}</div>);
                })()}
            </div>
        );
    }
}

export default translations(configuredRadium(AdminDisciplineSelectionList));