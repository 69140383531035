import React from 'react';
import translations from 'decorators/translations.js';
import {localeDateString} from 'helpers/stringTemplates';
import {
    DetailsDataContainer,
    DetailsDataContainerRow
} from 'components';
import {
    decorators,
    measures,
    colors
} from 'styles/design.js';
import {
    getClientAddress,
    getBuildingAddress
} from 'helpers/addressHelper.js';
import _ from 'lodash';

const infoRowStyle = {
    display: 'flex',
    flexFlow: 'row nowrap'
};

const infoLabelCellStyle = {
    padding: '0.75em',
    width: '50%'
};

const infoDataCellStyle = {
    padding: '0.75em',
    fontWeight: 'bold'
};

const infoContainerTitle = {
    lineHeight: measures.double,
    paddingTop: measures.double,
    borderBottom: decorators.coloredBorder(colors.black)
};

const BuildingInformation = (props) => {
    const {
        t,
        contractInfo,
        building,
        client,
     } = props;
    return (
        <DetailsDataContainer noTitle={true}>
            <div>
                <h3 style={infoContainerTitle}>
                    {
                        (() => {
                            if (!props.building.locationAddress || !props.building.locationAddress.street) {
                                return (
                                    <span style={{color: colors.basicRed, float: 'right', fontSize: '0.75em'}}>
                                        {t('address_inherited_from_client')}
                                    </span>
                                );
                            }
                        })()
                    }
                    {t('building')}
                </h3>
            </div>
            <DetailsDataContainerRow
                id="BuildingInformation_name"
                rowLabel={t('name')}
                rowValue={props.building.name}
            />
            <DetailsDataContainerRow
                id="BuildingInformation_agreementId"
                rowLabel={t('agreement')}
                rowValue={props.agreement ? (props.agreement.id + ' '+ t(props.agreement.agreementType === 'main' ? 'main_agreement' : props.agreement.agreementType) + ' ' + t(props.agreement.agreementStatus) + ' ' + localeDateString(props.agreement.created)) : ''}
            />
            {
                (() => {
                    const address = getBuildingAddress({building: props.building, client: props.client});
                    return (
                        <div>
                            <DetailsDataContainerRow
                                id="BuildingInformation_locationAddressStreet"
                                rowLabel={t('address')}
                                rowValue={address.street}
                            />
                            <DetailsDataContainerRow
                                id="BuildingInformation_locationAddressZip"
                                rowLabel={t('zip')}
                                rowValue={address.zip}
                            />
                            <DetailsDataContainerRow
                                id="BuildingInformation_locationAddressCity"
                                rowLabel={t('city')}
                                rowValue={address.city}
                            />
                        </div>
                    );
                })()
            }
            { (() => {
                let contactName = '';
                let contactEmail = '';
                let contactPhone = '';
                let hasBuildingContact = false;
                if (building.contactName || building.contactPhone || building.contactEmail) {
                    contactName = building.contactName;
                    contactEmail = building.contactEmail;
                    contactPhone = building.contactPhone;
                    hasBuildingContact = true;
                }
                else {
                    contactName = contractInfo.contactName;
                    if (contractInfo.contactPhone || contractInfo.contactEmail) {
                        contactPhone = contractInfo.contactPhone;
                        contactEmail = contractInfo.contactEmail;
                    }
                    else {
                        contactPhone = client.clientPhone;
                        contactEmail = client.email;
                    }
                }
                return <div>
                    <div>
                        <h3 style={infoContainerTitle}>
                            {
                                (() => {
                                    if (!hasBuildingContact) {
                                        return (
                                            <span style={{color: colors.basicRed, float: 'right', fontSize: '0.75em'}}>
                                                {t('inherited_from_client')}
                                            </span>
                                        );
                                    }
                                })()
                            }
                            {t('contact_info')}
                        </h3>
                    </div>
                    <DetailsDataContainerRow
                        id="BuildingInformation_contactName"
                        rowLabel={t('name')}
                        rowValue={contactName}
                    />
                    <DetailsDataContainerRow
                        id="BuildingInformation_contactEmail"
                        rowLabel={t('email')}
                        rowValue={contactEmail}
                    />
                    <DetailsDataContainerRow
                        id="BuildingInformation_contactPhone"
                        rowLabel={t('phone')}
                        rowValue={contactPhone}
                    />
                </div>;
            })() }

            <div>
                <h3 style={infoContainerTitle}>{t('building_parts')}</h3>
            </div>

            {
                (() => {
                    if (props.building.buildingPart) {
                        return props.building.buildingPart.map((part, index) => {
                            return (
                                <div id={'BuildingInformation_buildingPart' + index} key={index}>
                                    <div style={infoRowStyle}>
                                        <div style={infoLabelCellStyle}>{t('building_type')}</div>

                                        <div style={infoDataCellStyle} className="buildingType">{t(part.type)}</div>
                                    </div>

                                    <div style={infoRowStyle}>
                                        <div style={infoLabelCellStyle}>{t('building_size')}</div>

                                        <div style={infoDataCellStyle} className="buildingSize">{t(part.size)} (m²)</div>
                                    </div>

                                    <div style={infoRowStyle}>
                                        <div style={infoLabelCellStyle}>{t('build_year')}</div>

                                        <div style={infoDataCellStyle} className="buildYear">{t(part.buildYear)}</div>
                                    </div>

                                    <div style={infoRowStyle}>
                                        <div style={infoLabelCellStyle}>{t('renovated_year')}</div>

                                        <div style={infoDataCellStyle} className="renovatedYear">{t(part.renovatedYear)}</div>
                                    </div>
                                    <div style={infoRowStyle}>

                                        <div style={infoLabelCellStyle}>{t('renovation_description')}</div>
                                    </div>
                                    <div style={infoRowStyle}>
                                        <div style={infoDataCellStyle} className="renovationDescription">{part.description}</div>
                                    </div>
                                </div>
                            );
                        });
                    }
                    return (
                        <div style={infoRowStyle}>
                            <div id="BuildingInformation_noBuildingParts">{t('no_building_parts_found')}</div>
                        </div>
                    );
                })()
            }
        </DetailsDataContainer>
    );
};
export default translations(BuildingInformation);