import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';
import configuredRadium from 'configuredRadium.js';
import {localeDateString} from 'helpers/stringTemplates';
import {
    formStyles,
    commonStyles,
    decorators,
    measures,
    colors,
    layout,
    size
} from 'styles/design.js';
import {
    SaveButton,
    CancelButton,
    SFInputField,
    SFSelectField,
    SFTextareaField,
    AddressSelector,
    ScrollToComponent,
    ConfirmableButton,
    LoadingIndicator,
    DummyInputField,
}
    from 'components';

import {
    isEditable,
    isAgreementSigned,
    isBuildingDeletable,
    canUserDeleteBuilding
} from 'helpers/agreement.js';

import _ from 'lodash';

const addButtonStyle = {
    ...size.small,
    marginLeft: measures.standard,
    cursor: 'pointer',
    backgroundColor: colors.lightGray,
    padding: measures.half
};

const infoContainerTitle = {
    lineHeight: measures.double,
    paddingTop: measures.double,
    borderBottom: decorators.coloredBorder(colors.black)
};

const reduxFormObject = {
    form: 'buildingForm',
    fields: [
        'agreementId',
        'newAgreementId',
        'name',
        'billingCompany',
        'billingIndexId',
        'electronicInvoiceNumber',
        'vatNumber',
        'businessID',
        'billingRef',
        'buildingType',
        'renovatedYear',
        'buildYear',
        'buildingSize',
        'buildingPart[].type',
        'buildingPart[].description',
        'buildingPart[].size',
        'buildingPart[].buildYear',
        'buildingPart[].renovatedYear',
        'buildingPart[].description',
        'buildingPart[].usageWorkingDayStart',
        'buildingPart[].usageWorkingDayEnd',
        'buildingPart[].usageSaturdayStart',
        'buildingPart[].usageSaturdayEnd',
        'buildingPart[].usageSundayStart',
        'buildingPart[].usageSundayEnd',
        'billingAddress.street', 'billingAddress.zip', 'billingAddress.city',
        'locationAddress.street', 'locationAddress.zip', 'locationAddress.city',
        'specificLocationAddress',
        'specificContactInformation',
        'ownBillingDetails',
        'contactName',
        'contactEmail',
        'contactPhone'
    ]
};

class BuildingForm extends Component {
    static propTypes = {
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        cancelAction: PropTypes.func.isRequired
    };

    componentWillMount() {
        const {
            building,
            agreementId
         } = this.props;
        if (building) {
            this.initializeForm(building, agreementId);
        }
    }

    componentWillUnmount() {
        this.props.destroyForm();
    }

    initializeForm(buildingObject, agreementId) {
        const building = _.cloneDeep(buildingObject);
        if (building.billingStreetNumber) {
            building.billingAddress = building.billingAddress + ' ' + building.billingStreetNumber;
            building.billingStreetNumber = '';
        }
        if (agreementId) {
            building.agreementId = agreementId;
        }
        if (this.props.newBuilding) {
            building.specificContactInformation = 'specific';
            building.specificLocationAddress = 'specific';
            building.ownBillingDetails = '0';
        }
        else {
            building.specificContactInformation = building.contactEmail || building.contactPhone ? 'specific' : 'client';
            building.specificLocationAddress = building.locationAddress && !_.isEmpty(building.locationAddress.street) ? 'specific' : 'client';
            building.ownBillingDetails = building.billingCompany && !_.isEmpty(building.billingCompany) ? '1' : '0';
        }

        this.setState({initialized: true});
        this.props.initializeForm(building);
        this.updateBillingIndexes(building.salesArea || this.props.contractInfo.salesArea);
    }

    updateBillingIndexes(country) {
        const countryBillingIndexes = _.filter(this.props.billingIndexes, {countryId: country}) || [];
        const defaultIndex = _.find(countryBillingIndexes, 'default') || {};
        this.props.fields.billingIndexId.onChange(defaultIndex.id);
        this.setState({countryBillingIndexes: countryBillingIndexes});
    }

    addPart = (event) => {
        this.props.fields.buildingPart.addField();
        event.preventDefault();
    };
    selectAgreement = (val, isExisting) => {
        if (isExisting) {
            this.props.fields.newAgreementId.onChange(val);
        } else {
            this.props.fields.agreementId.onChange(val);
        }
    };

    deleteBuildingPart = (event, index) => {
        this.props.fields.buildingPart.removeField(index);
        event.preventDefault();
    };

    toggleChangeAgreement = (event) => {
        event.preventDefault();
        this.setState({changeAgreement: !this.state.changeAgreement});
    };

    renderAgreementOption = (agreement, idx, current) => {
        const t = this.props.t;
        const fields = [];
        if (current) {
            fields.push(t('current'));
        }
        fields.push(agreement.id);
        fields.push(t(agreement.agreementType));
        fields.push(t(agreement.agreementStatus));
        fields.push(localeDateString(agreement.created));
        fields.push(t('buildings') + ': ' + _.uniq(_.map(agreement.buildings, 'id')).length);
        return (
            <option key={'agr_idx_' + idx} value={agreement.id}>
                {fields.join(' - ')}
            </option>
        );
    }

    renderAgreementSelection = (draftAgreements, approvedMains, current) => {
        const t = this.props.t;
        return (
            <SFSelectField
                id="BuildingForm_agreement"
                onChangeAction={(event) => {
                    const selected = event.target.value;
                    //If selected value is the same, cancel edit.
                    if (current && parseInt(selected, 10) === current.id) {
                        this.toggleChangeAgreement(event);
                    } else {
                        this.selectAgreement(selected, !!current)
                    }
                }}
                //If there are current agreement, change field to be newAgreementId
                formProperty={current ? this.props.fields.newAgreementId : this.props.fields.agreementId}
                fieldName={t('agreement')}
            >
                <option value="">{t('select')}</option>
                <option value="new">{t('create_new_agreement')}</option>
                {draftAgreements.map((agreement, idx) => this.renderAgreementOption(agreement, idx, current))}
                {approvedMains.map((agreement, idx) => this.renderAgreementOption(agreement, idx))}
            </SFSelectField>
        );
    }

    render() {
        const {
            fields: {
                contactName,
                contactEmail,
                contactPhone,
                name,
                billingCompany,
                billingIndexId,
                electronicInvoiceNumber,
                vatNumber,
                businessID,
                billingRef,
                ownBillingDetails,
                billingAddress,
                locationAddress,
                specificLocationAddress,
                specificContactInformation,
            },
            handleSubmit,
            submitting,
            t,
            contractInfo,
            buildingTypes,
            client,
        } = this.props;
        const agreementsContractInfo = _.find(this.props.agreements.byContractInfo, { id: contractInfo.id });
        const buildingsContractInfo = _.find(this.props.buildings.byContractInfo, { id: contractInfo.id });
        const agreements = agreementsContractInfo && agreementsContractInfo.agreements;
        const buildings = buildingsContractInfo && buildingsContractInfo.buildings;
        const draftAgreements = _.filter(agreements, (agreement) => {
            return isEditable(agreement);
        });
        const approvedMains = _.filter(agreements, (agreement) => {
            if (isAgreementSigned(agreement) && agreement.agreementType === 'main') {
                const addons = _.filter(agreements, {mainAgreementId: agreement.id});
                if (addons && addons.length > 0) {
                    const editableAddons = _.filter(addons, (addon) => {
                        return isEditable(addon);
                    });
                    return editableAddons.length === 0;
                }
                return true;
            }
        });
        const buildingAgreement = this.props.building && this.props.building.agreementId
            ? _.find(agreements, {id: this.props.building.agreementId})
            : null;
        const isMovable = isEditable(buildingAgreement) && buildingAgreement.agreementType === 'main';
        const selectedAgreementId = parseInt(this.props.fields.agreementId.value, 10);
        const agreementMainAgreement = _.find(agreements, ['id', selectedAgreementId]);
        const isFirstBuilding = _.filter(buildings, (building) => {
            //Find buildings of the same agreement, skip selected building
            if (building.agreementId === selectedAgreementId || (agreementMainAgreement && building.agreementId === agreementMainAgreement.id)) {
                if (this.props.building && this.props.building.id) {
                    return this.props.building.id !== building.id;
                }
                return true;
            }
        });
        const showForm = this.props.newAgreement || !this.props.newBuilding || this.props.fields.agreementId.value !== '' || (draftAgreements.length === 0 && approvedMains.length === 0);
        return (
            <div style={{paddingBottom: '2em'}}>
                <form onSubmit={handleSubmit}>
                    <section style={[{marginBottom: measures.standard}]}>
                        <div style={{display: this.props.building.id ? 'block' : 'none'}}>
                            {
                                (() => {
                                    if (this.props.deletingBuilding) {
                                        return <LoadingIndicator big={true} visible={true} />;
                                    }
                                    if (canUserDeleteBuilding(this.props.user, this.props.building) && isBuildingDeletable(agreements, this.props.building)) {
                                        return (
                                            <ConfirmableButton
                                                style={{backgroundColor: colors.brightRed}}
                                                buttonIcon={'fa fa-trash'}
                                                buttonText={this.props.t('delete_building')}
                                                confirmButtonText={this.props.t('yes')}
                                                confirmText={this.props.t('confirm_delete_building')}
                                                showLoading={this.props.building.deleting}
                                                action={this.props.deleteBuilding}
                                            />
                                        );
                                    }
                                    return (
                                        <div style={{marginTop: measures.standard, marginBottom: measures.standard}}>
                                            <button
                                                style={{...commonStyles.button, backgroundColor: colors.gray}}
                                                disabled={true}
                                                onClick={() => {
                                                }}
                                            >
                                                {t('delete_building')}
                                            </button>
                                            <p style={{fontSize: '0.9em', paddingTop: measures.half}}>
                                                * {t('building_not_removable')}
                                            </p>
                                        </div>
                                    );

                                })()
                            }
                        </div>

                        {
                            (() => {
                                if (this.state.changeAgreement) {
                                    return this.renderAgreementSelection(draftAgreements, approvedMains, buildingAgreement);
                                } else if (buildingAgreement) {
                                    const agreementRows = [];
                                    agreementRows.push(t(buildingAgreement.agreementType === 'main' ? 'main_agreement' : buildingAgreement.agreementType));
                                    agreementRows.push(t(buildingAgreement.agreementStatus));
                                    agreementRows.push(localeDateString(buildingAgreement.created));
                                    return (
                                        <div><b>{t('agreement')}: </b>
                                            {agreementRows.join(' - ')}
                                            {isMovable && <button onClick={this.toggleChangeAgreement} style={{
                                                ...commonStyles.button,
                                                marginLeft: measures.standard
                                            }}><i className="fa fa-pencil"/></button>}
                                        </div>);
                                } else if (this.props.newAgreement || (draftAgreements.length === 0 && approvedMains.length === 0)) {
                                    return (
                                        <div>
                                            <h2 style={{display: 'inline-block'}}>{t('agreement')}:</h2>
                                            <div style={{
                                                display: 'inline',
                                                paddingLeft: measures.half
                                            }}>{t('add_new')}</div>
                                        </div>);
                                } else if (this.props.newBuilding) {
                                    return null;
                                }
                                return this.renderAgreementSelection(draftAgreements, approvedMains);
                            })()
                        }

                        {
                            (() => {
                                const hasContractInfoContact = contractInfo.contactEmail || contractInfo.contactPhone;
                                const hasBillingAddress = !_.isEmpty(contractInfo.billingAddress);
                                let billingIndex = '';
                                if (this.props.billingIndexes && contractInfo.billingIndexId) {
                                    const index = _.find(this.props.billingIndexes, {id: contractInfo.billingIndexId});
                                    if (index) {
                                        billingIndex = index.key;
                                    }
                                }
                                if (showForm) {
                                    return (
                                        <div>
                                            <SFInputField id="BuildingForm_name" formProperty={name} fieldName={t('name')} mandatory/>

                                            <h3 style={infoContainerTitle}>
                                                {t('contact_info')}
                                            </h3>
                                            <AddressSelector
                                                id="BuildingForm_specificContactInformationSelector"
                                                options={
                                                    [
                                                        {
                                                            label: t('Use contract info details'),
                                                            value: 'client'
                                                        },
                                                        {
                                                            label: t('Set building specific contact details'),
                                                            value: 'specific'
                                                        }
                                                    ]
                                                }
                                                name="specificiContactInformation"
                                                formProperty={specificContactInformation}
                                            />
                                            { specificContactInformation.value === 'specific' ?
                                                <div>
                                                    <SFInputField
                                                        id="BuildingForm_contactName"
                                                        formProperty={contactName}
                                                        fieldName={t('name')}/>
                                                    <SFInputField
                                                        id="BuildingForm_contactEmail"
                                                        formProperty={contactEmail}
                                                        fieldName={t('email')}/>
                                                    <SFInputField
                                                        id="BuildingForm_contactPhone"
                                                        formProperty={contactPhone}
                                                        fieldName={t('phone')}/>
                                                </div>
                                                : <div>
                                                    <DummyInputField
                                                        label={t('name')}
                                                        value={ contractInfo.contactName }
                                                    />
                                                    <DummyInputField
                                                        label={t('email')}
                                                        value={ hasContractInfoContact ? contractInfo.contactEmail : client.email }
                                                    />
                                                    <DummyInputField
                                                        label={t('phone')}
                                                        value={ hasContractInfoContact ? contractInfo.contactPhone : client.clientPhone }
                                                    />
                                                </div>
                                            }

                                            <h3 style={infoContainerTitle}>
                                                {t('address')}
                                            </h3>
                                            <div style={specificLocationAddress.value ? layout.none : layout.block}>
                                                <div style={{
                                                    marginTop: measures.standard,
                                                    marginBottom: measures.standard
                                                }}>{t('address_details_inherited_from_the_client')}</div>
                                            </div>
                                            <AddressSelector
                                                id="BuildingForm_specificAddressSelection"
                                                options={
                                                    [
                                                        {
                                                            label: t('use_client_address'),
                                                            value: 'client'
                                                        },
                                                        {
                                                            label: t('set_specific_location_address'),
                                                            value: 'specific'
                                                        }
                                                    ]
                                                }
                                                name="specificLocationAddress"
                                                formProperty={specificLocationAddress}
                                            />
                                            {specificLocationAddress.value === 'specific' ?
                                                <div>
                                                    <div>
                                                        <SFInputField id="BuildingForm_locationAddressStreet"
                                                            formProperty={locationAddress.street}
                                                            fieldName={t('address')}
                                                        />
                                                    </div>

                                                    <div>
                                                        <div style={formStyles.colStyle('left', '39%')}>
                                                            <SFInputField id="BuildingForm_locationAddressZip"
                                                                formProperty={locationAddress.zip}
                                                                fieldName={t('zip')}
                                                            />
                                                        </div>

                                                        <div style={formStyles.colStyle('right', '60%')}>
                                                            <SFInputField id="BuildingForm_locationAddressCity"
                                                                formProperty={locationAddress.city}
                                                                fieldName={t('city')}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                : <div>
                                                    <div>
                                                        <DummyInputField
                                                            label={t('address')}
                                                            value={ !_.isEmpty(client.clientAddress) ? client.clientAddress.street : '' }
                                                        />
                                                    </div>
                                                    <div>
                                                        <div style={formStyles.colStyle('left', '39%')}>
                                                            <DummyInputField
                                                                label={t('zip')}
                                                                value={ !_.isEmpty(client.clientAddress) ? client.clientAddress.zip : '' }
                                                            />
                                                        </div>

                                                        <div style={formStyles.colStyle('right', '60%')}>
                                                            <DummyInputField
                                                                label={t('city')}
                                                                value={ !_.isEmpty(client.clientAddress) ? client.clientAddress.city : '' }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <h3 style={{...infoContainerTitle }}>{t('building_parts')}</h3>

                                            <div style={{border: '1px solid', overflow: 'auto', padding: '10px'}}>
                                                {
                                                    (() => {
                                                        return this.props.fields.buildingPart.map((part, index) => {
                                                            return (
                                                                <div className="buildingParts" key={index}
                                                                     style={{
                                                                         borderBottom: '1px solid',
                                                                         paddingBottom: measures.standard,
                                                                         overflow: 'auto'
                                                                     }}>
                                                                    {
                                                                        !this.props.building.id && <ScrollToComponent/>
                                                                    }

                                                                    <div style={{overflow: 'auto'}}>
                                                                        <div style={formStyles.colStyle('left', '49%')}>
                                                                            <SFSelectField
                                                                                id={'BuildingForm_buildingPart_buildingType_' + index}
                                                                                formProperty={part.type}
                                                                                fieldName={t('building_type')}>
                                                                                <option>{t('select')}</option>
                                                                                {
                                                                                    (() => {
                                                                                        return buildingTypes.map((btype) => {
                                                                                            return (
                                                                                                <option key={btype} value={btype}>
                                                                                                    {t(btype)}
                                                                                                </option>
                                                                                            );
                                                                                        });
                                                                                    })()
                                                                                }
                                                                            </SFSelectField>
                                                                        </div>

                                                                        <div style={formStyles.colStyle('right', '49%')}>
                                                                            <SFInputField
                                                                                id={'BuildingForm_buildingPart_buildingSize_' + index}
                                                                                formProperty={part.size}
                                                                                fieldName={t('building_size') + ' (m²)'}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div style={{overflow: 'auto'}}>
                                                                        <div style={formStyles.colStyle('left', '49%')}>
                                                                            <SFInputField
                                                                                id={'BuildingForm_buildingPart_buildYear_' + index}
                                                                                formProperty={part.buildYear}
                                                                                fieldName={t('build_year')}
                                                                            />
                                                                        </div>

                                                                        <div style={formStyles.colStyle('right', '49%')}>
                                                                            <SFInputField
                                                                                id={'BuildingForm_buildingPart_renovatedYear_' + index}
                                                                                formProperty={part.renovatedYear}
                                                                                fieldName={t('renovated_year')}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <SFTextareaField
                                                                            id={'BuildingForm_buildingPart_renovationDescription_' + index}
                                                                            formProperty={part.description}
                                                                            fieldName={t('renovation_description')}
                                                                        />
                                                                    </div>
                                                                    <a
                                                                        id={'BuildingForm_buildingPart_deleteBuildingPart_' + index}
                                                                        style={{
                                                                            ...commonStyles.deleteButtonLink,
                                                                            ...layout.floatRight,
                                                                            marginRight: '1em'
                                                                        }}
                                                                        onClick={(event) => this.deleteBuildingPart(event, index)}>
                                                                        {this.props.t('delete')}
                                                                    </a>
                                                                </div>
                                                            );
                                                        });
                                                    })()

                                                }

                                                <div style={{
                                                    height: measures.double,
                                                    marginBottom: measures.standard,
                                                    marginTop: measures.standard
                                                }}>
                                                    <a
                                                        id="BuildingForm_addBuildingPart"
                                                        style={{...addButtonStyle, ...layout.inlineBlock, ...layout.floatLeft}}
                                                        onClick={(event) => this.addPart(event)}
                                                    >
                                                        + {this.props.t('add_building_part')}
                                                    </a>
                                                </div>
                                            </div>
                                            <div style={{
                                                ...layout.floatRight, ...layout.inlineBlock,
                                                clear: 'both',
                                                marginTop: '1em'
                                            }}>
                                                <span style={{marginRight: measures.standard}}>
                                                    <CancelButton
                                                        id="BuildingForm_cancel"
                                                        cancelAction={this.props.cancelAction}
                                                    />
                                                </span>

                                                <SaveButton
                                                    id="BuildingForm_submit"
                                                    onClick={handleSubmit}
                                                    disabled={submitting}
                                                    submitting={submitting}
                                                />
                                            </div>
                                        </div>
                                    );
                                }
                                return (
                                    <span style={{marginRight: measures.standard, marginTop: measures.double}}>
                                        <CancelButton
                                            style={{border: '1px solid'}}
                                            id="BuildingForm_cancel"
                                            cancelAction={this.props.cancelAction}
                                        />
                                    </span>
                                );
                            })()
                        }
                    </section>
                </form>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        agreements: state.agreement,
        buildings: state.building,
        buildingTypes: state.building.types,
        countries: state.countries.countries,
        billingIndexes: state.billingIndex.all,
        user: state.userProfile.user,
        client: state.client.client,
    };
}

export default translations(reduxForm(reduxFormObject, mapStateToProps)(configuredRadium(BuildingForm)));
