import React, { Component } from 'react';
import translations         from 'decorators/translations.js';
import configuredRadium     from 'configuredRadium.js';
import _                    from 'lodash';
import { connect }          from 'react-redux';

import {
    StandardPage,
    BreadcrumbBar,
    AdminSubNavigation,
    FrameAgreementDisciplineList
} from 'components';

import {
    commonStyles,
} from 'styles/design.js';

import { loadCountry, saveCountry } from 'redux/modules/country.js';

import { loadAllDisciplineConfigs } from 'redux/modules/disciplineConfig.js';
import {loadWithType} from 'redux/modules/translations.js';

class FrameAgreementDisciplinesConfigurationView extends Component {

      componentDidMount() {
        const { dispatch, match: {params}} = this.props;
        const promises = [];
        if(!this.props.countries.loaded || this.props.countries.selectedCountry.id !== params.countryId) {
            promises.push(dispatch(loadCountry(params.countryId, this.props.profile.accessToken)));
        }
        if(!this.props.discipline.loaded || this.props.discipline.frameAgreementDisciplines.length === 0) {
            promises.push(dispatch(loadAllDisciplineConfigs(params.countryId, 'frameAgreement', this.props.profile.accessToken)));

        }
        return Promise.all(promises).then(() => {
            if(this.props.selectedCountry) {
                dispatch(loadWithType(3, 'en,' + this.props.selectedCountry.defaultLanguage, this.props.selectedCountry.id, this.props.profile.accessToken));
            }
        });
    }
    componentWillReceiveProps(nextProps) {
        if (!this.props.disciplineConfig.loadingAll && !this.props.disciplineConfig.loadedAll && nextProps.disciplineConfig.loadingAll) {
            this.props.dispatch(loadAllDisciplineConfigs(this.props.match.params.countryId, 'frameAgreement', this.props.profile.accessToken));
        }
        if (this.props.translations.saving && nextProps.translations.saved) {
            this.props.dispatch(loadWithType(3, 'en,' + this.props.selectedCountry.defaultLanguage, this.props.selectedCountry.id, this.props.profile.accessToken));
        }
    }

    handleConfigSave = (countryConfiguration) => {
        const conf = this.props.selectedCountry;
        conf.frameAgreementConfig = countryConfiguration;
        const dispatchSave = this.props.dispatch(saveCountry(conf, this.props.profile.accessToken));
        this.setState({isSaving: true});
        return dispatchSave.then( res => {
            if (res.result) {
                this.setState({ isSaving: false, isLoading: true });
                const countryId = this.props.match.params.countryId;
                this.props.dispatch(loadCountry(countryId, this.props.profile.accessToken));
            }
            return res;
        })
    };
    render() {
        const { t, selectedCountry, translations } = this.props;

        return (
            <StandardPage
                withBreadcrumb
                withSideNavigation
                loaderVisible={this.props.countries.loading}
            >
                <BreadcrumbBar
                    page="admin"
                    path={ this.props.match.url }
                />
                <AdminSubNavigation params={this.props.match.params} activeNavi="frameAgreementConfig" />
                <header>
                    <h1 style = { commonStyles.pageTitle } >{t('frame_agreement')} - { this.props.t(this.props.match.params.countryId) }</h1>
                </header>

                <FrameAgreementDisciplineList
                    disciplines={this.props.disciplineConfig.frameAgreementConfigs}
                    saveConfig={this.handleConfigSave}
                    countryConfig={selectedCountry}
                    translatables={translations.translatables}
                />
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        countries: state.countries,
        selectedCountry: state.countries.selectedCountry,
        languages: state.translations.languages,
        profile: state.userProfile,
        discipline: state.discipline,
        department: state.department,
        translations: state.translations,
        frameAgreement: state.frameagreement,
        disciplineConfig: state.disciplineConfig,
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(FrameAgreementDisciplinesConfigurationView)));
