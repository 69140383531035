const LOAD       = 'ServiFlex/Client/LOAD',
    LOAD_SUCCESS = 'ServiFlex/Client/LOAD_SUCCESS',
    LOAD_FAIL    = 'ServiFlex/Client/LOAD_FAIL',
    SAVE         = 'ServiFlex/Client/SAVE',
    SAVE_SUCCESS = 'ServiFlex/Client/SAVE_SUCCESS',
    SAVE_FAIL    = 'ServiFlex/Client/SAVE_FAIL',
    LOAD_ALL       = 'ServiFlex/Client/LOAD_ALL',
    LOAD_ALL_SUCCESS = 'ServiFlex/Client/LOAD_ALL_SUCCESS',
    LOAD_ALL_FAIL    = 'ServiFlex/Client/LOAD_ALL_FAIL',
    RECENT       = 'ServiFlex/Client/RECENT',
    RECENT_SUCCESS = 'ServiFlex/Client/RECENT_SUCCESS',
    RECENT_FAIL    = 'ServiFlex/Client/RECENT_FAIL',
    LOAD_CONTRACTINFOS = 'Serviflex/Client/LOAD_CONTRACTINFOS',
    LOAD_CONTRACTINFOS_SUCCESS = 'Serviflex/Client/LOAD_CONTRACTINFOS_SUCCESS',
    LOAD_CONTRACTINFOS_FAIL = 'Serviflex/Client/LOAD_CONTRACTINFOS_FAIL';

const initialState = { loading: false, client: {}, clients: [], loaded: false, reload: true, saving: false, recents: [], contractInfos: [], loadingContractInfos: false, loadedContractInfos: false };

export const paymentTermOptions = [14, 21, 30, 40, 45, 60];

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                client: action.result
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SAVE:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                error: action.error
            };
        case SAVE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };

        case LOAD_ALL:
            return {
                ...state,
                loading: true
            };
        case LOAD_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                clients: action.result
            };
        case LOAD_ALL_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };

        case RECENT:
            return {
                ...state,
                loadingRecent: true,
                loading: true
            };
        case RECENT_SUCCESS:
            return {
                ...state,
                loadingRecent: false,
                loadedRecent: true,
                reload: false,
                loading: false,
                loaded: true,
                recents: action.result
            };
        case RECENT_FAIL:
            return {
                ...state,
                loadingRecent: false,
                loadedRecent: false,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_CONTRACTINFOS:
            return {
                ...state,
                loadingContractInfos: true,
            };
        case LOAD_CONTRACTINFOS_SUCCESS:
            return {
                ...state,
                loadingContractInfos: true,
                loadedContractInfos: true,
                contractInfos: action.result
            };
        case LOAD_CONTRACTINFOS_FAIL:
            return {
                ...state,
                loadingContractInfos: false,
                loadedContractInfos: false,
                error: action.error
            };
        default:
            return state;
    }
}

export function loadClient(clientId, accessToken) {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client) => client.get(`/clients/${clientId}?filter=${encodeURIComponent(JSON.stringify({include: 'contractInfos'}))}`, { accessToken }
        )
    };
}

export function update(clientObject, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.patch('/clients/' + clientObject.id, { data: clientObject, accessToken }
        )
    };
}

export function save(clientObject, accessToken) {
    if (clientObject.id) {
        return update(clientObject, accessToken);
    }
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.post('/clients', { data: clientObject, accessToken }
        )
    };
}

export function searchAll(char, accessToken) {
    return {
        types: [LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_FAIL],
        promise: (client) => client.get('/clients/byName/' + char, { accessToken })
    };
}
export function loadRecentClients(accessToken) {
    return {
        types: [RECENT, RECENT_SUCCESS, RECENT_FAIL],
        promise: (client) => client.get('/clients/recents', { accessToken })
    };
}

export function isClientLoaded(state, clientId) {
    if(state.client && state.client.loaded && !clientId) {
        return true;
    } else if(state.client && state.client.loaded && state.client.client.id === parseInt(clientId, 10)) {
        return true;
    }
    return false;
}

export function loadClientContractInfos(clientId, accessToken) {
    return {
        types: [LOAD_CONTRACTINFOS, LOAD_CONTRACTINFOS_SUCCESS, LOAD_CONTRACTINFOS_FAIL],
        promise: client => client.get(`/clients/${clientId}/contractInfoList`, { accessToken })
    };
};