import React, {Component}    from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import { CostNumber }                   from 'components';
import { measures, layout, size }       from 'styles/design.js';

const tableCell = {
    padding: measures.half
};

const lastColumn = {
    width: '100%',
    textAlign: 'right'
};

const tableCellHeader = {
    ...tableCell,
    ...size.small,
    fontWeight: 'bold'
};

class HoursOfServiceTable extends Component {

    static propTypes = {
        hoursOfService: PropTypes.array.isRequired,
        serviceLevel: PropTypes.string.isRequired,
        calculation: PropTypes.array
    };

    static defaultProps = {
        hoursOfService: [],
        calculation: [],
    };

    render() {
        const { t, hoursOfService, calculation } = this.props;
        return (
            <div>
                {(() => {
                    if ( hoursOfService && hoursOfService.length > 0) {
                        let totalSum = 0;
                        if(calculation && calculation.length > 0) {
                            calculation.map((service) => {
                                totalSum += (service.cost || 0);
                            });
                        }
                        return (
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            <td style= {{ ...tableCellHeader, width: '60%' }}>{ t('description') }</td>
                                            <td style= {{ ...tableCellHeader, width: '10%' }}>{ t('hours') }</td>
                                            <td style= {{ ...tableCellHeader, width: '10%' }}>{ t('visits') }</td>
                                            <td style= {{ ...tableCellHeader, width: '10%', ...lastColumn }} />
                                        </tr>
                                    </thead>
                                    {(() => {
                                        return hoursOfService.map((hoursOfServiceRow, index) => {
                                            const calc = calculation && calculation[index] ? calculation[index] : {cost:0};
                                            return (
                                                <tbody key = { 'hours_' + index + '_tbody' }>
                                                <tr>
                                                    <td style= { tableCell }>{ hoursOfServiceRow.description }</td>
                                                    <td style= { tableCell }>{ hoursOfServiceRow.hours }</td>
                                                    <td style= { tableCell }>{ hoursOfServiceRow.visits }</td>
                                                    <td rowSpan="2" style= {{ ...tableCell, ...lastColumn }}>
                                                        <CostNumber style = {{ fontWeight: 'normal' }}>
                                                            { !calc.cost ? 0 : calc.cost}
                                                        </CostNumber>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style = { tableCellHeader }>{ t('hours_visits_repetition') }</td>
                                                    <td style = { tableCell } colSpan="3">
                                                        { hoursOfServiceRow.multiplier ? t('visits_repetition_' + hoursOfServiceRow.multiplier ) : '' }
                                                    </td>
                                                </tr>
                                                </tbody>
                                            );
                                        });
                                    })()}
                                </table>
                                <div style = {{ marginTop: measures.half, marginBottom: measures.double, marginLeft: measures.half, marginRight: measures.half, fontWeight: 'bold' }}>
                                    { t('hours_for_service_total') }<CostNumber style = { layout.floatRight }>{ totalSum }</CostNumber>
                                </div>
                            </div>
                        );
                    }
                    return (<div style = {{ marginBottom: measures.double, marginLeft: measures.half, marginRight: measures.half }}>{ t('no_hours_of_service_added') }</div>);
                })()}
          </div>
        );
    }
}

export default translations(HoursOfServiceTable);