import _ from 'lodash';

export const calculatePercentage = (value) => {
    return Math.round((parseFloat(value) - 1) * 100);
};

function calculationSumOf(costType) {
    return (disciplines) => {
        if(disciplines) {
            return _.sum(disciplines.map((discipline)=> {
                if(discipline && discipline.fullCostCalculation) {
                    return discipline.fullCostCalculation[costType];
                }
            }));
        }
    };
}
function hasCustomIncome(disciplines) {
    return _.some(disciplines, discipline => {
        const costCalculation = discipline.fullCostCalculation;
        return costCalculation && costCalculation.oldIncome && costCalculation.oldIncome !== costCalculation.totalIncome
    });
}

export const calculatedDisciplineCosts = (disciplines) => {
    const totalCostsSum = calculationSumOf('totalCosts')(disciplines);
    const totalIncomeSum = calculationSumOf('totalIncome')(disciplines);
    const oldIncomeSum = calculationSumOf('oldIncome')(disciplines);
    const actualProjectMarginPercent = (totalCostsSum !== 0 && totalIncomeSum !== 0) ? ((1 - (totalCostsSum / totalIncomeSum)) * 100).toFixed(2) : 0;
    let customIncomeComment = '';
    let customIncomeDifference = 0;
    if(hasCustomIncome(disciplines)) {
        customIncomeComment = disciplines[0].fullCostCalculation.customIncomeComment;
        customIncomeDifference = calculationSumOf('customIncomeDifference')(disciplines);
    }

    return {
        blueCollar: calculationSumOf('blueCollar')(disciplines),
        whiteCollar: calculationSumOf('whiteCollar')(disciplines),
        serviceCar: calculationSumOf('serviceCar')(disciplines),
        materials: calculationSumOf('materials')(disciplines),
        risk: calculationSumOf('risk')(disciplines),
        subcontracting: calculationSumOf('subcontracting')(disciplines),
        totalCosts: totalCostsSum,
        oldIncome: oldIncomeSum,
        actualProjectMargin: actualProjectMarginPercent,
        customIncomeComment,
        customIncomeDifference,
        totalIncome: totalIncomeSum,
        workingHours: calculationSumOf('workingHours')(disciplines)
    };
};

export const calculateOldIncomeForDisciplineCategory = (disciplines) => {
    return calculationSumOf('oldIncome')(disciplines);
};

export const getDisciplineTotal = ({calculatedDiscipline, discipline}) => {
    let sum = 0;

    if(_.isEmpty(calculatedDiscipline) && discipline.fullCostCalculation) {
        return discipline.fullCostCalculation.totalIncome;
    }
    if (!calculatedDiscipline || !calculatedDiscipline.disciplineSystems) {
        return sum;
    }
    if(calculatedDiscipline && calculatedDiscipline.fullCostCalculation) {
        return calculatedDiscipline.fullCostCalculation.totalIncome;
    }
    if(calculatedDiscipline.systemsCostCalculation) {
        return _.sumBy(calculatedDiscipline.systemsCostCalculation, 'totalIncome');
    }
    if(calculatedDiscipline && calculatedDiscipline.disciplineSystems) {
        calculatedDiscipline.disciplineSystems.forEach((sys) => {
            sys.hoursOfService.map((service) => {
                sum += (service.cost || 0);
            });
            sys.materials.map((material) => {
                sum += (material.cost || 0);
            });
            sys.repairs.map((repair) => {
                sum += (repair.cost || 0);
            });
            if (sys.subcontracting) {
                sys.subcontracting.map((subcontractingRow) => {
                    sum += (subcontractingRow.cost || 0);
                });
            }
        });
    }
    return sum;
};

export const getOldTotalForDiscipline = (discipline) => {
    if(discipline.fullCostCalculation) {
        return discipline.fullCostCalculation.oldIncome;
    }
    return 0;
}
