
const timers = {};
module.exports = (initCallback, callback, ms, uniqueId) => {
    let id = uniqueId;
    if (!id) {
        id = "Don't call this twice without a uniqueId";
    }
    if (timers[id]) {
        clearTimeout(timers[id]);

    } else if(initCallback) {
        initCallback();
    }
    timers[id] = setTimeout(()=>{ delete timers[id]; callback(); }, ms);
    return timers[id];
};
