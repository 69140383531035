import React, { Component }             from 'react';
import translations                     from 'decorators/translations.js';
import PropTypes                        from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import { connect }                      from 'react-redux';
import {
    VerticalNavigationBar,
    VerticalNavigationItem
}                                       from 'components/index.js';

import './AdminSubNavigation.css';

class AdminSubNavigation extends Component {

    static propTypes = {
        activeNavi: PropTypes.string
    };

    render() {
        const { t, params } = this.props;
        return (
            <div className="admin-subnavigation-container">
                <VerticalNavigationBar>
                    <VerticalNavigationItem url={'/admin/' + params.countryId + '/mainagreement'} id="mainAgreementTranslations">{t('main_agreement_config')}</VerticalNavigationItem>
                    <VerticalNavigationItem url={'/admin/' + params.countryId} id="countryConfig" exact subItem>{t('cost_calculations')}</VerticalNavigationItem>
                    <VerticalNavigationItem url={'/admin/' + params.countryId + '/disciplines'} id="disciplineConfig" subItem>{t('disciplines')}</VerticalNavigationItem>
                    <VerticalNavigationItem url={'/admin/' + params.countryId + '/sla'} id="countrySLAConfig" subItem>{t('country_sla_config')}</VerticalNavigationItem>
                    <VerticalNavigationItem url={'/admin/' + params.countryId + '/termsandconditions'} id="termsAndConditionsConfig" subItem>{t('terms_and_conditions_config')}</VerticalNavigationItem>
                    <VerticalNavigationItem url={'/admin/' + params.countryId + '/frameagreement/translations'} id="frameAgreementConfig"exact >{t('frame_agreement_config')}</VerticalNavigationItem>
                    <VerticalNavigationItem url={'/admin/' + params.countryId + '/frameagreement'} id="frameAgreementConfig" subItem exact>{t('Configuration')}</VerticalNavigationItem>
                    <VerticalNavigationItem url={'/admin/' + params.countryId + '/frameagreement/disciplines'} id="frameAgreementConfig" subItem exact>{t('disciplines')}</VerticalNavigationItem>
                    <VerticalNavigationItem url={'/admin/' + params.countryId + '/departments'} id="departmentConfig">{t('departments')}</VerticalNavigationItem>
                    <VerticalNavigationItem url={'/admin/' + params.countryId + '/ccc'} id="ccc">{t('ccc')}</VerticalNavigationItem>
                </VerticalNavigationBar>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client
    };
}
const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(AdminSubNavigation)));
