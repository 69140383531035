import React from 'react';
import './ReportTable.css';

const ReportTable = (props) => {
    return (
        <table className="report-table">
            <tbody>
            {props.data.map((d, i) => {
                return (
                    <tr key={'row' + i} className="report-table-row">
                        <td className="report-table-cell">{d.label}</td>
                        <td className="report-table-cell">{d.valueText || d.value}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    )
};

export default ReportTable;