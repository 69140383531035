import React, {Component }           from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import { commonStyles, measures, layout }       from 'styles/design.js';
import {
    SFInputField,
    CostNumber,
    SFMultiplierSelect
}                           from 'components';

const smallFieldStyle = {
    ...layout.inlineBlock,
    width: '8em',
    marginRight: measures.standard
};

const mediumFieldStyle = {
    ...layout.inlineBlock,
    width: '10em',
    marginRight: measures.standard
};

class HoursOfServiceEdit extends Component {

    static propTypes = {
        hoursOfService: PropTypes.array.isRequired,
        calculation: PropTypes.array,
        removeHours: PropTypes.func.isRequired,
        addHours: PropTypes.func.isRequired
    };

    static defaultProps = {
        hoursOfService: [],
        calculation: [],
    };

    render() {
        const { t, form, calculation } = this.props;
        const hoursOfService = form.hoursOfService;
        return (
            <div className="clearfix">
                { (() => {
                    if (hoursOfService && hoursOfService.length > 0) {
                        return hoursOfService.map((hoursOfServiceRow, index) => {
                            const calc = calculation && calculation[index] ? calculation[index] : {cost:0};
                            return (
                                <div key = { index } style = {{ marginBottom: measures.double }}>
                                    <div>
                                        <div className="clearfix">
                                            <button type="button" style = {{ ...commonStyles.smallDeleteButton, ...layout.floatRight, width: '5em' }} onClick = { () => { this.props.removeHours(index); } }>{ t('delete') }</button>
                                            <h3 style = {{ marginBottom: measures.standard }} >{ hoursOfServiceRow.description ? hoursOfServiceRow.description.value : '' }</h3>
                                        </div>
                                        <SFInputField
                                            id={'HoursOfServiceList_description_' + index}
                                            defaultInput={true}
                                            fieldName= { t('description') }
                                            { ...hoursOfServiceRow.description }
                                        />
                                        <div style = { smallFieldStyle }>
                                            <SFInputField
                                                id={'HoursOfServiceList_hours_' + index}
                                                defaultInput={true}
                                                type="number"
                                                fieldName={ t('hours') }
                                                { ...hoursOfServiceRow.hours }
                                            />
                                        </div>
                                        <div style = { smallFieldStyle }>
                                            <SFInputField
                                                id={'HoursOfServiceList_visits_' + index}
                                                defaultInput={true}
                                                type="number"
                                                fieldName={ t('visits') }
                                                { ...hoursOfServiceRow.visits }
                                            />
                                        </div>
                                        <div style={mediumFieldStyle}>
                                            <SFMultiplierSelect multiplier = { hoursOfServiceRow.multiplier } />
                                        </div>
                                    </div>
                                    <div style = { { textAlign: 'right' } }>
                                        <div style = { layout.floatRight }>
                                            {
                                                (() => {
                                                    if(this.props.calculating) {
                                                        return (<span>{t('calculating')}</span>);
                                                    }
                                                    return (
                                                        <CostNumber id={'HoursOfServiceList_cost_' + index}>{ calc.cost }</CostNumber>
                                                    );
                                                })()
                                            }
                                        </div>
                                    </div>
                                </div>
                            );
                        });
                    }
                })()}
            </div>
        );
    }
}

export default translations(HoursOfServiceEdit);
