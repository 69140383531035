import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import configuredRadium                 from 'configuredRadium.js';

import {KeyValueDiv}                from 'components';

import {commonStyles, colors, layout, measures}       from 'styles/design.js';

const tableStyles = {
    width: '100%',
    padding: measures.standard,
    margin: measures.standard,
    fontSize: '0.9em'
};

const tdStyle = {
    paddingTop: measures.half,
    paddingLeft: measures.half,
    paddingRight: measures.half
};

const tdStyleHeader = {
    padding: measures.half,
    borderBottom: '1px solid ' + colors.lightGray
};
class FrameAgreementPricingConfigInformation extends Component {
    render() {
        const {t, countryConfig} = this.props;
        return (
            <div style={[{height: '2em', overflow: 'auto'}, this.props.editing && this.props.editing !== '' ? layout.none : layout.inline]}>
                <div style = {{marginBottom: measures.standard}}>
                    <div style={ [ layout.floatRight, layout.inlineBlock ] }>
                        <button type="button"
                                onClick={ this.props.editClicked }
                                style={ [commonStyles.button, { backgroundColor: colors.lightBlue }] }>{ t('edit') }</button>
                    </div>
                </div>
                <div>
                    <p><b>{t('pricing')}:</b></p>
                </div>
                <table style = {tableStyles} cellPadding="0" cellSpacing="0">
                    <thead>
                    <tr>
                        <td style = {tdStyleHeader}>
                            {t('name')}
                        </td>
                        <td style = {tdStyleHeader}>
                            {t('name')} ({t('en')})
                        </td>
                        <td style = {tdStyleHeader}>
                            {t('basic_charge')} ({countryConfig.currency})
                        </td>
                        <td style = {tdStyleHeader}>
                            {t('overtime_50')} ({countryConfig.currency})
                        </td>
                        <td style = {tdStyleHeader}>
                            {t('overtime_100')} ({countryConfig.currency})
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        (() => {
                            if(this.props.discipline.pricing) {
                                return this.props.discipline.pricing.map((priceRow, index) => {
                                    return (
                                        <tr key={'discipline_pricing_' + this.props.discipline.disciplineCategory + '_' + index }>
                                            <td style = {tdStyle}>
                                                {priceRow.name}
                                            </td>
                                            <td style = {tdStyle}>
                                                {priceRow.nameEnglish}
                                            </td>
                                            <td style = {tdStyle}>
                                                {priceRow.basicCharge}
                                            </td>
                                            <td style = {tdStyle}>
                                                {priceRow.overtime50}
                                            </td>
                                            <td style = {tdStyle}>
                                                {priceRow.overtime100}
                                            </td>
                                        </tr>
                                    );
                                });
                            }
                            return <tr><td style = {tdStyle} colSpan="4">{t('no_pricing_rows')}</td></tr>;
                        })()
                    }
                    </tbody>
                </table>
        </div>
        );
    }
}

export default translations(configuredRadium(FrameAgreementPricingConfigInformation));
