import React from 'react';

import _ from 'lodash';

import {
    SFInputField,
    SFSelectField,
    Button
} from 'components';

class AgreementDetailsForm extends React.Component {

    state = {form: {}, errors: {}};

    componentWillMount() {
        const form = {};
        const fields = ['name', 'customIndex', 'startingDate', 'language'];
        _.map(fields, (field) => form[field] = this.props.agreement[field]);
        this.setState((state) => ({...state, form}));
    }

    changeValue = (value, key) => {
        const form = this.state.form;
        const errors = this.state.errors;
        form[key] = value;
        if (value !== '') {
            errors[key] = null;
        }
        if (key === 'language' && value === '') {
            form['language'] = null;
        }
        this.setState((state) => ({...state, form, errors}));
    };

    submit = () => {
        const form = this.state.form;
        const errors = {};
        let isError = false;
        if (isError) {
            this.setState((state) => ({...state, errors}));
        } else {
            this.props.submit(this.state.form);
        }
    };

    render() {
        const {t} = this.props;
        return (
            <div>
                <SFInputField
                    defaultInput={true}
                    id="AgreementDetailsForm_name"
                    error={this.state.errors.name}
                    value={this.state.form.name || ''}
                    onChange={(event) => this.changeValue(event.target.value, 'name')}
                    fieldName={t('name')}
                />

                <SFInputField
                    defaultInput={true}
                    id="AgreementDetailsForm_customIndex"
                    error={this.state.errors.customIndex}
                    value={this.state.form.customIndex || ''}
                    onChange={(event) => this.changeValue(event.target.value, 'customIndex')}
                    fieldName={t('Start index number')}
                />

                <SFInputField
                    defaultInput={true}
                    id="AgreementDetailsForm_startingDate"
                    error={this.state.errors.startingDate}
                    value={this.state.form.startingDate || ''}
                    onChange={(event) => this.changeValue(event.target.value, 'startingDate')}
                    fieldName={t('When do the agreement start')}
                />

                <SFSelectField
                    defaultInput={true}
                    id="AgreementDetailsForm_language"
                    error={ this.state.errors.language }
                    value={ this.state.form.language }
                    fieldName={ t('Language') }
                    onChange={ e => this.changeValue(e.target.value, 'language')}
                >
                    <option value={''}>{t('select')}</option>
                    <option value={this.props.selectedCountry.defaultLanguage}>{ t(this.props.selectedCountry.defaultLanguage) }</option>
                    { this.props.selectedCountry.defaultLanguage !== 'en' && <option value="en">{t('en')}</option> }
                </SFSelectField>

                <div>

                    <Button
                        variant="link"
                        onClick={this.props.cancel}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        id="AgreementDetailsForm_submit"
                        onClick={this.submit}
                        disabled={this.props.submitting}
                        loading={this.props.submitting}
                    >
                        {t('save')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default AgreementDetailsForm;