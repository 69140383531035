import React, {Component}           from 'react';
import PropTypes from 'prop-types';
import {
    Step,
    ProcessHeader,
    StepTimeline
}           from 'components';

import {
    saveData,
    sendForward
}           from 'redux/modules/cccProcess';

import {
    resendProcessStep
}           from 'redux/modules/agreement';

import _ from 'lodash';

const styles = {
    processContainer: {
        fontFamily: 'Arial',
        fontWeight: 'normal',
        color: 'rgb(0, 45, 110)',
        maxWidth: '48em',
        margin: '0px auto',
    }
};

class ProcessContainer extends Component {

    static propTypes = {
        stepsConfig: PropTypes.array.isRequired,
        stepsWithData: PropTypes.array.isRequired,
        agreement: PropTypes.object.isRequired
    };

    submitData = (values) => {
        const data = { values };
        return this.props.dispatch(saveData(data, this.props.agreement.id, this.props.currentStep, this.props.uuidToken));
    };
    sendForward = (comment) => {
        return this.props.dispatch(sendForward({comment}, this.props.agreement.id, this.props.currentStep, this.props.uuidToken));
    };
    resendStep = (step, comment) => {
        if(this.props.accessToken) {
            return this.props.dispatch(resendProcessStep({comment}, this.props.agreement.id, step, this.props.accessToken));
        }
    };
    render() {
        const { stepsConfig, stepsWithData, uuidToken } = this.props;

        const latestStep = _.maxBy(stepsWithData, 'step');
        const values = {};
        if(stepsWithData) {
            stepsWithData.map((step) => {
                if(!values[step.step]) {
                    values[step.step] = {};
                }
                if(step.data) {
                    step.data.map((dataRow) => {
                        values[step.step][dataRow.key] = dataRow.value;
                    });
                }
            });
        }
        return (
            <div style={styles.processContainer}>
                <ProcessHeader />
                <StepTimeline steps={stepsConfig} stepData={stepsWithData} latestStep={latestStep} />
                {
                    (() => {
                        if(stepsConfig) {
                            return stepsConfig.map((step, idx) => {
                                const editable = step.step === latestStep.step && latestStep.uuid === uuidToken;
                                const hasData = _.find(stepsWithData, {step: step.step});
                                const isMine = hasData ? hasData.uuid === uuidToken : false;
                                return (
                                    <Step
                                        key={'step_index_' + idx}
                                        submitData={this.submitData}
                                        sendForward={this.sendForward}
                                        step={step}
                                        editable={editable}
                                        isMine={isMine}
                                        values={values[step.step] || {}}
                                        stepNumber={idx + 1}
                                        resendStep={this.resendStep}
                                    />
                                );
                            });
                        }
                    })()
                }
            </div>
        );
    }
}
export default ProcessContainer;