
import React            from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';
import { colors }       from 'styles/design.js';


const radioContainerStyle = {
    marginTop: '1.5em',
    position: 'relative',
    height: '2.5em'
};
const radioLabelStyle = {
    display: 'block',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    zIndex: '1'
};
const activeRadioLabelStyle = {};
const textfieldStyle = {
    position: 'absolute',
    left: '1.5em',
    top: '-0.5em',
    borderColor: colors.blue,
    padding: '0.5em',
    fontSize: '1em'
};
const disabledStyle = {
    color: colors.lightgray,
    borderColor: colors.lightgray
};

const SFCustomRadioField = (props) => {
    const textfieldDisabled = props.formProperty.value !== props.value;
    return (
        <div style={ props.disabled ? [radioContainerStyle, {color: 'silver'}] : radioContainerStyle} className="radio">
            <input id={props.value}
                   type="radio"
                   name={props.name}
                   value={props.value}
                   onChange={props.formProperty.onChange}
                   onClick = {props.onUpdate}
                   checked={props.formProperty.value === props.value}
                   readOnly={ props.formProperty.readOnly }
            />
            <label style={[textfieldDisabled ? radioLabelStyle : activeRadioLabelStyle ]} htmlFor={props.value}></label>
            <input style={[textfieldStyle, textfieldDisabled ? disabledStyle : {}]}
                   id={props.customValue}
                   type="text"
                   name={props.customValue}
                   placeholder={ props.label }
                   disabled={textfieldDisabled}
                   { ...props.customFormProperty }
            />
        </div>
    );
};
export default translations(configuredRadium(SFCustomRadioField));

