import React, {Component}   from 'react';
import translations from 'decorators/translations.js';
import { connect }          from 'react-redux';
import configuredRadium     from 'configuredRadium.js';
import _                    from 'lodash';
import {
    StandardPage,
    BreadcrumbBar,
    CostCalculation,
    LoadingIndicator,
    BackLink,
    Header,
    Section
}                           from 'components';


import { loadContractInfo, isContractInfoLoaded } from 'redux/modules/contractInfo';
import {
    loadWithIncludes as loadAgreement
} from 'redux/modules/agreement.js';

import {
    isEditable
}                                   from 'helpers/agreement.js';
import {
    calculatedDisciplineCosts
} from 'helpers/calculation.js';

import { loadCountry } from 'redux/modules/country.js';

class CostEditView extends Component {

    componentDidMount() {
        const promises = [];
        const params = this.props.match.params;
        const token = this.props.profile.accessToken;
        if(!isContractInfoLoaded(this.props, params.contractInfoId)) {
            promises.push(this.props.dispatch(loadContractInfo(params.contractInfoId, token)));
        }
        if(!this.props.agreement.loaded || this.props.agreement.agreement.length === 0 || !this.props.agreement.agreement[0] || this.props.agreement.agreement[0].id !== this.props.match.params.agreementId) {
            promises.push(this.props.dispatch(loadAgreement(params.agreementId, token)));
        }
        return Promise.all(promises).then(() => {
            const contractInfo = this.props.contractInfo.contractInfo;
            if (contractInfo) {
                this.props.dispatch(loadCountry(contractInfo.salesArea, token));
            }
        });
    }

    calculateMainDisciplineCosts(disciplines) {
        const costs = _.transform(_.groupBy(disciplines, 'disciplineCategory'), (result, value, key) => {
            result.push({
                disciplineCategory: key,
                calculation: calculatedDisciplineCosts(value)
            });
        }, []);
        return costs;
    }



    isEditMode = (index) => {
        return this.props.hash === '#discipline' + index;
    };

    render() {
        const {
            t,
            client,
            match: {
                params
            },
            updating,
        } = this.props;
        const agreement = this.props.agreement.agreement ? this.props.agreement.agreement : {};
        if(_.isEmpty(agreement)) {
            return <LoadingIndicator visible={true}/>
        }
        let agreementDisciplines = agreement.disciplines;
        if (agreement.agreementStatus && agreement.agreementStatus.search('approved|sent_for_approval|signed') > -1) {
            agreementDisciplines = agreement.approvedDisciplines;
        }
        const totalFullCostcalculation = calculatedDisciplineCosts(agreementDisciplines);
        const disciplineCalculations = this.calculateMainDisciplineCosts(agreementDisciplines);
        return (
            <StandardPage withBreadcrumb disableScrollToTop>
                <BreadcrumbBar
                    page="agreement"
                    contractInfo={this.props.contractInfo}
                    actionUrl = { '/client/' + client.id + '/details' }
                    linkName = { t('show_client_details') }
                    lockedText = { !isEditable(agreement) ? t('locked') : null}
                />
                <BackLink
                    title={t('Back to agreement')}
                    to={ `/client/${params.clientId}/contractInfo/${params.contractInfoId}/agreement/${params.agreementId}/summary`}
                    id="SLAView_Back"
                />
                <Header id="CostEditView_Header" title={ t('costs') } />

                <Section>
                    <CostCalculation
                        clientId={ params.clientId }
                        contractInfoId = {params.contractInfoId}
                        agreementId={ params.agreementId }
                        calculation = { totalFullCostcalculation }
                        title = "full_cost_calculation"
                        disciplineType = "full_cost_calculation"
                    />
                </Section>
                { ((isEditMode)=> {
                    return disciplineCalculations.map((disciplineCalculation, index) => {
                        //const disciplineAnchorName = 'discipline' + index;
                        return (
                            <Section key = { index }>
                                {/*<a name={ disciplineAnchorName } style={{ position: 'absolute', marginTop: '-5em' }} />*/}
                                <CostCalculation
                                    editMode={ isEditMode(index) }
                                    clientId={ params.clientId }
                                    contractInfoId = {params.contractInfoId}
                                    agreementId={ params.agreementId }
                                    calculation = { disciplineCalculation.calculation }
                                    title = { disciplineCalculation.disciplineCategory }
                                    disciplineType = { disciplineCalculation.disciplineCategory }
                                    disciplines={agreementDisciplines}
                                    updating={!!updating}
                                />
                            </Section>
                        );
                    });
                })(this.isEditMode)}
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        agreement: state.agreement,
        agreements: state.agreement.agreements,
        disciplines: state.discipline.disciplines,
        userProfile: state.userProfile.user,
        countries: state.countries,
        client: state.client,
        profile: state.userProfile,
        params: state.router.params,
        hash: state.router.location.hash,
        contractInfo: state.contractInfo,
        updating: state.discipline.updating,
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(CostEditView)));
