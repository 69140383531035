import { combineReducers }              from 'redux';
import { reducer as formReducer }       from 'redux-form';
import { routerReducer }                from 'react-router-redux';

import env                          from './env.js';
import userProfile                  from './userProfile.js';
import cookies                      from './cookies.js';
import searchResults                from './searchResults.js';
import client                       from './client.js';
import agreement                    from './agreement.js';
import building                     from './building.js';
import discipline                   from './discipline.js';
import translations                 from './translations.js';
import countries                    from './country.js';
import disciplineConfig             from './disciplineConfig.js';
import error                        from './error.js';
import files                        from './files.js';
import frameagreement               from './frameagreement.js';
import department                   from './department.js';
import billingIndex                 from './billingIndex.js';
import cccProcess                   from './cccProcess.js';
import report                       from './report.js';
import download                     from './download.js';
import attachment                   from './attachment.js';
import kitpack                      from './kitpack.js';
import system                       from './system.js';
import agreementContents            from './agreementContents.js';
import contractInfo                 from './contractInfo.js';
import signature                    from './signature';
import copying                      from './copying';

export default combineReducers({
    form: formReducer,
    router: routerReducer,
    env,
    userProfile,
    cookies,
    searchResults,
    client,
    agreement,
    building,
    discipline,
    translations,
    countries,
    disciplineConfig,
    error,
    frameagreement,
    department,
    files,
    billingIndex,
    cccProcess,
    report,
    download,
    attachment,
    kitpack,
    system,
    agreementContents,
    contractInfo,
    signature,
    copying
});
