import React from 'react';

import {
    commonStyles,
} from 'styles/design';

import _ from 'lodash';

const FrameAgreementServiceCarInfo = ({agreement, t, country}) => {
    const config = country.frameAgreementConfig;
    return (<div style={commonStyles.dataParentDiv}>

        <div style={commonStyles.dataContentHeaderDiv}>
            {t('service_car')}:
        </div>
        {
            (() => {
                if (agreement.serviceCar && agreement.serviceCar.length > 0) {
                    return agreement.serviceCar.map((option, index) => {
                        return (
                            <div
                                key={'service_car' + index}
                                className="ValueRow"
                                id={'FrameAgreementInfo_serviceCar_' + index}
                            >
                                {(option.type === 'custom' ? option.name : t(option.name))} {option.value} {option.valueType || ''}
                            </div>
                        );
                    })
                }
                return (
                    <div className="ValueRow">
                        {
                            (() => {
                                if (agreement.serviceCarType && config) {
                                    let serviceCar = agreement.serviceCarType;

                                    if (serviceCar === 'distance') {
                                        serviceCar = 'price_per_km';
                                    } else if (serviceCar === 'time') {
                                        serviceCar = 'price_per_hour';
                                    }
                                    const serviceCarConfigItem = _.find(config.serviceCar, {name: serviceCar});

                                    if (serviceCarConfigItem) {
                                        return (
                                            <div>{t(serviceCar)
                                            + ': '
                                            + (serviceCar !== 'other'
                                                ? (serviceCarConfigItem.value
                                                    + ' ' + country.currency)
                                                : agreement.serviceCarDescription)}
                                            </div>
                                        );
                                    }
                                }
                                return <div>{t('none_selected')}</div>;
                            })()
                        }
                    </div>
                );
            })()
        }

    </div>);
}

export default FrameAgreementServiceCarInfo;