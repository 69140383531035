import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    save as saveAgreement,
    loadWithIncludes as loadAgreement,
} from 'redux/modules/agreement.js';
import {
    loadClientContractInfos
} from 'redux/modules/client';
import {
    loadContractInfo,
    isContractInfoLoaded,
} from 'redux/modules/contractInfo';
import { loadWithType } from 'redux/modules/translations.js';
import {loadCountry} from 'redux/modules/country.js';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import {
    StandardPage,
    Header,
    BreadcrumbBar,
    BackLink,
    LoadingIndicator,
} from 'components';
import {
    isEditable
} from 'helpers/agreement.js';
import translations from 'decorators/translations.js';
import SmartViewForm from 'components/Agreement/SmartViewForm';
import SmartViewInformation from 'components/Agreement/SmartViewInformation';

const TRANSLATION_KEYS = [
    'mainagreement_s2_p3',
    'mainagreement_s2_p4',
    'mainagreement_s2_p5'
]
class SmartViewView extends Component {
    state = {
        editMode: false,
        isSaving: false,
        isLoading: true,
    };

    componentDidMount() {
        this.loadContents();
    }

    componentDidUpdate(prevProps) {
        const params = this.props.match.params;
        const prevParams = prevProps.match.params;
        if (params.agreementId !== prevParams.agreementId) {
            this.loadContents();
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            match: {
                params,
            },
            profile,
            loadAgreement,
        } = this.props;
        if (!nextProps.agreement) {
            return;
        }
        if (this.state.isSaving && nextProps.agreementState.reload) {
            loadAgreement(params.agreementId, profile.accessToken);
            this.setState({ isSaving: false });
            this.setState({ isLoading: true });
            window.scrollTo(0,0);
        } else if (this.state.isLoading && nextProps.agreementState.loaded) {
            this.setState({ isLoading: false, editMode: false });
        }
    }

    loadContents = async () => {
        const {
            match: {
                params
            },
            profile: {
                accessToken
            },
            loadAgreement,
            loadContractInfo,
            loadCountry,
            loadTranslations,
        } = this.props;

        await loadAgreement(params.agreementId, accessToken);
        const contractInfo = await loadContractInfo(params.contractInfoId, accessToken);
        const salesArea = contractInfo && contractInfo.result && contractInfo.result.salesArea;
        const country = await loadCountry(salesArea, accessToken);
        const defaultLanguage = country && country.result && country.result.defaultLanguage;
        const languages = ['en'];
        const lang = defaultLanguage || 'en';
        if (lang && lang !== 'en') {
            languages.push(lang);
        }
        await loadTranslations(2, languages.join(','), country.id, accessToken);
        this.setState({
            isLoading: false,
            language: lang
        });
    }

    handleSubmitAgreement = (data) => {
        const {
            match: {
                params,
            },
            profile,
            saveAgreement,
        } = this.props;
        const agreementId = params.agreementId;
        const newData = { ...data };
        newData.id = agreementId;
        if (!data.clientNet || data.clientNetCustomPrice == null || data.clientNetCustomPrice === '') {
            newData.clientNetCustomPrice = null;
        }
        const dispatchSave = saveAgreement(newData, profile.accessToken);
        this.setState({ isSaving: true });
        return dispatchSave;
    };

    setEditable = newState => {
        this.setState({
            editMode: newState
        });
    };

    getContent = () => {
        const {
            agreement,
            client,
            countries
        } = this.props;
        const {
            editMode,
            isLoading
        } = this.state;
        const printContent = this.getDescription();

        if ((isEmpty(agreement) || isEmpty(countries.selectedCountry)) && isLoading) {
            return <LoadingIndicator visible={true} />;
        } else if (editMode) {
            return (
                <SmartViewForm
                    cancelAction={ () => { this.setEditable(false); } }
                    agreement={ agreement }
                    onSubmit={ this.handleSubmitAgreement }
                    client={ client }
                    editing = { editMode }
                    countryConfig = { countries.selectedCountry }
                    submitting={ (this.state.isSaving || this.state.isLoading) }
                    printContent={ printContent }
                />
            );
        }
        return (
            <SmartViewInformation
                agreement={agreement}
                client={ client }
                countryConfig = { countries.selectedCountry }
                isEditable={isEditable(agreement)}
                editClicked={ () => { this.setEditable(true); }}
                printContent={ printContent }
            />
        );
    };

    getTranslations(translatables, keys, language) {
        return keys.map(key => {
            if (translatables && translatables[key]) {
                if (translatables[key][language]) {
                    return translatables[key][language];
                }
                else {
                    return translatables[key]['en-master'];
                }
            }
            return {
                disabled: true,
                value: ''
            };
        })
    }

    getDescription = () => {
        const {
            translatables,
            agreement,
            selectedCountry,
        } = this.props;
        if (!translatables || !selectedCountry || !selectedCountry.costCalculation) {
            return null;
        }
        const language = this.getAgreementLanguage();
        let keys = TRANSLATION_KEYS;
        if (agreement.smartViewPricing) {
            keys = compact(agreement.smartViewPricing.map((pricing, index) => {
                return pricing.enabled && `mainagreement_smartview_pricing_${index}`;
            }));
        }
        const printTexts = this.getTranslations(translatables, keys, language);
        if (!printTexts.some(Boolean)) {
            return null;
        }

        if (agreement.smartViewPricing) {
            return (
                <div>
                    {printTexts.map(printText => printText && !printText.disabled && <div style={{margin: '1rem 0'}}>{printText.value}</div>)}
                </div>
            )
        }
        return (
            <div>
                {!printTexts[0].disabled && <p>{ printTexts[0].value }</p>}
                <ul style={{ paddingLeft: '40px', listStyleType: 'disc', marginTop: '1em', marginBottom: '1em' }}>
                    {!printTexts[1].disabled && <li>{ printTexts[1].value }</li>}
                    {!printTexts[2].disabled && <li>{ printTexts[2].value }</li>}
                </ul>
            </div>
        )
    };

    getAgreementLanguage = () => {
        const {
            language,
            agreement,
            selectedCountry,
        } = this.props;
        if (agreement.language) {
            return agreement.language;
        }
        if (language === selectedCountry.defaultLanguage) {
            return language;
        }
        return 'en';
    };

    render() {
        const {
            agreement,
            contractInfo,
            match: {
                params
            },
            t,
        } = this.props;
        if (!agreement) {
            return null;
        }
        const {
            clientId,
            contractInfoId
        } = params;
        const basePath = `/client/${clientId}/contractInfo/${contractInfoId}/agreement/${agreement.id}`;

        return <StandardPage
            withBreadcrumb
            loaderVisible={this.props.agreement.loading}
        >
            <BreadcrumbBar
                page="agreement"
                contractInfo={contractInfo && contractInfo.contractInfo}
                agreement={agreement}
                actionUrl={ `/client/${clientId}/details` }
                linkName={ t('show_client_details') }
                lockedText={ !isEditable(agreement) ? t('locked') : null}
            />
            {
                agreement && agreement.id &&
                <BackLink
                    title={t('Back to agreement')}
                    to={`${basePath}/summary`}
                    id="SmartViewView_Back"
                />
            }
            <Header title={ t('SmartView') } />
            {this.getContent()}
        </StandardPage>
    }
}

const mapStateToProps = state => ({
    agreementState: state.agreement,
    agreement: state.agreement.agreement,
    client: state.client,
    countries: state.countries,
    profile: state.userProfile,
    contractInfo: state.contractInfo,
    selectedCountry: state.countries.selectedCountry,
    language: state.translations.language,
    translatables: state.translations.translatables,
});

const mapDispatchToProps = dispatch => ({
    loadContractInfo: (id, token) => dispatch(loadContractInfo(id, token)),
    loadCountry: (salesArea, token) => dispatch(loadCountry(salesArea, token)),
    loadAgreement: (agreementId, contractualInfoId, accessToken) => dispatch(loadAgreement(agreementId, contractualInfoId, accessToken)),
    loadClientContractInfos: (clientId, accessToken) => dispatch(loadClientContractInfos(clientId, accessToken)),
    isContractInfoLoaded: (client, accessToken) => dispatch(isContractInfoLoaded(client, accessToken)),
    saveAgreement: (newData, accessToken) => dispatch(saveAgreement(newData, accessToken)),
    loadTranslations: (type, languages, countryId, accessToken) => dispatch(loadWithType(type, languages, countryId, accessToken)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(SmartViewView));