import React from 'react';

import './Bundle.css';

const BundleItemList = (props) => {
    return (
        <div className="bundle-content">
            <div className="bundle-item-list">
                {props.children}
            </div>
        </div>
    );
};

export default BundleItemList;