import React, { Component } from 'react';
import translations from 'decorators/translations.js';
import { connect } from 'react-redux';
import {
    Header,
    StandardPage,
    LoadingIndicator
} from 'components';
import ContractInfoForm from 'components/ContractInfo/ContractInfoForm';
import _ from 'lodash';
import { loadClient } from 'redux/modules/client.js';
import { loadCountries }        from 'redux/modules/country.js';
import { loadAllDepartments }   from 'redux/modules/department.js';
import { loadBillingIndexes }   from 'redux/modules/billingIndex';
import { loadContractInfo, saveContractInfo }     from 'redux/modules/contractInfo';

class ClientContractInfoView extends Component {
    componentDidMount() {
        const {
            dispatch,
            match: {
                params
            }
        } = this.props;
        const promises = [];

        if (params.contractInfoId) {
            promises.push(dispatch(loadContractInfo(params.contractInfoId, this.props.profile.accessToken)));
        }
        promises.push(dispatch(loadClient(params.clientId, this.props.profile.accessToken)));
        promises.push(dispatch(loadCountries(this.props.profile.accessToken)));
        promises.push(dispatch(loadAllDepartments(this.props.profile.accessToken)));

        if (!this.props.billingIndexes || this.props.billingIndexes === 0) {
            promises.push(dispatch(loadBillingIndexes(this.props.profile.accessToken)));
        }
        return Promise.all(promises);
    }

    handleSubmit = (data) => {
        const {
            match: {
                params
            },
            profile,
            dispatch,
            history
        } = this.props;
        const formData = _.cloneDeep(data);
        if (!formData.id) {
            formData.id = params.contractInfoId;
        }
        this.setState({submitting: true});
        if (formData.salesArea) {
            if (!formData.specificBillingAddress || formData.specificBillingAddress === 'client') {
                formData.billingAddress = null;
            }
            if (!formData.specificContactInformation || formData.specificContactInformation === 'client') {
                formData.contactEmail = null;
                formData.contactPhone = null;
            }
            if (formData.departmentId === "0") {
                delete formData.departmentId;
            }
            const dispatchSave = dispatch(saveContractInfo(formData, profile.accessToken));
            dispatchSave.then(result => {
                this.setState({ submitting: false });
                if (result && !result.error) {
                    history.push('/client/' + params.clientId + '/contractInfo/' + params.contractInfoId);
                }
            });
            this.setState({ submitting: true });
            return dispatchSave;
        }
    };

    handleCancel = (event) => {
        const {
            clientId,
            contractInfoId
        } = this.props.match.params;
        this.props.history.push(`/client/${clientId}/contractInfo/${contractInfoId}`);
        event.preventDefault();
    };

    render() {
        const {
            t,
            contractInfo,
            client,
            department,
            countries,
            billingIndexes,
        } = this.props;

        return <StandardPage>
            <Header title={t('Edit contract info')}/>
            <section>
            { (contractInfo.loading || department.loading)
                ? <LoadingIndicator visible={true}/>
                : <ContractInfoForm
                    contractInfo={ contractInfo.contractInfo }
                    client={ client }
                    departments={department.departments}
                    countries={countries}
                    billingIndexes={billingIndexes}
                    onSubmit={ this.handleSubmit }
                    submitting={ false }
                    cancelAction={ this.handleCancel }
                />
            }
            </section>
        </StandardPage>;
    }
}

const mapStateToProps = (state) => ({
    contractInfo: state.contractInfo,
    client: state.client.client,
    profile: state.userProfile,
    countries: state.countries.countries,
    department: state.department,
    billingIndexes: state.billingIndex.all
});
const connector = connect(mapStateToProps);

export default translations(connector(ClientContractInfoView));