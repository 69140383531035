import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Collapse extends Component {
    state = {
        height: 'auto'
    };

    componentDidMount() {
        const containerHeight = this.getHeight();
        this.setHeight(containerHeight);
    }

    componentDidUpdate(prevProps, prevState) {
        const height = this.getHeight();

        if (prevProps.isCollapsed !== this.props.isCollapsed) {
            if (this.props.isCollapsed === true) {
                this.onCollapse();
            }
            else if (this.props.isCollapsed === false) {
                this.onExpand();
            }
        }

        if (this.state.height !== height ) {
            this.setHeight(height);
        }
    }

    getHeight = () => {
        return this.container ? this.container.scrollHeight : 0;
    };

    setHeight = newHeight => {
        this.setState({
            height: newHeight
        });
    };

    // Custom handler for content collapsing
    onCollapse = () => {
        if (this.props.onCollapse) {
            this.props.onCollapse(this.props);
        }
    };

    // Custom handler for content expanding
    onExpand = () => {
        if (this.props.onExpand) {
            this.props.onExpand(this.props);
        }
    };

    render () {
        const { isCollapsed, children } = this.props;
        const duration = this.state.height / 1200;

        // Set height
        const styles = {
            overflow: !isCollapsed ? 'visible' : 'hidden',
            transition: `height ${duration}s ease`,
            height: !isCollapsed ? 'auto' : 0 // TODO: Bring back animated collapse
        };

        return (
            <div
                ref={node => {
                    this.container = node;
                }}
                style={styles}
            >
                {children}
            </div>
        );
    }
}

Collapse.propTypes = {
    // Collapsable content
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    // Custom className
    className: PropTypes.string,
    // Open/closed state of collapse element
    isCollapsed: PropTypes.bool
};
