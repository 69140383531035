import React from 'react';
import { Link } from 'react-router-dom';
import translations from 'decorators/translations.js';

import './AgreementListItem.css';

const AgreementList = ({
        title,
        hoverTitle,
        variant,
        link,
        id,
        timeString,
        timeValue,
        agreementStatus,
        createdBy,
        actions,
        t
    }) => {

    let actionList;
    if (actions) {
        actionList = actions.map((action, idx) => {
            return (
                <span key={'action_key_' + idx} className="agreement-list-item-action">
                    {action}
                </span>
            )
        });
    }

    let variantClass = "agreement-list-item";
    if (variant) {
        variantClass += " agreement-list-item-" + variant;
    }

    return (
        <div className={ variantClass }>
            <div className="agreement-list-item-main">
                <div className="agreement-list-item-title-wrapper">
                    <span className="agreement-list-item-title">
                    { link ? (
                        <Link className="agreement-list-item-link" id={id} title={hoverTitle} to={link}>
                            {title}
                        </Link>
                    ) : (
                        <span title={hoverTitle}>{title}</span>
                    )}
                    </span>
                    <span className="agreement-list-item-time">
                        {timeString} {timeValue}
                    </span>
                </div>
                {
                    createdBy && <div className="agreement-list-item-created">{t('created')}: {createdBy}</div>
                }
            </div>
            <div className="agreement-list-item-side">
                { agreementStatus &&
                <span className={agreementStatus ? 'agreement-list-item-status agreement-list-item-status-' + agreementStatus : 'agreement-list-item-status'}>
                    {t(agreementStatus)}
                </span>
                }
                { actionList &&
                    <span className="agreement-list-item-actions">
                        { actionList }
                    </span>
                }
            </div>
        </div>
    )
}

export default translations(AgreementList);