import React from 'react';

import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import _ from 'lodash';
import {
    layout,
    commonStyles
} from 'styles/design.js';

import {
    Button,
    SFTextareaField,
    SFRadioFields
} from "components";
import FrameAgreementMaterialForm from "./FrameAgreementFormContents/FrameAgreementMaterialForm";
import FrameAgreementSubcontractingForm from "./FrameAgreementFormContents/FrameAgreementSubcontractingForm";
import MultiSelectionForm from './FrameAgreementFormContents/MultiSelectionForm';

const validate = (values, props) => {
    const errors = {};
    const minMargin = props.countryConfig && props.country.frameAgreementConfig ? props.country.frameAgreementConfig.minSubcontractingMargin : 0;
    values.subcontracting.map((price, idx) => {
        if (price.margin < minMargin) {
            if (!errors.subcontracting) {
                errors.subcontracting = [];
            }
            errors.subcontracting[idx] = {
                margin: props.t('invalid_margin_limit_is') + ' ' + minMargin
            };
        }
    });
    return errors;
};
const reduxFormObject = {
    form: 'createFrameAgreementForm',
    fields: [
        'serviceCarType',
        'serviceCarValue',
        'serviceCarDescription',
        'serviceCar[].name',
        'serviceCar[].value',
        'serviceCar[].valueType',
        'serviceCar[].type',
        'serviceCar[].description',
        'materials[].name',
        'materials[].discount',
        'materials[].margin',
        'materials[].valueType',
        'materials[].description',
        'materials[].type',
        'subcontracting[].name',
        'subcontracting[].margin',
        'materialsType',
        'departmentId',
        'paymentTerm',
        'printoutName',
        'additionalFees[].name',
        'additionalFees[].value',
        'additionalFees[].valueType',
        'additionalFees[].type',
        'additionalFees[].description',
        'serviceCarInputSelection'
    ],
    validate
};

class FrameAgreementPriceForm extends React.Component {
    static propTypes = {
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        contractInfo: PropTypes.object.isRequired,
    };
    state = {};

    componentWillMount() {
        const agreement = Object.assign({}, this.props.agreement),
            contractInfo = this.props.contractInfo,
            country = this.props.country;

        if (country.frameAgreementConfig) {
            const frameAgreementConfig = country.frameAgreementConfig || {};
            if (agreement.id) {
                agreement.additionalFees = _.filter(agreement.additionalFees, (fee) => {
                    //Take fees marked as custom, or those that have agreement config set.
                    return fee.type === 'custom' || _.find(frameAgreementConfig.additionalFees, {name: fee.name});
                });
                agreement.serviceCar = _.filter(agreement.serviceCar, (fee) => {
                    //Take fees marked as custom, or those that have agreement config set.
                    return fee.type === 'custom' || _.find(frameAgreementConfig.serviceCar, {name: fee.name});
                });
            } else {
                //Set arrays empty.
                agreement.materials = [];
                agreement.additionalFees = frameAgreementConfig.additionalFees ? frameAgreementConfig.additionalFees : [];
                agreement.serviceCar = frameAgreementConfig.serviceCar ? frameAgreementConfig.serviceCar : [];
            }
            if (!agreement.departmentId && contractInfo) {
                agreement.departmentId = contractInfo.departmentId;
            }
            if (!agreement.paymentTerm && contractInfo) {
                agreement.paymentTerm = contractInfo.paymentTerm;
            }
            if (agreement.serviceCarType === null || (agreement.serviceCar && agreement.serviceCar.length > 0)) {
                agreement.serviceCarInputSelection = 'new';
            } else {
                agreement.serviceCarInputSelection = 'old';
            }
            this.props.initializeForm(agreement);
        } else {
            this.setState({invalidConfigurations: true});
        }
    }

    handleAddMaterial = () => {
        this.props.fields.materials.addField();
    };

    handleRemoveMaterial = (materialIndex) => {
        this.props.fields.materials.removeField(materialIndex);
    };

    handleAddSubcontracting = () => {
        this.props.fields.subcontracting.addField();
    };

    handleRemoveSubcontracting = (index) => {
        this.props.fields.subcontracting.removeField(index);
    };
    toggleServiceCarType = (idx) => {
        if(idx === 'none') {
            this.setState((state) => ({...state, serviceCarType:null}));
        } else {
            this.setState((state) => ({...state, serviceCarType: idx}));
        }
    };

    toggleAdditionalFee = (checked, configuredFee) => {
        const existing = _.findIndex(this.props.fields.additionalFees, (fee) => fee.name && fee.name.value === configuredFee.name);
        if (checked) {
            this.props.fields.additionalFees.addField(configuredFee);
        } else if (existing !== -1) {
            this.props.fields.additionalFees.removeField(existing);
        }
    };
    toggleServiceCar = (checked, configuredFee) => {
        const existing = _.findIndex(this.props.fields.serviceCar, (fee) => fee.name && fee.name.value === configuredFee.name);
        if (checked) {
            this.props.fields.serviceCar.addField(configuredFee);
        } else if (existing !== -1) {
            this.props.fields.serviceCar.removeField(existing);
        }
    };

    render() {
        const {t, fields, country} = this.props;
        const config = country.frameAgreementConfig;
        return (
            <div>
                <div style={commonStyles.dataParentDiv} id="FrameAgreementForm_serviceCarType">
                    <div style={commonStyles.dataContentHeaderDiv}>
                        {t('service_car')}:
                    </div>
                    {
                        fields.serviceCarType.value !== null &&
                        <div style={{marginTop: 0}} className="CheckboxContainer">
                            <SFRadioFields
                                name="ServiceCarInputType"
                                formProperty={fields.serviceCarInputSelection}
                                options={[
                                    {
                                        label: t('Select single option'),
                                        value: 'old'
                                    },
                                    {
                                        label: t('Select multiple'),
                                        value: 'new'
                                    }
                                ]}
                            />
                        </div>
                    }
                    {
                        (() => {
                            if (fields.serviceCarInputSelection.value === 'old') {

                                return (
                                    <div className="service-car-selections">
                                        <h2>{t('options')}:</h2>
                                        <SFRadioFields
                                            name="serviceCarType"
                                            formProperty={fields.serviceCarType}
                                            options={config.serviceCar.map((row) => ({
                                                label: t(row.name),
                                                value: row.name
                                            }))}
                                        />
                                        <div
                                            style={fields.serviceCarType.value === 'other' ? layout.block : layout.none}>
                                            <SFTextareaField
                                                formProperty={fields.serviceCarDescription}
                                                rows={4}
                                                fieldName={t('service_car_other_description')}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <div className="service-car-selections">
                                    <h2>
                                        {t('options')}:
                                    </h2>
                                    <MultiSelectionForm
                                        t={t}
                                        type="serviceCar"
                                        options={fields.serviceCar}
                                        country={country}
                                        toggle={this.toggleServiceCar}
                                    />
                                </div>
                            );
                        })()
                    }

                </div>
                <div style={commonStyles.dataParentDiv} id="FrameAgreementForm_additionalFees">
                    <div style={commonStyles.dataContentHeaderDiv}>
                        {t('additional_fees')}:
                    </div>
                    <MultiSelectionForm
                        t={t}
                        type="additionalFees"
                        options={fields.additionalFees}
                        country={country}
                        toggle={this.toggleAdditionalFee}
                    />
                </div>


                <FrameAgreementMaterialForm
                    materials={fields.materials}
                    country={country}
                    t={t}
                    handleAddMaterial={this.handleAddMaterial}
                    handleRemoveMaterial={this.handleRemoveMaterial}
                />
                <FrameAgreementSubcontractingForm
                    t={t}
                    subcontracting={fields.subcontracting}
                    handleAddSubcontracting={this.handleAddSubcontracting}
                    handleRemoveSubcontracting={this.handleRemoveSubcontracting}
                />
                <div className="ContentDiv">
                    <Button
                        variant="link"
                        id="FrameAgreementPriceFor_cancel"
                        onClick={this.props.cancel}
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        id="FrameAgreementPriceFor_save"
                        onClick={this.props.handleSubmit}
                        loading={this.props.saving}
                        disabled={this.props.saving}
                    >
                        {t('save')}
                    </Button>
                </div>
            </div>
        );
    }
}

export default reduxForm(reduxFormObject)(FrameAgreementPriceForm);
