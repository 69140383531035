import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium from 'configuredRadium.js';
import {measures, layout} from 'styles/design.js';
import {
    SFSelectField,
} from 'components';


const mediumFieldStyle = [
    layout.inlineBlock,
    {
        width: '10em',
        marginRight: measures.standard
    }
];

class SFMultiplierSelect extends Component {

    static propTypes = {
        hoursOfServiceRow: PropTypes.object
    };

    onChange = (event) => {
        this.setState({value: event.target.value});
        this.props.multiplier.onChange(event);
    };

    render() {
        const {t, multiplier, showEvery10th, boxWidth} = this.props;
        return (
            <div style={ Object.assign({}, mediumFieldStyle, boxWidth ? { width: boxWidth } : {}) }>
                <SFSelectField
                    defaultInput={true}
                    value={multiplier.value}
                    onChange={this.onChange}
                    fieldName={t('hours_visits_repetition')}
                    style={{ width: boxWidth }}
                >
                    <option value = "1">{ t('visits_repetition_1') }</option>
                    <option value = "0.5">{ t('visits_repetition_0.5') }</option>
                    <option value = "0.33">{ t('visits_repetition_0.33') }</option>
                    <option value = "0.25">{ t('visits_repetition_0.25') }</option>
                    <option value = "0.2">{ t('visits_repetition_0.2') }</option>
                    {
                        showEvery10th && <option value="0.1">{t('visits_repetition_0.1')}</option>
                    }
                </SFSelectField>
            </div>
        );
    }
}

export default translations(configuredRadium(SFMultiplierSelect));
