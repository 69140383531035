import React, { Component }                 from 'react';
import PropTypes                            from 'prop-types';

import './Dropdown.css';

class DropdownMenu extends Component {
    
    static propTypes = {
        style: PropTypes.object,
        title: PropTypes.string
    };
    render() {
        return (
            <div className="dropdown-title" style={ this.props.style }>
                { this.props.title }
            </div>
        );
    }
}

export default DropdownMenu;
