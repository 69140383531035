import React from 'react';
import translations from 'decorators/translations.js';
import './CalendarMatrix.css';

const CalendarMatrix = ({ t, items }) => {
    const monthTranslations = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

    const renderHeaderRow = (title) => {
        return (
            <div className="calendar-matrix-row">
                <div className="calendar-matrix-item-title calendar-matrix-col"></div>
                { monthTranslations.map((title) => {
                    return renderHeaderName(title);
                })}
            </div>
        );
    };

    const renderHeaderName = (month) => {
        return (
            <div className="calendar-matrix-col calendar-matrix-header">{ t(month) }</div>
        );
    };

    const renderRow = (item) => {
        return (
            <div className="calendar-matrix-row">
                <div className="calendar-matrix-item-title calendar-matrix-col">
                    { item.title }
                </div>
                { item.months.map((month) => 
                    renderMonth(month)
                )}
            </div>
        );
    };

    const renderMonth = (month) => {
        return (
            <div 
                className={ 
                    'calendar-matrix-col ' 
                    + (month 
                        ? 'calendar-matrix-item-checked' 
                        : 'calendar-matrix-item-unchecked'
                    ) 
                } 
            />
        );
    };

    return (
        <div className="calendar-matrix">
            { renderHeaderRow(monthTranslations) }
            { items.map((item) => {
                return renderRow(item);
            })}
        </div>
    );
}

export default translations(CalendarMatrix);