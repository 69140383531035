import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';

import {
    StandardPage,
    CountrySelectionList,
} from 'components';

import {
    measures,
    commonStyles
} from 'styles/design.js';

import {loadCountries} from 'redux/modules/country.js';

class CountrySelectionView extends Component {

    componentWillMount() {
        if (!this.props.countries.loadedAll) {
            return this.props.dispatch(loadCountries(this.props.profile.accessToken));
        }
    }
    render() {
        if (!this.props.user.isAdmin && !this.props.user.isController) {
            return (<div>
                <StandardPage
                    loaderVisible={
                        this.props.countries.loading
                    }>
                    <header>
                        <h1 style={commonStyles.pageTitle}>{this.props.t('country_selection_view_title')}</h1>
                    </header>
                </StandardPage>
            </div>);
        }
        return (
            <div>
                <StandardPage loaderVisible={
                    this.props.countries.loading
                }>
                    <header>
                        <h1 style={commonStyles.pageTitle}>
                            <div style={commonStyles.headerIcon}><i className={'fa fa-wrench'}/></div>
                            {this.props.t('country_selection_view_title')}</h1>
                    </header>
                    <div style={{marginTop: measures.standard}}>
                        <CountrySelectionList countries={this.props.countries}/>
                    </div>
                </StandardPage>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client,
        countries: state.countries,
        user: state.userProfile.user,
        profile: state.userProfile
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(CountrySelectionView));