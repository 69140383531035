import React from 'react';
import './ButtonGroup.css';

const ButtonGroup = ({ children, ...other }) =>
    <div
        className="button-group"
        { ...other }
    >
        { children }
    </div>;

export default ButtonGroup;