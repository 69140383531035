import React from 'react';

import {
    SFSelectField
} from 'components';

const DepartmentSelection = (props) => {
    const { t, departments } = props;
    if(departments.length === 0) {
        return <div>{t('no_departments')}</div>;
    }
    return (
        <SFSelectField
            defaultInput={true}
            onChange={props.onChange}
            value={props.value}
            id={props.id || 'selectDepartment'}
            fieldName={t('department')}
        >
            <option value="0">{t('select')}</option>
            {
                departments && departments.length > 0 &&
                departments.map((dep) =>
                    <option value={dep.id} key={dep.name}>{dep.name}</option>)
            }
        </SFSelectField>
    );
};

export default DepartmentSelection;