import getCSRFUrl   from 'helpers/getCSRFUrl.js';

const SEARCH          = 'ServiFlex/Search/SEARCH',
    SEARCH_SUCCESS    = 'ServiFlex/Search/SEARCH_SUCCESS',
    SEARCH_FAIL       = 'ServiFlex/Search/SEARCH_FAIL',
    SEARCH_CLEAR      = 'ServiFlex/Search/SEARCH_CLEAR';

const initialState = { searching: false, results: [], searched: false };

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SEARCH:
            return {
                ...state,
                searching: true
            };
        case SEARCH_SUCCESS:
            return {
                ...state,
                searching: false,
                searched: true,
                results: action.result,
                searchString: action.searchString
            };
        case SEARCH_FAIL:
            return {
                ...state,
                searching: false,
                searched: false,
                error: action.error
            };
        case SEARCH_CLEAR:
            return {
                ...state,
                searching: false,
                searched: false,
                results: []
            };
        default:
            return state;
    }
}

export function search(s, accessToken) {
    if (typeof(s) === 'object') {
        if (s.clear) {
            return { type: SEARCH_CLEAR, searchString: '' };
        }
        if (s.waitForInput) {
            return { type: SEARCH, searchString: '' };
        }
    } else if (typeof(s) === 'string' && s.length > 0) {
        return {
            types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
            promise: (client) => client.get(
                getCSRFUrl('/search/fulltext', '', {searchString: s}), { accessToken }
            ),
            searchString: s
        };
    }
    return { type: SEARCH_CLEAR, searchString: '' };
}

