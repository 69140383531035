import React, { Component }  from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import { reduxForm }                    from 'redux-form';
import _ from 'lodash';

import {
    SFInputField,
    RichTextEditor,
    DisciplineIcon,
    SaveButton,
    CancelButton
}                                       from 'components';

import {
    fonts,
    size,
    layout,
    measures
}                                       from 'styles/design.js';

const disciplineNameStyle = [
    fonts.sanches,
    size.zeta,
    layout.inline,
    {
        paddingLeft: measures.standard,
        paddingRight: measures.standard
    }
];

const disciplineIconStyle = [
    {
        margin: measures.standard,
        maxWidth: '50px'
    }
];

const formTitleStyle = {
    fontSize: size.normal.fontSize,
    fontFamily: fonts.arial.fontFamily
};

const reduxFormObject = {
    form: 'countryConfigForm',
    fields: [
        'disciplineCategory',
        'disciplineType',
        'disciplineConfig',
        'countryId',
        'projectMargin',
        'id',
    ]
};
class DisciplineTypeConfigForm extends Component {
    static propTypes = {
        disciplineConfig: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        cancelAction: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired
    };
    constructor(props) {
        super(props);
        this.state = {terms: {}};
    }

    componentWillMount() {
        this.props.initializeForm(this.props.disciplineConfig);
        if(this.props.disciplineConfig.terms && !_.isEmpty(this.props.disciplineConfig.terms)) {
            this.setState({terms: this.props.disciplineConfig.terms});
        }
    }
    componentWillReceiveProps(nextProps) {
        if(_.isEmpty(this.props.disciplineConfig.terms) && !_.isEmpty(nextProps.disciplineConfig.terms)) {
            this.setState({terms: nextProps.disciplineConfig.terms});
        }
    }

    handleCancel(event) {
        this.props.initializeForm(this.props.disciplineConfig);
        this.props.cancelAction(event);
    }

    changeTermsValue = (key, value) => {
        const terms = Object.assign({}, this.state.terms);
        if(value === '<p><br></p>') {
            value = null;
        }
        terms[key] = value;
        this.props.fields.terms.onChange(terms);
        this.setState({terms});
    };

    render() {
        const {
            t,
            params,
            fields,
            handleSubmit
        } = this.props;

        return (
            <div style={{ overflow: 'auto', clear: 'both' }}>
                <DisciplineIcon style={ disciplineIconStyle } diciplineType={ params.disciplineType } />

                <h2 style={ disciplineNameStyle }>
                    { t(params.disciplineType) }
                </h2>

                <form onSubmit={ handleSubmit } style={{ marginTop: '2.2em', marginLeft: '50px', paddingLeft: '1em' }}>

                    <SFInputField
                        oneline
                        formProperty={ fields.projectMargin }
                        fieldName={ t('project_margin') + ' %' }
                        placeholder="0,00"
                        maxLength="4"
                    />
                </form>

                <div style={ [layout.floatRight, layout.inlineBlock, { clear: 'both', margin: '2em 0' }] }>
                    <SaveButton onClick={ handleSubmit }
                        disabled={ this.props.submitting }
                        submitting={ this.props.submitting }
                    />

                    <div style={{ display: 'inline-block', float: 'right' }}>
                        <CancelButton cancelAction={ this.handleCancel.bind(this) } />
                    </div>
                </div>
            </div>
        );
    }
}


function mapStateToProps() {
    return {};
}
export default translations(reduxForm(reduxFormObject, mapStateToProps)(configuredRadium(DisciplineTypeConfigForm)));
