import React from 'react';
import './Accordion.css';

const AccordionTitle = props => {
    return <div className="accordion-title">
        { props.children }
    </div>;
};

export default AccordionTitle;
