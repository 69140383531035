import React from 'react';
import translations from 'decorators/translations.js';

import {
    commonStyles,
    measures,
    colors,
    layout,
} from 'styles/design.js';

const labelStyle = {
    ...layout.block,
    width: '100%',
    marginBottom: measures.standard
};

const validityIndicatorStyle = {
    ...layout.inlineBlock,
    ...layout.floatRight,
    ...layout.absolute,
    ...layout.verticalMiddle,
    color: colors.basicGreen,
    lineHeight: '1.5em',
    fontSize: '150%',
    marginTop: '0.5em',
    marginLeft: '-1.25em'
};
const invalidityIndicatorStyle = {
    ...layout.inlineBlock,
    ...layout.floatRight,
    ...layout.absolute,
    ...layout.verticalMiddle,
    color: colors.basicRed,
    lineHeight: '1.5em',
    fontSize: '150%',
    marginTop: '0.5em',
    marginLeft: '-1.25em'
};

const formSelectStyle = {
    ...commonStyles.select,
    marginTop: '0.5em'
};

const mandatoryInputStyle = {
    borderWidth: '2px',
};

const inInvalidStateInputStyle = {
    borderColor: colors.basicRed
};

const validationErrorStyle = {
    color: colors.basicRed
};

const onelineInputStyle = {
    ...layout.textRight,
    ...layout.floatRight,

    display: 'inline-block',
    marginTop: '0'
};

const twoRowsInputStyle = {
    width: '100%',
    display: 'block'
};


const onelineLabelStyle = {
    ...layout.verticalMiddle,
    display: 'inline-block',
    lineHeight: '2.5em',
    height: '2.5em',
};

const twoRowsLabelStyle = {
    paddingTop: measures.standard
};

const SFSelectField = (properties) => {
    const props = Object.assign({}, properties);
    if (!props.formProperty) {
        props.formProperty = {};
    }
    const hideIcons = Boolean(props.hideIcons) || false;
    const labelStyles = Object.assign({}, labelStyle, props.oneline ? onelineLabelStyle : twoRowsLabelStyle);
    const inputStyles = props.oneline ? onelineInputStyle : twoRowsInputStyle;
    return (
        <label style={labelStyles}>
            {(() => {
                if ((props.formProperty && props.formProperty.error) || props.error) {
                    return <span style={validationErrorStyle}>{props.error || props.formProperty.error}</span>;
                }
                return props.fieldName;
            })()
            }
            <div style={inputStyles}>
                {
                    (() => {
                        const inputStyles = Object.assign({}, formSelectStyle);
                        if(props.style) Object.assign(inputStyles, props.style);
                        if(props.mandatory) Object.assign(inputStyles, mandatoryInputStyle);
                        if(props.selectStyle) Object.assign(inputStyles, props.selectStyle);
                        if(props.formProperty && props.formProperty.error) Object.assign(inputStyles, inInvalidStateInputStyle);
                        const inputData = {
                            disabled: props.disabled,
                            style: inputStyles,
                            id: props.id
                        };
                        if(props.defaultInput) {
                            Object.assign(inputData,
                                {
                                    onChange: props.onChange,
                                    value: props.value || ''
                                });
                        }
                        if(props.formProperty) {
                            Object.assign(inputData, props.formProperty);
                        }
                        if(props.onChangeAction) {
                            Object.assign(inputData, {onChange: props.onChangeAction});
                        }
                        if(props.className) {
                            Object.assign(inputData, {className: props.className});
                        }
                        return <select {...inputData}>{props.children}</select>;
                    })()
                }

                {(() => {
                    if (!hideIcons) {
                        if (props.formProperty.error) {
                            return <span style={invalidityIndicatorStyle}><i className="fa fa-exclamation"/></span>;
                        } else if (props.formProperty.touched && props.mandatory) {
                            return <span style={validityIndicatorStyle}><i className="fa fa-check"/></span>;
                        }
                    }
                })()
                }
            </div>
        </label>
    );
};
export default translations(SFSelectField);
