import React            from 'react';
import translations from 'decorators/translations.js';

import './RichTextView.css';

const RichTextView = (props) => {

    if (props.value) {
        return (
            <div
                id={props.id}
                className="RichText-contents"
                style={props.labelStyle}
                dangerouslySetInnerHTML={{ __html: props.value }} />
        );
    }
    return (
        <div
            id={props.id}
            className="RichText-contents"
            style={props.labelStyle}
        >
            {props.children}
        </div>
    );
};

export default translations(RichTextView);
