import React from 'react';
import {
    SFCheckBox
} from 'components';


const Option = ({toggle, label, checked, id}) => {
    return (
        <div className="valueRow" key={id}>
            <label>
                <SFCheckBox
                    defaultInput={true}
                    id={id}
                    onChange={toggle}
                    value={checked}
                    rightLabel={label}
                />
            </label>
        </div>
    );
}

export default Option;