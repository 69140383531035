import React from 'react';
import translations from 'decorators/translations.js';
import ReportSection from './ReportSection.js';
import {
    ReportTableMulti,
    Section
} from 'components';

const ByDiscipline = ({
    totalsByDiscipline,
    chartSize,
    t
}) => {
    if (totalsByDiscipline && totalsByDiscipline.length > 0) {
        return (
            <ReportSection title={ t('Totals by discipline')}>
                <ReportTableMulti
                    data={totalsByDiscipline}
                />
            </ReportSection>
        );
    }
    return (
        <Section>
            <p>{ t('no_agreements_available') }</p>
        </Section>
    )
}

export default translations(ByDiscipline);