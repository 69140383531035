import React, {Component} from 'react';
import translations from 'decorators/translations';
import PropTypes from 'prop-types';
import {numberFormat} from 'helpers/stringTemplates.js';
import _ from 'lodash';

import {
    commonStyles,
    layout,
    colors,
} from 'styles/design.js';

import {
    KeyValueDiv
} from 'components';

const infoContainerTable = {
    width: '100%'
};

const infoLabelCellStyle = {
    padding: '0.75em',
    width: '70%',
};

const infoDataCellStyleLeft = {
    padding: '0.75em',
    width: '30%',
};

const bodyStyle = {width: '100%'};

const priceTypes = {
    upfront: 'Upfront price',
    yearly: 'Yearly price',
    monthly: 'Monthly price'
};

class CountryInformation extends Component {

    static propTypes = {
        countryConfig: PropTypes.object.isRequired,
        languages: PropTypes.array.isRequired,
    };

    countHourlyPrice(costPerHour, marginPercentage) {
        //return numberFormat((costPerHour && marginPercentage) ? costPerHour * (1 + (marginPercentage / 100)) : 0);
        return numberFormat((costPerHour && marginPercentage) ? costPerHour / (1 - (marginPercentage / 100)) : 0);
    }

    render() {
        const {t, countryConfig} = this.props;
        const costCalc = countryConfig.costCalculation ? countryConfig.costCalculation : {};
        const costPerHour = _.sum(
            (
                [
                    costCalc.projectLeader ? parseFloat(costCalc.projectLeader) : 0,
                    costCalc.salary ? parseFloat(costCalc.salary) : 0,
                    costCalc.serviceCar ? parseFloat(costCalc.serviceCar) : 0,
                    costCalc.socialCost ? parseFloat(costCalc.socialCost) : 0,
                    costCalc.tools ? parseFloat(costCalc.tools) : 0,
                    costCalc.riskFactor ? parseFloat(costCalc.riskFactor) : 0
                ].concat(
                    costCalc.customValues ? costCalc.customValues.map((customValue) => {
                        return parseFloat(customValue.value);
                    }) : 0
                ))
        );

        const hourlyPrice = this.countHourlyPrice(costPerHour, countryConfig.projectMargin);
        return (
            <div>
                <div style={{height: '2em'}}>
                    <div style={{...layout.floatRight, ...layout.inlineBlock}}>
                        <button
                            id="CountryInformation_edit"
                            type="button"
                            onClick={this.props.editClicked}
                            style={{...commonStyles.button, backgroundColor: colors.lightBlue}}>
                            {t('edit')}
                        </button>
                    </div>
                </div>
                <div style={commonStyles.dataParentDiv}>
                    <div style={commonStyles.dataContentHeaderDiv}>
                        {t('country_values')}:
                    </div>
                    <KeyValueDiv name={t('currency')}
                                 value={countryConfig.currency}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('Exchange rate') + ' (' + countryConfig.currency + ' -> EUR)'}
                                 value={countryConfig.exchangeRate}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('default_language')}
                                 value={countryConfig.defaultLanguage}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('sales_organization')}
                                 value={countryConfig.salesOrganization}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('national_phone_number')}
                                 value={countryConfig.nationalPhoneNumber}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('caverion_unit_in_agreement')}
                                 value={countryConfig.caverionSupplierName}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('company_reg_num_in_agreement')}
                                 value={countryConfig.caverionCompanyRegNo}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('addon_limit') + ' (' + countryConfig.currency + ')'}
                                 value={countryConfig.addOnLimit}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('subcontracting_minimum_margin')}
                                 value={countryConfig.subcontractingMinMargin ? countryConfig.subcontractingMinMargin : 0 + ' %'}
                                 rightStyles={layout.quarterWidth}/>
                </div>
                <div style={commonStyles.dataParentDiv}>
                    <div style={commonStyles.dataContentHeaderDiv}>
                        {t('cost_calculation')}:
                    </div>
                    <KeyValueDiv name={t('salary')}
                                 value={(costCalc.salary ? costCalc.salary : 0)}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('social_cost')}
                                 value={(costCalc.socialCost ? costCalc.socialCost : 0)}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('project_leader')}
                                 value={(costCalc.projectLeader ? costCalc.projectLeader : 0)}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('service_car')}
                                 value={(costCalc.serviceCar ? costCalc.serviceCar : 0)}
                                 rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('tools')}
                                 value={costCalc.tools ? costCalc.tools : 0} rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('risk_factor')}
                                 value={costCalc.riskFactor ? costCalc.riskFactor : 0}
                                 rightStyles={layout.quarterWidth}/>
                    {
                        (() => {
                            if (costCalc.customValues && costCalc.customValues.length > 0) {
                                return costCalc.customValues.map((value) => {
                                    return (<KeyValueDiv name={value.name} value={value.value}
                                                         rightStyles={layout.quarterWidth}/>);
                                });
                            }
                        })()
                    }
                    <KeyValueDiv rightStyles={{fontWeight: 'bold', ...layout.quarterWidth}}
                        leftStyles={{fontWeight: 'bold'}}
                        name={t('cost_per_hour')} value={costPerHour + ' ' + countryConfig.currency}
                    />

                    <KeyValueDiv name={t('project_margin')} value={countryConfig.projectMargin + ' %'}
                        rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('vat')} value={(countryConfig.vat ? countryConfig.vat : 0) + ' %'}
                        rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv rightStyles={{fontWeight: 'bold', ...layout.quarterWidth}}
                        leftStyles={{fontWeight: 'bold'}}
                        name={t('hourly_price')} value={hourlyPrice + ' ' + countryConfig.currency}
                    />
                    <KeyValueDiv name={t('addon_limit')} value={(countryConfig.addOnLimit ? countryConfig.addOnLimit : 0) + ' ' + countryConfig.currency}
                        rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('subcontracting_minimum_margin')} value={(countryConfig.subcontractingMinMargin ? countryConfig.subcontractingMinMargin : 0) + ' %'}
                        rightStyles={layout.quarterWidth}/>
                    <KeyValueDiv name={t('SmartView yearly price')} value={(countryConfig.clientNetDefaultPrice ? countryConfig.clientNetDefaultPrice : 0) + ' ' + countryConfig.currency}
                        rightStyles={layout.quarterWidth}/>

                </div>
                <div style={commonStyles.dataParentDiv}>
                    <div style={commonStyles.dataContentHeaderDiv}>
                        {t('SmartView pricing')}
                    </div>
                    {!costCalc.smartViewPricing
                        ? null
                        : _.map(costCalc.smartViewPricing, (pricing, index) =>
                        <div key={index}>
                            <KeyValueDiv name={pricing.name} value={`${pricing.price} ${countryConfig.currency} / ${t(pricing.priceTarget ? pricing.priceTarget : 'building')}`}
                                rightStyles={layout.quarterWidth}/>
                        </div>
                    )}
                </div>
                <div style={commonStyles.dataParentDiv}>
                    <div style={commonStyles.dataContentHeaderDiv}>
                        {t('service_level_descriptions')}:
                    </div>
                    <table style={infoContainerTable}>
                        <tbody style={bodyStyle}>
                        <tr>
                            <td style={infoLabelCellStyle}>{t('service_level_description_bronze')}:</td>
                        </tr>
                        <tr>
                            <td style={infoDataCellStyleLeft}>
                                {countryConfig.bronzeServiceLevelDescription}
                            </td>
                        </tr>
                        <tr>
                            <td style={infoLabelCellStyle}>{t('service_level_description_silver')}:</td>
                        </tr>
                        <tr>
                            <td style={infoDataCellStyleLeft}>
                                {countryConfig.silverServiceLevelDescription}
                            </td>
                        </tr>
                        <tr>
                            <td style={infoLabelCellStyle}>{t('service_level_description_gold')}:</td>
                        </tr>
                        <tr>
                            <td style={infoDataCellStyleLeft}>
                                {countryConfig.goldServiceLevelDescription}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default translations(CountryInformation);
