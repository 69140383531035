import React            from 'react';

const defaultStyle = { maxWidth: '50px', maxHeight: '45px' };

const DisciplineIcon = (props) => {
    return (
        () => {
            switch (props.diciplineType) {
                case 'heating' : return <img alt="icon" src={require('images/heating.svg')} style={ props.imgStyle || defaultStyle }/>;
                case 'info_communication' : return <img alt="icon" src={require('images/information_communication.svg')} style={ props.imgStyle || defaultStyle } />;
                case 'safety' : return <img alt="icon" src={require('images/security.svg')} style={ props.imgStyle || defaultStyle } />;
                case 'ventilation_and_air_conditioning' : return <img alt="icon" src={require('images/ventilation.svg')} style={ props.imgStyle || defaultStyle } />;
                case 'cooling' : return <img alt="icon" src={require('images/cooling.svg')} style={ props.imgStyle || defaultStyle } />;
                case 'electricity' : return <img alt="icon" src={require('images/electric.svg')} style={ props.imgStyle || defaultStyle } />;
                case 'automation' : return <img alt="icon" src={require('images/automation.svg')} style={ props.imgStyle || defaultStyle } />;
                case 'energy' : return <img alt="icon" src={require('images/energy.svg')} style={ props.imgStyle || defaultStyle } />;
                case 'facility_services' : return <img alt="icon" src={require('images/facility_services.svg')} style={ props.imgStyle || defaultStyle } />;
                case 'full_cost_calculation' : return <img alt="icon" src={require('images/full_cost_calculation.svg')} style={ props.imgStyle || defaultStyle } />;
                case 'management' : return <i className="fa fa-black-tie" style={{fontSize: '30px', color: '#002D6E' }} />;
                default:
                case 'remote_center' : return <img alt="icon" src={require('images/facility_services.svg')} style={ props.imgStyle || defaultStyle } />;
            }
        })()
        ;
};

export default DisciplineIcon;
