import _            from 'lodash';

const LOAD                 = 'ServiFlex/Building/LOAD',
    LOAD_SUCCESS           = 'ServiFlex/Buliding/LOAD_SUCCESS',
    LOAD_FAIL              = 'ServiFlex/Building/LOAD_FAIL',
    LOAD_BUILDINGS         = 'ServiFlex/Building/LOAD_BUILDINGS',
    LOAD_BUILDINGS_SUCCESS = 'ServiFlex/Building/LOAD_BUILDINGS_SUCCESS',
    LOAD_BUILDINGS_FAIL    = 'ServiFlex/Building/LOAD_BUILDINGS_FAIL',
    SAVE                   = 'ServiFlex/Building/SAVE',
    SAVE_SUCCESS           = 'ServiFlex/Building/SAVE_SUCCESS',
    SAVE_FAIL              = 'ServiFlex/Building/SAVE_FAIL',
    LOAD_TYPES             = 'ServiFlex/Building/LOAD_TYPES',
    LOAD_TYPES_SUCCESS     = 'ServiFlex/Building/LOAD_TYPES_SUCCESS',
    LOAD_TYPES_FAIL        = 'ServiFlex/Building/LOAD_TYPES_FAIL',
    DELETE                  = 'ServiFlex/Building/DELETE',
    DELETE_SUCCESS          = 'ServiFlex/Building/DELETE_SUCCESS',
    DELETE_FAIL             = 'ServiFlex/Building/DELETE_FAIL';

const initialState = { loading: false, types: [], buildings: [], loaded: false, reload: true, saving: false, building: {} };

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                building: action.result
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_BUILDINGS:
            return {
                ...state,
                loading: true
            };
        case LOAD_BUILDINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                byContractInfo: action.result,
                //buildings: action.result
            };
        case LOAD_BUILDINGS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SAVE:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                building: action.result,
                error: action.error
            };
        case SAVE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };
        case LOAD_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                types: action.result
            };
        case DELETE:
            return {
                ...state,
                deleting: true
            };
        case DELETE_SUCCESS:
            return {
                ...state,
                deleting: false,
                deleted: true,
                reload: true
            };
        case DELETE_FAIL:
            return {
                ...state,
                deleting: false,
                deleted: false,
                error: action.error ? action.error.error : ''
            };
        default:
            return state;
    }
}

export function loadBuilding(buildingId, accessToken) {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client) => client.get(`/buildings/${buildingId}?filter=${encodeURIComponent(JSON.stringify({ include: {disciplines: 'disciplineSystems'}}))}`, { accessToken }
        )
    };
}

export function loadTypes(accessToken) {
    return {
        types: [LOAD_TYPES, LOAD_TYPES_SUCCESS, LOAD_TYPES_FAIL],
        promise: (client) => client.get(
            '/buildings/types', { accessToken }
        )
    };
}
export function loadBuildings(clientId, accessToken) {
    return {
        types: [LOAD_BUILDINGS, LOAD_BUILDINGS_SUCCESS, LOAD_BUILDINGS_FAIL],
        promise: (client) => client.get(
            '/clients/' + clientId + '/buildingsWithIncludes', { accessToken }
        )
    };
}

export function save(building, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.post('/buildings/save', { data: building, accessToken }
        )
    };
}

export function areBuildingsLoaded(state) {
    if(state.building && state.building.loaded) {
        return true;
    }
    return false;
}
export function deleteBuilding(id, accessToken) {
    return {
        types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
        promise: (client) =>
            client.del('/buildings/' + id + '/deleteBuilding', {accessToken}
        )
    }
}