import React, {Component }  from 'react';
import translations         from 'decorators/translations.js';
import configuredRadium     from 'configuredRadium.js';
import PropTypes            from 'prop-types';
import { Link }          from 'react-router-dom';
import {
    commonStyles,
} from 'styles/design.js';

class EditCostsButton extends Component {

    static propTypes = {
        clientId: PropTypes.number.isRequired,
        agreementId: PropTypes.number.isRequired
    };

    getLinkUrl() {
        const { clientId, agreementId, contractInfoId, disciplineAnchorName } = this.props;
        return `/client/${clientId}/contractInfo/${contractInfoId}/agreement/${agreementId}/costs/edit#${disciplineAnchorName}`;
    }

    render() {
        return (
        <Link
            to={ this.getLinkUrl() }
            style = { commonStyles.buttonLink }
        >
            {this.props.t('adjust_income')}
        </Link>
    );}
}

export default translations(configuredRadium(EditCostsButton));