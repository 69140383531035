import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';
import {loadCountries} from 'redux/modules/country.js';
import {loadClient} from 'redux/modules/client.js';
import {saveContractInfo} from 'redux/modules/contractInfo.js';
import {loadAllDepartments} from 'redux/modules/department.js';
import {loadBillingIndexes} from 'redux/modules/billingIndex.js';
import _ from 'lodash';

import {
    StandardPage,
    LoadingIndicator
} from 'components';
import ContractInfoForm from 'components/ContractInfo/ContractInfoForm.js';

import {
    commonStyles
} from 'styles/design.js';


class CreateContractInfoView extends Component {
    constructor(props) {
        super(props);
        this.state = {dataLoaded: false};
    }
    componentWillMount() {
        const {
            dispatch,
            match: {
                params
            }
        } = this.props;
        const promises = [];
        promises.push(dispatch(loadClient(params.clientId, this.props.profile.accessToken)));
        promises.push(dispatch(loadCountries(this.props.profile.accessToken)));
        promises.push(dispatch(loadAllDepartments(this.props.profile.accessToken)));
        promises.push(dispatch(loadBillingIndexes(this.props.profile.accessToken)));
        return Promise.all(promises).then(() => {
            this.setState({dataLoaded: true});
        });
    }

    handleSubmit = formData => {
        const {
            dispatch,
            match: {
                params
            }
        } = this.props;
        const data = _.cloneDeep(formData);
        data.clientId = params.clientId;
        if (data && params.clientId) {
            const dispatchSave = dispatch(saveContractInfo(data, this.props.profile.accessToken));
            dispatchSave.then(saveResult => {
                dispatch(loadClient(params.clientId, this.props.profile.accessToken))
                    .then(clientLoadResult => {
                        this.setState({submitting: false});
                        if (saveResult && !saveResult.error) {
                            this.props.history.push('/client/' + params.clientId);
                        }
                    })
            });
            this.setState({submitting: true});
            return dispatchSave;
        }
    };

    handleCancel = (event) => {
        const { client } = this.props;
        const clientId = client && client.client && client.client.id;
        this.props.history.push(`/client/${clientId}`);
        event.preventDefault();
    };

    render() {
        const t = this.props.t;
        return (
            <StandardPage>
                <header>
                    <h1 style={commonStyles.pageTitle}>{t('Add new contract')}</h1>
                </header>
                {
                    (() => {
                        if (!this.state.dataLoaded) {
                            return <LoadingIndicator visible={true}/>
                        }
                        return (
                            <ContractInfoForm
                                client={ this.props.client.client }
                                departments={this.props.department.departments}
                                countries={this.props.countries}
                                billingIndexes={this.props.billingIndexes}
                                onSubmit={ this.handleSubmit }
                                submitting={ false }
                                cancelAction={ this.handleCancel }
                            />
                        );
                    })()
                }
            </StandardPage>
        );
    }
}



function mapStateToProps(state) {
    return {
        client: state.client,
        profile: state.userProfile,
        countries: state.countries.countries,
        department: state.department,
        billingIndexes: state.billingIndex.all
    };
}

const connector = connect(mapStateToProps);

export default translations(connector(CreateContractInfoView));