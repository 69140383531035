import React from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';
import {localeDateString} from 'helpers/stringTemplates';
import _ from 'lodash';
import {
    measures,
    colors
} from 'styles/design.js';
import {
    DocumentListItem
} from 'components';

const agreementTableStyle = {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'transparent'
};

const statusColor = (status) => {
    return {
        draft: colors.lightBlue,
        rejected: colors.basicRed,
        approved: colors.blue,
        sent_for_approval: colors.blue,
    }[status] || colors.blue;
};

const AgreementStatus = (props) => {
    const {t, agreement, handleClick, current} = props;
    if (!agreement || !agreement.agreementStatus) {
        return (<div/>);
    }
    const isRevoked = Boolean(agreement.agreementStatus === 'draft' && agreement.revokedBy && agreement.revokedTime);
    const isSentForApproval = Boolean(agreement.agreementStatus === 'sent_for_approval');
    const isRejected = Boolean(agreement.agreementStatus === 'rejected');
    const isApproved = Boolean(agreement.approvalTime);
    const isSigned = Boolean(agreement.agreementStatus === 'signed' && agreement.signedTime);
    const isMain = Boolean(agreement.agreementType.match(/main/));
    const isAddon = agreement.mainAgreementId;
    const isFrameAgreement = Boolean(agreement.agreementType === 'frameagreement');
    const isExternalMain = Boolean(agreement.agreementType === 'external_main');
    const isModified = Boolean(!isExternalMain && agreement.created !== agreement.modified);
    const isLost = Boolean(agreement.agreementStatus === 'lost');

    const show = [
        {type: 'created', timeField: 'created', visible: isMain || isFrameAgreement},
        {type: 'modified', timeField: 'modified', visible: (isMain || isAddon || isFrameAgreement) && isModified},
        {type: 'approved', visible: (isMain || isAddon || isFrameAgreement) && isApproved},
        {type: 'rejected', timeField: 'rejectionTime', visible: isRejected},
        {type: 'signed', visible: (isMain || isAddon || isFrameAgreement) && isSigned},
        {type: 'sentForApproval', timeField: 'sentForApprovalTime', field: 'sent_for_approval', visible: isSentForApproval},
        {type: 'revoked', visible: isRevoked},
        {type: 'lost', visible: isLost}
    ];

    let agreementName = '';
    switch (agreement.agreementType) {
        case 'addon':
            agreementName = 'addon';
            break;
        case 'external_main':
            agreementName = 'agreement_outside_serviflex';
            break;
        case 'frameagreement':
            agreementName = 'frame_agreement';
            break;
        default:
            agreementName = 'main_agreement';
    }
    const visibles = _.filter(show, (row) => row.visible);
    return (
        <div
            id={props.id}
            style={[agreementTableStyle, current === agreement.id ? {borderColor: colors.gray} : {borderColor: 'transparent'}]}
            onClick={handleClick}>
            <DocumentListItem
                icon={isMain || isAddon || isFrameAgreement ? 'file-text-o' : 'file-o'}
                iconColor={statusColor(agreement.agreementStatus)}
                iconLabel={(isMain || isAddon || isFrameAgreement) && !isExternalMain ? t(agreement.agreementStatus) : ''}
                title={t(agreementName)}
            >
                <p style={isExternalMain ? {} : {display: 'none'}}>
                    {agreement.externalRef}
                </p>
                <p style={isMain || isAddon || isFrameAgreement ? {display: 'none'} : {}}>
                    {t('no_main_agreement_defined')}
                </p>
                {
                    visibles.map((row, idx) => {
                        return (<p key={'row_' + idx}>
                            <span>{t(row.field || row.type)}</span>
                            <br/>
                            <span>{localeDateString(agreement[row.timeField || row.type+'Time'], {showTimeZone: true})}</span>
                            { agreement[row.type+'By'] &&
                            <span>
                                <span> - </span>
                                <span>{ agreement[row.type+'By']}</span>
                            </span>
                            }
                        </p>)
                    })
                }
                {
                    (() => {
                        if (props.showComment && (agreement.agreementStatus === 'rejected' || agreement.agreementStatus === 'approved') && agreement.approverComment) {
                            return (
                                <div style={{
                                    marginTop: measures.double,
                                    paddingLeft: measures.half,
                                    borderLeft: '5px solid ' + colors.orange
                                }}>{agreement.approverComment}</div>
                            );
                        }
                    })()
                }
            </DocumentListItem>
        </div>
    );
};

export default translations(configuredRadium(AgreementStatus));
