import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium              from 'configuredRadium.js';
import {Link} from 'react-router-dom';

import {
    commonStyles,
    colors
}                                    from 'styles/design.js';

class CreateDisciplineButton extends Component {

    static propTypes = {
        clientId: PropTypes.number.isRequired,
        buildingId: PropTypes.number.isRequired,
        contractInfoId: PropTypes.number.isRequired
    };

    render() {
        const { t, clientId, buildingId, contractInfoId } = this.props;
        return (
            <Link id={this.props.id} to={`/client/${clientId}/contractInfo/${contractInfoId}/building/${buildingId}/disciplines/add`}>
                <span style = { [ commonStyles.buttonLink, buildingId === null ? {backgroundColor: colors.gray} : {}, this.props.style ? this.props.style : {} ] }>
                    { t('add_discipline') }
                </span>
            </Link>
        );
    }
}

export default translations(configuredRadium(CreateDisciplineButton));
