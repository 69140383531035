import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import configuredRadium             from 'configuredRadium.js';
import {connect}                    from 'react-redux';
import DatePicker                   from 'react-datepicker';
import moment                       from 'moment';
import {agreementReport}            from 'redux/modules/report.js';
import {loadCountries}              from 'redux/modules/country.js';
import {
    loadCountryDepartments,
    shouldLoadCountryDepartments
}                                   from 'redux/modules/department.js';

import {
    commonStyles
}                                   from 'styles/design.js';

import _ from 'lodash';

import './datepicker.css';

import {
    ReportList
} from 'components';

const styles = {
    listContainer: {
        display: 'block',
        overflow: 'hidden',
        marginTop: '1em'
    }
};

class ReportForAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('year'),
            endDate: moment()
        };
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }
    updateAgreements = () => {
        let data = {};
        data.dateFrom = this.state.startDate.format('YYYY-MM-DD');
        data.dateTo = this.state.endDate.format('YYYY-MM-DD');
        if (this.state.selectedCountry) {
            data.countries = this.state.selectedCountry;
        }
        if (this.state.selectedCountry && this.state.departments) {
            data.departments = [parseInt(this.state.departments, 10)];
        }

        return this.props.dispatch(
            agreementReport(data, this.props.profile.accessToken)
        );
    };
    changeAreaValue = (event) => {
        const value = event.target.value;
        if (value) {
            if (this.state.selectedCountry !== value) {
                if(shouldLoadCountryDepartments(this.props.department, value)) {
                    this.props.dispatch(loadCountryDepartments(value, this.props.profile.accessToken));
                }
                this.setState({departments: null, selectedCountry: value}, () => this.updateAgreements());
            }
        } else {
            this.setState({selectedCountry: null}, () => this.updateAgreements());
        }
    };
    changeDepartmentValue = (event) => {
        const value = event.target.value;
        if (value) {
            this.setState({departments: value}, () => this.updateAgreements());
        } else {
            this.setState({departments: null}, () => this.updateAgreements());
        }
    };
    handleChangeStart = (date) => {
        if (date) {
            this.setState({startDate: date}, () => this.updateAgreements());
        }
    };
    handleChangeEnd = (date) => {
        if (date) {
            this.setState({endDate: date}, () => this.updateAgreements());
        }
    };
    componentDidMount = () => {
        this.props.dispatch(loadCountries(this.props.profile.accessToken)).then(() => {
            if (!this.props.user.isAdmin || this.props.user.isApprover) {
                const firstCountry = _.first(this.props.countries.countries).id;
                this.setState({
                    selectedCountry: firstCountry
                });
                if(shouldLoadCountryDepartments(this.props.department, firstCountry)) {
                    this.props.dispatch(loadCountryDepartments(firstCountry, this.props.profile.accessToken));
                }
                this.updateAgreements();
            }
        });
    };
    renderCountrySelect = (t) => {
        return (
            <div style={{ marginBottom: '2em' }}>
                <label htmlFor="area" style={{ marginBottom: '0.5em', display: 'block' }}>{ t('select_sales_area')}</label>
                <div>
                    <select
                        style={{ ...commonStyles.select, cursor: 'pointer' }}
                        id="area"
                        onChange={ (event) => { this.changeAreaValue(event) } } value={ this.state.selectedCountry }
                    >
                        <option key="country_not_selected" value="">{ t('select') }</option>
                        {
                            (() => {
                                if(!this.props.user.isAdmin) {
                                    return this.props.user.salesAreas.map((country, idx) => {
                                        return <option key={ 'country_' + idx } value={ country }>{ t(country) }</option>;
                                    });
                                } else if (this.props.countries.countries && Object.keys(this.props.countries.countries).length > 0) {
                                    return this.props.countries.countries.map((country, idx) => {
                                        return <option key={ 'country_' + idx } value={ country.id }>{ t(country.id) }</option>;
                                    });
                                }
                            })()
                        }
                    </select>
                </div>
            </div>
        );
    };
    renderDepartmentSelect = (departments, t) => {
        return (
            <div style={{ marginBottom: '2em' }}>
                <label htmlFor="department" style={{ marginBottom: '0.5em', display: 'block' }}>{ t('select_department') }</label>
                <div>
                    <select
                        style={{ ...commonStyles.select, cursor: 'pointer' }}
                        id="department"
                        onChange={ (event) => { this.changeDepartmentValue(event) } }
                    >
                        <option key="department_not_selected" value="">{ t('select') }</option>
                        {
                            departments && departments.length > 0 &&
                            departments.map((dep) =>
                                <option value={ dep.id } key={ dep.name + '_' + dep.id }>{dep.name}</option>)
                        }
                    </select>
                </div>
            </div>
        );
    };
    renderTimeframeSelect = (t, locale) => {
        return (
            <div style={{ marginBottom: '2em' }}>
                <div style={{ marginBottom: '0.5em' }}>{ t('select_date_range') }</div>
                <div>
                    <span style={{ display: 'inline-block' }}>
                        <DatePicker
                            locale={locale}
                            dateFormat="L"
                            selected={this.state.startDate}
                            selectsStart
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeStart}
                        />
                    </span>
                    <span> - </span>
                    <span style={{ display: 'inline-block' }}>
                        <DatePicker
                            locale={locale}
                            dateFormat="L"
                            selected={this.state.endDate}
                            selectsEnd
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeEnd}
                        />
                    </span>
                </div>
            </div>
        );
    };
    render() {
        const t = this.props.t;
        const locale = navigator.language || 'en';
        return (
            <div>
                    {
                        (() => {
                                return (
                                    <div style={{ marginBottom: '4em' }}>
                                        {
                                            (() => {
                                                if (this.props.user.isAdmin || this.props.user.isApprover) {
                                                    return this.renderCountrySelect(t);
                                                }
                                                return (
                                                    <div style={{ margin: '2em 0' }}>
                                                        {
                                                            (() => {
                                                                return this.props.user.salesAreas.map((area) => {
                                                                    return <div>{ t(area) }</div>
                                                                });
                                                            })()
                                                        }
                                                    </div>
                                                );
                                            })()
                                        }
                                        {
                                            (() => {
                                                if (this.state.selectedCountry
                                                    && this.props.department.countryDepartments.length > 0
                                                    && (this.props.user.isAdmin || this.props.user.isApprover)) {
                                                    const departments = this.props.department.countryDepartments;
                                                    return this.renderDepartmentSelect(departments, t);
                                                }
                                            })()
                                        }
                                        { this.renderTimeframeSelect(t, locale) }
                                    </div>
                                );
                        })()
                    }

                    <div style={ styles.listContainer }>
                        {
                            (() => {
                                if(this.props.report.agreement || this.props.report.frameAgreement) {
                                    return <ReportList agreementData={ this.props.report.agreement } />;
                                }
                            })()
                        }
                    </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        department: state.department,
        countries: state.countries,
        report: state.report,
        profile: state.userProfile,
        user: state.userProfile.user
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(ReportForAdmin)));


