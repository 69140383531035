import React, {Component}    from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import {reduxForm}                    from 'redux-form';
import configuredRadium                 from 'configuredRadium.js';
import {
    SaveButton,
    CancelButton,
    SFInputField,
    SFSelectField,
    SFRadioField,
    SFCheckBox,
    ConfirmableButton,
    ScrollToTopOnMount
}                                       from 'components/index.js';
import {
    layout,
    measures,
    colors
}                                       from 'styles/design.js';

import _ from 'lodash';

const rowContainerStyle = {};
const rowTitleStyle = {
    paddingTop: '2em',
};
const rowStyle = {
    width: '100%',
    clear: 'both',
    paddingLeft: '2em',
    paddingTop: '0.5em',
};
const col25Style = {
    width: '50%',
    display: 'inline-block',
    '@media only screen and (max-width: 660px)': {
        width: '100%',
        display: 'block'
    }
};
const col70Style = {
    width: '70%',
    display: 'inline-block',
    '@media only screen and (max-width: 660px)': {
        width: '100%',
        display: 'block'
    }
};
const col30Style = {
    width: '30%',
    display: 'inline-block',
    '@media only screen and (max-width: 660px)': {
        width: '100%',
        display: 'block'
    }
};
const padRightStyle = {
    paddingRight: '0.5em',
    '@media only screen and (max-width: 660px)': {
        paddingRight: 0,
    }
};
const padLeftStyle = {
    paddingLeft: '0.5em',
    '@media only screen and (max-width: 660px)': {
        paddingLeft: 0,
    }
};

const colorBarStyle = {
    height: '4px',
    width: '100%',
    '@media only screen and (max-width: 660px)': {
        display: 'none',
    }
};

const validationErrorStyle = {
    color: colors.basicRed
};

const ahuImageOptionStyle = {
    overflow: 'auto',
    width: '45%',
    height: '200px',
    border: '1px solid',
    margin: measures.half,
    padding: measures.half,
    float: 'left'
};

const shouldNotBeEmpty = (key, data, props) => {
    if (props.form && props.form[key] && props.form[key].touched && !data[key]) {
        return 'Value cannot be empty';
    }
};

const validate = (data, props) => {
    const errors = {};
    /*
    let untouched = false;
    _.forEach(props.fields, (field) => {
        if(!props.form[field] || !props.form[field].touched) {
            untouched = true;
        }
    });
    if(untouched) {
        errors.common = 'Provide all values';
    }*/
    props.fields.map((prop) => {
        if(prop !== 'id' && prop !== 'roomTempSensorCritical' && prop !== 'electricitySensorMain') {
            errors[prop] = shouldNotBeEmpty(prop, data, props);
            if (!data[prop]) {
                errors.common = 'All values are not set';
            }
        }
    });
    return errors;
};

const reduxFormObj = {
    form: 'kitPackForm',
    fields: [
        'id',
        'contractYears',
        'airHandlingSystem',
        'airHandlingSystemType',
        'electricitySensorLocation',
        'electricitySensorLocationArea',
        'electricitySensorMain',
        'presenceDetectionSensorLocation',
        'roomTempSensorLocation',
        'roomTempSensorCritical',
        'ahuSpeedType',
        //'roomTempMin0',
        'roomTempMin1',
        'roomTempMax0',
        //'roomTempMax1',
    ],
    validate
};
class KitPackForm extends Component {

    static propTypes = {
        kitpack: PropTypes.object.isRequired,
        systems: PropTypes.array,
        systemTypes: PropTypes.array,
        handleSubmit: PropTypes.func.isRequired,
        handleCancel: PropTypes.func,
        submitting: PropTypes.bool
    }
    componentWillMount() {
        this.props.initializeForm(this.props.kitpack);
    }
    componentWillReceiveProps(nextProps) {
        if(_.isEmpty(this.props.kitpack) && !_.isEmpty(nextProps.kitpack)) {
            this.props.destroyForm();
            this.props.initializeForm(nextProps.kitpack);
        }
    }

    componentWillUnmount() {
        this.props.destroyForm();
    }

    toggleShowAHUs = () => {
        this.setState({showAllAHUs: !this.state.showAllAHUs});
    };
    toggleAhuImage = (val) => {
        if(this.state.showAllAHUs) {
            this.props.fields.airHandlingSystemType.onChange(val);
            this.toggleShowAHUs();
        }
    };
    deleteKitpack = () => {
        const id = this.props.kitpack.id;
        this.props.deleteKitpack(id);
    };
    doSubmit = (event) => {
        if(!this.props.disabled) {
            this.props.handleSubmit();
        }
        event.preventDefault();
    };

    render() {
        const {
            contractYears,
            airHandlingSystem,
            airHandlingSystemType,
            electricitySensorLocation,
            electricitySensorLocationArea,
            electricitySensorMain,
            presenceDetectionSensorLocation,
            roomTempSensorLocation,
            ahuSpeedType,
            //roomTempMin0,
            roomTempMin1,
            roomTempMax0
            //roomTempMax1
        } = this.props.fields;
        const {systemTypes, systems, handleCancel, submitting, t} = this.props;

        const speedOptions = [{
            value: '1',
            label: '1_speed_option'
        }, {
            value: '2',
            label: '2_speed_option'
        }, {
            value: 'variable',
            label: 'variable_speed_option'
        }];
        const disabled = this.props.disabled;
        return (
            <div>
                <ScrollToTopOnMount />
                <div style={disabled ? layout.block : layout.none}>
                    <div className="highlight">{t('form_disabled_ahu_systems_deleted')}</div>
                </div>
                <form onSubmit={ this.doSubmit }>
                    <div style={ rowContainerStyle }>
                        <h2 style={ rowTitleStyle }>{ t('contract_years') }</h2>
                        <div style={ rowStyle }>
                            <SFSelectField
                                disabled={disabled}
                                formProperty={ contractYears }
                                fieldName={ t('contract_years') }
                            >
                                <option value="">-- Select --</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                            </SFSelectField>
                        </div>
                    </div>
                    <div style={ rowContainerStyle }>
                        <h2 style={ rowTitleStyle }>{ t('air_handling_unit') }</h2>
                        <div style={ rowStyle }>
                            <div>
                                {
                                    (() => {
                                        if(this.props.disabled && airHandlingSystem.value) {
                                            return (<div>{airHandlingSystem.value} ({t('deleted')})</div>);
                                        }
                                        return (
                                            <SFSelectField
                                                selectStyle={{ width: '100% '}}
                                                formProperty={ airHandlingSystem }
                                                fieldName={ t('system') }
                                                disabled={disabled}
                                            >
                                                <option value="">-- Select --</option>
                                                { (() => {
                                                    return systems.map((val) => {
                                                        return (<option key={ 'system-' + val.systemID }
                                                                        value={ val.systemID }>{ val.systemID }</option>);
                                                    });
                                                })()}
                                            </SFSelectField>
                                        );
                                    })()
                                }

                            </div>
                        </div>

                        <div style={ rowContainerStyle }>
                            <h2 style={ rowTitleStyle }>{ t('air_handling_unit_type') }</h2>
                            <div style={{width: '100%', marginTop: measures.double, marginBottom: measures.standard, marginLeft: measures.standard}}>
                                {
                                    (() => {
                                        if(airHandlingSystemType.error && airHandlingSystemType.error !== '') {
                                            return (<div style = {{color: 'red', marginTop: measures.standard, backgroundColor: colors.lightGray, border: '1px solid', padding: measures.standard}}>{airHandlingSystemType.error}</div>);
                                        }
                                    })()
                                }
                                <p style = {{marginTop: measures.standard, fontSize: '1.2em'}}>
                                    <a style = {{cursor: 'pointer'}} onClick = {this.toggleShowAHUs}>
                                        {!airHandlingSystemType.value ? t('no_ahu_selected') + ' - ' : ''}
                                        {
                                            (() => {
                                                if(this.state.showAllAHUs) {
                                                    return (
                                                        <span>
                                                            {t('hide_ahu_options')} &nbsp;
                                                            <i className = {'fa fa-compress'} />
                                                        </span>
                                                    );
                                                }
                                                return (
                                                    <span>
                                                        {t('show_ahu_options')} &nbsp;
                                                        <i className = {'fa fa-expand'} />
                                                    </span>
                                                );
                                            })()
                                        }
                                    </a>
                                </p>
                                <div style = {{overflow: 'auto'}}>
                                {
                                    (() => {
                                        return systemTypes.map((ahu, index) => {
                                            return (
                                                <div key={'ahuindex_' + index}
                                                        style={[
                                                            ahuImageOptionStyle,
                                                            airHandlingSystemType.value === ahu.name || this.state.showAllAHUs ? layout.inline : layout.none
                                                        ]}
                                                >
                                                        <SFRadioField
                                                            disabled={disabled}
                                                            name={'airHandlingSystemType'} label={ahu.name}
                                                            value={ahu.name}
                                                            formProperty={airHandlingSystemType}
                                                            onUpdate = {this.toggleShowAHUs}
                                                        />
                                                        <img
                                                            alt="ahu"
                                                            onClick = {() => { if(!disabled) this.toggleAhuImage(ahu.name); }}
                                                            src={require('images/AHU/' + ahu.name.replace('. ', '-') + '.png')}
                                                            width="100%"
                                                        />
                                                </div>
                                            );
                                        });
                                    })()
                                }
                                </div>
                            </div>
                            <div style={{ margin: '2em 0' }}>
                                <h4>{t('air_handling_unit_speed')}:</h4>
                                <div style={ validationErrorStyle }>{ahuSpeedType.error}</div>
                                {
                                    (() => {
                                        return speedOptions.map((opt, idx) => {
                                            return (
                                                <div style={[layout.inlineBlock, {marginRight: measures.standard}]}>
                                                    <SFRadioField
                                                        disabled={disabled}
                                                        key={ 'speed_option_' + idx }
                                                        name="speedOptions"
                                                        label={t(opt.label)}
                                                        value={opt.value}
                                                        formProperty={ahuSpeedType}
                                                    />
                                                </div>
                                            );
                                        });
                                    })()
                                }
                            </div>
                        </div>
                    </div>
                    <div style={ rowContainerStyle }>
                        <h2 style={ rowTitleStyle }>{ t('electricity') }</h2>
                        <div style = { [rowStyle] }>
                            <div style = {[layout.floatLeft, {lineHeight: '0.5em'}]}>
                                <SFCheckBox
                                    disabled={disabled}
                                    formProperty = { electricitySensorMain }
                                />
                            </div>
                            <div style = {[{marginTop: measures.standard}]}>
                                {t('main_sensor')}
                            </div>
                        </div>
                        <div style={ rowStyle }>
                            <div style={ [col70Style, padRightStyle] }>
                                <SFInputField
                                    disabled={disabled}
                                    formProperty={ electricitySensorLocation }
                                    fieldName={ t('meter_location') }
                                />
                            </div>
                            <div style={ [col30Style, padLeftStyle] }>
                                <SFInputField
                                    disabled={disabled}
                                    formProperty={ electricitySensorLocationArea }
                                    fieldName={ t('meter_area') + ' (m²)' }
                                />
                            </div>
                        </div>
                    </div>
                    <div style={ rowContainerStyle }>
                        <h2 style={ rowTitleStyle }>{ t('presence_detection') }</h2>
                        <div style={ rowStyle }>
                            <SFInputField
                                disabled={disabled}
                                formProperty={ presenceDetectionSensorLocation }
                                fieldName={ t('sensor_location') }
                            />
                        </div>
                    </div>
                    <div style={ rowContainerStyle }>
                        <h2 style={ rowTitleStyle }>{ t('vip_temperature') }</h2>
                        <div style={ rowStyle }>
                            <SFInputField
                                disabled={disabled}
                                formProperty={ roomTempSensorLocation }
                                fieldName={ t('sensor_location') }
                            />
                        </div>
                        {/*
                        <div style = { [rowStyle] }>
                            <div style = {[layout.floatLeft, {lineHeight: '0.5em'}]}>
                                <SFCheckBox formProperty = { roomTempSensorCritical } style = {{width: '20px'}} />
                            </div>

                            <div style = {[{marginTop: measures.standard}]}>
                                {t('is_critical')}
                            </div>
                        </div>
                         */}
                    </div>
                    <div style={ rowContainerStyle }>
                        <h2 style={ rowTitleStyle }>{ t('temperature_values') }</h2>
                        <div style={ rowStyle }>
                            <div style={ colorBarStyle }>
                                <div style={{ display: 'inline-block', height: '4px', width:'25%', backgroundColor:'red' }} />
                                {
                                    //<div style={{ display: 'inline-block', height: '4px', width:'15%', backgroundColor:'orange' }} />
                                }
                                <div style={{ display: 'inline-block', height: '4px', width:'50%', backgroundColor:'green' }} />
                                {
                                    // <div style={{ display: 'inline-block', height: '4px', width:'15%', backgroundColor:'orange' }} />
                                }
                                <div style={{ display: 'inline-block', height: '4px', width:'25%', backgroundColor:'red' }} />
                            </div>
                            {/*
                            <div style={ [col25Style, padRightStyle] }>
                                <SFInputField formProperty={ roomTempMin0 } fieldName={ t('min-min') }/>
                            </div>*/}
                            <div style={ [col25Style, padLeftStyle, padRightStyle] }>
                                <SFInputField
                                    disabled={disabled}
                                    formProperty={ roomTempMin1 }
                                    fieldName={ t('min') }
                                />
                            </div>
                            <div style={ [col25Style, padLeftStyle, padRightStyle] }>
                                <SFInputField
                                    disabled={disabled}
                                    formProperty={ roomTempMax0 }
                                    fieldName={ t('max') }
                                />
                            </div>
                            {/*
                            <div style={ [col25Style, padLeftStyle] }>
                                <SFInputField formProperty={ roomTempMax1 } fieldName={ t('max-max') }/>
                            </div>*/}
                        </div>
                    </div>

                    <div style={ [ layout.floatRight, layout.inlineBlock, { padding: '2em 0 4em 0' } ] }>
                        <div style={{ display: 'inline-block' }}>
                            <CancelButton
                                cancelAction={ handleCancel }
                                disabled={ submitting }
                            />
                        </div>
                            <SaveButton
                                style={disabled ? layout.none : layout.block}
                                buttonType={'submit'}
                                disabled={ submitting }
                                submitting={ submitting }
                            />
                    </div>
                </form>

                <div style={{float: 'left', marginTop: measures.double, display: this.props.kitpack && !disabled ? 'block' : 'none', maxWidth: '70%' }}>
                    <ConfirmableButton
                        buttonIcon = { 'fa fa-trash' }
                        confirmText = { this.props.t('confirm_delete_kitpack') }
                        buttonText = { ' ' + this.props.t('delete') }
                        style = {{
                            backgroundColor: colors.brightRed,
                            color: colors.white
                        }}
                        action = { this.deleteKitpack }
                    />
                </div>
                <div style={{clear: 'both'}} />
            </div>
        );
    }
}

export default translations(reduxForm(reduxFormObj)(configuredRadium(KitPackForm)));
