import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import { connect }                  from 'react-redux';
import { loadConfig, saveConfig, disableDisciplineTypeForCountry, enableDisciplineTypeForCountry }   from 'redux/modules/disciplineConfig.js';
import _ from 'lodash';
import { loadCountry } from 'redux/modules/country';
import {
    StandardPage,
    AdminSubNavigation,
    BreadcrumbBar,
    LoadingIndicator,
    Header,
    TranslationForm,
    DisciplineTypeConfigForm,
    DisciplineTypeInformation,
    Button,
    FileDetails,
} from 'components';
import {
    loadFiles,
    uploadFile,
    deleteFile
}                               from 'redux/modules/files.js';
import { download } from 'redux/modules/download.js';

import {
    saveTranslation
} from 'redux/modules/translations';

class DisciplineTypeConfigurationView extends Component {
    state = {};

    componentWillMount() {
        const { dispatch, match: {params}, profile: { accessToken }} = this.props;
        const { countryId, disciplineCategory, disciplineType } = params;
        const promises = [];
        promises.push(dispatch(loadConfig(countryId, disciplineCategory, disciplineType, accessToken )));
        promises.push(dispatch(loadFiles('agreementfiles', 'disciplineterms', accessToken)));
        promises.push(this.props.loadCountry(countryId, this.props.profile.accessToken))
        return Promise.all(promises);
    }

    componentWillReceiveProps(/*nextProps*/) {
        if (this.state.isSaving) {
            const countryId = this.props.match.params.countryId;
            this.props.loadCountry(countryId, this.props.profile.accessToken);
            this.props.dispatch(loadConfig(countryId, this.props.match.params.disciplineCategory, this.props.match.params.disciplineType, this.props.profile.accessToken));
            this.setState({isSaving: false});
            this.setState({isLoading: true});
        } else if (this.state.isLoading) {
            this.setState({isLoading: false, editDiscipline: false});
        }
    }
    editDiscipline(value) {
        this.setState({editDiscipline: value});
    }

    handleCancel() {
        this.editDiscipline( false );
    }

    handleConfigSave = (disciplineConfig) => {
        const dispatchSave = this.props.dispatch(saveConfig(disciplineConfig, this.props.profile.accessToken ));
        this.setState({isSaving: true});
        return dispatchSave;
    };
    saveTranslation = (key, value, language) => {
        const data = {
            key,
            value,
            language,
            type: 1,
            country: this.props.match.params.countryId
        };
        this.props.saveTranslation(data, this.props.profile.accessToken);
    }
    reloadConfig = () => {
        this.props.dispatch(loadConfig(
            this.props.match.params.countryId,
            this.props.match.params.disciplineCategory,
            this.props.match.params.disciplineType,
            this.props.profile.accessToken
        ));
    }
    toggleEnabled = () => {
        const api = this.props.disciplineConfig.global === true ? this.props.enableDisciplineTypeForCountry : this.props.disableDisciplineTypeForCountry;
        api(this.props.disciplineConfig.id, this.props.match.params.countryId, this.props.profile.accessToken)
            .then( res => {
                if (res.result) {
                    this.reloadConfig();
                }
            });
    }
    checkExisting(files, selectedCountry) {
        const { disciplineCategory, disciplineType } = this.props.match.params;
        const found = {};
        _.forEach(files, function check(value) {
            if (value.name === `terms_and_conditions_discipline_${disciplineCategory}_${disciplineType}_en_${selectedCountry.id}.pdf`) {
                found.en = value;
            }
            if (value.name === `terms_and_conditions_discipline_${disciplineCategory}_${disciplineType}_${selectedCountry.defaultLanguage}_${selectedCountry.id}.pdf`) {
                found.local = value;
            }
        });
        return found;
    }
    deleteFile = (event, filename) => {
        this.setState({ deletingFile: false });
        this.props.deleteFile(
            'agreementfiles',
            'disciplineterms',
            filename,
            this.props.profile.accessToken
        ).then( res => {
            if (!res.error) {
                this.props.dispatch(loadFiles('agreementfiles', 'disciplineterms', this.props.profile.accessToken));
                this.setState({ deletingFile: false, isLoadingFiles: true });
            } else {
                this.setState({ deletingFile: false, error: res.error.error });
            }
        })
        this.setState({ deletingFile: filename });
    }

    downloadFile = (event, name) => {
        event.preventDefault();
        this.props.downloadFile('terms', name, 'terms', this.props.profile.accessToken);
    };
    uploadFile = (file, filename) => {
        this.setState({ isUploading: true });
        this.props.uploadFile(
            'agreementfiles',
            'disciplineterms',
            file,
            filename,
            this.props.profile.accessToken
        ).then( res => {
            if (!res.error) {
                this.props.dispatch(loadFiles('agreementfiles', 'disciplineterms', this.props.profile.accessToken));
                this.setState({ isUploading: false, isLoadingFiles: true });
            } else {
                this.setState({ isUploading: false, error: res.error.error });
            }
        })
    };

    render() {
        const t = this.props.t;
        const { selectedCountry, loading } = this.props.countries;
        const loadingConfig = this.props.loadingConfig;
        const { disciplineCategory, disciplineType } = this.props.match.params
        const disciplinePrefix = `discipline_${disciplineCategory}_${disciplineType}`;
        const serviceLevels = ['bronze', 'silver', 'gold'];

        const disciplineConfig = this.props.disciplineConfig;

        const localizables = [disciplinePrefix].concat(serviceLevels.map( val => `${disciplinePrefix}_${val}`));
        const headers = ['General Service Level', 'Bronze', 'Silver', 'Gold'];

        const byType = _.groupBy(disciplineConfig.translations, 'key');
        const fixed = {};
        for (let key in byType) {
            const byLanguage = _.groupBy(byType[key], 'language');
            fixed[key] = {};
            for(let lang in byLanguage) {
                fixed[key][lang] = byLanguage[lang][0];
            }
        }
        const mappedList = fixed;
        const filesFound = this.checkExisting(this.props.files.files, selectedCountry);

        return (
            <StandardPage withBreadcrumb withSideNavigation>
                <BreadcrumbBar
                    page="admin"
                    path={this.props.location.pathname}
                />
                <AdminSubNavigation params={this.props.match.params} activeNavi="disciplineConfig" />
                <Header title="Disciplines" />
                {
                    // Only display the "Disable/Enable for country" buttons when the country is using global disciplines.
                    // Disabling a discipline would delete the local instance of it from the database and that is everything you have if the country is not using global disciplines.
                    !loadingConfig && selectedCountry.showGlobalDisciplines && <div style={{ marginBottom: '1em'}}>
                        <Button onClick={this.toggleEnabled}>
                            { disciplineConfig.global === true ? t('Enable for country') : t('Disable for country') }
                        </Button>
                    </div>
                }
                { this.props.disciplineConfigState.toggling && <LoadingIndicator big={ true } visible={ true } />}
                {
                    this.props.disciplineConfigState.toggleError && <div style={{ margin: '1em', fontSize: '0.9em' }}>Changing state failed: {this.props.disciplineConfigState.toggleError.error.message }</div>
                }


                { !loadingConfig && <div>
                    { this.state.editDiscipline && this.props.disciplineConfig ?
                        (
                            <DisciplineTypeConfigForm
                                params = {this.props.match.params}
                                onSubmit= { this.handleConfigSave }
                                disciplineConfig={ this.props.disciplineConfig }
                                cancelAction={ () => { this.editDiscipline(false); } }
                            />
                        ) :
                        <DisciplineTypeInformation
                            disciplineConfig={ this.props.disciplineConfig }
                            languages={ this.props.languages }
                            editClicked={ () => { this.editDiscipline(true); }}
                        />
                    }
                    </div>
                }

                {
                    (loadingConfig || loading) && <LoadingIndicator big={true} visible={true} />
                }
                {
                    !loadingConfig && !loading && selectedCountry && selectedCountry.id &&
                    <div style={{ marginTop: '2em' }}>
                        <TranslationForm
                            list={mappedList}
                            localizables={localizables}
                            headers={headers}
                            country={ selectedCountry }
                            type = {1}
                            richText = { true }
                            externalId = {{ disciplineTypeId: disciplineConfig.id }}
                            afterSaveTrigger = { this.reloadConfig }
                        />
                    </div>
                }
                {
                    <div style={{ marginTop: '2em' }}>
                        <FileDetails
                            language={'en'}
                            filename={`terms_and_conditions_discipline_${disciplineCategory}_${disciplineType}_en_${selectedCountry.id}.pdf`}
                            uploadFile={this.uploadFile}
                            downloadFile={this.downloadFile}
                            deleteFile={this.deleteFile}
                            exist={!_.isEmpty(filesFound.en)}
                            loading={this.props.files.loading || this.props.files.uploading || this.props.download.loading}
                        />
                    </div>
                }
                {selectedCountry.defaultLanguage !== 'en' && (
                    <FileDetails
                        language={selectedCountry.defaultLanguage}
                        filename={'terms_and_conditions_' + selectedCountry.defaultLanguage + '_' + selectedCountry.id + '.pdf'}
                        uploadFile={this.uploadFile}
                        downloadFile={this.downloadFile}
                        deleteFile={this.deleteFile}
                        exist={!_.isEmpty(filesFound.local)}
                        loading={this.props.files.loading || this.props.files.uploading || this.props.download.loading}
                    />
                )}
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client,
        params : state.router.params,
        disciplineConfig: state.disciplineConfig.config,
        disciplineConfigReload: state.disciplineConfig.reload,
        loadingConfig: state.disciplineConfig.loading,
        disciplineConfigError: state.disciplineConfig.error,
        disciplineConfigState: state.disciplineConfig,
        profile: state.userProfile,
        translation: state.translations,
        countries: state.countries,
        files: state.files,
        download: state.download,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveTranslation: (data, token) => dispatch(saveTranslation(data, token)),
        loadCountry: (id, token) => dispatch(loadCountry(id, token)),
        disableDisciplineTypeForCountry: (id, country, token) => dispatch(disableDisciplineTypeForCountry(id, country, token)),
        enableDisciplineTypeForCountry: (id, country, token) => dispatch(enableDisciplineTypeForCountry(id, country, token)),
        downloadFile: (downloadType, name, type, accessToken) => {
            return dispatch(download({downloadType, name, type}, accessToken));
        },
        uploadFile: (share, dir, file, filename, accessToken) => {
            return dispatch(uploadFile(share, dir, file, filename, accessToken))
        },
        deleteFile: (share, dir, filename, accessToken) => {
            return dispatch(deleteFile(share, dir, filename, accessToken))
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(DisciplineTypeConfigurationView));