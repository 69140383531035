import React, {Component}           from 'react';
import translations                 from 'decorators/translations.js';
import PropTypes                    from 'prop-types';
import {
    commonStyles,
    measures,
layout }                            from 'styles/design.js';
import {
    SFInputField,
    CostNumber,
    SFMultiplierSelect,
    SFTextareaField
}                                   from 'components';

const fieldStyle = {
    flex: 1,
    marginRight: measures.half
};

const placeHolderFieldStyle = {
    ...layout.inlineBlock,
    width: '21em',
    marginRight: measures.standard
};
class MaterialEdit extends Component {

    static propTypes = {
        form: PropTypes.object.isRequired,
        calculation: PropTypes.array,
        removeMaterialAction: PropTypes.func.isRequired,
    };

    static defaultProps = {
        materials: [],
        calculation: [],
    };

    render() {
        const { t, form: {materials}, calculation} = this.props;
        return (
                <div className="clearfix">
                    <div>
                        { (() => {
                            if (materials) {
                                return materials.map((material, index) => {
                                    const calc = calculation && calculation[index] ? calculation[index] : {cost:0};
                                    return (
                                        <div key = { index } style = {{ marginBottom: measures.double }}>
                                            <div>
                                                <div>
                                                    <button
                                                        type = "button"
                                                        style = {{ ...commonStyles.smallDeleteButton, ...layout.floatRight, width: '5em' }}
                                                        onClick = { () => this.props.removeMaterialAction(index) }>{ t('delete') }</button>
                                                    <h3 style = {{ marginBottom: measures.standard, minHeight: measures.double }} >{ material.description ? material.description.value : '' }</h3>
                                                </div>
                                                <div>
                                                <SFInputField
                                                    defaultInput={true}
                                                    fieldName= { t('description') }
                                                    { ...material.description }
                                                />
                                                </div>
                                                <div style = {{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', width: '100%' }}>
                                                    <div style={ fieldStyle }>
                                                        <SFInputField
                                                            {...material.materialQuantity}
                                                            defaultInput={true}
                                                            type="number"
                                                            fieldName= { t('material_quantity') }
                                                        />
                                                    </div>
                                                    <div style={ fieldStyle }>
                                                        <SFInputField
                                                            {...material.materialCost}
                                                            defaultInput={true}
                                                            type="number"
                                                            fieldName= { t('material_cost') }
                                                            placeholder = "0,00"
                                                        />
                                                    </div>
                                                    <div style={ fieldStyle }>
                                                        <SFInputField
                                                            {...material.hours}
                                                            defaultInput={true}
                                                            type="number"
                                                            fieldName= { t('hours') }
                                                        />
                                                    </div>
                                                    <div style={ fieldStyle }>
                                                        <SFInputField
                                                            {...material.visits}
                                                            defaultInput={true}
                                                            type="number"
                                                            fieldName= { t('visits') }
                                                        />
                                                    </div>
                                                    <div style={{ ...fieldStyle, width: '10em', marginRight: 0 }}>
                                                        <SFMultiplierSelect multiplier = { material.multiplier } boxWidth="10em" />
                                                    </div>
                                                </div>
                                                <div style = { placeHolderFieldStyle }>
                                                </div>
                                            </div>
                                            <div>
                                                <SFTextareaField
                                                    defaultInput={true}
                                                    fieldName={ t('additional_details')}
                                                    {...material.additionalDetails} />
                                            </div>
                                            <div style = { layout.floatRight }>
                                                <div style = { layout.floatRight }>
                                                    {
                                                        (() => {
                                                            if(this.props.calculating) {
                                                                return (<span>{t('calculating')}</span>);
                                                            }
                                                            return (
                                                                <CostNumber>{ calc.cost }</CostNumber>
                                                            );
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                });
                            }
                        })()}
                    </div>
                </div>
    );}
}
export default translations(MaterialEdit);
