import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {decimalRound} from 'helpers/stringTemplates.js';
import configuredRadium                 from 'configuredRadium.js';
import { deepCopy } from 'helpers/objectHelper.js';

import {
    layout,
    measures,
    commonStyles
}                         from 'styles/design.js';

import {
    SaveButton,
    CancelButton
}                       from 'components';

const tableTd = {
    padding: measures.half
};

const tableTdHeader = {
    padding: measures.half
};
class FrameAgreementPricingForm extends Component {

    componentWillMount() {
        this.setState({disciplineForm: this.props.discipline});
    }
    calculateDiscountedPrice(price, discount, currency) {
        const disc = discount ? discount : 0;
        const rounded = decimalRound(price * ((100 - disc) / 100), currency);
        return rounded;
    }
    calculateNewDiscount = (event) => {
        const newDiscount = event.target.value;
        const index = event.target.id;
        const discipline = deepCopy(this.state.disciplineForm);
        const pricing = discipline.pricing[index];
        pricing.discount = event.target.value;
        const invalidDiscounts = this.state.invalidDiscounts || {};
        if(newDiscount > this.props.countryConfig.frameAgreementConfig.maxDiscount) {
            invalidDiscounts[index] = true;
        } else {
            invalidDiscounts[index] = false;
            pricing.clientPrice = this.calculateDiscountedPrice(pricing.basicCharge, pricing.discount, this.props.countryConfig.currency);
            pricing.clientOvertime50 = this.calculateDiscountedPrice(pricing.overtime50, pricing.discount, this.props.countryConfig.currency);
            pricing.clientOvertime100 = this.calculateDiscountedPrice(pricing.overtime100, pricing.discount, this.props.countryConfig.currency);
        }
        this.setState({disciplineForm: discipline, invalidDiscounts});
    };
    submitForm() {
        const self = this;
        return () => {
            const discipline = self.state.disciplineForm;
            self.props.onSubmit(discipline);
        };
    }
    render() {
        const {t, countryConfig} = this.props;
        const submitting = false;
        const discipline = this.state.disciplineForm;
        return (
            <div style = {{overflow: 'auto'}}>
                <table cellSpacing = "0" cellPadding = "0" style = {{fontSize: '0.9em'}}>
                    <thead>
                    <tr>
                        <td style = {tableTdHeader}>
                            {t('name')}
                        </td>
                        <td style = {tableTdHeader}>
                            {t('name')} ({'en'})
                        </td>
                        <td style = {tableTdHeader}>
                            {t('basic_charge')} ({countryConfig.currency})
                        </td>
                        {/* SST-330, Moving this info to a translation. Only commenting out since we're not removing the logic either */}
                        {/*
                        <td style = {tableTdHeader}>
                            {t('overtime_50')} ({countryConfig.currency})
                        </td>
                        <td style = {tableTdHeader}>
                            {t('overtime_100')} ({countryConfig.currency})
                        </td>
                        */}
                        <td style = {tableTdHeader}>
                            {t('discount')} (%)
                        </td>
                        <td style = {tableTdHeader}>
                            { t('client_price')} ({countryConfig.currency})
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        (() => {
                            return discipline.pricing.map((priceRow, index) => {
                                return (
                                    <tr key = {'discipline_pricing_' + discipline.disciplineCategory + '_' + index }>
                                        <td style = {tableTd}>
                                            {priceRow.name}
                                        </td>
                                        <td style = {tableTd}>
                                            {priceRow.basicCharge}
                                        </td>
                                        {/* SST-330, Moving this info to a translation. Only commenting out since we're not removing the logic either */}
                                        {/*
                                        <td style = {tableTd}>
                                            {priceRow.overtime50}
                                        </td>
                                        <td style = {tableTd}>
                                            {priceRow.overtime100}
                                        </td>
                                        */}
                                        <td style = {tableTd}>
                                            <input style = { commonStyles.input } type = "text" id = { index } onChange = { this.calculateNewDiscount } value = { priceRow.discount } />
                                            <br />
                                            {
                                                this.state.invalidDiscounts && this.state.invalidDiscounts[index] ? 'Invalid discount, maximum is: ' + countryConfig.frameAgreementConfig.maxDiscount : ''
                                            }
                                        </td>
                                        <td>
                                            {priceRow.clientPrice}
                                        </td>
                                    </tr>
                                );
                            });

                        })()
                    }
                    </tbody>
                </table>
                <div style = {{overflow: 'auto', marginBottom: measures.standard}}>
                    <div style={ [ layout.floatRight, layout.inlineBlock, { clear: 'both'} ] }>
                        <span style={ [{ marginRight : measures.standard }] }><CancelButton
                            cancelAction={ this.props.cancelAction }/></span>
                    <SaveButton onClick={ this.submitForm() } disabled={ submitting } submitting={ submitting }/>
                    </div>
                </div>
            </div>
        );
    }
}

export default translations(configuredRadium(FrameAgreementPricingForm));
