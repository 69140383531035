const LOAD_ALL          = 'ServiFlex/Country/LOAD_ALL',
    LOAD_ALL_SUCCESS    = 'ServiFlex/Country/LOAD_ALL_SUCCESS',
    LOAD_ALL_FAIL       = 'ServiFlex/Country/LOAD_ALL_FAIL',
    LOAD                = 'ServiFlex/Country/LOAD',
    LOAD_SUCCESS        = 'ServiFlex/Country/LOAD_SUCCESS',
    LOAD_FAIL           = 'ServiFlex/Country/LOAD_FAIL',
    SAVE                = 'ServiFlex/Country/SAVE',
    SAVE_SUCCESS        = 'ServiFlex/Country/SAVE_SUCCESS',
    SAVE_FAIL           = 'ServiFlex/Country/SAVE_FAIL',
    SAVE_DEPARTMENT                = 'ServiFlex/Country/SAVE_DEPARTMENT',
    SAVE_DEPARTMENT_SUCCESS        = 'ServiFlex/Country/SAVE_DEPARTMENT_SUCCESS',
    SAVE_DEPARTMENT_FAIL           = 'ServiFlex/Country/SAVE_DEPARTMENT_FAIL';

const initialState = { loading: false, countries: [], selectedCountry: {}, loaded: false, reload: true, saving: false, loadedAll: false };

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD_ALL:
            return {
                ...state,
                loading: true
            };
        case LOAD_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                loadedAll: true,
                loaded: true,
                reload: false,
                countries: action.result
            };
        case LOAD_ALL_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD:
            return {
                ...state,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                selectedCountry: action.result
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SAVE:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                error: action.error
            };
        case SAVE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };
        case SAVE_DEPARTMENT:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case SAVE_DEPARTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                error: action.error
            };
        case SAVE_DEPARTMENT_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };
        default:
            return state;
    }
}

export function loadCountries(accessToken) {
    return {
        types: [LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_FAIL],
        promise: (client) => client.get(
            '/countries/all', {accessToken}
        )
    };
}

export function loadCountry(countryId, accessToken) {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client) => client.get(
            '/countries/' + countryId, { accessToken }
        )
    };
}

export function saveCountry(countryConfig, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.patch(
            '/countryconfigs/' + countryConfig.id, { data: countryConfig, accessToken }
        )
    };
}

export function isCountryLoaded(state, salesArea) {
    if(state.countries && state.countries.loaded && state.countries.selectedCountry && state.countries.selectedCountry.id === salesArea) {
        return true;
    }
    return false;
}

export function saveCountryKitpackPricing(countryConfig, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.patch(
            '/countryconfigs/' + countryConfig.id + '/saveKitpackPricing', { data: countryConfig, accessToken }
        )
    };
}