const LOAD_FILES = 'ServiFlex/Files/LOAD_FILES',
    LOAD_FILES_SUCCESS = 'ServiFlex/Files/LOAD_FILES_SUCCESS',
    LOAD_FILES_FAIL = 'ServiFlex/Files/LOAD_FILES_FAIL',
    UPLOAD_FILE = 'ServiFlex/Files/UPLOAD_FILE',
    UPLOAD_FILE_SUCCESS = 'ServiFlex/Files/UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_FAIL = 'ServiFlex/Files/UPLOAD_FILE_FAIL',
    REQUEST_FILE = 'ServiFlex/Files/REQUEST_FILE',
    REQUEST_FILE_SUCCESS = 'ServiFlex/Files/REQUEST_FILE_SUCCESS',
    REQUEST_FILE_FAIL = 'ServiFlex/Files/REQUEST_FILE_FAIL',
    DELETE_FILE = 'ServiFlex/Files/DELETE_FILE',
    DELETE_FILE_SUCCESS = 'ServiFlex/Files/DELETE_FILE_SUCCESS',
    DELETE_FILE_FAIL = 'ServiFlex/Files/DELETE_FILE_FAIL';


const initialState = {
    loading: false,
    files: [],
    result: [],
    loaded: false,
    uploading: false,
    uploaded: false,
    linkLoading: false,
    linkLoaded: false,
    fileLink: [],
    deleting: false,
    deleted: false
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD_FILES:
            return {
                ...state,
                loading: true
            };
        case LOAD_FILES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                files: action.result
            };
        case LOAD_FILES_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case UPLOAD_FILE:
            return {
                ...state,
                uploading: true,
                error: null
            };
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                uploading: false,
                uploaded: true,
                result: action.result
            };
        case UPLOAD_FILE_FAIL:
            return {
                ...state,
                uploading: false,
                uploaded: false,
                error: action.error
            };
        case REQUEST_FILE:
            return {
                ...state,
                linkLoading: true,
                linkLoaded: false
            };
        case REQUEST_FILE_SUCCESS:
            return {
                ...state,
                linkLoading: false,
                linkLoaded: true,
                fileLink: action.result
            };
        case REQUEST_FILE_FAIL:
            return {
                ...state,
                linkLoading: false,
                linkLoaded: false,
                error: action.error
            };
        case DELETE_FILE:
            return {
                ...state,
                deleting: true
            };
        case DELETE_FILE_FAIL: {
            return {
                ...state,
                deleting: false,
                deleted: false,
                error: action.error
            };
        }
        case DELETE_FILE_SUCCESS: {
            return {
                ...state,
                deleting: false,
                deleted: true,
                result: action.result
            };
        }
        default:
            return state;
    }
}

export function loadFiles(share, dir, accessToken) {
    return {
        types: [LOAD_FILES, LOAD_FILES_SUCCESS, LOAD_FILES_FAIL],
        promise: (client) => client
            .post('/files', {data: {share: share, dir: dir}, accessToken})
    };
}

export function uploadFile(share, dir, file, filename, accessToken) {
    return {
        types: [UPLOAD_FILE, UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAIL],
        promise: (client) => client
            .post('/fileupload', {
                data: {
                    directory: dir,
                    share: share,
                    file: {file: file, filename: filename, param: 'attachment'}
                }, accessToken
            })
    };
}
export function requestDownloadLink(share, dir, filename, accessToken) {
    return {
        types: [REQUEST_FILE, REQUEST_FILE_SUCCESS, REQUEST_FILE_FAIL],
        promise: (client) => client
            .post('/files/requestsas', {data: {share: share, dir: dir, filename: filename}, accessToken})
    };
}
export function deleteFile(share, dir, filename, accessToken) {
    return {
        types: [DELETE_FILE, DELETE_FILE_SUCCESS, DELETE_FILE_FAIL],
        promise: (client) => client
            .post('/files/delete', {data: {share: share, dir: dir, filename: filename}, accessToken})
    };
}
