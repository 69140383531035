import {createReducerFromMapping} from 'redux/utils/index.js';

const COPY_AGREEMENT = 'ServiFlex/COPY_AGREEMENT',
    COPY_AGREEMENT_FAIL = 'ServiFlex/AgreementContent/COPY_AGREEMENT_FAIL',
    COPY_AGREEMENT_SUCCESS = 'ServiFlex/AgreementContent/COPY_AGREEMENT_SUCCESS',
    COPY_FRAME_AGREEMENT = 'ServiFlex/COPY_FRAME_AGREEMENT',
    COPY_FRAME_AGREEMENT_FAIL = 'ServiFlex/AgreementContent/COPY_FRAME_AGREEMENT_FAIL',
    COPY_FRAME_AGREEMENT_SUCCESS = 'ServiFlex/AgreementContent/COPY_FRAME_AGREEMENT_SUCCESS';

export function copyAgreement(agreementId, targetContractInfoId, accessToken) {
    return {
        types: [COPY_AGREEMENT, COPY_AGREEMENT_SUCCESS, COPY_AGREEMENT_FAIL],
        promise: client => client.post('/Agreements/copyAgreement', { data: { agreementId: parseInt(agreementId, 10), targetContractInfoId: parseInt(targetContractInfoId, 10) }, accessToken })
    };
}

export function copyFrameAgreement(agreementId, targetContractInfoId, accessToken) {
    return {
        types: [COPY_FRAME_AGREEMENT, COPY_FRAME_AGREEMENT_SUCCESS, COPY_FRAME_AGREEMENT_FAIL],
        promise: client => client.post('/FrameAgreements/copyAgreement', { data: { agreementId: parseInt(agreementId, 10), targetContractInfoId: parseInt(targetContractInfoId, 10) }, accessToken })
    };
}

const initialState = {
    copying: false,
    copied: false,
    result: null,
    list: [],
    error: null
};

export default createReducerFromMapping({
    [COPY_AGREEMENT]: (state, action) => ({
        ...state,
        copying: true,
        error: null
    }),
    [COPY_AGREEMENT_SUCCESS]: (state, action) => ({
        ...state,
        copying: false,
        copied: true,
        result: action.result
    }),
    [COPY_AGREEMENT_FAIL]: (state, action) => ({
        ...state,
        copying: false,
        copied: false,
        error: action.error
    }),
    [COPY_FRAME_AGREEMENT]: (state, action) => ({
        ...state,
        copying: true,
        error: null
    }),
    [COPY_FRAME_AGREEMENT_SUCCESS]: (state, action) => ({
        ...state,
        copying: false,
        copied: true,
        result: action.result
    }),
    [COPY_FRAME_AGREEMENT_FAIL]: (state, action) => ({
        ...state,
        copying: false,
        copied: false,
        error: action.error
    })
}, initialState);
