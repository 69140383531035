import React from 'react';
import { NavLink } from 'react-router-dom';

const TabLabel = ({children, className, selected, onClick, navLink, to, exact, href, ...other}) => {
    if (navLink) {
        return (
            <NavLink
                to={to}
                exact={exact}
                className={'tabs-label'}
                {...other}
            >
                {children}
            </NavLink>
        );
    }
    return (
        <a 
            href={href ? href : ''} 
            className={'tabs-label' + (selected ? ' active' : '')}
            onClick={onClick}
            {...other}
        >
            {children}
        </a>
    );
}

export default TabLabel;