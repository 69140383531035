import React                    from 'react';
import { CSSTransitionGroup }   from 'react-transition-group';
import translations             from 'decorators/translations.js';

import './Loader.css';

const Loader = ({ isVisible, t }) => {
    const renderLoader = () => {
        return (
            <div id="overlay-loader" key="mainloader" className="loader">
                <div className="loader-overlay">
                    <div className="loader-wrapper">
                        <div className="loader-icon">
                            <div><i className="fa fa-spinner fa-spin" /></div>
                        </div>
                        <div className="loader-title">
                            { t('loading') }
                            <span className="loader-dot">.</span>
                            <span className="loader-dot">.</span>
                            <span className="loader-dot">.</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <CSSTransitionGroup
            component="div"
            transitionName="loader"
            transitionEnterTimeout={200}
            transitionLeaveTimeout={200}
        >
            { isVisible && renderLoader() }
        </CSSTransitionGroup>
    );
}

export default translations(Loader);
