import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';
import _ from 'lodash';

import {
    StandardPage,
    AdminSubNavigation,
    BreadcrumbBar,
    CountryTerminationTermsForm,
    CountryTerminationTermsInformation,
    CountrySLADescriptionsForm,
    CountrySLADescriptionsInformation,
    TranslationForm,
} from 'components';

import {
    measures,
    commonStyles
} from 'styles/design.js';

import {loadCountry, saveCountry} from 'redux/modules/country.js';
import {loadLanguages, loadWithType, saveTranslation} from 'redux/modules/translations.js';

const divBlock = {
    ...commonStyles.bottomDivider,
    marginTop: measures.standard,
    marginBottom: measures.standard,
    paddingBottom: measures.triple
};

const TERMINATION_LOCALIZABLES = [
    'mainagreement_termination_terms_description'
];

const SLA_LOCALIZABLES = [
    'mainagreement_sla_terms_basic_description',
    'mainagreement_sla_terms_response_time_description',
    'mainagreement_sla_terms_yearly_meeting_description',
    'mainagreement_sla_terms_support_247_description',
];

class CountrySLAConfigurationView extends Component {
    state = {};

    componentWillMount() {
        const {dispatch, match: {params}} = this.props;
        const promises = [];
        if (!this.props.countries.loaded || this.props.countries.selectedCountry.id !== params.countryId) {
            promises.push(dispatch(loadCountry(params.countryId, this.props.profile.accessToken)));
        }
        if (!this.props.translations.loaded) {
            promises.push(dispatch(loadLanguages(this.props.profile.accessToken)));
        }
        return Promise.all(promises).then(() => {
            if(this.props.countries.selectedCountry) {
                this.props.dispatch(loadWithType(2, 'en,' + this.props.countries.selectedCountry.defaultLanguage, this.props.countries.selectedCountry.id, this.props.profile.accessToken));
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isSaving) {
            const countryId = this.props.match.params.countryId;
            this.props.dispatch(loadCountry(countryId, this.props.profile.accessToken));
            this.setState({isSaving: false});
            this.setState({isLoading: true});
        } else if (this.state.isLoading) {
            this.setState({
                isLoading: false,
                editMonitoring: false,
                editTerminationTerms: false,
                editSLADescriptions: false
            });
        }
        if(this.props.translations.saving && nextProps.translations.saved) {
            this.setState({savingTranslation: false, loadingTranslation: true});
            if(this.props.countries.selectedCountry) {
                this.props.dispatch(loadWithType(2, 'en,' + this.props.countries.selectedCountry.defaultLanguage, this.props.countries.selectedCountry.id, this.props.profile.accessToken));
            }
        }
    }

    handleConfigSave = (countryConfiguration) => {
        const dispatchSave = this.props.dispatch(saveCountry(countryConfiguration, this.props.profile.accessToken));
        this.setState({isSaving: true});
        return dispatchSave;
    };

    editMonitoring(value) {
        this.setState({editMonitoring: value});
    }

    editTerminationTerms(value) {
        this.setState({editTerminationTerms: value});
    }

    editSLADescriptions(value) {
        this.setState({editSLADescriptions: value});
    }

    handleCancel() {
        this.editMonitoring(false);
        this.editTerminationTerms(false);
        this.editSLADescriptions(false);
    }

    getTranslatable = value => ({
        value,
        disabled: false
    });

    addOldDescriptionToList = (translatable, list, key, selectedCountry, translations) => {
        const newList = Object.assign({}, list);
        newList[key] = translations.translatables[key] || {};
        if (!newList[key].en || (_.isEmpty(newList[key].en) && newList[key].en.disabled !== false)) {
            newList[key].en = translatable;
        }
        if (!newList[key][selectedCountry.defaultLanguage] || (_.isEmpty(newList[key][selectedCountry.defaultLanguage]) && newList[key][selectedCountry.defaultLanguage].disabled !== false)) {
            newList[key][selectedCountry.defaultLanguage] = translatable;
        }
        return newList;
    };

    render() {
        const {
            translations,
            saveTranslation,
            countries: {
                selectedCountry,
            },
            t
        } = this.props;

        let terminationList = {};
        let slaList = {};
        let smartViewList = {};
        let smartViewLocalizables = [];

        if (translations && translations.translatables && selectedCountry && selectedCountry.terminationTermsDescription) {
            smartViewLocalizables = selectedCountry.costCalculation.smartViewPricing
                ? _.map(selectedCountry.costCalculation.smartViewPricing, (_, index) => `mainagreement_smartview_pricing_${index}`)
                : [];
            TERMINATION_LOCALIZABLES.forEach(key => {
                if (key === 'mainagreement_termination_terms_description') {
                    const translatable = this.getTranslatable(selectedCountry.terminationTermsDescription);
                    terminationList = this.addOldDescriptionToList(translatable, terminationList, key, selectedCountry, translations);
                }
                else {
                    terminationList[key] = translations.translatables[key];
                };
            });
            SLA_LOCALIZABLES.forEach(key => {
                if (key === 'mainagreement_sla_terms_basic_description') {
                    const translatable = this.getTranslatable(selectedCountry.slaBasicDescription);
                    slaList = this.addOldDescriptionToList(translatable, slaList, key, selectedCountry, translations);
                }
                else if (key === 'mainagreement_sla_terms_response_time_description') {
                    const translatable = this.getTranslatable(selectedCountry.slaResponseTimeDescription);
                    slaList = this.addOldDescriptionToList(translatable, slaList, key, selectedCountry, translations);
                }
                else if (key === 'mainagreement_sla_terms_yearly_meeting_description') {
                    const translatable = this.getTranslatable(selectedCountry.slaYearlyMeetingDescription);
                    slaList = this.addOldDescriptionToList(translatable, slaList, key, selectedCountry, translations);
                }
                else if (key === 'mainagreement_sla_terms_support_247_description') {
                    const translatable = this.getTranslatable(selectedCountry.slaSupport247description);
                    slaList = this.addOldDescriptionToList(translatable, slaList, key, selectedCountry, translations);
                }
                else {
                    slaList[key] = translations.translatables[key];
                };
            });
            smartViewLocalizables.forEach(key => {
                smartViewList[key] = translations.translatables[key];
            })
        }

        return (
            <StandardPage
                withBreadcrumb
                withSideNavigation
                loaderVisible={this.props.countries.loading}
            >
                <BreadcrumbBar
                    page="admin"
                    path={this.props.location.pathname}
                />
                <AdminSubNavigation params={this.props.match.params} activeNavi="countrySLAConfig"/>
                <header>
                    <h1 style={commonStyles.pageTitle}>{this.props.t('country_sla_config')}</h1>
                </header>
                {/*
                <div style={divBlock}>
                    {(() => {
                        if (this.state.editTerminationTerms) {
                            return (<CountryTerminationTermsForm
                                onSubmit={this.handleConfigSave}
                                cancelAction={() => {
                                    this.editTerminationTerms(false);
                                }}
                                countryConfig={this.props.countries.selectedCountry}
                            />);
                        }
                        return (<CountryTerminationTermsInformation
                            countryConfig={this.props.countries.selectedCountry}
                            editClicked={() => {
                                this.editTerminationTerms(true);
                            }}
                        />);

                    })()}
                </div>
                <div style={commonStyles.dataParentDiv}>
                    <TranslationForm
                        localizables={TERMINATION_LOCALIZABLES}
                        country={selectedCountry}
                        type={2}
                        richText={true}
                        list={terminationList}
                    />
                </div>
                */}

                <div style={divBlock}>
                    {(() => {
                        if (this.state.editSLADescriptions) {
                            return (<CountrySLADescriptionsForm
                                onSubmit={this.handleConfigSave}
                                cancelAction={() => {
                                    this.editSLADescriptions(false);
                                }}
                                countryConfig={this.props.countries.selectedCountry}
                                languages={this.props.translations.languages}
                                saveTranslation={saveTranslation}
                            />);
                        }
                        return (<CountrySLADescriptionsInformation
                            countryConfig={this.props.countries.selectedCountry}
                            languages={this.props.translations.languages}
                            editClicked={() => {
                                this.editSLADescriptions(true);
                            }}
                        />);

                    })()}
                    <div style={commonStyles.dataParentDiv}>
                        <div style={commonStyles.dataContentHeaderDiv}>
                            {t('sla_descriptions')}:
                        </div>
                        <TranslationForm
                            localizables={SLA_LOCALIZABLES}
                            country={selectedCountry}
                            type={2}
                            richText={true}
                            list={slaList}
                            labels={[
                                t('sla_basic_description'),
                                t('sla_responsetime_description'),
                                t('sla_yearlymeeting_description'),
                                t('sla_support_247_description')
                            ]}
                        />
                    </div>
                    {selectedCountry && selectedCountry.costCalculation && selectedCountry.costCalculation.smartViewPricing && selectedCountry.costCalculation.smartViewPricing.length &&
                        <div style={commonStyles.dataParentDiv}>
                            <div style={commonStyles.dataContentHeaderDiv}>
                                {t('SmartView pricing descriptions')}:
                            </div>
                            <TranslationForm
                                localizables={smartViewLocalizables}
                                country={selectedCountry}
                                type={2}
                                richText={false}
                                list={smartViewList}
                                labels={_.map(selectedCountry.costCalculation.smartViewPricing, pricing => pricing.name)}
                            />
                        </div>
                    }
                </div>
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client,
        countries: state.countries,
        translations: state.translations,
        user: state.userProfile.user,
        profile: state.userProfile
    };
}

function mapDispatchToProps(dispatch) {
    return {
        saveTranslation: (data, token) => dispatch(saveTranslation(data, token)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(CountrySLAConfigurationView));
