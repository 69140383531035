import React, {Component }   from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/translations.js';
import configuredRadium                 from 'configuredRadium.js';
import { connect }                      from 'react-redux';
import { getLanguage } from 'helpers/languageHelper';

import {
    commonStyles,
    colors
} from 'styles/design.js';

import {
  LoadingIndicator
} from 'components';

class SAPPrintoutButton extends Component {

    static propTypes = {
        agreementId: PropTypes.number.isRequired,
        allowPrint: PropTypes.func,
        printAction: PropTypes.func
    };

    buttonClicked(event) {
        if (typeof(this.props.allowPrint) === 'function') {
            if (!this.props.allowPrint() ) {
                event.preventDefault();
                return false;
            }
        }
        return true;
    }

    render() {
        const lang = getLanguage(this.props.translations.language);
        return (
        <a
            onClick={ (event) => this.props.printAction(event, this.props.agreementId, lang) }
            style = { [ commonStyles.buttonLink, {backgroundColor: colors.lightBlue}] }
        >
            <LoadingIndicator visible = { this.props.submitting } />
            {this.props.t('print_sap_printout')}
        </a>
    );}
}


function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        cookies: state.cookies,
        translations: state.translations
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(SAPPrintoutButton)));


