import React from 'react';

import _ from 'lodash';
import {
    Button
} from 'components';
import {
    commonStyles
} from 'styles/design';

import Option from "./Option";
import CustomOption from "./CustomOption";

const MultiSelectionForm = ({options, type, t, country, toggle}) => {
    const config = country.frameAgreementConfig;
    return (
        <div
            style={{
                ...commonStyles.dataParentDiv,
                display: config[type] && config[type].length > 0 ? 'block' : 'none'
            }}
            id="FrameAgreementForm_serviceCar"
        >
            {
                config[type] && config[type].map((option, idx) => {
                    const selectedOption = _.find(options, fee => fee.name && fee.name.value === option.name);
                    const existingValue = selectedOption ? selectedOption.value.value : option.value;
                    return (
                        <Option
                            key={'service_car' + idx}
                            label={t(option.name) + ' ' + (existingValue || '') + ' ' + (option.valueType || '')}
                            toggle={(value) => toggle(value, option)}
                            id={'FrameAgreementForm_' + type + '_' + idx}
                            checked={!!selectedOption}
                        />
                    );
                })
            }
            {
                options.map((option, idx) => {
                    if (option.type.value === 'custom') {
                        return (
                            <CustomOption
                                key={'custom_' + type + '_' + idx}
                                label={option.name.value + ' ' + option.value.value + ' ' + (option.valueType.value || '')}
                                toggle={(event) => options.removeField(idx)}
                                id={'FrameAgreementForm_' + type + '_' + idx}
                                option={option}
                                checked={true}
                                t={t}
                            />
                        );
                    }
                })
            }

            <Button
                variant="add"
                onClick={() => options.addField({
                    name: '',
                    type: 'custom',
                    value: null,
                    valueType: '',
                    description: ''
                })}>
                <i className="fa fa-plus"/> {t('Add')}
            </Button>
        </div>
    );
}

export default MultiSelectionForm;