import React from 'react';

import {
    Button,
    RichTextView
} from 'components';

import './AgreementContents.css';

const ContentRow = ({content, section, t, editRow, overriden, header, isEditable}) => {
    return (<div className="agreement-contents-row">
        {isEditable && <div style={{float: 'right'}}>
            <Button variant="primary" onClick={editRow}>
                <i className="fa fa-pencil" />
            </Button>
        </div>}
        <div>
            <p className="agreement-contents-row-title">
                <i className="fa fa-2x fa-file-text-o" /> {section}: {header}
            </p>
            <div className="agreement-contents-row-content">
            {
                overriden ? 
                <RichTextView value={content} /> :
                <RichTextView>{content}</RichTextView>
            }
            </div>
        </div>
    </div>);
}

export default ContentRow;