import React, {Component}           from 'react';
import translations                 from 'decorators/translations.js';
import configuredRadium             from 'configuredRadium.js';
import {connect}                    from 'react-redux';
import DatePicker                   from 'react-datepicker';
import { localeDateString }         from 'helpers/stringTemplates';
import moment                       from 'moment';
import 'moment/min/locales.min';
import {agreementReport}            from 'redux/modules/report.js';
import {loadCountries}              from 'redux/modules/country.js';
import {
    loadCountryDepartments,
    shouldLoadCountryDepartments
}                                   from 'redux/modules/department.js';

import {
    commonStyles,
    colors
}                                   from 'styles/design.js';

import _ from 'lodash';

import './datepicker.css';

import {
    Button,
    Tabs,
    Tab
} from 'components';

import BySelection from './BySelection.js';
import ByCountry from './ByCountry.js';
import ByDepartment from './ByDepartment.js';
import ByPerson from './ByPerson.js';
import ByDiscipline from './ByDiscipline.js';
import ByClientNet from './ByClientNet';

const statusOrder = {
    'signed'            : 1,
    'approved'          : 2,
    'sent_for_approval' : 3,
    'lost'              : 4,
    'rejected'          : 5,
    'draft'             : 6
};

const isCompleted = (status) => {
    if (status === 'signed' ||
        status === 'approved') {
        return true;
    }
    return false;
};

const isLost = (status) => {
    if (status === 'lost') {
        return true;
    }
    return false;
}

class ReportForAdmin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: moment().startOf('year'),
            endDate: moment(),
            selectedCurrency: 'euro'
        };
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.getCurrency = this.getCurrency.bind(this);
    }
    getCurrency(country) {
        if (this.state.selectedCurrency === 'euro') {
            return 'EUR';
        } else {
            const countries = this.props.countries.countries;
            const countryIndex = _.findIndex(countries, { id: country });
            return countries[countryIndex].currency;
        }
    }
    handleCurrencyChange(event) {
        const selected = event.target.checked;
        this.setState({
            selectedCurrency: selected ? 'local' : 'euro'
        });
    }
    updateAgreements = () => {
        let data = {};
        data.dateFrom = this.state.startDate.format('YYYY-MM-DD');
        data.dateTo = this.state.endDate.format('YYYY-MM-DD');
        data.totals = true;
        if (this.state.selectedCountry && this.state.selectedCountry === 'all') {
            data.countries = this.props.countries.countries.map(country => {
                return country.id;
            });
        }
        else if (this.state.selectedCountry) {
            data.countries = this.state.selectedCountry;
        }
        if (this.state.selectedCountry && this.state.departments) {
            data.departments = [parseInt(this.state.departments, 10)];
        }

        return this.props.dispatch(
            agreementReport(data, this.props.profile.accessToken)
        );
    };
    selectAllAreas = (event) => {
        const selected = event.target.checked;
        this.setState({departments: null, selectedCountry: selected ? 'all' : null}, () => this.updateAgreements());
    }
    changeAreaValue = (event) => {
        const value = event.target.value;
        if (value) {
            if (this.state.selectedCountry !== value) {
                if(value === 'all') {
                    this.setState({departments: null, selectedCountry: value}, () => this.updateAgreements());
                } else {
                    if(shouldLoadCountryDepartments(this.props.department, value)) {
                        this.props.dispatch(loadCountryDepartments(value, this.props.profile.accessToken));
                    }
                    this.setState({departments: null, selectedCountry: value}, () => this.updateAgreements());
                }
            }
        } else {
            this.setState({selectedCountry: null}, () => this.updateAgreements());
        }
    };
    changeDepartmentValue = (event) => {
        const value = event.target.value;
        if (value) {
            this.setState({departments: value}, () => this.updateAgreements());
        } else {
            this.setState({departments: null}, () => this.updateAgreements());
        }
    };
    handleChangeStart = (date) => {
        if (date) {
            this.setState({startDate: date}, () => this.updateAgreements());
        }
    };
    handleChangeEnd = (date) => {
        if (date) {
            this.setState({endDate: date}, () => this.updateAgreements());
        }
    };
    selectCustomTimeframe = ({startDate, endDate, e}) => {
        e.preventDefault();
        this.setState({ startDate, endDate }, () => this.updateAgreements());
    };
    selectLastYear = (e) => {
        const lastYear = moment().subtract(1, 'year').year();
        const startDate = moment(lastYear + '-01-01', 'YYYY-MM-DD');
        const endDate = moment(lastYear + '-12-31', 'YYYY-MM-DD');
        this.selectCustomTimeframe({startDate, endDate, e});
    };
    selectLastMonth = (e) => {
        e.preventDefault();
        const startDate = moment().subtract(1,'months').startOf('month');
        const endDate = moment().subtract(1,'months').endOf('month');
        this.selectCustomTimeframe({startDate, endDate, e});
    };
    selectAllTime = (e) => {
        e.preventDefault();
        const startDate = moment('2015-01-01', 'YYYY-MM-DD');
        const endDate = moment();
        this.selectCustomTimeframe({startDate, endDate, e});
    };
    componentDidMount = () => {
        this.props.dispatch(loadCountries(this.props.profile.accessToken)).then(() => {
            if (!this.props.user.isAdmin || this.props.user.isApprover) {
                const firstCountry = _.first(this.props.countries.countries).id;
                this.setState({
                    selectedCountry: firstCountry
                });
                if(shouldLoadCountryDepartments(this.props.department, firstCountry)) {
                    this.props.dispatch(loadCountryDepartments(firstCountry, this.props.profile.accessToken));
                }
                this.updateAgreements();
            }
        });
    };
    sortKeysByStatusFunction = (a, b) => {
        if (statusOrder[a] === statusOrder[b]) {
            return 0;
        }
        return statusOrder[a] < statusOrder[b] ? -1 : 1;
    }
    sortByStatusFunction = (a, b) => {
        if (statusOrder[a.status] === statusOrder[b.status]) {
            return 0;
        }
        return statusOrder[a.status] < statusOrder[b.status] ? -1 : 1;
    };
    mapStatusColor = (status) => {
        let color;
        switch (status) {
            case 'approved':
            case 'signed':
                color = colors.blue;
                break;
            case 'sent_for_approval':
                color = colors.orange;
                break;
            case 'lost':
            case 'rejected':
                color = colors.basicRed;
                break;
            case 'draft':
                color = colors.lightBlue;
                break;
            default:
                color = colors.blue;
        }
        return color;
    };
    mapChartStatusColor = (status) => {
        let color;
        switch (status) {
            case 'approved':
                color = colors.basicGreen;
                break;
            case 'signed':
                color = colors.blue;
                break;
            case 'sent_for_approval':
                color = colors.lightGreen;
                break;
            case 'lost':
                color = colors.basicRed;
                break;
            case 'rejected':
                color = colors.orange;
                break;
            case 'draft':
                color = colors.lightBlue;
                break;
            default:
                color = colors.blue;
        }
        return color;
    };
    createListElement = (agreementStatuses, agreements, status, t) => {
        const size = agreementStatuses[status].length;
        let newData = {};

        newData.size = size;
        newData.icon = 'file-pdf-o';
        newData.status = status;
        newData.color = this.mapStatusColor(status);
        newData.title = t(status) + ' (' + size + ')';
        newData.data = agreementStatuses[status].map(agreementId => {
            const agreement = _.find(agreements, {id: agreementId});
            let title = agreement &&
                agreement.contractInfo &&
                agreement.contractInfo.client &&
                agreement.contractInfo.client.name;

            if (agreement.agreementType === 'addon') {
                title += ', ' + t('addon');
            } else if (agreement.agreementType === 'main') {
                title += ', ' + t('mainagreement');
            }

            return {
                title: title,
                subtitle: t(agreement.agreementStatus) + ' ' + localeDateString(agreement.modified),
                agreementType: agreement.agreementType,
                agreementStatus: agreement.agreementStatus,
                totals: agreement.totals,
                href: '/client/' + (agreement &&
                    agreement.contractInfo &&
                    agreement.contractInfo.client &&
                    agreement.contractInfo.client.id)
            }
        });

        return newData;
    }
    renderCountrySelect = (t) => {
        return (
            <div style={{ marginBottom: '2em' }}>
                <label htmlFor="area" style={{ marginBottom: '0.5em', display: 'block' }}>{ t('select_sales_area')}</label>
                { this.props.user.isAdmin &&
                    <div style={{margin: '0.5em 0'}}>
                        <input type="checkbox" onChange={ e => this.selectAllAreas(e) } /> { t('select_all') }
                    </div>
                }
                <div>
                    <select
                        disabled={ this.state.selectedCountry === 'all' }
                        style={{ ...commonStyles.select, cursor: 'pointer' }}
                        id="area"
                        onChange={ (event) => { this.changeAreaValue(event) } } value={ this.state.selectedCountry }
                    >
                        <option key="country_not_selected" value="">{ t('select') }</option>
                        { /* this.props.user.isAdmin && <option key="all" value="all">{t('all')}</option> */ }
                        {
                            (() => {
                                if(!this.props.user.isAdmin) {
                                    return this.props.user.salesAreas.map((country, idx) => {
                                        return <option key={ 'country_' + idx } value={ country }>{ t(country) }</option>;
                                    });
                                } else if (this.props.countries.countries && Object.keys(this.props.countries.countries).length > 0) {
                                    return this.props.countries.countries.map((country, idx) => {
                                        return <option key={ 'country_' + idx } value={ country.id }>{ t(country.id) }</option>;
                                    });
                                }
                            })()
                        }
                    </select>
                </div>
            </div>
        );
    };
    renderDepartmentSelect = (departments, t) => {
        if (this.state.selectedCountry !== 'all') {
            return (
                <div style={{ marginBottom: '2em' }}>
                    <label htmlFor="department" style={{ marginBottom: '0.5em', display: 'block' }}>{ t('select_department') }</label>
                    <div>
                        <select
                            style={{ ...commonStyles.select, cursor: 'pointer' }}
                            id="department"
                            onChange={ (event) => { this.changeDepartmentValue(event) } }
                        >
                            <option key="department_not_selected" value="">{ t('select') }</option>
                            {
                                departments && departments.length > 0 &&
                                departments.map((dep) =>
                                    <option value={ dep.id } key={ dep.name + '_' + dep.id }>{dep.name}</option>)
                            }
                        </select>
                    </div>
                </div>
            );
        }
        return null;
    };
    renderTimeframeSelect = (t, locale) => {
        return (
            <div style={{ marginBottom: '2em' }}>
                <div style={{ marginBottom: '0.5em' }}>{ t('select_date_range') }</div>
                <div>
                    <span style={{ display: 'inline-block' }}>
                        <DatePicker
                            locale={locale}
                            dateFormat="L"
                            selected={this.state.startDate}
                            selectsStart
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeStart}
                        />
                    </span>
                    <span> - </span>
                    <span style={{ display: 'inline-block' }}>
                        <DatePicker
                            locale={locale}
                            dateFormat="L"
                            selected={this.state.endDate}
                            selectsEnd
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onChange={this.handleChangeEnd}
                        />
                    </span>
                </div>
                <div style={{margin: '1em 0'}}>
                    <Button isButton onClick={ e => this.selectAllTime(e) }>{ t('All time') }</Button>
                    <span> </span>
                    <Button isButton onClick={ e => this.selectLastYear(e) }>{ t('Last calendar year') }</Button>
                    <span> </span>
                    <Button isButton onClick={ e => this.selectLastMonth(e) }>{ t('Last calendar month') }</Button>
                </div>
            </div>
        );
    };

    // Get grouped status counts from data
    getGroupedCountsByStatus(data, t) {
        let agreementCountsByStatus = [];
        const statusGroups = [
            'Completed',
            'Lost',
            'In Process',
            'Total'
        ];

        statusGroups.forEach((group, index) => {
            agreementCountsByStatus[index] = {
                label: t(group),
                value: 0
            };
        });

        if (typeof data === 'object') {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    const value = data[key].length;
                    if (isCompleted(key))  {
                        // Completed
                        agreementCountsByStatus[0].value += value;
                    } else if (isLost(key)) {
                        // Lost
                        agreementCountsByStatus[1].value += value;
                    } else {
                        // In process
                        agreementCountsByStatus[2].value += value;
                    }
                    // Total
                    agreementCountsByStatus[3].value += data[key].length;
                }
            }
        }
        return agreementCountsByStatus;
    };

    // Get status chart data from data
    getChartData(data, t) {
        let agreementsChartData = [];
        if (typeof data === 'object') {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    agreementsChartData.push({
                        value: data[key].length,
                        label: t(key),
                        status: key,
                        fillColor: this.mapStatusColor(key)
                    });
                }
            }
            agreementsChartData = _.values(agreementsChartData).sort(this.sortByStatusFunction);
        }
        return agreementsChartData;
    };

    // Get list list element data from data
    getListData(data, dataByStatus, t) {
        let agreements = {};
        if (data && dataByStatus) {
            for (let key in dataByStatus) {
                if (dataByStatus.hasOwnProperty(key)) {
                    agreements[key] = this.createListElement(dataByStatus, data, key, t);
                }
            }
        }
        return agreements;
    };

    // Get agreement income, costs and margin totals from data
    getTotals(data, t) {
        let agreementTotals = [];
        if (data) {
            const keys = ['costs', 'income'];
            keys.forEach(key => {
                agreementTotals.push({
                    value: data[key],
                    valueText: data[key] + ' EUR',
                    label: t(key),
                    status: key
                });
            });
        }
        return agreementTotals;
    };

    // Get margins by country from data
    getMarginsByCountry(data, t) {
        let agreementMarginsByCountry = [];
        if (typeof data === 'object' && Object.keys(data).length > 0)  {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    agreementMarginsByCountry.push({
                        value: data[key].margin,
                        valueText: data[key].margin + ' %',
                        label: t(key)
                    });
                }
            }
        }
        return agreementMarginsByCountry;
    }

    // Get margins by country from data
    getDataByCountry = (data, t) => {
        let agreementMarginsByCountry = [];
        if (typeof data === 'object' && Object.keys(data).length > 0)  {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    const selectedCurrency = this.state.selectedCurrency;
                    let income = 0;
                    let costs = 0;
                    if (selectedCurrency === 'euro' || this.getCurrency(key) === 'EUR') {
                        income = data[key].income ? data[key].income : 0;
                        costs = data[key].costs ? data[key].costs : 0;
                    } else {
                        income = data[key].incomeLocal ? data[key].incomeLocal : 0;
                        costs = data[key].costsLocal ? data[key].costsLocal : 0;
                    }
                    const margin = data[key].margin ? data[key].margin : 0;

                    agreementMarginsByCountry.push({
                        label: t(key),
                        values: [{
                            label: t('income'),
                            valueText: income + ' ' + this.getCurrency(key),
                            value: income
                        }, {
                            label: t('costs'),
                            valueText: costs + ' ' + this.getCurrency(key),
                            value: costs
                        }, {
                            label: t('margin'),
                            valueText: margin + '%',
                            value: margin
                        }]
                    });
                }
            }
        }
        return agreementMarginsByCountry;
    }

    // Get agreements by country and status from data
    getAgreementsByCountryAndStatus(data, grouped, t) {
        let agreementsByCountryAndStatuses = {};
        if (typeof data === 'object' && Object.keys(data).length > 0) {
            const agreementsByStatus = data;
            const countries = Object.keys(data);

            const modifyCountryData = (country, statusData, keys) => {
                let total = 0;
                let newObj = {};
                newObj.values = [];

                keys.forEach(status => {
                    let value = 0;
                    if (status in statusData && statusData[status].constructor === Array) {
                        value = statusData[status].length;
                    }
                    newObj[t(status)] = value;
                    newObj.values.push({
                        label: t(status),
                        value: value,
                        color: status === 'lost' ? colors.basicRed : null
                    });
                    total += value;
                });

                newObj.label = t(country) + ' (' + total + ')';

                return newObj;
            }

            let countryData = [];
            let statuses = [];

            if (grouped) {
                statuses = [
                    'In Process',
                    'Completed',
                    'Lost'
                ];

                // Normalize country data to have all possible statuses and return new object
                countryData = countries.map(country => {
                    const statusData = this.getGroupedCountsByStatus(agreementsByStatus[country], t);
                    let newObj = {};
                    newObj.values = [];

                    statusData.forEach(status => {
                        newObj[status.label] = status.value;
                        newObj.values.push({
                            label: t(status.label),
                            value: status.value
                        });
                    });

                    return {
                        label: t(country),
                        ...newObj
                    }
                });

            } else {
                // Get all statuses that are available in data
                for (let i = 0; i < countries.length; i++) {
                    const country = countries[i];
                    statuses = statuses.concat(Object.keys(agreementsByStatus[country]));
                }
                statuses = _.uniq(statuses).sort(this.sortKeysByStatusFunction).reverse();

                // Normalize country data to have all possible statuses and return new object
                countryData = countries.map(country => {
                    const statusData = agreementsByStatus[country];
                    return modifyCountryData(country, statusData, statuses);
                });
            }

            // Assign data
            agreementsByCountryAndStatuses.data = countryData;
            // Assign statuses
            agreementsByCountryAndStatuses.keys = statuses.map(status => {
                return t(status);
            });;
            // Assign colors
            agreementsByCountryAndStatuses.colors = statuses.map(this.mapChartStatusColor);
        }
        return agreementsByCountryAndStatuses;
    }

    getMarginsByDepartment(data, departments, t) {
        let newData = [];

        if (typeof data === 'object' && Object.keys(data).length > 0) {
            for (let country in data) {
                const countryData = data[country];

                for (let departmentId in countryData) {
                    const arrayIndex = _.findIndex(departments, { id: +departmentId });
                    if (arrayIndex > -1) {
                        newData.push({
                            label: departments[arrayIndex].name + ' ' + t(country),
                            valueText: countryData[departmentId].margin + '%',
                            value: countryData[departmentId].margin
                        });
                    }
                }
            }
        }

        return newData;
    }

    getDataByDepartment = (data, departments, t) => {
        let newData = {};
        newData.totals = [];
        newData.counts = [];

        if (typeof data === 'object' && Object.keys(data).length > 0) {
            for (let country in data) {
                const countryData = data[country];

                for (let departmentId in countryData) {
                    const arrayIndex = _.findIndex(departments, { id: +departmentId });
                    if (arrayIndex > -1) {
                        const label = departments[arrayIndex].name + ' ' + t(country);
                        let totals = [];

                        if (typeof countryData[departmentId].income !== 'undefined'
                            || typeof countryData[departmentId].costs !== 'undefined'
                            || typeof countryData[departmentId].margin !== 'undefined') {
                            const selectedCurrency = this.state.selectedCurrency;
                            let income = 0;
                            let costs = 0;
                            if (selectedCurrency === 'euro' || this.getCurrency(country) === 'EUR') {
                                income = countryData[departmentId].income ? countryData[departmentId].income : 0;
                                costs = countryData[departmentId].costs ? countryData[departmentId].costs : 0;
                            } else {
                                income = countryData[departmentId].incomeLocal ? countryData[departmentId].incomeLocal : 0;
                                costs = countryData[departmentId].costsLocal ? countryData[departmentId].costsLocal : 0;
                            }
                            const margin = countryData[departmentId].margin ? countryData[departmentId].margin : 0;
                            totals = [{
                                label: t('income'),
                                valueText: income + ' ' + this.getCurrency(country),
                                value: income
                            }, {
                                label: t('costs'),
                                valueText: costs + ' ' + this.getCurrency(country),
                                value: costs
                            }, {
                                label: t('margin'),
                                valueText: margin + '%',
                                value: margin
                            }];
                        } else if (typeof countryData[departmentId].averageDiscount !== 'undefined'
                            && countryData[departmentId].averageDiscount !== null) {
                            totals = [{
                                label: t('Average discount'),
                                valueText: countryData[departmentId].averageDiscount + '%',
                                value: countryData[departmentId].averageDiscount
                            }]
                        }
                        if (totals.length > 0) {
                            newData.totals.push({
                                label: label,
                                values: totals
                            });
                        }
                        const counts = [];
                        for (let status in countryData[departmentId].byStatus) {
                            counts.push({
                                label: t(status),
                                value: countryData[departmentId].byStatus[status].length
                            })
                        }
                        newData.counts.push({
                            label: label,
                            values: counts
                        })
                    }
                }
            }
        }
        return newData;
    }

    getDataByPerson(data, t) {
        let newData = [];

        if (typeof data === 'object' && Object.keys(data).length > 0) {
            for (let person in data) {
                if (person && person !== 'null') {
                    newData.push({
                        label: person,
                        value: data[person].length
                    });
                }
            }
        }
        return newData;
    }

    getListDataByPerson(data, agreements, t) {
        let newList = [];

        if (data && agreements) {
            for (let person in data) {
                if (data.hasOwnProperty(person) && person && person !== 'null') {
                    let newData = {};
                    newData.size = data[person].length;
                    newData.icon = 'file-pdf-o';
                    newData.color = colors.blue;
                    newData.title = person  + ' (' + data[person].length + ')';
                    newData.data = [];
                    newData.data = data[person].map(agreementId => {
                        const agreement = _.find(agreements, {id: agreementId});
                        let title = agreement && agreement.contractInfo &&
                            agreement.contractInfo.client && agreement.contractInfo.client.name;

                        if (agreement.agreementType === 'addon') {
                            title += ', ' + t('addon');
                        } else if (agreement.agreementType === 'main') {
                            title += ', ' + t('mainagreement');
                        }

                        return {
                            title: title,
                            subtitle: t(agreement.agreementStatus) + ' ' + localeDateString(agreement.modified),
                            agreementType: agreement.agreementType,
                            agreementStatus: agreement.agreementStatus,
                            href: '/client/' + (agreement &&
                                agreement.contractInfo &&
                                agreement.contractInfo.client &&
                                agreement.contractInfo.client.id)
                        }
                    });

                    newList.push(newData);
                }
            }
        }
        return newList;
    }

    getTotalsByDiscipline(data, t) {
        let newData = [];

        if (typeof data === 'object' && Object.keys(data).length > 0) {
            for (let discipline in data) {
                if (discipline && discipline !== 'null') {
                    newData.push({
                        label: t(discipline),
                        values: [
                            {
                                label: t('income'),
                                valueText: data[discipline].income + ' EUR',
                                value: data[discipline].income
                            },
                            {
                                label: t('costs'),
                                valueText: data[discipline].costs + ' EUR',
                                value: data[discipline].costs
                            },
                            {
                                label: t('margin'),
                                valueText: data[discipline].margin + '%',
                                value: data[discipline].margin
                            },
                            {
                                label: t('count'),
                                value: data[discipline].count
                            }
                        ]
                    });
                }
            }
        }
        return newData;
    }

    getListDataByClientNet(agreements = [], t) {
        const agreementsWithClientNet = _.filter(agreements, agreement => !!agreement.clientNet);
        const agreementsWithoutClientNet = _.filter(agreements, agreement => !agreement.clientNet);

        const getAgreementsByStatus = agreements => {
            return _.groupBy(agreements, 'agreementStatus');
        };

        const getListByStatus = agreements => {
            const agreementsByStatus = getAgreementsByStatus(agreements);
            const list = _.map(Object.keys(agreementsByStatus), key => getListObject(key, agreementsByStatus[key]));
            return list;
        };

        const getListObject = (status, listAgreements = []) => {
            const listSize = listAgreements.length;
            let newData = {};
            newData.size = listSize;
            newData.icon = 'file-pdf-o';
            newData.color = this.mapStatusColor(status);
            newData.title = t(status) + ' (' + listSize + ')';
            newData.data = [];
            newData.data = listAgreements.map(agreement => {
                let title = agreement && agreement.contractInfo &&
                    agreement.contractInfo.client && agreement.contractInfo.client.name;

                if (agreement.agreementType === 'addon') {
                    title += ', ' + t('addon');
                } else if (agreement.agreementType === 'main') {
                    title += ', ' + t('mainagreement');
                }

                return {
                    title: title,
                    subtitle: t(agreement.agreementStatus) + ' ' + localeDateString(agreement.modified),
                    agreementType: agreement.agreementType,
                    agreementStatus: agreement.agreementStatus,
                    href: '/client/' + (agreement &&
                        agreement.contractInfo &&
                        agreement.contractInfo.client &&
                        agreement.contractInfo.client.id)
                }
            });

            return newData;
        };

        return {
            withClientNetAgreements: agreementsWithClientNet,
            withClientNetListData: getListByStatus(agreementsWithClientNet),
            withoutClientNetAgreements: agreementsWithoutClientNet,
            withoutClientNetListData: getListByStatus(agreementsWithoutClientNet)
        };
    }

    render() {
        const t = this.props.t;
        const chartSize = { width: 928, height: 400 };

        const agreementData = this.props.report.agreement;
        const dataByCountry = agreementData.byCountry ? agreementData.byCountry : {};
        const dataByDepartment = agreementData.byDepartment ? agreementData.byDepartment : {};
        const dataByPeople = agreementData.byPeople ? agreementData.byPeople : {};
        const totals = agreementData.totals ? agreementData.totals : {};

        const agreementCount = agreementData.agreements ? agreementData.agreements.length : null;
        const frameAgreementCount = agreementData.frameAgreements ? agreementData.frameAgreements.length : null;

        const agreementsByStatuses = totals.agreementsByStatuses;
        const frameAgreementsByStatuses = totals.frameAgreementsByStatuses;

        // Get frame agreements listing data
        const frameAgreementListItems = this.getListData(agreementData.frameAgreements, frameAgreementsByStatuses, t);
        const frameAgreementsListData = _.values(frameAgreementListItems).sort(this.sortByStatusFunction);

        // Frame agreement chart data
        const frameAgreementsChartData = this.getChartData(frameAgreementsByStatuses, t);

        // Frame agreement counts by status
        //const frameAgreementCountsByStatus = this.getGroupedCountsByStatus(frameAgreementsByStatuses, t);


        // Sort data for agreement listings
        const agreementListItems = this.getListData(agreementData.agreements, agreementsByStatuses, t);
        const agreementsListData = _.values(agreementListItems).sort(this.sortByStatusFunction);

        // Agreement chart data
        const agreementsChartData = this.getChartData(agreementsByStatuses, t);

        // Agreement counts by status
        //const agreementCountsByStatus = this.getGroupedCountsByStatus(agreementsByStatuses, t);

        // Agreement totals
        const agreementTotals = this.getTotals(totals.agreementTotals, t);
        const agreementTotalsTableData = totals.agreementTotals ? Object.keys(totals.agreementTotals).map((key, index) => {
            return {
                label: t(key),
                value: totals.agreementTotals[key] +
                    (key !== 'margin'
                    ? ' EUR'
                    : '%')
            }
        }) : null;

        // Agreement data by country
        const agreementMarginsByCountry = this.getMarginsByCountry(dataByCountry.agreementTotalsByCountry, t);
        const agreementDataByCountry = this.getDataByCountry(dataByCountry.agreementTotalsByCountry, t);

        // Agreements by country and status
        const agreementsByCountryAndStatuses = this.getAgreementsByCountryAndStatus(dataByCountry.agreementsByCountryAndStatuses, false, t);
        const frameAgreementsByCountryAndStatuses = this.getAgreementsByCountryAndStatus(dataByCountry.frameAgreementsByCountryAndStatuses, false, t);

        // Country department data
        const agreementMarginsByDepartment = this.getMarginsByDepartment(dataByDepartment.agreementTotalsByCountryAndDepartment, agreementData.departments, t);
        const agreementDataByDepartment = this.getDataByDepartment(dataByDepartment.agreementTotalsByCountryAndDepartment, agreementData.departments, t);
        const agreementTotalsByDepartment = agreementDataByDepartment.totals;
        const agreementsByStatusAndDepartment = agreementDataByDepartment.counts;

        const frameAgreementDataByDepartment = this.getDataByDepartment(dataByDepartment.frameAgreementsByCountryAndDepartment, agreementData.departments, t);
        const frameAgreementTotalsByDepartment = frameAgreementDataByDepartment.totals;
        const frameAgreementsByStatusAndDepartment = frameAgreementDataByDepartment.counts;

        // People data
        const agreementDataByPerson = this.getDataByPerson(dataByPeople.agreementsByPeople, t);
        const agreementListDataByPerson = this.getListDataByPerson(dataByPeople.agreementsByPeople, agreementData.agreements, t);
        const frameAgreementDataByPerson = this.getDataByPerson(dataByPeople.frameAgreementsByPeople, t);
        const frameAgreementListDataByPerson = this.getListDataByPerson(dataByPeople.frameAgreementsByPeople, agreementData.frameAgreements, t);

        // Discipline data
        const totalsByDiscipline = this.getTotalsByDiscipline(totals.totalsByDisciplines, t);

        // Data by SmartView selection
        const agreementsByClientNet = this.getListDataByClientNet(agreementData.agreements, t);

        return (
            <div ref={ node => { this.node = node }}>
                <div style={{ marginBottom: '4em' }}>
                    {
                        (() => {
                            if (this.props.user.isAdmin || this.props.user.isApprover) {
                                return this.renderCountrySelect(t);
                            }
                            return (
                                <div style={{ margin: '2em 0' }}>
                                    {
                                        this.props.user && this.props.user.salesAreas.map((area) => {
                                            return <div>{t(area)}</div>;
                                        })
                                    }
                                </div>
                            );
                        })()
                    }
                    {
                        (() => {
                            if (this.state.selectedCountry
                                && this.props.department.countryDepartments.length > 0
                                && (this.props.user.isAdmin || this.props.user.isApprover)) {
                                const departments = this.props.department.countryDepartments;
                                return this.renderDepartmentSelect(departments, t);
                            }
                        })()
                    }
                    { this.renderTimeframeSelect(t, 'en') }
                    <p style={{margin: '1em 0'}}>
                        <input type="checkbox" onClick={e=>this.handleCurrencyChange(e)} /> { t('Show local currency if available') }
                    </p>
                </div>

                <Tabs>
                    <Tab title={ t('Selection') }>
                        <BySelection
                            chartSize={chartSize}
                            agreementCount={agreementCount}
                            agreementsListData={agreementsListData}
                            agreementsChartData={agreementsChartData}
                            agreementTotals={agreementTotals}
                            agreementTotalsTableData={agreementTotalsTableData}
                            frameAgreementCount={frameAgreementCount}
                            frameAgreementsChartData={frameAgreementsChartData}
                            frameAgreementsListData={frameAgreementsListData}
                            frameAgreements={frameAgreementListItems}
                        />
                    </Tab>
                    { this.state.selectedCountry === 'all' &&
                        <Tab title={ t('By country') }>
                            <ByCountry
                                chartSize={chartSize}
                                agreementMarginsByCountry={agreementMarginsByCountry}
                                agreementsByCountryAndStatuses={agreementsByCountryAndStatuses}
                                agreementDataByCountry={agreementDataByCountry}
                                frameAgreementsByCountryAndStatuses={frameAgreementsByCountryAndStatuses}
                            />
                        </Tab>
                    }
                    { !this.state.departments &&
                        <Tab title={ t('By department') }>
                            <ByDepartment
                                chartSize={chartSize}
                                agreementMarginsByDepartment={agreementMarginsByDepartment}
                                agreementTotalsByDepartment={agreementTotalsByDepartment}
                                agreementsByStatusAndDepartment={agreementsByStatusAndDepartment}
                                frameAgreementTotalsByDepartment={frameAgreementTotalsByDepartment}
                                frameAgreementsByStatusAndDepartment={frameAgreementsByStatusAndDepartment}
                            />
                        </Tab>
                    }
                    <Tab title={ t('By person') }>
                        <ByPerson
                            chartSize={chartSize}
                            agreementDataByPerson={agreementDataByPerson}
                            agreementListDataByPerson={agreementListDataByPerson}
                            frameAgreementDataByPerson={frameAgreementDataByPerson}
                            frameAgreementListDataByPerson={frameAgreementListDataByPerson}
                        />
                    </Tab>
                    <Tab title={ t('By discipline') }>
                        <ByDiscipline
                            chartSize={chartSize}
                            totalsByDiscipline={totalsByDiscipline}
                        />
                    </Tab>
                    <Tab title={ t('By SmartView selection') }>
                        <ByClientNet
                            agreementsByClientNet={agreementsByClientNet}
                        />
                    </Tab>
                </Tabs>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        department: state.department,
        countries: state.countries,
        report: state.report,
        profile: state.userProfile,
        user: state.userProfile.user
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(ReportForAdmin)));


