import React, {Component}               from 'react';
import translations                     from 'decorators/translations.js';
import { connect }                      from 'react-redux';
import _                                from 'lodash';
import {
    BuildingAndAgreementRow,
    RenderDiscipline,
    DisciplinesByAgreement,
    CreateDisciplineButton,
    LoadingIndicator
}                                       from 'components';
import {
    colors,
    commonStyles,
    measures,
    layout
}                                       from 'styles/design.js';
import {
    SFRadioField
}                                       from 'components/index.js';

class BuildingsAndAgreements extends Component {
    state = {};

    toggleType = (e) => {
        const value = e.target.value;
        this.setState({listType: value});
    };

    sortAgreements(agreements) {
        if(agreements.length <= 1) {
            return agreements;
        }
        const newList = [];
        const mains = _.filter(agreements, (agr) => {
            return agr.agreementType === 'main' || agr.agreementType === 'external_main';
        });
        mains.map((agreement) => {
            newList.push(agreement);
        });
        if(mains.length > 0) {
            mains.map((main) => main.addons = []);
            const addons = _.filter(agreements, {agreementType: 'addon'});
            addons.map((addon) => {
                const main = _.find(newList, {id: addon.mainAgreementId});
                if(main) {
                    main.addons.push(addon);
                }
            });
        }
        return newList;
    }

    render() {
        const { t, agreementState, buildingState, contractInfo } = this.props;
        const contractInfoId = contractInfo.id;

        const agreementsCInfo = _.find(agreementState.byContractInfo, { id: contractInfoId });
        const agreements = agreementsCInfo ? agreementsCInfo.agreements : [];
        const buildingsCInfo = _.find(buildingState.byContractInfo, { id: contractInfoId});
        const buildings = buildingsCInfo ? buildingsCInfo.buildings : [];

        const types = [{
            value: 'agreements',
            label: t('by_agreements')
        }, {
            value: 'buildings',
            label: t('by_buildings')
        }];

        const sortedAgreements = this.sortAgreements(agreements);

        return (
            <div>
                <h2 style = {{ ...commonStyles.pageSectionTitle, marginBottom: '0' }} >{ t('buildings_and_agreements_section_header') }</h2>
                <div style={{marginBottom: measures.double}}>
                { types.map((type, typeIdx) => {
                    return (
                        <div key={'type_idx_' + contractInfoId + '_' + typeIdx} style={{display: 'inline-block', marginRight: measures.standard}}>
                            <SFRadioField
                                id={`${contractInfoId}_by_${type.value}`}
                                formProperty={{
                                    value: this.state.listType || 'agreements',
                                    onChange: this.toggleType
                                }}
                                label={type.label}
                                value={type.value}
                                name={ `listType_${contractInfoId}` }
                            />
                        </div>
                    );
                }) }
                </div>
                {
                    (() => {
                        if(buildingState.loading || agreementState.loading) {
                            return <LoadingIndicator big={true} visible={true} />;
                        } else if(this.state.listType === 'buildings' && buildings) {
                            if(buildings.length === 0) {
                                return <div style={{ marginBottom: measures.standard, color: colors.gray }}>{t('no_buildings')}</div>;
                            }

                            return buildings.map((building, idx) => {
                                if (_.isEmpty(building.agreement)) {
                                    return null;
                                }
                                const link = `/client/${this.props.client.id}/contractInfo/${building.contractInfoId}/building/${building.id}`;
                                return (
                                    <div id={'BuildingAndAgreements_building_' + idx} key={'building_idx_' + idx} style={{marginBottom: measures.double}}>
                                        <BuildingAndAgreementRow
                                            id={'building_' + idx}
                                            title={building.name || ' -- '}
                                            link={link}
                                            rowType={'building'}
                                            createdBy={building.createdBy}
                                            buildingId={building.id}
                                            contractInfoId={contractInfo.id}
                                            downloadFile={this.props.downloadFile}
                                        />
                                        {
                                            (() => {
                                                return _.map(building.disciplines || [], (discipline, disciplineIdx) => {
                                                    const agreement = _.find(agreements, { id: discipline.agreementId });
                                                    return <RenderDiscipline
                                                        key={'discipline_idx_' + disciplineIdx}
                                                        contractInfoId={contractInfo.id}
                                                        clientId={ contractInfo.clientId }
                                                        agreement={agreement}
                                                        discipline={discipline}
                                                        type="building"
                                                    />;
                                                });
                                            })()
                                        }

                                        <div>
                                            <div>
                                                <CreateDisciplineButton
                                                    clientId={ contractInfo.clientId }
                                                    contractInfoId={ contractInfo.id }
                                                    buildingId={ building.id }
                                                    style={ layout.floatRight }
                                                />
                                            </div>
                                        </div>
                                        <div style={{clear: 'both'}} />
                                    </div>
                                );
                            });
                        } else if(!agreementState.loading && sortedAgreements.length === 0) {
                            return (
                                <div style={{marginBottom: measures.standard, color: colors.gray}}>
                                    {t('no_agreements')}.&nbsp;
                                </div>
                            );
                        }
                        return sortedAgreements.map((agreement, idx) => {
                            const link = `/client/${this.props.client.id}/contractInfo/${agreement.contractInfoId}/agreement/${agreement.id}/summary`;
                            return (
                                <div key={'agreement_idx_' + idx} style={{marginBottom: measures.double}}>
                                    <BuildingAndAgreementRow
                                        id={'BuildingsAndAgreements_Agreement_' + idx}
                                        title={ agreement.name && agreement.name !== '' ? agreement.name : t('main_agreement')}
                                        hoverTitle={ t('main_agreement') + ' ' + agreement.id}
                                        link={link}
                                        created={agreement.created}
                                        signedTime={agreement.signedTime}
                                        approvalTime={agreement.approvalTime}
                                        modifiedTime={agreement.modifiedTime}
                                        agreementStatus={agreement.agreementStatus}
                                        rowType={'agreement'}
                                        agreementId={agreement.id}
                                        createdBy={agreement.createdBy}
                                        downloadFile={this.props.downloadFile}
                                    />
                                    <DisciplinesByAgreement
                                        agreement={agreement}
                                        contractInfo={contractInfo}
                                        downloadFile={this.props.downloadFile}
                                    />
                                    <div style={{clear: 'both'}} />
                                </div>
                            );
                        });
                    })()
                }
                <div style={{clear: 'both'}} />
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        buildingState: state.building,
        agreementState: state.agreement,
        client: state.client.client,
    };
}
const connector = connect(mapStateToProps);
export default translations(connector(BuildingsAndAgreements));