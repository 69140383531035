import React, {Component}   from 'react';

import {
    colors,
    layout
} from 'styles/design.js';

const titleBarStyles = [
    layout.fixed,
    {
        height: '3.75em',
        backgroundColor: colors.blue,
        top: 0,
        left: 0,
        right: 0,
        zIndex: 4
    }
];

class TitleBar extends Component {
    render() {
        return (
            <header style={ titleBarStyles }></header>
        );
    }
}

export default TitleBar;
