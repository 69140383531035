import React            from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';
import {
    SFInputField
}                       from 'components';

const AddressForm = (props) => {

    return (
        <div>
            <SFInputField formProperty={props.address.street} fieldLabel={props.t('street')} />
            <SFInputField formProperty={props.address.zip} fieldLabel={props.t('zip')} />
            <SFInputField formProperty={props.address.city} fieldLabel={props.t('city')} />
        </div>
    );
};
export default translations(configuredRadium(AddressForm));
