import _ from 'lodash';
export const initializeNewKey = ({defaultKeys, index, changeValue}) => {
    const obj = {};
    defaultKeys.map((key) => {
        obj[key] = {
            value: '',
            onChange: (event) => changeValue(event.target.value, index, key)
        };
    });
    return obj;
};

export const initializeExistingKey = ({keys, values, index, changeValue}) => {
    if(!_.isEmpty(keys)) {
        keys.map( key => {
            if (!values[key]) {
                values[key] = '';
            }
        });
    }
    const obj = {};
    Object.keys(values).map((key) => {
        obj[key] = {
            value: values[key],
            onChange: (event) => changeValue(event.target.value, index, key)
        };
    });
    return obj;
};

export const getValues = (object) => {
    const res = {};
    Object.keys(object).map((key) => {
        const val = object[key] ? object[key].value : '';
        res[key] = val;
    })
    return res;
};