import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import configuredRadium             from 'configuredRadium.js';
import { Link }                     from 'react-router-dom';

import {
    colors
}                                   from 'styles/design.js';
import {
    DocumentListItem
}                                   from 'components';

const styles = {
    listItem: {
        display: 'block',
        position: 'relative',
        padding: '1em 1em 1em 3.5em',
        marginBottom: '0.125em',
        backgroundColor: '#fcfcfc'
    }
};

class SubListItem extends Component {
    
    render() {
        if (this.props.variant === 'document') {
            return (
                <li style={{ backgroundColor: '#fcfcfc', marginBottom: '0.125em', paddingLeft: '2.75em' }}>
                    <DocumentListItem
                        to={ this.props.href }
                        title={ this.props.title }
                        variant="compact"
                    >
                        <p>
                            { this.props.subtitle }
                        </p>
                    </DocumentListItem>
                </li>
            )
        }
        return (
            <li style={ styles.listItem }>
                <Link to={ this.props.href } style={{ cursor: this.props.href ? 'pointer' : 'auto' }}>
                    <span>{ this.props.title }</span> <span style={{ fontSize: '0.75em', color: colors.gray }}>{ this.props.subtitle }</span>
                </Link>
            </li>
        );
    }
}
export default translations(configuredRadium(SubListItem));
