import React, { Component } from 'react';
import { connect } from 'react-redux';
import translations from 'decorators/translations.js';
import _ from 'lodash';
import {
    saveGlobalDisciplineType,
    deleteGlobalDisciplineType,
    loadAllDisciplineConfigs,
} from 'redux/modules/disciplineConfig';

import {
    StandardPage,
    Section,
    DisciplineIcon,
    Button,
    Dialog,
    CancelButton,
    ConfirmableButton,
    SFInputField,
} from 'components';

const formData = {
    name: '',
    id: null,
    global: true,
    serviflex: 1,
    disciplineCategoryId: null,
};

class DisciplineGlobalConfigurationView extends Component {
    state = {
        form: {
            ...formData
        },
        editing: false
    };

    componentDidMount() {
        const {
            profile: {
                accessToken
            },
        } = this.props;
        this.props.loadAllDisciplineConfigs(accessToken);
    }

    changeValue = (value, key) => {
        const form = Object.assign({}, this.state.form);
        form[key] = value;
        this.setState(state => ({...state, form }));
    };

    handleEdit = (e, discipline) => {
        e.preventDefault();
        if (!discipline) {
            return;
        }
        const form = {
            disciplineCategoryId: discipline.disciplineCategoryId,
            name: discipline.disciplineType,
            id: discipline.id,
        };
        this.setState({
            form
        });
        this.openDialog();
    };

    handleAdd = () => this.openDialog();

    openDialog = () => this.setState({ editing: true });

    closeDialog = () => this.setState({ editing: false });

    handleSave = () => {
        const {
            profile: {
                accessToken
            }
        } = this.props;
        const {
            form
        } = this.state;
        if (!form || !form.name || !form.disciplineCategoryId) {
            return;
        }
        const data = {
            ...form,
            disciplineType: form.name
        };
        data.disciplineTypeId = form.id;
        delete data.id;
        this.props.saveGlobalDisciplineType({
            data,
            accessToken
        }).then(() => this.props.loadAllDisciplineConfigs(accessToken));
        this.closeDialog();
    };

    handleDelete = () => {
        const {
            profile: {
                accessToken
            }
        } = this.props;
        const id = _.get(this.state, 'form.id');
        if (!id) {
            return;
        }
        this.props.deleteGlobalDisciplineType({
            id,
            accessToken
        }).then(() => this.props.loadAllDisciplineConfigs(accessToken));
        this.closeDialog();
    };

    handleCancel = () => {
        this.setState({ form: { ...formData } })
        this.closeDialog();
    };

    render() {
        const {
            t,
            availableDisciplines,
            profile: {
                user
            },
        } = this.props;
        const {
            form,
            editing,
        } = this.state;
        if (!user || !user.isAdmin) {
            return null;
        }
        const filteredDisciplines = _.filter(availableDisciplines, discipline => discipline.global === true && !!discipline.serviflex);
        const groupedDisciplines = _.groupBy(filteredDisciplines, item => item.disciplineCategory);
        const categories = _.map(_.keys(groupedDisciplines), key => {
            const id = _.get(groupedDisciplines[key], '[0].disciplineCategoryId');
            return {
                name: key,
                id
            };
        });
        console.log(categories);
        console.log(availableDisciplines);
        console.log(filteredDisciplines);
        return <StandardPage>
            <Section>
                <div style={{ marginBottom: '2em', overflow: 'hidden' }}>
                    <Button onClick={ this.handleAdd } style={{ float: 'right' }}>{t('Add new')}</Button>
                </div>
                {_.map(_.entries(groupedDisciplines), ([group, disciplines]) =>
                    <div key={group}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <DisciplineIcon diciplineType ={group} />
                            <div style={{ marginLeft: '0.5em', fontWeight: 'bold' }}>
                                {t('discipline')}: {t(group)} ({group})
                            </div>
                        </div>
                        <div style={{ margin: '1.5em' }}>
                            {_.map(disciplines, discipline =>
                                <div key={discipline.disciplineType} style={{ marginBottom: '1em' }}>
                                    <span>{ t(`${discipline.disciplineType}`) } ({discipline.disciplineType}) </span>
                                    <a
                                        href=""
                                        onClick={ e => this.handleEdit(e, discipline) }
                                    >
                                        {t('edit')}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </Section>
            {editing && <Dialog>
                <form style={{ fontSize: '1rem' }}>
                    <label>
                        <div>{t('Category')}</div>
                        <select
                            onChange={event => this.changeValue(event.target.value, 'disciplineCategoryId')}
                            value={this.state.form.disciplineCategoryId}
                        >
                            <option>{t('select')}</option>
                            {_.map(categories, category =>
                                <option value={category.id}>{category.name}</option>
                            )}
                        </select>
                    </label>
                    <SFInputField
                        defaultInput={true}
                        id="DisciplineGlobalConfigurationView_name"
                        value={this.state.form.name}
                        onChange={event => this.changeValue(event.target.value, 'name')}
                        fieldName={t('Technical name')}
                    />
                    <div style={{ overflow: 'hidden' }}>
                        {form && form.id &&
                            <span style={{ float: 'left' }}>
                                <ConfirmableButton
                                    variant="delete"
                                    buttonIcon = {'fa fa-trash'}
                                    confirmButtonText = {t('yes')}
                                    confirmText = {t('Are you sure?')}
                                    action = {this.handleDelete}
                                />
                            </span>
                        }
                        <span style={{ float: 'right', marginLeft: '1em' }}>
                            <Button
                                onClick={this.handleSave}
                                loading={this.state.submitting}
                                disabled={this.state.submitting}
                            >
                                {this.props.t('save')}
                            </Button>
                        </span>
                        <span style={{ float: 'right' }}>
                            <CancelButton
                                cancelAction={this.handleCancel}
                                disabled={this.state.submitting}
                                submitting={this.state.submitting}
                            />
                        </span>
                    </div>
                    {
                        _.get(this.props, 'error.error.message') &&
                        <div>
                            { t('Error saving') }: { _.get(this.props, 'error.error.message') }
                        </div>
                    }
                </form>
            </Dialog>}
        </StandardPage>;
    }
}


const mapStateToProps = state => ({
    profile: state.userProfile,
    availableDisciplines: state.disciplineConfig.configs,
});

const mapDispatchToProps = dispatch => ({
    loadAllDisciplineConfigs: accessToken => dispatch(loadAllDisciplineConfigs(undefined, undefined, accessToken)),
    saveGlobalDisciplineType: ({ data, accessToken }) => dispatch(saveGlobalDisciplineType(data, accessToken)),
    deleteGlobalDisciplineType: ({ id, accessToken }) => dispatch(deleteGlobalDisciplineType(id, accessToken))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default translations(connector(DisciplineGlobalConfigurationView));