import React, {Component }            from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';


import {
    commonStyles,
    layout,
    colors
} from 'styles/design.js';

const editButtonStyle = [
    commonStyles.tinyButton,
    layout.floatRight,
    {
        minWidth: '5em',
        borderRadius: '3px',
        backgroundColor: colors.lightBlue,
        color: colors.white,
        whiteSpace: 'nowrap'
    }
];

class SystemSegmentEditButton extends Component {

    render() {
        const {t, isEditable} = this.props;
        if(this.props.showEditButton && isEditable === true) {
            return (
                <div className="clearfix">
                    <button
                        id={this.props.id}
                        type="button"
                        onClick = { this.props.onClick }
                        style = { [ editButtonStyle] }
                    >
                        { this.props.editText ? this.props.editText : t('edit') }
                    </button>
                </div>
            );
        } else if(isEditable === false) {
            return <div />;
        }
        return (<div style = {[layout.floatRight, {fontSize: '0.8em', width: '150px'}]}>{t('notity_edit_open')}</div>);
    }
}

export default translations(configuredRadium(SystemSegmentEditButton));