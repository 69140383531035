import React, { Component } from 'react';
import translations from 'decorators/translations.js';
import { connect } from 'react-redux';
import {
    StandardPage,
    BackLink,
    ConfirmableButton,
    LoadingIndicator
} from 'components';
import {
    commonStyles,
    measures,
    colors,
} from 'styles/design.js';

import { loadContractInfo, deleteContractInfo } from 'redux/modules/contractInfo.js';
import { loadClient }   from 'redux/modules/client.js';
import { loadDepartment  }       from 'redux/modules/department.js';
import { loadBillingIndexes }   from 'redux/modules/billingIndex.js';
import ContractInformation from 'components/ContractInfo/ContractInformation';
import Button from 'components/Buttons/Button';
import _ from 'lodash';

class ClientContractInfoView extends Component {
    componentWillMount() {
        const { dispatch, match: {params}} = this.props;
        const promises = [];

        promises.push(dispatch(loadClient(params.clientId, this.props.profile.accessToken)));
        promises.push(dispatch(loadContractInfo(params.contractInfoId, this.props.profile.accessToken))
            .then(() => {
                if(this.props.contractInfo.departmentId) {
                    dispatch(loadDepartment(this.props.contractInfo.departmentId, this.props.profile.accessToken));
                }
            }));
        if(!this.props.billingIndexes || this.props.billingIndexes.length === 0) {
            promises.push(dispatch(loadBillingIndexes(this.props.profile.accessToken)));
        }
        return Promise.all(promises);
    }

    canUserDeleteContractInfo = () => {
        return true;
    }
    isContractInfoDeletable = () => {
        const contractInfo = this.props.contractInfo;
        if (!_.isEmpty(contractInfo.agreements) || !_.isEmpty(contractInfo.frameAgreements)) {
            return false;
        }
        return true;
    }
    deleteContractInfo = () => {
        const { history, dispatch, profile: { accessToken }, match: { params } } = this.props;
        const id = _.get(this.props, 'match.params.contractInfoId');
        dispatch(deleteContractInfo(id, accessToken))
            .then(res => {
                if (res.error) {
                    const message = _.get(res, 'error.error.message', 'Something went wrong');
                    this.setState({ deleteError: message });
                } else {
                    dispatch(loadClient(this.props.match.params.clientId, this.props.profile.accessToken))
                        .then(result => {
                            history.push('/client/' + params.clientId);
                        });
                }
            });
    };

    render() {
        const {
            client,
            contractInfo,
            departmentState,
            billingIndexes,
            match: {
                params: {
                    clientId,
                    contractInfoId
                }
            },
            t
        } = this.props;

        if (_.isEmpty(contractInfoId)) {
            return (
                <StandardPage>
                    Invalid contract info
                </StandardPage>
            );
        }

        return <StandardPage>
            <div><BackLink to={ `/client/${clientId}` } title={ t('Back to client') } /></div>
            <header>
                <h1 style={commonStyles.pageTitle}>{this.props.t('client_details')}</h1>
                <Button
                    href={ `/client/${clientId}/contractinfo/${contractInfoId}/edit` }
                    style={{ float: 'right' }}
                >
                    { t('edit') }
                </Button>
            </header>
                <div style={{ marginTop: '1em' }}>
                    <LoadingIndicator visible={this.props.contractInfoState.loading} />
                </div>
                <div style={{ marginTop: '0.5em', display: contractInfo.id ? 'block' : 'none'}}>
                {
                    (() => {
                        if (this.props.deletingContractInfo) {
                            return <LoadingIndicator big={true} visible={true} />;
                        }
                        if (this.canUserDeleteContractInfo(this.props.profile, contractInfo) && this.isContractInfoDeletable()) {
                            return (
                                <ConfirmableButton
                                    style={{backgroundColor: colors.brightRed}}
                                    buttonIcon={'fa fa-trash'}
                                    buttonText={this.props.t('delete_contractinfo')}
                                    confirmButtonText={this.props.t('yes')}
                                    confirmText={this.props.t('confirm_delete_contractinfo')}
                                    showLoading={this.props.contractInfo.deleting}
                                    action={this.deleteContractInfo}
                                />
                            );
                        }
                        return (
                            <div style={{marginTop: measures.standard, marginBottom: measures.standard}}>
                                <button
                                    style={{...commonStyles.button, backgroundColor: colors.gray}}
                                    disabled={true}
                                    onClick={() => {
                                    }}
                                >
                                    {t('delete_contractinfo')}
                                </button>
                                <p style={{fontSize: '0.9em', paddingTop: measures.half}}>
                                    * {t('contractinfo_not_removable')}
                                </p>
                            </div>
                        );

                    })()
                }
            </div>
            {
                contractInfo && contractInfo.id &&

                <ContractInformation
                    contractInfo={ contractInfo }
                    department={ departmentState.department }
                    billingIndexes={ billingIndexes }
                    canUserDeleteContractInfo={ this.canUserDeleteContractInfo }
                    isContractInfoDeletable={ this.isContractInfoDeletable }
                    client={ client }
                />
            }
        </StandardPage>;
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client,
        profile: state.userProfile,
        departmentState: state.department,
        billingIndexes: state.billingIndex.all,
        contractInfoState: state.contractInfo,
        contractInfo: state.contractInfo.contractInfo
    };
}

const connector = connect(mapStateToProps);

export default translations(connector(ClientContractInfoView));