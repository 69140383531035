import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium from 'configuredRadium.js';
import {connect} from 'react-redux';
import {
    LoadingIndicator,
    RichTextEditor,
    RichTextView,
    Button
} from 'components';
import {
    commonStyles,
    decorators,
    measures,
    layout,
    colors
} from 'styles/design.js';
import _ from 'lodash';
import './ServiceLevelSelection.css';

const radioLabelStyle = {
    ...layout.inlineBlock,
    backgroundColor: colors.lightBlue,
    fontSize: '1em',
    padding: '0.5em 0.75em',
    textDecoration: 'none',
    color: colors.white,
    borderRadius: '3px',
    cursor: 'pointer',
    lineHeight: measures.half,
    marginRight: measures.half
};

const radioInputStyle = {
    ...layout.hidden,
    width: '0px',
    margin: 0,
    padding: 0
};

const checkedStyle = {
    backgroundColor: colors.blue
};

const mainContainerStyle = {
    padding: measures.standard + ' 0',
    overflow: 'hidden',
    borderBottom: decorators.coloredBorder(colors.lightGray)
};

const warningStyle = {
    padding: measures.standard,
    color: colors.basicRed
};

const descriptionStyle = {
    margin: '1em 0',
    lineHeight: 1.25,
    fontSize: '0.875em'
};

const serviceLevels = ['bronze', 'silver', 'gold'];

class ServiceLevelSelection extends Component {

    static propTypes = {
        handleServiceLevelChange: PropTypes.func.isRequired
    };

    componentWillMount() {
        if (!_.isEmpty(this.props.discipline)) {
            this.init(this.props.discipline);
            if (!this.props.discipline.serviceLevel && !this.props.discipline.id) {
                this.setState({editing: true});
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.selectedLevel && !_.isEmpty(nextProps.discipline)) {
            this.init(nextProps.discipline);
            if (!nextProps.discipline.serviceLevel && !nextProps.discipline.id) {
                this.setState({editing: true});
            }
        }
        this.setState({levelWarning: this.props.selectedLevel && nextProps.discipline.serviceLevel !== this.state.selectedLevel});
    }

    init(discipline) {
        const form = {
            serviceLevel: {
                value: discipline.serviceLevel
            },
            disciplineCategory: {
                value: discipline.disciplineCategory
            },
            disciplineType: {
                value: discipline.disciplineType
            },
            serviceLevelDescription: {
                value: discipline.serviceLevelDescription
            },
            hideServiceLevel: {
                value: discipline.hideServiceLevel
            },
            hideSystems: {
                value: discipline.hideSystems
            },
            useDisciplineTerms: {
                value: discipline.useDisciplineTerms
            }
        };

        Object.keys(form).map((key) => {
            form[key].onChange = (event, value) => {
                if (event.target) {
                    this.changeValue(key, event.target.value);
                } else if (value) {
                    this.changeValue(key, value);
                }
            }
        });
        const overrideDescription = !_.isEmpty(discipline.serviceLevelDescription);
        this.setState({form, overrideDescription});
    }

    changeValue = (key, value) => {
        if (this.state.editing) {
            if (key === 'serviceLevel') {
                if (this.props.discipline.id && this.props.discipline.serviceLevel !== value) {
                    this.setState({levelWarning: true});
                }
                this.setState({ overrideDescription: false });
                this.props.handleServiceLevelChange(value);
            }
            const form = Object.assign({}, this.state.form);
            form[key].value = value;
            this.setState({form});
        }
    };

    isSelected(level) {
        return level === this.state.form.serviceLevel.value;
    }
    getSelectedServiceLevel = () => this.state.form.serviceLevel.value;

    startEdit = () => {
        this.setState({editing: true});
    };
    handleDowngradingServiceLevel = disciplineProp => {
        if (disciplineProp.serviceLevel === 'gold') {
            return disciplineProp;
        }
        const discipline = JSON.parse(JSON.stringify(disciplineProp));
        _.map(discipline.disciplineSystems, sys => {
            if (discipline.serviceLevel === 'bronze') {
                sys.materials = null;
                sys.repairs = null;
            }
            if(discipline.serviceLevel === 'silver') {
                sys.repairs = null;
            }
        });
        return discipline;
    }

    save = () => {
        const discipline = this.props.discipline;

        Object.keys(this.state.form).map((key) => {
            discipline[key] = this.state.form[key].value;
        });
        if (this.state.overrideDescription !== true) {
            discipline.serviceLevelDescription = null;
        }
        if (discipline.serviceLevel === '') {
            return this.setState({serviceLevelError: true});
        }
        discipline.actionType = 'updateServiceLevel';
        discipline.recalculate = true;
        const updatedDiscipline = this.handleDowngradingServiceLevel(discipline);
        this.props.handleSave(updatedDiscipline).then(() => {
            if (!this.props.isNewDiscipline) {
                this.setState({editing: false});
            }
        });
    };

    cancel = () => {
        this.setState({editing: false});
        this.props.handleCancel();
    };

    // Update overrideDescription state variable and set the default description as base for editing.
    overrideServiceLevelDescription = description => {
        this.setState({ overrideDescription: !this.state.overrideDescription });
        this.changeValue('serviceLevelDescription', description);
    }
    
    getServiceLevelDescription = selectedLevel => {
        const { disciplineConfig, countries } = this.props;
        const serviceLevelDescriptionBaseKey = `discipline_${disciplineConfig.disciplineCategory}_${disciplineConfig.disciplineType}`;
        const serviceLevelDescriptionKey = `${serviceLevelDescriptionBaseKey}_${this.getSelectedServiceLevel()}`;
        const language = this.getLanguage();
    
        let levelDescriptionObj = _.find(disciplineConfig.translations, row => (row.key === serviceLevelDescriptionKey) && row.language === language);
        if (!levelDescriptionObj) {
            // Use general description if service level (bronze, silver, gold) description is not available
            levelDescriptionObj = _.find(disciplineConfig.translations, row => (row.key === serviceLevelDescriptionBaseKey) && row.language === language);
        }
        const levelDescription = levelDescriptionObj ? levelDescriptionObj.value : '';

        if (_.isEmpty(levelDescription)) {
            const countryConfig = countries && !_.isEmpty(countries.selectedCountry) && countries.selectedCountry;
            const fallback = countryConfig[`${selectedLevel}ServiceLevelDescription`];
            return fallback || '';
        }
        return levelDescription;
    }

    getLanguage = () => {
        const { discipline, countries: { selectedCountry } } = this.props;
        const agreement = _.get(discipline, 'agreement');
        const language = agreement && agreement.language ? agreement.language : selectedCountry.defaultLanguage;
        return language;
    }

    render() {
        const {
            t,
            submitting,
            isEditable,
            isCustomTermsAvailable
        } = this.props;
        const { serviceLevel, serviceLevelDescription, hideServiceLevel, hideSystems, useDisciplineTerms } = this.state.form;
        const canEdit = this.props.canEdit || this.state.editing;
        if (_.isEmpty(this.state.form)) {
            return <LoadingIndicator/>;
        }
        const showDescription = true;
        const levelDescription = this.getServiceLevelDescription(serviceLevel.value);
        const language = this.getLanguage();
        return (
            <div style={mainContainerStyle}>
                <div>
                    <h2 id="ServiceLevelSelection_title" style={{
                        ...commonStyles.pageSubTitle,
                        marginBottom: measures.standard,
                        marginTop: measures.standard
                    }}>{t('service_level')}</h2>
                </div>
                <div>
                    <div style={{
                        padding: measures.double,
                        backgroundColor: !this.props.discipline.serviceLevel ? colors.lightGray : colors.lightGreen
                    }}>
                        {(() => {
                            if (canEdit) {
                                return serviceLevels.map((servicelevel) => {
                                    return (
                                        <label
                                            className="ServiceLevelSelection_serviceLevel"
                                            id={'ServiceLevelSelection_serviceLevel_' + servicelevel}
                                            key={servicelevel}
                                            style={Object.assign({}, radioLabelStyle, this.isSelected(servicelevel) ? checkedStyle : {})}
                                        >
                                            {t('serviceLevel_' + servicelevel)}
                                            <input
                                                style={radioInputStyle}
                                                onClick={serviceLevel.onChange}
                                                type="radio"
                                                name="servicelevel"
                                                value={servicelevel}
                                            />
                                        </label>
                                    );
                                });
                            }
                            return serviceLevels.map((servicelevel) => {
                                return (
                                    <label
                                        className="ServiceLevelSelection_serviceLevel"
                                        key={servicelevel}
                                        style={[radioLabelStyle, this.isSelected(servicelevel) ? checkedStyle : []]}
                                    >
                                        {t('serviceLevel_' + servicelevel)}
                                        <input
                                            readOnly
                                            style={radioInputStyle}
                                            type="radio"
                                            name="servicelevel"
                                            value={servicelevel}
                                        />
                                    </label>
                                );
                            });
                        })()}
                    </div>
                    {
                        !this.state.editing &&
                        <div style={{ marginTop: '1em', fontSize: '0.9em' }}>
                            <div>
                                {
                                    hideServiceLevel.value !== true ?
                                    t('Service level is printed on agreement') :
                                    t('Service level not going to be printed')
                                }
                            </div>
                            <div style={{ marginTop: '0.5em' }}>
                                {
                                    hideSystems.value !== true ?
                                    t('Systems are printed on the agreement') :
                                    t('Systems are not going to be printed')
                                }
                            </div>
                            {isCustomTermsAvailable &&
                                <div style={{ marginTop: '0.5em' }}>
                                    {
                                        useDisciplineTerms.value !== true ?
                                        t('Normal agreement terms and conditions') :
                                        t('Discipline specific terms and conditions')
                                    }
                                </div>
                            }
                        </div>
                    }

                    { serviceLevels.map(level => {
                        if (this.isSelected(level)) {
                            return <div key={ level } style={ descriptionStyle }>

                                {
                                    !_.isEmpty(levelDescription) && this.state.editing &&
                                    <div style={{ marginBottom: '1em' }}>
                                        <div style={{ paddingTop: '0.5em'}}>
                                            <label style={{ alignItems: 'center', display: 'flex' }}>
                                                <input
                                                    checked={ hideServiceLevel.value }
                                                    type="checkbox"
                                                    onClick={ (event) => this.changeValue('hideServiceLevel', event.target.checked) }
                                                />&nbsp; { t('Hide servicelevel on printout') }
                                            </label>
                                        </div>
                                        <div style={{ paddingTop: '0.5em'}}>
                                            <label style={{ alignItems: 'center', display: 'flex' }}>
                                                <input
                                                    checked={ hideSystems.value }
                                                    type="checkbox"
                                                    onClick={ (event) => this.changeValue('hideSystems', event.target.checked) }
                                                />&nbsp; { t('Hide systems on printout') }
                                            </label>
                                        </div>
                                        {isCustomTermsAvailable &&
                                        <div style={{ paddingTop: '0.5em'}}>
                                            <label style={{ alignItems: 'center', display: 'flex' }}>
                                                <input
                                                    checked={ useDisciplineTerms.value }
                                                    type="checkbox"
                                                    onClick={ (event) => this.changeValue('useDisciplineTerms', event.target.checked) }
                                                />&nbsp; { t('Use discipline specific terms and conditions') }
                                            </label>
                                        </div>
                                        }
                                    </div>
                                }
                                <h3 style={{ margin: '0.5em 0' }}>{ t('description') } ({ language })</h3>
                                {
                                    !_.isEmpty(levelDescription) && this.state.editing &&
                                    <div style={{ marginBottom: '1em' }}>
                                        <label>
                                            <input
                                                checked={ this.state.overrideDescription }
                                                type="checkbox"
                                                onClick={ () => this.overrideServiceLevelDescription(levelDescription) } /> { t('Override description') }
                                        </label>
                                    </div>
                                }
                                {
                                    !this.state.overrideDescription &&
                                    <RichTextView value={ levelDescription } />
                                }

                            </div>;
                        }
                        return null;
                    })}
                    {
                        this.state.editing && this.state.overrideDescription &&
                        <div style={this.state.editing && this.state.overrideDescription ? layout.block : layout.none}>
                            <RichTextEditor
                                onChange={serviceLevelDescription.onChange}
                                descriptionKey={'serviceLevelDescription'}
                                value={serviceLevelDescription.value}
                                label={t('description')}
                            />

                        </div>
                    }
                    { !_.isEmpty(serviceLevelDescription.value) &&
                        <div style={showDescription && !this.state.editing ? layout.block : layout.none}>
                            <div style={{margin: measures.standard}}>
                                <RichTextView
                                    id="ServiceLevelSelection_serviceLevelDescriptionValue"
                                    value={serviceLevelDescription.value}
                                />
                            </div>
                        </div>
                    }
                    <div style={[this.state.levelWarning ? warningStyle : layout.none]}>
                        {t('discipline_service_level_change_warning')}
                    </div>
                    {
                        canEdit && !this.state.editing && isEditable &&
                        <div style={{...layout.floatRight, marginTop: measures.standard, minHeight: measures.double}}>
                            <Button
                                variant="secondary"
                                id="ServiceLevelSelection_Edit"
                                disabled={submitting}
                                onClick={this.startEdit}
                            >
                                {t('edit')}
                            </Button>
                        </div>
                    }

                    <div style={{
                        ...layout.floatRight,
                        display: this.state.serviceLevelError ? 'block' : 'none', ...commonStyles.errorMessage
                    }}>
                        {t('service_level_is_required')}
                    </div>
                    <div style={{clear: 'both'}}/>
                    {
                        this.state.editing &&
                        <div style={{float: 'right', marginTop: measures.standard}}>
                            <Button
                                id="ServiceLevelSelection_cancel"
                                variant="link"
                                disabled={submitting}
                                onClick={this.cancel}
                            >
                                {t('cancel')}
                            </Button>
                            <Button
                                id="ServiceLevelSelection_save"
                                onClick={this.save}
                                disabled={submitting}
                                loading={submitting}
                            >
                                {t('save')}
                            </Button>
                        </div>
                    }

                </div>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        discipline: state.discipline.discipline
    };
}

const connector = connect(mapStateToProps);

export default translations(connector(configuredRadium(ServiceLevelSelection)));
