import React                from 'react';

import './VerticalNavigationBar.css';
import TabLabel from '../Tabs/TabLabel';

const VerticalNavigationItem = ({id, exact, children, url, subItem}) => {
    return (
        <TabLabel id={'VerticalNavigationBar_link_' + id} to={{ pathname: url }} exact={exact} navLink>
            <span className={ subItem ? 'vertical-navigation-subitem' : '' }>
                { subItem && <i className="fa fa-angle-right" /> }
                { children }
            </span>
        </TabLabel>
    );
}

export default VerticalNavigationItem;
