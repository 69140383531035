import React, {Component}    from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import {reduxForm}                    from 'redux-form';
import configuredRadium                 from 'configuredRadium.js';
import {
    measures,
    layout,
    formStyles
}                                       from 'styles/design.js';
import {
    SaveButton,
    CancelButton,
    SFInputField,
    SFCheckBox
}                                       from 'components';
import _ from 'lodash';

const shouldNotBeEmpty = (formFieldName) => {
    return (data, props, errors) => {
        if (props.form && props.form[formFieldName] && props.form[formFieldName].touched && !data[formFieldName]) {
            errors[formFieldName] = 'Value cannot be empty';
        }
        return errors;
    };
};

const validate = (data, props) => {
    let errors = {};
    errors = shouldNotBeEmpty('name')(data, props, errors);
    errors = shouldNotBeEmpty('salesArea')(data, props, errors);
    return errors;
};

const reduxFormObject = {
    form: 'clientForm',
    fields: [
        'name', 'email', 'clientInfo', 'clientPhone', 'clientAddress',
        'vatNumber', 'businessID',
        'clientAddress.street', 'clientAddress.zip', 'clientAddress.city',
        'testClient'
    ],
    validate
};
class ClientForm extends Component {

    static propTypes = {
        client: PropTypes.object,
        fields: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        cancelAction: PropTypes.func.isRequired,
        countries: PropTypes.array.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        const defaultValues = {
            isKeyCustomer: true
        };
        let client = this.props.client;
        this.props.initializeForm(client || defaultValues);
    }

    render() {
        const {
            fields: {
                name,
                email,
                clientInfo,
                clientPhone,
                clientAddress,
                vatNumber,
                businessID,
                testClient
            },
            handleSubmit,
            submitting,
            t
        } = this.props;

        return (
            <div>
                <section style={{marginBottom: measures.standard}}>
                    <SFInputField id="ClientForm_companyName" formProperty={name} fieldName={t('company_name')}
                                  mandatory/>
                    <SFInputField id="ClientForm_companyEmail" formProperty={email} fieldName={t('email')} />
                    <SFInputField id="ClientForm_companyPhone" formProperty={clientPhone}
                                  fieldName={t('client_phone')}/>
                    <SFInputField id="ClientForm_clientInfo" formProperty={clientInfo}
                                  fieldName={t('client_info_field')}/>
                    <SFInputField id="ClientForm_clientAddressStreet" formProperty={clientAddress.street}
                                  fieldName={t('address')}/>
                    <div>
                        <div style={formStyles.colStyle('left', '39%')}>
                            <SFInputField id="ClientForm_clientAddressZip" formProperty={clientAddress.zip}
                                          fieldName={t('zip')}/>
                        </div>
                        <div style={formStyles.colStyle('right', '60%')}>
                            <SFInputField id="ClientForm_clientAddressCity" formProperty={clientAddress.city}
                                          fieldName={t('city')}/>
                        </div>
                    </div>

                    <SFInputField
                        id="ClientForm_vatNumber"
                        formProperty={vatNumber}
                        fieldName={t('vat_number')}
                    />
                    <SFInputField
                        id="ClientForm_businessId"
                        formProperty={businessID}
                        fieldName={t('business_id')}
                    />
                    <SFCheckBox
                        id="ClientForm_TestClient"
                        formProperty={testClient}
                        rightLabel={t('test_client')}
                    />

                    <div style={layout.floatRight}>
                        <span style={{marginRight: measures.standard}}>
                            <CancelButton
                                cancelAction={this.props.cancelAction}
                            />
                        </span>
                        <SaveButton
                            id="ClientForm_submit"
                            onClick={handleSubmit}
                            buttonType={'submit'}
                            disabled={submitting}
                            submitting={submitting}
                        />
                    </div>
                </section>
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        user: state.userProfile.user
    };
}

export default translations(reduxForm(reduxFormObject, mapStateToProps)(configuredRadium(ClientForm)))
