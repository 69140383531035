import Radium from 'radium';

let _matchMedia = null;

function configuredRadium(component) {
    return Radium({
        matchMedia: _matchMedia
    })(component);
}

configuredRadium.setMatchMedia = (matchMedia) => {
    _matchMedia = matchMedia;
};

export default configuredRadium;
