import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import translations         from 'decorators/translations.js';
import configuredRadium     from 'configuredRadium.js';
import { connect }          from 'react-redux';

import {
    StandardPage,
    ClientInformation,
    EditClientButton,
    BreadcrumbBar,
    CloseButton,
    BackLink
} from 'components';

import {
    layout,
    measures,
    commonStyles
} from 'styles/design.js';

import { loadClient }   from 'redux/modules/client.js';
import { loadDepartment }       from 'redux/modules/department.js';
import { loadBillingIndexes }   from 'redux/modules/billingIndex.js';

const buttonLayout = [
    layout.floatRight
];
class ClientDetailsView extends Component {

    static propTypes = {
        client: PropTypes.object.isRequired
    };

    componentWillMount() {
        const { dispatch, match: {params}} = this.props;
        const promises = [];
        dispatch(loadClient(params.clientId, this.props.profile.accessToken))
            .then(() => {
                if(this.props.client.departmentId) {
                    dispatch(loadDepartment(this.props.client.departmentId, this.props.profile.accessToken));
                }
            });
        if(!this.props.billingIndexes || this.props.billingIndexes.length === 0) {
            promises.push(dispatch(loadBillingIndexes(this.props.profile.accessToken)));
        }
        return Promise.all(promises);
    }

    render() {
        const {
            t,
            match: {
                params: {
                    clientId,
                    contractInfoId
                }
            }
         } = this.props;
        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar path={this.props.location.pathname} />
                { clientId &&
                    <BackLink title={t('Back to client')}
                        to={`/client/${clientId}/`}/>
                }
                <header>
                    <h1 id="ClientDetailsView_title" style={ commonStyles.pageTitle }>{ t('client_details') }</h1>
                    <div style={ [buttonLayout, {width: '400px'}]}>
                        <div style={ [layout.floatRight, { marginLeft: '0.5em' }] }>
                            <EditClientButton clientId={ this.props.client.id }/>
                        </div>
                    </div>
                </header>
                <ClientInformation
                    client={ this.props.client }
                    department={ this.props.departmentState.department }
                    billingIndexes={ this.props.billingIndexes }
                />
                <div style={ [ layout.floatRight, layout.inlineBlock ] }>
                    <span style={ [{ marginRight : measures.standard }] }>
                        <CloseButton path={'/client/' + this.props.match.params.clientId + '/'}/>
                    </span>
                </div>
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client,
        profile: state.userProfile,
        departmentState: state.department,
        billingIndexes: state.billingIndex.all
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(ClientDetailsView)));