import React from 'react';

import {
    measures
} from 'styles/design';

const FrameAgreementMaterial = (props) => {
    const { material, index, t } = props;
    const type = material.type || 'listPrice';
    return (
        <div key={'material_index' + index} style={{marginTop: measures.standard, marginBottom: measures.standard, borderBottom: 'thin solid'}}>
            <div>
                {t('type')}: {t('material_type_' + type)}
            </div>
            { material.type === 'other' &&
                <div style={{paddingTop: measures.standard}}>
                    {t('name')}: {material.name}
                </div>
            }
            <div style={{paddingTop: measures.standard, paddingBottom: measures.standard}}>
                {
                    (() => {
                        if(material.type === 'listPrice') {
                            return <span>{t(material.valueType)}: {material[material.valueType]}%</span>;
                        }
                        if(material.type === 'netPrice') {
                            return <span>{t(material.valueType)}: {material[material.valueType]}%</span>;
                        }
                        return <span>{t('description')}: {material.description} </span>;

                    })()
                }

            </div>
        </div>
    );
};

export default FrameAgreementMaterial;