import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';

import { isFrameAgreementEditable } from 'helpers/agreement';

import {
    loadFrameAgreement,
    saveFrameAgreement,
    shouldReloadFrameAgreement,
} from 'redux/modules/frameagreement.js';

import {
    loadCountry,
    isCountryLoaded
} from 'redux/modules/country.js';

import {
    loadContractInfo,
    isContractInfoLoaded
} from 'redux/modules/contractInfo';
import { loadWithType } from 'redux/modules/translations.js';


import {
    SLAInformation,
    SLAForm,
    StandardPage,
    BreadcrumbBar,
    BackLink,
    Header
} from 'components';
import {
    commonStyles,
} from 'styles/design.js';

const slaTranslatableMap = {
    slaResponseTimeDescription: 'mainagreement_sla_terms_response_time_description',
    slaYearlyMeetingDescription: 'mainagreement_sla_terms_yearly_meeting_description',
    slaSupport247description: 'mainagreement_sla_terms_support_247_description'
};

class FrameAgreementSLAView extends Component {
    state = {};

    componentWillMount() {
        const promiseArr = [];
        const {match: {params}} = this.props;
        if (!isContractInfoLoaded(this.props, params.contractInfoId)) {
            promiseArr.push(this.props.loadContractInfo(params.contractInfoId, this.props.profile.accessToken).then((res) => {
                this.loadCountryAndDepartments()
            }));
        } else {
            promiseArr.push(this.loadCountryAndDepartments());
        }
        if (shouldReloadFrameAgreement(this.props, params.frameAgreementId)) {
            promiseArr.push(this.props.loadFrameAgreement(params.frameAgreementId, this.props.profile.accessToken));
        }

        const token = this.props.profile.accessToken;
        return Promise.all(promiseArr).then(async () => {
            const contractInfo = this.props.contractInfo.contractInfo;
            let country;
            if (contractInfo) {
                country = await this.props.dispatch(loadCountry(contractInfo.salesArea, token));
            }
            const defaultLanguage = country && country.result && country.result.defaultLanguage;
            const languages = ['en'];
            const lang = defaultLanguage || 'en';
            if (lang && lang !== 'en') {
                languages.push(lang);
            }
            await this.props.dispatch(loadWithType(2, languages.join(','), country.id, token));
            this.setState({
                isLoading: false,
                language: lang
            });
        });

    }

    loadCountryAndDepartments() {
        const contractInfo = this.props.contractInfo.contractInfo || null;
        if (contractInfo && !this.props.countries.loading && !isCountryLoaded(this.props, contractInfo.salesArea)) {
            this.props.loadCountry(contractInfo.salesArea, this.props.profile.accessToken);
        }
    }

    editSLA = (value) => {
        this.setState({editSLA: value});
    };

    handleSubmitAgreement = (data) => {
        const agreementId = this.props.match.params.frameAgreementId;
        const contractInfoId = this.props.match.params.contractInfoId;
        const clientId = this.props.match.params.clientId;

        data.contractInfoId = contractInfoId;
        data.id = agreementId; // must exist
        this.props.dispatch(saveFrameAgreement(data, this.props.profile.accessToken)).then((res) => {
            if (res.result) {
                this.props.history.push(`/client/${clientId}/contractInfo/${contractInfoId}/frameagreement/${agreementId}`);
            }
        });
    };

    handleCancel() {
        this.editSLA(false);
    }

    getAgreementLanguage = () => {
        const {
            language,
            agreement: {
                agreement
            },
            countries: {
                selectedCountry
            }
        } = this.props;
        if (agreement.language) {
            return agreement.language;
        }
        if (language === selectedCountry.defaultLanguage) {
            return language;
        }
        return 'en';
    };

    getSlaDescription = key => {
        const {
            translatables,
            countries: {
                selectedCountry
            }
        } = this.props;
        if (!translatables) {
            return null;
        }
        const language = this.getAgreementLanguage();
        if (
            translatables &&
            slaTranslatableMap[key] &&
            translatables[slaTranslatableMap[key]] &&
            translatables[slaTranslatableMap[key]][language]
        ) {
            return translatables[slaTranslatableMap[key]][language].value;
        }
        else if (selectedCountry && selectedCountry[key]) {
            return selectedCountry[key];
        }
        else {
            return '';
        }
    };

    render() {
        const {t} = this.props,
            agreement = this.props.frameAgreement.agreement || {},
            contractInfo = this.props.contractInfo.contractInfo;

        const contractInfoId = this.props.match.params.contractInfoId;
        const clientId = this.props.match.params.clientId;

        const isEditable = isFrameAgreementEditable(this.props.frameAgreement.agreement);
        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar
                    page="frameagreement"
                    agreement={agreement}
                    contractInfo={contractInfo}
                />

                {
                    agreement &&
                    <BackLink title={t('Back to agreement')}
                              to={`/client/${clientId}/contractInfo/${contractInfoId}/frameagreement/${agreement.id}`}/>
                }
                <Header title={t('SLA')}/>

                <div style={commonStyles.subSection}>
                    {(() => {
                        if (this.state.editSLA) {
                            return (
                                <SLAForm
                                    cancelAction={() => {
                                        this.editSLA(false);
                                    }}
                                    agreement={agreement}
                                    onSubmit={this.handleSubmitAgreement}
                                    editing={this.state.editSLA}
                                    countryConfig={this.props.countries.selectedCountry}
                                    saving={this.props.frameAgreement.saving}
                                    getSlaDescription={this.getSlaDescription}
                                />
                            );
                        }
                        return (
                            <SLAInformation
                                agreement={agreement}
                                countryConfig={this.props.countries.selectedCountry}
                                editClicked={() => {
                                    this.editSLA(true);
                                }}
                                isEditable={isEditable}
                                getSlaDescription={this.getSlaDescription}
                            />
                        );
                    })()}
                </div>
            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        department: state.department,
        countries: state.countries,
        frameAgreement: state.frameagreement,
        profile: state.userProfile,
        contractInfo: state.contractInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadContractInfo: (id, token) => dispatch(loadContractInfo(id, token)),
        loadCountry: (salesArea, accessToken) => dispatch(loadCountry(salesArea, accessToken)),
        loadFrameAgreement: (agreementId, accessToken) => dispatch(loadFrameAgreement(agreementId, accessToken)),
        saveFrameAgreement: (agreement, accessToken) => dispatch(saveFrameAgreement(agreement, accessToken))
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(FrameAgreementSLAView));
