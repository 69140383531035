import React from 'react';

import {
    SFInputField,
} from 'components';

class OperatingHoursForm extends React.Component {
    state = {};

    render() {
        const { t, form } = this.props;
        return (
            <div>
                <div className="bolded">
                    {t('working_days')}:
                </div>
                <div className="operating-hours-row">
                    <SFInputField
                        id="Operating_Hours_workingday_start"
                        {...form.workingDayStart}
                        defaultInput={true}
                        type="number"
                        fieldName= { t('start') }
                    />
                    <SFInputField
                        id="Operating_Hours_workingday_end"
                        {...form.workingDayEnd}
                        defaultInput={true}
                        type="number"
                        fieldName= { t('end') }
                    />
                </div>
                <div className="bolded">
                    {t('saturday')}:
                </div>
                <div className="operating-hours-row">
                    <SFInputField
                        id="Operating_Hours_saturday_start"
                        {...form.saturdayStart}
                        defaultInput={true}
                        type="number"
                        fieldName= { t('start') }
                    />
                    <SFInputField
                        id="Operating_Hours_saturday_end"
                        {...form.saturdayEnd}
                        defaultInput={true}
                        type="number"
                        fieldName= { t('end') }
                    />
                </div>
                <div className="bolded">
                    {t('sunday')}:
                </div>
                <div className="operating-hours-row">

                    <SFInputField
                        id="Operating_Hours_sunday_start"
                        {...form.sundayStart}
                        defaultInput={true}
                        type="number"
                        fieldName= { t('start') }
                    />
                    <SFInputField
                        id="Operating_Hours_sunday_end"
                        {...form.sundayEnd}
                        defaultInput={true}
                        type="number"
                        fieldName= { t('end') }
                    />
                </div>
            </div>
        );
    };
};

export default OperatingHoursForm;