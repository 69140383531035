import React, {Component}from 'react';
import translations from 'decorators/translations.js';
import configuredRadium                 from 'configuredRadium.js';
import { connect }       from 'react-redux';
import _                 from 'lodash';


import { loadContractInfo, isContractInfoLoaded } from 'redux/modules/contractInfo';
import {
    loadBuilding
} from 'redux/modules/building';

import {
    loadAgreements
} from 'redux/modules/agreement';

import {
    isEditable
} from 'helpers/agreement.js';
import {
    StandardPage,
    CreateDisciplineButton,
    DisciplineList,
    BreadcrumbBar,
    CloseButton,
    BackLink,
} from 'components';
import {
    measures,
    layout,
    commonStyles
} from 'styles/design.js';

import {
    deleteDiscipline
} from 'redux/modules/discipline.js';

const buttonLayout = [
    layout.floatRight
];

class BuildingOverviewView extends Component {
    componentWillMount() {
        const promises = [],
            params = this.props.match.params,
            dispatch = this.props.dispatch,
            accessToken = this.props.profile.accessToken;

        if(!isContractInfoLoaded(this.props, params.contractInfoId)) {
            promises.push(dispatch(loadContractInfo(params.contractInfoId, accessToken)));
        }
        if (!this.props.buildingState.loaded || _.get(this.props, 'buildingState.building.id') !== params.buildingId) {
            promises.push(dispatch(loadBuilding(params.buildingId, accessToken)));
        }
        return Promise.all(promises).then( () => {
            const building = _.get(this.props, 'buildingState.building');
            dispatch(loadAgreements(this.props.contractInfo.contractInfo.clientId, accessToken));
        });
    }
    componentWillReceiveProps(nextProps) {
        const promises = [];
        if(this.props.disciplineState.deleting && nextProps.disciplineState.deleted) {
            promises.push(this.props.dispatch(loadBuilding(this.props.match.params.buildingId, this.props.profile.accessToken)));
        }
        return Promise.all(promises);
    }

    handleDisciplineDelete = (id) => {
        // do the deletion
        const {
            buildingState: { building },
        } = this.props;
        const disciplines = building.disciplines;
        const discipline = _.find(disciplines, { id });
        const contractInfo = _.find(this.props.agreement.byContractInfo, row => row.id === building.contractInfoId);
        const agreements = contractInfo.agreements;
        const agreement = _.find(agreements, { id: discipline.agreementId });

        if(agreement && discipline && agreement.id === discipline.agreementId && isEditable(agreement)) {
            this.setState({deletingDiscipline: id});
            this.props.dispatch(deleteDiscipline(id, this.props.profile.accessToken));
        }
    };
    render() {
        const {
            t,
            match: {params},
            buildingState: {building},
            contractInfo: { contractInfo },
        } = this.props;
        const client = contractInfo.client || {};
        const selectedBuilding = building;
        if(this.props.buildingState.loading || this.props.contractInfo.loading){
            return <StandardPage loaderVisible={true}><div /></StandardPage>
        }
        const contractInfoWithAgreements = _.find(this.props.agreement.byContractInfo, row => row.id === building.contractInfoId);
        const agreements = contractInfoWithAgreements ? contractInfoWithAgreements.agreements : [];
        if(selectedBuilding) {
            const disciplines = selectedBuilding.disciplines;
            return (
                <StandardPage withBreadcrumb>
                    <BreadcrumbBar
                        page="building"
                        contractInfo={ contractInfo }
                        actionUrl={ `/client/${params.clientId}/contractInfo/${params.contractInfoId}/building/${params.buildingId}/details`}
                        linkName={ t('show_building_details') }
                        linkId="BuildingOverviewView_buildingDetailsButton"
                        building={ selectedBuilding }
                    />
                    { params.clientId && contractInfo.id && building.agreementId &&
                        <BackLink title={t('Back to agreement')}
                            to={`/client/${params.clientId}/contractInfo/${contractInfo.id}/agreement/${building.agreementId}/`}/>
                    }
                    <header style={ { ...commonStyles.bottomDivider, marginBottom: measures.standard } }>
                        <h1 style={ commonStyles.pageTitle }>{ t('disciplines') }</h1>
                        <div style={ buttonLayout }>
                            <CreateDisciplineButton
                                id="BuildingOverviewView_createDisciplineButton"
                                clientId={ client.id }
                                contractInfoId={contractInfo.id}
                                buildingId={ selectedBuilding.id }
                                style={ buttonLayout }/>
                        </div>
                    </header>
                    <DisciplineList
                        disciplines={ disciplines }
                        building={ selectedBuilding }
                        contractInfo = {contractInfo }
                        client = { client }
                        handleDisciplineDelete = { this.handleDisciplineDelete }
                        agreements = { agreements }
                    />
                    { params.clientId && contractInfo.id && building.agreementId &&
                        <div style={ [ layout.floatRight, layout.inlineBlock ] }>
                            <span style={ [{ marginRight : measures.standard }] }>
                                <CloseButton
                                    id="BuildingOverviewView_close"
                                    path={`/client/${params.clientId}/contractInfo/${contractInfo.id}/agreement/${building.agreementId}/`}
                                />
                            </span>
                        </div>
                    }
                </StandardPage>
            );
        }
        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar
                    actionUrl={ `/client/${params.clientId}`}
                    linkName={ t('show_building_details') }
                />
                { t('building_not_found') }
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        agreement: state.agreement,
        buildingState: state.building,
        disciplineState: state.discipline,
        profile: state.userProfile,
        contractInfo: state.contractInfo,
        client: state.client,
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(BuildingOverviewView)));