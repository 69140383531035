import React from 'react';
import PropTypes from 'prop-types';
import Dropzone                 from 'react-dropzone';
import translations from 'decorators/translations.js';
import configuredRadium         from 'configuredRadium.js';

import {
    ConfirmableButton,
    LoadingIndicator
} from 'components';


import {
    measures,
    layout,
    colors
}                               from 'styles/design.js';

const styling = {
    style: {
        width: '95%',
        margin: measures.standard,
        padding: measures.standard,
        border: '1px dashed'
    },
    activeStyle: {
        backgroundColor: '#eee',
        border: '1px dashed'
    },
    rejectStyle: {
        backgroundColor: '#ffdddd',
        border: '1px dashed'
    }
};

class FileDetails extends React.Component {

    static propTypes = {
        language: PropTypes.string.isRequired,
        filename: PropTypes.string.isRequired,
        uploadFile: PropTypes.func.isRequired,
        downloadFile: PropTypes.func.isRequired,
        deleteFile: PropTypes.func.isRequired,
        exist: PropTypes.bool,
        loading: PropTypes.bool
    };

    onDrop = (files, filename) => {
        this.props.uploadFile(
            files[0],
            filename
        );
    };
    render() {
        const { language, filename, exist, loading, t } = this.props;
        return (
            <div>
                <div style={[{ padding: measures.standard }]}>
                    {t('frame_terms_file') + ' - ' + t(language)}
                </div>

                <div style={[{ padding: measures.standard }]}>

                    {
                        (() => {
                            if(loading) {
                                return <LoadingIndicator visible={true} />
                            } else if (exist) {
                                return (
                                    <div>
                                        <div style={[layout.floatRight]}>
                                            <ConfirmableButton
                                                style={{backgroundColor: colors.red}}
                                                buttonIcon = { 'fa fa-trash' }
                                                confirmButtonText = { this.props.t('yes') }
                                                confirmText = { this.props.t('confirm_delete_file') }
                                                action = { (event) => this.props.deleteFile(event, filename) }
                                            />
                                        </div>
                                        <div style={{cursor: 'pointer'}} onClick={(event) => this.props.downloadFile(event, filename)}>
                                            <i style={ { fontSize: '2.2em' } }
                                               className={ 'fa fa-file-text-o' } /> {filename}
                                        </div>
                                    </div>
                                );
                            }
                            return (
                                <span
                                    style={{ color: colors.gray }}>
                                    {t('file_not_found')}
                                </span>);
                        })()
                    }
                </div>

                <div>
                    <Dropzone
                        onDrop={(files) => this.onDrop(files, filename)}
                        accept={'application/pdf'}
                        multiple={false}
                        style={ styling.style }
                        activeStyle={ styling.activeStyle }
                        rejectStyle={styling.rejectStyle}>
                        <div>{t('file_upload_info')}</div>
                        <LoadingIndicator visible={ loading } />
                    </Dropzone>
                </div>
            </div>
        );
    }
}

export default translations(configuredRadium(FileDetails));