import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import translations from 'decorators/translations.js';
import {
    withRouter
} from 'react-router-dom';

import {
    isAgreementLoaded,
    loadAgreement,
    loadAHUTypes
} from 'redux/modules/agreement';

import { loadContractInfo, isContractInfoLoaded } from 'redux/modules/contractInfo';

// import {loadDisciplineMetadata} from 'redux/modules/discipline.js';
import { loadAllDisciplineConfigs } from 'redux/modules/disciplineConfig';


import {
    loadCountry,
    isCountryLoaded
} from 'redux/modules/country.js';

import {
    loadCollectors,
    updateCollector,
    upsertSensor,
    addBundleForCollector,
    loadBundles,
    updateKitpackBundle,
    deleteBundle,
    deleteKitpack
} from 'redux/modules/kitpack';

import CollectorForm from './Collectors/CollectorForm';

import {
    isEditable
} from 'helpers/agreement.js';

import {
    StandardPage,
    Button,
    SFSelectField,
    BackLink,
    ConfirmableButton,
    BreadcrumbBar,
    Dialog,
    LoadingIndicator
} from 'components/index';

import _ from 'lodash';

import SensorForm from './Collectors/SensorForm';

import {
    download
}   from 'redux/modules/download.js';

import './CollectorView.css';
import BundleList from '../../../components/Bundle/BundleList';
import BundleForm from './Collectors/BundleForm';
import CostSummary from '../../../components/CostSummary/CostSummary';

class CollectorView extends React.Component {
    state = {};
    static propTypes = {
        t: PropTypes.func.isRequired,
        kitpack: PropTypes.object.isRequired
    };

    componentWillMount() {
        const promises = [];
        const params = this.props.match.params;
        if (!isAgreementLoaded(this.props.agreement, _.get(this.props, 'match.params.agreementId', null))) {
            this.props.loadAgreement(params.agreementId, params.contractInfoId, this.props.profile.accessToken).then((res) => {
                if(res.result) {
                    const agreement = res.result;
                    if(!this.props.kitpack.bundles || _.isEmpty(this.props.kitpack.bundles)) {
                        this.loadKitpackBundles(agreement.contractInfo.salesArea);
                    }
                    if(!isCountryLoaded(this.props, agreement.contractInfo.salesArea)) {
                        this.props.loadCountry(agreement.contractInfo.salesArea, this.props.profile.accessToken);
                    }
                }
                return res;
            })
        }
        if (!isContractInfoLoaded(this.props, this.props.match.params.contractInfoId)) {
            promises.push(this.props.loadContractInfo(this.props.match.params.contractInfoId, this.props.profile.accessToken));
        }
        Promise.all(promises).then(res => {
            const cInfo = _.get(this.props, 'contractInfo.contractInfo');
            this.loadKitpackBundles(cInfo.salesArea);
            if(!isCountryLoaded(this.props, cInfo.salesArea)) {
                this.props.loadCountry(cInfo.salesArea, this.props.profile.accessToken);
            }
        });

        if (!this.props.discipline.loaded || _.isEmpty(this.props.discipline.disciplineMetadata)) {
            // this.props.loadDisciplineMetadata(null, this.props.profile.accessToken);
            this.props.loadAllDisciplineConfigs(null, null, this.props.profile.accessToken);
        }
        if (!this.props.kitpack.collectors || this.props.kitpack.collectors.length === 0 || (this.props.kitpack.collectors.length > 0 && _.first(this.props.kitpack.collectors).id !== this.props.match.params.id)) {
            this.props.loadCollectors({agreementId: this.props.match.params.agreementId}, this.props.profile.accessToken);
        }
        if (!this.props.agreement.ahuTypes || _.isEmpty(this.props.agreement.ahuTypes)) {
            this.props.loadAHUTypes(this.props.profile.accessToken);
        }
    }

    loadKitpackBundles = (countryId) => {
        this.props.loadBundles(countryId, this.props.profile.accessToken);
    }

    addSensor = () => {
        this.setState({newSensor: true});
    };

    cancelSensorForm = () => {
        this.setState({newSensor: false, editSensor: false});
    };
    submitSensor = (values) => {
        values.kitPackId = this.props.match.params.collectorId;
        this.props.upsertSensor(values, this.props.profile.accessToken).then((res) => {
            if (res.result) {
                this.props.loadCollectors({agreementId: this.props.match.params.agreementId}, this.props.profile.accessToken);
                this.setState({newSensor: false, editSensor: false});
            } else {
                this.setState({error: res.error});
            }
        });
    };

    editBundleDetails = ({bundleId, details}) => {
        if (bundleId && details && details.length > 0) {
            details.forEach((detail, idx) => {
                const detailId = detail.id;
                const value = detail.value;
                if (bundleId && detailId) {
                    const data = {
                        [detailId]: value
                    };
                    this.props.updateKitpackBundle(bundleId, data, this.props.profile.accessToken).then((res) => {
                        if (res.result && idx === details.length - 1) {
                            this.props.loadCollectors({agreementId: this.props.match.params.agreementId}, this.props.profile.accessToken);
                        }
                    });
                }
            });
        }
    };

    editBundleDetail = ({bundleId, detailId, value}) => {
        if (bundleId && detailId) {
            const data = {
                [detailId]: value
            };
            this.props.updateKitpackBundle(bundleId, data, this.props.profile.accessToken).then((res) => {
                if (res.result) {
                    this.props.loadCollectors({agreementId: this.props.match.params.agreementId}, this.props.profile.accessToken);
                }
            });
        }
    };

    getAgreementDisciplines = () => {
        const agreement = this.props.agreement.agreement;
        if (!agreement || !agreement.disciplines) {
            return [];
        }
        const final = {};
        agreement.disciplines.map((discipline) => {
            if (!final[discipline.disciplineCategory]) {
                final[discipline.disciplineCategory] = [];
            }
            const exists = _.find(final[discipline.disciplineCategory], {disciplineType: discipline.disciplineType});
            if (!exists) {
                final[discipline.disciplineCategory].push({
                    disciplineCategory: discipline.disciplineCategory,
                    disciplineType: discipline.disciplineType,
                    disciplineSystems: _.map(discipline.disciplineSystems, (sys) => ({
                        id: sys.id,
                        systemId: sys.systemID,
                        equipmentType: sys.equipmentType,
                        buildingId: discipline.buildingId
                    }))
                });
            } else {
                exists.disciplineSystems = exists.disciplineSystems.concat(
                    _.map(discipline.disciplineSystems, (sys) => ({
                        id: sys.id,
                        systemId: sys.systemID,
                        equipmentType: sys.equipmentType,
                        buildingId: discipline.buildingId
                    }))
                );
            }
        });
        return final;
    };
    getDisciplines = () => {
        const disciplineMetadata = this.props.discipline.disciplineMetadata;
        if (!disciplineMetadata) {
            return [];
        }
        return _.groupBy(disciplineMetadata, 'disciplineCategory');
    };
    submitUpdate = (values) => {
        this.props.updateCollector(values, this.props.profile.accessToken).then((res) => {
            if (res.result) {
                this.props.loadCollectors({agreementId: this.props.match.params.agreementId}, this.props.profile.accessToken).then((res) => {
                    if (res.result) {
                        this.setState({editCollector: false});
                    }
                });
            }
        });
    };
    toggleEditSensor = (sensorId, bundleId) => {
        this.setState((state) => ({...state, editSensor: sensorId || null, bundleId: bundleId}));
    };
    setValue = (key, value) => {
        this.setState((state) => {
            state[key] = value;
            return {
                ...state
            }
        });
    };

    addAHUForCollector = (id) => {
        this.addBundleForCollector(id);
    };

    addBundleForCollector = (id) => {
        const bundleId = id || this.state.bundleId;
        if(bundleId) {
            const collectorId = this.props.match.params.collectorId;
            this.props.addBundleForCollector(collectorId, bundleId, this.props.profile.accessToken).then((res) => {
                if(res.result) {
                    const bundleId = _.get(res, 'result.id', null);
                    this.props.loadCollectors({agreementId: this.props.match.params.agreementId}, this.props.profile.accessToken).then((res) => {
                        this.setState({
                            newBundleAdding: true
                        });
                        // set timeout to visualize bundle adding before config wizard starts
                        setTimeout(() => {
                            this.setState({
                                newBundleAdding: false
                            });
                            this.openBundleSensorEditor(bundleId);
                        }, 600);
                    });
                }
            })
        }
    };

    openBundleSensorEditor = (bundleId) => {
        this.setState({
            editBundleSensors: true,
            editBundleSensorsId: bundleId
        });
    };

    closeBundleSensorEditor = () => {
        this.setState({
            editBundleSensors: false,
            editBundleSensorsId: null
        });
    };

    hasVentilationDiscipline = (disciplines) => {
        return _.find(disciplines, ((discipline) => discipline.disciplineCategory === 'ventilation_and_air_conditioning' && discipline.disciplineSystems.length > 0));
    }

    filterBundles = (bundles, agreement) => {
        const collectorBundles = this.getCollector() ? this.getCollector().bundles : [];
        return _.filter(bundles, (bundle) => {
            if(bundle.visible) {
                if (bundle.type === 'kitpack') {
                    //Won't allow adding kitpack if there are no ventilation discipline;
                    if (!this.hasVentilationDiscipline(agreement.disciplines)) {
                        return false;
                    }
                    return !_.find(collectorBundles, (collectorBundle) => collectorBundle.pricing && (collectorBundle.pricing.type === 'kitpack' || collectorBundle.pricing.type !== 'receiver'))
                } else if (bundle.type !== 'receiver') {
                    return true;
                }
            }

        });
    }

    downloadFile = (downloadType, type, id, lang) => {
        this.props.downloadFile(downloadType, type, id, null, lang, this.props.profile.accessToken);
        return false;
    };

    getCollector = () => {
        return _.find(this.props.kitpack.collectors, {id: parseInt(this.props.match.params.collectorId, 10)});
    }

    deleteBundle = (bundleId) => {
        this.props.deleteBundle(bundleId, this.props.profile.accessToken).then((res) => {
            if (res.result) {
                this.props.loadCollectors({agreementId: this.props.match.params.agreementId}, this.props.profile.accessToken);
            }
        });
    }

    deleteCollector = () => {
        this.props.deleteKitpack(this.getCollector().id, this.props.profile.accessToken).then((res) => {
            if (res.result) {
                const params = this.props.match.params;
                this.props.history.push(`/client/${params.clientId}/contractInfo/${params.contractInfoId}/agreement/${params.agreementId}/collectors`);
            }
        });
    }

    openNewBundleSelector = (bundles) => {
        this.setState({
            addBundleSelector: true
        });
    }

    handleNewBundleCancel = () => {
        this.setState({
            addBundleSelector: false
        });
    }

    handleNewBundleAdd = () => {
        this.setState({
            addBundleSelector: false
        });
        this.addBundleForCollector();
    }

    onStepChange = () => {
        const el = this.editDialog;
        el.scrollDialogToTop();
    }

    toggleCostSummary = () => {
        this.setState({
            showCostSummary: !this.state.showCostSummary
        });
    }

    render() {
        const {t} = this.props;
        const collector = this.getCollector() || {};
        const allSensors = _.merge(_.flatten(_.map(collector.bundles, 'sensors')));
        const params = this.props.match.params;

        const agreement = _.get(this.props, 'agreement.agreement', {});
        const isAgreementEditable = isEditable(agreement);
        const bundleOptions = this.filterBundles(this.props.kitpack.bundles, agreement);
        const sensorBundles = _.filter(this.props.kitpack.bundles, {type: 'sensor'});
        const systems = agreement ?
            _.merge(
                _.flatten(
                    _.map(agreement.disciplines, discipline => {
                        return _.map(discipline.disciplineSystems, system => {
                            //Add details to system to be able to show that which building the system belongs to, and under which discipline it is.
                            system.disciplineCategory = discipline.disciplineCategory;
                            system.buildingId = discipline.buildingId;
                            return system;
                        })
                    })
                )
            ) : [];
        const ventilationSystems = _.filter(systems, {disciplineCategory: 'ventilation_and_air_conditioning'});
        const electricitySystems = _.filter(systems, {disciplineCategory: 'electricity'});
        let ventilationsAvailable = false;

        systems.map( system => {
            const systemUsed = _.findIndex(allSensors, {disciplineSystemId: system.id});
            system.available = systemUsed === -1;
        });
        ventilationSystems.map( system => {
            const systemUsed = _.findIndex(allSensors, {disciplineSystemId: system.id});
            system.available = systemUsed === -1;
            if (system.available) {
                ventilationsAvailable = true;
            }
        });

        let bundles = [];
        if (collector && collector.bundles) {
            bundles = collector.bundles;
        }

        const country = this.props.countries.selectedCountry || {};
        const pricing = collector.pricing;
        const totals = pricing && pricing.totals ? pricing.totals : null;

        return (
            <StandardPage
                withBreadcrumb
                loaderVisible={this.props.kitpack.loading || this.props.kitpack.saving}
            >
                <BreadcrumbBar
                    page="agreement"
                    agreement={agreement}
                    contractInfo={this.props.contractInfo.contractInfo}
                    actionUrl={'/client/' + this.props.match.params.clientId + '/details'}
                    linkName={t('show_client_details')}
                    lockedText={!isEditable(agreement) ? t('locked') : null}
                />
                {
                    (() => {
                        if (this.state.editCollector) {
                            return (
                                <CollectorForm
                                    agreement={agreement}
                                    collector={collector}
                                    submit={this.submitUpdate}
                                    submitting={this.props.kitpack.saving}
                                    mainCollectorAvailable={collector.type === 'main_collector'}
                                    cancel={() => this.setState((state) => ({...state, editCollector: false}))}
                                />);
                        } else if (this.state.newSensor || this.state.editSensor) {
                            const sensor = _.find(_.find(collector.bundles, {id: this.state.bundleId}).sensors, {id: this.state.editSensor});
                            return (<SensorForm
                                systemsByDiscipline={{ventilation: ventilationSystems, electricity: electricitySystems}}
                                sensor={sensor}
                                ahuTypes={this.props.agreement.ahuTypes}
                                agreement={this.props.agreement.agreement}
                                cancel={this.cancelSensorForm}
                                submit={this.submitSensor}
                                disciplines={this.getDisciplines()}
                                agreementDisciplines={this.getAgreementDisciplines()}
                                systems={_.filter(systems, {available: true})}
                                types={this.props.kitpack.sensorTypes || []}
                            />);
                        }
                        return (
                            <div className={ this.state.newBundleAdding && 'collector-new-bundle-add-active' }>
                                <div>
                                    <BackLink
                                        id="CollectorView_backToCollectors"
                                        title={t('Back to agreement')}
                                        to={`/client/${params.clientId}/contractInfo/${params.contractInfoId}/agreement/${params.agreementId}/summary`}
                                        />
                                    <div className="collector-header">
                                        <h2 className="displayInlineBlock">
                                            {t('Collector')}
                                        </h2>
                                        {isAgreementEditable && <div className="displayInline">
                                            <Button
                                                size="small"
                                                id="CollectorView_edit"
                                                onClick={() => this.setState((state) => ({
                                                    ...state,
                                                    editCollector: true,
                                                    newSensor: false
                                                }))}>
                                                {t('edit')}
                                            </Button>
                                        </div>}
                                        {isAgreementEditable && <div className="collector-delete">
                                            <ConfirmableButton
                                                size="small"
                                                action={this.deleteCollector}
                                                buttonText={t('Delete collector')}
                                                confirmText={t('are_you_sure') + '?'}
                                                variant="delete"
                                            />
                                        </div>}
                                    </div>

                                     <BundleList
                                        t={t}
                                        bundles={bundles}
                                        pricing={pricing}
                                        handleSensorEdit={this.toggleEditSensor}
                                        handleBundleDetailEdit={this.editBundleDetail}
                                        country={country}
                                        handleBundleDelete={this.deleteBundle}
                                        isEditable={isAgreementEditable}
                                    />

                                    { bundleOptions.length > 0 && isAgreementEditable &&
                                        <div className="collector-add-bundle">
                                            { ventilationsAvailable &&
                                                <a
                                                    href=""
                                                    onClick={e => {e.preventDefault(); this.addAHUForCollector(_.find(bundleOptions, {type: 'ahu'}).id); }}
                                                    className="collector-add-bundle-button"
                                                >
                                                    <i className="fa fa-plus" /> { t('New air handling unit') }
                                                </a>
                                            }
                                            <a
                                                href=""
                                                onClick={e => {e.preventDefault(); this.openNewBundleSelector(_.filter(bundleOptions, (bundle => bundle.type === 'sensor'))) }}
                                                className="collector-add-bundle-button"
                                            >
                                                <i className="fa fa-plus" /> { t('New sensor') }
                                            </a>
                                            { this.state.addBundleSelector &&
                                                <Dialog
                                                    actions={[
                                                    {
                                                        onClick: this.handleNewBundleCancel,
                                                        title: t('cancel'),
                                                        variant: 'link'
                                                    },{
                                                        onClick: this.handleNewBundleAdd,
                                                        title: t('Add'),
                                                        variant: 'primary'
                                                    }]}
                                                >
                                                    <div className="collector-add-bundle-selector">
                                                        <SFSelectField
                                                            defaultInput={true}
                                                            onChange={(event) => this.setValue('bundleId', event.target.value)}
                                                            value={this.state.bundleId}
                                                            fieldName={t('Select type of sensor')}>
                                                            <option value={null}>{t(sensorBundles.length > 0 ? 'select' : 'none')}</option>
                                                            {
                                                                sensorBundles.map((row) =>
                                                                    <option key={row.id} value={row.id}>
                                                                        {row.name}
                                                                    </option>
                                                                )
                                                            }
                                                        </SFSelectField>
                                                    </div>
                                                </Dialog>
                                            }
                                        </div>
                                    }
                                    { this.state.editBundleSensors &&
                                        <Dialog disableBodyScroll size="xl" ref={node => { this.editDialog = node; }}>
                                            { collector
                                                && collector.bundles
                                                && collector.bundles.length > 0
                                                && _.find(collector.bundles, {id: this.state.editBundleSensorsId})
                                                && <BundleForm
                                                    systemsByDiscipline={{ventilation: ventilationSystems, electricity: electricitySystems}}
                                                    sensors={_.find(collector.bundles, {id: this.state.editBundleSensorsId}).sensors}
                                                    ahuTypes={this.props.agreement.ahuTypes}
                                                    agreement={this.props.agreement.agreement}
                                                    cancel={this.cancelSensorForm}
                                                    submit={this.submitSensor}
                                                    disciplines={this.getDisciplines()}
                                                    agreementDisciplines={this.getAgreementDisciplines()}
                                                    types={this.props.kitpack.sensorTypes || []}
                                                    onClose={this.closeBundleSensorEditor}
                                                    onStepChange={this.onStepChange}
                                                />
                                            }
                                        </Dialog>
                                    }

                                    {bundles && bundles.length > 0 && totals &&
                                        <div style={{ margin: '2em 0' }}>
                                            {!this.state.showCostSummary
                                                ? <div className="bundle-totals">
                                                    <h2>{t('Totals')}</h2>
                                                    <CostSummary
                                                        costs={[
                                                            {
                                                                data: [
                                                                    {
                                                                        label: t('Yearly price'),
                                                                        value: totals.sums.customerYearlyPrice
                                                                    }
                                                                ]
                                                            }
                                                        ]}
                                                        summaryTitle={t('Customer prices')}
                                                        currency={country.currency}
                                                    />
                                                </div>
                                                : <div className="bundle-totals">
                                                    <h2>{t('Totals')}</h2>
                                                    <CostSummary
                                                        costs={[
                                                            {
                                                                title: t('Branch office'),
                                                                data: [
                                                                    {
                                                                        label: t('Installation'),
                                                                        value: totals.branchOffice.installationCost
                                                                    },
                                                                    {
                                                                        label: t('Yearly cost'),
                                                                        value: totals.branchOffice.yearlyCost,
                                                                    },
                                                                    {
                                                                        label: t('Yearly equipment cost'),
                                                                        value: totals.branchOffice.yearlyEquipmentCost
                                                                    },
                                                                ]
                                                            },
                                                            {
                                                                title: t('Remote center'),
                                                                data: [
                                                                    {
                                                                        label: t('Installation'),
                                                                        value: totals.remoteCenter.installationCost
                                                                    },
                                                                    {
                                                                        label: t('Yearly cost'),
                                                                        value: totals.remoteCenter.yearlyCost
                                                                    }
                                                                ]
                                                            },{
                                                                title: t('Caverion costs'),
                                                                data: [
                                                                    {
                                                                        label: t('Equipment'),
                                                                        value: totals.sums.equipment
                                                                    },
                                                                    {
                                                                        label: t('First year'),
                                                                        value: totals.sums.firstYear
                                                                    },
                                                                    {
                                                                        label: t('Costs after first year'),
                                                                        value: totals.sums.yearlyPricesTotal
                                                                    },
                                                                    {
                                                                        label: t('Total cost'),
                                                                        value: totals.sums.costsTotal
                                                                    }
                                                                ]
                                                            }
                                                        ]}
                                                        summaryTitle={t('cost_summary')}
                                                        currency={country.currency}
                                                    />
                                                    <CostSummary
                                                        costs={[
                                                            {
                                                                data: [
                                                                    {
                                                                        label: t('margin'),
                                                                        value: totals.sums.projectMargin,
                                                                        percentage: true
                                                                    },
                                                                    {
                                                                        label: t('Customer price'),
                                                                        value: totals.sums.customerPrice
                                                                    },
                                                                    {
                                                                        label: t('Yearly price'),
                                                                        value: totals.sums.customerYearlyPrice,
                                                                        secondary: true
                                                                    },
                                                                    {
                                                                        label: t('Monthly price'),
                                                                        value: totals.sums.customerMonthlyPrice,
                                                                        secondary: true
                                                                    },
                                                                    {
                                                                        label: t('Upfront price'),
                                                                        value: totals.sums.upfrontCost
                                                                    },
                                                                ]
                                                            }
                                                        ]}
                                                        costsTotalLabel={t('Total customer price')}
                                                        costsTotalValue={totals.sums.customerTotalSalesPrice}
                                                        summaryTitle={t('Customer prices')}
                                                        currency={country.currency}
                                                    />
                                                </div>
                                            }
                                            <Button onClick={this.toggleCostSummary}>{t('Show full cost summary')}</Button>
                                        </div>
                                    }

                                    <Button
                                        onClick={() => this.downloadFile('print', 'kitpack', collector.id, 'en')}
                                    >
                                        <LoadingIndicator visible={this.props.download.loading}/>
                                        <i className="fa fa-print"/> {t('print_for_remote_center')}
                                    </Button>

                                    { /*
                                        bundleOptions.length > 0 &&
                                        <div>
                                            <div className="displayInlineBlock">
                                                <SFSelectField
                                                    defaultInput={true}
                                                    onChange={(event) => this.setValue('bundleId', event.target.value)}
                                                    value={this.state.bundleId}
                                                    fieldName={t('Add bundle to collector')}>
                                                    <option value={null}>{t(bundleOptions.length > 0 ? 'select' : 'none')}</option>
                                                    {
                                                        bundleOptions.map((row) => <option key={row.id} value={row.id}>{row.name}</option>)
                                                    }
                                                </SFSelectField>
                                            </div>
                                            <div className="displayInline">
                                                <Button
                                                    onClick={this.addBundleForCollector}
                                                >
                                                    {t('Add')}
                                                </Button>
                                            </div>
                                            {
                                                !this.hasVentilationDiscipline(agreement.disciplines) && <div className="add-bundle-selection-error">{t('kitpack_error_no_ventilation_discipline')}</div>
                                            }
                                        </div>
                                        */ }
                                </div>
                            </div>
                        );
                    })()
                }

            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        download: state.download,
        agreement: state.agreement,
        discipline: state.discipline,
        kitpack: state.kitpack,
        countries: state.countries,
        client: state.client,
        user: state.userProfile.user,
        contractInfo: state.contractInfo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadAgreement: (agreementId, contractInfoId, accessToken) => dispatch(loadAgreement(agreementId, contractInfoId, accessToken)),
        loadCollectors: (filters, accessToken) => dispatch(loadCollectors(filters, accessToken)),
        upsertSensor: (data, accessToken) => dispatch(upsertSensor(data, accessToken)),
        updateCollector: (data, accessToken) => dispatch(updateCollector(data, accessToken)),
        //loadDisciplineMetadata: (type, accessToken) => dispatch(loadDisciplineMetadata(type, accessToken)),
        loadAllDisciplineConfigs: (countryId, type, accessToken) => dispatch(loadAllDisciplineConfigs(countryId, type, accessToken)),
        loadAHUTypes: (accessToken) => dispatch(loadAHUTypes(accessToken)),
        addBundleForCollector: (collectorId, bundleId, accessToken) => dispatch(addBundleForCollector(collectorId, bundleId, accessToken)),
        loadBundles: (countryId, accessToken) => dispatch(loadBundles(countryId, accessToken)),
        updateKitpackBundle: (bundleId, data, accessToken) => dispatch(updateKitpackBundle(bundleId, data, accessToken)),
        loadCountry: (salesArea, accessToken) => dispatch(loadCountry(salesArea, accessToken)),
        deleteBundle: (bundleId, accessToken) => dispatch(deleteBundle(bundleId, accessToken)),
        deleteKitpack: (bundleId, accessToken) => dispatch(deleteKitpack(bundleId, accessToken)),
        loadContractInfo: (clientId, accessToken) => dispatch(loadContractInfo(clientId, accessToken)),
        downloadFile: (downloadType, type, id, appendix, lang, accessToken) => dispatch(download({downloadType, type, id, appendix, lang}, accessToken))

    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default withRouter(translations(connector(CollectorView)));