import React, {Component}       from 'react';
import translations from 'decorators/translations.js';
import { connect }              from 'react-redux';
import configuredRadium         from 'configuredRadium.js';

import {
    StandardPage,
    BreadcrumbBar,
    AdminSubNavigation,
    TranslationForm
} from 'components';

import {
    measures,
    commonStyles
} from 'styles/design.js';

import { loadCountry } from 'redux/modules/country.js';
import { loadLanguages, loadWithType } from 'redux/modules/translations.js';
import _ from 'lodash';

class MainAgreementConfigurationView extends Component {

    componentWillMount() {
        const { dispatch, match: {params}} = this.props;
        const promises = [];
        if(!this.props.countries.loaded || this.props.countries.selectedCountry.id !== params.countryId) {
            promises.push(dispatch(loadCountry(params.countryId, this.props.profile.accessToken)));
        }
        if(!this.props.translations.loaded) {
            promises.push(dispatch(loadLanguages(this.props.profile.accessToken)));
        }
        return Promise.all(promises).then(() => {
            if(this.props.selectedCountry) {
                this.props.dispatch(loadWithType(2, 'en,' + this.props.selectedCountry.defaultLanguage, this.props.selectedCountry.id, this.props.profile.accessToken));
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.translations.loading && nextProps.translations.loaded) {
            this.setState({translationsLoaded:true, editing: null});
        }
        if(this.props.translations.saving && nextProps.translations.saved) {
            this.setState({savingTranslation: false, loadingTranslation: true});
            this.props.dispatch(loadWithType(2, 'en,' + this.props.selectedCountry.defaultLanguage, this.props.selectedCountry.id, this.props.profile.accessToken));
        } else if(this.props.translations.saving && !nextProps.translations.saving) {
            //Notify user about failure.
        }
    }

    render() {
        const {
            t
        } = this.props;
        const localizables = _.filter(Object.keys(this.props.translations.translatables), (key) => {
            if (_.startsWith(key, 'mainagreement_terms')) {
                return false;
            }
            if (
                key.indexOf('mainagreement_') !== -1 &&
                this.props.translations.translatables[key]['en-master']
            ) {
                return key;
            }
        });
        const addonTranslations = _.filter(Object.keys(this.props.translations.translatables), (key) => key.indexOf('addonagreement_') !== -1);
        const commonTermsTranslations = ['mainagreement_terms_p1'];

        return (
            <StandardPage withBreadcrumb withSideNavigation>
                <BreadcrumbBar
                    page="admin"
                    path={this.props.location.pathname}
                />
                <AdminSubNavigation params={this.props.match.params} activeNavi="mainAgreementConfig" />
                <header>
                    <h1 style = { commonStyles.pageTitle } >{ this.props.t(this.props.match.params.countryId) }</h1>
                </header>
                <div style = {{ marginTop: measures.standard }}>
                    <h2>{t('main_agreement')}</h2>
                    <TranslationForm
                        translations={this.props.translations.translatables}
                        localizables={localizables}
                        country={this.props.selectedCountry}
                    />
                </div>
                <div style = {{ marginTop: '2em' }}>
                    <h2>{t('addon')}</h2>
                    <TranslationForm
                        localizables={addonTranslations}
                        country={this.props.selectedCountry}
                        translations={this.props.translations.translatables}
                    />
                </div>
                <div style = {{ marginTop: '2em' }}>
                    <h2>{t('Common terms')}</h2>
                    <TranslationForm
                        localizables={commonTermsTranslations}
                        country={this.props.selectedCountry}
                        translations={this.props.translations.translatables}
                    />
                </div>
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        countries: state.countries,
        clientError: state.client.error,
        selectedCountry: state.countries.selectedCountry,
        countryShouldReload: state.countries.reload,
        countryError: state.countries.error,
        languages: state.translations.languages,
        profile: state.userProfile,
        translations: state.translations
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(MainAgreementConfigurationView)));