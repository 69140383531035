import React from 'react';
import {
    Card
} from 'components';

const Collector = ({clientId, contractInfoId, agreementId, collector, t, idx}) => {
    return (
        <Card
            id={'Collector_Card_' + idx}
            to={'/client/' + clientId + '/contractInfo/' + contractInfoId + '/agreement/' + agreementId + '/collectors/' + collector.id}
            title={collector.name || t('Collector')}
            ctas={[{
                title: t('edit')
            }]}
        >
            {
                collector.bundles.map((bundle, idx) => <div key={'bundle_' + idx} className="collectors-item">{bundle.pricing.name}</div>)
            }
        </Card>
    );
}

export default Collector;