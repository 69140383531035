import React, {Component} from 'react';
import {reduxForm}                    from 'redux-form';
import {decimalRound} from 'helpers/stringTemplates.js';

import {
    layout,
    measures
}                         from 'styles/design.js';

import {
    SFInputField,
    SFTextareaField,
    Button
}                       from 'components';

const tableTd = {
    padding: measures.half
};

const tableTdHeader = {
    padding: measures.half
};

const reduxFormObject = {
    form: 'FrameAgreementPricingForm',
    fields: [
        'disciplineCategory',
        'description',
        'pricing[].name',
        'pricing[].nameEnglish',
        'pricing[].basicCharge',
        'pricing[].overtime50',
        'pricing[].overtime100'
    ]
};

class FrameAgreementPricingConfigForm extends Component {

    componentWillMount() {
        const discipline = this.props.discipline;
        if(!discipline.pricing) {
            discipline.pricing = [];
        }
        if(!discipline.description) {
            discipline.description = '';
        }
        this.props.initializeForm(discipline);
    }

    handleAddPricing = () => {
        this.props.fields.pricing.addField();
    };

    deletePricingRow = (index) => {
        this.props.fields.pricing.removeField(index);
    };
    calculateOvertime(price, multiplier, currency) {
        const result = decimalRound(price * (multiplier ? multiplier : 1), currency);
        return result;
    }
    render() {
        const {t, discipline, fields, countryConfig, submittingPricing} = this.props;
        return (
            <div style = {{overflow: 'auto'}}>
                <table cellSpacing = "0" cellPadding = "0" style = {{fontSize: '0.9em'}}>
                    <thead>
                    <tr>
                        <td style = {tableTdHeader}>
                            {t('name')}
                        </td>
                        <td style = {tableTdHeader}>
                            {t('name')} ({t('en')})
                        </td>
                        <td style = {tableTdHeader}>
                            {t('basic_charge')} ({countryConfig.currency})
                        </td>
                        <td style = {tableTdHeader}>
                            {t('overtime_50')} ({countryConfig.currency})
                        </td>
                        <td style = {tableTdHeader}>
                            {t('overtime_100')} ({countryConfig.currency})
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        (() => {
                            return fields.pricing.map((priceRow, index) => {
                                const overtime50value = this.calculateOvertime(priceRow.basicCharge.value, countryConfig.frameAgreementConfig.overtime50multiplier, countryConfig.currency);
                                const overtime100value = this.calculateOvertime(priceRow.basicCharge.value, countryConfig.frameAgreementConfig.overtime100multiplier, countryConfig.currency);
                                return (
                                    <tr key = {'discipline_pricing_' + discipline.disciplineCategory + '_' + index }>
                                        <td style = {tableTd}>
                                            <SFInputField formProperty = {priceRow.name} />
                                        </td>
                                        <td style = {tableTd}>
                                            <SFInputField formProperty = {priceRow.nameEnglish} />
                                        </td>
                                        <td style = {tableTd}>
                                            <SFInputField formProperty = {priceRow.basicCharge} />
                                        </td>
                                        <td style = {tableTd}>
                                            <input type = "hidden" />
                                            {overtime50value}
                                        </td>
                                        <td style = {tableTd}>
                                            <input type = "hidden" />
                                            {overtime100value}
                                        </td>
                                        <td>
                                            <Button variant="delete" onClick={() => this.deletePricingRow(index)}>
                                                <i className="fa fa-trash" />
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            });
                        })()
                    }
                    <tr>
                        <td colSpan="3">
                            <Button onClick={ this.handleAddPricing }>
                                <i className="fa fa-plus" /> { t('add_pricing_row') }
                            </Button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div style = {{overflow: 'auto', marginBottom: measures.standard}}>
                    <div style={{ ...layout.floatRight, ...layout.inlineBlock, clear: 'both' }}>
                            <Button variant="cancel" onClick={this.props.cancelAction}>
                                {t('cancel')}
                            </Button>
                            <Button onClick={this.props.handleSubmit} disabled={submittingPricing} loading={submittingPricing}>
                                {t('save')}
                            </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm(reduxFormObject)(FrameAgreementPricingConfigForm);
