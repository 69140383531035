import React, {Component}   from 'react';
import translations from 'decorators/translations.js';
import { connect }          from 'react-redux';
import configuredRadium     from 'configuredRadium.js';
import _                    from 'lodash';
import {
    StandardPage,
    BreadcrumbBar,
    CostCalculation,
    SAPPrintoutButton,
    BackLink,
    Header,
    Section
}                           from 'components';
import {
    layout
}                           from 'styles/design.js';

import { loadContractInfo, isContractInfoLoaded } from 'redux/modules/contractInfo';
import {
    loadWithIncludes as loadAgreement
}                                   from 'redux/modules/agreement.js';

import {
    isEditable
}                                   from 'helpers/agreement.js';

import {
    calculatedDisciplineCosts
} from 'helpers/calculation.js';

import { download  } from 'redux/modules/download.js';
import { loadCountry } from 'redux/modules/country.js';

class CostView extends Component {

    componentDidMount() {
        const promises = [];
        const params = this.props.match.params;
        const token = this.props.profile.accessToken;
        if(!isContractInfoLoaded(this.props, params.contractInfoId)) {
            promises.push(this.props.dispatch(loadContractInfo(params.contractInfoId, token)));
        }
        const agreementState = this.props.agreement;
        if(!agreementState.loaded || !agreementState.agreement || agreementState.agreement.id !== params.agreementId) {
            promises.push(this.props.dispatch(loadAgreement(params.agreementId, token)));
        }
        return Promise.all(promises).then(() => {
            const contractInfo = this.props.contractInfo.contractInfo;
            if (contractInfo) {
                this.props.dispatch(loadCountry(contractInfo.salesArea, token));
            }
        });
    }
    calculateMainDisciplineCosts(disciplines) {
        const costs = _.transform(_.groupBy(disciplines, 'disciplineCategory'), (result, value, key) => {
            result.push({
                disciplineCategory: key,
                calculation: calculatedDisciplineCosts(value)
            });
        }, []);
        return costs;
    }

    hasCustomIncome(costCalculation) {
        if(costCalculation) {
            return costCalculation.oldIncome && costCalculation.oldIncome !== costCalculation.totalIncome;
        }
        return false;
    }

    printSAP = (event, id, lang) => {
        event.preventDefault();
        if(!this.props.download.loading) {
            this.props.downloadFile('print', 'agreement', id, 'SAP', lang, this.props.profile.accessToken);
        }
        return false;
    };

    render() {
        const { t, match: {params} } = this.props;
        const agreement = this.props.agreement.agreement;
        const contractInfo = this.props.contractInfo.contractInfo;
        if(_.isEmpty(agreement)) {
            return <StandardPage loaderVisible={true}><div /></StandardPage>;
        }
        let agreementDisciplines = agreement.disciplines;
        if (agreement.agreementStatus && agreement.agreementStatus.search('approved|sent_for_approval|signed') > -1) {
            agreementDisciplines = agreement.approvedDisciplines;
        }
        const totalFullCostcalculation = agreementDisciplines && agreementDisciplines.length > 0 ? calculatedDisciplineCosts(agreementDisciplines) : [];
        const disciplineCalculations = agreementDisciplines && agreementDisciplines.length > 0 ? this.calculateMainDisciplineCosts(agreementDisciplines) : [];
        return (
            <StandardPage withBreadcrumb loaderVisible={this.props.agreement.loading}>
                <BreadcrumbBar
                    page="agreement"
                    contractInfo={contractInfo}
                    agreement={agreement}
                    actionUrl = { `/client/${params.clientId}/contractInfo/${params.contractInfoId}/details`}
                    linkName = { t('show_client_details') }
                    lockedText = { !isEditable(agreement) ? t('locked') : null}
                />
                <BackLink
                    title={t('Back to agreement')}
                    to={ `/client/${params.clientId}/contractInfo/${params.contractInfoId}/agreement/${params.agreementId}/summary`}
                    id="SLAView_Back"
                />
                <Header id="CostView_Header" title={ t('costs') } />

                <Section>
                    <CostCalculation
                        clientId={ params.clientId }
                        contractInfoId = {params.contractInfoId}
                        agreementId={ params.agreementId }
                        calculation = { totalFullCostcalculation }
                        title = "full_cost_calculation"
                        disciplineType = "full_cost_calculation"
                        kitpackCosts={
                            {
                                kitpackCosts: _.get(agreement, 'kitpackTotals.yearlyPrice', null),
                                kitpackIncome: _.get(agreement, 'kitpackTotals.customerYearlyPrice', null)
                            }
                        }
                        clientNet={agreement.clientNet}
                        clientNetTotalPrice={agreement.clientNetTotalPrice}
                        clientNetCustomPrice={agreement.clientNet && agreement.clientNetCustomPrice}
                        isEditable={isEditable(agreement)}
                    />
                </Section>
                { (()=> {
                    return disciplineCalculations.map((disciplineCalculation, index) => {
                        const disciplineAnchorName = 'discipline' + index;
                        return (
                            <Section key = { index }>
                                {/*<a name={ disciplineAnchorName } style={{ position: 'absolute', marginTop: '-5em' }} />*/}
                                <CostCalculation
                                    editable
                                    clientId={ params.clientId }
                                    contractInfoId = {params.contractInfoId}
                                    agreementId={ params.agreementId }
                                    disciplineAnchorName={ disciplineAnchorName }
                                    calculation = { disciplineCalculation.calculation }
                                    title = { disciplineCalculation.disciplineCategory }
                                    disciplineType = { disciplineCalculation.disciplineCategory }
                                    isEditable={isEditable(agreement)}
                                    disciplines={agreementDisciplines}
                                />
                            </Section>
                        );
                    });
                })()}
                <Section>
                    <div style = { layout.floatRight } >
                        <SAPPrintoutButton printAction={this.printSAP} agreementId = { agreement.id } submitting={this.props.download.loading}/>
                    </div>
                </Section>
            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        agreement: state.agreement,
        agreements: state.agreement.agreements,
        disciplines: state.discipline.disciplines,
        userProfile: state.userProfile.user,
        countries: state.countries,
        client: state.client,
        profile: state.userProfile,
        params: state.router.params,
        download: state.download,
        contractInfo: state.contractInfo
    };
}

function mapDispatchToProps(dispatch) {
    return {
        downloadFile: (downloadType, type, id, appendix, lang, accessToken) => {
            return dispatch(download({downloadType, type, id, appendix, lang}, accessToken));
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(configuredRadium(CostView)));
