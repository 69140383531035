import React, { Component }  from 'react';
import { colors }       from 'styles/design.js';

const pageStyles =
    {
        fontFamily: 'Sanchez-Regular',
        fontWeight: 'normal',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '10%',
        width: '100%',
        textAlign: 'center',
        color: colors.blue
    };

class ErrorPage extends Component {

    render() {
        const {error} = this.props;
        if(error.reload) {
            this.props.history.push('/auth/authorize');
        }
        return (
            <div style={{width: '100%'}}>
            <div style = {pageStyles}>
                <img alt="error" src={require('images/errorpage2.svg')} style={{width: '150px'}} />
                <h1 style = {{marginTop: '2em', marginBottom: '1em', fontSize: '2em'}}>Something went wrong.</h1>
                <p style = {{fontSize: '1.2em', fontFamily: 'Arial'}}>Please try again later</p>
                {
                    (()=>{
                        if(error.message) {
                            return (
                                <p style={{marginTop: '1em', fontSize: '0.9em', fontFamily: 'Arial'}}>Message: {error.message}</p>);
                        }
                        return <span />; 
                    })()
                }
                <div style = {{marginTop: '2em', marginBottom: '2em'}}>
                    <span>
                        <a href="mailto:serviflex@caverion.com?subject=Serviflex error">Report problem</a>
                    </span>
                </div>
                <div style = {{marginTop: '2em'}}>
                    <a href="/auth/logout">Logout</a>
                </div>
            </div>

        </div>
        );
    }
}
export default ErrorPage;