
import React, { Component }  from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import {
    AgreementStatus,
    AddOnStatus,
}                                       from 'components/index.js';
import {
    colors
}                                       from 'styles/design.js';

import {
    Link
}                                       from 'react-router-dom';

import './AgreementStatusInformation.css';

const linkStyle = {
    color: colors.blue,
    opacity: 1
};

class AgreementStatusInformation extends Component {

    static propTypes = {
        agreement: PropTypes.object.isRequired,
        addons: PropTypes.array
    };

    render() {
        const { t, agreement, addons, verbose, current} = this.props;
        return (
            <div style={{ marginTop: '4em' }}>
                <Link
                    className={ addons !== undefined && addons.length > 0 ? 'agreement-status-linked' : ''}
                    style={ linkStyle }
                    to={'/client/' + agreement.clientId + /contractInfo/ + agreement.contractInfoId + '/' + (agreement.agreementType === 'frameagreement' ? 'frameagreement' : 'agreement') + '/' + agreement.id + '/summary'}
                >
                    <AgreementStatus
                        showComment={this.props.showComment}
                        agreement={agreement}
                        t={t}
                        verbose={ verbose }
                        current={current}
                    />
                </Link>
                <div>
                { (() => {
                    if (!addons) return (<div />);
                    return addons.map((addon, addonIndex) => {
                        return (
                            <div style={{ paddingLeft: '3em' }} key={ 'addon-status-' + addon.id }>
                                <Link className="agreement-status-linked" style={ linkStyle } to={'/client/' + addon.clientId + '/contractInfo/' + addon.contractInfoId + '/agreement/' + addon.id + '/summary'}>
                                    <AddOnStatus
                                        agreement={ addon }
                                        index={ addonIndex + 1 }
                                        t={ t }
                                        verbose={ verbose }
                                        current={current}
                                    />
                                </Link>
                            </div>
                        );
                    });
                })()}
                </div>
            </div>
        );
    }
}
export default translations(configuredRadium(AgreementStatusInformation));
