import React    from 'react';
import { Link } from 'react-router-dom';

import './BreadCrumb.css';

const BreadCrumb = (props) => {
    return (
        <li className="breadcrumb-item">
            { props.locked && <i className="fa fa-lock breadcrumb-locked" /> }
            <Link to={ props.path }>
                { props.name }
            </Link>
        </li>
    );
};

export default BreadCrumb;
