import {
    colors
} from 'styles/design.js';
import _ from 'lodash';

export const statusColor = status => {
    let color = colors.lightBlue;
    switch(status) {
        case 'approved':
        case 'signed':
            color = colors.blue;
            break;
        default:
            color = colors.lightBlue;
    }
    return color;
};

export const getAgreementName = type => {
    switch (type) {
        case 'addon':
            return 'addon';
        case 'external_main':
            return 'agreement_outside_serviflex';
        case 'frameagreement':
            return 'frame_agreement';
        default:
            return 'main_agreement';
    }
};

export const getAgreementLink = agreement => {
    const client = _.get(agreement, 'contractInfo.client', null);
    if(agreement && client) {
        return `/client/${client.id}/contractInfo/${agreement.contractInfoId}/${ agreement.agreementType === 'frameagreement' ? 'frameagreement' : 'agreement'}/${agreement.id}/summary`;
    }
    return '#';
};