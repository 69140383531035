import React                from 'react';
import {
    SaveButton,
    CancelButton
}                           from 'components';

const SystemFormActions = ({ idPrefix, children, handleSave, handleCancel, submitting }) => {
    return (
        <div className="clearfix" style={{ marginTop: '2em' }}>
            { children }
            <SaveButton 
                id={ idPrefix + '_save' } 
                onClick = { handleSave } 
                disabled = { submitting } 
                submitting = { submitting } 
            />
            <CancelButton 
                style={{ float: 'right', marginRight: '2em' }}
                id={ idPrefix + '_cancel' } 
                cancelAction = { handleCancel }
            />
        </div>
    );
}

export default SystemFormActions;