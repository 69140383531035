import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';

import {
    RepairTable,
    RepairEdit,
    SystemSegmentEditButton,
    SystemFormWrapper,
    SystemFormBody,
    SystemFormActions,
    Button
} from 'components';

import _ from 'lodash';

import {
    initializeNewKey,
    initializeExistingKey,
    getValues
}                                       from 'helpers/formHelper.js';

const defaultKeys = [
    'materialCost',
    'materialQuantity',
    'description',
    'hours',
    'visits',
    'multiplier'
];

const idPrefix = "SystemRepairsContainer";

class SystemRepairsContainer extends Component {

    static propTypes = {
        system: PropTypes.object.isRequired,
        calculatedSystem: PropTypes.object,
        resetField: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
        submitting: PropTypes.bool,
        handleSave: PropTypes.func.isRequired,
        isEditable: PropTypes.bool.isRequired
    };

    componentWillMount() {
        this.init(this.props.system);
    }

    componentWillReceiveProps(nextProps) {
        if(_.isEmpty(this.state.form) || (_.isEmpty(this.state.form.repairs) && !_.isEmpty(nextProps.system.repairs))) {
            this.init(nextProps.system);
        }
    }
    init(system) {
        const form = {
            repairs: []
        };
        if(system.repairs && _.isArray(system.repairs)) {
            system.repairs.map((material, index) => {
                const obj = initializeExistingKey({keys: defaultKeys, values: material, index, changeValue: this.changeValue});
                form.repairs.push(obj);
            });
        }
        this.setState({form});
    }
    getForm() {
        return Object.assign({}, this.state.form);
    }
    changeValue = (value, index, key) => {
        const form = this.getForm();
        form.repairs[index][key].value = value;
        if(['hours', 'visits', 'multiplier', 'materialCost', 'materialQuantity'].indexOf(key) !== -1) {
            const sys = Object.assign({}, this.props.system);
            if(!sys.repairs) {
                sys.repairs = [];
            }
            form.repairs.map((material, idx) => {
                const obj = getValues(material);
                if(!sys.repairs[idx]) {
                    sys.repairs.push(obj);
                } else {
                    sys.repairs[idx] = obj;
                }
            });
            this.props.calculateCosts(sys);
        }
        this.setState({form});
    };
    isDataValid(data) {
        return data && data.length > 0 && !this.props.editMode;
    }
    remove = (index) => {
        this.setState({removed: true});
        const form = this.getForm();
        form.repairs.splice(index, 1);
        this.setState({form});
    };
    add = () => {
        const form = this.getForm();
        const newRow = initializeNewKey({defaultKeys, index: (form.repairs.length), changeValue: this.changeValue});
        form.repairs.push(newRow);
        this.setState({form});
    };
    cancel = () => {
        this.init(this.props.system);
        this.props.handleCancel();
    };
    handleSave = () => {
        const form = this.getForm();
        const system = this.props.system;
        system.repairs = [];
        form.repairs.map((row) => {
            const tmp = getValues(row);
            system.repairs.push(tmp);
        });
        this.props.handleSave(system);
    };
    expandArea = () => {
        if(!this.state.removed && this.state.form.repairs && this.state.form.repairs.length === 0 && this.props.isEditable) {
            this.add();
            this.props.systemSegmentEditAction();
        }
        this.setState({expandThis: !this.state.expandThis});
    };

    renderNotEditable = (form) => {
        const {t, showEditButton, isEditable} = this.props;

        return (
            <div>
                <SystemSegmentEditButton
                    onClick={this.props.systemSegmentEditAction}
                    showEditButton={showEditButton}
                    editText={form.repairs && form.repairs.length > 0 ? t('edit') : t('add_repairs')}
                    isEditable = {isEditable}
                />
                <RepairTable
                    repairs={ this.props.system.repairs }
                    calculation={ this.props.calculatedSystem ? this.props.calculatedSystem.repairs : [] }
                />
            </div>
        )
    }

    renderEditable = (form) => {
        const { submitting } = this.props;
        
        return (
            <div>
                <RepairEdit
                    form={ form }
                    calculation={ this.props.calculatedSystem ? this.props.calculatedSystem.repairs : [] }
                    removeRepairAction={ this.remove }
                    resetFunction={ this.props.resetField }
                    calculating={this.props.calculating}
                />
                <SystemFormActions 
                    idPrefix={ idPrefix }
                    submitting={ submitting }
                    handleSave={ this.handleSave }
                    handleCancel={ this.cancel }
                >
                    <Button 
                        variant="add"
                        onClick={ this.add }
                    >
                        + { this.props.t('add_repairs') }
                    </Button>
                </SystemFormActions>
            </div>
        )
    }

    render() {
        const {t, calculatedSystem} = this.props;
        const editMode = this.props.editMode;
        const form = this.state.form;
        const expandThis = editMode ? true : this.state.expandThis;
        const isValid = this.isDataValid(form.repairs);
        let sum = 0;
        if (calculatedSystem && calculatedSystem.repairs) {
            calculatedSystem.repairs.map((row) => {
                sum += (row.cost || 0);
            });
        }
        return (
            <SystemFormWrapper
                id={ idPrefix + '_title' }
                isVisible={ expandThis } 
                handleToggle={ this.expandArea }
                title={ t('title_repairs') }
                cost={ sum }
                isValid={ isValid }
            >
                <SystemFormBody
                    notEditableBody={ this.renderNotEditable(form) }
                    editableBody={ this.renderEditable(form) }
                    isEditable={ editMode }
                />
            </SystemFormWrapper>
        );
    }
}
export default translations(SystemRepairsContainer);
