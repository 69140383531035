import React from 'react';
import Bundle from './Bundle';
import CostSummary from '../CostSummary/CostSummary';

import './Bundle.css';

const BundleList = ({bundles, pricing, handleSensorEdit, handleBundleDetailEdit, handleBundleDelete, t, country, isEditable}) => {
    const totals = pricing && pricing.totals ? pricing.totals : null;

    return (
        <div className="bundles">
            {bundles && bundles.map((bundle, idx) => {
                if (bundle && bundle.pricing) {
                    const bundlePricing = pricing && pricing[bundle.id] ? pricing[bundle.id] : {};
                    return (
                        <Bundle
                            handleSensorEdit={handleSensorEdit}
                            key={'bundle' + bundle.id}
                            pricing={bundlePricing}
                            bundle={bundle}
                            country={country}
                            t={t}
                            handleBundleDetailEdit={handleBundleDetailEdit}
                            handleBundleDelete={handleBundleDelete}
                            isEditable={isEditable}
                        />
                    );
                }
            })}
        </div>
    );
};

export default BundleList;