import React, {Component}        from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import { CostNumber }                       from 'components';
import { measures, layout, size }           from 'styles/design.js';

const tableCell = {
    padding: measures.half
};
const tableCellHeader = {
    ...tableCell,
    ...size.small,
    fontWeight: 'bold'
};

const lastColumn = {
    textAlign: 'right'
};

class SubcontractingTable extends Component {

    static propTypes = {
        subcontracting: PropTypes.array,
        system: PropTypes.object,
        calculation: PropTypes.array
    }

    render() {
        const { calculation } = this.props;
        if(!this.props.system || !this.props.system.subcontracting) {
            return null;
        }
        const subcontracting = this.props.system.subcontracting;
        return (
            <div>
                {(() => {
                    if ( subcontracting && subcontracting.length > 0) {
                        let totalSum = 0;
                        if(this.props.calculation) {
                            this.props.calculation.map((subcontracting) => { totalSum += (subcontracting.cost || 0); });
                        }
                        return (
                            <div>
                                <table style={{ width: '100%' }}>
                                    <thead>
                                    <tr>
                                        <td style= { tableCellHeader }>{ this.props.t('description') }</td>
                                        <td style= { tableCellHeader }>{ this.props.t('cost') + ' ' + this.props.t('vat') + ' 0%' }</td>
                                        <td style= { tableCellHeader }>{ this.props.t('net_profit_margin') }</td>
                                        <td style= {{ ...tableCellHeader, ...lastColumn }}> </td>
                                    </tr>
                                    </thead>
                                    {(() => {
                                        return subcontracting.map((subcontractingRow, index) => {
                                            const calc = calculation && calculation[index] ? calculation[index] : {cost: 0};
                                            return (
                                                <tbody key = { 'subcontracting_' + index + '_tbody' }>
                                                <tr>
                                                    <td style= { tableCell }>{ subcontractingRow.description }</td>
                                                    <td style= { tableCell }>{ subcontractingRow.price }</td>
                                                    <td style= { tableCell }>{ subcontractingRow.margin }</td>
                                                    <td style= {{ ...tableCell, ...lastColumn }}><CostNumber style = {{ fontWeight: 'normal' }}>{ !calc.cost ? 0 : calc.cost}</CostNumber></td>
                                                </tr>
                                                <tr>
                                                    <td style = { tableCellHeader }>{ this.props.t('hours_visits_repetition') }</td>
                                                    <td style = { tableCell } colSpan="3">
                                                        { this.props.t('visits_repetition_' + (subcontractingRow.multiplier || 1) )}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            );
                                        });
                                    })()}
                                </table>
                                <div style = {{ marginBottom: measures.double, marginLeft: measures.half, marginRight: measures.half, fontWeight: 'bold' }}>{ this.props.t('subcontracting_total') }<CostNumber style = { layout.floatRight }>{ totalSum }</CostNumber></div>
                            </div>
                        );
                    }
                    return (<div style = {{ marginBottom: measures.double, marginLeft: measures.half, marginRight: measures.half }}>{ this.props.t('no_subcontracting_added') }</div>);
                })()}
            </div>
        );
    }
}

export default translations(SubcontractingTable);
