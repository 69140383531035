import React from 'react';
import PropTypes from 'prop-types';
import './Accordion.css';

const AccordionContent = props => {
    return <div className="accordion-content">
        { props.children }
    </div>;
};

AccordionContent.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

export default AccordionContent;
