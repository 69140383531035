import React, {Component} from 'react';
import translations from 'decorators/translations.js';

import PropTypes from 'prop-types';
import {
    isEditable
} from 'helpers/agreement.js';
import {
    colors,
    measures,
    size
} from 'styles/design.js';
import {
    VisitPlanCalendar,
    ConfirmableButton,
    SFCheckBox
} from 'components';

import {
    Link
} from 'react-router-dom';

import _ from 'lodash';

const subDisciplineItemWrapper = {
    margin: '0.5em 3.5em 0.5em 0.5em'
};

const subDisciplineItemStyle = {
    backgroundColor: colors.lightGray,
    padding: measures.standard,
    cursor: 'pointer'
};

const deleteButtonDivStyles = {
    position: 'absolute',
    right: 0,
    top: 0,
    margin: measures.fatHalf,
    fontSize: '0.9em',
    zIndex: 1
};

class SubDisciplineItems extends Component {

    static propTypes = {
        agreement: PropTypes.object.isRequired
    };
    handleDelete = (row) => {
        this.props.handleDisciplineDelete(row.id);
    };

    render() {
        const { agreements } = this.props;
        return (
            <div>
                {(() => {
                    if (this.props.subdisciplines !== null && this.props.subdisciplines.length > 0) {
                        return this.props.subdisciplines.map((subdiscipline, index) => {
                            const isCustom = _.startsWith(subdiscipline.disciplineType, 'custom_discipline');
                            const systemCount = subdiscipline.disciplineSystems ? subdiscipline.disciplineSystems.length : 0;
                            const agreement = _.find(agreements, { id: subdiscipline.agreementId });
                            return (
                                <div
                                    key={subdiscipline.id}
                                    id={'SubDisciplineItems_' + subdiscipline.disciplineCategory + '_' + subdiscipline.disciplineType}
                                    style={{ position: 'relative' }}
                                >
                                    <div style={{
                                        float: 'right',
                                        marginTop: measures.standard,
                                        marginRight: measures.double,
                                        display: isEditable(agreement) ? 'none' : 'block'
                                    }}>
                                        <i className="fa fa-lock"/>
                                    </div>

                                    <div style={ subDisciplineItemWrapper }>
                                        <Link
                                            to={`/client/${this.props.clientId}/contractInfo/${this.props.contractInfoId}/building/${this.props.buildingId}/discipline/${subdiscipline.id}`}>
                                            <div style={subDisciplineItemStyle}>
                                                {isCustom ? subdiscipline.disciplineName : this.props.t(subdiscipline.disciplineType)}
                                                <small style={size.small}>, {this.props.t('systems')}: {systemCount} </small>
                                            </div>
                                        </Link>
                                    </div>
                                    <div style={{
                                        ...deleteButtonDivStyles,
                                        display: isEditable(agreement) ? 'block' : 'none'
                                    }}>
                                        <ConfirmableButton
                                            style={{backgroundColor: colors.brightRed}}
                                            id={'SubDisciplineitems_delete_' + index}
                                            buttonIcon={'fa fa-trash'}
                                            confirmButtonText={this.props.t('yes')}
                                            confirmText={this.props.t('confirm_delete_discipline') + '?'}
                                            action={() => this.handleDelete(subdiscipline)}
                                        />
                                    </div>
                                    {
                                        (() => {
                                            if (subdiscipline.needbased) {
                                                return (<div style={{margin: measures.standard, fontSize: '0.7em'}}>
                                                    <SFCheckBox
                                                        disabled={true}
                                                        rightLabel={this.props.t('needbased')}
                                                        formProperty={{value: true}}
                                                    />
                                                </div>);
                                            }
                                            return <VisitPlanCalendar visitPlan={subdiscipline.visitPlan} showSelection={false}/>;
                                        })()
                                    }

                                </div>
                            );
                        });
                    }
                    return (<div/>);
                })()}
            </div>
        );
    }
}

export default translations(SubDisciplineItems);
