import React, { Component }    from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import { connect }                        from 'react-redux';
import { localeDateString }     from 'helpers/stringTemplates';
import { Link } from 'react-router-dom';
import { getLanguage } from 'helpers/languageHelper';
import {
    measures,
    colors,
    size,
    commonStyles
}                                         from 'styles/design.js';

import {
    isEditable
}                                   from 'helpers/agreement.js';

import {
    download
}   from 'redux/modules/download.js';
import {
    LoadingIndicator
} from 'components';

const kitPackRowStyle = {
    width: '75%',
    background: colors.lightGray,
    padding: '1em 2em',
    margin: '0 0 0.5em 0',
    cursor: 'pointer'
};

const printButtonStyle = {
    width: '20%',
    padding: '1em',
    cursor: 'pointer',
    color: 'white',
    float: 'right',
    backgroundColor: colors.lightBlue
};

class KitPackList extends Component {

    static propTypes = {
        kitpacks: PropTypes.array,
        kitPackClicked: PropTypes.func,
    };

    static defaultProps = {
        kitpacks: [],
        kitPackClicked: (kitpack) => {
            console.log('kitPackClicked prop not defined. ', kitpack);
        }
    };

    downloadFile = (event, downloadType, type, id, lang) => {
        event.preventDefault();
        this.props.downloadFile(downloadType, type, id, null, lang, this.props.profile.accessToken);
        return false;
    };

    render() {
        const { t, kitpacks, agreement, params: {clientId, contractInfoId, agreementId} } = this.props;
        return (
            <div>
                { (() => {
                    return kitpacks.sort((a, b) => { return new Date(a.created).getTime() - new Date(b.created).getTime(); }).map((kitpack, i) => {
                        const lang = getLanguage(this.props.translations.language);
                        return (
                            <div key={'kitpack_' + i}>
                                <button
                                    onClick={(event) => this.downloadFile(event, 'print', 'kitpack', kitpack.id, lang)}
                                    style = {Object.assign({}, commonStyles.button, printButtonStyle)}
                                >
                                    {t('print')}
                                    <LoadingIndicator visible={this.props.download.loading} />
                                </button>
                                <div key={ 'list-kitpack-' + kitpack.id } style={ kitPackRowStyle } onClick={ () => { this.props.kitPackClicked(kitpack); } }>
                                    <span style = {{ cursor: 'pointer' }}>{ t('kitpack') } { i + 1 }
                                        <span style={ Object.assign({}, size.tiny, { marginLeft: '1em' }) }>{ localeDateString(kitpack.created, {showTimeZone: true}) }</span>
                                    </span>
                                </div>
                            </div>
                        );
                    });
                })()}
                {
                    (() => {
                        if(!this.props.kitpackSystems || this.props.kitpackSystems.length === 0) {
                            return (<p><b>{t('no_ahu_options_error')}</b></p>);
                        }
                        if(kitpacks.length === 0 && isEditable(agreement)) {
                            return (
                                <Link
                                    style={
                                        {
                                            marginTop: measures.half,
                                            padding: measures.standard,
                                            backgroundColor: colors.orange,
                                            lineHeight: '3em',
                                            cursor: 'pointer',
                                            width: '12em',
                                            color: colors.white
                                        }
                                    }
                                    to={'/client/' + clientId + '/contractInfo/' + contractInfoId  + '/agreement/' + agreementId + '/kitpack/new'}>
                                    <i className="fa fa-plus-circle" /> { t('add_new_kit_pack') }
                                </Link>
                            );
                        }
                    })()
                }
                </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        translations: state.translations,
        download: state.download
    };
}

function mapDispatchToProps(dispatch) {
    return {
        downloadFile: (downloadType, type, id, appendix, lang, accessToken) => {
            return dispatch(download({downloadType, type, id, appendix, lang}, accessToken));
        }
    }
}
const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(KitPackList));