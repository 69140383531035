import React from 'react';
import {
    Button
} from 'components';
import smoothscrollPolyfill from 'smoothscroll-polyfill';

import './Dialog.css';

smoothscrollPolyfill.polyfill();

/**
 * Dialog (modal) UI component 
 */
class Dialog extends React.Component {
    componentWillMount() {
        if (this.props.disableBodyScroll) {
            document.body.style.overflow = "hidden";
        }
    }

    componentDidMount() {
        this.scrollDialogToTop();
    }

    componentWillUnmount() {
        if (this.props.disableBodyScroll) {
            document.body.style.overflow = "visible";
        }
    }

    scrollDialogToTop = () => {
        this.bodyRef.scroll({
            left: 0,
            top: 0,
            behavior: 'smooth'
        })
    }

    render() {
        const { actions, children, variant, size } = this.props;
        let classes = 'dialog';

        if (variant) {
            classes += ' dialog-' + variant;
        }
        if (size) {
            classes += ' dialog-size-' + size;
        }
        return (
            <div className={ classes }>
                <div className="dialog-overlay">
                    <div className="dialog-window" ref={node => {this.bodyRef = node;}}>
                        <div className="dialog-body">
                            { children }
                        </div>
                        { actions instanceof Array && actions.length > 0 &&
                            <div className="dialog-actions">
                                { actions.map((action, idx) => {
                                    return (
                                        <Button 
                                            className="dialog-action"
                                            key={ 'action_' + idx }
                                            id={ action.id }
                                            style={ action.style }
                                            onClick={ action.onClick }
                                            variant={ action.variant }
                                        >
                                            { action.title }
                                        </Button>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Dialog;