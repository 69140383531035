import React from 'react';


const OperatingHoursDetails = ({t, hours}) => {

    return (
        <div className="operating-hours-container">
            <div className="operating-hours-row detail-row">
                <div className="bolded">
                    {t('working_days')}:
                </div>
                <div>
                    {hours.workingDayStart} - {hours.workingDayEnd}
                </div>
            </div>
            <div className="operating-hours-row detail-row">
                <div className="bolded">
                    {t('saturday')}:
                </div>
                <div>
                    {hours.saturdayStart} - {hours.saturdayEnd}
                </div>
            </div>
            <div className="operating-hours-row detail-row">
                <div className="bolded">
                    {t('sunday')}
                </div>
                <div>
                    {hours.sundayStart} - {hours.sundayEnd}
                </div>
            </div>

        </div>
    );
};

export default OperatingHoursDetails;