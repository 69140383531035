import React, {Component} from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/translations.js';
import _ from 'lodash';

import {
    Cards
} from 'components';

import './AgreementAttachments.css';
import Attachment from './Attachment.js';

class AgreementAttachments extends Component {

    constructor(props) {
        super(props);
        this.state = {attachments: [], uploading: []};
    }

    static propTypes = {
        agreement: PropTypes.object.isRequired,
        submitAttachments: PropTypes.func.isRequired,
        uploadFile: PropTypes.func.isRequired,
        deleteAttachment: PropTypes.func.isRequired,
        updateAttachment: PropTypes.func.isRequired
    };

    componentWillMount() {
        this.initAttachments(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.initAttachments(nextProps);
    }

    initAttachments(props) {
        if(this.state.attachments.length === 0 && props.agreement.attachments && props.agreement.attachments.length > 0) {
            this.setState({attachments: _.cloneDeep(props.agreement.attachments)});
        }
    }

    addRow = () => {
        const attachments = this.state.attachments;
        attachments.push({name: '', description: '', isNew: true});
        this.setState({attachments});
    };
    deleteRow = (idx) => {
        const attachments = this.state.attachments;
        const attachment = this.state.attachments[idx];
        //If attachment has id, do delete call for api first.
        if(attachment.id) {
            this.props.deleteAttachment(attachment).then(() => {
                attachments.splice(idx, 1);
                this.setState({attachments});
                this.props.getAgreements();
            })
        } else {
            attachments.splice(idx, 1);
            this.setState({attachments});
        }
    };
    save = () => {
        this.setState({submitting: true});
        this.props.submitAttachments(this.state.attachments)
            .then(() => {
                this.setState({submitting: false});
            });
    };
    updateAttachment = (idx, attachment) => {
        const attachments = this.state.attachments;
        attachments[idx] = attachment;
        return this.props.updateAttachment(attachment);
    };

    render() {
        const {t} = this.props;

        return (
            <div className="agreement-attachments">
                {
                    this.state.attachments.length === 0 && <span style={{ marginRight: '0.5em' }}>{ t('no_attachments') }</span>
                }
                <Cards>
                    {
                        this.state.attachments.map((attachment, idx) => {
                            return (
                                <Attachment
                                    getAgreements={this.props.getAgreements}
                                    updateAttachment={this.updateAttachment}
                                    deleteRow={() => this.deleteRow(idx)}
                                    uploadFile={this.props.uploadFile}
                                    key={'idx_' + idx}
                                    attachment={attachment}
                                    idx={idx}
                                    t={t}
                                    isEditable={this.props.isEditable}
                                />
                            );
                        })
                    }
                    {
                        this.props.isEditable &&
                        <div className="agreement-attachments-fake-card" onClick={this.addRow}>
                            <span>
                                <i className="fa fa-plus fa-3x"/><br /> 
                                {t('add_new')}
                            </span>
                        </div>
                    }
                </Cards>
            </div>
        );
    }
}

export default translations(AgreementAttachments);