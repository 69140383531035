import React from 'react';

import {reduxForm} from 'redux-form';

import {
    commonStyles,
    measures,
    colors,
} from 'styles/design';

import {
    SFCheckBox,
    DisciplineIcon,
    SFInputField,
    ConfirmableButton,
    Button
} from 'components';

import {decimalRound} from 'helpers/stringTemplates.js';

const reduxFormObject = {
    form: 'frameAgreementDisciplineForm',
    fields: [
        'disciplineCategory',
        'pricing[].name',
        'pricing[].enabled',
        'pricing[].basicCharge',
        'pricing[].overtime50',
        'pricing[].overtime100',
        'pricing[].discount',
        'pricing[].clientPrice',
        'pricing[].customValues[].name',
        'pricing[].customValues[].value',
    ]
};

class DisciplineForm extends React.Component {
    state = {invalidDiscounts: {}}
    componentWillMount() {
        const {
            discipline
        } = this.props;
        discipline.pricing.map((price) => {
            if(price.enabled === null || price.enabled === undefined) {
                price.enabled = true;
            }
        })
        this.props.initializeForm(discipline);
    }

    calculateDiscountedPrice(price, discount, currency) {
        const disc = discount ? discount : 0;
        return decimalRound(price * ((100 - disc) / 100), currency);
    }

    onChangeDiscount = (priceName, value) => {
        const priceField =this.props.fields.pricing.find(price => price.name.name === priceName);
        priceField.discount.onChange(value);
        const invalidDiscounts = this.state.invalidDiscounts;
        if(parseFloat(value) > this.props.country.frameAgreementConfig.maxDiscount) {
            invalidDiscounts[priceName] = true;
            this.setState((state) => ({...state, invalidDiscounts}));
        } else {
            invalidDiscounts[priceName] = false;
            this.setState((state) => ({...state, invalidDiscounts}));
            priceField.clientPrice.onChange(this.calculateDiscountedPrice(priceField.basicCharge.value, value, this.props.country.currency));
        }
    };

    getPriceRow = (price, index, country, t) => {
        return (
            <div key={'pricerow_' + index} className="FlexParent">
                <div className="width10 FlexTableChild">
                    <SFCheckBox formProperty={price.enabled} />
                </div>
                <div className="width25 FlexTableChild">
                    {price.name.value}
                </div>
                <div className="width20 FlexTableChild">
                    {price.basicCharge.value ? decimalRound(price.basicCharge.value, country.currency) : ''}
                </div>
                <div className="width25 FlexTableChild">
                    <SFInputField
                        type="number"
                        id={'PriceRow_discount_' + index}
                        style={{width: '90%'}}
                        defaultInput={true}
                        value={price.discount.value}
                        onChange={(event) => this.onChangeDiscount(price.name.name, event.target.value)}
                    />
                    {this.state.invalidDiscounts[price.name.name] && <div>{t('Invalid discount')}</div>}
                </div>
                <div className="width20 FlexTableChild" id={'PriceRow_clientPrice_' + index}>
                    {price.clientPrice.value ? decimalRound(price.clientPrice.value, country.currency) : ''}
                </div>
            </div>
        );
    }

    hasPricing = price => !!price.basicCharge.value;

    render() {
        const { fields, discipline, country, t, index, toggleEdit, handleSubmit, deleteDiscipline } = this.props;
        return (
            <div key={ 'discipline-selection-' + discipline.disciplineCategory }
                 style={{
                     ...commonStyles.bottomDivider,
                     padding: measures.standard,
                     marginBottom: measures.standard,
                     marginTop: measures.standard,
                     backgroundColor: colors.lightGray
                 }}>
                <div className="DisciplineHeaderContainer" id={'FrameAgreementDiscipline_' + index + '_header'}>
                    <div className="DisciplineIcon">
                        <DisciplineIcon
                            style={commonStyles.disciplineIconStyle}
                            diciplineType={discipline.disciplineCategory}
                        />
                    </div>
                    <div className="DisciplineHeader">
                        <h2 style={commonStyles.disciplineNameStyle}>{t(discipline.disciplineCategory)} </h2>
                    </div>
                    <div className="DisciplineEdit">

                        <ConfirmableButton
                            style={{backgroundColor: colors.brightRed}}
                            buttonIcon={'fa fa-trash'}
                            confirmButtonText={this.props.t('yes')}
                            confirmText={this.props.t('are_you_sure')}
                            action={deleteDiscipline}
                            variant="delete"
                            id="FrameAgreementDisciplineList_edit"
                        />
                    </div>
                </div>
                <div>
                    <div className="DisciplinePrices">
                        <div className="FlexParent">
                            <div className="width10 FlexTableChild">

                            </div>
                            <div className="width25 FlexTableChild">
                                {t('name')}
                            </div>
                            <div className="width20 FlexTableChild">
                                {t('basic_charge')} ({country.currency})
                            </div>
                            <div className="width25 FlexTableChild">
                                {t('discount')} (%)
                            </div>
                            <div className="width20 FlexTableChild">
                                {t('client_price')} ({country.currency})
                            </div>
                        </div>
                        {
                        fields.pricing
                            .filter(price => this.hasPricing(price))
                            .map((price, index) => {
                                return this.getPriceRow(price, index, country, t);
                            })
                        }
                        {
                        fields.pricing && fields.pricing
                            .some(price => !this.hasPricing(price))
                                && <div style={{ height: '1em', marginBottom: '1em' }}/>
                        }
                        {
                        fields.pricing
                            .filter(price => !this.hasPricing(price))
                            .map((price, index) => {
                                return this.getPriceRow(price, index, country, t);
                            })
                        }
                        <div>
                        <Button
                            variant="link"
                            onClick={toggleEdit}
                        >
                            {t('cancel')}
                        </Button>
                            <Button
                                id="FrameAgreementDisciplineForm_submit"
                                onClick={handleSubmit}>
                                {t('save')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default reduxForm(reduxFormObject)(DisciplineForm);