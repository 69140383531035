import React from 'react';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import smoothscrollPolyfill from 'smoothscroll-polyfill';

import './Steps.css';

smoothscrollPolyfill.polyfill();

class Steps extends React.Component {
    state = {
        activeStep: 0,
        height: 'auto'
    };

    componentDidMount() {
        if (this.props.children.length > 1) {
            this.setHeight(0);
            this.heightCheck = setInterval(() => {
                const newHeight = this.getHeight(this.state.activeStep);
                if (newHeight !== this.state.height) {
                    this.setHeight(this.state.activeStep);
                }
            }, 50);
        }
    }

    componentWillUnmount() {
        clearInterval(this.heightCheck);
    }

    setActive = step => {
        if (this.state.activeStep !== step) {
            if (this.props.children.length > 1) {
                this.setHeight(step);
            }
            if (typeof this.props.onStepChange === 'function') {
                this.props.onStepChange();
            }
            this.scrollToTop();
            this.setState({
                activeStep: step
            });
        }
    }

    getHeight = (step) => {
        const nodeName = 'node' + step;
        const newNode = ReactDOM.findDOMNode(this[nodeName]);
        return newNode.scrollHeight;
    }

    setHeight = step => {
        const newHeight = this.getHeight(step);

        this.setState({
            height: newHeight
        });
    }

    goToStep = (e, idx) => {
        e.preventDefault();
        this.setActive(idx);
    }

    scrollToTop() {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth' 
        });
    };

    nextStep = () => {
        if (this.state.activeStep !== this.props.children.length) {
            this.setActive(this.state.activeStep + 1);
        }
    };

    prevStep = () => {
        if (this.state.activeStep !== 0) {
            this.setActive(this.state.activeStep - 1);
        }
    };

    render() {
        const { children } = this.props;

        return (
            <div className="steps">
                {children.length > 1 &&
                    <div className="steps-index">
                        {_.times(children.length, Number).map(step=> {
                            return (
                                <a key={'step-index' + step} href="" onClick={e => this.goToStep(e, step)} className={`step-index ${step === this.state.activeStep && `active`}`}>
                                    <span className="step-inner">{step + 1}</span>
                                </a>
                            );
                        })}
                    </div>
                }
                <div className="steps-wrapper">
                    <div 
                        className="steps-content" 
                        style={{
                            height: this.state.height,
                            width: 100 * children.length + '%',
                            transform: 'translateX(' + (-this.state.activeStep * (100 / children.length)) + '%)'
                        }}
                    >
                        { React.Children.map(children, (child, idx) => {
                            const selected = (this.state.activeStep === idx);
                            const nodeName = 'node' + idx;
                            const childWithProps = React.cloneElement(child, {
                                selected: selected,
                                ref: (node) => { this[nodeName] = node; },
                                key: 'step' + idx,
                                firstStep: idx === 0,
                                lastStep: idx === children.length - 1,
                                nextStep: this.nextStep,
                                prevStep: this.prevStep
                            });
                            return childWithProps;
                        }) }
                    </div>
                </div>
            </div>
        )
    }
}

export default Steps;
