import React from 'react';
import BundleItemList from './BundleItemList';
import BundleItem from './BundleItem';
import BundleTitle from './BundleTitle';
import BundleDetails from './BundleDetails';
import CostSummary from '../CostSummary/CostSummary';
import BundleCostSummary from './BundleCostSummary';
import _ from 'lodash';

import './Bundle.css';

const Bundle = ({bundle, pricing, handleSensorEdit, handleBundleDelete, handleBundleDetailEdit, t, country, isEditable}) => {
    const handleDelete = () => {
        handleBundleDelete(bundle.id);
    }
    const contractYears = bundle.contractYears || 5;

    return (
        <div className={bundle.sensors && bundle.sensors.length === 0 ? 'bundle-special bundle' : 'bundle'}>
            <BundleTitle
                title={ t('collector_bundle_' + bundle.pricing.type) }
                allowDelete={bundle.type !== 'receiver'}
                handleBundleDelete={handleDelete}
                totalSalesPrice={_.get(pricing, 'sums.customerTotalSalesPrice', 0)}
                country={country}
                t={t}
            />
            {bundle.sensors && bundle.sensors.length > 0 &&
                <BundleItemList>
                    {bundle.sensors.map((sensor, idx) => {
                        return (
                            <BundleItem
                                t={t}
                                sensor={sensor}
                                key={sensor.id}
                                handleSensorEdit={(sensorId) => handleSensorEdit(sensorId, bundle.id)}
                                isEditable={isEditable}
                            />
                        );
                    })}
                </BundleItemList>
            }
            <BundleDetails
                bundleId={bundle.id}
                totalSalesPrice={_.get(pricing, 'sums.customerTotalSalesPrice', 0)}
                customerSalesPrice={bundle.customSalesPrice || _.get(pricing, 'sums.customerPrice', 0)}
                upfrontCost={bundle.upfrontCost}
                contractYears={contractYears}
                handleBundleDetailEdit={handleBundleDetailEdit}
                country={country}
                t={t}
                isEditable={isEditable}
            />
            <BundleCostSummary t={t}>
                <CostSummary
                    costs={[
                        {
                            data: [
                                {
                                    label: t('Yearly price'),
                                    value: _.get(pricing, 'sums.customerYearlyPrice', 0)
                                },
                                {
                                    label: t('Monthly price'),
                                    value: _.get(pricing, 'sums.customerMonthlyPrice', 0)
                                }
                            ]
                        }
                    ]}
                    currency={country.currency}
                />
            </BundleCostSummary>
        </div>
    );
};

export default Bundle;