import React from 'react';

import {
    SFInputField,
    Button
} from 'components';

const CustomOption = ({option, id, toggle, t}) => {
    return (
        <div className="CustomOptionContainer">
            <div className="CustomOptionChild">
                <SFInputField formProperty={option.name} fieldName={t('name')}/>
            </div>
            <div className="CustomOptionChild">
                <SFInputField formProperty={option.value} fieldName={t('value')}/>
            </div>
            <div className="CustomOptionChild">
                <SFInputField formProperty={option.valueType} fieldName={t('type')}/>
            </div>
            <div className="CustomOptionDelete">
                <Button
                    onClick={toggle}
                    variant="delete"
                >
                    <i className="fa fa-trash" />
                </Button>
            </div>
        </div>
    );
}

export default CustomOption;