import React from 'react';
import translations from 'decorators/translations.js';
import {
    layout,
    measures
} from 'styles/design.js';

const dataDivLeft = {
    ...layout.floatLeft,
    ...layout.inlineBlock,
    ...layout.halfWidth
};

const dataDivRight = {
    ...layout.floatRight,
    ...layout.inlineBlock,
    ...layout.halfWidth
};

const dataDiv = {
    padding: measures.standard,
    margin: measures.standard
};


const KeyValueDiv = (props) => {
    return (
        <div style={{...dataDiv, ...props.parentStyles}}>
            <div style={{...dataDivLeft, ...props.leftStyles}}>{props.name}{props.withColon ? ':' : ''}</div>
            <div style={{...dataDivRight, ...props.rightStyles}} id={props.id}>
                {props.value}
            </div>
        </div>
    );
};
export default translations(KeyValueDiv);

