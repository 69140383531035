import React from 'react';
import translations from 'decorators/translations';
import {
    Button,
    LoadingIndicator,
} from 'components/index.js';
import {
    measures,
} from 'styles/design.js';
import { getSmartViewTotalPrice } from './utils';

const SmartViewInformation = ({
    t,
    editClicked,
    isEditable,
    agreement,
    countryConfig,
    printContent,
}) => {
    if (!agreement || !countryConfig) {
        return <LoadingIndicator visible={true}/>;
    }
    const buildingCount = agreement.buildings ? agreement.buildings.length : 0;

    return (
        <div>
            {isEditable && (
            <Button id="SmartViewInformation_EditButton" onClick={editClicked}>
                <i className="fa fa-pencil" /> {t('edit')}
            </Button>
            )}
            {agreement.clientNet ?
                <div style={{ marginTop: measures.double }}>
                    <div style={{ marginTop: measures.standard }}>
                        <i style={{ fontSize: '1.2em' }} className="fa fa-check-square-o"/>
                        <span style={{ marginLeft: '1.5em' }}>
                            { t('client_net') }
                        </span>
                    </div>
                    <div style={{ marginTop: measures.double }}>
                        <strong>{ t('Admin user email') }:</strong> { agreement.clientNetAdminEmail || '-' }
                    </div>
                    {agreement.agreementType !== 'frameagreement' && !agreement.smartViewPricing && <div>
                        <div style={{ marginTop: measures.double }}>
                            <strong>{ t('Yearly price') }:</strong> { agreement.clientNetCustomPrice ? `${agreement.clientNetCustomPrice} ${countryConfig.currency || ''}` : '-' }
                        </div>
                        {agreement.clientNetCustomPrice &&
                            <div style={{ marginTop: measures.double }}>
                                <strong>{t('SmartView total price for {0} building(s)', buildingCount)}:</strong> {agreement.clientNetTotalPrice} {countryConfig.currency}
                            </div>
                        }
                    </div>}
                    {agreement.agreementType !== 'frameagreement' && agreement.smartViewPricing && <div>
                        <div style={{ marginTop: measures.double }}>
                            {agreement.smartViewPricing.length
                                ? <ul>
                                    {agreement.smartViewPricing.map(pricing => pricing.enabled
                                    ? <li style={{marginTop: measures.half}}>{agreement.language === 'en'
                                        ? pricing.nameEnglish
                                        : pricing.name}: {pricing.price} {countryConfig.currency} / {t(pricing.priceTarget)}
                                    </li>
                                    : null)}
                                </ul>
                                : '-'
                            }
                        </div>
                        <div style={{ marginTop: measures.double }}>
                            <strong>{t('SmartView total price for {0} building(s)', buildingCount)}:</strong> {getSmartViewTotalPrice(agreement, agreement.smartViewPricing, agreement.clientNetCustomPrice)} {countryConfig.currency}
                        </div>
                    </div>}
                    <div style={{marginTop: measures.double, marginBottom: measures.half}}>
                        {printContent}
                    </div>
                </div>
                :
                <div style={{ margin: '1em 0' }}>
                    <i style={{ fontSize: '1.2em' }} className="fa fa-square-o"/>
                    <span style={{ marginLeft: '1.5em' }}>
                        { t('client_net') }
                    </span>
                </div>
            }
        </div>
    );
}

export default translations(SmartViewInformation);