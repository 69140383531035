
import _ from 'lodash';

const lockedStatuses = ['approved', 'sent_for_approval', 'signed', 'lost'];
const openStatuses = ['draft', 'rejected'];

/**
 * returns an array of given agreement addons. If the given agreement is addon then returns that (in array)
 * @param {Array} agreements
 * @param {Object} agreement
 * @returns {Array}
 */
export const agreementAddons = (agreements, agreement) => {
    let addons = [];
    if (agreement.agreementType === 'addon') {
        addons = [agreement];
    } else {
        addons = _.sortBy(_.filter(agreements, (agr) => { return agr.mainAgreementId === agreement.id.toString(); }), (agr) => { return agr.created; });
    }
    return addons;
};

export const isFrameAgreementEditable  = (agreement) => {
    return openStatuses.indexOf(agreement.agreementStatus) !== -1;
};

export const isEditable = (agreement, agreements) => {
    if(!agreement) {
        return false;
    }
    //If agreement has parent, check that status too.
    if(agreement && agreement.mainAgreementId && agreements) {
        //If any of current agreement's parent etc is sent for approval, editing should be disabled. Handling for corner case, there shouldn't be parent agreements in other than signed state
        const isSentForApproval = _.find(agreements, (agr) => {
            return parseInt(agreement.mainAgreementId, 10) === agr.id && lockedStatuses.indexOf(agr.agreementStatus.index) !== -1;
        });
        if(isSentForApproval) {
            return false;
        }
    }
    return openStatuses.indexOf(agreement.agreementStatus) !== -1;
};

export const isAgreementSigned = (agreement) => {
    return agreement.agreementStatus === 'signed';
};

function getRelatedBuildings(agreements, building) {
    //Get agreements related to the building
    return _.filter(agreements, (agr) => {
        return agr.id === building.agreementId || _.find(agr.buildings, {id: building.id});
    });
}


export const isBuildingEditable = (agreements, building) => {
    const relatedToBuilding = getRelatedBuildings(agreements, building);
    //Check if any of the agreements related to this building is in draft state, if so, allow editing
    return _.find(relatedToBuilding, (agreement) => {
        return openStatuses.indexOf(agreement.agreementStatus) !== -1;
    });
};
export const isBuildingDeletable = (agreements, building) => {
    const relatedToBuilding = getRelatedBuildings(agreements, building);

    //Check if all agreements related to the building are in open state.
    return !_.find(relatedToBuilding, (agreement) => {
        return openStatuses.indexOf(agreement.agreementStatus) === -1;
    });
};
export const canUserDeleteBuilding = (user, building) => {
    return user.isAdmin || user.email === building.createdBy;
};


