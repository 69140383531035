import React from 'react';
import translations from 'decorators/translations.js';
import ReportSection from './ReportSection.js';
import {
    BarChart,
    ReportTableMulti,
    Section
} from 'components';

const ByDepartment = ({
    agreementMarginsByDepartment,
    agreementTotalsByDepartment,
    agreementsByStatusAndDepartment,
    frameAgreementTotalsByDepartment,
    frameAgreementsByStatusAndDepartment,
    chartSize,
    t
}) => {
    if ((agreementMarginsByDepartment && agreementMarginsByDepartment.length > 0)
        || (agreementsByStatusAndDepartment && agreementsByStatusAndDepartment.length > 0)
        || (frameAgreementTotalsByDepartment && frameAgreementTotalsByDepartment.length > 0)
        || (frameAgreementsByStatusAndDepartment && frameAgreementsByStatusAndDepartment.length > 0)
    ) {
        return (
            <div>
                { frameAgreementTotalsByDepartment
                    && frameAgreementTotalsByDepartment.length > 0
                    &&
                    <ReportSection title={ t('Frame agreement average discounts by deparment')}>
                        <ReportTableMulti
                            data={frameAgreementTotalsByDepartment}
                        />
                    </ReportSection>
                }
                { frameAgreementsByStatusAndDepartment
                    && frameAgreementsByStatusAndDepartment.length > 0
                    &&
                    <ReportSection title={ t('Frame agreements by deparment')}>
                        <ReportTableMulti
                            data={frameAgreementsByStatusAndDepartment}
                        />
                    </ReportSection>
                }
                { agreementMarginsByDepartment
                    && agreementMarginsByDepartment.length > 0
                    &&
                    <ReportSection title={ t('Serviflex agreement totals by department')}>
                        { agreementMarginsByDepartment.length < 10 &&
                            <BarChart 
                                data={agreementMarginsByDepartment} 
                                height={chartSize.height} 
                                width={chartSize.width} 
                                showValues
                            />
                        }
                        <ReportTableMulti
                            data={agreementTotalsByDepartment}
                        />
                    </ReportSection>
                }
                { agreementsByStatusAndDepartment
                    && agreementsByStatusAndDepartment.length > 0
                    &&
                    <ReportSection title={ t('Serviflex agreements by deparment')}>
                        <ReportTableMulti
                            data={agreementsByStatusAndDepartment}
                        />
                    </ReportSection>
                }
            </div>
        );
    } else {
        return (
            <Section>
                <p>{ t('no_agreements_available') }</p>
            </Section>
        );
    }
}

export default translations(ByDepartment);