import {createReducerFromMapping} from 'redux/utils/index.js';

const LOAD_ME = 'ServiFlex/UserProfile/LOAD_ME',
    LOAD_ME_SUCCESS = 'ServiFlex/UserProfile/LOAD_ME_SUCCESS',
    LOAD_ME_FAIL = 'ServiFlex/UserProfile/LOAD_ME_FAIL',
    LOAD_APPROVERS = 'ServiFlex/UserProfile/LOAD_APPROVERS',
    LOAD_APPROVERS_SUCCESS = 'ServiFlex/UserProfile/LOAD_APPROVERS_SUCCESS',
    LOAD_APPROVERS_FAIL = 'ServiFlex/UserProfile/LOAD_APPROVERS_FAIL',
    LOGOUT = 'ServiFlex/UserProfile/LOGOUT',
    LOGOUT_SUCCESS = 'ServiFlex/UserProfile/LOGOUT_SUCCESS',
    LOGOUT_FAIL = 'ServiFlex/UserProfile/LOGOUT_FAIL';


export const REFRESH_TOKEN = 'ServiFlex/Profile/REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'ServiFlex/Profile/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAIL = 'ServiFlex/Profile/REFRESH_TOKEN_FAIL';

export const LOAD_TOKEN = 'ServiFlex/Profile/LOAD_TOKEN';
export const LOAD_TOKEN_SUCCESS = 'ServiFlex/Profile/LOAD_TOKEN_SUCCESS';
export const LOAD_TOKEN_FAIL = 'ServiFlex/Profile/LOAD_TOKEN_FAIL';


const initialState = {
    loaded: false,
    loading: [],
    user: {},
    loadingApprovers: false,
    loadedApprovers: false,
    approvers: [],
    approversError: null
};

export default createReducerFromMapping({
    [LOAD_ME]: (state, action) => ({
        ...state,
        loading: state.loading.concat([action.key])
    }),
    [LOAD_ME_SUCCESS]: (state, action) => ({
        ...state,
        loading: state.loading.filter(x => x !== action.key),
        user: action.result,
    }),
    [LOAD_ME_FAIL]: (state, action) => ({
        ...state,
        loading: state.loading.filter(x => x !== action.key),
        user: action.error,
        error: action.error,
    }),
    [LOGOUT]: (state, action) => ({
        ...state,
        loggingOut: true
    }),
    [LOGOUT_SUCCESS]: (state, action) => ({
        ...state,
        loggingOut: false,
        user: null
    }),
    [LOGOUT_FAIL]: (state, action) => ({
        ...state,
        loggingOut: false,
        logoutError: action.error
    }),
    [LOAD_APPROVERS]: (state, action) => ({
        ...state,
        loadingApprovers: true
    }),
    [LOAD_APPROVERS_SUCCESS]: (state, action) => ({
        ...state,
        loadingApprovers: false,
        loadedApprovers: true,
        approvers: action.result
    }),
    [LOAD_APPROVERS_FAIL]: (state, action) => ({
        ...state,
        loadingApprovers: false,
        loadedApprovers: true,
        approversError: action.error
    }),

    [LOAD_TOKEN]: (state) => state,
    [LOAD_TOKEN_SUCCESS]: (state, action) => ({...state, accessTokenLoaded: true, accessToken: action.result}),
    [LOAD_TOKEN_FAIL]: (state) => ({...state, accessTokenLoaded: false, sessionExpired: true}),

    [REFRESH_TOKEN]: (state) => state,
    [REFRESH_TOKEN_SUCCESS]: (state, action) => {
        let accessToken = state.accessToken;
        if(action.result && action.result.token) {
            accessToken = action.result.token;
        }
        return ({
            ...state,
            sessionExpired: false,
            accessToken
        });
    },
    [REFRESH_TOKEN_FAIL]: (state, action) => {
        if (action.error.redirect) {
            console.log('redirecting user to', action.error.redirect);
            window.location = action.error.redirect;
        }
        return {...state, sessionExpired: true};
    },
}, initialState);

export function isLoaded(globalState) {
    return globalState.userProfile && globalState.userProfile.loaded;
}

export function load(accessToken) {
    return {
        types: [LOAD_ME, LOAD_ME_SUCCESS, LOAD_ME_FAIL],
        promise: (client) => client.get(
            '/profiles/userinfo', {accessToken}
        )
    };
}

export function logout() {
    return {
        types: [LOGOUT, LOGOUT_SUCCESS, LOGOUT_FAIL],
        promise: (client) => client.get('/logout')
    };
}

export function loadApprovers(accessToken) {
    return {
        types: [LOAD_APPROVERS, LOAD_APPROVERS_SUCCESS, LOAD_APPROVERS_FAIL],
        promise: (client) => client.get(
            '/profiles/getApprovers', {accessToken}
        )
    };
}


export function loadToken() {
    return {
        types: [LOAD_TOKEN, LOAD_TOKEN_SUCCESS, LOAD_TOKEN_FAIL],
        promise: client => {
            const options = {overrideServerURL: ''};
            return client.get('/auth/retrieveToken', options);
        }
    };
}

export function refreshToken() {
    return {
        types: [REFRESH_TOKEN, REFRESH_TOKEN_SUCCESS, REFRESH_TOKEN_FAIL],
        promise: client => {
            const options = {overrideServerURL: ''};
            return client.get('/auth/refreshToken', options);
        }
    };
}
