
import React            from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';

import {
    commonStyles
} from 'styles/design.js';

const SLAMonitoringInformation = (props) => {

    return (
        <div>
            <h2 style={[commonStyles.pageSubTitle]}>{props.t('')}</h2>
        </div>
    );
};
export default translations(configuredRadium(SLAMonitoringInformation));
