import React from 'react';

import {
    commonStyles,
    measures,
    colors
} from 'styles/design';

import {
    DisciplineIcon,
    Button
} from 'components';
import {decimalRound} from 'helpers/stringTemplates.js';

const formatPrice = price => price === 0 ? '' : price;

const FrameAgreementDiscipline = ({country, t, discipline, index, toggleEdit, isEditable, language}) => {
    const getPricingRow = (price, index) => {
        const parentClassNames = ['FlexParent'];
        if (price.enabled === false) {
            parentClassNames.push('disabled');
        }
        return <div key={'price_row_' + index} className={parentClassNames.join(' ')}>
            <div className="width25 FlexTableChild">
            {language === 'en' && price.nameEnglish ?
                price.nameEnglish :
                price.name
            }
            </div>
            <div className="width25 FlexTableChild">
                {formatPrice(decimalRound(price.basicCharge, country.currency))}
            </div>
            <div className="width25 FlexTableChild">
                {price.discount}
            </div>
            <div className="width25 FlexTableChild">
                {formatPrice(decimalRound(price.clientPrice, country.currency))}
            </div>
        </div>;
    }

    const hasPricing = price => !!price.basicCharge;

    return (
        <div className="ContentWrapper" key={'discipline-selection-' + discipline.disciplineCategory}
             style={{
                 ...commonStyles.bottomDivider,
                 padding: measures.standard,
                 marginBottom: measures.standard,
                 marginTop: measures.standard,
                 backgroundColor: colors.lightGray
             }}>
            <div className="DisciplineHeaderContainer">
                <div className="DisciplineIcon">
                    <DisciplineIcon
                        style={commonStyles.disciplineIconStyle}
                        diciplineType={discipline.disciplineCategory}
                    />
                </div>
                <div className="DisciplineHeader" id={'FrameAgreementDiscipline_' + index + '_header'}>
                    <h2 style={commonStyles.disciplineNameStyle}>{t(discipline.disciplineCategory)} </h2>
                </div>
                {
                    isEditable &&
                    <div className="DisciplineEdit">
                        <Button
                            id="FrameAgreementDisciplineList_edit"
                            onClick={() => toggleEdit(discipline.disciplineCategory)}
                        >
                            <i className="fa fa-edit"/> {t('edit')}
                        </Button>
                    </div>
                }
            </div>
            <div>
                <div className="DisciplinePrices">
                    <div className="FlexParent">
                        <div className="width25 FlexTableChild">
                            {t('name')}
                        </div>
                        <div className="width25 FlexTableChild">
                            {t('basic_charge')} ({country.currency})
                        </div>
                        <div className="width25 FlexTableChild">
                            {t('discount')} (%)
                        </div>
                        <div className="width25 FlexTableChild">
                            {t('client_price')} ({country.currency})
                        </div>
                    </div>
                    <div>
                    {
                        discipline.pricing && discipline.pricing
                            .filter(price => hasPricing(price))
                            .map((price, index) => {
                                return getPricingRow(price, index);
                            })
                    }
                    </div>
                    {
                        discipline.pricing && discipline.pricing
                            .some(price => !hasPricing(price))
                                && <div style={{ height: '1em', marginBottom: '1em' }}/>

                    }
                    <div>
                    {
                        discipline.pricing && discipline.pricing
                            .filter(price => !hasPricing(price))
                            .map((price, index) => {
                                return getPricingRow(price, index);
                            })
                    }
                    </div>
                </div>
            </div>

        </div>
    );
}

export default FrameAgreementDiscipline;