import React from 'react';

const Bar = ({x, y, width, height, fill, onMouseEnter, onMouseLeave, valueText, index}) => {

    const handleMouseEnter = (e) => {
        if (onMouseEnter) {
            onMouseEnter(e);
        }
    }

    const handleMouseLeave = (e) => {
        if (onMouseLeave) {
            onMouseLeave(e);
        }
    }

    return (
        <g>
            <rect
                className="chart-bar"
                x={x}
                y={y}
                width={width}
                height={height > 0 ? height : 0}
                fill={fill}
                title={valueText}
                onMouseMove={e => handleMouseEnter(e)}
                onMouseLeave={e => handleMouseLeave(e)}
            />
            { valueText && 
                <text
                    className="chart-bar-value"
                    x={x + width / 2}
                    y={y - 5}
                    textAnchor="middle"
                >
                    {valueText}
                </text>
            }
        </g>
    );
}

export default Bar;