import React, { Component } from 'react';
import { 
    scaleLinear, 
    scaleBand,
    scaleOrdinal  } from 'd3-scale';
import { max } from 'd3-array';
import { colors } from 'styles/design.js';

import Axis from './Axis.js';
import StackedBars from './StackedBars.js';
import Chart from './Chart.js';
import Legend from './Legend.js';

import './StackedBarChart.css';

class StackedBarChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverIndex: null
        };
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
    }
 

    handleMouseOver(idx, e) {
        this.setState({
            hoverIndex: idx
        });
    }

    handleMouseOut() {
        this.setState({
            hoverIndex: null
        });
    }

    render() {
        const props = this.props;
        const outerHeight = props.height || 400;
        const outerWidth = props.width || 800;

        const hoverIndex = this.state.hoverIndex;
    
        const {keys, data, xAxisKey} = props;
    
        // Get bar totals
        for (let i = 0; i < data.length; i++) {
            let total = 0;
            for (let j = 0; j < keys.length; j++) {
                let key = keys[j];
                let d = data[i];
                total += d[key];
            }
            data[i].total = total;
        }
    
        const chartColors = props.colors || [colors.lightBlue, colors.blue, colors.orange, colors.violet, colors.lightGreen, colors.basicGreen];
        const margin = props.margin || {top: 40, right: 120, bottom: 40, left: 20};
        const innerWidth = outerWidth - margin.left - margin.right;
        const innerHeight = outerHeight - margin.top - margin.bottom;
    
        const yScale = scaleLinear()
            .rangeRound([innerHeight, 0])
            .domain([0, max(data, (d) => { return d.total })])
        const xScale = scaleBand()
            .rangeRound([0, innerWidth])
            .padding(0.1)
            .domain(data.map(d => d[xAxisKey]));
        const colorScale = scaleOrdinal()
            .range(chartColors);
    
        const legendItems = keys.map((key, index) => {
            return {
                label: key,
                color: chartColors[index]
            }
        });
    
        const legend = (
            <Legend
                colorScale={colorScale}
                items={legendItems}
                width={outerWidth}
                height={outerHeight}
                margin={margin}
                hoverIndex={hoverIndex}
            />
        );
    
        return (
            <Chart
                width={outerWidth} 
                height={outerHeight}
                margin={margin}
                legend={legend}
            >
                <Axis 
                    yScale={yScale}
                    xScale={xScale}
                    height={innerHeight}
                    width={innerWidth}
                    margin={margin}
                />
                <StackedBars 
                    xScale={xScale}
                    yScale={yScale}
                    colorScale={colorScale}
                    width={innerWidth}
                    height={innerHeight}
                    data={data}
                    keys={keys}
                    xAxisKey={xAxisKey}
                    onMouseEnter={this.handleMouseOver}
                    onMouseLeave={this.handleMouseOut}
                />
            </Chart>
        )
    }
}

export default StackedBarChart;