import React from 'react';
import _ from 'lodash';
import ContentRow from './ContentRow';
import ContentRowForm from './ContentRowForm';
import { isEditable } from 'helpers/agreement.js';

import './AgreementContents.css';

class AgreementContents extends React.Component {
    constructor(props) {
        super(props);
        const {
            languages,
            language
        } = props;
        const selectedLanguage = _.includes(languages, language) ? language : 'en';
        this.state = {
            language: selectedLanguage
        }
    }

    changeLanguage = event => {
        const language = _.get(event, 'target.value', null);
        if(language) {
            this.setState( state => ({ ...state, language }));
        }
    }
    editRow = section => {
        this.setState( state => ({...state, editingSection: section}));
    }
    saveContents = content => {
        const overriden = this.getOverridenContents(this.state.editingSection);
        this.props.saveAgreementContents(content, this.state.editingSection, this.state.language, (overriden ? overriden.id : null))
            .then( () => this.setState( state => ({...state, editingSection: null})))
            .catch( error => console.error('Error', error));
    }
    deleteContents = () => {
        const overriden = this.getOverridenContents(this.state.editingSection);
        if (overriden && overriden.id) {
            this.props.deleteAgreementContents(overriden.id)
                .then( () => this.setState( state => ({...state, editingSection: null})))
                .catch( error => console.error('Error', error));
        }
    }
    getOverridenContents = section => {
        return _.find(this.props.overridenContents, {section: parseInt(section, 10), language: this.state.language});
    }
    getContents = () => {
        const {
            type
        } = this.props;

        const language = this.state.language || 'en';
        const contents = {};
        _.forEach(this.props.translatables, (value, key) => {
            if(key.indexOf(type + '_s') !== -1 && key.indexOf('header') === -1) {
                const section = parseInt(key.split('_')[1].replace('s', ''), 10);
                if(section) {
                    if(!contents[section]) {
                        contents[section] = [];
                    }
                    const translation = value[language] || value['en-master'];
                    if(!translation.disabled) {
                        contents[section].push(translation.value);
                    }
                }
            }
        });
        return contents;
    }
    getSectionHeader = (section) => {
        const language = this.state.language || 'en';
        const translations = this.props.translatables[this.props.type + '_s' + section + '_header'];
        return translations && translations[language] ? translations[language].value : '';
    }
    getSectionOriginalContent = (section) => {
        const language = this.state.language || 'en';
        const type = this.props.type;
        const list = [];
        _.forEach(this.props.translatables, (value, key) => {
            if(key.indexOf(type + '_s' + section + '_p') !== -1) {
                const translation = value[language] || value['en-master'];
                if(!translation.disabled) {
                    list.push(translation.value);
                }
            }
        });
        return list;
    }
    render() {
        const {
            languages,
            t,
            agreement,
        } = this.props;
        const isAgreementEditable = isEditable(agreement);
        const contents = this.getContents();
        return (
            <div>
                    <h2>{t('default_language')}:</h2>
                    <div>
                        <select onChange={this.changeLanguage} value={ this.state.language }>
                            {
                                languages.map(lang => <option value={lang} key={'lang_' + lang}>{lang}</option>)
                            }
                        </select>
                    </div>
                {
                    (() => {
                        const rows = [];
                        _.forEach(contents, (contentList, section) => {
                            const overriden =  this.getOverridenContents(section);
                            const header = this.getSectionHeader(section);
                            const originalContent = this.getSectionOriginalContent(section);
                            const content = overriden ? overriden.content : contentList.map((row, idx) => <p key={'row' + idx}>{row}</p>);
                            if(this.state.editingSection === section) {
                                rows.push(
                                    <ContentRowForm
                                        t={ t }
                                        section={ section }
                                        editing={ !!overriden }
                                        content={ content }
                                        delete={ this.deleteContents }
                                        cancel={ () => this.setState( state => ({...state, editingSection: null})) }
                                        submit={ this.saveContents }
                                        header={header}
                                        key={'section_' + section}
                                        originalContent={ originalContent }
                                    />);
                            } else {
                                rows.push(
                                    <ContentRow
                                        t={t}
                                        editRow={() => this.editRow(section)}
                                        content={ content }
                                        section={ section }
                                        overriden={ !!overriden }
                                        header={header}
                                        key={'section_' + section}
                                        isEditable={ isAgreementEditable }
                                    /> );
                            }
                        })
                        return rows;
                    })()
                }
            </div>
        );
    }
}

export default AgreementContents;