import React from 'react';

import './Steps.css';

class Step extends React.Component {
    render() {
        const { selected, children, nextStep, prevStep, firstStep, lastStep } = this.props;

        return (
            <div className={`step ${selected ? `step-active` : ``}`}>
                { React.Children.map(children, (child) => {
                    const childWithProps = React.cloneElement(child, {
                        nextStep: nextStep,
                        prevStep: prevStep,
                        firstStep: firstStep,
                        lastStep: lastStep
                    });
                    return childWithProps;
                })}
            </div>
        );
    }
};

export default Step;