import React, { Component } from 'react';
import translations         from 'decorators/translations.js';
import PropTypes            from 'prop-types';
import { localeDateString } from 'helpers/stringTemplates';
import _ from 'lodash';
import {
    LoadingIndicator,
    DocumentList,
    DocumentListItem
} from 'components';
import { statusColor, getAgreementLink, getAgreementName } from './Utils';

class RecentAgreements extends Component {

    static propTypes = {
        agreements: PropTypes.array,
        frameAgreements: PropTypes.array
    };

    renderCreator = (createdBy) => {
        return (
            <span style={{ fontWeight: 'bold', color: '#555' }}>
                <span>
                { createdBy }
                </span>
            </span>
        );
    }

    render() {
        const { agreements, frameAgreements, t } = this.props;
        if (this.props.loading || !agreements || !frameAgreements) {
            return <LoadingIndicator visible styles={{ fontSize: '25px' }} />;
        }
        const combined = agreements.concat(frameAgreements);
        return (
            <DocumentList variant="compact">
                { combined.map((agreement, index) => {
                    if (agreement) {
                        const agreementName = getAgreementName(agreement.agreementType);
                        return (
                            <DocumentListItem
                                key={ index }
                                id={'RecentAgreements_agreement_' + index}
                                icon="file-text-o"
                                iconLabel={ t(agreement.agreementStatus) }
                                iconColor={ statusColor(agreement.agreementStatus) }
                                title={ t(agreementName) + ' ' + _.get(agreement, 'contractInfo.client.name', 'N/A') }
                                to={ getAgreementLink(agreement) }
                            >
                                <p>
                                    { this.props.isAdmin && agreement.createdBy && this.renderCreator(agreement.createdBy) } { localeDateString(agreement.modified || agreement.created) }
                                </p>
                            </DocumentListItem>
                        );
                    }
                }) }
            </DocumentList>
        );
    }
}

export default translations(RecentAgreements);
