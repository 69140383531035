import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium             from 'configuredRadium.js';

import {
    layout,
    measures,
    colors,
    commonStyles
} from 'styles/design.js';
import {
    SFInputField,
    SFTextareaField,
    Button
} from 'components';

const dataDiv = {
    display: 'inline-block',
    verticalAlign: 'top',
    paddingRight: measures.standard
};

class SystemComponentForm extends Component {

    static propTypes = {
        form: PropTypes.object.isRequired,
        addComponent: PropTypes.func.isRequired,
        removeComponent: PropTypes.func.isRequired,
        updateComponentValue: PropTypes.func.isRequired,

    };

    onChange = (key, index, event) => {
        this.props.updateComponentValue({key, index, value: event.target.value});
    };

    render() {
        const { form, t } = this.props;
        const locations = form.locations;
        return (
            <div>
                <div>
                    { (() => {
                        if (locations) {
                            return (
                                <div>
                                    {(() => {
                                        return locations.map((location, index) => {
                                            return (
                                                <div key={'location_' + index}>

                                                    <div style={Object.assign({}, dataDiv, {width: '30%'})}>
                                                        <SFInputField
                                                            id={'SystemComponentForm_component_' + index}
                                                            fieldName={t('component')}
                                                            defaultInput={true}
                                                            value = {location.location ? location.location.value : '' }
                                                            onChange = {(event) => this.onChange('location', index, event)}
                                                        />
                                                    </div>

                                                    <div style={Object.assign({}, dataDiv, {width: '40%'})}>
                                                        <SFTextareaField
                                                            id={'SystemComponentForm_description_' + index}
                                                            defaultInput={true}
                                                            fieldName={t('description')}
                                                            value={location.description ? location.description.value : ''}
                                                            onChange = {(event) => this.onChange('description', index, event)}
                                                            rows={1}
                                                        />
                                                    </div>
                                                    <div style={Object.assign({}, dataDiv, {width: '20%'})}>
                                                        <SFInputField
                                                            id={'SystemComponentForm_capacity_' + index}
                                                            defaultInput={true}
                                                            value = {location.capacity ? location.capacity.value : ''}
                                                            onChange = {(event) => this.onChange('capacity', index, event)}
                                                            fieldName={ t('amount') } />
                                                    </div>
                                                    <div style={Object.assign({}, dataDiv, {width: '50px', marginLeft: measures.standard, paddingTop: '2.5em', float: 'right'})}>
                                                        <Button
                                                            variant="delete"
                                                            id={'SystemComponentForm_delete_' + index}
                                                            onClick = { (e) => this.props.removeComponent(e, index) }
                                                        >
                                                            <i className="fa fa-trash" />
                                                        </Button>
                                                    </div>

                                                </div>
                                            );
                                        });
                                    })()}
                                </div>
                            );

                        }
                    })()}
                </div>
                <div style = {{ height: measures.double, marginBottom: measures.double, marginTop: measures.standard }}>
                    <a
                        id="SystemComponentForm_addComponent"
                        style = {{
                            ...layout.floatLeft,
                            ...commonStyles.button,
                            backgroundColor: colors.blue
                        }}
                        onClick = { this.props.addComponent }>
                        { this.props.t('add_system_component') }
                    </a>
                </div>
            </div>
        );
    }
}
export default translations(configuredRadium(SystemComponentForm));
