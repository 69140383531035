import React from 'react';
import translations from 'decorators/translations.js';
import ReportSection from './ReportSection.js';
import {
    ListManager
} from 'components';
import isEmpty from 'lodash/isEmpty';

const ByClientNet = ({
    agreementsByClientNet,
    t
}) => {
    if (isEmpty(agreementsByClientNet)) {
        return null;
    }
    return (
        <div>
            <ReportSection title={`${t('Serviflex agreements with SmartView')} (${agreementsByClientNet.withClientNetAgreements && agreementsByClientNet.withClientNetAgreements.length})`}>
                <ListManager listData={ agreementsByClientNet.withClientNetListData } />
            </ReportSection>
            <ReportSection title={`${t('Serviflex agreements without SmartView')} (${agreementsByClientNet.withoutClientNetAgreements && agreementsByClientNet.withoutClientNetAgreements.length})`}>
                <ListManager listData={ agreementsByClientNet.withoutClientNetListData } />
            </ReportSection>
        </div>

    );
}

export default translations(ByClientNet);