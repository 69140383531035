import React from 'react';

import './CostSummary.css';

const CostSummary = (props) => {
    return (
        <div className="cost-summary">
            {props.summartyTitle && <div className="cost-summary-title">{props.summaryTitle}</div>}
            {props.costs.map((costGroup, idx) => {
                return (
                    <div  key={'costGroup' + idx} className="cost-summary-group">
                        {
                            costGroup.title && <div className="cost-summary-h4"><h4>{costGroup.title}</h4></div>}
                        <div className="cost-summary-list">
                            {costGroup.data.map((cost, idx) => {
                                let rowClass = 'cost-summary-row';
                                if (cost.invalid) {
                                    rowClass += ' cost-summary-row-invalid';
                                }
                                if (cost.secondary) {
                                    rowClass += ' cost-summary-row-secondary'
                                }

                                return (
                                    <div key={'costRow' + idx} className={rowClass}>
                                        <div className="cost-summary-label">
                                            {cost.label}
                                        </div>
                                        <div className="cost-summary-value">
                                            {cost.value} {cost.percentage ? '%' : props.currency}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                );
            })}

            {props.costsTotalValue &&
            <div className="cost-summary-totals cost-summary-row">
                <div className="cost-summary-label">
                    {props.costsTotalLabel}
                </div>
                <div className="cost-summary-value">
                    {props.costsTotalValue} {props.currency}
                </div>
            </div>
            }
        </div>
    );
};

export default CostSummary;