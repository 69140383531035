import React, {Component}               from 'react';
import translations from 'decorators/translations.js';
import { connect }                      from 'react-redux';
import { localeDateString }             from 'helpers/stringTemplates';
import { getLanguage } from 'helpers/languageHelper';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';

import {
    PrintDropdownMenu,
    LoadingIndicator,
    AgreementListItem
} from 'components';

class BuildingAndAgreementRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPrintDropdownVisible: false
        };
    }

    openPrintDropdown = (event) => {
        event.preventDefault();
        this.setState({
            isPrintDropdownVisible: !this.state.isPrintDropdownVisible
        });
    }

    getLinkUrl = buildingId => {
        const {
            contractInfoId,
            match: {
                params: {
                    clientId
                }
            }
        } = this.props;
        return `/client/${clientId}/contractInfo/${contractInfoId}/building/${buildingId}/details`;
    }

    handleClick = (e, buildingId) => {
        e.preventDefault();
        this.props.history.push(this.getLinkUrl(buildingId));
    };

    render() {
        const {
            t,
            link,
            title,
            rowType,
            created,
            signedTime,
            approvalTime,
            modifiedTime,
            agreementStatus,
            hoverTitle,
            createdBy,
            id
        } = this.props;

        const lang = getLanguage(this.props.translations.language);

        let actions = [];

        if (rowType !== 'building') {
            actions = [
                <span style={{ position: 'relative' }}>
                    <a
                        id={id}
                        onClick={ (event) => this.openPrintDropdown(event) }
                    >
                        <i className="fa fa-print" />
                    </a>
                    <PrintDropdownMenu
                        isVisible={ this.state.isPrintDropdownVisible }
                        style={{ marginTop: '1.25em', right: '-1em' }}
                        action={ this.props.downloadFile }
                        agreementId={ this.props.agreementId }
                        lang={ lang }
                    />
                </span>
            ];
        }
        else {
            actions = [
                <span>
                    <a
                        id={ `edit_${id}` }
                        onClick={ e => this.handleClick(e, this.props.buildingId) }
                    >
                        <span className="agreement-list-item-action-icon">
                            <i className="fa fa-info" />
                        </span>
                    </a>
                </span>
            ]
        }

        let timeString = '';
        let timeValue = '';

        if(signedTime) {
            timeString =  t('signed');
            timeValue = localeDateString(signedTime, { showTimeZone:true });
        } else if(approvalTime) {
            timeString = t('approved');
            timeValue = localeDateString(approvalTime, { showTimeZone:true });
        } else if(modifiedTime) {
            timeString = t('modified');
            timeValue = localeDateString(modifiedTime, { showTimeZone:true });
        } else if(created) {
            timeString = t('created');
            timeValue = localeDateString(created, { showTimeZone:true });
        }

        return (
            <AgreementListItem
                title={title}
                hoverTitle={hoverTitle}
                variant={ rowType !== 'addon' ? 'primary' : 'secondary'}
                link={link}
                id={this.props.id}
                timeString={timeString}
                timeValue={timeValue}
                agreementStatus={agreementStatus}
                createdBy={createdBy}
                actions={actions}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client,
        profile: state.userProfile,
        cookies: state.cookies,
        download: state.download,
        translations: state.translations
    }
}
const connector = connect(mapStateToProps);
export default withRouter(translations(connector(BuildingAndAgreementRow)));
