import React, {Component}      from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import { commonStyles, measures, layout } from 'styles/design.js';
import { SFInputField, CostNumber, SFMultiplierSelect }       from 'components';

const smallFieldStyle = {
    ...layout.inlineBlock,
    width: '9em',
    marginRight: measures.standard
};

const placeHolderFieldStyle = {
    ...layout.inlineBlock,
    width: '21em',
    marginRight: measures.standard
};

class SubcontractingEdit extends Component {

    static propTypes = {
        form: PropTypes.object.isRequired,
        calculation: PropTypes.array,
        countryConfig: PropTypes.object.isRequired
    };

    static defaultProps = {
        repairs: [],
        calculation: [],
    };
    render() {
        const { t, form: {subcontracting}, countryConfig, calculation } = this.props;
        return (
            <div className="clearfix">
                <div>
                    { (() => {
                        if (subcontracting) {
                            return subcontracting.map((subcontractingRow, index) => {
                                const calc = calculation && calculation[index] ? calculation[index] : {cost:0};
                                if(subcontractingRow.margin && parseFloat(subcontractingRow.margin.value) < countryConfig.subcontractingMinMargin) {
                                    subcontractingRow.margin.error = t('invalid_margin_limit_is') + ': ' + countryConfig.subcontractingMinMargin;
                                } else if(subcontractingRow.margin) {
                                    subcontractingRow.margin.error = null;
                                }
                                return (
                                    <div key = { index } style = {{ marginBottom: measures.double }}>
                                        <div style={{ overflow: 'hidden' }}>
                                            <div>
                                                <button type = "button" style = {{ ...commonStyles.smallDeleteButton, ...layout.floatRight, width: '5em' }} onClick = { () => this.props.handleRemoveSubcontracting(index) }>{ t('delete') }</button>
                                                <h3 style = {{ marginBottom: measures.standard, minHeight: measures.double }} >{ subcontractingRow.description ? subcontractingRow.description.value : '' }</h3>
                                            </div>
                                            <SFInputField
                                                defaultInput={true}
                                                fieldName= { t('description') }
                                                { ...subcontractingRow.description }
                                            />
                                            <div style = { smallFieldStyle }>
                                                <SFInputField
                                                    defaultInput={true}
                                                    fieldName= { t('cost') + ' ' + t('vat') + ' 0%' }
                                                    { ...subcontractingRow.price }
                                                    placeholder = "0,00"
                                                />
                                            </div>
                                            <div style = { smallFieldStyle }>
                                                <SFInputField
                                                    defaultInput={true}
                                                    fieldName= { t('net_profit_margin') }
                                                    { ...subcontractingRow.margin }
                                                    placeholder = "0,00"
                                                />
                                            </div>
                                            <SFMultiplierSelect
                                                multiplier = { subcontractingRow.multiplier }
                                            />
                                            <div style = { placeHolderFieldStyle }>
                                            </div>
                                        </div>
                                        <div style = { layout.floatRight }>
                                            {
                                                (() => {
                                                    if(this.props.calculating) {
                                                        return (<span>{t('calculating')}</span>);
                                                    }
                                                    return (
                                                        <CostNumber>{ calc.cost }</CostNumber>
                                                    );
                                                })()
                                            }
                                        </div>
                                    </div>
                                );
                            });
                        }
                    })()}
                </div>
            </div>
        );}
}

export default translations(SubcontractingEdit);
