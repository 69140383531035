import React from 'react';

import {
    SFInputField,
    Dialog
} from 'components/index';

class BundleEditDetail extends React.Component {
    state = {};

    componentWillMount() {
        if (this.props.value != null) {
            this.setState({
                value: this.props.value
            });
        }
    }

    setValue = (value) => {
        this.setState({
            value: value
        });
    };

    submit = (e) => {
        const detailId = this.props.detailId;
        const bundleId = this.props.bundleId;
        const value = this.state.value;
        this.props.submit({bundleId, detailId, value});
    };

    handleCancel = (e) => {
        this.props.handleCancel();
    };

    render() {
        const {t, detailId, detailName} = this.props;

        return (
            <Dialog 
                actions={[
                {
                    onClick: this.handleCancel,
                    title: t('cancel'),
                    variant: 'link'
                },{
                    onClick: this.submit,
                    title: t('save'),
                    variant: 'primary'
                }]}
            >
                <div className="bundle-edit-defail-form">
                    <SFInputField
                        id={'BundleEditDetail_' + detailId + '_input'}
                        defaultInput={true}
                        fieldName={detailName}
                        onChange={(event) => this.setValue(event.target.value)}
                        value={this.state.value}
                    />
                </div>
            </Dialog>
        );
    }
}

export default BundleEditDetail;