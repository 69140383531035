import React, {Component }   from 'react';
import configuredRadium                 from 'configuredRadium.js';
import { reduxForm }                    from 'redux-form';
import {
    DisciplineIcon,
    SFInputField,
    SaveButton,
    CancelButton
}                                       from 'components';

import {
    layout,
    colors,
    measures,
    commonStyles
}                                       from 'styles/design.js';
import { decimalRound } from 'helpers/stringTemplates.js';

import { deepCopy } from 'helpers/objectHelper.js';

import _ from 'lodash';
const headerCellStyle = {
    padding: measures.half
};
const dataCellStyle = {
    padding: measures.half
};
const reduxFormObject = {
    form: 'frameAgreementForm',
    fields: [
        'id',
        'name',
        'defaultDiscount',
        'address',
        'city',
        'zip',
        'localPhoneNumber',
        'discounts[].disciplineType',
        'discounts[].disciplineCategory',
        'discounts[].discount',
        'discounts[].types[].name',
        'discounts[].types[].discount',
        'discounts[].types[].basicCharge'
    ]
};


class DepartmentConfigForm extends Component {

    componentWillMount() {
        const disciplines = this.props.disciplines;
        const department = deepCopy(this.props.department);
        const discounts = [];
        if(department.discounts && department.discounts.length > 0) {
            department.discounts.map((discount ,idx) => {
                const exists = _.find(disciplines, {disciplineCategory: discount.disciplineCategory});
                if(exists) {
                   discounts.push(discount);
                }
            })
        }
        //Make sure that department haven't got any disciplines that are not configured.
        department.discounts = discounts;

        if(disciplines) {
            disciplines.map((discipline) => {
                if (department.discounts) {
                    const existingDiscount = _.find(department.discounts, {disciplineCategory: discipline.disciplineCategory});
                    const newPriceRows = [];
                    //Iterate both price arrays and find matches
                    if(discipline.pricing && discipline.pricing.length > 0) {
                        discipline.pricing.map((price) => {
                            const newRow = {
                                name: price.name,
                                basicCharge: price.basicCharge,
                                discount: parseFloat(department.defaultDiscount) || null
                            };
                            if (existingDiscount && existingDiscount.types && existingDiscount.types.length > 0) {
                                const existingRow = _.find(existingDiscount.types, {name: price.name});
                                if (existingRow && existingRow.discount) {
                                    newRow.discount = existingRow.discount;
                                }
                            }
                            newPriceRows.push(newRow);
                        });
                    }
                    if (existingDiscount) {
                        existingDiscount.types = newPriceRows;
                    } else {
                        const newDiscount = {
                            disciplineCategory: discipline.disciplineCategory,
                            discount: department.defaultDiscount,
                            types: newPriceRows
                        };
                        department.discounts.push(newDiscount);
                    }
                } else {
                    department.discounts = [];
                }
            });
        }
        this.props.initializeForm(department);
    }

    editPricing(category) {
        this.setState({editPricing: category});
    }
    addDiscount(category) {
        this.props.fields.discounts.addField({disciplineCategory: category, discount: 0});
    }
    removeDiscount(idx) {
        this.props.fields.discounts.removeField(idx);
    }
    render() {
        const {t, fields} = this.props;
        return (
            <div>
                <SFInputField formProperty = {this.props.fields.name} fieldName = {t('name')} />
                <SFInputField formProperty = {this.props.fields.address} fieldName = {t('address')} />
                <SFInputField formProperty = {this.props.fields.city} fieldName = {t('city')} />
                <SFInputField formProperty = {this.props.fields.zip} fieldName = {t('zip')} />
                <SFInputField formProperty = {this.props.fields.localPhoneNumber} fieldName = {t('local_phone_number')} />
                <SFInputField formProperty = {this.props.fields.defaultDiscount} fieldName = {t('default_discount')} />
                <div style={commonStyles.dataContentHeaderDiv}>
                    {t('disciplines')}:
                </div>
            {
                (() => {
                    return fields.discounts.map((discount) => {
                        const category = discount.disciplineCategory.value;
                        return (
                            <div key={ 'department-config-discipline-selection-' + category }
                                 style={ [ commonStyles.bottomDivider, { padding: measures.standard, marginBottom: measures.standard, marginTop: measures.standard, backgroundColor: colors.lightGray} ] }>
                                <div style={ [layout.verticalMiddle] }>
                                    <label>
                                        <div style={ [layout.floatLeft, {width: '30px'}] }>
                                            <DisciplineIcon style={ commonStyles.disciplineIconStyle }
                                                            diciplineType={ category }/>
                                        </div>
                                        <div
                                            style={ [{fontSize: '1em', paddingTop: measures.quarter, paddingBottom: measures.quarter }]}>
                                            <h2 style={ commonStyles.disciplineNameStyle }>{ t(category) } </h2>
                                        </div>
                                    </label>
                                </div>
                                <div>
                                    <table style = {{width: '100%'}}>
                                        <thead>
                                        <tr>
                                            <td style = {headerCellStyle}>
                                                {t('name')}
                                            </td>
                                            <td style = {headerCellStyle}>
                                                {t('basic_charge')}
                                            </td>
                                            <td style = {headerCellStyle}>
                                                {t('discount')}
                                            </td>
                                            <td style = {headerCellStyle}>
                                                {t('discounted_basic_charge')}
                                            </td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            (() => {
                                                if(discount.types) {
                                                    return discount.types.map((price, idx) => {
                                                        return (
                                                            <tr key={'price_idx_' + idx}>
                                                                <td style = {dataCellStyle}>
                                                                    {price.name.value}
                                                                </td>
                                                                <td style = {dataCellStyle}>
                                                                    {price.basicCharge.value}
                                                                </td>
                                                                <td style = {dataCellStyle}>
                                                                    <SFInputField formProperty = {price.discount} />
                                                                </td>
                                                                <td style = {dataCellStyle}>
                                                                    {decimalRound(price.basicCharge.value * (( 100 - price.discount.value) / 100), this.props.countryConfig.currency)}
                                                                </td>
                                                            </tr>
                                                        );
                                                    });
                                                }
                                            })()
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        );
                    });
                })()
            }
                <div style={ [layout.floatRight, layout.inlineBlock, { clear: 'both'}] }>
                    <SaveButton onClick={ this.props.handleSubmit } disabled={ this.props.submitting } submitting={ this.props.submitting }/>
                    <div style = {{ display: 'inline-block', float: 'right' }}><CancelButton cancelAction = { this.props.cancelEdit } /></div>
                </div>
            </div>
        );
    }
}

export default reduxForm(reduxFormObject)(configuredRadium(DepartmentConfigForm));
