import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import configuredRadium             from 'configuredRadium.js';

import {
    colors
}                                   from 'styles/design.js';

const styles = {
    listItem: {
        display: 'block',
        position: 'relative',
        padding: '1em 1em 1em 3.5em',
        marginBottom: '0.125em',
        backgroundColor: colors.lightGray
    },
    listHeader: {
        padding: '1em',
        backgroundColor: colors.blue,
        color: colors.white,
        fontFamily: 'Sanchez-Regular',
        fontWeight: 'normal'
    },
};

class ListTitle extends Component {
    render() {
        return (
            <h3 style={ [ styles.listItem, styles.listHeader ] }>
                { this.props.title }
            </h3>
        );
    }
}
export default translations(configuredRadium(ListTitle));
