import React from 'react';
import Steps from '../../../../components/Steps/Steps';
import Step from '../../../../components/Steps/Step';
import { Button } from 'components';
import SensorForm from './SensorForm';
import _ from 'lodash';
import translations from 'decorators/translations.js';

import './BundleForm.css';

class BundleForm extends React.Component {
    render() {
        const { t } = this.props;
        let sensors = this.props.sensors;
        sensors = _.orderBy(sensors, ['order']);

        if (sensors && sensors.length > 0 && this.props.disciplines && this.props.types && this.props.agreementDisciplines) {
            return (
                <div className="bundle-form">
                    <div>
                        <div className="bundle-form-close">
                            <Button variant="link" onClick={this.props.onClose}>
                                <i className="fa fa-close fa-2x" />
                            </Button>
                        </div>
                        <h1 className="bundle-form-title">
                            {t('Configure sensors')}
                        </h1>
                    </div>
                    <Steps onStepChange={this.props.onStepChange}>
                        {sensors.map(sensor => (
                            <Step key={sensor.id}>
                                <SensorForm
                                    systemsByDiscipline={this.props.systemsByDiscipline}
                                    sensor={sensor}
                                    ahuTypes={this.props.ahuTypes}
                                    agreement={this.props.agreement}
                                    cancel={this.props.cancel}
                                    submit={this.props.submit}
                                    disciplines={this.props.disciplines}
                                    agreementDisciplines={this.props.agreementDisciplines}
                                    types={this.props.types}
                                    onClose={this.props.onClose}
                                    isNew
                                />
                            </Step>
                        ))}
                    </Steps> 
                </div>
            );
        }
    };
}

export default translations(BundleForm);