import React                    from 'react';
import translations from 'decorators/translations.js';
import configuredRadium         from 'configuredRadium.js';
import {
    colors,
    size,
}                               from 'styles/design.js';

const agreementTableStyle = {
    marginTop: '0',
    display: 'table',
    width: '100%',
    cursor: 'pointer'
};
const agreementTableLabelCellStyle = {
    paddingRight: '1em',
    verticalAlign: 'top',
    display: 'table-cell',
    paddingLeft: '5px',
    width: '70px',
};
const statusColor = (status) => {
    return {
        draft: colors.lightBlue,
        rejected: colors.basicRed,
        approved: colors.blue,
        sent_for_approval: colors.blue,
    }[status] || colors.blue;
};
const labelStyle = (agreement) => {
    return [
        size.moreTiny,
        {
            marginTop: '5px',
            textAlign: 'center',
            display: 'block',
            color: statusColor(agreement.agreementStatus)
        }
    ];
};
const iconStyle = (agreement) => {
    return {
        fontSize: '2.5em',
        color: statusColor(agreement.agreementStatus)
    };
};

const AgreementIcon = (props) => {

    return (
        <div style={ agreementTableStyle }>
            <div style={ agreementTableLabelCellStyle }>
                <div style={{ textAlign: 'center' }}>
                    <i style={ iconStyle(props.agreement) } className={ props.icon } />
                    <span style={ labelStyle(props.agreement) }>{ props.label }</span>
                </div>
            </div>
        </div>
    );
};

export default translations(configuredRadium(AgreementIcon));
