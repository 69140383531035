import React from 'react';
import './DummyInputField.css';

const DummyInputField = ({ label, value }) => {
    return <div className="dummy-input-field">
        <div className="dummy-input-field__label">
            { label }
        </div>
        <div className="dummy-input-field__input">
            { value }
        </div>
    </div>
};

export default DummyInputField;
