import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {reduxForm} from 'redux-form';

import {
    Button,
    SFInputField,
    RichTextEditor
} from 'components';

import {
    layout,
    commonStyles
} from 'styles/design.js';

const reduxFormObject = {
    form: 'descriptionsForm',
    fields: [
        'id',
        'slaBasicDescription',
        'slaResponseTimeDescription',
        'slaClientNetDescription',
        'slaYearlyMeetingDescription',
        'slaSupport247description',
        'slaSupport247price'
    ]
};

class DescriptionForm extends Component {

    componentWillMount() {
        this.props.initializeForm(this.props.countryConfig);
        this.setState({canEdit: true});
    }

    handleCancel = () => {
        this.props.initializeForm(this.props.countryConfig);
        this.props.cancelAction();
    }

    descriptionChanged = (key, value) => {
        this.props.fields[key].onChange(value);
    }

    render() {
        const {t, fields} = this.props;
        return (
            <div>
                <div style={commonStyles.dataParentDiv}>
                    <div style={commonStyles.dataContentHeaderDiv}>
                        {t('sla_descriptions')}:
                    </div>
                    <input type="hidden" {...fields.id} />
                    {/*
                    <div>
                        <RichTextEditor
                            onChange={this.descriptionChanged}
                            descriptionKey={'slaBasicDescription'}
                            value={this.props.countryConfig.slaBasicDescription}
                            label={t('sla_basic_description')}
                        />
                    </div>
                    <div>
                        <RichTextEditor
                            onChange={this.descriptionChanged}
                            descriptionKey={'slaResponseTimeDescription'}
                            value={this.props.countryConfig.slaResponseTimeDescription}
                            label={t('sla_responsetime_description')}
                        />
                    </div>
                    <div>
                        <RichTextEditor
                            onChange={this.descriptionChanged}
                            descriptionKey={'slaClientNetDescription'}
                            value={this.props.countryConfig.slaClientNetDescription}
                            label={t('sla_clientnet_description')}
                        />
                    </div>
                    <div>
                        <RichTextEditor
                            onChange={this.descriptionChanged}
                            descriptionKey={'slaYearlyMeetingDescription'}
                            value={this.props.countryConfig.slaYearlyMeetingDescription}
                            label={t('sla_yearlymeeting_description')}
                        />
                    </div>

                    <div>
                        <RichTextEditor
                            onChange={this.descriptionChanged}
                            descriptionKey={'slaSupport247description'}
                            value={this.props.countryConfig.slaSupport247description}
                            label={t('sla_support_247_description')}
                        />
                    </div>
                    */}
                    <div>
                        <SFInputField
                            fieldName={t('sla_support_247_price') + ' (' + this.props.countryConfig.currency + ')'}
                            formProperty={fields.slaSupport247price} placeholder="0"/>
                    </div>

                    <div style={{...layout.floatRight, ...layout.inlineBlock, clear: 'both'}}>
                        <Button
                            id="CountrySLADescriptionsForm_cancel"
                            variant="cancel"
                            onClick={this.handleCancel}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            id="CountrySLADescriptionsForm_save"
                            onClick={this.props.handleSubmit}
                            disabled={this.props.submitting}
                            loading={this.props.submitting}
                        >
                            {t('save')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default translations(reduxForm(reduxFormObject)(DescriptionForm));
