import React            from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';
import {
    KeyValueDiv
}   from 'components/index.js';

const AddressInformation = (props) => {
    const { address, t } = props;
    const addressValues = address || {};
    if(props.styling === 'clientInfo') {
        return (
            <div>
                <KeyValueDiv
                    id={props.type + 'Street'}
                    name={ t('street')}
                    value={ addressValues.street }
                    withColon={true}
                    rightStyles={{fontWeight: 'bold'}}
                />
                <KeyValueDiv
                    id={props.type + 'Zip'}
                    name={ t('zip')}
                    value={ addressValues.zip }
                    withColon={true}
                    rightStyles={{fontWeight: 'bold'}}
                />
                <KeyValueDiv
                    id={props.type + 'City'}
                    name={ t('city')}
                    value={ addressValues.city }
                    withColon={true}
                    rightStyles={{fontWeight: 'bold'}}
                />
            </div>
        );
    }
    return (
        <div>
            <div>{props.t('street')}: {props.address.street}</div>
            <div>{props.t('zip')}: {props.address.zip}</div>
            <div>{props.t('city')}: {props.address.city}</div>
        </div>
    );
};
export default translations(configuredRadium(AddressInformation));
