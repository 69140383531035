import React from 'react';
import {
    ConfirmableButton
} from 'components';

const AgreementDeleteActions = ({ onDelete, t }) => <ConfirmableButton
    variant="delete"
    buttonIcon={ 'fa fa-trash' }
    buttonText={ t('delete') }
    confirmButtonText={ t('yes') }
    confirmText={ t('confirm_delete_agreement') }
    action={ onDelete }
/>;

export default AgreementDeleteActions;
