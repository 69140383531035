import React from 'react';

import translations from 'decorators/translations.js';

import {
    SFInputField,
    SFSelectField,
    Button
} from 'components/index';
import _ from 'lodash';

class CollectorForm extends React.Component {
    state = {errors: {}, type: 'collector'};

    componentWillMount() {
        if(this.props.collector) {
            const collector = this.props.collector;
            const state = Object.assign({},
                this.state,
                {
                    id: collector.id,
                    type: collector.type,
                    receiverLocation: collector.receiverLocation,
                    energyMeterLocation: collector.energyMeterLocation,
                    energyMeterIsMain: collector.energyMeterIsMain,
                    energyMeterServingArea: collector.energyMeterServingArea,
                    energyMeterServingDescription: collector.energyMeterServingDescription,
                    buildingId: collector.buyilding ? collector.building.id : null
                });
            this.setState(state);
        }
        if(this.props.isNew) {
            this.setState({
                hasKitpack: false
            });
        }
    }

    setValue = (key, value) => {
        this.setState((state) => {
            state[key] = value;
            delete state.errors[key];
            return {
                ...state
            }
        });
    };
    submit = () => {
        const values = this.state;
        if(!this.state.errors || _.isEmpty(this.state.errors)) {
            let payload = {
                type: values.type,
                id: values.id,
                receiverLocation: values.receiverLocation,
                energyMeterLocation: values.energyMeterLocation,
                energyMeterIsMain: values.energyMeterIsMain,
                energyMeterServingArea: values.energyMeterServingArea,
                energyMeterServingDescription: values.energyMeterServingDescription,
                buildingId: values.buildingId
            };
            /*
            if (this.props.isNew) {
                payload.hasKitpack = this.state.hasKitpack;
            }
            */
            this.props.submit(payload);
        }
    };

    render() {
        const {t, agreement} = this.props;
        return (
            <div>
                <h2 style={{fontWeight: 'normal', textRendering: 'optimizeLegibility'}}>{this.props.isNew ? t('Add new collector') : t('Edit collector')}</h2>
                <h3 style={{fontWeight: 'normal', marginTop: '2em'}}>
                    { t('Receiver') }
                </h3>
                <SFSelectField
                    id="CollectorForm_buildingId"
                    defaultInput={true}
                    value={this.state.buildingId || ''}
                    onChange={ (event) => {
                        const buildingId = event.target.value;
                        this.setState( state => ({...state, buildingId}))
                    }}
                    fieldName={t('building')}
                >
                    <option value="">{t('select')}</option>
                    {
                        agreement.buildings && agreement.buildings.length > 0 && agreement.buildings.map( building =>
                            <option value={building.id} key={building.id}>{building.name}</option> )
                    }

                </SFSelectField>
                <SFInputField
                    id="CollectorForm_receiverLocation"
                    defaultInput={true}
                    showErrorBelow={true}
                    error={this.state.errors['receiverLocation']}
                    fieldName={this.props.t('Receiver location')}
                    onChange={(event) => this.setValue('receiverLocation', event.target.value)}
                    value={this.state.receiverLocation}
                />
                <h3 style={{fontWeight: 'normal', marginTop: '2em'}}>
                    { t('Energy meter') }
                </h3>
                <SFInputField
                    id="CollectorForm_energyMeterLocation"
                    defaultInput={true}
                    showErrorBelow={true}
                    error={this.state.errors['energyMeterLocation']}
                    fieldName={this.props.t('Energy meter location')}
                    onChange={(event) => this.setValue('energyMeterLocation', event.target.value)}
                    value={this.state.energyMeterLocation}
                />
                <div style={{ margin: '2em 0 1em' }}>
                    <p>{ t('Meter type') }</p>
                    <label style={{margin: '0.5em 0', display:'block'}}>
                        <input
                            type="radio"
                            name="energyMeterIsMain"
                            id="CollectorForm_energyMeterIsMain_true"
                            onChange={(event) => this.setValue('energyMeterIsMain', true)}
                            checked={this.state.energyMeterIsMain === true}
                        /> {t('Main meter')}
                    </label>
                    <label style={{margin: '0.5em 0', display:'block'}}>
                        <input
                            type="radio"
                            name="energyMeterIsMain"
                            id="CollectorForm_energyMeterIsMain_false"
                            onChange={(event) => this.setValue('energyMeterIsMain', false)}
                            checked={this.state.energyMeterIsMain === false}
                        /> {t('Sub meter')}
                    </label>
                </div>
                <SFInputField
                    id="CollectorForm_mainMeterServingArea"
                    type="number"
                    defaultInput={true}
                    showErrorBelow={true}
                    error={this.state.errors['energyMeterServingArea']}
                    fieldName={this.props.t('Energy meter serving area') + ' (m²)'}
                    onChange={(event) => this.setValue('energyMeterServingArea', event.target.value)}
                    value={this.state.energyMeterServingArea}
                />
                { !this.state.energyMeterIsMain &&
                    <SFInputField
                        id="CollectorForm_mainMeterServingDescription"
                        defaultInput={true}
                        showErrorBelow={true}
                        error={this.state.errors['energyMeterServingDescription']}
                        fieldName={this.props.t('Energy meter serving area description')}
                        onChange={(event) => this.setValue('energyMeterServingDescription', event.target.value)}
                        value={this.state.energyMeterServingDescription}
                    />
                }
                {/*this.props.isNew &&
                    <label style={{margin: '2em 0', display:'block'}}>
                        <input
                            type="checkbox"
                            id="CollectorForm_hasKitpack"
                            onChange={(event) => this.setValue('hasKitpack', event.target.checked)}
                            value={this.state.hasKitpack}
                        /> {t('Include default kitpack')}
                    </label>
                */}
                <div className="FormContainer">
                    <div style={{float: 'right', marginTop: '1em'}}>
                        <Button
                            id="CollectorForm_cancel"
                            variant="link"
                            onClick={this.props.cancel}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            id="CollectorForm_save"
                            variant="primary"
                            loading={this.props.submitting}
                            onClick={this.submit}
                            disabled={this.props.submitting}>
                            {t('save')}
                        </Button>
                    </div>
                </div>
            </div>
        );

    }
}

export default translations(CollectorForm);