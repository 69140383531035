
const LOAD              = 'ServiFlex/DisciplineConfig/LOAD',
    LOAD_SUCCESS        = 'ServiFlex/DisciplineConfig/LOAD_SUCCESS',
    LOAD_FAIL           = 'ServiFlex/DisciplineConfig/LOAD_FAIL',
    SAVE                = 'ServiFlex/DisciplineConfig/SAVE',
    SAVE_SUCCESS        = 'ServiFlex/DisciplineConfig/SAVE_SUCCESS',
    SAVE_FAIL           = 'ServiFlex/DisciplineConfig/SAVE_FAIL',
    LOAD_ALL            ='ServiFlex/DisciplineConfig/LOAD_ALL',
    LOAD_ALL_SUCCESS    ='ServiFlex/DisciplineConfig/LOAD_ALL_SUCCESS',
    LOAD_ALL_FAIL       ='ServiFlex/DisciplineConfig/LOAD_ALL_FAIL',
    TOGGLE              ='ServiFlex/DisciplineConfig/TOGGLE',
    TOGGLE_SUCCESS      ='ServiFlex/DisciplineConfig/TOGGLE_SUCCESS',
    TOGGLE_FAIL         ='ServiFlex/DisciplineConfig/TOGGLE_FAIL',
    SAVE_GLOBAL_TYPE = 'ServiFlex/Discipline/SAVE_GLOBAL_TYPE',
    SAVE_GLOBAL_TYPE_SUCCESS = 'ServiFlex/Discipline/SAVE_GLOBAL_TYPE_SUCCESS',
    SAVE_GLOBAL_TYPE_FAIL = 'ServiFlex/Discipline/SAVE_GLOBAL_TYPE_FAIL',
    DELETE_GLOBAL_TYPE = 'ServiFlex/Discipline/DELETE_GLOBAL_TYPE',
    DELETE_GLOBAL_TYPE_SUCCESS = 'ServiFlex/Discipline/DELETE_GLOBAL_TYPE_SUCCESS',
    DELETE_GLOBAL_TYPE_FAIL = 'ServiFlex/Discipline/DELETE_GLOBAL_TYPE_FAIL';

const initialState = {
    loading: false,
    config: {},
    loaded: false,
    reload: true,
    saving: false,
    loadingAll: false,
    loadedAll: false,
    loadAllError: null,
    configs: [],
    frameAgreementConfigs: [],
    calculatedDiscipline: {},
    toggling: false,
    toggled: false,
    toggleError: null,
    toggleResult: null,
    savingType: false,
    deletingType: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                config: action.result || {}
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SAVE:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                error: action.error
            };
        case SAVE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };
        case LOAD_ALL:
            return {
                ...state,
                loadingAll: true,
                loadedAll: false,
                loadAllError: null,
            };
        case LOAD_ALL_SUCCESS:
            const data = {};
            if (action.agreementType === 'frameAgreement') {
                data.frameAgreementConfigs = action.result;
            } else {
                data.configs = action.result;
            }
            return {
                ...state,
                loadingAll: false,
                loadedAll: true,
                ...data
            };
        case LOAD_ALL_FAIL:
            return {
                ...state,
                loadingAll: false,
                loadedAll: false,
                loadAllError: action.error,
            };
        case TOGGLE:
            return {
                ...state,
                toggling: true,
                toggleError: null
            };
        case TOGGLE_SUCCESS:
            return {
                ...state,
                toggling: false,
                toggled: true,
                toggleResult: true
            };
        case TOGGLE_FAIL:
            return {
                ...state,
                toggling: false,
                toggled: false,
                toggleError: action.error
            }
        case SAVE_GLOBAL_TYPE:
            return {
                ...state,
                savingType: true,
                error: action.error
            };
        case SAVE_GLOBAL_TYPE_SUCCESS:
            return {
                ...state,
                savingType: false,
            };
        case SAVE_GLOBAL_TYPE_FAIL:
            return {
                ...state,
                savingType: false,
                error: action.error
            };
        case DELETE_GLOBAL_TYPE:
            return {
                ...state,
                deletingType: true,
                error: action.error
            };
        case DELETE_GLOBAL_TYPE_SUCCESS:
            return {
                ...state,
                deletingType: false,
            };
        case DELETE_GLOBAL_TYPE_FAIL:
            return {
                ...state,
                deletingType: false,
                error: action.error
            };
        default:
            return state;
    }
}

export function loadConfig(countryId, category, type, accessToken) {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client) => client.get(
            `/disciplineconfigs/${countryId}/${category}/${type}`, { accessToken }
        )
    };
}

export function saveConfig(disciplineConfig, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.put(
            '/disciplineconfigs', { data: disciplineConfig, accessToken }
        )
    };
}

export function loadAllDisciplineConfigs(countryId, agreementType, accessToken) {
    let filter;
    if (!countryId) {
        filter = { where: { global: true }};
    } else {
        filter = { where: { countryId: countryId }};
    }
    if (agreementType && agreementType === 'frameAgreement') {
        filter.where.frameAgreement = true;
    }
    return {
        types: [LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_FAIL],
        promise: client => client.get(`/disciplineConfigs?filter=${JSON.stringify(filter)}`, { accessToken }),
        agreementType
    };
};

export function disableDisciplineTypeForCountry(id, country, accessToken) {
    return {
        types: [TOGGLE, TOGGLE_SUCCESS, TOGGLE_FAIL],
        promise: client => client.post(`/disciplineConfigs/disableTypeFromCountry?id=${id}&country=${country}`, { accessToken })
    };
}

export function enableDisciplineTypeForCountry(id, country, accessToken) {
    return {
        types: [TOGGLE, TOGGLE_SUCCESS, TOGGLE_FAIL],
        promise: client => client.post(`/disciplineConfigs/enableTypeForCountry?id=${id}&country=${country}`, { accessToken })
    };
}

export function saveGlobalDisciplineType(config, accessToken) {
    return {
        types: [SAVE_GLOBAL_TYPE, SAVE_GLOBAL_TYPE_FAIL, SAVE_GLOBAL_TYPE_FAIL],
        promise: (client) => client.patch(
            '/disciplineconfigs/upsertGlobal', { data: config, accessToken }
        )
    };
}

export function deleteGlobalDisciplineType(id, accessToken) {
    return {
        types: [DELETE_GLOBAL_TYPE, DELETE_GLOBAL_TYPE_FAIL, DELETE_GLOBAL_TYPE_FAIL],
        promise: (client) => client.del(
            `/disciplineConfigs/deleteDisciplineType?id=${id}`, { accessToken }
        )
    };
}