import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import { connect }                  from 'react-redux';
import { loadAllDisciplineConfigs } from 'redux/modules/disciplineConfig';

import {
    StandardPage,
    BreadcrumbBar,
    AdminSubNavigation,
    AdminDisciplineSelectionList,
    Header,
    LoadingIndicator
} from 'components';

import _ from 'lodash';

import { loadCountry } from 'redux/modules/country.js';

import { loadWithType } from 'redux/modules/translations';

import {
    loadFiles,
    uploadFile,
    deleteFile
}                               from 'redux/modules/files.js';
import { download } from 'redux/modules/download.js';

class DisciplineConfigurationView extends Component {

    componentWillMount() {
        const { dispatch, match: {params}} = this.props;
        const accessToken = this.props.profile.accessToken;
        const promises = [];
        promises.push(dispatch(loadFiles('agreementfiles', 'disciplineterms', accessToken)));
        if(!this.props.discipline.loaded || _.isEmpty(this.props.discipline.disciplineMetadata)) {
            promises.push(dispatch(loadAllDisciplineConfigs(params.countryId, null, accessToken)));
        }
        if(!this.props.countries.loaded) {
            promises.push(dispatch(loadCountry(params.countryId, accessToken)));
        }
        return Promise.all(promises).then( res => {
            const country = this.props.countries.selectedCountry;
            const languages = country.defaultLanguage !== 'en' ? ['en', country.defaultLanguage] : ['en'];
            return this.props.loadWithType(1, languages, params.countryId, accessToken)
        })
    }

    checkExisting(files, selectedCountry, disciplineCategory) {
        const found = {};
        _.forEach(files, function check(value) {
            if (value.name === `terms_and_conditions_discipline_${disciplineCategory}_en_${selectedCountry.id}.pdf`) {
                found.en = value;
            }
            if (value.name === `terms_and_conditions_discipline_${disciplineCategory}_${selectedCountry.defaultLanguage}_${selectedCountry.id}.pdf`) {
                found.local = value;
            }
        });
        return found;
    }
    deleteFile = (event, filename) => {
        this.setState({ deletingFile: false });
        this.props.deleteFile(
            'agreementfiles',
            'disciplineterms',
            filename,
            this.props.profile.accessToken
        ).then( res => {
            if (!res.error) {
                this.props.dispatch(loadFiles('agreementfiles', 'disciplineterms', this.props.profile.accessToken));
                this.setState({ deletingFile: false, isLoadingFiles: true });
            } else {
                this.setState({ deletingFile: false, error: res.error.error });
            }
        })
        this.setState({ deletingFile: filename });
    }

    downloadFile = (event, name) => {
        event.preventDefault();
        this.props.downloadFile('terms', name, 'terms', this.props.profile.accessToken);
    };
    uploadFile = (file, filename) => {
        this.setState({ isUploading: true });
        this.props.uploadFile(
            'agreementfiles',
            'disciplineterms',
            file,
            filename,
            this.props.profile.accessToken
        ).then( res => {
            if (!res.error) {
                this.props.dispatch(loadFiles('agreementfiles', 'disciplineterms', this.props.profile.accessToken));
                this.setState({ isUploading: false, isLoadingFiles: true });
            } else {
                this.setState({ isUploading: false, error: res.error.error });
            }
        })
    };

    render() {
        const { selectedCountry } = this.props.countries;
        const { t } = this.props;

        return (
            <StandardPage withBreadcrumb withSideNavigation>
                <BreadcrumbBar
                    page="admin"
                    path={this.props.location.pathname}
                />
                <AdminSubNavigation params={this.props.match.params} activeNavi="disciplineConfig" />
                <Header title={t('disciplines')} />
                { this.props.disciplineConfig.loadingAll && <LoadingIndicator visible={true} big={true} /> }
                <AdminDisciplineSelectionList
                    disciplines={ this.props.disciplineConfig.configs }
                    countryId={ this.props.match.params.countryId }
                    uploadFile={this.uploadFile}
                    deleteFile={this.deleteFile}
                    downloadFile={this.downloadFile}
                    selectedCountry={selectedCountry}
                    files={this.props.files}
                    download={this.props.download}
                    checkExisting={this.checkExisting}
                />
            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        countries: state.countries,
        discipline : state.discipline,
        profile: state.userProfile,
        translations: state.translations,
        disciplineConfig: state.disciplineConfig,
        files: state.files,
        download: state.download,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadWithType: (type, languages, countryId, accessToken) => dispatch(loadWithType(type, languages, countryId, accessToken)),
        downloadFile: (downloadType, name, type, accessToken) => {
            return dispatch(download({downloadType, name, type}, accessToken));
        },
        uploadFile: (share, dir, file, filename, accessToken) => {
            return dispatch(uploadFile(share, dir, file, filename, accessToken))
        },
        deleteFile: (share, dir, filename, accessToken) => {
            return dispatch(deleteFile(share, dir, filename, accessToken))
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(DisciplineConfigurationView));
