import React, {Component }            from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';

import {
    commonStyles
} from 'styles/design.js';
import {Link} from 'react-router-dom';

class EditBuildingButton extends Component {

    static propTypes = {
        clientId: PropTypes.number.isRequired,
        buildingId: PropTypes.number.isRequired
    };

    render() {
        const {
            t,
            clientId,
            contractInfoId,
            buildingId
        } = this.props;

        return (
        <Link to={'/client/' + clientId + '/contractInfo/' + contractInfoId + '/building/' + buildingId + '/details/edit'} style = { commonStyles.buttonLink }>
            {t('edit')}
        </Link>
    );}
}
export default translations(EditBuildingButton);