import React, {Component} from 'react';
import {Button} from 'components';
import BundleEditDetail from './BundleEditDetail';

class BundleDetails extends Component {
    state = {
        editMode: null
    };

    setEditMode = (mode) => {
        this.setState({
            editMode: mode
        });
    };

    handleEditOpen = (e, mode) => {
        e.preventDefault();
        this.setEditMode(mode);
    }

    handleCancel = () => {
        this.setEditMode(null);
    };

    bundleDetail = (label, value, unit, id) => {
        const isValueSet = (value != null);
        const editLabel = isValueSet ? this.props.t('edit') : this.props.t('Set value');

        return (
            <div className="bundle-cost-detail" key={id}>
                <div className="bundle-cost-name">
                    {label}
                </div>
                <div className="bundle-cost-value">
                    {isValueSet &&
                        <span className="bundle-cost-value-inner">{value} {unit}</span>
                    }
                    {id && this.props.isEditable && <a className="bundle-cost-edit" href="" onClick={(e) => this.handleEditOpen(e, id)}>{editLabel}</a>}
                </div>
            </div>
        )
    }

    handleDetailEdit = ({bundleId, detailId, value}) => {
        this.setEditMode(null);
        this.props.handleBundleDetailEdit({bundleId, detailId, value})
    }

    render() {
        const {totalSalesPrice, customerSalesPrice, upfrontCost, bundleId, contractYears, t, country} = this.props;
        const details = [
            {
                name: t('Customer upfront price'),
                id: 'upfrontCost',
                buttonLabel: t('Edit upfront price'),
                value: upfrontCost,
                unit: country.currency
            },
            {
                name: t('Contract years'),
                id: 'contractYears',
                buttonLabel: t('Edit contract years'),
                value: contractYears
            },
            {
                name: t('Customer price'),
                id: 'customSalesPrice',
                buttonLabel: t('Edit custom sales price'),
                value: customerSalesPrice,
                unit: country.currency
            },
        ];

        return (
            <div className="bundle-cost clearfix">
                <div className="bundle-cost-actions">
                    {details.map(detail => {
                        return (
                            <div className="bundle-cost-action" key={detail.id}>
                                {this.state.editMode === detail.id &&
                                    <BundleEditDetail
                                        detailName={detail.name}
                                        detailId={detail.id}
                                        bundleId={bundleId}
                                        value={detail.value}
                                        submit={this.handleDetailEdit}
                                        handleCancel={this.handleCancel}
                                        t={t}
                                    />
                                }
                                {/*<Button variant="secondary" size="small" onClick={() => this.setEditMode(detail.id)}>{detail.buttonLabel}</Button> */}
                            </div>
                        );
                    })}
                </div>
                <div className="bundle-cost-details">
                    {details.map(detail => {
                        return this.bundleDetail(detail.name, detail.value, detail.unit, detail.id);
                    })}
                </div>
            </div>
        );
    }
};

export default BundleDetails;