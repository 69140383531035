const _ = require('lodash');
const LOAD = 'ServiFlex/ContractInfo/LOAD',
    LOAD_SUCCESS = 'ServiFlex/ContractInfo/LOAD_SUCCESS',
    LOAD_FAIL = 'ServiFlex/ContractInfo/LOAD_FAIL',
    SAVE = 'ServiFlex/ContractInfo/SAVE',
    SAVE_SUCCESS = 'ServiFlex/ContractInfo/SAVE_SUCCESS',
    SAVE_FAIL = 'ServiFlex/ContractInfo/SAVE_FAIL',
    DELETE              = 'ServiFlex/ContractInfo/DELETE',
    DELETE_SUCCESS      = 'ServiFlex/ContractInfo/DELETE_SUCCESS',
    DELETE_FAIL         = 'ServiFlex/ContractInfo/DELETE_FAIL',
    LOAD_CONTRACT_INFOS = 'ServiFlex/ContractInfo/LOAD_CONTRACT_INFOS',
    LOAD_CONTRACT_INFOS_SUCCESS = 'ServiFlex/ContractInfo/LOAD_CONTRACT_INFOS_SUCCESS',
    LOAD_CONTRACT_INFOS_FAIL = 'ServiFlex/ContractInfo/LOAD_CONTRACT_INFOS_FAIL';

const initialState = {
    loading: false,
    contractInfo: {},
    contractInfos: [],
    filteredContractInfos: [],
    loaded: false,
    reload: true,
    saving: false,
    deleting: false,
    deleted: false,
    deleteResult: null,
};

export const paymentTermOptions = [14, 21, 30, 40, 45, 60];

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                contractInfo: action.result
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SAVE:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                error: action.error
            };
        case SAVE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };
        case DELETE:
            return {
                ...state,
                deleting: true
            };
        case DELETE_FAIL:
            return {
                ...state,
                deleting: false,
                deleted: false,
                error: action.error
            };
        case DELETE_SUCCESS:
            return {
                ...state,
                deleting: false,
                deleted: true,
                deleteResult: action.result
            };
        case LOAD_CONTRACT_INFOS:
            return {
                ...state,
                loading: true
            };
        case LOAD_CONTRACT_INFOS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                filteredContractInfos: action.result
            };
        case LOAD_CONTRACT_INFOS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        default:
            return state;
    }
}

export function loadContractInfo(contractInfoId, accessToken) {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (contractInfo) => contractInfo.get(`/contractInfo/${contractInfoId}?filter=${encodeURIComponent(
            JSON.stringify({
                include: [
                    'client',
                    {
                        relation: 'agreements',
                        scope: {
                            fields: 'id'
                        }
                    },
                    {
                        relation: 'frameagreements',
                        scope: {
                            fields: 'id'
                        }
                    }
                ]
            })
        )}`, { accessToken })
    };
}

export function loadContractInfos({ salesArea, department, createdBy }, accessToken) {
    if (!salesArea && !department && !createdBy) {
        return {
            type: LOAD_CONTRACT_INFOS_FAIL
        }
    }
    const filter = {
        include: ['client', 'department'],
        where: {
            and: []
        }
    };
    if (salesArea) {
        filter.where.and.push({ salesArea });
    }
    if (department) {
        filter.where.and.push({ departmentId: department });
    }
    if (createdBy) {
        filter.where.and.push({ createdBy });
    }
    return {
        types: [LOAD_CONTRACT_INFOS, LOAD_CONTRACT_INFOS_SUCCESS, LOAD_CONTRACT_INFOS_FAIL],
        promise: (contractInfo) => contractInfo.get(`/contractInfo?filter=${encodeURIComponent(
            JSON.stringify(filter)
        )}`, { accessToken })
    };
}

export function updateContractInfo(contractInfoObject, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (contractInfo) => contractInfo.patch('/contractInfo/' + contractInfoObject.id, { data: contractInfoObject, accessToken }
        )
    };
}

export function saveContractInfo(contractInfoObject, accessToken) {
    if (contractInfoObject.id) {
        return updateContractInfo(contractInfoObject, accessToken);
    }
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (contractInfo) => contractInfo.post('/contractInfo', { data: contractInfoObject, accessToken }
        )
    };
}

export function isContractInfoLoaded(props, contractInfoId) {
    return _.get(props, 'contractInfo.contractInfo.id', null) === contractInfoId;
}

export const deleteContractInfo = (id, accessToken) => {
    return {
        types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
        promise: client => client.del(`/contractInfo/${id}/deleteInfo`, { accessToken })
    };
}