import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import translations from 'decorators/translations.js';

import {
    loadCollectors,
    addCollector
} from 'redux/modules/kitpack';

import {
    loadWithIncludes,
    loadAHUTypes,
    isAgreementLoaded
} from 'redux/modules/agreement';

import {
    loadCountry,
    isCountryLoaded
} from 'redux/modules/country.js';

import {
    BreadcrumbBar,
    StandardPage,
    Button,
    Cards,
    BackLink,
    ActionCard
} from 'components/index';

import Collector from './Collectors/Collector';

import {
    loadClient,
    isClientLoaded
} from 'redux/modules/client.js';

import {
    isEditable
} from 'helpers/agreement.js';

import _ from 'lodash';

import CollectorForm from './Collectors/CollectorForm';

import './CollectorView.css';

/**
 * Collectors view
 * This view has functionality to show multiple collectors. For now, it will only allow to create a new collector.
 * Modifications to turn multiple collector functionality back are marked with 'MULTIPLE'
 */

class CollectorsView extends React.Component {
    // MULTIPLE: set newCollector default to false
    state = {
        newCollector: true
    };
    static propTypes = {
        t: PropTypes.func.isRequired,
        kitpack: PropTypes.object.isRequired
    };

    componentWillMount() {
        const {
            loadCollectors,
            loadAgreement,
            loadClient,
            loadCountry,
            agreement,
            history,
            loadAHUTypes,
            profile,
            match: {
                params
            }
        } = this.props;
        loadCollectors({agreementId: params.agreementId}, profile.accessToken)
            // MULTIPLE: remove redirection to single collector page if a collector is found
            .then((res) => {
                if (res.result && res.result.length > 0) {
                    const collectorId = res.result[0].id;
                    history.push('/client/' + params.clientId + '/contractInfo/' + params.contractInfoId + '/agreement/' + params.agreementId + '/collectors/' + collectorId);
                }

            });
        if(!isAgreementLoaded(agreement, _.get(this.props, 'match.params.agreementId', null))) {
            loadAgreement(params.agreementId, profile.accessToken);
        }
        if (!isClientLoaded(this.props, params.clientId)) {
            loadClient(params.clientId, profile.accessToken).then((res) => {
                if(res.result) {
                    loadCountry(res.result.salesArea, profile.accessToken);
                }
            })
        }
        if (agreement.ahuTypes.length === 0) {
            loadAHUTypes(profile.accessToken);
        }
    }
    componentWillReceiveProps(nextProps) {
        const salesArea = _.get(this.props, 'client.client.salesArea', null)
;        if(salesArea && !isCountryLoaded(nextProps, this.props.client.client.salesArea) && !nextProps.countries.loading) {
            this.props.loadCountry(nextProps.client.client.salesArea, this.props.profile.accessToken);
        }
    }

    addCollector = (e) => {
        e.preventDefault();
        this.setState({newCollector: true});
    };
    cancelAddCollector = () => {
        this.setState({newCollector: false});
    };

    submitCollector = (values) => {
        const {
            match: {
                params
            }
        } = this.props;
        values.agreementId = params.agreementId;
        this.props.addCollector(values, this.props.profile.accessToken).then((res) => {
            if (res.result) {
                // MULTIPLE: uncomment following lines and remove redirection to single collector page
                //this.props.loadCollectors({agreementId: this.props.match.params.agreementId}, this.props.profile.accessToken);
                //this.setState({newCollector: false});
                this.props.history.push('/client/' + params.clientId + '/contractInfo/' + params.contractInfoId + '/agreement/' + params.agreementId + '/collectors/' + res.result.id);
            } else {
                this.setState({error: res.error});
            }
        });
    };

    getDisciplines = () => {
        const agreement = this.props.agreement.agreement;
        if (!agreement) {
            return [];
        }
        const disciplines = _.map(agreement.disciplines, (dis) => {
            return {
                disciplineCategory: dis.disciplineCategory,
                disciplineType: dis.disciplineType,
                systems: _.map(dis.disciplineSystems, (sys) => ({systemId: sys.systemID, equipmentType: sys.equipmentType}))
            }
        });
        return disciplines;
    };

    isMainCollectorAvailable = () => {
        const disciplines = this.getDisciplines();
        //Check if ventilation and air conditioning is available
        const ventilation = _.find(disciplines, {disciplineCategory: 'ventilation_and_air_conditioning'});
        const alreadyThere = _.find(this.props.kitpack.collectors, {type: 'main_collector'});
        return ventilation && !alreadyThere;
    }


    render() {
        const {t, agreement: {agreement}} = this.props;
        const params = this.props.match.params;
        return (
            <StandardPage
                loaderVisible={this.props.agreement.loading || this.props.kitpack.loading || this.props.kitpack.saving}
                withBreadcrumb>
                { agreement &&
                    <BreadcrumbBar
                        page="agreement"
                        agreement={agreement}
                        actionUrl={`/client/${params.clientId}/details`}
                        linkName={t('show_client_details')}
                        lockedText={!isEditable(agreement) ? t('locked') : null}
                    />
                }
                <div>
                    {
                        // MULTIPLE: uncomment following row
                        // !this.state.newCollector &&
                        agreement &&
                        <BackLink
                            id="CollectorsView_back"
                            title={t('Back to agreement')}
                            to={`/client/${params.clientId}/contractInfo/${agreement.contractInfoId}/agreement/${params.agreementId}/summary`}
                        />
                    }
                    {
                        // MULTIPLE: uncomment this
                        // <h2>{t('Collectors')}</h2>
                    }
                    {
                        (() => {
                            if (agreement && this.state.newCollector) {
                                return (
                                    <CollectorForm
                                        isNew
                                        agreement={agreement}
                                        cancel={this.cancelAddCollector}
                                        submit={this.submitCollector}
                                        mainCollectorAvailable={this.isMainCollectorAvailable()}
                                    />
                                );
                            }
                            return (
                                <Cards>
                                    {
                                        this.props.kitpack.collectors.map((collector, idx) =>
                                            <Collector
                                                key={'collectors_' + idx}
                                                t={t}
                                                idx={idx}
                                                collector={collector}
                                                contractInfoId={params.contractInfoId}
                                                agreementId={params.agreementId}
                                                clientId={params.clientId}
                                            />
                                        )
                                    }
                                    <ActionCard key={'add_collector'} onClick={this.addCollector} id="CollectorsView_Add">
                                        {t('Add new collector')}
                                    </ActionCard>
                                </Cards>
                            );
                        })()
                    }
                </div>
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        agreement: state.agreement,
        kitpack: state.kitpack,
        client: state.client,
        countries: state.countries
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadAgreement: (agreementId, accessToken) => dispatch(loadWithIncludes(agreementId, accessToken)),
        loadAHUTypes: (accessToken) => dispatch(loadAHUTypes(accessToken)),
        loadCollectors: (filters, accessToken) => dispatch(loadCollectors(filters, accessToken)),
        addCollector: (data, accessToken) => dispatch(addCollector(data, accessToken)),
        loadClient: (clientId, accessToken) => dispatch(loadClient(clientId, accessToken)),
        loadCountry: (salesArea, accessToken) => dispatch(loadCountry(salesArea, accessToken)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default translations(connector(CollectorsView));