import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import { localeDateString } from 'helpers/stringTemplates';

import {
    DocumentList,
    DocumentListItem,
    LoadingIndicator
} from 'components';

class RecentClientList extends Component {

    static propTypes = {
        clients: PropTypes.array,
        loading: PropTypes.bool,
        isAdmin: PropTypes.bool
    };

    renderCreator = (createdBy) => {
        return (
            <span style={{ fontWeight: 'bold', color: '#555' }}>
                <span>
                { createdBy }
                </span>
            </span>
        );
    }

    render() {
        const { clients, loading, t } = this.props;
        if(loading) {
            return (<LoadingIndicator visible={ true } styles={{ fontSize: '25px' }}/>);
        } else if(!clients || clients.length === 0) {
            return <div>{t('no_results')}</div>
        }
        return (
            <DocumentList variant="compact" parentClass="recent-clients">
                { clients && clients.map((client, index) => {
                    return (
                        <DocumentListItem
                            id={'RecentClientList_client_' + index}
                            key={ 'recent-clients-list-item' + index }
                            icon="user"
                            title={ client.name }
                            to={ '/client/' + client.id }
                        >
                            <p>
                                { this.props.isAdmin && client.createdBy && this.renderCreator(client.createdBy) } { localeDateString(client.modified || client.created) }
                            </p>
                        </DocumentListItem>
                    );
                }) }
            </DocumentList>
        );}
}
export default RecentClientList;
