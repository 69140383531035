import React, { Component }                 from 'react';
import {Link}                               from 'react-router-dom';

import './DocumentList.css';

class DocumentListItem extends Component {

    renderIcon() {
        return (
            <div style={ this.props.iconColor && {color: this.props.iconColor} } className="document-list-icon">
                <i className={ this.props.icon ? 'fa fa-' + this.props.icon : '' } />
                <div className="document-list-icon-label">{ this.props.iconLabel }</div>
            </div>
        );
    }
    renderBody() {
        return (
            <div className="document-list-body">
                <div className="document-list-title">{ this.props.title }</div>
                <div className="document-list-content">{ this.props.children }</div>
            </div>
        );
    }
    render() {
        if (this.props.to) {
            return (
                <Link
                    id={this.props.id}
                    to={ this.props.to }
                    className={ this.props.variant ? 'document-list-item document-list-item-' + this.props.variant : 'document-list-item' }
                >
                    { this.props.icon && this.renderIcon() }
                    { this.renderBody() }
                </Link>
            );
        }
        return (
            <div
                className={ this.props.variant ? 'document-list-item document-list-item-' + this.props.variant : 'document-list-item' }
            >
                { this.props.icon && this.renderIcon() }
                { this.renderBody() }
            </div>
        );
    }
}

export default DocumentListItem;
