import React, { Component }                 from 'react';
import PropTypes                            from 'prop-types';
import { Button }                           from 'components';

import './Dropdown.css';

class DropdownToggle extends Component {
    
    static propTypes = {
        onClick: PropTypes.func,
        title: PropTypes.string
    };
    render() {
        const { variant, style, id, onClick, children, size } = this.props;

        return (
            <Button
                variant={variant}
                size={size}
                style={style}
                dropdownButton
                id={id}
                onClick = {onClick}>
                {children}
            </Button>
        );}
}

export default DropdownToggle;
