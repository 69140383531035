import React, { Component } from 'react';
import translations         from 'decorators/translations.js';
import PropTypes            from 'prop-types';
import { Link }             from 'react-router-dom';

import { localeDateString } from 'helpers/stringTemplates';

import {
    measures,
    colors,
    decorators
} from 'styles/design.js';

const listItemStyle = {
    color: colors.blue,
    padding: measures.standard,
    marginRight: measures.standard
};

class ApprovableAgreementsList extends Component {

    static propTypes = {
        agreements: PropTypes.array
    };

    getAgreementLink = (agreement) => {
        const type = agreement.agreementType === 'frameagreement' ? 'frameagreement' : 'agreement';
        return '/client/' + agreement.clientId + '/contractInfo/' + agreement.contractInfoId + '/' + type + '/' + agreement.id + '/approve';
    };

    render() {
        const t = this.props.t;
        this.props.frameAgreements.map((agr) => {
            agr.agreementType = 'frameagreement';
        });
        const agreements = this.props.agreements.concat(this.props.frameAgreements || []);
        return (
            <ul style={{ borderLeft: decorators.coloredBorder(colors.lightGray) }}>
                { agreements.map((agreement, index) => {
                    let label;
                    switch(agreement.agreementType) {
                        case 'frameagreement':
                            label = 'frame_agreement';
                            break;
                        case 'main':
                        default:
                            label = 'main_agreement';
                            break;
                        case 'addon':
                            label = 'addon';
                            break;
                    }
                    return (
                        <li key={'approval_list_' + index} style={ listItemStyle }>
                            <Link to={ this.getAgreementLink(agreement) } style={{ color: colors.blue }}>
                                <i className="fa fa-file-text-o" />
                                <span style={{ marginLeft: measures.standard }}>{ t(label) } </span>
                                <span> { agreement
                                        && agreement.contractInfo
                                        && agreement.contractInfo
                                        && agreement.contractInfo.client
                                        && agreement.contractInfo.client.name } </span>
                                <span style={{ display: 'inline', fontSize: '0.8em' }}>
                                    { localeDateString(agreement.modified || agreement.created) }
                                </span>
                            </Link>
                        </li>
                    );
                }) }
            </ul>
        );}
}

export default translations(ApprovableAgreementsList);
