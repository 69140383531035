import React, {Component} from 'react';
import translations from 'decorators/translations.js'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {
    SaveButton,
    CancelButton,
    SFCheckBox,
    RichTextEditor,
    RichTextView
} from 'components';
import {
    layout,
    commonStyles,
    measures,
    colors
}
    from 'styles/design.js';
import _ from 'lodash';

import {saveTranslation} from 'redux/modules/translations.js';

class TranslationForm extends Component {
    state = {disabledTranslation: false};

    componentWillMount() {
        if (this.props.language) {
            this.setState((state) => ({...state, language: this.props.language}));
        }
    }

    static propTypes = {
        translations: PropTypes.object.isRequired, //Translations.
        localizables: PropTypes.array.isRequired
    };

    getTranslations = () => {
        return this.props.list || this.props.translations.translatables;
    }

    translate(key, lang, shownOnInput) {
        const translations = this.getTranslations();
        if (translations[key] && translations[key][lang]) {
            const translation = translations[key][lang] || {};
            if (translation.disabled) {
                return '';
            }
            return translation.value;
        } else {
            const translation = _.get(translations, `[${key}].en-master`, {});
            if (translation.disabled) {
                return '';
            } else if (translation.value) {
                return translation.value + (!shownOnInput ? ' *' : '');
            } else {
                return key;
            }
        }
    }

    editClicked = (key, language) => {
        const translationValue = this.translate(key, language, true);
        const translation = _.get(this.getTranslations(), `[${key}][${language}]`, {});
        this.setState({translationValue, editing: key, disabledTranslation: translation.disabled});
    };
    cancelClicked = () => {
        this.setState({editing: null, disabledTranslation: false});
    };
    saveClicked = () => {
        if (this.state.translationValue === null) {
            return;
        }
        const data = {
            key: this.state.editing,
            value: this.state.translationValue,
            language: this.state.language ? this.state.language : 'en',
            disabled: this.state.disabledTranslation || false,
            type: this.props.type || 2,
            country: this.props.country.id,
            ...this.props.externalId
        };
        this.setState({submitting: true});
        this.props.dispatch(saveTranslation(data, this.props.profile.accessToken)).then(() => {
            this.setState({submitting: false, editing: null});
            if (this.props.afterSaveTrigger) {
                return this.props.afterSaveTrigger();
            }

        });
    };
    fieldChange = (event) => {
        this.setState({translationValue: event.target.value});
    };
    changeRichTextField = (key, value) => {
        this.setState({ translationValue: value });
    }
    languageSelected = (event) => {
        this.cancelClicked();
        this.setState({language: event.target.value});
    };
    toggleDisabled = (value) => {
        if (value) {
            this.setState({translationValue: ''});
        }
        this.setState({disabledTranslation: value})
    }

    render() {
        const { t, richText, labels } = this.props;
        const isRichText = richText || false;
        const editing = this.state.editing;
        const lang = this.state.language || 'en';
        const languages = ['en'];
        const country = this.props.country || {};
        if (!_.isEmpty(country.defaultLanguage) && country.defaultLanguage !== 'en') {
            languages.push(country.defaultLanguage);
        }
        if (_.isEmpty(this.props.translations) || Object.keys(this.props.translations).length === 0) {
            return (<div>{t('unable_to_load_translations')}</div>);
        }
        return (
            <div>
                <div style={{marginTop: measures.standard, marginBottom: measures.standard}}>
                    {
                        (!this.props.country || (this.props.country && this.props.country !== 'globaladmin')) &&
                        <div>
                            <div style={layout.inlineBlock}>
                                <h3 style={{textTransform: 'capitalize'}}>{t('language')}:</h3>
                            </div>
                            <div style={{...layout.inlineBlock, marginLeft: measures.double}}>
                                <select style={commonStyles.select} onChange={this.languageSelected}>
                                    {
                                        (() => {
                                            return languages.map((langOpt, idx) => {
                                                return (<option
                                                    key={'lang_idx_' + idx}
                                                    value={langOpt}>{t(langOpt)}
                                                </option>);
                                            });
                                        })()
                                    }
                                </select>
                            </div>
                            { /*
                            <div>
                                * Asterisk after the translation indicates that it is the fallback English translation
                            </div>
                            */ }
                        </div>
                    }

                </div>
                {
                    (() => {
                        return this.props.localizables.map((key, transIdx) => {
                            const mainEnglish = this.translate(key, 'en-master');
                            if (!mainEnglish) {
                                console.log('No translation found for key', key);
                                return;
                            }
                            const containsParam = mainEnglish.indexOf('{0}') !== -1;
                            const sectionFetch = parseInt(key.split('_')[1].replace('s', ''), 10);
                            const section = !_.isNaN(sectionFetch) ? sectionFetch : null;
                            const isHeader = key.indexOf('header') !== -1;
                            const translation = this.translate(key, lang);
                            if (editing && editing === key) {
                                return (
                                    <div key={'localizables_' + transIdx} style={{
                                        margin: measures.standard,
                                        padding: measures.half,
                                        borderBottom: 'thin solid'
                                    }}>
                                        {labels && labels[transIdx] &&
                                            <p style={{ marginTop: '1em' }}>
                                                {labels[transIdx]}:
                                            </p>
                                        }
                                        <div style={{marginBottom: measures.standard}}>
                                            {
                                                isRichText ?
                                                <RichTextEditor
                                                    onChange={this.changeRichTextField}
                                                    value={this.state.translationValue}
                                                    label={t('mainagreement_s' + section + '_header')}
                                                />
                                                :
                                                <textarea
                                                    disabled={this.state.disabledTranslation}
                                                    style={{width: '100%', height: '50px'}}
                                                    onChange={this.fieldChange}
                                                    value={this.state.translationValue !== null ? this.state.translationValue : this.translate(key, lang, true)}
                                                />
                                            }


                                            <SFCheckBox
                                                defaultInput={true}
                                                value={this.state.disabledTranslation || false}
                                                onChange={this.toggleDisabled}
                                                rightLabel={t('disabled')}
                                            />
                                        </div>
                                        <p style={{color: colors.gray, fontSize: '0.8em'}}>
                                            {t('master_english')}: "{this.translate(key, 'en-master')}"
                                        </p>
                                        {
                                            (() => {
                                                if (containsParam) {
                                                    return (
                                                        <div style={{
                                                            marginTop: measures.standard,
                                                            fontSize: '0.9em',
                                                            fontWeight: 'bold'
                                                        }} className="highlight">
                                                            {t('note_translation_requires_param')}
                                                        </div>
                                                    );
                                                }
                                            })()
                                        }
                                        <div style={{
                                            ...layout.floatRight, ...layout.inlineBlock,
                                            clear: 'both',
                                            margin: '2em 0'
                                        }}>
                                            <SaveButton
                                                onClick={this.saveClicked}
                                                disabled={this.state.submitting}
                                                submitting={this.state.submitting}
                                            />
                                            <div style={{display: 'inline-block', float: 'right'}}>
                                                <CancelButton cancelAction={this.cancelClicked}/>
                                            </div>
                                        </div>
                                        <div style={{clear: 'both'}}/>
                                    </div>
                                );
                            }
                            return (
                                <div key={'localizables_' + transIdx} style={{
                                    marginTop: measures.half,
                                    marginBottom: measures.half,
                                    borderBottom: 'thin solid'
                                }}>
                                    {labels && labels[transIdx] &&
                                        <p style={{ marginTop: '1em' }}>
                                            {labels[transIdx]}
                                        </p>
                                    }
                                    <div style={{...layout.floatRight, marginBottom: measures.standard}}>
                                        <button
                                            style={{
                                                ...commonStyles.smallSaveButton,
                                                backgroundColor: colors.lightBlue,
                                                width: '30px'
                                            }}
                                            onClick={() => this.editClicked(key, lang)}>
                                            <i className="fa fa-pencil"/>
                                        </button>
                                    </div>
                                    <div style={{margin: measures.half}}>
                                        {
                                            (() => {
                                                if (isHeader) {
                                                    return <h3
                                                        style={{paddingTop: measures.half}}>{section}: {translation}</h3>;
                                                } else if (!section) {
                                                    return <div
                                                        style={{paddingTop: measures.standard}}>
                                                            { this.props.headers && <h3 style={{ paddingBottom: '0.5em' }}>{ this.props.headers[transIdx] }</h3> }
                                                            {
                                                                isRichText ? <RichTextView value={translation} /> : translation
                                                            }
                                                        </div>;
                                                }
                                                return (
                                                    <div style={{margin: measures.standard, fontSize: '0.8em'}}>
                                                        {translation}
                                                    </div>);
                                            })()
                                        }
                                    </div>
                                    <div style={{clear: 'both'}}/>
                                </div>
                            );

                        });
                    })()
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        translations: state.translations
    }
}

const connector = connect(mapStateToProps);
export default translations(connector(TranslationForm));
