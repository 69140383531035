import React, {Component }   from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import { commonStyles }                 from 'styles/design.js';

class CancelButtonLink extends Component {

    static propTypes = {
        cancelAction: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    };

    static defaultProps = {
        disabled: false,
    };

    buttonClicked = (event) => {
        if (!this.props.disabled && this.props.cancelAction) this.props.cancelAction(event);
    };

    render() {
        const { t, submitting } = this.props;
        const styles = Object.assign({}, commonStyles.cancelButtonLink, this.props.style);
        return (
        <a id={this.props.id} style={styles} disabled = { submitting } onClick={ this.buttonClicked }>{ t('cancel') }</a>
    );}
}

export default translations(configuredRadium(CancelButtonLink));
