import React, {Component}   from 'react';
import translations from 'decorators/translations.js';
import { connect }          from 'react-redux';
import configuredRadium     from 'configuredRadium.js';
import { loadCountry } from 'redux/modules/country.js';

import { loadContractInfo } from 'redux/modules/contractInfo.js';
import {
    savePriceDetailLevel,
    saveShowMonthlyPrice,
    loadWithIncludes as loadAgreement
} from 'redux/modules/agreement.js';

import {
    loadBuildings
}   from 'redux/modules/building.js';


import {
    isEditable
}                                   from 'helpers/agreement.js';
import {
    StandardPage,
    BreadcrumbBar,
    AgreementCostSummary,
    BackLink,
    Header,
    Section
}                           from 'components';
import {
    layout,
    measures,
    size,
    colors
}                           from 'styles/design.js';

const optionlink = [
    size.normal,
    {
        marginRight: measures.standard,
        cursor: 'pointer',
        backgroundColor: colors.lightGray,
        padding: '0.75em'
    }
];
const selectedOption = {
    backgroundColor: colors.blue,
    color: colors.white
};
class PriceListView extends Component {
    componentDidMount() {
        const { dispatch, match: {params}} = this.props;
        const promises = [];
        promises.push(dispatch(loadContractInfo(params.contractInfoId, this.props.profile.accessToken)));
        promises.push(dispatch(loadAgreement(params.agreementId, this.props.profile.accessToken, true)));
        promises.push(dispatch(loadBuildings(params.clientId, this.props.profile.accessToken)));
        this.setState({loadingCountry: true});
        return Promise.all(promises).then(() => {
            const contractInfo = this.props.contractInfo.contractInfo;
            if (contractInfo && contractInfo.id) {
                dispatch(loadCountry(contractInfo.salesArea, this.props.profile.accessToken));
            }
        })
    }
    componentWillReceiveProps(nextProps) {
        if(this.state.loadingCountry && nextProps.countries.loaded) {
            this.setState({loadingCountry: false});
        }

        if((this.state.changePriceLevel || this.state.updatingMonthlyPrice) && nextProps.agreementObj.reload) {
            const loadAgr = this.props.dispatch(loadAgreement(this.props.match.params.agreementId, this.props.profile.accessToken));
            this.setState({updatingMonthlyPrice: false, loadingAgreement: true, changePriceLevel: false});
            return loadAgr;
        }
        if(this.state.loadingAgreement && nextProps.agreement) {
            this.setState({loadingAgreement: false});
        }
    }

    clickSublevel = (option) => {
        if(isEditable(this.props.agreement)) {
            this.props.dispatch(savePriceDetailLevel(this.props.match.params.agreementId, {priceDetailLevel: option}, this.props.profile.accessToken));
            this.setState({changePriceLevel: true, subLevel: option});
        }
    };
    changeShowMonthly = (show) => {
        if(isEditable(this.props.agreement)) {
            this.setState({updatingMonthlyPrice: true});
            return this.props.dispatch(saveShowMonthlyPrice(this.props.match.params.agreementId, {showMonthlyPrice: show}, this.props.profile.accessToken));
        }
    };

    render() {
        const { t, client, match: { params } } = this.props;
        const agreement = this.props.agreement;
        if (!agreement) {
            return <StandardPage
                withBreadcrumb
                loaderVisible={this.props.agreementObj.loading}
            />
        }
        let agreementDisciplines = agreement.disciplines;
        if (agreement.agreementStatus && agreement.agreementStatus.search('approved|sent_for_approval') > -1) {
            agreementDisciplines = agreement.approvedDisciplines;
        }
        const subLevel = (this.state.subLevel ? this.state.subLevel : agreement.priceDetailLevel);
        const contractInfo = this.props.contractInfo.contractInfo;
        return (
            <StandardPage withBreadcrumb loaderVisible={this.props.agreementObj.loading}>
                <BreadcrumbBar
                    page="agreement"
                    agreement={agreement}
                    contractInfo={contractInfo}
                    actionUrl = { '/client/' + client.client.id + '/details' }
                    linkName = { t('show_client_details') }
                    lockedText = { !isEditable(agreement) ? t('locked') : null}
                />
                {
                    agreement && agreement.id &&
                    <BackLink title={t('Back to agreement')} to={'/client/' + contractInfo.clientId + '/contractInfo/' + params.contractInfoId + '/agreement/' + agreement.id + '/summary'} />
                }
                <Header  id="PriceListView_Header" title={ (this.state.showDetails ? t('pricelist_details') : t('pricelist')) } />

                <Section title={t('price_list_detail_level')} style = {{overflow: 'auto'}}>
                    <div style = {{marginTop: measures.standard, marginBottom: '1.5em'}}>
                        {t('price_list_selection_description')}
                    </div>
                    <div style = {{overflow: 'auto'}}>
                        <div>
                            <a onClick = {() => this.clickSublevel(0)} style = {[ optionlink, layout.inlineBlock, layout.floatLeft, subLevel === 0 ? selectedOption : {} ]} >{ this.props.t('price_total_option') }</a>
                            <a onClick = {() => this.clickSublevel(1)} style = {[ optionlink, layout.inlineBlock, layout.floatLeft, subLevel === 1 ? selectedOption : {} ]} >{ this.props.t('price_discipline_option') }</a>
                            <a onClick = {() => this.clickSublevel(2)} style = {[ optionlink, layout.inlineBlock, layout.floatLeft, subLevel === 2 ? selectedOption : {} ]} >{ this.props.t('price_subdiscipline_option') }</a>
                            <a onClick = {() => this.clickSublevel(3)} style = {[ optionlink, layout.inlineBlock, layout.floatLeft, subLevel === 3 ? selectedOption : {} ]} >{ this.props.t('price_system_option') }</a>
                        </div>
                    </div>
                </Section>
                <Section>
                    {
                        (() => {
                            if(this.props.countries && this.props.countries.selectedCountry) {
                                return (
                                    <AgreementCostSummary
                                        countryConfig = {this.props.countries.selectedCountry}
                                        changeShowMonthly = {(show) => this.changeShowMonthly(show)}
                                        agreement={ agreement }
                                        disciplines={ agreementDisciplines }
                                        showLevel={ subLevel}
                                        buildings = {this.props.buildings}
                                        level = {subLevel} />
                                );
                            }
                        })()
                    }
                </Section>
            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        agreement: state.agreement.agreement,
        agreementObj: state.agreement,
        disciplines: state.discipline.disciplines,
        userProfile: state.userProfile.user,
        countries: state.countries,
        client: state.client,
        profile: state.userProfile,
        buildings: state.building.buildings,
        contractInfo: state.contractInfo,
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(PriceListView)));
