import React                from 'react';
import translations         from 'decorators/translations.js';
import {
    Dropdown
}                           from 'components';
import { withRouter }         from 'react-router-dom';

const AddonDropdown = (props) => {
    const {t, addons, match: { params } } = props;

    if (addons.length > 0) {
        const clientId = params && params.clientId;
        let options = addons.map((addon, addonIndex) => {
            return { 
                name: <span><i className="fa fa-angle-right"/> { t('addon') + ' ' + (addonIndex + 1) }</span>, 
                url: '/client/' + clientId + '/contractInfo/' + addon.contractInfoId + '/agreement/' + addon.id + '/summary'
            }
        });
        options.splice(0, 0, {
            name: t('main_agreement'),
            url: '/client/' + clientId + '/contractInfo/' + addons[0].contractInfoId  + '/agreement/' + addons[0].mainAgreementId + '/summary'
        });

        return (
            <Dropdown
                buttonVariant="page-title"
                options={options}
            >
                { props.children }
            </Dropdown>
        );
    }
    return null;
};

export default translations(withRouter(AddonDropdown));
