import React from 'react';
import './Card.css';

/**
 * Wrapper UI component for Cards
 * Current styling allows 3 cards in a row 
 */
const Cards = ({children, ...other}) => {
    return <div className="cards" {...other}>{children}</div>
}

export default Cards;