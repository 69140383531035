
import React, { Component } from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium     from 'configuredRadium.js';
import { localeDateString }     from 'helpers/stringTemplates';

import {
    LoadingIndicator,
} from 'components';

import {
    commonStyles,
    colors,
    layout,
    measures,
} from 'styles/design.js';

const formSelectStyle = [
    commonStyles.select,
    {
        paddingRight: '0em',
        marginTop: 0,
        '@media only screen and (max-width: 660px)': {
            width: '100%',
        }
    }
];

const formInputStyle = [
    commonStyles.input,
    {
        paddingRight: '2em',
        marginTop: '0.5em',
        width: '100%',
        display: 'inline',
    }
];
const labelStyle = [
    layout.block,
    {
        width: '100%',
        marginBottom: measures.standard,
    }
];

const saveButtonStyle = {
    backgroundColor: colors.basicGreen,
    margin: '0.5em 1em',
    '@media only screen and (max-width: 660px)': {
        display: 'block',
        maxWidth: '6em',
        margin: '0.5em 0',
    }
};

class MainAgreementSelector extends Component {

    static propTypes = {
        mainAgreement: PropTypes.object,
        addOn: PropTypes.object.isRequired,
        agreements: PropTypes.array.isRequired,
        convertToMainAgreement: PropTypes.func.isRequired,
        connectToExternalMainAgreement: PropTypes.func.isRequired,
        connectToMainAgreement: PropTypes.func.isRequired,
    };

    componentWillMount() {
        let initialSelection = '';
        let initialRef = '';
        if (this.props.mainAgreement && this.props.mainAgreement.agreementType === 'external_main') {
            initialSelection = 'external_agreement';
            initialRef = this.props.mainAgreement.externalRef;
        } else if (this.props.mainAgreement && this.props.mainAgreement.agreeementType === 'main') {
            initialSelection = this.props.mainAgreement.id;
        }
        this.setState({
            externalRef: initialRef,
            selection: initialSelection
        });
    }

    setExternalRef(event) {
        this.setState({externalRef: event.target.value });
    }

    saveSelection() {
        if (this.state.selection === 'external_agreement') {
            if (this.state.externalRef && this.state.externalRef.length > 0) {
                this.setState({ saving: true });
                this.props.connectToExternalMainAgreement(this.props.addOn.id, this.state.externalRef);
            }
        } else if (this.state.selection === 'convert_to_main_agreement') {
            this.setState({converting: true});
            this.props.convertToMainAgreement(this.props.addOn.id);

        } else if (this.state.selection.length > 0) {
            this.setState({saving: true});
            this.props.connectToMainAgreement(this.props.addOn.id, this.state.selection);
        }
    }

    selectionChanged(event) {
        this.setState({selection: event.target.value });
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <select style={formSelectStyle} onChange={this.selectionChanged.bind(this)} value={ this.state.selection }>
                      <option value="">{ t('choose_main_agreement') }</option>
                      {(() => {
                          return this.props.agreements.map((agreement) => {
                              if (agreement.agreementType === 'main' && agreement.agreementStatus !== 'draft') {
                                  return (<option key={ agreement.id } value={ agreement.id }>{ t('main_agreement') } { t('created') } { localeDateString( agreement.created, {showTimeZone: false}) }</option>);
                              }
                          });
                      })()}
                      <option value="external_agreement">{ t('agreement_outside_serviflex') }</option>
                      <option value="convert_to_main_agreement">{ t('convert_to_main_agreement') }</option>
                </select>
                {(() => {
                    if (this.state.selection && this.state.selection !== 'convert_to_main_agreement') {
                        return (<a style = { [ commonStyles.buttonLink, saveButtonStyle ] } disabled={ this.state.saving } onClick={ this.saveSelection.bind(this) }><LoadingIndicator visible = { this.state.saving } />{ t('save') }</a>);
                    }
                })()}
                {(() => {
                    if (this.state.selection === 'convert_to_main_agreement') {
                        return (
                            <div style={{ marginTop: '1em' }}>
                                <label style={ labelStyle }>{ t('confirm_convert_to_main_agreement') }</label>
                                <a style = { [ commonStyles.buttonLink, {backgroundColor: colors.basicGreen, marginTop: '0.5em' }] } onClick={ this.saveSelection.bind(this) }><LoadingIndicator visible = { this.state.converting } />{ t('convert') }</a>
                            </div>
                        );
                    } else if (this.state.selection === 'external_agreement') {
                        return (
                            <div style={{ marginTop: '1em' }}>
                                <div style={{ display:'table-cell' }}>
                                    <label style={ labelStyle }>{ t('external_agreement_reference') }
                                        <input style={ formInputStyle } type="text" value={ this.state.externalRef } onChange={ this.setExternalRef.bind(this) } />
                                    </label>
                                </div>
                            </div>
                        );
                    }
                })()}
            </div>
        );
    }
}

export default translations(configuredRadium(MainAgreementSelector));
