import React, { Component }   from 'react';
import translations from 'decorators/translations.js';
import {
    RichTextView,
    KeyValueDiv,
    Button
}                 from 'components/index';

import {
    commonStyles,
    layout,
    measures
}                                       from 'styles/design.js';


class TerminationTermsInformation extends Component {
    render() {
        const {
            t,
            countryConfig
        } = this.props;

        return (
            <div>
                <div style={commonStyles.dataParentDiv}>
                    <div style={commonStyles.dataContentHeaderDiv}>
                        {t('termination_terms_information')}:
                    </div>

                    <div style={{ ...layout.floatRight, marginTop: measures.half}}>
                        <Button
                            id="TerminationTermsInformation_edit"
                            onClick={ this.props.editClicked }
                        >
                            { t('edit') }
                        </Button>
                    </div>

                    <div style={{ marginTop: '2em' }}>
                        {
                            (() => {
                                if (countryConfig.terminationTerms) {
                                    return countryConfig.terminationTerms.map((term, index) => {
                                        return (
                                            <KeyValueDiv key={'valuediv_' + index}
                                                name={`${term.description} ${term.descriptionEnglish ? `(${term.descriptionEnglish})` : ''}`}
                                                value={term.price + ' ' + countryConfig.currency }
                                            />
                                        );
                                    });
                                }

                                return (
                                    <p>
                                        {t('no_termination_terms_found')}
                                    </p>
                                );
                            })()
                        }
                    </div>
                    {/*
                    <div style={{ marginTop: measures.double, marginLeft: measures.half }}>
                        <div>
                            { t('termination_terms_description') + ':'}
                        </div>

                        <RichTextView
                            labelStyle={{ padding: measures.standard }}
                            value={countryConfig.terminationTermsDescription}
                        />
                    </div>
                    */}
                </div>
            </div>
        );
    }
}
export default translations(TerminationTermsInformation);