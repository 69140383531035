import React, {Component }       from 'react';
import translations from 'decorators/translations.js';
import configuredRadium                     from 'configuredRadium.js';
import {
    commonStyles,
    layout
}                                           from 'styles/design.js';
import {
    LoadingIndicator
}                                           from 'components';

class SaveButton extends Component {

    render() {
        return (
        <button
            type={ this.props.buttonType ? this.props.buttonType : 'button' }
            disabled = { this.props.disabled }
            onClick = { this.props.onClick }
            id={this.props.id}
            style = {
                [
                    commonStyles.button,
                    layout.block,
                    { minWidth : '100px'},
                    layout.floatRight,
                    layout.textCenter,
                    this.props.style ? this.props.style : {}
                ]
            }>
            <LoadingIndicator visible = { this.props.submitting } />
            { this.props.label ? this.props.label : this.props.t('save') }
        </button>
        );
    }
}

export default translations(configuredRadium(SaveButton));