import React, { Component }                 from 'react';
import PropTypes                            from 'prop-types';

import './DocumentList.css';

class DocumentList extends Component {
    static propTypes = {
        parentClass: PropTypes.string,
        variant: PropTypes.string,
        style: PropTypes.object,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node
        ])
    };

    render() {
        const classNames = [];
        classNames.push(this.props.variant ? 'document-list document-list-' + this.props.variant : 'document-list');
        if(this.props.parentClass) {
            classNames.push(this.props.parentClass);
        }
        return (
            <div
                className={ classNames.join(' ') }
                style={ this.props.style }
            >
                { this.props.children }
            </div>
        );
    }
}

export default DocumentList;
