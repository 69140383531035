import React, { Component } from 'react';
import { connect }          from 'react-redux';
import translations         from 'decorators/translations.js';
import { Link } from 'react-router-dom';

import {
    loadMyAgreements,
    loadApprovableAgreements
} from 'redux/modules/agreement.js';

import {
    loadMyFrameAgreements,
    loadApprovableFrameAgreements
} from 'redux/modules/frameagreement.js';

import {
    loadRecentClients
} from 'redux/modules/client.js';

import {
    StandardPage,
    CreateClientButton,
    SearchAgreements,
    RecentAgreements,
    ApprovableAgreementsList,
    RecentClientList
} from 'components';

import {
    commonStyles,
    measures,
    fonts,
    size
} from 'styles/design.js';

import { search as searchAction } from 'redux/modules/searchResults.js';

const latestContainer = {
    overflow: 'hidden',
    marginTop: measures.double
};
const approvablesContainer = {
    overflow: 'hidden',
    marginTop: measures.double
};
const titleStyle = {
    fontSize: '1.75em',
    marginBottom: 0
};

class WelcomeView extends Component {

    componentWillMount() {
        return Promise.all([
            this.props.dispatch(loadMyAgreements(this.props.userProfile.accessToken)),
            this.props.dispatch(loadApprovableAgreements(this.props.userProfile.accessToken)),
            this.props.dispatch(loadMyFrameAgreements(this.props.userProfile.accessToken)),
            this.props.dispatch(loadApprovableFrameAgreements(this.props.userProfile.accessToken)),
            this.props.dispatch(loadRecentClients(this.props.userProfile.accessToken))
        ]);
    }
    componentDidMount() {
        this.props.dispatch(searchAction('', this.props.userProfile.accessToken));
    }
    render() {
        const t = this.props.t || {};
        const { user } = this.props;

        if (!user || (!user.isAdmin && !user.isApprover && !user.isController && user.salesAreas.length === 0)) {
            return (
                <StandardPage>
                    <div>
                        {t('No access. Please contact your division to get access to ServiFlex+ tool.')}
                    </div>
                </StandardPage>
            );
        }
        return (
            <StandardPage>
                <div style = {{ marginTop: measures.standard, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '5em' }}>
                    <h1 className="WelcomeView-title" style = {{...commonStyles.pageTitle,...titleStyle}}><div style = { commonStyles.headerIcon }><i className= { 'fa fa-search' } /></div>{ t('search_title') }</h1>
                    <CreateClientButton t={ this.props.t } />
                </div>
                <SearchAgreements
                    searchResults = { this.props.searchResults }
                    fireSearch = { searchString => this.props.dispatch(searchAction(searchString, this.props.userProfile.accessToken)) }
                />
                {
                    (() => {
                        if((this.props.approvableAgreements && this.props.approvableAgreements.length > 0) || (this.props.approvableFrameAgreements && this.props.approvableFrameAgreements.length > 0)) {
                            return (
                                <div style={ approvablesContainer }>
                                    <div style={{ marginBottom: measures.standard }}>
                                        <p style={{ ...size.epsilon, fontFamily: fonts.SanchesRegular }}>{ t('approvable_agreements_list') }</p>
                                    </div>

                                    <ApprovableAgreementsList
                                        agreements = { this.props.approvableAgreements || [] }
                                        frameAgreements = { this.props.approvableFrameAgreements || [] }
                                    />
                                </div>
                            );
                        }
                    })()
                }

                <div style={ latestContainer }>
                    <div style={{ marginBottom: measures.standard }}>
                        <p style={{ ...size.epsilon, fontFamily: fonts.SanchesRegular }}>
                            { this.props.user.isAdmin ? t('recent_agreements') : t('my_recent_agreements') }
                            <span style={{ marginLeft: '0.5em', ...size.tiny, ...fonts.arial }}>
                                (<Link to="/myagreements">
                                    { this.props.user.isAdmin ? t('View my all agreements') : t('View all') }
                                </Link>)
                            </span>
                        </p>
                    </div>
                    <RecentAgreements
                        agreements = { this.props.myAgreements }
                        frameAgreements = { this.props.myFrameAgreements }
                        loading = { this.props.agreementState.loadingMyAgreements}
                        isAdmin = { this.props.user.isAdmin }
                    />
                </div>
                <div style={latestContainer}>
                    <div style={{ marginBottom: measures.standard }}>
                        <p style={{ ...size.epsilon, fontFamily: fonts.SanchesRegular }}>
                            { this.props.user.isAdmin ? t('recent_clients') : t('my_recent_clients') }
                        </p>
                    </div>
                    <RecentClientList
                        clients = { this.props.clientState.recents }
                        loading = { this.props.clientState.loading }
                        t={ t }
                        isAdmin = { this.props.user.isAdmin }
                    />
                </div>
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        searchResults: state.searchResults,
        userProfile: state.userProfile,
        agreementState: state.agreement,
        myAgreements: state.agreement.myAgreements,
        myFrameAgreements: state.frameagreement.myAgreements,
        approvableAgreements: state.agreement.approvableAgreements,
        approvableFrameAgreements: state.frameagreement.approvableAgreements,
        user: state.userProfile.user,
        error: state.error,
        clientState: state.client,
        translations: state.translations
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(WelcomeView));