import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import translations from 'decorators/translations.js';
import {
    DropdownMenu,
    DropdownItem,
    DropdownTitle
} from 'components';

const PrintDropdownMenu = ({ style, isVisible, t, action, agreementId, lang }) => {
    return <DropdownMenu style={ style } isVisible = { isVisible }>
        <DropdownTitle title={ t('agreement') } />
        <DropdownItem
            action ={ (event) => { action(event, 'print', 'agreement', agreementId, null, lang) } }
        >
            { t('agreement') }
        </DropdownItem>
        <DropdownItem
            action = { (event) => { action(event, 'print', 'agreement', agreementId, 'SAP', lang) } }
        >
            { t('costs') }
        </DropdownItem>

    </DropdownMenu>;
}

PrintDropdownMenu.propTypes = {
    style: PropTypes.object,
    agreementId: PropTypes.number,
    lang: PropTypes.string,
    action: PropTypes.func,
    t: PropTypes.func,
    isVisible: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        agreement: state.agreement,
        profile: state.userProfile,
        client: state.client.client,
        kitpack: state.kitpack
    };
}

const connector = connect(mapStateToProps);
export default connector(translations(PrintDropdownMenu));
