import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {loadApprovers} from 'redux/modules/userProfile.js';
import _ from 'lodash';

import {
    LoadingIndicator,
    SFSelectField,
    CancelButton,
    Dialog,
    Button
} from 'components';

import {
    commonStyles,
    layout,
} from 'styles/design.js';

const buttonStyle = {
    ...commonStyles.button,
    width: 'auto',
};
const buttonContainerStyle = {
    display: 'block'
};
const descriptionContainerStyle = {
    display: 'block',
    textAlign: 'left',
};

const containerStyle = {
    padding: '0',
};

class SendAgreementToApproval extends Component {
    state = {};
    static propTypes = {
        agreement: PropTypes.object.isRequired,
    };

    componentWillMount() {
        if (!this.props.profile.approvers || this.props.profile.approvers.length === 0) {
            return this.props.loadApprovers(this.props.profile.accessToken);
        }
    }

    setApproverEmail = event => {
        this.setState({
            approverEmail: event.target.value
        });
    };

    sendForApproval = () => {
        this.props.send(this.state.approverEmail).then(() => {
            this.cancel();
        });
    };

    revokeApproval = () => {
        this.setState({revoking: true});
        this.props.revoke();
    };

    toggleApproverEmail = () => {
        this.setState({askApproverEmail: !this.state.askApproverEmail});
    };
    cancel = () => {
        this.setState({askApproverEmail: false, approverEmail: null });
    };
    render() {
        const {
            agreement, t
        } = this.props;

        const profile = _.get(this.props, 'profile.user', {});
        const canRevoke = agreement.agreementStatus === 'sent_for_approval' && (profile.isAdmin || profile.email === agreement.sentForApprovalBy);
        return (
            <div style={containerStyle}>
                <div style={this.state.askApproverEmail ? {display: 'block', width: '100%'} : {display: 'none'}}>
                    <Dialog>
                        <div style={descriptionContainerStyle}>
                            <label>{t('email')}:</label>
                            <SFSelectField formProperty={{}} onChangeAction={this.setApproverEmail}>
                                <option value="0">{t('select')}</option>
                                {
                                    (() => {
                                        const approvers = _.uniq(_.map(_.sortBy(this.props.profile.approvers, ['order', 'identifier']), 'identifier'));
                                        return approvers.map((approver, idx) => {
                                            return (
                                                <option key={'approver_' + idx} value={approver}>{approver}</option>);
                                        });
                                    })()
                                }
                            </SFSelectField>
                        </div>
                        <div>
                            {this.state.approverEmail ? t('confirm_send_for_approval', this.state.approverEmail) : ''}
                        </div>
                        <div style={{...buttonContainerStyle, textAlign: 'right', fontSize: '1rem'}}>
                        <span style={layout.inlineBlock}>
                         <CancelButton
                             cancelAction={this.cancel}
                             disabled={this.state.submitting}
                             submitting={this.state.submitting}
                         />
                        </span>
                            <span style={{...layout.inlineBlock, marginLeft: '1em'}}>
                            <Button onClick={this.sendForApproval}>
                                {this.props.t('send')}
                            </Button>
                        </span>
                        </div>
                    </Dialog>
                </div>

                {
                    agreement.agreementStatus.search('draft|rejected') > -1 &&
                    <div>
                        <div style={buttonContainerStyle}>
                            <Button onClick={this.toggleApproverEmail}>
                                {t('send_for_approval')}
                            </Button>
                        </div>
                    </div>
                }
                {canRevoke && <div>
                    <button onClick={this.revokeApproval} style={buttonStyle}>{t('cancel_sending_for_approval')}
                        <LoadingIndicator visible={this.props.loading}/>
                    </button>
                </div>}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    profile: state.userProfile
});

const mapActionsToProps = (dispatch) => ({
    loadApprovers: (accessToken) => dispatch(loadApprovers(accessToken))
});

const connector = connect(mapStateToProps, mapActionsToProps);
export default translations(connector(SendAgreementToApproval));