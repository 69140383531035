import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import SignatureModal from './SignatureModal';
import AgreementSignature from './AgreementSignature';
import './AgreementSignatures.css';

const dummySignature = {
    line1: '',
    line2: ''
};

class AgreementSignatures extends Component {
    state = {
        signatures: [],
        signature: null,
        showModal: false,
        customerDummySignature: {
            ...dummySignature,
            type: 0
        },
        caverionDummySignature: {
            ...dummySignature,
            type: 1
        }
    };

    static propTypes = {
        signatures: PropTypes.array.isRequired,
        isEditable: PropTypes.bool.isRequired,
        deleteSignature: PropTypes.func.isRequired,
        saveSignature: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        customerName: PropTypes.string.isRequired,
        caverionName: PropTypes.string.isRequired,
        refreshAgreement: PropTypes.func.isRequired,
    };

    toggleAddSignature = (signatureDummy, isDefault) => {
        this.setState({
            showModal: true,
            signature: {
                ...signatureDummy,
            },
            isDefault // "editing" default dummy
        });
    };

    toggleEditSignature = (id, e) => {
        e.preventDefault();
        const signature = _.find(this.props.signatures, { id });
        if (!signature) {
            console.error('Invalid signature', id);
            return;
        }
        this.setState({
            showModal: true,
            signature,
        });
    };

    saveSignature = signature => {
        const {
            saveSignature,
            signatures,
            refreshAgreement
        } = this.props;
        const {
            isDefault,
            customerDummySignature,
            caverionDummySignature
        } = this.state;
        // If we are adding the first new signature we need to add the unmodified default signature first
        // The first signature is fake dummy (not saved to database), so we also need to check if we are "editing" that default signature with isDefault
        // If there are no other signatures and we "edit" first signature, it is saved as the only signature
        const signatureType = signature.type;
        if (!isDefault && _.isEmpty(_.filter(signatures, { type: signatureType }))) {
            const defaultSignature = signatureType === 0 ? customerDummySignature : caverionDummySignature;
            saveSignature({
                ...defaultSignature
            }).then(() => {
                saveSignature(signature).then(result => {
                    if (!result.error) {
                        refreshAgreement();
                        this.setState({
                            showModal: false,
                        });
                    }
                });
            });
        }
        else {
            saveSignature(signature).then(result => {
                if (!result.error) {
                    refreshAgreement();
                    this.setState({
                        showModal: false,
                    });
                }
            });
        }
    };

    deleteSignature = id => {
        if (!id) {
            return;
        }
        this.props.deleteSignature(id);
    };

    render() {
        const {
            t,
            signatures,
            customerName,
            caverionName,
            saving,
            error
        } = this.props;
        const {
            customerDummySignature,
            caverionDummySignature,
            signature,
            showModal
        } = this.state;
        const customerSignatures = _.filter(signatures, { type: 0 });
        const caverionSignatures = _.filter(signatures, { type: 1 });

        return (
            <div className="agreement-signatures">
                {
                    showModal &&
                    <SignatureModal
                        saving={saving}
                        error={error}
                        signature={signature}
                        closeModal={() => this.setState({ showModal: false, signature: null })}
                        saveSignature={this.saveSignature}
                        t={t}
                    />
                }
                <div className="agreement-signatures-header">
                    {t('Following signature fields will be printed to agreement')}
                </div>

                <div className="agreement-signatures-columns">
                    <div className="agreement-signatures-list">
                        <div className="agreement-signatures-list-subheader">{customerName}</div>
                        {_.isEmpty(customerSignatures)
                            ? <AgreementSignature
                                signature={ customerDummySignature }
                                toggleEditSignature={ () => this.toggleAddSignature(customerDummySignature, true) }
                                t={ t }
                                isEditable={this.props.isEditable}
                            />
                            : _.map(customerSignatures, signature =>
                                <AgreementSignature
                                    key={ signature.id }
                                    signature={ signature }
                                    toggleEditSignature={ this.toggleEditSignature }
                                    deleteSignature={ customerSignatures.length !== 1 ? this.deleteSignature : null }
                                    t={ t }
                                    isEditable={this.props.isEditable}
                                />
                            )
                        }
                        {this.props.isEditable &&
                        <div className="agreement-signatures-add" onClick={() => this.toggleAddSignature(customerDummySignature)}>
                            <span>
                                <i className="fa fa-plus fa-3x"/><br />
                                {t('add_new')}
                            </span>
                        </div>}
                    </div>
                    <div className="agreement-signatures-list">
                    <div className="agreement-signatures-list-subheader">{caverionName}</div>
                        {_.isEmpty(caverionSignatures)
                            ? <AgreementSignature
                                signature={ caverionDummySignature }
                                toggleEditSignature={ () => this.toggleAddSignature(caverionDummySignature, true) }
                                t={ t }
                                isEditable={this.props.isEditable}
                            />
                            : _.map(caverionSignatures, signature =>
                                <AgreementSignature
                                    key={ signature.id }
                                    signature={ signature }
                                    toggleEditSignature={ this.toggleEditSignature }
                                    deleteSignature={ caverionSignatures.length !== 1 ? this.deleteSignature : null }
                                    t={ t }
                                    isEditable={this.props.isEditable}
                                />
                            )
                        }
                        {this.props.isEditable &&
                        <div className="agreement-signatures-add" onClick={() => this.toggleAddSignature(caverionDummySignature)}>
                            <span>
                                <i className="fa fa-plus fa-3x"/><br />
                                {t('add_new')}
                            </span>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

export default AgreementSignatures;