import React, { Component } from 'react';
import translations         from 'decorators/translations.js';
import { Link }             from 'react-router-dom';

import {
    commonStyles
} from 'styles/design.js';

class CreateClientButton extends Component {
    render() {
        return (
        <Link id="WelcomeView_addClient" to={'/createClient'} style = { commonStyles.buttonLink }>{ this.props.t('add_new_client_button') }</Link>
    );}
}

export default translations(CreateClientButton);
