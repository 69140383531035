import React from 'react';

import {
    Button,
    LoadingIndicator
} from 'components';

import {
    colors,
    measures
} from 'styles/design';

import _ from 'lodash';

const FrameAgreementDetails = ({agreement, toggleEdit, t, departments, departmentsLoading, isEditable}) => {
    const department = departments && departments.length > 0 && agreement.departmentId ? _.find(departments, {id: agreement.departmentId}) : {};
    const departmentName = department && department.name ? department.name : agreement.departmentId;
    return (
        <div>
            <div>
                <h3 style={{display: 'inline-block'}}>{t('details')}</h3>
                {
                    isEditable && <div style={{marginLeft: measures.standard, display: 'inline'}}>
                        <Button
                            id="FrameAgreementDetails_edit"
                            onClick={toggleEdit}>
                            <i className="fa fa-pencil"/>
                        </Button>
                    </div>
                }
            </div>
            <div
                style={{
                    border: '1px dashed ' + colors.lightGray,
                    padding: '0.75em 0.5em',
                    marginTop: '0.5em',
                    letterSpacing: 'normal'
                }}
            >
                <div className="FrameAgreementDetailBlock">
                    <div>{t('department')}:</div>
                    <div id="FrameAgreementDetails_department">
                        <LoadingIndicator visible={departmentsLoading}/> {departmentName}
                    </div>
                </div>
                <div className="FrameAgreementDetailBlock">
                    <div>{t('payment_term')}:</div>
                    <div id="FrameAgreementDetails_paymentTerm">{agreement.paymentTerm || 14} {t('days')}</div>
                </div>
                <div className="FrameAgreementDetailBlock">
                    <div>{t('name')}:</div>
                    <div id="FrameAgreementDetails_name">{agreement.name}</div>
                </div>
                <div className="FrameAgreementDetailBlock">
                    <div>{t('printout_name')}:</div>
                    <div id="FrameAgreementDetails_printoutName">{agreement.printoutName}</div>
                </div>
                <div className="FrameAgreementDetailBlock">
                    <div>{t('description')}:</div>
                    <div id="FrameAgreementDetails_description">{agreement.description}</div>
                </div>
                <div className="FrameAgreementDetailBlock">
                    <div>{t('default_discount')}:</div>
                    <div id="FrameAgreementDetails_defaultDiscount">{agreement.defaultDiscount ? agreement.defaultDiscount + ' %' : ''}</div>
                </div>
                <div className="FrameAgreementDetailBlock">
                    <div>{t('Language')}:</div>
                    <div id="AgreementDetails_language">{ t(agreement.language || 'Not defined') }</div>
                </div>
            </div>
        </div>
    );
};

export default FrameAgreementDetails;