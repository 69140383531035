import React, { Component } from 'react';
import translations         from 'decorators/translations.js';
import PropTypes            from 'prop-types';
import configuredRadium     from 'configuredRadium.js';
import { Link }             from 'react-router-dom';

import {
    commonStyles,
    colors,
    measures
} from 'styles/design.js';

class CloseButton extends Component {

    static propTypes = {
        path: PropTypes.string.isRequired
    };

    render() {
        return (
            <Link id={this.props.id} to={this.props.path}>
                <span style={ [commonStyles.button, {backgroundColor: colors.lightBlue, margin: measures.standard + ' 0', textAlign: 'center'}] }>
                    { this.props.t('close') }
                </span>
            </Link>
        );}
}

export default translations(configuredRadium(CloseButton));
