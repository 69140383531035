import React, {Component}           from 'react';
import translations                 from 'decorators/translations.js';
import PropTypes                    from 'prop-types';
import configuredRadium             from 'configuredRadium.js';
import { connect }                  from 'react-redux';
import { HorizontalNavigationBar }  from 'components';

import './AgreementSubNavigation.css';

class AgreementSubNavigation extends Component {

    static propTypes = {
        activeMenu: PropTypes.string
    };

    render() {
        const { urlParams, t } = this.props;
        const params = urlParams;
        if(!params) {
            return <div />;
        }
        const agreementUrl = '/client/' + params.clientId + '/contractInfo/' + params.contractInfoId  + '/agreement/' + params.agreementId;
        const linkToAgreement = agreementUrl + '/summary';
        const linkToAgreementSLA = agreementUrl + '/sla';
        const linkToAgreementMonitoring = agreementUrl + '/monitoring';
        //const linkToCollectors = agreementUrl + '/collectors';
        const linkToAgreementCosts = agreementUrl + '/costs';
        const linkToAgreementPriceList = agreementUrl + '/pricelist';
        //const linkToAgreementProcess = agreementUrl + '/process';
        const agreementNaviItems = [
            { url: linkToAgreementSLA, label: t('SLA'), id: 'sla' },
            { url: linkToAgreementMonitoring, label: t('monitoring'), id: 'monitoring' },
            { url: linkToAgreementCosts, label: t('costs'), id: 'costs' },
            { url: linkToAgreementPriceList, label: t('pricelist'), id: 'pricelist' },
            { url: linkToAgreement, label: t('summary'), id: 'summary' }
        ];
        /*
        if(agreement && agreement.agreementStatus === 'signed') {
            agreementNaviItems.push({ url: linkToAgreementProcess, label: t('process'), id: 'process' });
        }
        */
        const linkToFrameAgreement = '/client/' + params.clientId + '/contractInfo/' + params.contractInfoId  + '/frameagreement/' + params.frameAgreementId + '/summary';
        const linkToFrameAgreementComments = '/client/' + params.clientId + '/contractInfo/' + params.contractInfoId  + '/frameagreement/' + params.frameAgreementId + '/comments';
        const linkToFrameAgreementSLA = '/client/' + params.clientId + '/contractInfo/' + params.contractInfoId  + '/frameagreement/' + params.frameAgreementId + '/sla';
        const frameAgreementNaviItems = [
            { url: linkToFrameAgreement, label: t('summary'), id: 'summary' },
            { url: linkToFrameAgreementComments, label: t('comments'), id: 'comments' },
            { url: linkToFrameAgreementSLA, label: t('sla'), id: 'sla' }
        ];
        const naviItems = params.frameAgreementId ? frameAgreementNaviItems : agreementNaviItems;
        return (
            <div className="agreement-subnavigation-container">
                <HorizontalNavigationBar items={ naviItems }/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client,
        params: state.router.params,
    };
}
const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(AgreementSubNavigation)));
