import React from 'react';

const TabContent = ({children, selected}) => {
    return (
        <div 
            className={'tabs-tab' + (selected ? ' active' : '')}
        >
            { children }
        </div>
    );
}

export default TabContent;