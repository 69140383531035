import React, { Component } from 'react';
import Bar from './Bar.js';

class Bars extends Component {
    render() {
        const {data, height, yScale, xScale, showValues} = this.props;

        const bars = data.map((d, i) => {
            const x = xScale(d.label);
            const y = yScale(d.value);
            const barHeight = height - yScale(d.value);
            const barWidth = xScale.bandwidth();
            const fillColor = d.fillColor || '#002D6E';
            let value;
            if (showValues) {
                value = d.valueText || d.value;
            }

            return (
                <Bar
                    key={'bar' + i}
                    x={x}
                    y={y}
                    height={barHeight}
                    width={barWidth}
                    valueText={value}
                    fill={fillColor}
                />
            )
        });

        return (
            <g
                ref={node => {this.node = node}}
                className="barchart"
            >
                {bars}
            </g>
        );
    }
}

export default Bars;