import {createReducerFromMapping} from 'redux/utils/index.js';
import getCSRFUrl from 'helpers/getCSRFUrl';

const LOAD_KITPACKS = 'Kitpack/LOAD_KITPACKS',
    LOAD_KITPACKS_SUCCESS = 'Kitpack/LOAD_KITPACKS_SUCCESS',
    LOAD_KITPACKS_FAIL = 'Kitpack/LOAD_KITPACKS_FAIL',
    LOAD = 'Kitpack/LOAD',
    LOAD_SUCCESS = 'Kitpack/LOAD_SUCCESS',
    LOAD_FAIL = 'Kitpack/LOAD_FAIL',
    ADD = 'Kitpack/ADD',
    ADD_SUCCESS = 'Kitpack/ADD_SUCCESS',
    ADD_FAIL = 'Kitpack/ADD_FAIL',
    UPDATE = 'Kitpack/UPDATE',
    UPDATE_SUCCESS = 'Kitpack/UPDATE_SUCCESS',
    UPDATE_FAIL = 'Kitpack/UPDATE_FAIL',
    ADD_SENSOR = 'Kitpack/ADD_SENSOR',
    ADD_SENSOR_SUCCESS = 'Kitpack/ADD_SENSOR_SUCCESS',
    ADD_SENSOR_FAIL = 'Kitpack/ADD_SENSOR_FAIL',
    UPDATE_SENSOR = 'Kitpack/UPDATE_SENSOR',
    UPDATE_SENSOR_SUCCESS = 'Kitpack/UPDATE_SENSOR_SUCCESS',
    UPDATE_SENSOR_FAIL = 'Kitpack/UPDATE_SENSOR_FAIL',
    ADD_BUNDLE = 'Kitpack/ADD_BUNDLE',
    ADD_BUNDLE_SUCCESS = 'Kitpack/ADD_BUNDLE_SUCCESS',
    ADD_BUNDLE_FAIL = 'Kitpack/ADD_BUNDLE_FAIL',
    LOAD_PRICING = 'Kitpack/LOAD_PRICING',
    LOAD_PRICING_SUCCESS = 'Kitpack/LOAD_PRICING_SUCCESS',
    LOAD_PRICING_FAIL = 'Kitpack/LOAD_PRICING_FAIL',
    LOAD_COUNTRY_PRICING = 'Kitpack/LOAD_COUNTRY_PRICING',
    LOAD_COUNTRY_PRICING_SUCCESS = 'Kitpack/LOAD_COUNTRY_PRICING_SUCCESS',
    LOAD_COUNTRY_PRICING_FAIL = 'Kitpack/LOAD_COUNTRY_PRICING_FAIL',
    LOAD_BUNDLES = 'Kitpack/LOAD_BUNDLES',
    LOAD_BUNDLES_SUCCESS = 'Kitpack/LOAD_BUNDLES_SUCCESS',
    LOAD_BUNDLES_FAIL = 'Kitpack/LOAD_BUNDLES_FAIL',
    UPDATE_KITPACK_BUNDLE = 'Kitpack/UPDATE_KITPACK_BUNDLE',
    UPDATE_KITPACK_BUNDLE_SUCCESS = 'Kitpack/UPDATE_KITPACK_BUNDLE_SUCCESS',
    UPDATE_KITPACK_BUNDLE_FAIL = 'Kitpack/UPDATE_KITPACK_BUNDLE_FAIL',
    DELETE_BUNDLE = 'Kitpack/DELETE_BUNDLE',
    DELETE_BUNDLE_FAIL = 'Kitpack/DELETE_BUNDLE_FAIL',
    DELETE_BUNDLE_SUCCESS = 'Kitpack/DELETE_BUNDLE_SUCCESS',
    DELETE_KITPACK = 'Kitpack/DELETE_KITPACK',
    DELETE_KITPACK_FAIL = 'Kitpack/DELETE_KITPACK_FAIL',
    DELETE_KITPACK_SUCCESS = 'Kitpack/DELETE_KITPACK_SUCCESS';


const sensorTypes = [
    {
        name: 'temperature',
    },
    {
        name: 'presence',
    },
    {
        name: 'ventilation',
    },
    {
        name: 'electricity',
    }
];

const initialState = {
    loading: false,
    data: [],
    loadedForClient: {},
    collectors: [],
    globalPricing: [],
    countryPricing: [],
    bundles: [],
    sensorTypes
};


export default createReducerFromMapping({
    [LOAD_KITPACKS]: (state, action) => ({...state, loading: true}),
    [LOAD_KITPACKS_SUCCESS]: (state, action) => ({
        ...state,
        loading: false,
        loaded: true,
        data: action.result
    }),
    [LOAD_KITPACKS_FAIL]: (state, action) => ({...state, loading: false, loaded: false, error: action.error}),
    [LOAD]: (state, action) => ({...state, loading: true}),
    [LOAD_SUCCESS]: (state, action) => ({
        ...state,
        loading: false,
        loaded: true,
        collectors: action.result
    }),
    [LOAD_FAIL]: (state, action) => ({...state, loading: false, loaded: false, error: action.error}),
    [ADD]: (state) => ({...state, saving: true}),
    [ADD_SUCCESS]: (state) => ({...state, saving: false, saved: true}),
    [ADD_FAIL]: (state, action) => ({...state, saving: false, error: action.error}),
    [UPDATE]: (state) => ({...state, saving: true}),
    [UPDATE_SUCCESS]: (state) => ({...state, saving: false, saved: true}),
    [UPDATE_FAIL]: (state, action) => ({...state, saving: false, error: action.error}),
    [ADD_SENSOR]: (state) => ({...state, saving: true}),
    [ADD_SENSOR_SUCCESS]: (state) => ({...state, saving: false}),
    [ADD_SENSOR_FAIL]: (state, action) => ({...state, saving: false, error: action.error}),
    [UPDATE_SENSOR]: (state) => ({...state, saving: true}),
    [UPDATE_SENSOR_SUCCESS]: (state) => ({...state, saving: false}),
    [UPDATE_SENSOR_FAIL]: (state, action) => ({...state, saving: false, error: action.error}),
    [LOAD_PRICING]: (state) => ({...state, loading: true}),
    [LOAD_PRICING_SUCCESS]: (state, action) => ({...state, loading: false, globalPricing: action.result}),
    [LOAD_PRICING_FAIL]: (state, action) => ({...state, loading: false, error: action.error}),
    [LOAD_COUNTRY_PRICING]: (state) => ({...state, loading: true}),
    [LOAD_COUNTRY_PRICING_SUCCESS]: (state, action) => ({...state, loading: false, countryPricing: action.result}),
    [LOAD_COUNTRY_PRICING_FAIL]: (state, action) => ({...state, loading: false, error: action.error}),
    [LOAD_BUNDLES]: (state) => ({...state, loading: true}),
    [LOAD_BUNDLES_SUCCESS]: (state, action) => ({...state, loading: false, bundles: action.result}),
    [LOAD_BUNDLES_FAIL]: (state, action) => ({...state, loading: false, error: action.error}),
    [UPDATE_KITPACK_BUNDLE]: (state) => ({...state, saving: true}),
    [UPDATE_KITPACK_BUNDLE_SUCCESS]: (state, action) => ({...state, saving: false, saved: true}),
    [UPDATE_KITPACK_BUNDLE_FAIL]: (state, action) => ({...state, saving: false, saved: false, error: action.error}),
    [ADD_BUNDLE]: (state) => ({...state, saving: true}),
    [ADD_BUNDLE_SUCCESS]: (state, action) => ({...state, saving: false, saved: true}),
    [ADD_BUNDLE_FAIL]: (state, action) => ({...state, saving: false, saved: false, error: action.error}),
    [DELETE_BUNDLE]: (state, action) => ({
        ...state,
        deleting: true
    }),
    [DELETE_BUNDLE_SUCCESS]: (state, action) => ({
        ...state,
        deleting: false,
        deleted: true
    }),
    [DELETE_BUNDLE_FAIL]: (state, action) => ({
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
    }),
    [DELETE_KITPACK]: (state, action) => ({
        ...state,
        deleting: true
    }),
    [DELETE_KITPACK_SUCCESS]: (state, action) => ({
        ...state,
        deleting: false,
        deleted: true
    }),
    [DELETE_KITPACK_FAIL]: (state, action) => ({
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
    })
}, initialState);

export function loadKitPacks(clientId, accessToken) {
    return {
        types: [LOAD_KITPACKS, LOAD_KITPACKS_SUCCESS, LOAD_KITPACKS_FAIL],
        clientId: parseInt(clientId, 10),
        promise: (client) => client.get(
            '/clients/' + clientId + '/kitpacks', {accessToken}
        )
    };
}

export function loadKitPacksForAgreement(agreementId, accessToken) {
    return {
        types: [LOAD_KITPACKS, LOAD_KITPACKS_SUCCESS, LOAD_KITPACKS_FAIL],
        agreementId: parseInt(agreementId, 10),
        promise: (client) => client.get(
            `/agreements/${agreementId}/kitpacks?filter=${encodeURIComponent(JSON.stringify({ include: { bundles: 'sensors' }}))}`, {accessToken}
        )
    };
}

export function loadCollectors({agreementId, clientId}, accessToken) {
    const filter = {agreementId, clientId};
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client) => client.get(
            getCSRFUrl('/kitpacks/list', '', filter), {accessToken}
        )
    }
}

export function addCollector(data, accessToken) {
    return {
        types: [ADD, ADD_SUCCESS, ADD_FAIL],
        promise: (client) => client.post('/kitpacks/addCollector', {data, accessToken})
    };
}

export function updateCollector(data, accessToken) {
    return {
        types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
        promise: (client) => client.patch('/kitpacks/' + data.id, {data, accessToken})
    };
}

export function upsertSensor(data, accessToken) {
    if(!data.id) {
        return addSensor(data, accessToken);
    } else {
        return updateSensor(data, accessToken);
    }
}
export function addSensor(data, accessToken) {
    return {
        types: [ADD_SENSOR, ADD_SENSOR_SUCCESS, ADD_SENSOR_FAIL],
        promise: (client) => client.post('/kitpacks/' + data.kitPackId + '/kitPackSensors', {data, accessToken})
    }
}
export function updateSensor(data, accessToken) {
    return {
        types: [UPDATE_SENSOR, UPDATE_SENSOR_SUCCESS, UPDATE_SENSOR_FAIL],
        promise: (client) => client.patch('/KitPackSensors/' + data.id, {data, accessToken})
    }
}
export function addBundleForCollector(collectorId, bundleId, accessToken) {
    return {
        types: [ADD_BUNDLE, ADD_BUNDLE_SUCCESS, ADD_BUNDLE_FAIL],
        promise: (client) => client.get('/KitPacks/' + collectorId + '/addBundle/' + bundleId, {accessToken})
    }
}

export function loadKitpackGlobalPricing(accessToken) {
    return {
        types: [LOAD_PRICING, LOAD_PRICING_SUCCESS, LOAD_PRICING_FAIL],
        promise: (client) => client.get('/kitpackpricings/', {accessToken})
    };
}

export function loadKitpackCountryPricing(countryId, accessToken) {
    return {
        types: [LOAD_COUNTRY_PRICING, LOAD_COUNTRY_PRICING_SUCCESS, LOAD_COUNTRY_PRICING_FAIL],
        promise: (client) => client.get('/countryconfigs/' + countryId + '/kitpackPricing', {accessToken})
    }
}

export function loadBundles(countryId, accessToken) {
    return {
        types: [LOAD_BUNDLES, LOAD_BUNDLES_SUCCESS, LOAD_BUNDLES_FAIL],
        promise: (client) => client.get('/kitpacks/bundles/' + countryId, {accessToken})
    }
}

export function updateKitpackBundle(bundleId, data, accessToken) {
    return {
        types: [UPDATE_KITPACK_BUNDLE, UPDATE_KITPACK_BUNDLE_SUCCESS, UPDATE_KITPACK_BUNDLE_FAIL],
        promise: client => client.patch('/kitpackbundles/' + bundleId + '/save', {data, accessToken})
    };
}

export function deleteBundle(bundleId, accessToken) {
    return {
        types: [DELETE_BUNDLE, DELETE_BUNDLE_SUCCESS, DELETE_BUNDLE_FAIL],
        promise: client => {
            return client.del('/kitpackbundles/' + bundleId, {accessToken});
        }
    };
}

export function deleteKitpack(kitpackId, accessToken) {
    return {
        types: [DELETE_KITPACK, DELETE_KITPACK_SUCCESS, DELETE_KITPACK_FAIL],
        promise: client => {
            return client.del('/kitpacks/' + kitpackId, {accessToken});
        }
    };
}
