import React, {Component }from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import { connect }                   from 'react-redux';
import {
    loadWithIncludes as loadAgreement,
    loadAgreements,
    signAgreement
}                                    from 'redux/modules/agreement.js';
import { loadBuildings }             from 'redux/modules/building.js';
import {
    ConfirmableButton,
}                                    from 'components';
import { colors } from 'styles/design.js';

class SignAgreementButton extends Component {
    state = {
        signing: false
    };

    static propTypes = {
        agreement: PropTypes.object.isRequired,
    };

    sign = (event) => {
        this.setState({ signing: true });
        this.props.signAgreement(this.props.agreement, this.props.profile.accessToken)
        .then(()=>{
            this.setState({ signing: false });
        });
    };

    render() {
        const { t, agreement } = this.props;
        const canBeSigned = agreement.agreementStatus === 'approved';
        if (canBeSigned) {
            return <ConfirmableButton
                action={this.sign}
                confirmText={t('confirm_approve_addon')}
                buttonText={t('set_agreement_signed')}
                showLoading={this.state.signing}
                style={{backgroundColor: colors.lightBlue, borderColor: colors.lightBlue}}
            />;
        }
        return null;
    }
}


function mapStateToProps(state) {
    return {
        countries: state.countries,
        selectedCountry: state.countries.selectedCountry,
        languages: state.translations.languages,
        profile: state.userProfile,
        files: state.files,
        disciplineMetadata: state.discipline.disciplineMetadata,
        discipline: state.discipline,
        department: state.department,
        translations: state.translations
    };
}
function mapDispatchToProps(dispatch) {
    return {
        signAgreement: (agreement, accessToken) => {
            const agreementId = agreement && agreement.id;
            const clientId = agreement && agreement.contractInfo && agreement.contractInfo.clientId;
            return dispatch(signAgreement(agreementId, accessToken))
                .then(() => {
                    return Promise.all([
                        dispatch(loadAgreements(clientId, accessToken)),
                        dispatch(loadBuildings(clientId, accessToken)),
                        dispatch(loadAgreement(agreementId, accessToken, true)),
                    ]);
                });
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(SignAgreementButton));
