import React, {Component}      from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import { commonStyles, measures, layout } from 'styles/design.js';
import { SFInputField, CostNumber, SFMultiplierSelect }       from 'components';

const fieldStyle = {
    flex: 1,
    marginRight: measures.half
};

const placeHolderFieldStyle = {
    ...layout.inlineBlock,
    width: '21em',
    marginRight: measures.standard
};

class RepairEdit extends Component {

    static propTypes = {
        form: PropTypes.object.isRequired,
        calculation: PropTypes.array,
        removeRepairAction: PropTypes.func.isRequired,

    }

    static defaultProps = {
        repairs: [],
        calculation: [],
    };

    render() {
        const { t, form: {repairs}, calculation } = this.props;
        return (
                <div className="clearfix">
                    <div>
                        { (() => {
                            if (repairs) {
                                return repairs.map((repair, index) => {
                                    const calc = calculation && calculation[index] ? calculation[index] : {cost:0};
                                    return (
                                        <div key = { index } style = {{ marginBottom: measures.double }}>
                                            <div style = { layout.inlineBlock }>
                                                <div>
                                                    <button type = "button" style = {{ ...commonStyles.smallDeleteButton, ...layout.floatRight, width: '5em' }} onClick = { () => this.props.removeRepairAction(index) }>{ t('delete') }</button>
                                                    <h3 style = {{ marginBottom: measures.standard, minHeight: measures.double }} >{ repair.description ? repair.description.value : '' }</h3>
                                                </div>
                                                <SFInputField
                                                    defaultInput={true}
                                                    fieldName= { t('description') }
                                                    { ...repair.description }
                                                />
                                                <div style = {{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'space-between', width: '100%' }}>
                                                    <div style = { fieldStyle }>
                                                        <SFInputField
                                                            defaultInput={true}
                                                            fieldName= { t('material_quantity') }
                                                            type="number"
                                                            { ...repair.materialQuantity }
                                                            />
                                                    </div>
                                                    <div style = { fieldStyle }>
                                                        <SFInputField
                                                            defaultInput={true}
                                                            fieldName= { t('material_cost') }
                                                            type="number"
                                                            { ...repair.materialCost }
                                                            placeholder = "0,00"/>
                                                    </div>
                                                    <div style = { fieldStyle }>
                                                        <SFInputField
                                                            defaultInput={true}
                                                            fieldName= { t('hours') }
                                                            type="number"
                                                            { ...repair.hours }
                                                        />
                                                    </div>
                                                    <div style = { fieldStyle }>
                                                        <SFInputField
                                                            defaultInput={true}
                                                            fieldName= { t('visits') }
                                                            type="number"
                                                            { ...repair.visits }
                                                        />
                                                    </div>
                                                    <div style = {{ ...fieldStyle, width: '10em', marginRight: 0 }}>
                                                        <SFMultiplierSelect multiplier = { repair.multiplier } showEvery10th={true} boxWidth="10em" />
                                                    </div>
                                                </div>
                                                <div style = { placeHolderFieldStyle }>
                                                </div>
                                            </div>
                                            <div style = { layout.floatRight }>
                                                <div style = { layout.floatRight }>
                                                    {
                                                        (() => {
                                                            if(this.props.calculating) {
                                                                return (<span>{t('calculating')}</span>);
                                                            }
                                                            return (
                                                                <CostNumber>{ calc.cost }</CostNumber>
                                                            );
                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                });
                            }
                        })()}
                    </div>
                </div>
    );}
}

export default translations(RepairEdit);