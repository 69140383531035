import {createReducerFromMapping} from 'redux/utils/index.js';
const SAVE      = 'ServiFlex/Report/SAVE',
    SAVE_SUCCESS = 'ServiFlex/Report/SAVE_SUCCESS',
    SAVE_FAIL    = 'ServiFlex/Report/SAVE_FAIL',
    DEL      = 'ServiFlex/Report/DEL',
    DEL_SUCCESS = 'ServiFlex/Report/DEL_SUCCESS',
    DEL_FAIL    = 'ServiFlex/Report/DEL_FAIL';

const initialState = {
    saving: false,
    saved: false,
    reload: true
};

export default createReducerFromMapping({
    [SAVE]: (state) => ({
        ...state,
        saving: true,
        loading: true,
    }),
    [SAVE_SUCCESS]: (state, action) => ({
        ...state,
        editingSystem: action.result.id,
        loading: false,
        saving: false,
        saved: true,
        result: action.result
    }),
    [SAVE_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        saving: false,
        saved: false,
        error: action.error
    }),
    [DEL]: (state) => ({...state, loading: true}),
    [DEL_SUCCESS]: (state) => ({...state, loading: false}),
    [DEL_FAIL]: (state) => ({...state, loading: false}),
}, initialState);

export function addSystem(system, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => {
            const options = {data: system, accessToken};
            return client.post('/disciplinesystems/', options);
        }
    };
}

export function saveSystem(system, accessToken) {
    if(system.id) {
        return {
            types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
            promise: (client) => {
                const options = {data: system, accessToken};
                return client.patch('/disciplinesystems/' + system.id, options);
            }
        };
    } else {
        return addSystem(system, accessToken);
    }
}

export function deleteSystem(id, accessToken) {
    return {
        types: [DEL, DEL_SUCCESS, DEL_FAIL],
        promise: (client) => client.del('/disciplinesystems/' + id, {accessToken})
    };

}

