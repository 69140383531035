import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium from 'configuredRadium.js';
import {connect} from 'react-redux';
import {localeCurrencyString} from 'helpers/stringTemplates.js';
import {layout, colors} from 'styles/design.js';

const costNumber = [
    layout.inlineBlock,
    layout.verticalMiddle,
    {
        lineHeight: '2em',
        fontWeight: 'bold'
    }
];

class CostNumber extends Component {

    static propTypes = {
        format: PropTypes.string,
    }

    render() {
        const currency = this.props.selectedCountry && this.props.selectedCountry.currency ? this.props.selectedCountry.currency : 'eur';
        const language = this.props.selectedCountry && this.props.selectedCountry.defaultLanguage ? this.props.selectedCountry.defaultLanguage : 'en';
        const isNegative = this.props.children < 0;
        return (
            <div id={this.props.id}
                 style={[costNumber, this.props.style ? this.props.style : {}, isNegative ? {color: colors.basicRed} : {}]}>
                {localeCurrencyString(this.props.children, {locale: language})} {this.props.hideCurrency ? '' : currency}
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        selectedCountry: state.countries.selectedCountry
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(CostNumber)));
