const LOAD_ALL = 'ServiFlex/BillingIndex/LOAD_ALL';
const LOAD_ALL_SUCCESS = 'ServiFlex/BillingIndex/LOAD_ALL_SUCCESS';
const LOAD_ALL_FAIL = 'ServiFlex/BillingIndex/LOAD_ALL_FAIL';
const SAVE = 'ServiFlex/BillingIndex/SAVE';
const SAVE_SUCCESS = 'ServiFlex/BillingIndex/SAVE_SUCCESS';
const SAVE_FAIL = 'ServiFlex/BillingIndex/SAVE_FAIL';
const DELETE = 'ServiFlex/BillingIndex/DELETE';
const DELETE_SUCCESS = 'ServiFlex/BillingIndex/DELETE_SUCCESS';
const DELETE_FAIL = 'ServiFlex/BillingIndex/DELETE_FAIL';

const initialState = {
    loading: false,
    billingIndexes: [],
    all: [],
    loaded: false,
    reload: true,
    saving: false,
    saved: false
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD_ALL:
            return {
                ...state,
                loading: true
            };

        case LOAD_ALL_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                all: action.result
            };

        case LOAD_ALL_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SAVE:
            return {
                ...state,
                saving: true
            };

        case SAVE_SUCCESS:
            return {
                ...state,
                saving: false,
                saved: true
            };
        case SAVE_FAIL:
            return {
                ...state,
                saving: false,
                saved: false
            };
        default:
            return state;
    }
}

export function loadBillingIndexes(accessToken) {
    return {
        types: [LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_FAIL],
        promise: (client) => client.get('/billingindexes', { accessToken }
        )
    };
}
export function saveBillingIndex(data, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.patch('/billingIndexes', { data, accessToken})
    };
}
export function addBillingIndex(data, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.post('/billingIndexes', { data, accessToken})
    };
}

export function deleteBillingIndex(id, accessToken) {
    return {
        types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
        promise: (client) => client.del('/billingIndexes/' + id, { accessToken})
    };
}
