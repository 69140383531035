import React, {Component}           from 'react';

import {
    ListItemHeader,
    SubListItem
}                                   from 'components';

const styles = {
    subListVisible: {
        opacity: 1,
        transform: 'translateY(0)',
        transition: 'opacity 0.3s, transform 0.3s'
    },
    subListHidden: {
        opacity: 0,
        height: 0,
        overflow: 'hidden',
        visibility: 'hidden',
        transform: 'translateY(10px)'
    }
};

export default class ListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true
        };
    }
    handler() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    render() {
        const { listItem } = this.props;
        console.log(listItem);
        if (listItem && listItem.data && listItem.data.length && listItem.data[0].totals) {
            const sums = listItem.data.reduce((acc, item, index) => {
                console.log(`${item.title}, costs: ${item.totals.costs}, income: ${item.totals.income}`);
                if (item && item.totals) {
                    acc.costs = acc.costs + item.totals.costs;
                    acc.income = acc.income + item.totals.income;
                }
                return acc;
            }, { costs: 0, income: 0 });
            console.log(listItem.status, sums);
        }
        return (
            <ListItemHeader collapsed={ this.state.collapsed } handler={ this.handler.bind(this) } iconStyle={ listItem.icon } color={ listItem.color } title={ listItem.title }>
                <ul style={ this.state.collapsed ? styles.subListHidden : styles.subListVisible }>
                {
                    (() => {
                        if (listItem.data) {
                            return listItem.data.map((data, idx) => {
                                return (
                                    <SubListItem
                                        key={ 'sublist_item_' + idx }
                                        title={ data.title }
                                        subtitle={ data.subtitle }
                                        href={ data.href }
                                        variant="document"
                                    />
                                );
                            });
                        }
                    })()
                }
                </ul>
            </ListItemHeader>
        );
    }
}
