import {createReducerFromMapping} from 'redux/utils/index.js';

const UPDATE = 'ServiFlex/Attachment/UPDATE',
    UPDATE_FAIL = 'ServiFlex/Attachment/UPDATE_FAIL',
    UPDATE_SUCCESS = 'ServiFlex/Attachment/UPDATE_SUCCESS',
    ADD = 'ServiFlex/Attachment/ADD',
    ADD_FAIL = 'ServiFlex/Attachment/ADD_FAIL',
    ADD_SUCCESS = 'ServiFlex/Attachment/ADD_SUCCESS',
    DELETE = 'ServiFlex/Attachment/DELETE',
    DELETE_FAIL = 'ServiFlex/Attachment/DELETE_FAIL',
    DELETE_SUCCESS = 'ServiFlex/Attachment/DELETE_SUCCESS';

const initialState = {
    updating: false,
    updated: false,
    reload: true
};

export default createReducerFromMapping({
    [UPDATE]: (state, action) => ({
        ...state,
        updating: true
    }),
    [UPDATE_SUCCESS]: (state, action) => ({
        ...state,
        updating: false,
        updated: true,
        file: action.result
    }),
    [UPDATE_FAIL]: (state, action) => ({
        ...state,
        updating: false,
        updated: false,
        error: action.error
    }),
    [ADD]: (state, action) => ({
        ...state,
        adding: true
    }),
    [ADD_SUCCESS]: (state, action) => ({
        ...state,
        adding: false,
        added: true,
        file: action.result
    }),
    [ADD_FAIL]: (state, action) => ({
        ...state,
        adding: false,
        added: false,
        error: action.error
    }),
    [DELETE]: (state, action) => ({
        ...state,
        deleting: true
    }),
    [DELETE_SUCCESS]: (state, action) => ({
        ...state,
        deleting: false,
        deleted: true
    }),
    [DELETE_FAIL]: (state, action) => ({
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
    })
}, initialState);

export function updateAttachment(data, accessToken) {
    if(!data.id) {
        return addAttachment(data, accessToken);
    }
    return {
        types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
        promise: client => {
            return client.patch('/attachments/' + data.id, {data, accessToken});
        }
    };
}

export function addAttachment(data, accessToken) {
    return {
        types: [ADD, ADD_SUCCESS, ADD_FAIL],
        promise: client => {
            return client.post('/attachments', {data, accessToken});
        }
    };
}

export function deleteAttachment(attachment, accessToken) {
    return {
        types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
        promise: client => {
            return client.del('/attachments/' + attachment.id, {accessToken});
        }
    };
}

