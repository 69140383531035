import React from 'react';

const TabWrapper = ({children, style, vertical, ...other}) => {
    let tabClass = "tabs";
    if (vertical) {
        tabClass += " tabs-vertical";
    }

    return (
        <div 
            className={tabClass}
            style={style} 
            {...other}
        >
            {children}
        </div>
    );
};

export default TabWrapper;