import React from 'react';
import { scaleLinear, scaleBand } from 'd3-scale';
import { max } from 'd3-array'

import Axis from './Axis.js';
import Bars from './Bars.js';
import Chart from './Chart.js';

const BarChart = (props) => {
    const {data, showValues} = props;
    const margin = props.margin || {top: 40, right: 20, bottom: 40, left: 20};
    const width = props.width - margin.left - margin.right;
    const height = props.height - margin.top - margin.bottom;

    const yScale = scaleLinear()
        .rangeRound([height, 0])
        .domain([0, max(data, d => d.value)])
    const xScale = scaleBand()
        .rangeRound([0, width])
        .padding(0.1)
        .domain(data.map(d => d.label));

    return (
        <Chart
            width={props.width} 
            height={props.height}
            margin={margin}
        >
            <Axis 
                yScale={yScale}
                xScale={xScale}
                height={height}
                width={width}
                margin={margin}
            />
            <Bars 
                xScale={xScale}
                yScale={yScale}
                width={width}
                height={height}
                data={data}
                showValues={showValues}
            />
        </Chart>
    )
}

export default BarChart;