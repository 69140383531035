
import React, {Component}   from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import _                                from 'lodash';
import {
    DisciplineIcon,
    CostNumber,
}                                       from 'components';
import {
    fonts,
    size,
    layout,
    colors,
    measures,
    commonStyles
}                                       from 'styles/design.js';
import { getSmartViewTotalPrice } from './utils';

const containerStyle = {
    width: '100%',
};

const disciplineNameStyle = [
    fonts.sanches,
    size.normal,
    layout.inline,
    {
        paddingLeft: measures.standard,
        paddingRight: measures.standard
    }
];

const disciplineIconStyle = [
    {
        margin: measures.standard,
        maxWidth: '50px'
    }
];

class AgreementCostSummary extends Component {

    static propTypes = {
        agreement: PropTypes.object.isRequired,
        disciplines: PropTypes.array.isRequired,
        buildings: PropTypes.array.isRequired,
        diff: PropTypes.object,
    };

    agreementTotal() {
        const {
            disciplines,
            agreement,
        } = this.props;
        let sum = 0;
        const smartViewPrice = getSmartViewTotalPrice(agreement, agreement.smartViewPricing, agreement.clientNetCustomPrice);
        if(disciplines) {
            disciplines.forEach((discipline) => {
                sum += this.subdisciplineTotal(discipline);
            });
        }
        if(agreement.kitpackTotals) {
            sum += _.get(agreement, 'kitpackTotals.customerYearlyPrice', 0);
        }
        if(agreement.clientNet && smartViewPrice != null) {
            sum += smartViewPrice;
        }
        return sum;
    }

    serviflexAgreementTotal() {
        let sum = 0;
        if(this.props.disciplines) {
            this.props.disciplines.forEach((discipline) => {
                sum += this.subdisciplineTotal(discipline);
            });
        }
        return sum;
    }

    disciplineTotal(disciplines) {
        let sum = 0;
        disciplines.forEach((discipline) => {
            sum += this.subdisciplineTotal(discipline);
        });
        return sum;
    }

    subdisciplineTotal(subdiscipline) {
        let sum = 0;
        if(!subdiscipline.systemsCostCalculation) {
            return sum;
        }
        subdiscipline.systemsCostCalculation.forEach((systemCalculation) => {
            sum += this.systemTotalFromCalculation(systemCalculation);
        });
        if (this.props.diff && !isNaN(this.props.diff[subdiscipline.id])) {
            sum = this.props.diff[subdiscipline.id];
        }
        return sum;
    }
    systemTotalFromCalculation(systemCalculation) {
        return (systemCalculation.totalIncome);
    }

    gross(vat, net) {
        return net + (net * (vat / 100));
    }

    vatSum(vat, net) {
        return this.gross(vat, net) - net;
    }
    toggleShowMonthly() {
        const self = this;
        return (event) => {
            self.setState({showMonthly: event.target.checked});
            this.props.changeShowMonthly(event.target.checked);
        };
    }

    render() {
        const { agreement, disciplines, t, countryConfig, showLevel } = this.props;
        const diff = this.props.diff || {};
        const groups = _.groupBy(disciplines, 'disciplineCategory');
        const diffTotal = _.sum(_.values(diff));
        let vat = 0;
        if(agreement && countryConfig) {
            vat = agreement.vat || countryConfig.vat;
        }
        let agreementTotalNet = this.agreementTotal();
        if (agreement.agreementType === 'addon' && diffTotal !== 0) agreementTotalNet = diffTotal;
        const showMonthlyPrice = this.state && this.state.showMonthly ? this.state.showMonthly : agreement.showMonthlyPrice;
        const smartViewPrice = getSmartViewTotalPrice(agreement, agreement.smartViewPricing, agreement.clientNetCustomPrice);

        return (
            <div style={ [containerStyle] }>
                { (() => {
                    if(showLevel > 0) {
                        return _.keys(groups).map((disciplineCategory) => {
                            const disciplineGroupTotal = this.disciplineTotal(groups[disciplineCategory]);
                            return (
                                <div style={{ marginBottom: '3em' }} key={ 'costsummary-' + disciplineCategory }>
                                    <div key={ 'cost-summary-' + disciplineCategory }
                                         style={{ display:'table', width: '100%' }}>
                                        <div style={{ display:'table-cell', width: '50px', verticalAlign: 'middle' }}>
                                            <DisciplineIcon style={ disciplineIconStyle }
                                                            diciplineType={ disciplineCategory }/>
                                        </div>
                                        <div style={{display:'table-cell', verticalAlign: 'middle' }}>
                                            <h2 style={ disciplineNameStyle }>{ t(disciplineCategory) }</h2>
                                        </div>
                                        <div style={{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                            <CostNumber>{ disciplineGroupTotal }</CostNumber>
                                        </div>
                                    </div>
                                    <div style={ [{ marginLeft: '50px', marginTop: 0, paddingLeft: '1em' }] }>
                                        { (() => {
                                            if (showLevel > 1) {
                                                const subdisciplines = groups[disciplineCategory];
                                                return subdisciplines.map((subdiscipline) => {
                                                    const building = _.find(this.props.buildings, {id: subdiscipline.buildingId}) || {};
                                                    const subdisciplineTotal = this.subdisciplineTotal(subdiscipline);
                                                    return (
                                                        <div key={ 'cost-summary-subdiscipline-' + subdiscipline.id }>
                                                            <div
                                                                style={{ display:'table', width: '100%', marginTop: '1em' }}>
                                                                <div style={{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle', fontWeight: 'bold', fontSize: '1.1em' }}>
                                                                    <span>
                                                                        { subdiscipline.disciplineName || t(subdiscipline.disciplineType) }
                                                                    </span>
                                                                    <span style={[layout.inline, {color: colors.gray, marginLeft: measures.half, fontSize: '0.8em'}]}>
                                                                        {building.name}
                                                                    </span>
                                                                </div>
                                                                <div style={{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                                                    <CostNumber style={{fontWeight:'normal'}}>{ subdisciplineTotal }</CostNumber>
                                                                </div>
                                                            </div>
                                                            { (() => {
                                                                if(showLevel > 2 && subdiscipline.systemsCostCalculation) {
                                                                    return subdiscipline.systemsCostCalculation.map((system, sysIndex) => {
                                                                        const systemTotal = this.systemTotalFromCalculation(system);
                                                                        return (
                                                                            <div key={ 'subdiscipline-systems-' + sysIndex }>
                                                                                <div style={{ display:'table', width: '100%', marginTop: '1em' }}>
                                                                                    <div style={[{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }, {fontSize: '0.9em', fontWeight: 'bold', paddingLeft: measures.standard}]}>
                                                                                        {system.systemID}: {system.equipmentType}
                                                                                    </div>
                                                                                    <div style={{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                                                                        <CostNumber style={{fontWeight:'normal'}}>{ systemTotal }</CostNumber>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    });
                                                                }
                                                            })()}
                                                        </div>
                                                    );
                                                });
                                            }
                                            return (<div />);
                                        })()}
                                    </div>
                                </div>
                            );
                        });
                    }
                    return (
                        <div style={[commonStyles.subSection]}>
                            <div style={{ display:'table', width: '100%' }}>
                                <div style={{ display: 'table-row' }}>
                                    <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }}>
                                        <h2 style = { disciplineNameStyle }>{ t('serviflex_agreement') }</h2>
                                    </div>
                                    <div style = {{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                        <CostNumber>{ this.serviflexAgreementTotal() }</CostNumber>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })()}
                {
                    this.props.agreement.kitpackTotals &&
                    <div style={commonStyles.subSection}>
                        <div style={{ display:'table', width: '100%' }}>
                            <div style={{ display: 'table-row' }}>
                                <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }}>
                                    <h2 style = { disciplineNameStyle }>{ t('monitoring_yearly_fee_description') }</h2>
                                </div>
                                <div style = {{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                    <CostNumber>{ _.get(this.props.agreement, 'kitpackTotals.customerYearlyPrice', 0) }</CostNumber>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    agreement.clientNet && agreement.clientNetCustomPrice != null &&
                    <div style={commonStyles.subSection}>
                        <div style={{ display:'table', width: '100%' }}>
                            <div style={{ display: 'table-row' }}>
                                <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }}>
                                    <h2 style = { disciplineNameStyle }>{ t('SmartView yearly price') }</h2>
                                </div>
                                <div style = {{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                    <CostNumber>{smartViewPrice}</CostNumber>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div style={commonStyles.subSection}>
                    <div style={{ display:'table', width: '100%' }}>
                        <div style={{ display: 'table-row' }}>
                            <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }}>
                                <h2 style = { disciplineNameStyle }>{ t('yearly_total') }</h2>
                            </div>
                            <div style = {{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                <CostNumber>{ agreementTotalNet }</CostNumber>
                            </div>
                        </div>
                        <div style={[{ display: 'table-row' }, diffTotal ? {} : {display: 'none'}]}>
                            <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }}>
                                <h2 style = { disciplineNameStyle }>{ t('addon') }</h2>
                            </div>
                            <div style = {{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                <CostNumber>{ diffTotal }</CostNumber>
                            </div>
                        </div>
                    </div>
                    { (() => {
                        if (vat && agreementTotalNet) {
                            return (
                                <div style={{ display:'table', width: '100%' }}>
                                    <div style={{ display: 'table-row' }}>
                                        <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }}>

                                        </div>
                                        <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }}>
                                            <h2 style = { disciplineNameStyle }>{t('vat')} {vat}%</h2>
                                        </div>
                                        <div style = {{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                            <CostNumber>{ this.vatSum(vat, agreementTotalNet) }</CostNumber>
                                        </div>
                                    </div>
                                    <div style={{ display: 'table-row' }}>
                                        <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }}>
                                        </div>
                                        <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }}>
                                            <h2 style = { disciplineNameStyle }>{t('yearly_total')} ({t('incl_vat')} {vat}%)</h2>
                                        </div>
                                        <div style = {{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                            <CostNumber>{ this.gross(vat, agreementTotalNet) }</CostNumber>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })()}
                    {
                        (() => {
                            return (
                                <div style={{paddingTop: measures.double, display: 'table', width: '100%'}}>
                                    <div style = {{display: 'table-row', width: '100%'}}>
                                        <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle', width: '70%' }}>
                                            <label>
                                                <input type = "checkbox" onChange = {this.toggleShowMonthly()} checked = {agreement.showMonthlyPrice} />
                                                {t('print_monthly_price')}
                                            </label>
                                        </div>
                                    </div>
                                    <div style={[{ display: 'table-row' }, (!showMonthlyPrice ? {textDecoration: 'line-through'} : {})]}>
                                        <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }}>
                                            <h2 style = { disciplineNameStyle }>{t('monthly_price')} ({t('excluding_vat')})</h2>
                                        </div>
                                        <div style = {{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                            <CostNumber style = {!showMonthlyPrice ? {textDecoration: 'line-through'} : {}}>{ agreementTotalNet / 12 }</CostNumber>
                                        </div>
                                    </div>
                                    <div style={[{ display: 'table-row' }, (!showMonthlyPrice ? {textDecoration: 'line-through'} : {})]}>
                                        <div style = {{ display:'table-cell', textAlign: 'left', verticalAlign: 'middle' }}>
                                            <h2 style = { disciplineNameStyle }>{t('monthly_price')} ({t('incl_vat')} {vat}%)</h2>
                                        </div>
                                        <div style = {{ display:'table-cell', textAlign: 'right', verticalAlign: 'middle' }}>
                                            <CostNumber style = {!showMonthlyPrice ? {textDecoration: 'line-through'} : {}}>{ this.gross(vat, agreementTotalNet) / 12 }</CostNumber>
                                        </div>
                                    </div>
                                </div>
                            );
                        })()
                    }
                </div>
            </div>
        );
    }
}

export default translations(configuredRadium(AgreementCostSummary));