import React from 'react';

import './Bundle.css';

class BundleCostSummary extends React.Component {
    state = {
        isHidden: false
    };

    toggleVisibility = () => {
        this.setState({
            isHidden: !this.state.isHidden
        });
    };

    handleClick = (e) => {
        e.preventDefault();
        this.toggleVisibility();
    }

    render() {
        const {children, t} = this.props;
        const contentClass = "bundle-cost-summary-content";
        // const toggleClass = "bundle-cost-summary-toggle";
        const isHidden = this.state.isHidden;

        return (
            <div className="bundle-cost-summary">
                {/*
                <a className={!isHidden ? toggleClass + ' is-hidden' : toggleClass} href="" onClick={this.handleClick}>
                    {t('Show cost summary')}
                    <span className="bundle-cost-summary-arrow">
                        <i className="fa fa-angle-double-down" aria-hidden="true" />
                    </span>
                </a>
                */}
                <div className={isHidden ? contentClass + ' is-hidden' : contentClass}>
                    {children}
                </div>
            </div>
        );
    };
};

export default BundleCostSummary;