import React, { Component } from 'react';
import PropTypes            from 'prop-types';
import translations         from 'decorators/translations.js';
import configuredRadium     from 'configuredRadium.js';
import { Link }             from 'react-router-dom';

import {
    commonStyles
} from 'styles/design.js';
class EditClientButton extends Component {

    static propTypes = {
        clientId: PropTypes.number.isRequired
    };

    render() {
        return (
        <Link
            to = {'/client/' + this.props.clientId + '/details/edit'}
            style = { commonStyles.buttonLink }
        >
            {this.props.t('edit')}
        </Link>
    );}
}

export default translations(configuredRadium(EditClientButton));