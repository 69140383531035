import superagent from 'superagent';
import _ from 'lodash';

const formatUrl = (ServerURL, path) => {
    if(ServerURL) {
        return ServerURL + path;
    }

    return path;
};

export default class ApiClient {
    constructor(ServerURL, APISubscriptionKey) {
        ['get', 'post', 'put', 'patch', 'del'].forEach((method) => {
            this[method] = (path, options) => {
                return new Promise((resolve, reject) => {
                    const url = formatUrl(options && options.overrideServerURL !== undefined ? options.overrideServerURL : ServerURL, path);
                    const superRequest = superagent[method](url);
                    if(options && options.params) {
                        superRequest.query(options.params);
                    }
                    if(options && options.data && options.data.file) {
                        superRequest.attach(options.data.file.param, options.data.file.file, options.data.file.filename);
                        _.forEach(options.data, function parser(value, key) {
                            if (key !== 'file') {
                                superRequest.field(key, value);
                            }
                        });
                    } else if(options && options.data) {
                        superRequest.type('json');
                        superRequest.send(options.data);
                    } else if (options && options.blob) {
                        superRequest.responseType('blob');
                    }

                    // Include given access token to request headers.
                    if(options && options.accessToken) {
                        superRequest.set('Authorization', 'Bearer ' + options.accessToken);
                    }

                    // Include API management subscription key to request headers.
                    superRequest.set('Ocp-Apim-Subscription-Key', APISubscriptionKey);

                    superRequest.end((error, response) => {
                        if(error) {
                            reject((response && response.body) || error);
                        } else {
                            resolve(response.body);
                        }
                    });
                });
            };
        });
    }
}
