import React, { Component }  from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import {
    KeyValueDiv,
    FrameAgreementDisciplineList
}                                       from 'components';
import {
    commonStyles,
    layout,
    colors,
    measures,
    fonts
}                                       from 'styles/design.js';

import { RichTextView }                 from 'components/index';

const dataParentDiv = {
    paddingTop: measures.half,
    paddingLeft: measures.half,
    paddingRight: measures.half,
    marginTop: measures.half
};

const dataContentHeaderDiv = {
    backgroundColor: colors.lightBlue,
    padding: measures.half,
    marginTop: measures.standard,
    border: '1px solid ' + colors.lightGray,
    color: '#fff',
    ...fonts.plainsanches
};

class FrameAgreementConfigInformation extends Component {
    static propTypes = {
        countryConfig: PropTypes.object.isRequired
    };

    componentWillMount() {
        this.setState({ showDisciplines: true });
    }

    toggleShowDisciplines() {
        this.setState({ showDisciplines: !this.state.showDisciplines });
    }

    render() {
        const {
            t,
            filesFound,
            countryConfig,
        } = this.props;

        if (!countryConfig.frameAgreementConfig) {
            countryConfig.frameAgreementConfig = {
                pricePerKm: 0,
                pricePerHour: 0,
                maxDiscount: 0,
                minSubcontractingMargin: 0,
                totalLiability: 0,
                totalLiabilityComment: '',
                projectLeader: {
                    basicCharge: 0,
                    overtime50: 0,
                    overtime100: 0,
                    discount: 0
                },
                disciplines: []
            };
        }
        return (
            <div>
                <div style={[{ height: '2em', marginTop: measures.double }]}>
                    <div style={ [layout.floatRight, layout.inlineBlock] }>
                        <button
                            type="button"
                            onClick={ this.props.editClicked }
                            style={ [
                                commonStyles.button,
                                { backgroundColor: colors.lightBlue }
                            ] }>
                            { t('edit') }
                        </button>
                    </div>
                </div>
                {/*
                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('terms_and_conditions_comment')}:
                    </div>

                    <RichTextView
                        labelStyle={{ padding: measures.standard }}
                        value={ countryConfig.frameAgreementConfig.termsAndConditionsComment }
                    />
                </div>
                */}
                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('service_car_values')}:
                    </div>
                    {
                        countryConfig.frameAgreementConfig && countryConfig.frameAgreementConfig.serviceCar && countryConfig.frameAgreementConfig.serviceCar.map((scar, idx) =>
                            <KeyValueDiv key={'scar_' + idx} name={t(scar.name)} value={ scar.name !== 'other' ? scar.value + ' ' +countryConfig.currency : scar.description} />
                        )
                    }
                </div>

                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('additional_fees')}:
                    </div>
                    {
                        countryConfig.frameAgreementConfig && countryConfig.frameAgreementConfig.additionalFees && countryConfig.frameAgreementConfig.additionalFees.map((fee, idx) =>
                            <div key={'fee_idx_' + idx}>
                                <KeyValueDiv name={t(fee.name)} value={ (fee.value || '') + ' ' + (fee.valueType || '') } />
                                <div style={{marginLeft: measures.double, paddingLeft: measures.standard, fontSize: '0.9em'}}>{fee.description}</div>
                            </div>
                        )
                    }
                </div>

                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('price_values')}:
                    </div>

                    <KeyValueDiv
                        name={t('overtime50multiplier')}
                        value={(countryConfig.frameAgreementConfig.overtime50multiplier ? countryConfig.frameAgreementConfig.overtime50multiplier : 0)}
                    />

                    <KeyValueDiv
                        name={t('overtime100multiplier')}
                        value={(countryConfig.frameAgreementConfig.overtime100multiplier ? countryConfig.frameAgreementConfig.overtime100multiplier : 0)}
                    />

                    <KeyValueDiv
                        name={t('max_customer_discount')}
                        value={(countryConfig.frameAgreementConfig.maxDiscount ? countryConfig.frameAgreementConfig.maxDiscount : 0) + ' %'}
                    />

                    <KeyValueDiv
                        name={t('min_subcontracting_margin')}
                        value={(countryConfig.frameAgreementConfig.minSubcontractingMargin ? countryConfig.frameAgreementConfig.minSubcontractingMargin : 0) + ' %'}
                    />
                </div>

                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('liability')}:
                    </div>

                    <KeyValueDiv
                        name={t('total_liability')}
                        value={(countryConfig.frameAgreementConfig.totalLiability ? countryConfig.frameAgreementConfig.totalLiability : 0) + ' ' + countryConfig.currency}
                    />

                    <KeyValueDiv
                        name={t('total_liability_comment')}
                        value={countryConfig.frameAgreementConfig.totalLiabilityComment}
                    />
                </div>
                <div style={dataParentDiv}>
                    <div style={dataContentHeaderDiv}>
                        {t('attachment')}:
                    </div>

                    <div style={[{ padding: measures.standard }]}>
                        {t('frame_terms_file') + ' - ' + t('en')}
                    </div>

                    <div style={[{ padding: measures.standard }]}>
                        {
                            (() => {
                                if (filesFound.en) {
                                    return (
                                        <div style={{cursor: 'pointer', display: 'inline'}} onClick={(event) => this.props.downloadFile(event, filesFound.en.name)}>
                                            <i style={ { fontSize: '2.2em' } }
                                               className={ 'fa fa-file-text-o' } /> {filesFound.en.name}
                                        </div>
                                    );
                                }
                                return (
                                    <span style={{ color: colors.gray }}>
                                        {t('file_not_found')}
                                    </span>
                                );
                            })()
                        }
                    </div>

                    <div style={[{ padding: measures.standard }]}>
                        {t('frame_terms_file') + ' - ' + t(countryConfig.defaultLanguage)}
                    </div>

                    <div style={[{ padding: measures.standard }]}>
                        {
                            (() => {
                                if (filesFound.local) {
                                    return (
                                        <div style={{cursor: 'pointer', display: 'inline'}} onClick={(event) => this.props.downloadFile(event, filesFound.local.name)}>
                                            <i style={ { fontSize: '2.2em' } }
                                               className={ 'fa fa-file-text-o' } /> {filesFound.local.name}
                                        </div>
                                    );
                                }
                                return (
                                    <span style={{ color: colors.gray }}>
                                        {t('file_not_found')}
                                    </span>
                                );
                            })()
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default translations(configuredRadium(FrameAgreementConfigInformation));
