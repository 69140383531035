import React from 'react';

import translations from 'decorators/translations.js';
import {connect} from 'react-redux';
import _ from 'lodash';

import {
    StandardPage,
    BreadcrumbBar,
    TranslationForm,
    Tabs,
    Tab,
    Header
} from "components";

import GlobalAdminCollectorForm from './GlobalAdminCollectorForm';

import {loadWithType} from 'redux/modules/translations.js';

class GlobalAdminView extends React.Component {

    componentWillMount() {
        this.props.loadMasterEnglish(this.props.profile.accessToken);
    }

    render() {
        const agreementTranslations = _.filter(Object.keys(this.props.translations.translatables), (key) => key.indexOf('mainagreement_') !== -1);
        const frameAgreementTranslations = _.filter(Object.keys(this.props.translations.translatables), (key) => key.indexOf('frameagreement_') !== -1);
        const addonTranslations = _.filter(Object.keys(this.props.translations.translatables), (key) => key.indexOf('addonagreement_') !== -1);
        return (
            <StandardPage
                withBreadcrumb
                withSideNavigation
                loaderVisible={this.props.translations.loading}
            >
                <BreadcrumbBar
                    page="globaladmin"
                    path={this.props.location.pathname}
                />
                <Header title="Global admin"/>
                <Tabs vertical>
                    <Tab title="Serviflex Translations">
                        {
                            agreementTranslations && agreementTranslations.length > 0 &&
                            <TranslationForm
                                key="mainagreement_form"
                                localizables={agreementTranslations}
                                country="globaladmin"
                                language="en-master"
                            />
                        }

                    </Tab>
                    <Tab title="Frame agreement Translations">
                        {
                            frameAgreementTranslations && frameAgreementTranslations.length > 0 &&
                            <TranslationForm
                                key="frameagreement_form"
                                localizables={frameAgreementTranslations}
                                country="globaladmin"
                                language="en-master"
                            />
                        }
                    </Tab>
                    <Tab title="Addon Translations">
                        {
                            addonTranslations && addonTranslations.length > 0 &&
                            <TranslationForm
                                key="addonagreement_form"
                                localizables={addonTranslations}
                                country="globaladmin"
                                language="en-master"
                            />
                        }
                    </Tab>
                    <Tab title="CCC Collectors & Sensors">
                        {
                            <GlobalAdminCollectorForm t={this.props.t} />
                        }
                    </Tab>
                </Tabs>
            </StandardPage>
        );
    }

}

function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        translations: state.translations,
        admin: state.admin
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadMasterEnglish: (accessToken) => dispatch(loadWithType(2, 'en-master', null, accessToken)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(translations(GlobalAdminView));