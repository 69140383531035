export const getClientAddress = ({client, type}) => {
    if(!client) return {};
    return client[type] || client.clientAddress || {};
};

export const getBuildingAddress = ({building, client}) => {
    if(building.locationAddress && building.locationAddress.street) {
        return building.locationAddress;
    } else if(client.clientAddress && client.clientAddress.street) {
        return client.clientAddress;
    }
    return {};
};
