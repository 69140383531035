import React, {Component}    from 'react';
import translations from 'decorators/translations.js';
import configuredRadium            from 'configuredRadium.js';
import {connect}          from 'react-redux';

//import { load as loadTranslations }           from 'redux/modules/translations.js';
import {
    StandardPage,
    BreadcrumbBar
} from 'components';

import {
    commonStyles,
    colors
} from 'styles/design.js';

const headerStyle = {
    marginLeft: '1em',
    marginTop: '1em',
    padding: '1em',
    fontWeight: 'bold'
};
const listStyle = {
    marginLeft: '2em',
    padding: '0.5em'
};

class UserinfoView extends Component {

    componentWillMount() {
        this.setState({selectedLanguage: this.props.cookies.selectedLanguage ? this.props.cookies.selectedLanguage : 'en'});
    }
    componentWillReceiveProps(nextProps) {
        if(this.state.loadingTranslations && nextProps.translations.loaded) {
            this.setState({translationsUpdated: true, loadingTranslations: false});
        }
    }
    logout() {
        return (event) => {
            window.location.replace('/auth/logout');
            event.preventDefault();
        };
    }
    changeLanguage() {
        return () => {
            if(this.state.selectedLanguage !== '-') {
                //this.setState({loadingTranslations: true});
                //return this.props.dispatch(loadTranslations(this.state.selectedLanguage, this.props.profile.accessToken));
            }
        };
    }
    selectLanguage() {
        return (event) => {
            this.setState({selectedLanguage: event.target.value, translationsUpdated: false});
        };
    }

    render() {
        const {user} = this.props;
        return (
            <div>
                <StandardPage withBreadCrumb>
                    <BreadcrumbBar />
                    <header>
                        <h1 style={ commonStyles.pageTitle }>User info page (debug)</h1>
                    </header>
                    {(() => {
                        const returnables = [];
                        if (this.props.user) {
                            if (user.isAdmin) {
                                returnables.push(<div style={headerStyle}>User is admin</div>);
                            }
                            if (user.isController) {
                                returnables.push(<div style={headerStyle}>User is controller - able to see only own
                                    salesarea(s) but able to config countries</div>);
                            }
                            if (user.isApprover) {
                                returnables.push(<div style={headerStyle}>User is approver -able to approve agreements
                                    (not yet implemented)</div>);
                            }
                            if (user.salesAreas) {
                                returnables.push(<div style={headerStyle}>Users salesareas:</div>);
                                user.salesAreas.map((area) => {
                                    returnables.push(<li style={listStyle}>{area}</li>);
                                });
                            }
                            if (user.groups) {
                                returnables.push(<div style={headerStyle}>Users groups</div>);
                                user.groups.map((group) => {
                                    returnables.push(<li style={listStyle}>{group}</li>);
                                });
                            }
                            returnables.push(<div style={headerStyle}>Preferred languages list (from browser):</div>);
                            if (navigator) {
                                if (navigator.language) {
                                    returnables.push(<li style={listStyle}>{navigator.language}</li>);
                                }
                                if (navigator.languages) {
                                    navigator.languages.map((lang) => {
                                        returnables.push(<li style={listStyle}>{lang}</li>);
                                    });
                                }
                            }
                            returnables.push(<div style={headerStyle}><p><b>Navigator:</b></p>
                                <div
                                    style={{fontSize:'0.6em', padding: '1em', backgroundColor: colors.lightGray, border: '1px dashed ' + colors.gray, wordWrap: 'break-word'}}>
                                    {JSON.stringify(navigator)}
                                </div>
                            </div>);
                            returnables.push(<div style={headerStyle}><p><b>Whole user object:</b></p>
                                <div
                                    style={{fontSize:'0.6em', padding: '1em', backgroundColor: colors.lightGray, border: '1px dashed ' + colors.gray, wordWrap: 'break-word'}}>
                                    {JSON.stringify(this.props.user)}
                                </div>
                            </div>);
                        }
                        return returnables;
                    })()}
                    <div style={[headerStyle, {marginTop: '3em'}]}>
                        <a id="UserInfoView_logout" href="/auth/logout">Logout user</a>
                    </div>
                </StandardPage>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.userProfile.user,
        profile: state.userProfile,
        translations: state.translations,
        cookies: state.cookies
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(UserinfoView)));
