import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components';
import translations from 'decorators/translations.js';

import { saveSvgAsPng } from 'save-svg-as-png'

import './Chart.css';

class Chart extends Component {
    // Downloading doesn't work with IE, so let's add a way to check IE
    isIE() {
        const ua = window.navigator.userAgent;
        const msie = ua.indexOf("MSIE ");

        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv:11\./)) {
            return true;
        }
        return false;
    }

    onClick(e) {
        e.preventDefault();
        saveSvgAsPng(this.node, 'diagram.png', {scale: 1.5, backgroundColor: '#ffffff'});
    }

    render() {
        const {width, height, children, margin, legend, t} = this.props;
        let transform;
        if (margin && margin.left && margin.top) {
            transform = 'translate(' + margin.left + ',' + margin.top + ')';
        }
        return (
            <div className="chart-container">
                <svg 
                    className="chart" 
                    height={height}
                    width={width}
                    ref={node => {this.node=node}}
                >
                    <g transform={transform}>
                        {children}
                    </g>
                    {legend}
                </svg>
                {
                    !this.isIE() &&
                    <div style={{ textAlign: 'right', margin: '0.5em 0 2em' }}>
                        <Button 
                            size="xsmall"
                            variant="secondary" 
                            onClick={e=>this.onClick(e)}
                        >
                            { t('Download chart') } <i className="fa fa-download" />
                        </Button>
                    </div>
                }
            </div>
        )
    }
    
}

Chart.propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    children: PropTypes.node
}

export default translations(Chart);