import React from 'react';
import translations from 'decorators/translations.js';
import ReportSection from './ReportSection.js';
import {
    BarChart,
    StackedBarChart,
    ReportTableMulti,
    Section
} from 'components';

const ByCountry = ({
    chartSize,
    frameAgreementsByCountryAndStatuses,
    agreementsByCountryAndStatuses,
    agreementMarginsByCountry,
    agreementDataByCountry,
    t
}) => {

    if ((agreementsByCountryAndStatuses.data && agreementsByCountryAndStatuses.keys && agreementsByCountryAndStatuses.keys.length > 0)
        || (frameAgreementsByCountryAndStatuses.data && frameAgreementsByCountryAndStatuses.keys && frameAgreementsByCountryAndStatuses.keys.length > 0)
        || (agreementMarginsByCountry && agreementMarginsByCountry.length > 0)) { 
        return (
            <div>
                { frameAgreementsByCountryAndStatuses.data && frameAgreementsByCountryAndStatuses.keys && frameAgreementsByCountryAndStatuses.keys.length > 0 &&
                    <ReportSection title={ t('Frame agreements by country and status') }>
                        <StackedBarChart 
                            colors={frameAgreementsByCountryAndStatuses.colors}
                            data={frameAgreementsByCountryAndStatuses.data} 
                            keys={frameAgreementsByCountryAndStatuses.keys}
                            xAxisKey="label"
                            height={chartSize.height} 
                            width={chartSize.width} 
                        />
                        <ReportTableMulti
                            data={frameAgreementsByCountryAndStatuses.data} 
                        />
                    </ReportSection>
                }
                { agreementsByCountryAndStatuses.data && agreementsByCountryAndStatuses.keys && agreementsByCountryAndStatuses.keys.length > 0 &&
                    <ReportSection title={ t('Serviflex agreements by country and status') }>
                        <StackedBarChart 
                            colors={agreementsByCountryAndStatuses.colors}
                            data={agreementsByCountryAndStatuses.data} 
                            keys={agreementsByCountryAndStatuses.keys}
                            xAxisKey="label"
                            height={chartSize.height} 
                            width={chartSize.width} 
                        />
                        <ReportTableMulti
                            data={agreementsByCountryAndStatuses.data} 
                        />
                    </ReportSection>
                }
                { agreementMarginsByCountry && agreementMarginsByCountry.length > 0 && (
                        <ReportSection title={ t('Serviflex agreement totals by country')}>
                            <BarChart 
                                data={agreementMarginsByCountry} 
                                height={chartSize.height} 
                                width={chartSize.width} 
                                showValues
                            />
                            <ReportTableMulti 
                                data={agreementDataByCountry} 
                            />
                        </ReportSection>
                    )
                }
            </div>
        );
    }
    return (
        <Section>
            <p>{ t('no_agreements_available') }</p>
        </Section>
    )
}

export default translations(ByCountry);