import React from 'react';
import translations from 'decorators/translations.js';

import { connect } from 'react-redux';
import {
    isEditable
}                                   from 'helpers/agreement.js';
import _ from 'lodash';
import {
    StandardPage,
    BreadcrumbBar,
    BackLink,
} from 'components';

import {
    upsertAgreementContents,
    loadAgreementContents,
    deleteAgreementContents
} from 'redux/modules/agreementContents';

import { loadContractInfo, isContractInfoLoaded } from 'redux/modules/contractInfo';

import {
    loadFrameAgreement,
    shouldReloadFrameAgreement,
} from 'redux/modules/frameagreement.js';

import {
    loadCountry,
    isCountryLoaded
} from 'redux/modules/country.js';

import { loadWithType as loadTranslations } from 'redux/modules/translations.js';

import AgreementContents from 'components/AgreementContent/AgreementContents';

class FrameAgreementContentsView extends React.Component {
    state = {};

    componentDidMount() {
        const {match: {params}} = this.props;
        if (!isContractInfoLoaded(this.props, params.clientId)) {
            this.props.loadContractInfo(params.contractInfoId, this.props.profile.accessToken).then((res) => {
                this.loadCountryAndDepartments()
            });
        } else {
            this.loadCountryAndDepartments();
        }
        if (shouldReloadFrameAgreement(this.props, params.frameAgreementId)) {
            this.props.loadFrameAgreement(params.frameAgreementId, this.props.profile.accessToken);
        }

        if(params.frameAgreementId) {
            this.props.loadAgreementContents({frameAgreementId: params.frameAgreementId}, this.props.profile.accessToken);
        }
    }
    loadCountryAndDepartments() {
        const contractInfo = this.props.contractInfo.contractInfo || null;
        if (!this.props.countries.loading && !isCountryLoaded(this.props, contractInfo.salesArea)) {
            this.props.loadCountry(contractInfo.salesArea, this.props.profile.accessToken).then((res) => {
                if (res.result) {
                    this.loadTranslations();
                }
            })
        } else {
            this.loadTranslations();
        }
    }

    loadTranslations() {
        const country = this.props.countries.selectedCountry;
        if (country) {
            const languages = ['en'];
            const countryLang = country.defaultLanguage || 'en';
            if (countryLang !== 'en') languages.push(countryLang);

            this.props.loadTranslations(2, languages.join(','), country.id, this.props.profile.accessToken).then((transRes) => {
                if (transRes && transRes.result) {
                    const data = transRes.result;
                    const sections = _.map(_.filter(Object.keys(data), (key) => key.indexOf('frameagreement_s') !== -1 && key.indexOf('_header') !== -1), (row, idx) => {
                        return {key: row, id: (idx + 1), translations: data[row]};
                    });
                    this.setState( state => ({...state, sections}));
                }
            });
            this.setState({
                language: countryLang
            });
        }
    }

    saveAgreementContents = (content, section, language, id) => {
        const frameAgreementId = _.get(this.props, 'match.params.frameAgreementId');
        const data = {
            id,
            frameAgreementId,
            section,
            content,
            language,
        };
        return this.props.upsertAgreementContents(data, this.props.profile.accessToken)
            .then( res => this.props.loadAgreementContents({frameAgreementId}, this.props.profile.accessToken))
            .catch( error => console.error('error', error));
    }
    deleteAgreementContents = (id) => {
        const frameAgreementId = _.get(this.props, 'match.params.frameAgreementId');
        if (!id) {
            return null;
        }
        return this.props.deleteAgreementContents(id, this.props.profile.accessToken)
            .then( res => this.props.loadAgreementContents({frameAgreementId}, this.props.profile.accessToken))
            .catch( error => console.error('error', error));
    }
    render() {
        const languages = ['en'].concat(this.props.countries.selectedCountry ? [this.props.countries.selectedCountry.defaultLanguage] : []);
        const t = this.props.t,
            agreement = this.props.frameAgreement.agreement,
            client = this.props.clientState.client,
            contractInfo = this.props.contractInfo.contractInfo || {},
            params = this.props.match.params;

        return (
            <StandardPage
                withBreadcrumb
                loaderVisible={this.props.clientState.loading || this.props.frameAgreement.loading}>
                <BreadcrumbBar
                    page="agreement"
                    agreement={agreement}
                    contractInfo={ contractInfo }
                    actionUrl = { '/client/' + client.id + '/details' }
                    linkName = { t('show_client_details') }
                    lockedText = { !isEditable(agreement) ? t('locked') : null}
                />
                {
                    agreement && agreement.id &&
                    <BackLink
                        title={t('Back to agreement')}
                        to={`/client/${client.id}/contractInfo/${params.contractInfoId}/frameagreement/${agreement.id}`}
                        id="SLAView_Back"
                    />
                }
                {

                    !this.props.translations.loading && this.props.translations.translatables &&
                    <AgreementContents
                        languages={languages}
                        translatables={this.props.translations.translatables}
                        language={this.state.language || 'en'}
                        overridenContents={this.props.agreementContents.list}
                        saveAgreementContents={ this.saveAgreementContents }
                        deleteAgreementContents={ this.deleteAgreementContents }
                        t={t}
                        type="frameagreement"
                        agreement={agreement}
                    />
                }
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        clientState: state.client,
        frameAgreement: state.frameagreement,
        profile: state.userProfile,
        agreementContents: state.agreementContents,
        translations: state.translations,
        countries: state.countries,
        contractInfo: state.contractInfo,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadTranslations: (type, languages, countryId, accessToken) => dispatch(loadTranslations(type, languages, countryId, accessToken)),
        loadContractInfo: (id, accessToken) => dispatch(loadContractInfo(id, accessToken)),
        loadCountry: (salesArea, accessToken) => dispatch(loadCountry(salesArea, accessToken)),
        loadFrameAgreement: (agreementId, accessToken) => dispatch(loadFrameAgreement(agreementId, accessToken)),
        loadAgreementContents: (params, token) => dispatch(loadAgreementContents(params, token)),
        upsertAgreementContents: (data, token) => dispatch(upsertAgreementContents(data, token)),
        deleteAgreementContents: (contentId, token) => dispatch(deleteAgreementContents(contentId, token))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(translations(FrameAgreementContentsView));