import React, {Component}               from 'react';
import translations from 'decorators/translations.js';
import { connect }                      from 'react-redux';
import configuredRadium                 from 'configuredRadium.js';
import {
    loadAgreement,
    approve as approveAgreement,
    reject as rejectAgreement
}                                       from 'redux/modules/agreement.js';
import {
    loadFrameAgreement,
    approve as approveFrameAgreement,
    reject as rejectFrameAgreement
}                                       from 'redux/modules/frameagreement.js';
import { loadContractInfo }           from 'redux/modules/contractInfo.js';
import { download }                     from 'redux/modules/download.js';
import { loadCountry }                  from 'redux/modules/country.js';

import { commonStyles }                 from 'styles/design.js';
import _ from 'lodash';

import {
    StandardPage,
    BreadcrumbBar,
    ApprovePage,
    BackLink
} from 'components';
import { PrintingView } from 'containers';

class AgreementApproveView extends Component {
    componentWillMount() {
        this.setState({checkboxSelected: false});
        const promises = [];
        const params = this.props.match.params;
        promises.push(this.props.loadContractInfo(params.contractInfoId, this.props.profile.accessToken));
        if(this.props.match.params.agreementId) {
            promises.push(this.props.loadAgreement('agreement', params.agreementId, this.props.profile.accessToken, params.contractInfoId));
            this.setState({type: 'agreement'});
        } else if(this.props.match.params.frameAgreementId) {
            promises.push(this.props.loadAgreement('frameAgreement', this.props.match.params.frameAgreementId, this.props.profile.accessToken));
            this.setState({type: 'frameAgreement'});
        }
        return Promise.all(promises).then(() => {
            const contractInfo = this.props.contractInfo.contractInfo;
            if (contractInfo) {
                this.props.loadCountry(contractInfo.salesArea, this.props.profile.accessToken);
            }
        })
    }
    handleSubmit = ({approving, comment}) => {
        const fn = approving ? this.props.approveAgreement : this.props.rejectAgreement;
        let agreement;
        if(this.state.type === 'frameAgreement') {
            agreement = this.props.frameAgreementState.agreement;
        } else {
            agreement = this.props.agreementState.agreement;
        }
        fn(this.state.type, agreement.id, comment, this.props.profile.accessToken).then(() => {
            this.props.loadAgreement(this.state.type, agreement.id, this.props.profile.accessToken, this.props.match.params.contractInfoId).then(() => {
                this.setState({submitting: false});
            });
        });
        this.setState({submitting: true});
    };
    downloadFile = (event, downloadType, type, id, appendix, lang) => {
        event.preventDefault();
        this.openPrintingDialog({ printoutLanguage: lang });
    };
    openPrintingDialog = ({ printoutLanguage, printoutType }) => {
        this.setState({
            printoutLanguage: printoutLanguage ? printoutLanguage : 'en',
            printingDialog: true,
        });
    };
    closePrintingDialog = () => {
        this.setState({
            printoutLanguage: '',
            printingDialog: false,
        });
    };
    render() {
        const { agreementState, frameAgreementState, t, client, contractInfo } = this.props;
        if(!client || agreementState.loading || frameAgreementState.loading) {
            return <StandardPage loaderVisible={!client || agreementState.loading || frameAgreementState.loading}><div /></StandardPage>;
        }

        let agreement;
        if(this.state.type === 'agreement') {
            agreement = agreementState.agreement;
        } else {
            agreement = frameAgreementState.agreement;
        }
        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar contractInfo={ contractInfo && contractInfo.contractInfo } actionUrl={ '/client/' + this.props.match.params.clientId + '/details' } linkName={ t('show_client_details') }/>
                {
                    client && client.id && agreement && agreement.id &&
                    <BackLink title={t('Back to agreement')} to={'/client/' + client.id + '/contractInfo/' + agreement.contractInfoId + '/' + this.state.type + '/' + agreement.id + '/summary'} />
                }
                <header>
                    <h1 style={ [commonStyles.pageTitle, { marginTop: '0.175em', marginRight: '1em' }] }><div style = { commonStyles.headerIcon } ><i className= { 'fa fa-user' } /></div>{ client && client.name }</h1>
                </header>
                <div>
                    <h2 style={ commonStyles.pageSectionTitle }>{ t('agreement_approval') }</h2>
                </div>
                <ApprovePage
                    handleSubmit={this.handleSubmit}
                    downloadFile={this.downloadFile}
                    loadingFile={this.props.download.loading}
                    agreement={agreement}
                    language={_.get(this.props, 'countries.selectedCountry.defaultLanguage')}
                    type={this.state.type}
                    submitting={this.state.submitting}
                />
                {this.state.printingDialog &&
                    <PrintingView
                        agreementId={ this.props.match.params.agreementId }
                        frameAgreementId={ this.props.match.params.frameAgreementId }
                        closeAction={ this.closePrintingDialog }
                        lang={ this.state.printoutLanguage }
                    />
                }
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        agreementState: state.agreement,
        frameAgreementState: state.frameagreement,
        profile: state.userProfile,
        client: state.contractInfo.contractInfo.client,
        download: state.download,
        countries: state.countries,
        contractInfo: state.contractInfo,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadAgreement(type, id, token, contractInfoId) {
            if(type === 'frameAgreement') {
                return dispatch(loadFrameAgreement(id, token));
            }
            return dispatch(loadAgreement(id, contractInfoId, token));
        },
        loadContractInfo(id, token) {
            return dispatch(loadContractInfo(id, token));
        },
        approveAgreement(type, id, comment, token) {
            if(type === 'frameAgreement') {
                return dispatch(approveFrameAgreement(id, comment, token));
            }
            return dispatch(approveAgreement(id, comment, token));
        },
        rejectAgreement(type, id, comment, token) {
            if(type === 'frameAgreement') {
                return dispatch(rejectFrameAgreement(id, comment, token));
            }
            return dispatch(rejectAgreement(id, comment, token));
        },
        downloadFile: (downloadType, type, id, appendix, lang, accessToken) => {
            return dispatch(download({downloadType, type, id, appendix, lang}, accessToken));
        },
        loadCountry: (salesArea, accessToken) => {
            return dispatch(loadCountry(salesArea, accessToken));
        }
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(configuredRadium(AgreementApproveView)));
