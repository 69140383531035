import React, {Component}    from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import { measures, layout, size }       from 'styles/design.js';
import { CostNumber }                   from 'components';

const tableCell = {
    padding: measures.half
};

const tableCellHeader = {
    ...tableCell,
    ...size.small,
    fontWeight: 'bold',
    minWidth: '5em',
    textAlign: 'left'
};

const lastColumn = {
    textAlign: 'right'
};

class MaterialTable extends Component {

    static propTypes = {
        materials: PropTypes.array.isRequired,
        calculation: PropTypes.array
    };

    static defaultProps = {
        materials: [],
        calculation: [],
    };

    render() {
        const calculation = this.props.calculation || [];
        return (
            <div>
                    {(() => {
                        if ( this.props.materials && this.props.materials.length > 0) {
                            let totalSum = 0;
                            calculation.map((material) => { totalSum += (material.cost || 0); });
                            return (
                                <div>
                                        <table style={{ width: '100%' }}>
                                        <thead>
                                            <tr>
                                                <th style= { tableCellHeader }>{ this.props.t('description') }</th>
                                                <th style= { tableCellHeader }>{ this.props.t('material_quantity') }</th>
                                                <th style= { tableCellHeader }>{ this.props.t('material_cost') }</th>
                                                <th style= { tableCellHeader }>{ this.props.t('hours') }</th>
                                                <th style= { tableCellHeader }>{ this.props.t('visits') }</th>
                                                <th style= {{ ...tableCellHeader, ...lastColumn }}></th>
                                            </tr>
                                        </thead>
                                        {(() => {
                                            return this.props.materials.map((materialRow, index) => {
                                                const calc = calculation && calculation[index] ? calculation[index] : {cost:0};
                                                return (

                                                    <tbody key = { 'materials_' + index + '_tbody' }>
                                                        <tr>
                                                            <td style= { tableCell }>{ materialRow.description }</td>
                                                            <td style= { tableCell }>{ materialRow.materialQuantity }</td>
                                                            <td style= { tableCell }>{ materialRow.materialCost }</td>
                                                            <td style= { tableCell }>{ materialRow.hours }</td>
                                                            <td style= { tableCell }>{ materialRow.visits }</td>
                                                            <td style= {{ ...tableCell, ...lastColumn }}><CostNumber style = {{ fontWeight: 'normal' }}>{ !calc.cost ? 0 : calc.cost}</CostNumber></td>
                                                        </tr>
                                                        <tr>
                                                            <td style = { tableCellHeader }>{ this.props.t('hours_visits_repetition') }</td>
                                                            <td style = { tableCell } colSpan="3">
                                                                { this.props.t('visits_repetition_' + (materialRow.multiplier || 1 )) }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                );
                                            });
                                        })()}
                                        </table>
                                        <div style = {{ marginTop: measures.standard, marginBottom: measures.double, marginLeft: measures.half, marginRight: measures.half, fontWeight: 'bold' }}>{ this.props.t('material_total') }<CostNumber style = { layout.floatRight }>{ totalSum }</CostNumber></div>
                                </div>
                            );
                        }
                        return (<div style = {{ marginBottom: measures.double, marginLeft: measures.half, marginRight: measures.half }}>{ this.props.t('no_materials_added') }</div>);
                    })()}
          </div>
        );
    }
}
export default translations(MaterialTable);