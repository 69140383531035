import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import translations from 'decorators/translations.js';
import { startExport, exportStatus, exportDownload } from 'redux/modules/download';
import {
    Dialog,
    Button,
    LoadingIndicator,
} from 'components';

const TIMER = 3000;
const TIMEOUT_TIMER = 120000;
const CLOSE_TIMEOUT = 3000;

const containerStyles = {
    marginBottom: '1rem'
};

class PrintingView extends Component {
    timer = null;
    timeoutTimer = null;
    closeTimer = null;
    state = {
        closeTimeout: CLOSE_TIMEOUT
    };

    componentDidMount() {
        const {
            startExport,
            agreementId,
            frameAgreementId,
            printoutType,
            filetype,
            lang,
            profile: {
                accessToken
            },
        } = this.props;
        const id = {};
        if (agreementId) {
            id.agreementId = agreementId;
        }
        else if (frameAgreementId) {
            id.frameAgreementId = frameAgreementId;
        }
        else {
            this.handleFail();
        }
        startExport({ ...id, printoutType, filetype, lang }, accessToken)
            .then(({ result }) => {
                if (result && result.id) {
                    this.timer = setInterval(() => this.queryStatus(result.id), TIMER);
                    this.timeoutTimer = setTimeout(() => this.handleLongQuery(), TIMEOUT_TIMER); 
                }
                else {
                    this.handleFail();
                }
            });
    }

    componentWillUnmount() {
        this.clearTimers();
    }

    clearTimers = () => {
        clearInterval(this.timer);
        clearInterval(this.timeoutTimer);
        clearInterval(this.closeTimer);
        this.timer = null;
        this.timeoutTimer = null;
        this.closeTimer = null;
    };

    handleLongQuery = () => {
        this.handleFail(true);
    };

    handleFail = longQuery => {
        this.clearTimers();
        this.setState({
            downloadFailed: true,
            longQuery
        });
    };

    handleDownload = id => {
        const {
            profile: {
                accessToken
            },
            closeAction,
            exportDownload,
        } = this.props;
        const {
            closeTimeout
        } = this.state;
        this.clearTimers();
        this.setState({
            downloadReady: true
        });
        exportDownload(id, accessToken);
        let timer = closeTimeout;
        this.closeTimer = setInterval(() => {
            if (timer > 0) {
                this.setState({
                    closeTimeout: timer - 1000
                });
                timer = timer - 1000;
            }
            else {
                this.clearTimers();
                closeAction();
            }
        }, 1000);
    };

    queryStatus = id => {
        const {
            exportStatus,
            gettingStatus,
            profile: {
                accessToken
            },
        } = this.props;

        if (gettingStatus) {
            return;
        }
        exportStatus(id, accessToken).then(({ result }) => {
            if (result && result.status === 'success') {
                this.handleDownload(id);
            }
            else if (!result || result.status === 'failure') {
                this.handleFail();
            }
        });
    };

    render() {
        const {
            closeAction,
            t
        } = this.props;
        const {
            downloadReady,
            downloadFailed,
            closeTimeout,
            longQuery,
        } = this.state;

        return <Dialog>
            <div style={ containerStyles}>
                {!downloadReady && !downloadFailed &&
                    <div>
                        <LoadingIndicator visible /> { t('Creating printout, please wait.') }
                    </div>
                }
                {downloadReady &&
                    <div>
                        { t(`Your download should start now. This window will automatically close in {0} seconds.`, closeTimeout / 1000) }
                    </div>
                }
                {downloadFailed && 
                    <div>
                        { longQuery 
                            ? <span><LoadingIndicator visible /> { t('Creating printout is taking a long time. Please wait or cancel and try again') }</span>
                            : t('Creating printout failed.')
                        }
                    </div>
                }
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    onClick={ closeAction }
                >
                    {!downloadReady && !downloadFailed
                        ? t('cancel') 
                        : t('close')
                    }
                </Button>
            </div>
        </Dialog>;
    }
}

PrintingView.propTypes = {
    language: PropTypes.string.isRequired,
    agreementId: PropTypes.number,
    frameAgreementId: PropTypes.number,
    printoutType: PropTypes.string,
    filetype: PropTypes.string,
    appendix: PropTypes.string,
};

PrintingView.defaultProps = {
    filetype: 'pdf',
    appendix: '',
};

const mapStateToProps = state => ({
    profile: state.userProfile,
    gettingStatus: state.download.gettingStatus,
});

const mapDispatchToProps = dispatch => ({
    startExport: (printProps, token) => dispatch(startExport(printProps, token)),
    exportStatus: (id, token) => dispatch(exportStatus(id, token)),
    exportDownload: (id, token) => dispatch(exportDownload(id, token)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default translations(connector(PrintingView));