import React from 'react';
import translations from 'decorators/translations.js';
import {
    Card,
    Cards
} from 'components';

import './FrameAgreementContentList.css';

/**
 * Frame agreement content overview
 */
const FrameAgreementContentList = ({frameAgreement, params, t}) => {

    const renderIsSet = ({list, field, label}) => {
        let isSet = false;
        if (list && field) {
            isSet = list.length > 0 || field !== '';
        } else if (list) {
            isSet = list.length > 0;
        } else if (field) {
            isSet = field !== '';
        }
        return (
            <div key={label} className="agreement-content-list-row">
                <span>
                    <i className={'fa ' + (isSet ? 'fa-check card-body-icon-green' : 'fa-times card-body-icon-red')}/>
                    {label}
                    </span>
            </div>
        );
    };
    const basePath = `/client/${params.clientId}/contractInfo/${params.contractInfoId}/frameagreement/${frameAgreement.id}`;
    const listOptions = [{key: 'materials', label: 'materials'}, {
        key: 'subcontracting',
        label: 'subcontracting'
    }, {key: 'additionalFees', label: 'additional_fees'}];
    if (frameAgreement && frameAgreement.id) {
        return (
            <Cards>
                <Card
                    id="FrameAgreementContentList_Disciplines"
                    to={`${basePath}/disciplines`}
                    title={t('disciplines')}
                    ctas={[{
                        title: t('disciplines')
                    }]}
                >
                    {
                        frameAgreement.disciplines && frameAgreement.disciplines.map((discipline) =>
                            <div key={'discipline_' + discipline.disciplineCategory}
                                 className="agreement-content-list-row">
                                <span>
                                    <i className="fa fa-check card-body-icon-green"/>
                                    {t(discipline.disciplineCategory)}
                                </span>
                            </div>
                        )
                    }
                    {
                        (!frameAgreement.disciplines || frameAgreement.disciplines.length === 0) && renderIsSet({list: frameAgreement.disciplines, label: t('disciplines')})
                    }
                </Card>
                <Card
                    id="FrameAgreementContentList_Prices"
                    to={`${basePath}/prices`}
                    title={t('Other prices')}
                    ctas={[{
                        title: t('Review prices')
                    }]}
                >
                    {
                        listOptions.map((option) => renderIsSet({
                            list: frameAgreement[option.key],
                            label: t(option.label)
                        }))
                    }
                    {
                        renderIsSet({
                            list: frameAgreement.serviceCar,
                            field: frameAgreement.serviceCarType,
                            label: t('service_car')
                        })
                    }
                </Card>
                <Card
                    id="FrameAgreementContentList_SLA"
                    to={`${basePath}/sla`}
                    title={t('SLA')}
                    ctas={[{
                        title: t('Set SLA')
                    }]}
                >
                    {renderIsSet({list: frameAgreement.responseTime, label: t('response_time')})}
                    {renderIsSet({list: frameAgreement.supportTime247, label: t('support_time')})}
                </Card>

                <Card
                    id="FrameAgreementContentList_Comments"
                    to={`${basePath}/comments`}
                    title={t('comments')}
                    ctas={[{
                        title: t('Add comments')
                    }]}
                >
                    <div className="agreement-content-list-row">{t('Add comments to the agreement printout')}</div>
                </Card>
                <Card
                    id="FrameAgreementContentList_Contents"
                    to={`${basePath}/contents`}
                    title={t('Contents')}
                    ctas={[{
                        title: t('Set contents')
                    }]}
                >
                    {
                        (frameAgreement.contents && frameAgreement.contents.length > 0) ? <p>{t('Contents set')}</p> : <p>{t('Defaults')}</p>
                    }
                </Card>
                <Card
                    id="FrameAgreementContentList_SmartView"
                    to={`${basePath}/smartview`}
                    title={t('SmartView')}
                    ctas={[{
                        title: t('Add SmartView')
                    }]}
                >
                    {
                        frameAgreement.clientNet ? <p>{t('SmartView added')}</p> : <p>{t('No SmartView')}</p>
                    }
                </Card>
            </Cards>
        )
    }
    return null;
}

export default translations(FrameAgreementContentList);