import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {SFInputField, Button} from 'components';
import {commonStyles, measures, layout} from 'styles/design.js';
import _ from 'lodash';

class DisciplineTitle extends Component {


    componentWillMount() {
        if (!_.isEmpty(this.props.discipline)) {
            this.init(this.props.discipline);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.selectedLevel && !_.isEmpty(nextProps.discipline)) {
            this.init(nextProps.discipline);
        }
    }

    init(discipline) {
        const form = {
            disciplineName: {
                value: discipline.disciplineName
            }
        };
        Object.keys(form).map((key) => {
            form[key].onChange = (event) => this.changeValue(key, event.target.value);
        });
        this.setState({form});
    }

    changeValue = (key, value) => {
        if (this.state.editing) {
            const form = Object.assign({}, this.state.form);
            form[key].value = value;
            this.setState({form});
        }
    };


    startEdit = () => {
        this.setState({editing: true});
    };

    save = () => {
        const discipline = this.props.discipline;
        discipline.disciplineName = this.state.form.disciplineName.value;
        this.props.handleSave(discipline)
            .then(() => {
                this.setState({editing: false});
            });
    };

    cancel = () => {
        this.setState({editing: false});
        this.props.handleCancel();
    };

    render() {
        const {submitting, discipline, t} = this.props;
        const isCustomDiscipline = _.startsWith(discipline.disciplineType, 'custom_discipline');
        const canEdit = this.props.canEdit || this.state.editing;
        if (isCustomDiscipline) {
            return (
                <div style={{...commonStyles.bottomDivider, paddingBottom: measures.double}}>
                    <div>
                        <h2 style={{
                            marginBottom: measures.double,
                            marginTop: measures.standard,
                            ...commonStyles.pageSubTitle
                        }}>
                            {this.props.t('discipline_name')}
                        </h2>
                    </div>
                    <div>
                        {
                            canEdit && isCustomDiscipline && !this.state.editing &&
                            <div style={{float: 'right'}}>
                                <Button
                                    id="DisciplineTitle_edit"
                                    variant="secondary"
                                    onClick={this.startEdit}
                                >
                                    {t('edit')}
                                </Button>
                            </div>
                        }

                        {(() => {
                            if (this.state.editing) {
                                return (
                                    <div>
                                        <div style={{maxWidth: '30em', marginTop: '-2em'}}>
                                            <SFInputField
                                                id="DisciplineTitle_name"
                                                defaultInput={true}
                                                placeholder={this.props.t('discipline_name')}
                                                {...this.state.form.disciplineName}
                                            />
                                        </div>
                                        <div style={layout.floatRight}>
                                            <Button
                                                variant="link"
                                                id="DisciplineTitle_cancel"
                                                disabled={submitting}
                                                onClick={this.cancel}
                                            >
                                                {t('cancel')}
                                            </Button>
                                            <Button
                                                id="DisciplineTitle_save"
                                                onClick={this.save}
                                                disabled={submitting}
                                                loading={submitting}
                                            >
                                                {t('save')}
                                            </Button>
                                        </div>
                                    </div>
                                );
                            }
                            return (<div id="DisciplineTitle_currentName">{this.props.discipline.disciplineName}</div>);
                        })()}


                    </div>
                    <div style={{clear: 'both'}}/>
                </div>
            );
        }
        return (<div/>);
    }
}

export default translations(DisciplineTitle);
