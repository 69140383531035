import React from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';

import {
    Button,
    SFInputField
} from 'components';

import { loadKitpackGlobalPricing } from 'redux/modules/kitpack';

import './GlobalAdminView.css';

class GlobalAdminCollectorForm extends React.Component {
    state = {errors: {}};

    componentDidMount() {
        this.props.loadKitpackGlobalPricing(this.props.profile.accessToken);
    }

    addRow = () => {
        this.setState((state) => ({...state, showAddRow: true}));
    }

    setValue = (key, value) => {
        const newValues = {};
        newValues[key] = value;
        this.setState((state) => ({...state, ...newValues}))
    };
    addNewRow = () => {
        const values = {
            name: this.state.name,
            branchOfficeInstallationHours: this.state.branchOfficeInstallationHours,
            branchOfficeYearlyHours: this.state.branchOfficeYearlyHours,
            remoteCenterInstallationHours: this.state.remoteCenterInstallationHours,
            remoteCenterYearlyHours: this.state.remoteCenterYearlyHours,
            cost: this.state.cost
        };
        this.setState((state) => ({...state, showAddRow: false, rows: [values], name: null, branchOfficeInstallationHours: null, remoteCenterInstallationHours: null, cost: null}));
    };

    render() {
        const apiRows = _.get(this.props, 'kitpack.globalPricing', []);
        const rows = this.state.rows ? apiRows.concat(this.state.rows) : apiRows;
        if (this.state.showAddRow) {
            return (<div>
                <SFInputField
                    defaultInput={true}
                    showErrorBelow={true}
                    error={this.state.errors['name']}
                    fieldName={this.props.t('name')}
                    onChange={(event) => this.setValue('name', event.target.value)}
                    value={this.state.name}/>

                <SFInputField
                    defaultInput={true}
                    showErrorBelow={true}
                    error={this.state.errors['cost']}
                    fieldName={this.props.t('cost')}
                    onChange={(event) => this.setValue('cost', event.target.value)}
                    value={this.state.cost}/>

                <h3>Branch Office</h3>
                <SFInputField
                    defaultInput={true}
                    showErrorBelow={true}
                    error={this.state.errors['branchOfficeInstallationHours']}
                    fieldName={this.props.t('branchOfficeInstallationHours')}
                    onChange={(event) => this.setValue('branchOfficeInstallationHours', event.target.value)}
                    value={this.state.branchOfficeInstallationHours}/>
                <SFInputField
                    defaultInput={true}
                    showErrorBelow={true}
                    error={this.state.errors['branchOfficeYearlyHours']}
                    fieldName={this.props.t('branchOfficeYearlyHours')}
                    onChange={(event) => this.setValue('branchOfficeYearlyHours', event.target.value)}
                    value={this.state.branchOfficeYearlyHours}/>

                <h3>Remote center</h3>
                <SFInputField
                    defaultInput={true}
                    showErrorBelow={true}
                    error={this.state.errors['remoteCenterInstallationHours']}
                    fieldName={this.props.t('remoteCenterInstallationHours')}
                    onChange={(event) => this.setValue('remoteCenterInstallationHours', event.target.value)}
                    value={this.state.remoteCenterInstallationHours}
                />
                <SFInputField
                    defaultInput={true}
                    showErrorBelow={true}
                    error={this.state.errors['remoteCenterYearlyHours']}
                    fieldName={this.props.t('remoteCenterYearlyHours')}
                    onChange={(event) => this.setValue('remoteCenterYearlyHours', event.target.value)}
                    value={this.state.remoteCenterYearlyHours}
                />
                <div style={{margin: '2em', border: 'thin solid gray', padding: '1em'}}>
                   Which sensors included, should be added here.
                </div>
                <Button variant="link" onClick={() => this.setState((state) => ({...state, showAddRow: false}))}>
                    Cancel
                </Button>
                <Button onClick={this.addNewRow}>Add</Button>
            </div>);
        }
        return (
            <div>
                <div style={{marginTop: '2em', marginBottom: '2em', fontSize: '0.8em'}}>
                    <p>BO = Branch Office</p>
                    <p>RC = Remote Center</p>
                </div>
                <div className="collector-pricing-row" style={{borderTop: 'thin solid gray'}}>
                    <div className="collector-pricing-column">
                        Name
                    </div>
                    <div className="collector-pricing-column">
                        Includes
                    </div>
                    <div className="collector-pricing-column">
                        Cost
                    </div>
                    <div className="collector-pricing-column">
                        BO inst. hours
                    </div>
                    <div className="collector-pricing-column">
                        BO yearly hours
                    </div>
                    <div className="collector-pricing-column">
                        RC inst. hours
                    </div>
                    <div className="collector-pricing-column">
                        RC yearly hours
                    </div>
                    <div className="collector-pricing-column">
                        Battery amount
                    </div>
                </div>

                {
                    rows && rows.map((row) =>
                        <div className="collector-pricing-row">
                            <div className="collector-pricing-column">
                                {row.name}
                            </div>
                            <div className="collector-pricing-column">
                                {row.includes && row.includes.map((device) => <span><p>{device.type} - {device.name}</p></span>)}
                            </div>
                            <div className="collector-pricing-column">
                                {row.cost}
                            </div>
                            <div className="collector-pricing-column">
                                {row.branchOfficeInstallationHours}
                            </div>
                            <div className="collector-pricing-column">
                                {row.branchOfficeYearlyHours}
                            </div>
                            <div className="collector-pricing-column">
                                {row.remoteCenterInstallationHours}
                            </div>
                            <div className="collector-pricing-column">
                                {row.remoteCenterYearlyHours}
                            </div>
                            <div className="collector-pricing-column">
                                {row.batteryAmount}
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }

}

function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        kitpack: state.kitpack
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadKitpackGlobalPricing: (accessToken) => dispatch(loadKitpackGlobalPricing(accessToken))
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(GlobalAdminCollectorForm);