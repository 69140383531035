import React, {Component}           from 'react';
import _ from 'lodash';

import {
    colors
} from 'styles/design.js';

const styles = {
    step: {
        // backgroundColor: '#ccc'
    },
    inputRows: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between'
    },
    inputRow: {
        width: '49%',
        marginBottom: '1em',
        marginTop: '1em'
    },
    labelText: {
        display: 'block',
        marginBottom: '0.25em'
    },
    textInput: {
        display: 'block',
        width: '100%',
        padding: '0.75em 1em',
        border: '1px solid ' + colors.lightGray,
        ':focus': {
            border: '1px solid ' + colors.lightBlue,
            boxShadow: 'none',
            outline: 0
        }
    },
    textarea: {
        display: 'block',
        width: '100%',
        minHeight: '6em',
        padding: '0.75em 1em',
        border: '1px solid ' + colors.lightGray,
    },
    saveButton: {
        float: 'right',
        borderRadius: '3px',
        padding: '1em',
        border: '1px solid ' + colors.orange,
        backgroundColor: colors.white,
        color: colors.orange,
    },
    sendButton: {
        float: 'right',
        borderRadius: '3px',
        padding: '1em',
        border: 0,
        backgroundColor: colors.orange,
        color: colors.white,
    },
    confirmForm: {
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column'
    }
};
class Step extends Component {

    componentWillMount() {
        let form = {};
        if(this.props.values) {
            form = this.props.values;
        }
        this.setState({form});
    }
    componentWillReceiveProps(nextProps) {
        if(_.isEmpty(this.props.values)) {
            this.setState({form: nextProps.values});
        }
    }

    setData = (key, value) => {
        const form = this.state.form;
        form[key] = value;
        this.setState({form});
    };

    submitData = () => {
        this.props.submitData(this.state.form);
    };
    sendForward = () => {
        this.props.sendForward(this.state.comment);
    };

    resendStep = (stepId) => {
        this.setState({resendingStep: true});
        this.props.resendStep(stepId, this.state.comment).then(() => {
            this.setState({resendingStep: false});
        });
    };

    render() {
        const { editable, step } = this.props;
        return (
            <div style={{margin: '2em 0'}}>
                <h2 style={{ fontSize: '1.5em', fontFamily: 'Sanchez-Regular', fontWeight: 'normal', marginBottom: '0.66em' }}>
                    Stage {this.props.stepNumber}
                </h2>
                <div style={styles.step}>
                    <div style={styles.inputRows}>
                    {
                        (() => {
                            if(editable && step && step.fields) {
                                return step.fields.map((field, idx) => {
                                    const fieldOptions = {
                                        name: field.name,
                                        value: this.state.form[field.name],
                                        onChange: (event) => this.setData(field.name, event.target.value)
                                    };
                                    return (
                                        <div key={'field_key' + idx} style={ styles.inputRow }>
                                            <label style={ styles.label }>
                                                <div style={ styles.labelText }>{field.name}</div>
                                                {
                                                    (() => {
                                                        if(field.type && field.type === 'textarea') {
                                                            return (<textarea
                                                                style={ styles.textarea }
                                                                { ...fieldOptions }
                                                            />);
                                                        }
                                                        return (<input
                                                            type="text"
                                                            style={ styles.textInput }
                                                            { ...fieldOptions }
                                                        />);
                                                    })()
                                                }

                                            </label>
                                        </div>
                                    );
                                });
                            }
                            return (
                                <div>
                                    {JSON.stringify(this.props.values)}
                                    <div>
                                        <div>
                                            <textarea onChange={(event) => this.setState({comment: event.target.value})} value={this.state.comment} />
                                        </div>
                                        <input type="button" disabled={this.state.resendingStep} onClick={() => this.resendStep(this.props.stepNumber)} value="Resend" />
                                    </div>
                                </div>
                            );
                        })()
                    }
                    </div>
                    {
                        (() => {
                            if(editable) {
                                return (
                                    <div>
                                        <div style={{ overflow: 'hidden' }}>
                                            <input type="button" style={ styles.saveButton } onClick={this.submitData} value="Save draft" />
                                        </div>
                                        <div style={{ margin: '2em 0', overflow: 'hidden' }}>
                                            <h2>Send data</h2>
                                            <div style={ styles.confirmForm }>
                                                <div style={ styles.inputRow }>
                                                    <label style={{ paddingLeft: '1em' }}>
                                                        <input style={{ float: 'left' }} type="checkbox" onClick={() => this.setState({confirmSend: !this.state.confirmSend})} />
                                                        I understand that data will be sent
                                                    </label>
                                                </div>
                                                <div style={ styles.inputRow }>
                                                    <p style={ styles.labelText }>Notes</p>
                                                    <textarea style={ styles.textarea } onChange={(event) => this.setState({comment: event.target.value})} value={this.state.comment} />
                                                </div>
                                                <div style={ styles.inputRow }>
                                                    <input style={ styles.sendButton } type="button" disabled={!this.state.confirmSend} onClick={this.sendForward} value="Confirm and send" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })()
                    }

                </div>
            </div>
        );
    }
}

export default Step;
