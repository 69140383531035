import React from 'react';

import {
    Button
} from 'components';

import {
    colors,
    measures
} from 'styles/design';

const AgreementDetails = ({agreement, toggleEdit, t, isEditable}) => {
    return (
        <div>
            <div>
                <h3 style={{display: 'inline-block'}}>{t('details')}</h3>
                {
                    isEditable && <div style={{marginLeft: measures.standard, display: 'inline'}}>
                        <Button
                            id="AgreementDetails_edit"
                            onClick={toggleEdit}>
                            <i className="fa fa-pencil"/>
                        </Button>
                    </div>
                }
            </div>
            <div
                style={{
                    border: '1px dashed ' + colors.lightGray,
                    padding: '0.75em 0.5em',
                    marginTop: '0.5em',
                    letterSpacing: 'normal'
                }}
            >
                <div className="agreement-detail-block">
                    <div>{t('name')}:</div>
                    <div id="AgreementDetails_name">{agreement.name}</div>
                </div>
                <div className="agreement-detail-block">
                    <div>{t('Start index number')}:</div>
                    <div id="AgreementDetails_customIndex">{agreement.customIndex}</div>
                </div>
                <div className="agreement-detail-block">
                    <div>{t('When do the agreement start')}:</div>
                    <div id="AgreementDetails_startingDate">{agreement.startingDate}</div>
                </div>
                <div className="agreement-detail-block">
                    <div>{t('Language')}:</div>
                    <div id="AgreementDetails_language">{ t(agreement.language || 'Not defined') }</div>
                </div>
            </div>
        </div>
    );
};

export default AgreementDetails;