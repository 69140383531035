import React, {Component }                  from 'react';
import translations from 'decorators/translations.js';
import { connect }                          from 'react-redux';
import { loadTypes as loadBuildingTypes }   from 'redux/modules/building';
import { save as saveBuilding }             from 'redux/modules/building.js';
import { loadContractInfo } from 'redux/modules/contractInfo.js';

import {
    StandardPage,
    BuildingForm,
    BreadcrumbBar,
    LoadingIndicator
} from 'components';

import {
    commonStyles
} from 'styles/design.js';
import { loadClient, isClientLoaded }   from 'redux/modules/client.js';

import { loadCountries }                from 'redux/modules/country.js';
import {
    loadBuilding,
    deleteBuilding
}                                       from 'redux/modules/building.js';

import { loadBillingIndexes }           from 'redux/modules/billingIndex';
import {
    loadAgreements
}                               from 'redux/modules/agreement.js';

import {withRouter} from 'react-router-dom';

class BuildingDetailsEditView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount() {
        const {
            contractInfo
        } = this.props;
        const params = this.props.match.params;
        const promises = [];
        if(!isClientLoaded({client: this.props.clientState}, params.clientId)) {
            promises.push(this.props.dispatch(loadClient(params.clientId, this.props.profile.accessToken)));
        }
        if (contractInfo.id !== params.contractInfoId) {
            promises.push(this.props.dispatch(loadContractInfo(params.contractInfoId, this.props.profile.accessToken)));
        }
        if(!this.props.agreement.loaded || this.props.agreement.agreements.length === 0) {
            promises.push(this.props.dispatch(loadAgreements(params.clientId, this.props.profile.accessToken)));
        }
        if(this.props.building.types.length === 0) {
            promises.push(this.props.dispatch(loadBuildingTypes(this.props.profile.accessToken)));
        }
        if(this.props.countryState.countries.length === 0 || !this.props.countryState.loaded) {
            promises.push(this.props.dispatch(loadCountries(this.props.profile.accessToken)));
        }
        if(!this.props.billingIndex.loaded) {
            promises.push(this.props.dispatch(loadBillingIndexes(this.props.profile.accessToken)));
        }
        if(!this.props.selectedBuilding || parseInt(this.props.selectedBuilding.id, 10) !== parseInt(params.buildingId, 10)) {
            promises.push(this.props.dispatch(loadBuilding(params.buildingId, this.props.profile.accessToken)));
        }
        return Promise.all(promises).then(() => {
            this.setState({dataLoaded: true});
        })
    }

    componentWillReceiveProps(nextProps) {
        const promises = [];
        const params = this.props.match.params;
        if(!nextProps.building.loading && !nextProps.building.error && (!nextProps.selectedBuilding || parseInt(nextProps.selectedBuilding.id, 10) !== parseInt(params.buildingId, 10))) {
            promises.push(this.props.dispatch(loadBuilding(params.buildingId, this.props.profile.accessToken)));
        }
        return Promise.all(promises);
    }

    deleteBuilding = () => {
        const buildingId = this.props.match.params.buildingId;
        this.props.deleteBuilding(buildingId, this.props.profile.accessToken).then((res) => {
            if(res.result && !res.error) {
                this.props.history.push('/client/' + this.props.match.params.clientId);
            }
        });
    };

    handleSubmit = (data) => {
        const {
            match: {
                params: {
                    clientId,
                    buildingId,
                    contractInfoId
                }
            }
        } = this.props;
        data.id = buildingId;
        data.clientId = clientId;
        data.contractInfoId = contractInfoId;
        if(data.ownBillingDetails === '0') {
            //Reset all billing fields, just in case.
            const billingFields = ['billingCompany', 'billingAddress', 'billingIndexId', 'billingRef', 'businessID', 'electronicInvoiceNumber', 'vatNumber'];
            billingFields.map((field) => {
                data[field] = null;
            });
        }
        if (data.specificContactInformation === 'client') {
            data.contactName = null;
            data.contactPhone = null;
            data.contactEmail = null;
        }
        if(!data.specificLocationAddress || data.specificLocationAddress === 'client') {
            data.locationAddress = null;
        }
        const dispatchSave = this.props.dispatch(saveBuilding(data, this.props.profile.accessToken));
        dispatchSave.then((res) => {
            if(data.newAgreementId === 'new') {
                this.props.dispatch(loadAgreements(data.clientId, this.props.profile.accessToken));
            }
            if(res && res.result) {
                this.props.history.push('/client/' + clientId + '/contractInfo/' + contractInfoId + '/building/' + buildingId + '/details');
            }
        });
        return dispatchSave;
    };

    handleCancel = () => {
        const {
            match: {
                params: {
                    clientId,
                    buildingId,
                    contractInfoId
                }
            }
        } = this.props;
        this.props.history.push('/client/' + clientId + '/contractInfo/' + contractInfoId + '/building/' + buildingId + '/details/');
    };

    render() {
        const selectedBuilding = this.props.selectedBuilding;
        const client = this.props.clientState.client;
        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar building = { selectedBuilding }  />
                <header>
                    <h1 style = { commonStyles.pageTitle } >{ this.props.t('building_details') } </h1>
                </header>
                {
                    (() => {
                        if(!this.state.dataLoaded) {
                            return <LoadingIndicator visible={true} />
                        }
                        return (
                            <BuildingForm
                                cancelAction = { this.handleCancel }
                                building = { selectedBuilding }
                                onSubmit= { this.handleSubmit }
                                client={client}
                                user={this.props.profile.user}
                                deleteBuilding={this.deleteBuilding}
                                deletingBuilding={this.props.building.deleting}
                                contractInfo={ this.props.contractInfo }
                            />
                        );
                    })()
                }
            </StandardPage>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        deleteBuilding: (buildingId, accessToken) => dispatch(deleteBuilding(buildingId, accessToken))
    };
}

function mapStateToProps(state) {
    return {
        agreement: state.agreement,
        building: state.building,
        selectedBuilding: state.building.building,
        clientState : state.client,
        profile: state.userProfile,
        countryState: state.countries,
        billingIndex: state.billingIndex,
        contractInfo: state.contractInfo.contractInfo
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default withRouter(translations(connector(BuildingDetailsEditView)));