import React from 'react';
import translations from 'decorators/translations.js';

import { connect } from 'react-redux';
import {
    isEditable
}                                   from 'helpers/agreement.js';
import _ from 'lodash';
import {
    StandardPage,
    BreadcrumbBar,
    BackLink,
    Header
} from 'components';

import {
    upsertAgreementContents,
    loadAgreementContents,
    deleteAgreementContents
} from 'redux/modules/agreementContents';

import {
    loadWithIncludes as loadAgreement
} from 'redux/modules/agreement.js';

import {
    loadContractInfo,
    isContractInfoLoaded
} from 'redux/modules/contractInfo';


import {loadCountry} from 'redux/modules/country.js';

import { loadWithType as loadTranslations } from 'redux/modules/translations.js';

import AgreementContents from 'components/AgreementContent/AgreementContents';

class AgreementContentsView extends React.Component {
    state = {};

    componentDidMount() {
        const params = _.get(this.props, 'match.params', {}),
            agreementId = params.agreementId,
            contractInfoId = params.contractInfoId,
            accessToken = this.props.profile.accessToken;

        if(agreementId && this.props.agreement.id !== parseInt(agreementId, 10)) {
            this.props.loadAgreement(agreementId, accessToken, true);
        }
        new Promise((resolve, reject) => {
            if (!isContractInfoLoaded(this.props, params.contractInfoId)) {
                this.props.loadContractInfo(params.contractInfoId, accessToken)
                    .then( response => response.result ? resolve(response.result) : reject(response));
            } else {
                resolve(this.props.contractInfo.contractInfo);
            }
        }).then((contractInfo) => {
            return new Promise((resolve, reject) => {
                if (this.props.selectedCountry && this.props.selectedCountry.id === contractInfo.salesArea) {
                    resolve(this.props.selectedCountry);
                } else {
                    this.props.loadCountry(contractInfo.salesArea, accessToken)
                    .then((result) => {
                        if (result.result) {
                            resolve(result.result);
                        } else {
                            reject(result.error);
                        }
                    });
                }
            });
        }).then((country) => {
            const languages = ['en'];
            const lang = country.defaultLanguage || 'en';
            if (lang && lang !== 'en') {
                languages.push(lang);
            }
            this.props.loadTranslations(2, languages.join(','), country.id, accessToken);
            this.setState({
                language: lang
            });
        }).catch(() => {
            this.setState({errorState: true});
        });

        if(agreementId) {
            this.props.loadAgreementContents({agreementId}, this.props.profile.accessToken);
        }
    }
    saveAgreementContents = (text, section, language, id) => {
        const agreementId = _.get(this.props, 'match.params.agreementId');
        const content = text ? text : '';
        const data = {
            id,
            agreementId,
            section,
            content,
            language,
        };
        return this.props.upsertAgreementContents(data, this.props.profile.accessToken)
            .then( res => this.props.loadAgreementContents({agreementId}, this.props.profile.accessToken))
            .catch( error => console.error('error', error));
    }
    deleteAgreementContents = (id) => {
        const agreementId = _.get(this.props, 'match.params.agreementId');
        if (!id) {
            return null;
        }
        return this.props.deleteAgreementContents(id, this.props.profile.accessToken)
            .then( res => this.props.loadAgreementContents({agreementId}, this.props.profile.accessToken))
            .catch( error => console.error('error', error));
    }
    render() {
        const languages = ['en'].concat(this.props.countries.selectedCountry ? [this.props.countries.selectedCountry.defaultLanguage] : []);
        const t = this.props.t,
            agreement = this.props.agreement.agreement || {},
            params = this.props.match.params;
        const { clientId } = params;
        const basePath = `/client/${params.clientId}/contractInfo/${params.contractInfoId}/agreement/${agreement.id}`;

        return (
            <StandardPage
                withBreadcrumb
                loaderVisible={this.props.agreement.loading}>
                <BreadcrumbBar
                    page="agreement"
                    contractInfo={this.props.contractInfo && this.props.contractInfo.contractInfo}
                    agreement={agreement}
                    actionUrl = { `/client/${clientId}/details` }
                    linkName = { t('show_client_details') }
                    lockedText = { !isEditable(agreement) ? t('locked') : null}
                />
                {
                    agreement && agreement.id &&
                    <BackLink
                        title={t('Back to agreement')}
                        to={`${basePath}/summary`}
                        id="SLAView_Back"
                    />
                }
                    <Header title={ t('Override agreement contents')}/>
                {

                    !this.props.translations.loading && this.props.translations.translatables && this.state.language &&
                    <AgreementContents
                        languages={languages}
                        translatables={this.props.translations.translatables}
                        language={this.state.language || 'en'}
                        overridenContents={this.props.agreementContents.list}
                        saveAgreementContents={ this.saveAgreementContents }
                        deleteAgreementContents={ this.deleteAgreementContents }
                        t={t}
                        type={agreement.agreementType === 'addon' ? 'addonagreement' : 'mainagreement'}
                        agreement={agreement}
                    />
                }
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        clientState: state.client,
        agreement: state.agreement,
        profile: state.userProfile,
        agreementContents: state.agreementContents,
        translations: state.translations,
        countries: state.countries,
        contractInfo: state.contractInfo
    }
}

function mapDispatchToProps(dispatch) {
    return {
        loadContractInfo: (id, token) => dispatch(loadContractInfo(id, token)),
        loadTranslations: (type, languages, countryId, accessToken) => dispatch(loadTranslations(type, languages, countryId, accessToken)),
        loadCountry: (countryId, token) => dispatch(loadCountry(countryId, token)),
        loadAgreement: (agreementId, contractInfoId, token) => dispatch(loadAgreement(agreementId, contractInfoId, token)),
        loadAgreementContents: (params, token) => dispatch(loadAgreementContents(params, token)),
        upsertAgreementContents: (data, token) => dispatch(upsertAgreementContents(data, token)),
        deleteAgreementContents: (contentId, token) => dispatch(deleteAgreementContents(contentId, token))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(translations(AgreementContentsView));