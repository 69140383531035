import React, {Component}       from 'react';
import translations from 'decorators/translations.js';
import { connect }              from 'react-redux';
import configuredRadium         from 'configuredRadium.js';

import _ from 'lodash';

import {
    StandardPage,
    BreadcrumbBar,
    AdminSubNavigation,
    DepartmentConfigInformation,
    DepartmentConfigForm,
    LoadingIndicator
} from 'components';

import {
    commonStyles
} from 'styles/design.js';

import { loadCountry } from 'redux/modules/country.js';
import { loadCountryDepartments, saveDepartment} from 'redux/modules/department.js';

class DepartmentConfigurationView extends Component {

    componentWillMount() {
        const { dispatch, match: {params}} = this.props;
        const promises = [];
        if(!this.props.countries.loaded || this.props.countries.selectedCountry.id !== params.countryId) {
            promises.push(dispatch(loadCountry(params.countryId, this.props.profile.accessToken)));
        }
        return Promise.all(promises).then(() => {
            if(!this.props.department || !this.props.department.loaded || this.props.department.countryDepartments.length === 0 || _.first(this.props.department.countryDepartments).countryConfigId !== params.countryId) {
                //Departments not available if country configurations are missing. Check that created field is there.
                if(!this.props.selectedCountry || this.props.selectedCountry.created) {
                    promises.push(dispatch(loadCountryDepartments(this.props.match.params.countryId, this.props.profile.accessToken)));
                }
            }
        });
    }
    componentWillReceiveProps(newprops) {
        if(this.props.department.saving && newprops.department.reload) {
            this.setState({savingDepartment: false, loadingDepartment: true, editingDepartment: false});
            this.props.dispatch(loadCountryDepartments(this.props.selectedCountry.id, this.props.profile.accessToken));
        }
        if (this.department && this.department.loading && newprops.department && newprops.department.loaded) {
            this.setState({savingDepartment: false, editingDepartment: false});
        }
    }
    getDepartment(id) {
        let returnable = null;
        this.props.department.countryDepartments.map((dep) => {
            if(dep.id === parseInt(id, 10)) {
                returnable = dep;
            }
        });
        return returnable;
    }
    toggleEditDepartment = (value) => {
        this.setState({editDepartment: value});
    };

    editDepartmentClick = () => {
        this.setState({editingDepartment: true});
    };
    selectDepartment = (event, id) => {
        this.setState({departmentId: id, editingDepartment: true});
        event.preventDefault();
    };
    cancelSelectDepartment = () => {
        this.setState({editingDepartment: false});
    };

    handleDepartmentSubmit = (conf) => {
        this.setState({savingDepartment: true});
        return this.props.dispatch(saveDepartment(conf, this.props.profile.accessToken));
    };
    loadDepartments = () => {
        return this.props.dispatch(loadCountryDepartments(this.props.selectedCountry.id, this.props.profile.accessToken));
    };

    render() {
        const { t, selectedCountry } = this.props;
        return (
            <StandardPage withBreadcrumb withSideNavigation>
                <BreadcrumbBar
                    page="admin"
                    path={this.props.location.pathname}
                />
                <AdminSubNavigation params={this.props.match.params} activeNavi="departmentConfig" />
                <header>
                    <h1 style = { commonStyles.pageTitle } >{t('departments')} - { this.props.t(this.props.match.params.countryId) }</h1>
                </header>
                {
                    (() => {
                        if(this.props.countries.loading || _.isEmpty(selectedCountry)) {
                            return <LoadingIndicator visible={true}/>
                        } else if(!this.props.selectedCountry || !this.props.selectedCountry.created) {
                            return (<div>{t('department_available_after_country_configurations')}</div>);
                        } else if(this.state.editingDepartment && this.state.departmentId) {
                            return (<DepartmentConfigForm
                                t={this.props.t}
                                cancelEdit = {this.cancelSelectDepartment}
                                onSubmit = {this.handleDepartmentSubmit}
                                department = {this.getDepartment(this.state.departmentId)}
                                countryConfig={ selectedCountry }
                                disciplines = {selectedCountry.frameAgreementConfig.disciplines}
                            />);
                        }
                        return (<DepartmentConfigInformation
                            t={this.props.t}
                            departments = { this.props.department.countryDepartments }
                            disciplines={selectedCountry.frameAgreementConfig ? selectedCountry.frameAgreementConfig.disciplines : []}
                            countryConfig={ selectedCountry }
                            editClicked = {this.editDepartmentClick}
                            selectDepartment = {this.selectDepartment}
                            cancelEdit = {this.toggleEditDepartment}
                            loadDepartments = {this.loadDepartments}
                        />);
                    })()
                }
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        countries: state.countries,
        selectedCountry: state.countries.selectedCountry,
        languages: state.translations.languages,
        profile: state.userProfile,
        discipline: state.discipline,
        department: state.department,
        translations: state.translations
    };
}

function mapDispatchToProps() {
    return {};
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(configuredRadium(DepartmentConfigurationView)));
