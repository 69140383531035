import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

/**
 * Action card UI component
 */
const ActionCard = ({children, to, id, onClick}) => {
    // Render card
    if (to) {
        return (
            <Link
                id={id}
                to={to}
                className="action-card"
            >
                <span>
                    <i className="fa fa-plus fa-3x"/><br /> 
                    {children}
                </span>
            </Link>
        )
    }
    return (
        <a
            href=""
            onClick={onClick}
            id={id} 
            className="action-card"
        >
            <span>
                <i className="fa fa-plus fa-3x"/><br /> 
                {children}
            </span>
        </a>
    );
};

export default ActionCard;