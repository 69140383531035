import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import {
    Dialog,
    LoadingIndicator,
    Button
} from 'components';

class ConfirmableButton extends Component {
    state = {};

    static propTypes = {
        buttonVariant: PropTypes.string,
        action: PropTypes.func.isRequired
    };

    doAction = () => {
        this.props.action();
        this.reset();
    };

    firstClick = () => {
        if (!this.props.deleting) {
            this.setState({clickedOnce: true});
        }
    };

    reset = () => {
        this.setState({clickedOnce: false});
    };

    renderConfirm = () => {
        if (this.state.clickedOnce) {
            return (
                <Dialog
                    actions={[
                        {
                            id: 'ConfirmableButton_cancel',
                            onClick: this.reset,
                            title: this.props.t('cancel'),
                            variant: 'cancel'
                        },
                        {
                            id: 'ConfirmableButton_confirm',
                            onClick: this.doAction,
                            title: this.props.confirmButtonText || this.props.t('yes'),
                            variant: 'primary'
                        }
                    ]}
                >
                    {this.props.confirmText || this.props.t('delete_system') + '?'}
                </Dialog>
            );
        }
    }

    render() {
        const propStyles = this.props.style || {};
        const deleteButtonStyle = {
            ...propStyles
        };

        return (
            <div>
                <div style={{visibility: this.state.clickedOnce ? 'hidden' : 'visible'}}>
                    {this.props.children}
                </div>
                <div>
                    { this.renderConfirm(deleteButtonStyle) }
                    <Button
                        variant={ this.props.variant }
                        size={ this.props.size }
                        className={ this.props.buttonClasses && this.props.buttonClasses.join(' ') }
                        style={{ ...deleteButtonStyle }}
                        id={this.props.id}
                        onClick={this.firstClick}
                    >
                        {this.props.buttonIcon && <i className={'fa ' + this.props.buttonIcon}/>}
                        <span> {this.props.buttonText} </span>
                        <LoadingIndicator visible={this.props.showLoading}/>
                    </Button>
                </div>
            </div>
        );
    }
}

export default translations(ConfirmableButton);
