export default function clientMiddleware(client) {
    return ({ dispatch, getState }) => {
        return next => action => {
            if (typeof action === 'function') {
                return action(dispatch, getState);
            }

            const { promise, types, precondition, ...rest } = action; // eslint-disable-line no-redeclare

            if (!promise) {
                return next(action);
            }

            const [request, success, failure] = types;

            if (precondition) {
                const state = getState();

                if (!precondition(state, action)) {
                    console.log(`Precondition does not hold for ${request}`);

                    return new Promise(function(resolve) {
                        resolve({
                            ...action,
                            result: action.defaultResult && action.defaultResult(state, action)
                        });
                    });
                }
            }

            next({ ...rest, type: request });
            return promise(client).then(
                result => next({ ...rest, result, type: success }),
                error => next({ ...rest, error, type: failure })
            ).catch((error) => {
                console.log(error.stack);
                console.error('MIDDLEWARE ERROR:', error);
                next({ ...rest, error, type: failure });
            });
        };
    };
}
