import _ from 'lodash';

const LOAD = 'ServiFlex/Agreement/LOAD',
    LOAD_SUCCESS = 'ServiFlex/Agreement/LOAD_SUCCESS',
    LOAD_FAIL = 'ServiFlex/Agreement/LOAD_FAIL',
    SAVE = 'ServiFlex/Agreement/SAVE',
    SAVE_SUCCESS = 'ServiFlex/Agreement/SAVE_SUCCESS',
    SAVE_FAIL = 'ServiFlex/Agreement/SAVE_FAIL',
    SAVE_KITPACK = 'ServiFlex/Agreement/SAVE_KITPACK',
    SAVE_KITPACK_SUCCESS = 'ServiFlex/Agreement/SAVE_KITPACK_SUCCESS',
    SAVE_KITPACK_FAIL = 'ServiFlex/Agreement/SAVE_KITPACK_FAIL',
    DELETE_KITPACK = 'ServiFlex/Agreement/DELETE_KITPACK',
    DELETE_KITPACK_SUCCESS = 'ServiFlex/Agreement/DELETE_KITPACK_SUCCESS',
    DELETE_KITPACK_FAIL = 'ServiFlex/Agreement/DELETE_KITPACK_FAIL',
    LOAD_AGREEMENTS = 'ServiFlex/Agreement/LOAD_AGREEMENTS',
    LOAD_AGREEMENTS_SUCCESS = 'ServiFlex/Agreement/LOAD_AGREEMENTS_SUCCESS',
    LOAD_AGREEMENTS_FAIL = 'ServiFlex/Agreement/LOAD_AGREEMENTS_FAIL',
    LOAD_USER_AGREEMENTS = 'ServiFlex/Agreement/LOAD_USER_AGREEMENTS',
    LOAD_USER_AGREEMENTS_SUCCESS = 'ServiFlex/Agreement/LOAD_USER_AGREEMENTS_SUCCESS',
    LOAD_USER_AGREEMENTS_FAIL = 'ServiFlex/Agreement/LOAD_USER_AGREEMENTS_FAIL',
    LOAD_MY_AGREEMENTS = 'ServiFlex/Agreement/LOAD_MY_AGREEMENTS',
    LOAD_MY_AGREEMENTS_SUCCESS = 'ServiFlex/Agreement/LOAD_MY_AGREEMENTS_SUCCESS',
    LOAD_MY_AGREEMENTS_FAIL = 'ServiFlex/Agreement/LOAD_MY_AGREEMENTS_FAIL',
    LOAD_APPROVABLE_AGREEMENTS = 'ServiFlex/Agreement/LOAD_APPROVABLE_AGREEMENTS',
    LOAD_APPROVABLE_AGREEMENTS_SUCCESS = 'ServiFlex/Agreement/LOAD_APPROVABLE_AGREEMENTS_SUCCESS',
    LOAD_APPROVABLE_AGREEMENTS_FAIL = 'ServiFlex/Agreement/LOAD_APPROVABLE_AGREEMENTS_FAIL',
    LOAD_KITPACKS = 'ServiFlex/Agreement/LOAD_KITPACKS',
    LOAD_KITPACKS_SUCCESS = 'ServiFlex/Agreement/LOAD_KITPACKS_SUCCESS',
    LOAD_KITPACKS_FAIL = 'ServiFlex/Agreement/LOAD_KITPACKS_FAIL',
    LOAD_KITPACK_SYSTEMS = 'ServiFlex/Agreement/LOAD_KITPACK_SYSTEMS',
    LOAD_KITPACK_SYSTEMS_SUCCESS = 'ServiFlex/Agreement/LOAD_KITPACK_SYSTEMS_SUCCESS',
    LOAD_KITPACK_SYSTEMS_FAIL = 'ServiFlex/Agreement/LOAD_KITPACK_SYSTEMS_FAIL',
    SEND_FOR_APPROVAL = 'ServiFlex/Agreement/SEND_FOR_APPROVAL',
    SEND_FOR_APPROVAL_SUCCESS = 'ServiFlex/Agreement/SEND_FOR_APPROVAL_SUCCESS',
    SEND_FOR_APPROVAL_FAIL = 'ServiFlex/Agreement/SEND_FOR_APPROVAL_FAIL',
    APPROVE = 'ServiFlex/Agreement/APPROVE',
    APPROVE_SUCCESS = 'ServiFlex/Agreement/APPROVE_SUCCESS',
    APPROVE_FAIL = 'ServiFlex/Agreement/APPROVE_FAIL',
    REJECT = 'ServiFlex/Agreement/REJECT',
    REJECT_SUCCESS = 'ServiFlex/Agreement/REJECT_SUCCESS',
    REJECT_FAIL = 'ServiFlex/Agreement/REJECT_FAIL',
    REVOKE = 'ServiFlex/Agreement/REVOKE',
    REVOKE_SUCCESS = 'ServiFlex/Agreement/REVOKE_SUCCESS',
    REVOKE_FAIL = 'ServiFlex/Agreement/REVOKE_FAIL',
    CONVERT = 'ServiFlex/Agreement/CONVERT',
    CONVERT_SUCCESS = 'ServiFlex/Agreement/CONVERT_SUCCESS',
    CONVERT_FAIL = 'ServiFlex/Agreement/CONVERT_FAIL',
    CONNECT_EXT = 'ServiFlex/Agreement/CONNECT_EXT',
    CONNECT_EXT_SUCCESS = 'ServiFlex/Agreement/CONNECT_EXT_SUCCESS',
    CONNECT_EXT_FAIL = 'ServiFlex/Agreement/CONNECT_EXT_FAIL',
    CONNECT_MAIN = 'ServiFlex/Agreement/CONNECT_MAIN',
    CONNECT_MAIN_SUCCESS = 'ServiFlex/Agreement/CONNECT_MAIN_SUCCESS',
    CONNECT_MAIN_FAIL = 'ServiFlex/Agreement/CONNECT_MAIN_FAIL',
    SIGN_AGREEMENT = 'ServiFlex/Agreement/SIGN_AGREEMENT',
    SIGN_AGREEMENT_SUCCESS = 'ServiFlex/Agreement/SIGN_AGREEMENT_SUCCESS',
    SIGN_AGREEMENT_FAIL = 'ServiFlex/Agreement/SIGN_AGREEMENT_FAIL',
    DIFF_AGREEMENT = 'ServiFlex/Agreement/DIFF_AGREEMENT',
    DIFF_AGREEMENT_SUCCESS = 'ServiFlex/Agreement/DIFF_AGREEMENT_SUCCESS',
    DIFF_AGREEMENT_FAIL = 'ServiFlex/Agreement/DIFF_AGREEMENT_FAIL',
    LOAD_AHU_TYPES = 'ServiFlex/Agreement/LOAD_AHU_TYPES',
    LOAD_AHU_TYPES_FAIL = 'ServiFlex/Agreement/LOAD_AHU_TYPES_FAIL',
    LOAD_AHU_TYPES_SUCCESS = 'ServiFlex/Agreement/LOAD_AHU_TYPES_SUCCESS',
    SAVE_PRICE_DETAIL = 'ServiFlex/Agreement/SAVE_PRICE_DETAIL',
    SAVE_PRICE_DETAIL_SUCCESS = 'ServiFlex/Agreement/SAVE_PRICE_DETAIL_SUCCESS',
    SAVE_PRICE_DETAIL_FAIL = 'ServiFlex/Agreement/SAVE_PRICE_DETAIL_FAIL',
    NEW_PROCESS = 'ServiFlex/Agreement/NEW_PROCESS',
    NEW_PROCESS_SUCCESS = 'ServiFlex/Agreement/NEW_PROCESS_SUCCESS',
    NEW_PROCESS_FAIL = 'ServiFlex/Agreement/NEW_PROCESS_FAIL',
    RESEND_STEP = 'ServiFlex/Agreement/RESEND_STEP',
    RESEND_STEP_SUCCESS = 'ServiFlex/Agreement/RESEND_STEP_SUCCESS',
    RESEND_STEP_FAIL = 'ServiFlex/Agreement/RESEND_STEP_FAIL',
    UNAPPROVE = 'ServiFlex/Agreement/UNAPPROVE',
    UNAPPROVE_SUCCESS = 'ServiFlex/Agreement/UNAPPROVE_SUCCESS',
    UNAPPROVE_FAIL = 'ServiFlex/Agreement/UNAPPROVE_FAIL',
    UPDATE_LOST = 'ServiFlex/Agreement/UPDATE_LOST',
    UPDATE_LOST_SUCCESS = 'ServiFlex/Agreement/UPDATE_LOST_SUCCESS',
    UPDATE_LOST_FAIL = 'ServiFlex/Agreement/UPDATE_LOST_FAIL',
    DELETE_AGREEMENT = 'ServiFlex/Agreement/DELETE_AGREEMENT',
    DELETE_AGREEMENT_SUCCESS = 'ServiFlex/Agreement/DELETE_AGREEMENT_SUCCESS',
    DELETE_AGREEMENT_FAIL = 'ServiFlex/Agreement/DELETE_AGREEMENT_FAIL';

const responseTimes = ['2h', '4h', 'nextWorkingDay', 'custom_response', 'response_none'],
    supportTimes = ['24/7', 'custom_support', 'support_none'];

const initialState = {
    loading: false,
    agreement: null,
    responseTimes,
    supportTimes,
    agreements: [],
    userAgreements: [],
    myAgreements: [],
    approvableAgreements: [],
    kitpacks: [],
    kitPackSystems: [],
    diff: {},
    loaded: false,
    reload: false,
    saving: false,
    ahuTypes: [],
    deletingKitpack: false,
    deletedKitpack: false,
    kitpackError: false,
    updating: false,
    updated: false,
    deletingAgreement: false,
    deletedAgreement: false,
};

export default function reducer(state = initialState, action = {}) {

    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reloadSingle: false,
                agreement: action.result
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SEND_FOR_APPROVAL_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: true,
            };
        case SEND_FOR_APPROVAL_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: true
            };
        case LOAD_AGREEMENTS:
            return {
                ...state,
                loading: true
            };
        case LOAD_AGREEMENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                byContractInfo: action.result
            };
        case LOAD_AGREEMENTS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_USER_AGREEMENTS:
            return {
                ...state,
                loadingUserAgreements: true,
                loading: true
            };
        case LOAD_USER_AGREEMENTS_SUCCESS:
            return {
                ...state,
                loadingUserAgreements: false,
                loadedUserAgreements: true,
                reloadUserAgreements: false,
                loading: false,
                loaded: true,
                userAgreements: action.result
            };
        case LOAD_USER_AGREEMENTS_FAIL:
            return {
                ...state,
                loadingUserAgreements: false,
                loadedUserAgreements: false,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_MY_AGREEMENTS:
            return {
                ...state,
                loadingMyAgreements: true,
                loading: true
            };
        case LOAD_MY_AGREEMENTS_SUCCESS:
            return {
                ...state,
                loadingMyAgreements: false,
                loadedMyAgreements: true,
                reloadMyAgreements: false,
                loading: false,
                loaded: true,
                myAgreements: action.result
            };
        case LOAD_MY_AGREEMENTS_FAIL:
            return {
                ...state,
                loadingMyAgreements: false,
                loadedMyAgreements: false,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_APPROVABLE_AGREEMENTS:
            return {
                ...state,
                loadingMyAgreements: true
            };
        case LOAD_APPROVABLE_AGREEMENTS_SUCCESS:
            return {
                ...state,
                loadingMyAgreements: false,
                loadedMyAgreements: true,
                reloadMyAgreements: false,
                approvableAgreements: action.result
            };
        case LOAD_APPROVABLE_AGREEMENTS_FAIL:
            return {
                ...state,
                loadingMyAgreements: false,
                loadedMyAgreements: false,
                error: action.error
            };
        case LOAD_KITPACKS:
            return {
                ...state,
                loading: true
            };
        case LOAD_KITPACKS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                kitpacks: action.result
            };
        case LOAD_KITPACKS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_KITPACK_SYSTEMS:
            return {
                ...state,
                loading: true
            };
        case LOAD_KITPACK_SYSTEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                kitPackSystems: action.result
            };
        case LOAD_KITPACK_SYSTEMS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SAVE:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                agreement: action.result,
                error: action.error
            };
        case SAVE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };
        case SAVE_KITPACK:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case SAVE_KITPACK_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                agreement: action.result,
                saving: false,
                error: action.error
            };
        case SAVE_KITPACK_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                kitpackError: action.error
            };

        case DELETE_KITPACK:
            return {
                ...state,
                deletingKitpack: true
            };
        case DELETE_KITPACK_SUCCESS:
            return {
                ...state,
                deletingKitpack: false,
                deletedKitpack: true
            };
        case DELETE_KITPACK_FAIL:
            return {
                ...state,
                deletingKitpack: false,
                deletedKitpack: false,
                error: action.error
            };
        case CONVERT_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                error: action.error
            };
        case CONVERT_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case CONVERT:
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        case CONNECT_EXT_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
            };
        case CONNECT_EXT_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case CONNECT_EXT:
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        case CONNECT_MAIN_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
            };
        case CONNECT_MAIN_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case CONNECT_MAIN:
            return {
                ...state,
                loading: false,
                loaded: false,
            };
        case SIGN_AGREEMENT:
            return {
                ...state,
                loading: true,
                loaded: false,
                signing: true,
            };
        case SIGN_AGREEMENT_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                error: action.error,
                signing: false,
                signed: false
            };
        case SIGN_AGREEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: true,
                signing: false,
                signed: true,
                signedAgreement: action.result
            };
        case DIFF_AGREEMENT:
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        case DIFF_AGREEMENT_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case DIFF_AGREEMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                diff: action.result
            };
        case LOAD_AHU_TYPES_SUCCESS:
            return {
                ...state,
                ahuTypes: action.result
            };
        case SAVE_PRICE_DETAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case SAVE_PRICE_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                error: action.error
            };
        case SAVE_PRICE_DETAIL_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };
        case NEW_PROCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case NEW_PROCESS_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                error: action.error
            };
        case NEW_PROCESS_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };
        case RESEND_STEP:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case RESEND_STEP_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                error: action.error
            };
        case RESEND_STEP_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };
        case UNAPPROVE:
            return {
                ...state,
                loading: true,
                loaded: false,
            };
        case UNAPPROVE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                error: action.error,
            };
        case UNAPPROVE_SUCCESS:
            const agreement = state.agreement;
            Object.keys(action.result).map((key) => {
                agreement[key] = action.result[key];
            });
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: true,
                agreement
            };
        case APPROVE_SUCCESS:
            return {
                ...state,
                reload: true
            };
        case REJECT_SUCCESS:
            return {
                ...state,
                reload: true
            };
        case UPDATE_LOST:
            return {
                ...state,
                updating: true,
                updated: false,
            };
        case UPDATE_LOST_FAIL:
            return {
                ...state,
                updating: false,
                updated: false,
                reload: true,
                error: action.error,
            };
        case UPDATE_LOST_SUCCESS:
            return {
                ...state,
                updating: false,
                updated: true,
                reload: true,
                agreement: updateAgreementInState(state.agreement, action.result)
            };
        case DELETE_AGREEMENT:
            return {
                ...state,
                deletedAgreement: false,
                deletingAgreement: true
            };
        case DELETE_AGREEMENT_SUCCESS:
            return {
                ...state,
                deletedAgreement: true,
                deletingAgreement: false,
            };
        case DELETE_AGREEMENT_FAIL:
            return {
                ...state,
                deletingAgreement: false,
                error: action.error
            };
        default:
            return state;
    }
}

function updateAgreementInState(stateAgreement, agreement) {

    Object.keys(agreement).map((key) => {
        agreement[key] = agreement[key];
    });
    return agreement;
}

export function loadAgreement(agreementId, contractInfoId, accessToken) {
    const includes = ['signatures', 'contractInfo', 'addons', 'attachments', 'buildings', { disciplines: 'disciplineSystems'}, 'parent'];
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client) => client.get(
            `/contractInfo/${contractInfoId}/agreementWithIncludes/${agreementId}?includes=${encodeURIComponent(JSON.stringify(includes))}`, {accessToken}
        )
    };
}

export function loadWithIncludes(agreementId, accessToken, includeTotals) {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client) => client.get(
            '/agreements/' + agreementId + '/withIncludes' + (includeTotals ? '?totals=true' : ''), {accessToken}
        )
    };
}

export function loadAgreements(clientId, accessToken) {
    return {
        types: [LOAD_AGREEMENTS, LOAD_AGREEMENTS_SUCCESS, LOAD_AGREEMENTS_FAIL],
        promise: (client) => client.get(
            '/clients/' + clientId + '/agreementsWithIncludes', {accessToken}
        )
    };
}

export function loadUserAgreements(accessToken) {
    return {
        types: [LOAD_USER_AGREEMENTS, LOAD_USER_AGREEMENTS_SUCCESS, LOAD_USER_AGREEMENTS_FAIL],
        promise: (client) => client.get(
            '/agreements/userAgreements', {accessToken}
        )
    };
}

export function loadMyAgreements(accessToken) {
    return {
        types: [LOAD_MY_AGREEMENTS, LOAD_MY_AGREEMENTS_SUCCESS, LOAD_MY_AGREEMENTS_FAIL],
        promise: (client) => client.get(
            '/agreements/myAgreements', {accessToken}
        )
    };
}

export function loadApprovableAgreements(accessToken) {
    return {
        types: [LOAD_APPROVABLE_AGREEMENTS, LOAD_APPROVABLE_AGREEMENTS_SUCCESS, LOAD_APPROVABLE_AGREEMENTS_FAIL],
        promise: (client) => client.get(
            '/agreements/approvableAgreements', {accessToken}
        )
    };
}

export function loadKitPacks(agreementId, accessToken) {
    return {
        types: [LOAD_KITPACKS, LOAD_KITPACKS_SUCCESS, LOAD_KITPACKS_FAIL],
        promise: (client) => client.get(
            '/agreements/' + agreementId + '/kitpacks', {accessToken}
        )
    };
}

export function updateKitPack(kitPack, accessToken) {
    return {
        types: [SAVE_KITPACK, SAVE_KITPACK_SUCCESS, SAVE_KITPACK_FAIL],
        promise: (client) => client.patch('/kitpacks/' + kitPack.id, {data: kitPack, accessToken}
        )
    };
}

export function saveKitPack(kitPack, accessToken) {
    if (kitPack.id) {
        return updateKitPack(kitPack, accessToken);
    }
    return {
        types: [SAVE_KITPACK, SAVE_KITPACK_SUCCESS, SAVE_KITPACK_FAIL],
        promise: (client) => client.post(
            '/kitpacks', {data: kitPack, accessToken}
        )
    };
}

export function update(agreementObject, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.patch('/agreements/' + agreementObject.id, {data: agreementObject, accessToken}
        )
    };
}

export function save(agreementObject, accessToken) {
    if (agreementObject.id) {
        return update(agreementObject, accessToken);
    }
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.post(
            '/agreements', {data: agreementObject, accessToken}
        )
    };
}

export function sendForApproval(agreementId, email, accessToken) {
    return {
        types: [SEND_FOR_APPROVAL, SEND_FOR_APPROVAL_SUCCESS, SEND_FOR_APPROVAL_FAIL],
        promise: (client) => client.post(
            '/agreements/' + agreementId + '/sendForApproval', {data: {approver: email}, accessToken}
        )
    };
}

export function approve(agreementId, comment, accessToken) {
    return {
        types: [APPROVE, APPROVE_SUCCESS, APPROVE_FAIL],
        promise: (client) => client.post(
            '/agreements/' + agreementId + '/approve', {data: {comment}, accessToken}
        )
    };
}

export function reject(agreementId, comment, accessToken) {
    return {
        types: [REJECT, REJECT_SUCCESS, REJECT_FAIL],
        promise: (client) => client.post(
            '/agreements/' + agreementId + '/reject', {data: {comment}, accessToken}
        )
    };
}

export function revokeApproval(agreementId, accessToken) {
    return {
        types: [REVOKE, REVOKE_SUCCESS, REVOKE_FAIL],
        promise: (client) => client.post(
            '/agreements/' + agreementId + '/revoke', {accessToken}
        )
    };
}

export function convertToMainAgreement(agreementId, accessToken) {
    return {
        types: [CONVERT, CONVERT_SUCCESS, CONVERT_FAIL],
        promise: (client) => client.put(
            '/agreements/' + agreementId + '/convertToMainAgreement', {accessToken}
        )
    };
}

export function connectToExternalMainAgreement(agreementId, externalRef, accessToken) {
    return {
        types: [CONNECT_EXT, CONNECT_EXT_SUCCESS, CONNECT_EXT_FAIL],
        promise: (client) => client.put(
            '/agreements/' + agreementId + '/connectToExternalMainAgreement', {data: {externalRef}, accessToken}
        )
    };
}

export function connectToMainAgreement(agreementId, mainAgreementId, accessToken) {
    return {
        types: [CONNECT_MAIN, CONNECT_MAIN_SUCCESS, CONNECT_MAIN_FAIL],
        promise: (client) => client.put(
            '/agreements/' + agreementId + '/connectToMainAgreement', {data: {mainAgreementId}, accessToken}
        )
    };
}

export function signAgreement(agreementId, accessToken) {
    return {
        types: [SIGN_AGREEMENT, SIGN_AGREEMENT_SUCCESS, SIGN_AGREEMENT_FAIL],
        promise: (client) => client.put(
            '/agreements/' + agreementId + '/sign', {data: {agreementId}, accessToken}
        )
    };
}

export function getDiff(agreementId, accessToken) {
    return {
        types: [DIFF_AGREEMENT, DIFF_AGREEMENT_SUCCESS, DIFF_AGREEMENT_FAIL],
        promise: (client) => client.get(
            '/agreements/' + agreementId + '/diff', {data: {agreementId}, accessToken}
        )
    };
}

export function loadAHUTypes(accessToken) {
    return {
        types: [LOAD_AHU_TYPES, LOAD_AHU_TYPES_SUCCESS, LOAD_AHU_TYPES_FAIL],
        promise: (client) => client.get(
            '/agreements/getAHUTypes', {accessToken}
        )
    };
}

export function savePriceDetailLevel(agreementId, config, accessToken) {
    return {
        types: [SAVE_PRICE_DETAIL, SAVE_PRICE_DETAIL_SUCCESS, SAVE_PRICE_DETAIL_FAIL],
        promise: (client) => client.post(
            '/agreements/' + agreementId + '/updatePriceDetailLevel', {data: config, accessToken}
        )
    };
}

export function saveShowMonthlyPrice(agreementId, config, accessToken) {
    return {
        types: [SAVE_PRICE_DETAIL, SAVE_PRICE_DETAIL_SUCCESS, SAVE_PRICE_DETAIL_FAIL],
        promise: (client) => client.post(
            '/agreements/' + agreementId + '/updateShowMonthlyPrice', {data: config, accessToken}
        )
    };
}

export function areAgreementsLoaded(state) {
    if (state.agreement && state.agreement.loaded) {
        return true;
    }
    return false;
}

export function deleteKitpack(id, accessToken) {
    return {
        types: [DELETE_KITPACK, DELETE_KITPACK_SUCCESS, DELETE_KITPACK_FAIL],
        promise: (client) => client.del(
            '/KitPacks/' + id, {accessToken}
        )
    };
}

export function initNewProcess(data, agreementId, accessToken) {
    return {
        types: [NEW_PROCESS, NEW_PROCESS_SUCCESS, NEW_PROCESS_FAIL],
        promise: (client) => client.post('/agreements/' + agreementId + '/initNewProcess', {data, accessToken})
    };
}


export function resendProcessStep(data, agreementId, step, accessToken) {
    return {
        types: [RESEND_STEP, RESEND_STEP_SUCCESS, RESEND_STEP_FAIL],
        promise: (client) => client.post('/agreements/' + agreementId + '/resendProcessStep/' + step, {
            data,
            accessToken
        })
    };
}

export function unApproveAgreement(agreementId, accessToken) {
    return {
        types: [UNAPPROVE, UNAPPROVE_SUCCESS, UNAPPROVE_FAIL],
        promise: (client) => client.post('/agreements/' + agreementId + '/revertToDraft', {accessToken})
    };
}

export function updateAgreementLost(agreementId, setLost, accessToken) {
    return {
        types: [UPDATE_LOST, UPDATE_LOST_SUCCESS, UPDATE_LOST_FAIL],
        promise: (client) => client.patch('/agreements/' + agreementId + '/' + (setLost ? 'setLost' : 'unsetLost'), {accessToken})
    };
}


export function deleteAgreement(id, accessToken) {
    return {
        types: [DELETE_AGREEMENT, DELETE_AGREEMENT_SUCCESS, DELETE_AGREEMENT_FAIL],
        promise: (client) => client.del(
            '/agreements/' + id + '/deleteAgreement/', {accessToken}
        )
    };
}

export function isAgreementLoaded(state, id) {
    if(state.loaded && state.agreement && state.agreement.id === id) {
        return true;
    }
    return false;
}
