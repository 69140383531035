import React, {Component}               from 'react';
import translations                     from 'decorators/translations.js';
import {connect}                        from 'react-redux';
import {localeDateString}               from 'helpers/stringTemplates';
import _ from 'lodash';
import {
    measures,
    commonStyles,
    colors
} from 'styles/design.js';
import {
    AgreementListItem,
    LoadingIndicator
} from 'components';

class ClientFrameAgreements extends Component {

    render() {
        const {t, agreementState, contractInfoId, clientId } = this.props;
        const infoWithAgreements = _.find(agreementState.byContractInfo, { id: contractInfoId });;
        const agreements = infoWithAgreements ? infoWithAgreements.frameAgreements : [];
        return (
            <div style={Object.assign({
                marginTop: measures.standard,
                marginBottom: measures.fatStandard,
                borderBottom: '1px solid ' + colors.lightGray,
                paddingTop: '1em',
                paddingBottom: '2em'
            }, agreements.length > 0 ? {borderTop: '1px solid' + colors.lightGray} : {})}>
                <h2 style={ commonStyles.pageSectionTitle }>{ t('frame_agreement_section_header') }</h2>
                <div className="ClientFrameAgreements" style={{ marginTop: '1em' }}>
                {
                    (() => {
                        if(agreementState.loading) {
                            return <div><LoadingIndicator big={true} visible={true} /></div>;
                        }
                        if (agreements.length > 0) {
                            return agreements.map((agreement, index) => {
                                const actions = [
                                    <a
                                        key={'download_' + index}
                                        onClick={ (event) => { this.props.downloadFile(event, 'print', 'frameagreement', agreement.id, null, this.props.lang) }}
                                    >
                                        <i className="fa fa-print" />
                                        <LoadingIndicator visible={this.props.download.loading} />
                                    </a>
                                ];

                                return (
                                    <AgreementListItem
                                        key={'agreement_list_item_' + index}
                                        agreementStatus={agreement.agreementStatus}
                                        title={agreement.name ? agreement.name : t('unnamed')}
                                        hoverTitle={agreement.name ? agreement.name : t('unnamed')}
                                        link={`/client/${clientId}/contractInfo/${contractInfoId}/frameagreement/${agreement.id}/summary`}
                                        timeString={t('modified')}
                                        timeValue= {localeDateString(agreement.modified, {showTimeZone: true})}
                                        actions={actions}
                                        id={'frame_agreement_list_option_' + index}
                                        createdBy={agreement.createdBy}
                                    />
                                );
                            });
                        }
                        return (
                            <div style={{ marginTop: '1em', color: colors.gray }}>
                                {t('no_agreements')}.
                            </div>
                        );
                    })()
                }
                </div>
            </div>
        );
    }
}



function mapStateToProps(state) {
    return {
        agreementState: state.frameagreement,
        client: state.client,
        profile: state.userProfile
    };
}
const connector = connect(mapStateToProps);
export default translations(connector(ClientFrameAgreements));
