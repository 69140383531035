import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {reduxForm} from 'redux-form';

import {
    Button,
    SFInputField,
    RichTextEditor
} from 'components';

import {
    layout,
    measures,
    commonStyles,
    size
} from 'styles/design.js';

const tableStyle =
    {
        marginTop: measures.standard,
        marginBottom: measures.standard,
        width: '100%',
        fontSize: '0.8em'
    };

const tableCell = {
    paddingRight: measures.quarter
};
const tableCellMiddle = {
    ...tableCell,
    paddingRight: measures.quarter,
    width: '45%'
};

const tableCellHeader = {
    ...tableCell,
    ...size.small,
    fontWeight: 'bold'
};
const tableCellHeaderMiddle = tableCellHeader;

const reduxFormObject = {
    form: 'countryTerminationTermsForm',
    fields: [
        'id',
        'terminationTermsDescription',
        'terminationTerms[].description',
        'terminationTerms[].descriptionEnglish',
        'terminationTerms[].price'
    ],
};

class TerminationTermsForm extends Component {

    componentWillMount() {
        this.props.initializeForm(this.props.countryConfig);
        this.setState({canEdit: true});
    }

    handleCancel = () => {
        this.props.initializeForm(this.props.countryConfig);
        this.props.cancelAction();
    }

    handleRemoveTerms = (termsIndex) => {
        this.props.fields.terminationTerms.removeField(termsIndex);
    }

    handleAddTerms = () => {
        this.props.fields.terminationTerms.addField();
    }

    descriptionChanged = (key, value) => {
        this.props.fields[key].onChange(value);
    }

    render() {
        const {t, fields, countryConfig} = this.props;
        return (
            <div>
                <input type="hidden" {...fields.id} />
                <div style={commonStyles.dataParentDiv}>
                    <div style={commonStyles.dataContentHeaderDiv}>
                        {t('termination_terms_information')}:
                    </div>
                    <div>
                        <table style={tableStyle}>
                            <thead>
                            <tr>
                                <td style={tableCellHeaderMiddle}>
                                    {t('description')}
                                </td>
                                <td style={tableCellHeaderMiddle}>
                                    {t('description')} ({'en'})
                                </td>
                                <td style={tableCellHeader}>
                                    {t('price')} ({countryConfig.currency})
                                </td>
                                <td>
                                </td>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                fields.terminationTerms && fields.terminationTerms.map((term, index) =>
                                    (
                                        <tr key={index}>
                                            <td style={tableCellMiddle}>
                                                <SFInputField formProperty={term.description}/>
                                            </td>
                                            <td style={tableCellMiddle}>
                                                <SFInputField formProperty={term.descriptionEnglish}/>
                                            </td>
                                            <td style={tableCell}>
                                                <SFInputField formProperty={term.price} maxLength="6"/>
                                            </td>
                                            <td style={{verticalAlign: 'middle'}}>
                                                <div>
                                                    <Button
                                                        variant="delete"
                                                        onClick={() => this.handleRemoveTerms(index)}
                                                    >
                                                        <i className="fa fa-trash"/>
                                                    </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                            }
                            </tbody>
                        </table>
                    </div>
                    <Button
                        variant="add"
                        onClick={this.handleAddTerms}
                    >
                        <i className="fa fa-plus"/> {this.props.t('add_termination_row')}
                    </Button>
                    {/*
                    <div>
                        <RichTextEditor
                            onChange={this.descriptionChanged}
                            descriptionKey={'terminationTermsDescription'}
                            value={this.props.countryConfig.terminationTermsDescription}
                            label={t('termination_terms_description')}
                        />
                    </div>
                    */}
                    <div style={{...layout.floatRight, ...layout.inlineBlock, clear: 'both'}}>
                        <Button
                            id="TerminationTermsForm_cancel"
                            variant="cancel"
                            onClick={this.handleCancel}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            id="TerminationTermsForm_save"
                            onClick={this.props.handleSubmit}
                            diabled={this.props.submitting}
                            loading={this.props.submitting}
                        >
                            {t('save')}
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default translations(reduxForm(reduxFormObject)(TerminationTermsForm));