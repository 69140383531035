import React from 'react';
import translations from 'decorators/translations.js';
import ReportSection from './ReportSection.js';
import {
    ListManager,
    BarChart,
    ReportTable,
} from 'components';

const BySelection = ({
    agreementCount, 
    agreementsListData, 
    agreementsChartData,
    agreementTotals,
    agreementTotalsTableData,
    frameAgreementCount, 
    frameAgreementsListData, 
    frameAgreementsChartData, 
    frameAgreements, 
    chartSize, 
    t
}) => {
    return (
        <div>
            <ReportSection
                title={ t('Frame agreements by status') 
                    + (frameAgreementCount ? ' (' + frameAgreementCount + ')' : '') 
                } 
            >
                { (frameAgreementsChartData && frameAgreementsChartData.length > 0) ? 
                    <BarChart 
                        data={frameAgreementsChartData} 
                        height={chartSize.height} 
                        width={chartSize.width} 
                    />
                    : <p style={{margin: '1em 0'}}>{ t('no_agreements_available') }</p>
                }
                <ListManager listData={ frameAgreementsListData } />
            </ReportSection>
            <ReportSection
                title={ t('Serviflex agreements by status')
                    + (agreementCount ? ' (' + agreementCount + ')' : '')
                }
            >
                { (agreementsChartData && agreementsChartData.length > 0) ?
                    <BarChart 
                        data={agreementsChartData} 
                        height={chartSize.height}
                        width={chartSize.width} 
                    />
                    : <p style={{margin: '1em 0'}}>{ t('no_agreements_available') }</p>
                }
                <ListManager listData={ agreementsListData } />
            </ReportSection>
            { agreementTotals
                && agreementTotals.length > 0 
                && (agreementTotals[0].value > 0 || agreementTotals[1].value > 0)
                && (
                    <ReportSection title={ t('Serviflex agreement totals')}>
                        <BarChart 
                            data={agreementTotals} 
                            height={chartSize.height} 
                            width={chartSize.width} 
                            showValues
                        />
                        <ReportTable 
                            data={agreementTotalsTableData} 
                        />
                    </ReportSection>
                )
            }
        </div>
    );
};

export default translations(BySelection);