import React, {Component}   from 'react';
import PropTypes            from 'prop-types';
import translations         from 'decorators/translations.js';
import _                    from 'lodash';
import { NavLink }             from 'react-router-dom';

import './HorizontalNavigationBar.css';

class HorizontalNavigationBar extends Component {

    static propTypes = {
        items: PropTypes.array.isRequired
    };

    render() {
        const { items } = this.props;
        return (
            <ul className="horizontal-navigation-bar">
                { items && _.map(items, (item) => {
                    return (
                        <li className="horizontal-navigation-item" key={ 'horizontal-navigation-item-' + item.id }>
                            <NavLink id={'HorizontalNavigationBar_link_' + item.id} exact={ item.exact } className="horizontal-navigation-link" to={{ pathname: item.url }}>
                                <span>{ item.label }</span>
                            </NavLink>
                        </li>
                    );
                }) }
            </ul>
        );
    }
}

export default translations(HorizontalNavigationBar);
