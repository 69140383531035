import React, { Component } from 'react';
import translations         from 'decorators/translations.js';
import configuredRadium     from 'configuredRadium.js';
import { connect }          from 'react-redux';
import { withRouter }       from 'react-router-dom';

import {
    StandardPage,
    ClientForm,
    BreadcrumbBar,
    LoadingIndicator
} from 'components';

import {
    commonStyles
} from 'styles/design.js';

import { save as saveClient, loadClient } from 'redux/modules/client.js';
import { loadCountries }        from 'redux/modules/country.js';
import { loadAllDepartments }   from 'redux/modules/department.js';
import { loadBillingIndexes }   from 'redux/modules/billingIndex';

class ClientDetailsEditView extends Component {

    componentWillMount() {
        const {dispatch, match: {params}} = this.props;
        const promises = [];
        promises.push(dispatch(loadClient(params.clientId, this.props.profile.accessToken)));
        promises.push(dispatch(loadCountries(this.props.profile.accessToken)));
        promises.push(dispatch(loadAllDepartments(this.props.profile.accessToken)));

        if (!this.props.billingIndexes || this.props.billingIndexes === 0) {
            promises.push(dispatch(loadBillingIndexes(this.props.profile.accessToken)));
        }
        return Promise.all(promises);
    }

    /*
    static fetchData(getState, dispatch, location, params) {
        const promises = [];
        promises.push(dispatch(loadClient(params.clientId, this.props.profile.accessToken)));
        promises.push(dispatch(loadCountries(this.props.profile.accessToken)));
        promises.push(dispatch(loadAllDepartments(this.props.profile.accessToken)));

        if(!getState().billingIndex.all || getState().billingIndex.all.length === 0) {
            promises.push(dispatch(loadBillingIndexes(this.props.profile.accessToken)));
        }

        return Promise.all(promises);
    }*/

    handleSubmit = (data) => {
        data.id = this.props.match.params.clientId;
        this.setState({submitting: true});
        if (!data.specificBillingAddress || data.specificBillingAddress === 'client') {
            data.billingAddress = null;
        }
        if(data.departmentId === "0") {
            delete data.departmentId;
        }
        this.props.dispatch(saveClient(data, this.props.profile.accessToken)).then((result) => {
            if (result && !result.error) {
                this.props.history.push('/client/' + this.props.match.params.clientId + '/details');
            }
            this.setState({submitting: false});
        });
    };

    handleCancel = (event) => {
        this.props.history.push('/client/' + this.props.match.params.clientId + '/details');
        event.preventDefault();
    };

    render() {
        const submitting = this.state ? this.state.submitting : false;
        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar path={this.props.location.pathname}/>
                <header>
                    <h1 style={commonStyles.pageTitle}>{this.props.t('client_details')}</h1>
                </header>
                {
                    (() => {
                        if (this.props.client.loading || this.props.department.loading) {
                            return <LoadingIndicator visible={true}/>
                        }
                        return (
                            <ClientForm
                                cancelAction={this.handleCancel}
                                client={this.props.client.client}
                                submitting={submitting}
                                onSubmit={this.handleSubmit}
                                departments={this.props.department.departments}
                                countries={this.props.countries}
                                billingIndexes={this.props.billingIndexes}
                            />
                        );
                    })()
                }
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client,
        profile: state.userProfile,
        countries: state.countries.countries,
        department: state.department,
        billingIndexes: state.billingIndex.all
    };
}

const connector = connect(mapStateToProps);
export default withRouter(translations(connector(configuredRadium(ClientDetailsEditView))));