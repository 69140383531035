import React, { PureComponent }    from 'react';

import './Loading.css';

export default class Loading extends PureComponent {

    render() {
        return (
            <div className="client-net-loading">
                <div className="bottom-left-corner" />
                <div className="top-right-corner" />
                <div className="loading-text" />
            </div>
        );
    }
};
