import React from 'react';
import translations from 'decorators/translations.js';
import _ from 'lodash';
import {
    commonStyles,
    measures,
    colors,
    layout,
} from 'styles/design.js';

const labelStyle = {
    ...layout.block,
    marginBottom: measures.half,
    marginRight: measures.half
};

const validityIndicatorStyle = {
    ...layout.inlineBlock,
    ...layout.floatRight,
    ...layout.absolute,
    ...layout.verticalMiddle,
    color: colors.basicGreen,
    lineHeight: '1.5em',
    fontSize: '150%',
    marginTop: '0.5em',
    marginLeft: '-1.25em'
};
const checkStyle = {
    ...layout.verticalMiddle,
    color: colors.basicGreen,
    fontSize: '200%',
    zIndex: -1
};

const checkboxBorderStyle = {
    ...layout.inlineBlock,

    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: colors.blue,
    marginLeft: '0em',
    marginTop: '0.5em'
};
const invalidityIndicatorStyle = {
    ...layout.inlineBlock,
    ...layout.floatRight,
    ...layout.absolute,
    ...layout.verticalMiddle,
    color: colors.basicRed,
    lineHeight: '1.5em',
    fontSize: '150%',
    marginTop: '0.5em',
    marginLeft: '-1.25em',
    border: '2px solid blue'
};

const formInputStyle = {
    ...commonStyles.checkbox,
    ...layout.inlineBlock,
    padding: 0,
    marginLeft: '3em'
};

const mandatoryInputStyle = {
    borderWidth: '2px',
};

const inInvalidStateInputStyle = {
    borderColor: colors.basicRed
};

const validationErrorStyle = {
    color: colors.basicRed
};

const SFCheckBox = (properties) => {
    const props = Object.assign({}, properties);
    if (!props.formProperty) {
        props.formProperty = {};
    }
    return (
        <label style={labelStyle}>
            {(() => {
                if (props.formProperty && props.formProperty.error) {
                    return <span id={props.errorId} style={validationErrorStyle}>{props.formProperty.error}</span>;
                } else if (props.fieldName) {
                    return <span id={props.labelId}>{props.fieldName}</span>;
                }
            })()
            }

            {
                (() => {
                    const styles = Object.assign({}, formInputStyle, props.styles);
                    if (props.mandatory) Object.assign(styles, mandatoryInputStyle);
                    if (props.error) Object.assign(styles, inInvalidStateInputStyle);
                    Object.assign(styles, layout.vanish);
                    const inputProps = {
                        id: props.id,
                        style: styles,
                        type: 'checkbox'
                    };
                    if(props.onChange) {
                        Object.assign(inputProps, {
                            onChange: (event) => props.onChange(event.target.value === 'false'),
                            value: props.value
                        });
                    } else if(!_.isEmpty(props.formProperty)) {
                        Object.assign(inputProps, props.formProperty);
                    }
                    return (
                        <input {...inputProps} />
                    );
                })()
            }

            <div style={checkboxBorderStyle}>
                <span id={props.spanId} style={{
                    ...checkStyle,
                    visibility: (props.formProperty.value || props.value) ? 'visible' : 'hidden'
                }}>
                    <i className="fa fa-check"/></span>
            </div>
            {
                (() => {
                    if (props.formProperty.error) {
                        return <span style={invalidityIndicatorStyle}><i className="fa fa-exclamation"/></span>;
                    } else if (props.formProperty.touched && props.mandatory) {
                        return <span style={validityIndicatorStyle}><i className="fa fa-check"/></span>;
                    }
                })()
            }
            {props.rightLabel ? <span id={props.labelId} style={{paddingLeft: '1em'}}>{props.rightLabel}</span> : ''}
        </label>
    );
};
export default translations(SFCheckBox);
