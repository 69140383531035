import React, {Component}       from 'react';
import translations             from 'decorators/translations.js';
import PropTypes                from 'prop-types';
import {
    layout,
    measures,
    colors
} from 'styles/design.js';
import {
    SFInputField,
    SFSelectField,
    ConfirmableButton,
    SystemComponentForm,
    SystemComponentList,
    SystemSegmentEditButton,
    SystemFormWrapper,
    SystemFormBody,
    SystemFormActions,
    Button
} from 'components';
import _ from 'lodash';

const rightColumn = {
    ...layout.floatRight,
    ...layout.inlineBlock,
    minWidth: '12em',
    width: '49%'
};

const leftColumn = {
    ...layout.floatLeft,
    ...layout.inlineBlock,
    minWidth: '12em',
    width: '49%'
};

const valueLabelStyle = {
    fontWeight: 'bold',
    marginTop: measures.half,
    marginBottom: measures.half
};

const valueCellStyle = {
    fontWeight: 'normal',
    width: '50%'
};
const systemSubHeader = {
    color: colors.activeText,
    marginBottom: measures.standard,
};
const units = [
    'mA',
    'A',
    'mAh',
    'kW',
    'kWh',
    'V',
    'W',
    'm3',
    'm2',
    'm',
    'km',
    'kg',
    'l',
    'Item',
    'm3/s',
    'l/s',
    'kVA'
];

const idPrefix = "SystemInfoContainer";

class SystemInfoContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    static propTypes = {
        system: PropTypes.object.isRequired,
        handleCancel: PropTypes.func.isRequired,
        handleSave: PropTypes.func.isRequired,
        deleteSystem: PropTypes.func.isRequired,
        submitting: PropTypes.bool
    };

    componentWillMount() {
        this.init(this.props.system);
    }

    componentWillReceiveProps(nextProps) {
        if (_.isEmpty(this.state.form)) {
            this.init(nextProps.system);
        }
    }

    init(sys) {
        const system = Object.assign({}, sys);
        const form = {};
        const defaultKeys = [
            'systemID',
            'equipmentType',
            'equipmentTypeDescription',
            'componentComment',
            'systemIdComment',
            'floor',
            'room',
            'rating',
            'capacity',
            'quantityUnit',
            'comment',
            'ratingComment',
            'overrideCalculation',
            'functionalLocation',
            'serving',
        ];
        /*
        'locations[].location',
        'locations[].floor',
        'locations[].room',
        'locations[].description',
        */
        defaultKeys.map((key) => {
            if (!system[key]) {
                system[key] = '';
            }
        });
        Object.keys(system).map((key) => {
            switch (key) {
                case 'locations':
                    form[key] = [];
                    const components = system[key] || [];
                    components.map((component) => {
                        const componentForm = {};
                        Object.keys(component).map((key2) => {
                            componentForm[key2] = {
                                value: component[key2]
                            };
                        });
                        if (!componentForm.description) {
                            componentForm.description = {value: ''};
                        }
                        if (!componentForm.unit) {
                            componentForm.unit = {value: ''};
                        }
                        if (!componentForm.capacity) {
                            componentForm.capacity = {value: ''};
                        }
                        form['locations'].push(componentForm);
                    });
                    break;
                default:
                    form[key] = {
                        value: system[key],
                        onChange: (event) => this.changeValue(key, event.target.value)
                    };
            }

        });
        this.setState({form});
    }

    changeValue = (key, value) => {
        if (this.props.editMode) {
            const form = Object.assign({}, this.state.form);
            form[key].value = value;
            this.setState({form});
        }
    };

    isDataValid(data) {
        const props = ['systemID', 'equipmentType'];
        let valid = true;
        props.map((prop) => {
            if (!data[prop] || data[prop] === '') {
                valid = false;
            }
        });
        return valid;
    }

    saveAction = (event) => {
        const values = {};
        Object.keys(this.state.form).map((key) => {
            switch (key) {
                case 'locations':
                    values[key] = [];
                    this.state.form[key].map((location) => {
                        values[key].push({
                            location: location.location ? location.location.value : '',
                            description: location.description && location.description.value ? location.description.value : '',
                            unit: location.unit && location.unit.value ? location.unit.value : '',
                            capacity: location.capacity && location.capacity.value ? location.capacity.value : ''
                        });
                    });
                    break;
                default:
                    values[key] = this.state.form[key].value;
            }
        });
        this.props.handleSave(values);
        event.preventDefault();
    };

    resetField = () => {
        this.init(this.props.system);
    };

    getForm() {
        return Object.assign({}, this.state.form);
    }

    addComponent = (event) => {
        const form = this.getForm();
        if (!form.locations) {
            form.locations = [];
        }
        form.locations.push({
            location: {
                value: ''
            },
            description: {
                value: ''
            },
            unit: {
                value: ''
            },
            capacity: {
                value: ''
            }
        });
        this.setState({form, renderLocations: true});
        event.preventDefault();
    };
    removeComponent = (event, index) => {
        const form = this.getForm();
        form.locations.splice(index, 1);
        this.setState({form, renderLocations: true});
        event.preventDefault();
    };

    updateComponentValue = ({key, index, value}) => {
        const form = this.getForm();
        const locations = form.locations && form.locations.length > 0 ? form.locations.slice(0) : [];
        const location = locations[index] || {};
        if (!location[key]) {
            location[key] = {};
        }
        location[key].value = value;
        form.locations = locations;
        this.setState({form, renderLocations: true});
    };

    renderNotEditable = () => {
        const {system, t, showEditButton, isEditable} = this.props;
        return (
            <div>
                <SystemSegmentEditButton
                    id="SystemInfoContainer_edit"
                    onClick={this.props.systemSegmentEditAction}
                    showEditButton={showEditButton}
                    isEditable={isEditable}
                />

                <table
                    style={{
                        borderSpacing: measures.half,
                        borderCollapse: 'separate',
                        width: '100%',
                        marginBottom: measures.standard
                    }}>
                    <tbody>
                    <tr>
                        <td colSpan="2" style={valueCellStyle}>
                            <div style={valueLabelStyle}>{t('system_id')}</div>
                            {system.systemID}
                        </td>
                    </tr>

                    <tr>
                        <td style={valueCellStyle}>
                            <div style={valueLabelStyle}>{t('equipment_type')}</div>
                            {system.equipmentType}
                        </td>

                        <td style={valueCellStyle}>
                            <div style={valueLabelStyle}>{t('equipment_type_description')}</div>
                            {system.equipmentTypeDescription}
                        </td>
                    </tr>

                    <tr>
                        <td style={valueCellStyle}>
                            <div style={valueLabelStyle}>{t('floor')}</div>
                            {system.floor}
                        </td>

                        <td style={valueCellStyle}>
                            <div style={valueLabelStyle}>{t('room')}</div>
                            {system.room}
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2" style={valueCellStyle}>
                            <div style={valueLabelStyle}>{t('system_id_comment')}</div>
                            {system.systemIdComment}
                        </td>
                    </tr>
                    <tr>
                        <td style={valueCellStyle} colSpan="2">
                            <div style={valueLabelStyle}>{t('system_internal_comment')}</div>
                            {system.comment}
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2" style={valueCellStyle}>
                            <div style={valueLabelStyle}>{t('capacity')}</div>
                            {system.capacity} {t('unit_' + system.quantityUnit)}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <div style={valueLabelStyle}>{t('components')}</div>
                        </td>
                    </tr>
                    <tr>
                        <td style={valueCellStyle}>
                            <div style={valueLabelStyle}>{t('First year of visits')}</div>
                            {system.serving}
                        </td>
                    </tr>

                    <tr>
                        <td colSpan="2">
                            <SystemComponentList
                                components={system.locations}
                                systemId={this.props.systemIndex}/>
                        </td>
                    </tr>

                    <tr style={(system.locations && system.locations.length > 0 ? layout.visible : layout.none)}>
                        <td colSpan="2" style={valueCellStyle}>
                            <div style={valueLabelStyle}>{t('component_comment')}</div>
                            {system.componentComment}
                        </td>
                    </tr>

                    </tbody>
                </table>
            </div>
        );
    }

    renderEditable = (form, equipmentTypeOptionsWithSelect) => {
        const {submitting, t} = this.props;

        return (
            <div>
                <div style={{height: measures.double, ...layout.floatRight}}>
                    <ConfirmableButton
                        variant="delete"
                        size="small"
                        action={this.props.deleteSystem}
                        buttonIcon="fa fa-trash"
                        buttonText={t('delete')}
                    />
                </div>
                <h3 style={systemSubHeader}>{t('system_info')}</h3>
                <div>

                    <div>
                        <SFInputField
                            id="SystemInfoContainer_systemId"
                            defaultInput={true}
                            {...form.systemID}
                            fieldName={t('system_id')}
                            style={leftColumn}
                        />
                    </div>
                </div>

                <div>
                    <div style={leftColumn}>
                        {
                            (() => {
                                if (equipmentTypeOptionsWithSelect.length <= 1) {
                                    return (
                                        <SFInputField
                                            id="SystemInfoContainer_equipmentType"
                                            defaultInput={true}
                                            {...form.equipmentType}
                                            fieldName={t('equipment_type')}/>
                                    );
                                }
                                return (
                                    <SFSelectField
                                        defaultInput={true}
                                        {...form.equipmentType}
                                        fieldName={t('equipment_type')}>
                                        {
                                            (() => {
                                                return equipmentTypeOptionsWithSelect.map((equipmentTypeOption, idx) => {
                                                    return (
                                                        <option
                                                            key={'equipment_option_' + idx}
                                                            value={equipmentTypeOption}>
                                                            {t(equipmentTypeOption)}
                                                        </option>
                                                    );
                                                });
                                            })()
                                        }
                                    </SFSelectField>
                                );
                            })()
                        }
                    </div>

                    <div style={rightColumn}>
                        <SFInputField
                            id="SystemInfoContainer_equipmentTypeDescription"
                            defaultInput={true}
                            {...form.equipmentTypeDescription}
                            fieldName={t('equipment_type_description')}/>
                    </div>
                </div>

                <div>
                    <div style={leftColumn}>
                        <SFInputField
                            id="SystemInfoContainer_floor"
                            defaultInput={true}
                            {...form.floor}
                            fieldName={t('floor')}
                            style={leftColumn}/>
                    </div>

                    <div style={rightColumn}>
                        <SFInputField
                            id="SystemInfoContainer_room"
                            defaultInput={true}
                            {...form.room}
                            fieldName={t('room')}/>
                    </div>
                </div>

                <SFInputField
                    id="SystemInfoContainer_systemIdComment"
                    defaultInput={true}
                    {...form.systemIdComment}
                    fieldName={t('system_id_comment')}/>

                <div>
                    <Button
                        variant="edit"
                        onClick={() => this.setState( state => ({...state, showExtraInfo: !state.showExtraInfo}))}
                    >
                        {this.state.showExtraInfo ? t('Hide extra info') : t('Show extra info') }
                    </Button>
                    <div style={{display: this.state.showExtraInfo ? 'block' : 'none'}}>
                        <SFInputField
                            defaultInput={true}
                            {...form.comment}
                            fieldName={t('system_internal_comment')}
                        />
                    </div>
                </div>

                <div>
                    <div>
                        <div style={layout.inlineBlock}>
                            <div style={{ ...layout.floatLeft, width: '150px' }}>
                                <SFInputField
                                    id="SystemInfoContainer_capacity"
                                    defaultInput={true}
                                    type="number"
                                    {...form.capacity}
                                    fieldName={t('capacity')}/>
                            </div>

                            <div style={{ ...layout.floatRight, marginLeft: measures.standard }}>
                                <SFSelectField
                                    id="SystemInfoContainer_quantityUnit"
                                    defaultInput={true}
                                    {...form.quantityUnit}
                                    fieldName={t('unit')}
                                    mandatory={form.capacity.value && form.capacity.value !== ''}
                                    hideIcons={true}
                                >
                                    <option value="">{t('select')}</option>
                                    {
                                        (() => {
                                            return units.map((unit, idx) => {
                                                return (<option key={'unit_option_' + idx}
                                                                value={unit}>{t('unit_' + unit)}</option>);
                                            });
                                        })()
                                    }
                                </SFSelectField>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="clearfix">
                    <div style={leftColumn}>
                        <SFInputField
                            id="SystemInfoContainer_serving"
                            defaultInput={true}
                            {...form.serving}
                            fieldName={t('First year of visits')}
                            style={leftColumn}/>
                    </div>
                </div>

                <h3 style={systemSubHeader}>{t('components')}</h3>
                <SystemComponentForm
                    form={form}
                    addComponent={this.addComponent}
                    removeComponent={this.removeComponent}
                    resetFunction={this.resetField}
                    updateComponentValue={this.updateComponentValue}
                />

                <div style={(form.locations && form.locations.length > 0 ? layout.visible : layout.none)}>
                    <SFInputField
                        defaultInput={true}
                        {...form.componentComment}
                        fieldName={t('component_comment')}/>
                </div>

                <SystemFormActions
                    handleCancel={ this.props.handleCancel }
                    handleSave={ this.saveAction }
                    submitting={ submitting }
                    idPrefix={ idPrefix }
                />
            </div>
        )
    }

    render() {
        const {system, t} = this.props;
        const equipmentTypeOptionsWithSelect = ['select'];
        const editMode = this.props.editMode;
        const expandThis = editMode ? true : this.state.expandThis;
        const form = this.state.form;
        const isValid = this.isDataValid(system);

        return (
            <SystemFormWrapper
                id={ idPrefix + '_expand_' + this.props.index}
                isVisible={ expandThis }
                handleToggle={ () => {
                    this.setState({expandThis: !this.state.expandThis});
                } }
                title={ t('system_info') }
                isValid={ isValid }
            >
                <SystemFormBody
                    notEditableBody={ this.renderNotEditable() }
                    editableBody={ this.renderEditable(form, equipmentTypeOptionsWithSelect) }
                    isEditable={ editMode }
                />
            </SystemFormWrapper>
        );
    }
}

export default translations(SystemInfoContainer);
