import React, { Component } from 'react';
import {
    Dialog
 } from 'components';

class AgreementSignature extends Component {
    state = {
        confirmDelete: false
    };

    showConfirm = e => {
        e && typeof e.preventDefault === 'function' && e.preventDefault();
        this.setState({
            confirmDelete: true
        })
    };

    hideConfirm = e => {
        e && typeof e.preventDefault === 'function' && e.preventDefault();
        this.setState({
            confirmDelete: false
        });
    };

    renderDeleteConfirm = () => {
        const { t, signature: { id }, deleteSignature } = this.props;
        return <Dialog
            actions={[
                {
                    id: 'ConfirmDelete_cancel',
                    onClick: this.hideConfirm,
                    title: t('cancel'),
                    variant: 'cancel'
                },
                {
                    id: 'ConfirmDelete_confirm',
                    onClick: e => {
                        deleteSignature(id);
                        this.hideConfirm(e);
                    },
                    title: t('yes'),
                    variant: 'primary'
                }
            ]}
        >
            { t('Are you sure?') }
        </Dialog>;
    };

    render() {
        const { signature, toggleEditSignature, isEditable } = this.props;
        return <div className="item">
            <div>
                <div className="signature-line"></div>
                <div className="signature-line-label">
                    { signature.line1 }
                </div>
                <div className="signature-line-label">
                    { signature.line2 }
                </div>
            </div>
            {isEditable && <div className="signature-edit-container">
                {typeof this.props.deleteSignature === 'function' && <button
                    className="signature-button signature-button-delete"
                    onClick={ (e) => this.showConfirm(e) }
                >
                    <i className="fa fa-trash" />
                </button>}
                <button
                    className="signature-button signature-button-add"
                    onClick={ (e) => toggleEditSignature(signature.id, e)}
                >
                    <i className="fa fa-pencil" />
                </button>
            </div>}
            { this.state.confirmDelete && this.renderDeleteConfirm() }
        </div>
    }
}

export default AgreementSignature;
