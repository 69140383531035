import React, {Component }                  from 'react';
import PropTypes                            from 'prop-types';
import translations                         from 'decorators/translations.js';
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem
}                                           from 'components';

import './Dropdown.css';

class Dropdown extends Component {
    
    static propTypes = {
        options: PropTypes.array,
        buttonTitle: PropTypes.string,
        buttonVariant: PropTypes.string,
        buttonSize: PropTypes.string
    };
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        };
    }
    clickMenu = (event, onClick) => {
        event.preventDefault();
        this.setState({
            isVisible: !this.state.isVisible
        });
    }
    handleClick = (event) => {
        this.setState({
            isVisible: !this.state.isVisible
        });
    }
    render() {
        const { t, options } = this.props;
        return (
            <div className="dropdown" style={ this.props.style }>
                <DropdownToggle 
                    id={this.props.id}
                    variant={ this.props.buttonVariant }
                    size={ this.props.buttonSize }
                    style={ this.props.buttonStyle }
                    onClick={ (event) => this.clickMenu(event) }>
                    { this.props.children ? this.props.children : t('add_new') + '...'}
                </DropdownToggle>
                <DropdownMenu isVisible = { this.state.isVisible }>
                    {
                        (() => {
                            return options.map((option, index) => {
                                return (
                                    <DropdownItem onClick={this.handleClick} id={ option.id } key = { 'dropdownitem_key_' + index } action = { option.url }>
                                        { option.name }
                                    </DropdownItem>
                                );
                            });
                        })()
                    }
                </DropdownMenu>
            </div>

        );}
}

export default translations(Dropdown);
