const LOAD                 = 'ServiFlex/Translations/LOAD',
    LOAD_SUCCESS           = 'ServiFlex/Translations/LOAD_SUCCESS',
    LOAD_FAIL              = 'ServiFlex/Translations/LOAD_FAIL',
    LOAD_LANGUAGES         = 'ServiFlex/Translations/LOAD_LANGUAGES',
    LOAD_LANGUAGES_SUCCESS = 'ServiFlex/Translations/LOAD_LANGUAGES_SUCCESS',
    LOAD_LANGUAGES_FAIL    = 'ServiFlex/Translations/LOAD_LANGUAGES_FAIL',
    LOAD_WITHTYPE           = 'ServiFlex/Translations/LOAD_WITHTYPE',
    LOAD_WITHTYPE_SUCCESS           = 'ServiFlex/Translations/LOAD_WITHTYPE_SUCCESS',
    LOAD_WITHTYPE_FAIL           = 'ServiFlex/Translations/LOAD_WITHTYPE_FAIL',
    SAVE_TRANSLATION             = 'ServiFlex/Translations/SAVE_TRANSLATION',
    SAVE_TRANSLATION_SUCCESS             = 'ServiFlex/Translations/SAVE_TRANSLATION_SUCCESS',
    SAVE_TRANSLATION_FAIL             = 'ServiFlex/Translations/SAVE_TRANSLATION_FAIL',
    SET_LANGUAGE = 'ServiFlex/Translations/SET_LANGUAGE',
    TOGGLE_SHOW_KEYS = 'ServiFlex/Translations/TOGGLE_SHOW_KEYS';

const initialState = { showKeys: false, loaded: false, data: {}, language: null, languages: [], translations: {}, saving: false, saved: false, translatables: {}};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                data: action.result,
                language: action.language,
                translations: action.result
            };
        case LOAD_LANGUAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                languages: action.result,
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case LOAD_WITHTYPE:
            return {
                ...state,
                loading: true,
            };
        case LOAD_WITHTYPE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                translatables: action.result
            };
        case LOAD_WITHTYPE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SAVE_TRANSLATION:
            return {
                ...state,
                saving: true
            };
        case SAVE_TRANSLATION_SUCCESS: {
            const list = state.translatables;
            if(list[action.result.key] && list[action.result.key][action.result.language]) {
                list[action.result.key][action.result.language].value = action.result.value;
            }
            return {
                ...state,
                saving: false,
                saved: true,
                translatables: list,
                result: action.result
            };
        }
        case SAVE_TRANSLATION_FAIL:
            return {
                ...state,
                saving: false,
                saved: false,
                error: action.error
            };

        case SET_LANGUAGE:
            return {
                ...state,
                language: action.result
            };
        case TOGGLE_SHOW_KEYS:
            return {
                ...state,
                showKeys: action.result
            };
        default:
            return state;
    }
}

export function setLanguage(language) {
    return {
        types: ['', SET_LANGUAGE, ''],
        promise: () => { return new Promise((resolve) => { resolve(language); }); }
    };
}
export function toggleShowKeys(selected) {
    return {
        types: ['', TOGGLE_SHOW_KEYS, ''],
        promise: () => { return new Promise((resolve) => { resolve(selected); }); }
    };
}

export function isLoaded(globalState) {
    return globalState.translations && globalState.translations.loaded && globalState.translations.translations.length > 0;
}

export function load(lang, accessToken, init) {
    //language = cookies.locale || navigator.language || 'en',
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        language: lang,
        promise: (client) => client.get(
            '/translations/' + lang + '?init=' + init || false, { accessToken }
        )
    };
}

export function loadWithType(type, lang, country, accessToken) {
    const path = '/translations/configurableLocales/' + lang + '?type=' + type + (country ? '&country=' + country : '');
    return {
        types: [LOAD_WITHTYPE, LOAD_WITHTYPE_SUCCESS, LOAD_WITHTYPE_FAIL],
        promise: (client) => client.get(
            path, { accessToken }
        )
    };
}

export function loadLanguages(accessToken) {
    return {
        types: [LOAD_LANGUAGES, LOAD_LANGUAGES_SUCCESS, LOAD_LANGUAGES_FAIL],
        promise: (client) => client.get(
            '/translations/languages', { accessToken }
        )
    };
}

export function saveTranslation(translation, accessToken) {
    return {
        types: [SAVE_TRANSLATION, SAVE_TRANSLATION_SUCCESS, SAVE_TRANSLATION_FAIL],
        promise: (client) => client.post( '/translations/save', {data: {data: translation}, accessToken} )
    };
}
