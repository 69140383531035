import React, {Component}            from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';
import { measures }     from 'styles/design.js';
import { SFRadioField } from 'components/index.js';

class AddressSelector extends Component {
    render() {
        return (
            <div id={this.props.id} style={this.props.style}>
                {
                    (() => {
                        return this.props.options.map((option, idx) => {
                            return (<div key={'address_selector_idx_' + idx} style={{display: 'inline-block', marginRight: measures.standard}}>
                                <SFRadioField
                                    key={this.props.name + idx}
                                    name={this.props.name}
                                    label={option.label}
                                    value={option.value}
                                    formProperty={this.props.formProperty}
                                />
                            </div>);
                        });
                    })()
                }
            </div>
        );
    }
}
export default translations(configuredRadium(AddressSelector));
