import React, {Component}    from 'react';
import translations from 'decorators/translations.js';

import {
    layout,
    colors,
    commonStyles,
} from 'styles/design.js';
import _ from 'lodash';

import {
    KeyValueDiv,
    AddressInformation,
}                           from 'components';

const infoContainerStyle = {
    padding: '2em',
    borderBottom: '1px solid',
    borderColor: colors.lightGray
};

const infoContainerTitle = {
    ...commonStyles.pageSubTitle,
    ...layout.inlineBlock,
    ...layout.verticalTop,
    height : '100%',
    paddingTop: '0.75em'
};
const valueStyle = {
    fontWeight: 'bold'
};
class ContractInformation extends Component {

    render() {
        const { t, contractInfo, client } = this.props;
        let billingIndex = '';
        if(this.props.billingIndexes && contractInfo.billingIndexId) {
            const index = _.find(this.props.billingIndexes, {id: contractInfo.billingIndexId});
            if(index) {
                billingIndex = index.key;
            }
        }
        const hasOwnContactInfo = contractInfo.contactEmail || contractInfo.contactPhone;

        return (
        <div>
            <div style = { infoContainerStyle }>
                <h2 id="ContractInformation_title" style = { infoContainerTitle }>{ t('Contract info') }</h2>
                <KeyValueDiv
                    id="ClientInformation_salesArea"
                    name={ t('caverion_sales_area')}
                    value={ contractInfo.salesArea}
                    withColon={true}
                    rightStyles={{fontWeight: 'bold', textTransform: 'capitalize'}}
                />
                <KeyValueDiv
                    id="ClientInformation_department"
                    name={ t('department')}
                    value={ this.props.department.name}
                    withColon={true}
                    rightStyles={valueStyle}
                />
            </div>
            <div style = { infoContainerStyle }>
                <h2 style = { infoContainerTitle }>{ t('contact_info') }</h2>

                <KeyValueDiv
                    id="ClientInformation_contactName"
                    name={ t('name')}
                    value={ contractInfo.contactName }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_contactEmail"
                    name={ t('email')}
                    value={ hasOwnContactInfo ? contractInfo.contactEmail : client.email }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv

                    id="ClientInformation_contactPhone"
                    name={ t('phone')}
                    value={ hasOwnContactInfo ? contractInfo.contactPhone : client.clientPhone }
                    withColon={true}
                    rightStyles={valueStyle}
                />
            </div>
            <div style = { infoContainerStyle }>
                <h2 style = { infoContainerTitle }>{ t('billing_info') }</h2>
                <KeyValueDiv
                    id="ClientInformation_billingCompany"
                    name={ t('billing_company')}
                    value={ contractInfo.billingCompany || client.name }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_billingIndex"
                    name={ t('billing_index')}
                    value={ t(billingIndex) }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_electronicInvoiceNumber"
                    name={ t('electronic_invoice_number')}
                    value={ contractInfo.electronicInvoiceNumber}
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_billingRef"
                    name={ t('billing_ref')}
                    value={ contractInfo.billingRef }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_paymentTerm"
                    name={ t('payment_term') }
                    value={ (contractInfo.paymentTerm || 14) + ' ' + t('days') }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <AddressInformation type="ClientInformation_billingAddress" t={t} styling="clientInfo" address={!_.isEmpty(contractInfo.billingAddress) ? contractInfo.billingAddress : client.clientAddress} />
            </div>
            <div style = { infoContainerStyle }>
                <h2 style = { infoContainerTitle }>{ t('caverion_info') }</h2>

                <KeyValueDiv
                    id="ClientInformation_keyAccountManager"
                    name={ t('key_account_manager')}
                    value={ contractInfo.keyAccountManager }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_caverionEmail"
                    name={ t('email')}
                    value={ contractInfo.caverionEmail }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    name={ t('phone')}
                    id="ClientInformation_caverionPhone"
                    value={ contractInfo.caverionPhone }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <AddressInformation type="ClientInformation_caverionAddress" t={t} styling="clientInfo" address={contractInfo.caverionAddress} />
                <KeyValueDiv
                    name={ t('branch_office_phone')}
                    id="ClientInformation_branchOfficePhone"
                    value={ contractInfo.branchOfficePhone }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_localDutyPhone"
                    name={ t('local_duty_phone')}
                    value={ contractInfo.localDutyPhone }
                    withColon={true}
                    rightStyles={valueStyle}
                />
                <KeyValueDiv
                    id="ClientInformation_nationalServiceCenterPhone"
                    name={ t('national_service_center_telephone')}
                    value={ contractInfo.nationalServiceCenterPhone }
                    withColon={true}
                    rightStyles={valueStyle}
                />

            </div>
        </div>
        );
    }
}

export default translations(ContractInformation);