import getCSRFUrl   from 'helpers/getCSRFUrl.js';
import _            from 'lodash';

const LOAD                             = 'ServiFlex/Discipline/LOAD',
    LOAD_SUCCESS                       = 'ServiFlex/Discipline/LOAD_SUCCESS',
    LOAD_FAIL                          = 'ServiFlex/Discipline/LOAD_FAIL',
    SAVE                               = 'ServiFlex/Discipline/SAVE',
    SAVE_SUCCESS                       = 'ServiFlex/Discipline/SAVE_SUCCESS',
    SAVE_FAIL                          = 'ServiFlex/Discipline/SAVE_FAIL',
    CALCULATE_COSTS                    = 'ServiFlex/Discipline/CALCULATE_COSTS',
    CALCULATE_COSTS_FAIL               = 'ServiFlex/Discipline/CALCULATE_COSTS_FAIL',
    CALCULATE_COSTS_SUCCESS            = 'ServiFlex/Discipline/CALCULATE_COSTS_SUCCESS',
    UPDATE_COSTS                       = 'ServiFlex/Discipline/UPDATE_COSTS',
    UPDATE_COSTS_FAIL                  = 'ServiFlex/Discipline/UPDATE_COSTS_FAIL',
    UPDATE_COSTS_SUCCESS               = 'ServiFlex/Discipline/UPDATE_COSTS_SUCCESS',
    CREATE_NEW                         = 'ServiFlex/Discipline/CREATE_NEW',
    SET_DISCIPLINE                     = 'ServiFlex/Discipline/SET_DISCIPLINE',
    DELETE                             = 'ServiFlex/Discipline/DELETE',
    DELETE_SUCCESS                     = 'ServiFlex/Discipline/DELETE_SUCCESS',
    DELETE_FAIL                        = 'ServiFlex/Discipline/DELETE_FAIL',
    RESET                              = 'ServiFlex/Discipline/RESET',
    RESET_SUCCESS                      = 'ServiFlex/Discipline/RESET_SUCCESS',
    RESET_FAIL                         = 'ServiFlex/Discipline/RESET_FAIL';

const initialState = {
    loading: false,
    discipline: {},
    savedDiscipline: {},
    calculatedDiscipline: {},
    disciplines: [],
    disciplineMetadata: {},
    frameAgreementDisciplines: [],
    loaded: false,
    reload: false,
    saving: false,
    calculating: false,
    calculated: false,
    reseting: false,
    reset: false,
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                discipline: action.result
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SAVE:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: true,
                error: action.error
            };
        case SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: false,
                reload: true,
                saving: false,
                discipline: action.result,
            };
        case SAVE_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                saving: false,
                error: action.error
            };
        case CALCULATE_COSTS:
            return {
                ...state,
                calculating: true
            };
        case CALCULATE_COSTS_FAIL:
            return {
                ...state,
                calculating: false,
                calculated: false,
                error: action.error
            };
        case CALCULATE_COSTS_SUCCESS:
            return {
                ...state,
                calculated: true,
                calculating: false,
                calculatedDiscipline: action.result
            };
        case UPDATE_COSTS:
            return {
                ...state,
                updating: true
            };
        case UPDATE_COSTS_FAIL:
            return {
                ...state,
                updating: false,
                updated: false,
                error: action.error
            };
        case UPDATE_COSTS_SUCCESS:
            return {
                ...state,
                updating: false,
                updated: true,
                loaded: false,
                //calculatedDisciplines: action.result
            };
        case CREATE_NEW:
            const data = {
                disciplineCategory: action.result.disciplineCategory,
                disciplineType: action.result.disciplineType,
                systems: [],
                serviceLevel: '',
                buildingId: action.result.buildingId,
                visitPlan: []
            };
            return {
                ...state,
                discipline: data
            };
        case SET_DISCIPLINE:
            return {
                ...state,
                discipline: action.result,
                calculatedDiscipline: action.result
            };
        case DELETE:
            return {
                ...state,
                deleting: true
            };
        case DELETE_SUCCESS:
            return {
                ...state,
                deleting: false,
                deleted: true,
                result: action.result
            };
        case DELETE_FAIL:
            return {
                ...state,
                deleting: false,
                deleted: false,
                error: action.error
            };
        case RESET:
            return {
                ...state,
                reseting: true
            };
        case RESET_SUCCESS:
            const discipline = state.discipline;
            return {
                ...state,
                reseting: false,
                reset: true,
                calculatedDiscipline: _.find(action.result, { id: discipline.id }),
            };
        case RESET_FAIL:
            return {
                ...state,
                reseting: false,
                reset: false,
                error: action.error
            };
        default:
            return state;
    }
}

export function loadDiscipline(disciplineId, accessToken) {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client) => client.get('/disciplines/' + disciplineId + '?filter={"include": ["disciplineSystems","agreement"]}', { accessToken }
        )
    };
}

export function update(discipline, accessToken) {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.patch('/disciplines/' + discipline.id, { data: discipline, accessToken }
        )
    };
}

export function resetDisciplineCalculations(payload, accessToken) {
    return {
        types: [RESET, RESET_SUCCESS, RESET_FAIL],
        promise: (client) => client.post(
            '/disciplines/resetDisciplineCategoryCalculations', { data: payload, accessToken }
        )
    };
}

export function resetSubDisciplineCalculations(payload, accessToken) {
    return {
        types: [RESET, RESET_SUCCESS, RESET_FAIL],
        promise: (client) => client.post(
            '/disciplines/resetDisciplineCalculations', { data: payload, accessToken }
        )
    };
}

export function save(discipline, accessToken) {
    if (discipline.id) {
        return update(discipline, accessToken);
    }
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: (client) => client.post(
            '/disciplines', { data: discipline, accessToken }
        )
    };
}

export function calculateDisciplineCosts(discipline, accessToken) {
    return {
        types: [CALCULATE_COSTS, CALCULATE_COSTS_SUCCESS, CALCULATE_COSTS_FAIL],
        promise: (client) => client.post(
            '/calculations/discipline', { data: { discipline }, accessToken }
        )
    };
}

export function updateDisciplineWithCustomIncome(payload, accessToken) {
    return {
        types: [UPDATE_COSTS, UPDATE_COSTS_SUCCESS, UPDATE_COSTS_FAIL],
        promise: (client) => client.post(
            '/disciplines/updateDisciplineCategoryWithCustomIncome', { data: payload, accessToken }
        )
    };
}

export function updateSubDisciplineWithCustomIncome(payload, accessToken) {
    return {
        types: [UPDATE_COSTS, UPDATE_COSTS_SUCCESS, UPDATE_COSTS_FAIL],
        promise: (client) => client.post(
            '/disciplines/updateDisciplineWithCustomIncome', { data: payload, accessToken }
        )
    };
}

export function createNew(disciplineCategory, disciplineType, buildingId) {
    return {
        types: ['', CREATE_NEW, ''],
        promise: () => { return new Promise((resolve) => { resolve( { disciplineCategory, disciplineType, buildingId} ); }); }
    };
}
export function setDiscipline(disciplineObject) {
    return {
        types: ['', SET_DISCIPLINE, ''],
        promise: () => { return new Promise((resolve) => { resolve(disciplineObject); }); }
    };
}

export function deleteDiscipline(id, accessToken) {
    return {
        types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
        promise: (client) => client.post('/disciplines/deleteWithId', { data: {disciplineId: id}, accessToken })
    };
}
