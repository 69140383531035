import React                    from 'react';
import ReactDOM                 from 'react-dom';
import { Provider }             from 'react-redux';
import createHistory            from 'history/createBrowserHistory'
import { ConnectedRouter }      from 'react-router-redux';
import { Route, Switch }        from 'react-router-dom';

import { ApiClient }            from './utils/index.js';

import {
    Application,
    NotFound,
    WelcomeView,
    AgreementView,
    AgreementApproveView,
    CreateClientView,
    CreateBuildingView,
    ClientOverviewView,
    ClientDetailsView,
    BuildingOverviewView,
    CreateDisciplineView,
    CreateSubDisciplineView,
    BuildingDetailsView,
    ClientDetailsEditView,
    BuildingDetailsEditView,
    DisciplineView,
    SLAView,
    MonitoringView,
    CountrySelectionView,
    CostCalculationConfigurationView,
    CountrySLAConfigurationView,
    DisciplineConfigurationView,
    DisciplineTypeConfigurationView,
    CostView,
    CostEditView,
    PriceListView,
    KitPackEditView,
    CountryMainAgreementConfigurationView,
    CountryTermsAndConditionsConfigurationView,
    CountryFrameAgreementConfigurationView,
    CountryFrameAgreementDisciplinesConfigurationView,
    CreateFrameAgreementView,
    FrameAgreementView,
    UserinfoView,
    FrameAgreementTranslationView,
    FrameAgreementCommentsView,
    CccProcessView,
    ClientList,
    ReportView,
    FrameAgreementSLAView,
    DepartmentConfigurationView,
    AgreementCommentsView,
    AgreementContentsView,
    FrameAgreementContentsView,
    ClientContractInfoEditView,
    ClientContractInfoView,
    CreateContractInfoView,
    UserAgreementsView,
    AgreementDeleteView,
    SmartViewView,
    FrameAgreementSmartViewView,
} from 'containers/index.js';

import CollectorView from 'containers/Application/AgreementView/CollectorView';
import CollectorsView from 'containers/Application/AgreementView/CollectorsView';
import FrameAgreementPricesView from 'containers/Application/FrameAgreement/FrameAgreementPricesView';
import FrameAgreementDisciplinesView from 'containers/Application/FrameAgreement/FrameAgreementDisciplinesView';
import CountryCCCView from 'containers/Application/CountryCCCView/CountryCCCView';

import GlobalAdminView from 'containers/Application/GlobalAdmin/GlobalAdminView';
import DisciplineGlobalConfigurationView from 'containers/Application/DisciplineGlobalConfigurationView/DisciplineGlobalConfigurationView';

import configureStore from './redux/create.js';

const client = new ApiClient(process.env.REACT_APP_API_URL, process.env.REACT_APP_API_SUBSCRIPTION_KEY),
  history = createHistory(),
  store = configureStore(client, history);

ReactDOM.render(
  <Provider store={ store }>
    <ConnectedRouter history={ history }>
      <Switch>
        <Application>
          <Switch>
              <Route exact path="/" component={ WelcomeView } store={ store } />
              <Route exact path="/createClient" component={ CreateClientView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/summary" component={ AgreementView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId" component={ AgreementView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/approve" component={ AgreementApproveView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/costs" component={ CostView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/costs/edit" component={ CostEditView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/pricelist" component={ PriceListView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/sla" component={ SLAView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/contents" component={ AgreementContentsView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/monitoring" component={ MonitoringView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/kitpack/:kitPackId" component={ KitPackEditView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/process" component={ CccProcessView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/comments" component={ AgreementCommentsView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/collectors" component={ CollectorsView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/collectors/:collectorId" component={ CollectorView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/delete" component={ AgreementDeleteView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/smartview" component={ SmartViewView } store={store} />
              <Route exact path="/client/:clientId" component={ ClientOverviewView } store={store} />
              <Route exact path="/client/:clientId/details" component={ ClientDetailsView } store={store} />
              <Route exact path="/client/:clientId/details/edit" component={ ClientDetailsEditView } store={store} />
              <Route exact path="/client/:clientId/createcontractInfo" component={ CreateContractInfoView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId" component={ ClientContractInfoView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/edit" component={ ClientContractInfoEditView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/createBuilding" component={ CreateBuildingView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/agreement/:agreementId/createBuilding" component={ CreateBuildingView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/building/:buildingId" component={ BuildingOverviewView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/building/:buildingId/details" component={ BuildingDetailsView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/building/:buildingId/details/edit" component={ BuildingDetailsEditView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/building/:buildingId/discipline/:disciplineId" component={ DisciplineView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/building/:buildingId/disciplines/add" component={ CreateDisciplineView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/building/:buildingId/disciplines/add/:disciplineCategory/:disciplineType" component={ CreateSubDisciplineView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/createFrameAgreement" component={ CreateFrameAgreementView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/frameagreement/:frameAgreementId/summary" component = { FrameAgreementView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/frameagreement/:frameAgreementId/disciplines" component = { FrameAgreementDisciplinesView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/frameagreement/:frameAgreementId/prices" component = { FrameAgreementPricesView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/frameagreement/:frameAgreementId" component = { FrameAgreementView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/frameagreement/:frameAgreementId/comments" component = { FrameAgreementCommentsView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/frameagreement/:frameAgreementId/sla" component = { FrameAgreementSLAView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/frameagreement/:frameAgreementId/contents" component={ FrameAgreementContentsView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/frameagreement/:frameAgreementId/approve" component={ AgreementApproveView } store={store} />
              <Route exact path="/client/:clientId/contractinfo/:contractInfoId/frameagreement/:frameAgreementId/smartview" component={ FrameAgreementSmartViewView } store={store} />
              <Route exact path="/globaladmin" component = { GlobalAdminView } store={store} />
              <Route exact path="/globaladmin/disciplineConfig" component = { DisciplineGlobalConfigurationView } store={store} />
              <Route exact path="/admin" component = { CountrySelectionView } store={store} />
              <Route exact path="/admin/:countryId" component = { CostCalculationConfigurationView } store={store} />
              <Route exact path="/admin/:countryId/disciplines" component = { DisciplineConfigurationView } store={store} />
              <Route exact path="/admin/:countryId/disciplines/:disciplineCategory/:disciplineType" component = { DisciplineTypeConfigurationView } store={store} />
              <Route exact path="/admin/:countryId/sla" component = { CountrySLAConfigurationView } store={store} />
              <Route exact path="/admin/:countryId/mainagreement" component = { CountryMainAgreementConfigurationView } store={store} />
              <Route exact path="/admin/:countryId/termsandconditions" component = { CountryTermsAndConditionsConfigurationView } store={store} />
              <Route exact path="/admin/:countryId/frameagreement" component = { CountryFrameAgreementConfigurationView } store={store} />
              <Route exact path="/admin/:countryId/frameagreement/disciplines" component = { CountryFrameAgreementDisciplinesConfigurationView } store={store} />
              <Route exact path="/admin/:countryId/departments" component = { DepartmentConfigurationView } store={store} />
              <Route exact path="/admin/:countryId/ccc" component = { CountryCCCView } store={store} />
              <Route exact path="/admin/:countryId/frameagreement/translations" component = { FrameAgreementTranslationView } store={store} />
              <Route exact path="/userinfo" component = { UserinfoView } store={store} />
              <Route exact path="/welcome" component={ WelcomeView } store={store} />
              <Route exact path="/search" component= { WelcomeView } store={store} />
              <Route exact path="/clients" component={ ClientList } store={store} />
              <Route exact path="/report" component={ ReportView } store={store} />
              <Route exact path="/myagreements" component={ UserAgreementsView } store={store} />
              <Route exact path="/*" component={ NotFound } status={ 404 } store={store} />
          </Switch>
        </Application>
      </Switch>
    </ConnectedRouter>
  </Provider>
  , document.getElementById('root')
);
