import React, { Component } from 'react';
import { axisRight, axisBottom } from 'd3-axis';
import { select } from 'd3-selection';

class Axis extends Component {
    constructor(props) {
        super(props);
        this.renderAxis = this.renderAxis.bind(this);
    }

    componentDidMount() {
        this.renderAxis();
    }

    componentDidUpdate() {
        this.renderAxis();
    }

    renderAxis() {
        const g = select(this.node);
        g.select('.chart-axis-x')
            .call(
                axisBottom(this.props.xScale)
                    .tickSize(0)
            );

        const yAxis = g.select('.chart-axis-y');
        yAxis.call(
            axisRight(this.props.yScale)
                .ticks(5, "s")
                .tickSize(this.props.width
                        + (this.props.margin ? this.props.margin.left : 0)
                )
        );
        yAxis.select('.domain')
            .remove();
        yAxis.selectAll('.tick text').attr('x', 4).attr('dy', -4);
    }

    render() {
        const {margin, height} = this.props;

        const xAxisTranslate = height ? 'translate(0,' + height + ')' : '';
        const yAxisTranslate = margin && margin.left ? 'translate(-' + margin.left + ',0)' : '';

        return (
            <g
                ref={node => {this.node = node}}
                className="chart-axis"
            >
                <g
                    className="chart-axis-x"
                    transform={xAxisTranslate}
                />
                <g
                    className="chart-axis-y"
                    transform={yAxisTranslate}
                />
            </g>
        );
    }
}

export default Axis;