import React, { Component }             from 'react';
import { colors }                       from 'styles/design.js';
import { CostNumber }                   from 'components';

import './SystemFormWrapper.css';

class SystemFormWrapper extends Component {
    handleClick = (e, handleToggle) => {
        e.preventDefault();
        handleToggle();
    }

    renderSystemTitle = ({ id, isValid, title, cost, handleToggle }) => {
        return (
            <a
                className="system-form-title"
                href=""
                id={ id }
                style={ isValid === true ? { backgroundColor: colors.lightGreen } : {} }
                onClick={ (e) => { this.handleClick(e, handleToggle) }}
            >
                <span className="system-form-title-title">{ title }</span>
                { typeof cost !== 'undefined' &&
                    <span className="system-form-title-cost" id={id + 'Sum'}><CostNumber>{ cost }</CostNumber></span>
                }
            </a>
        )
    }

    renderSystemBody = (children) => {
        return <div className="system-form-body">{ children }</div>;
    }

    render() {
        const { id, isValid, title, children, cost, isVisible, handleToggle } = this.props;

        return (
            <div className="system-form">
                { this.renderSystemTitle({ id, isValid, title, cost, handleToggle }) }
                { isVisible && this.renderSystemBody(children) }
            </div>
        );
    }
}

export default SystemFormWrapper;