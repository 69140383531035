import React, { Component } from 'react';
import translations         from 'decorators/translations.js';
import PropTypes            from 'prop-types';
import { Link }             from 'react-router-dom';

import { size, colors }     from 'styles/design.js';

/*
 * This component creates a blue plus-sign button with a translated text: "Add discipline"
*/

class SmallCreateDisciplineButton extends Component {

    static propTypes = {
        clientId: PropTypes.number.isRequired
    };
    getLink() {
        return '/client/' + this.props.clientId + '/contractInfo/' + this.props.contractInfoId + '/building/' + this.props.buildingId + '/disciplines/add';
    }
    render() {
        const { t } = this.props;
        return (
            <Link to={ this.getLink() } style = { this.props.style ? this.props.style : {} }>
               <div style={{width: '100%'}}>
                   <span
                       className="fa fa-2x fa-plus"
                       style={{
                           ...size.small,
                           color: colors.white,
                           textAlign: 'center',
                           width: '30px',
                           height: '30px',
                           borderRadius: '3px',
                           backgroundColor: colors.lightBlue,
                           lineHeight: '2em'
                       }}
                   />
                   <span style={{
                       ...size.tiny,
                       color: colors.blue,
                       paddingLeft: '0.8em'
                   }}> { t('add_discipline') } </span>
               </div>
            </Link>
        );
    }
}
export default translations(SmallCreateDisciplineButton);
