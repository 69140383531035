import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    loadFrameAgreement,
    saveFrameAgreement,
} from 'redux/modules/frameagreement.js';
import { isFrameAgreementEditable } from 'helpers/agreement';
import {
    loadClientContractInfos
} from 'redux/modules/client';
import {
    loadContractInfo,
    isContractInfoLoaded,
} from 'redux/modules/contractInfo';
import { loadWithType } from 'redux/modules/translations.js';
import {loadCountry} from 'redux/modules/country.js';
import isEmpty from 'lodash/isEmpty';
import {
    StandardPage,
    Header,
    BreadcrumbBar,
    BackLink,
    LoadingIndicator,
} from 'components';
import translations from 'decorators/translations.js';
import SmartViewForm from 'components/Agreement/SmartViewForm';
import SmartViewInformation from 'components/Agreement/SmartViewInformation';

const TRANSLATION_KEYS = [
    'frameagreement_s2_p2',
    'frameagreement_s2_p3',
    'frameagreement_s2_p4'
]
class FrameAgreementSmartViewView extends Component {
    state = {
        editMode: false,
        isSaving: false,
        isLoading: true,
    };

    componentDidMount() {
        this.loadContents();
    }

    componentDidUpdate(prevProps) {
        const params = this.props.match.params;
        const prevParams = prevProps.match.params;
        if (params.frameAgreementId !== prevParams.frameAgreementId) {
            this.loadContents();
        }
    }

    componentWillReceiveProps(nextProps) {
        const {
            match: {
                params,
            },
            profile,
            loadFrameAgreement,
        } = this.props;
        if (!nextProps.agreement) {
            return;
        }
        if (this.state.isSaving && nextProps.agreementState.reload) {
            loadFrameAgreement(params.frameAgreementId, profile.accessToken);
            this.setState({ isSaving: false });
            this.setState({ isLoading: true });
            window.scrollTo(0,0);
        } else if (this.state.isLoading && nextProps.agreementState.loaded) {
            this.setState({ isLoading: false, editMode: false });
        }
    }

    loadContents = async () => {
        const {
            match: {
                params
            },
            profile: {
                accessToken
            },
            loadFrameAgreement,
            loadContractInfo,
            loadCountry,
            loadTranslations,
        } = this.props;

        await loadFrameAgreement(params.frameAgreementId, this.props.profile.accessToken);
        const contractInfo = await loadContractInfo(params.contractInfoId, accessToken);
        const salesArea = contractInfo && contractInfo.result && contractInfo.result.salesArea;
        const country = await loadCountry(salesArea, accessToken);
        const defaultLanguage = country && country.result && country.result.defaultLanguage;
        const languages = ['en'];
        const lang = defaultLanguage || 'en';
        if (lang && lang !== 'en') {
            languages.push(lang);
        }
        await loadTranslations(2, languages.join(','), country.id, accessToken);
        this.setState({
            isLoading: false,
            language: lang
        });
    }

    handleSubmitAgreement = (data) => {
        const {
            match: {
                params,
            },
            profile,
            saveFrameAgreement,
        } = this.props;
        const agreementId = params.frameAgreementId;
        const newData = { ...data };
        delete newData.clientNetCustomPrice;
        newData.id = agreementId;
        const dispatchSave = saveFrameAgreement(newData, profile.accessToken);
        this.setState({ isSaving: true });
        return dispatchSave;
    };

    setEditable = newState => {
        this.setState({
            editMode: newState
        });
    };

    getContent = () => {
        const {
            agreement,
            client,
            countries,
        } = this.props;
        const {
            editMode,
            isLoading
        } = this.state;
        const printContent = this.getDescription();
        const isEditable = isFrameAgreementEditable(agreement);

        if ((isEmpty(agreement) || isEmpty(countries.selectedCountry)) && isLoading) {
            return <LoadingIndicator visible={true} />;
        } else if (editMode) {
            return (
                <SmartViewForm
                    cancelAction={ () => { this.setEditable(false); } }
                    agreement={ agreement }
                    onSubmit={ this.handleSubmitAgreement }
                    client={ client }
                    editing = { editMode }
                    countryConfig = { countries.selectedCountry }
                    submitting={ (this.state.isSaving || this.state.isLoading) }
                    printContent={ printContent }
                />
            );
        }
        return (
            <SmartViewInformation
                agreement={agreement}
                client={ client }
                countryConfig = { countries.selectedCountry }
                isEditable={isEditable}
                editClicked={ () => { this.setEditable(true); }}
                printContent={ printContent }
            />
        );
    };

    getDescription = () => {
        const {
            translatables
        } = this.props;
        if (!translatables) {
            return null;
        }
        const language = this.getAgreementLanguage();
        const printTexts = TRANSLATION_KEYS.map(key => {
            if (translatables && translatables[key]) {
                if (translatables[key][language]) {
                    return translatables[key][language];
                }
                else {
                    return translatables[key]['en-master'];
                }
            }
            return {
                disabled: true,
                value: ''
            };
        })
        if (!printTexts[0] && !printTexts[1] && !printTexts[2]) {
            return null;
        }

        return (
            <div>
                {!printTexts[0].disabled && <p>{ printTexts[0].value }</p>}
                <ul style={{ paddingLeft: '40px', listStyleType: 'disc', marginTop: '1em', marginBottom: '1em' }}>
                    {!printTexts[1].disabled && <li>{ printTexts[1].value }</li>}
                    {!printTexts[2].disabled && <li>{ printTexts[2].value }</li>}
                </ul>
            </div>
        )
    };

    getAgreementLanguage = () => {
        const {
            language,
            agreement,
            selectedCountry,
        } = this.props;
        if (agreement.language) {
            return agreement.language;
        }
        if (language === selectedCountry.defaultLanguage) {
            return language;
        }
        return 'en';
    };

    render() {
        const {
            agreement,
            contractInfo,
            match: {
                params
            },
            t,
        } = this.props;
        if (!agreement) {
            return null;
        }
        const {
            clientId,
            contractInfoId
        } = params;
        const basePath = `/client/${clientId}/contractInfo/${contractInfoId}/frameagreement/${agreement.id}`;

        return <StandardPage
            withBreadcrumb
            loaderVisible={agreement.loading}
        >
            <BreadcrumbBar
                page="frameagreement"
                agreement={agreement}
                contractInfo={contractInfo}
            />

            {agreement && <BackLink
                title={t('Back to agreement')}
                to={basePath}
            />}
            <Header title={ t('SmartView') } />
            {this.getContent()}
        </StandardPage>
    }
}

const mapStateToProps = state => ({
    agreementState: state.frameagreement,
    agreement: state.frameagreement.agreement,
    client: state.client,
    countries: state.countries,
    profile: state.userProfile,
    contractInfo: state.contractInfo,
    selectedCountry: state.countries.selectedCountry,
    language: state.translations.language,
    translatables: state.translations.translatables,
});

const mapDispatchToProps = dispatch => ({
    loadContractInfo: (id, token) => dispatch(loadContractInfo(id, token)),
    loadCountry: (salesArea, token) => dispatch(loadCountry(salesArea, token)),
    loadClientContractInfos: (clientId, accessToken) => dispatch(loadClientContractInfos(clientId, accessToken)),
    isContractInfoLoaded: (client, accessToken) => dispatch(isContractInfoLoaded(client, accessToken)),
    loadTranslations: (type, languages, countryId, accessToken) => dispatch(loadWithType(type, languages, countryId, accessToken)),
    loadFrameAgreement: (agreementId, accessToken) => dispatch(loadFrameAgreement(agreementId, accessToken)),
    saveFrameAgreement: (agreement, accessToken) => dispatch(saveFrameAgreement(agreement, accessToken))
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(translations(FrameAgreementSmartViewView));