import React, {Component}   from 'react';
import translations from 'decorators/translations.js';
import { connect }          from 'react-redux';
import configuredRadium     from 'configuredRadium.js';
import {
    loadKitPacks,
    saveKitPack,
    loadAHUTypes,
    deleteKitpack
}                           from 'redux/modules/agreement.js';
import _ from 'lodash';
import {
    KitPackForm,
    StandardPage,
    BreadcrumbBar
}                           from 'components/index.js';
import {
    measures,
    commonStyles,
    layout,
}                           from 'styles/design.js';

class KitPackEditView extends Component {
    componentWillMount() {
        const { dispatch, match: {params}} = this.props;
        const promiseArr = [];
        promiseArr.push(dispatch(loadAHUTypes(this.props.profile.accessToken)));
        if(_.isEmpty(this.props.kitpacks)) {
            promiseArr.push(dispatch(loadKitPacks(params.agreementId, this.props.profile.accessToken)));
        }
        return Promise.all(
            promiseArr
        );
    }
    componentWillReceiveProps(/*nextProps*/) {
        if (this.state.isSaving) {
            if (!this.props.agreement.kitpackError) {
                const {
                    match: {
                        params: {
                            clientId,
                            contractInfoId,
                            agreementId
                        }
                    }
                } = this.props;
                return this.props.history.push(`/client/${clientId}/contractInfo/${contractInfoId}/agreement/${agreementId}/monitoring`);
            }
            this.setState({isSaving: false});
        }
    }
    /*
    static fetchData(getState, dispatch, location, params) {
        const promiseArr = [];
        promiseArr.push(dispatch(loadAHUTypes(this.props.profile.accessToken)));
        promiseArr.push(loadClientData(dispatch, getState, params));
        if(!getState().kitpacks) {
            promiseArr.push(dispatch(loadKitPacks(params.agreementId, this.props.profile.accessToken)));
        }
        if(!getState().kitpackSystems) {
            promiseArr.push(dispatch(loadKitPackSystems(params.agreementId, this.props.profile.accessToken)));
        }
        return Promise.all(
            promiseArr
        );
    }*/

    handleCancel = () => {
        const {
            match: {
                params: {
                    clientId,
                    contractInfoId,
                    agreementId
                }
            }
        } = this.props;
        this.props.history.push(`/client/${clientId}/contractInfo/${contractInfoId}/agreement/${agreementId}/monitoring`);
    };

    handleSubmit = (data) => {
        const agreementId = this.props.match.params.agreementId;
        data.agreementId = agreementId;
        this.setState({isSaving: true});
        return this.props.dispatch(saveKitPack(data, this.props.profile.accessToken));
    };
    deleteKitpack = (id) => {
        const {
            profile,
            match: {
                params: {
                    clientId,
                    contractInfoId,
                    agreementId
                }
            }
        } = this.props;
        this.props.dispatch(deleteKitpack(id, profile.accessToken)).then(() => {
            this.props.dispatch(loadKitPacks(agreementId, this.props.profile.accessToken)).then(() => {
                this.props.history.push(`/client/${clientId}/contractInfo/${contractInfoId}/agreement/${agreementId}/monitoring`);
            });
        });
    };

    render() {
        const { t, kitpacks } = this.props;
        const kitpack = _.find(kitpacks, (kp, i) => {
            return kp.id.toString() === this.props.match.params.kitPackId;
        }) || {};

        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar />
                {/*
                <AgreementSubNavigation urlParams={this.props.match.params}  activeMenu="" />
                */}
                <header style={ [{ marginBottom: measures.standard}] }>
                    <h1 style={ [ commonStyles.pageTitle, layout.inlineBlock, layout.verticalMiddle, { paddingTop: measures.fatHalf }] }>{ t('kitpack') } 1</h1>
                </header>
                {
                    (() => {
                        if (!kitpack.id && (!this.props.kitPackSystems || this.props.kitPackSystems.length === 0)) {
                            return (<p>{t('no_ahu_options_error')}</p>);
                        }
                        return (<KitPackForm systems={ this.props.kitPackSystems }
                                             systemTypes={ this.props.ahuTypes }
                                             kitpack={ kitpack }
                                             disabled={!this.props.kitPackSystems || this.props.kitPackSystems.length === 0}
                                             onSubmit={ this.handleSubmit }
                                             handleCancel={ this.handleCancel }
                                             deleteKitpack={ this.deleteKitpack }
                                             submitting={ this.state.isSaving }/>);
                    })()
                }

            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        agreements: state.agreement.agreements,
        ahuTypes: state.agreement.ahuTypes,
        kitpacks: state.agreement.kitpacks,
        kitPackSystems: state.agreement.kitPackSystems,
        profile: state.userProfile,
        agreement: state.agreement
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(KitPackEditView)));
