import{ Component }         from 'react';

//import './ErrorHandler.css';

function urlencodeFormData(fd){
    var s = '';
    function encode(s){ return encodeURIComponent(s).replace(/%20/g,'+'); }
    for(var pair of fd.entries()){
        if(typeof pair[1] === 'string'){
            s += (s?'&':'') + encode(pair[0])+'='+encode(pair[1]);
        }
    }
    return s;
}

class ErrorHandler extends Component {

    componentWillMount() {
        window.onerror = (messageOrEvent, source, lineNumber, columnNumber, error) => {
            const http = new XMLHttpRequest();
            http.open("POST", '/error/message', true);
            http.setRequestHeader("Content-type", "application/x-www-form-urlencoded");

            http.onReadyStateChange = function() {
                if(http.readyState === 4 && http.status === 200) {
                    console.log('sent');
                } else {
                    console.log('readyState is', http.readyState);
                    console.log('status is', http.status);
                }
            };
            const formData = new FormData(document.createElement('form'));
            formData.append('message', messageOrEvent);
            formData.append('source', source);
            formData.append('lineNumber', lineNumber);
            formData.append('colNumber', columnNumber);
            formData.append('error', error);
            formData.append('windowLocation', window.location);
            formData.append('browser', navigator.userAgent);
            http.send(urlencodeFormData(formData));
            return true;
        };
    }

    render() {
        return null;
    }
}

export default ErrorHandler;
