import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';
import {getLanguage} from 'helpers/languageHelper';
import {isFrameAgreementEditable} from 'helpers/agreement';

import {
    loadClient,
    isClientLoaded,
    paymentTermOptions,
    loadClientContractInfos
} from 'redux/modules/client.js';

import {
    saveSignature,
    deleteSignature
} from 'redux/modules/signature';

import {
    loadFrameAgreement,
    shouldReloadFrameAgreement,
    saveFrameAgreement,
    deleteFrameAgreement,
    frameAgreementStatusUpdate,
    sendForApproval as frameAgreementSendForApproval,
    revokeApproval as frameAgreementRevokeApproval,
} from 'redux/modules/frameagreement.js';

import {
    loadCountryDepartments,
    shouldLoadCountryDepartments
} from 'redux/modules/department.js';

import {
    uploadFile
} from 'redux/modules/files.js';

import { copyFrameAgreement } from 'redux/modules/copying';

import {
    loadCountry,
    isCountryLoaded
} from 'redux/modules/country.js';

import {
    updateAttachment,
    deleteAttachment
} from 'redux/modules/attachment.js';

import _ from 'lodash';

import FrameAgreementContentList from 'components/FrameAgreement/FrameAgreementContentList';
import FrameAgreementDetailsForm from 'components/FrameAgreement/FrameAgreementDetailsForm';
import FrameAgreementDetails from 'components/FrameAgreement/FrameAgreementDetails';
import AgreementSignatures from 'components/AgreementSignatures/AgreementSignatures';

import {
    AgreementTimestampList,
    StandardPage,
    BreadcrumbBar,
    FrameAgreementApprovalBar,
    ConfirmableButton,
    Header,
    Button,
    SendAgreementToApproval,
    BackLink,
    Section,
    CopyAgreementComponent,
    ButtonGroup,
    AgreementAttachments,
} from 'components';
import { PrintingView } from 'containers';

import {
    isContractInfoLoaded,
    loadContractInfo,
    loadContractInfos,
} from 'redux/modules/contractInfo';

import {
    download
} from 'redux/modules/download';
import {
    commonStyles,
    colors,
    measures
} from 'styles/design.js';
import { loadCountries } from 'redux/modules/country.js';

class FrameAgreementView extends Component {
    state = {form: {}};

    componentDidMount() {
        const {match: {params}} = this.props;
        this.props.loadClientContractInfos(params.clientId, this.props.profile.accessToken);
        this.props.loadCountries(this.props.profile.accessToken);
        if (!isContractInfoLoaded(this.props, params.contractInfoId)) {
            this.props.loadContractInfo(params.contractInfoId, this.props.profile.accessToken).then((res) => {
                this.loadCountryAndDepartments()
            });
        } else {
            this.loadCountryAndDepartments();
        }
        if (shouldReloadFrameAgreement(this.props, params.frameAgreementId)) {
            this.refreshAgreement();
        }

    }

    refreshAgreement = () => {
        const {match: {params}} = this.props;
        this.props.loadFrameAgreement(params.frameAgreementId, this.props.profile.accessToken);
    }

    loadCountryAndDepartments() {
        const contractInfo = this.props.contractInfo.contractInfo;
        if (contractInfo && !this.props.countries.loading && !isCountryLoaded(this.props, contractInfo.salesArea)) {
            this.props.loadCountry(contractInfo.salesArea, this.props.profile.accessToken);
        }
        if (contractInfo && !this.props.department.loading && shouldLoadCountryDepartments(this.props, contractInfo.salesArea)) {
            this.props.loadCountryDepartments(contractInfo.salesArea, this.props.profile.accessToken);
        }
    }

    toggleEdit = () => {
        this.setState((state) => ({...state, editAgreement: true}));
    };

    downloadFile = () => {
        const printoutLanguage = getLanguage(this.props.translations.language);
        this.openPrintingDialog({ printoutLanguage });
    };

    deleteFrameAgreement = () => {
        this.props.deleteFrameAgreement(this.props.frameAgreement.agreement.id, this.props.profile.accessToken).then((res) => {
            if (res.result) {
                this.props.history.push('/client/' + this.props.match.params.clientId);
            }
        })
    };
    submitAgreement = (form) => {
        form.id = this.props.frameAgreement.agreement.id;
        this.props.saveFrameAgreement(form, this.props.profile.accessToken).then((res) => {
            if (res.result) {
                this.setState((state) => ({...state, editAgreement: false}));
            }
        })
    }
    cancelEdit = () => {
        this.setState((state) => ({...state, editAgreement: false, form: null}))
    }

    statusUpdate = (status) => {
        this.props.frameAgreementStatusUpdate(this.props.frameAgreement.agreement.id, status, this.props.profile.accessToken).then((res) => {
            if (res.result) {
                this.refreshAgreement();
            }
        })
    };
    sendApprovalRequest = (email) => {
        const agreementId = _.get(this.props, 'match.params.frameAgreementId', null);
        if (agreementId) {
            return this.props.sendApprovalRequest(agreementId, email, this.props.profile.accessToken).then((res) => {
                if (res.result) {
                    return this.refreshAgreement();
                }
            })
        }
    }
    revokeApprovalRequest = () => {
        const agreementId = _.get(this.props, 'match.params.frameAgreementId', null);
        if (agreementId) {
            return this.props.revokeApprovalRequest(agreementId, this.props.profile.accessToken).then((res) => {
                if (res.result) {
                    return this.refreshAgreement();
                }
            })
        }
    }

    saveSignature = signature => {
        const agreement = this.props.frameAgreement.agreement;
        return this.props.saveSignature({ ...signature, frameAgreementId: agreement.id }, this.props.profile.accessToken);
    }
    deleteSignature = signatureId => {
        this.props.deleteSignature(signatureId, this.props.profile.accessToken)
            .then( res => {
                if (!res.error) {
                    this.refreshAgreement();
                };
            });
    }

    copyAgreement = targetContractInfoId => {
        const contractInfo = this.props.contractInfo.contractInfo;
        const agreementId = _.get(this.props, 'match.params.frameAgreementId', null);
        this.props.copyFrameAgreement(agreementId, targetContractInfoId, this.props.profile.accessToken)
            .then( res => {
                if (res.result) {
                    this.props.history.push(`/client/${contractInfo.clientId}`)
                }
            });
    };

    openPrintingDialog = ({ printoutLanguage }) => {
        this.setState({
            printoutLanguage: !_.isNil(printoutLanguage) ? printoutLanguage : 'en',
            printingDialog: true,
        });
    };

    closePrintingDialog = () => {
        this.setState({
            printoutLanguage: '',
            printingDialog: false,
        });
    };

    loadCountryDepartments = id => {
        return this.props.loadCountryDepartments(id, this.props.profile.accessToken);
    };

    loadContractInfos = filters => {
        return this.props.loadContractInfos(filters, this.props.profile.accessToken);
    };

    submitAttachments = (attachments) => {
        const agreement = this.props.agreementState.agreement;
        agreement.attachments = attachments;
        const promises = [];
        attachments.map((attachment) => {
            attachment.frameAgreementId = _.get(this.props, 'match.params.frameAgreementId', null);
            promises.push(this.props.updateAttachment(attachment, this.props.profile.accessToken));
        });
        return Promise.all(promises);
    };
    deleteAttachment = (attachment) => {
        return this.props.deleteAttachment(attachment, this.props.profile.accessToken);
    };

    updateAttachment = (attachment) => {
        attachment.frameAgreementId = _.get(this.props, 'match.params.frameAgreementId', null);
        return this.props.updateAttachment(attachment, this.props.profile.accessToken);
    };

    uploadFile = (file, filename) => {
        const agreementId = _.get(this.props, 'match.params.frameAgreementId', null);
        return this.props.uploadFile(
            'attachments',
            'frameagreement-' + agreementId,
            file,
            filename,
            this.props.profile.accessToken
        );
    };

    render() {
        const {t, match: { params }, countries} = this.props;
        const agreement = this.props.frameAgreement.agreement;
        const contractInfo = this.props.contractInfo.contractInfo;
        const isEditable = isFrameAgreementEditable(this.props.frameAgreement.agreement);
        const customerName = _.get(contractInfo, 'client.name') || '';
        const caverionSupplierName = _.get(countries, 'selectedCountry.caverionSupplierName');
        const caverionName = 'Caverion ' + caverionSupplierName;
        const agreementCountry = _.get(countries, 'selectedCountry.id');
        const language = agreement.language || this.state.printoutLanguage

        if (this.props.frameAgreement.loading || this.props.client.loading || this.props.countries.loading) {
            return (
                <StandardPage
                    withBreadcrumb
                    withSubNavigation
                    loaderVisible={true}
                >
                    <div/>
                </StandardPage>
            );
        } else if (!agreement) {
            return (
                <div style={{marginLeft: 'auto', marginRight: 'auto', width: '20em'}}>
                    <h2>
                        {t('agreement_not_found')}
                    </h2>
                </div>
            );
        }

        return (
            <StandardPage
                loaderVisible={this.props.frameAgreement.loading || this.props.frameAgreement.saving}
                withBreadcrumb
            >
                <BreadcrumbBar
                    page="frameagreement"
                    contractInfo={this.props.contractInfo.contractInfo}
                    agreement={this.props.frameAgreement.agreement}
                />
                { params.clientId &&
                    <BackLink title={t('Back to client')}
                        to={`/client/${params.clientId}/`}/>
                }
                <div style={{
                    ...commonStyles.buttonHeaderContainer,
                    marginTop: '0.25em',
                    marginBottom: '2em',
                    float: 'right'
                }}>
                    {
                        agreement.agreementStatus === 'lost' &&
                        <ConfirmableButton
                            style={{backgroundColor: colors.orange}}
                            action={() => this.statusUpdate('unlost')}
                            confirmText={t('confirm_unset_lost')}
                            buttonText={t('unset_lost')}
                            showLoading={this.props.frameAgreement.updating}
                        />
                    }
                    {
                        agreement.agreementStatus !== 'lost' &&
                        <ConfirmableButton
                            style={{backgroundColor: colors.orange}}
                            disabled={agreement.agreementStatus === 'signed'}
                            action={() => this.statusUpdate('lost')}
                            confirmText={t('confirm_set_lost')}
                            buttonText={t('set_lost')}
                            showLoading={this.props.frameAgreement.updating}
                        />
                    }

                    <div style={{marginLeft: measures.standard}}>
                        <Button onClick={this.downloadFile} loading={this.props.download.loading}
                                disabled={this.props.download.loading}>
                            <i className="fa fa-print"/> {t('print')}
                        </Button>
                    </div>
                </div>
                <Header title={t('frame_agreement')} badge={{type: agreement.agreementStatus}}>
                    <AgreementTimestampList
                        showComment={true}
                        agreement={agreement}
                        t={t}
                    />
                </Header>
                <FrameAgreementApprovalBar
                    user={this.props.profile.user}
                    agreement={this.props.frameAgreement.agreement}
                    t={t}
                    push={this.props.history.push}
                    clientId={contractInfo.clientId}
                />

                <div style={{marginBottom: measures.standard}}>
                    {
                        (() => {
                            if (this.state.editAgreement) {
                                return <FrameAgreementDetailsForm
                                    cancelEdit={this.cancelEdit}
                                    submitAgreement={this.submitAgreement}
                                    saving={this.props.frameAgreement.saving}
                                    agreement={agreement}
                                    t={t}
                                    client={this.props.client.client}
                                    departments={this.props.department.countryDepartments}
                                    departmentsLoading={this.props.department.loading}
                                    paymentTermOptions={paymentTermOptions}
                                    selectedCountry={this.props.countries.selectedCountry}
                                />;
                            }
                            return <FrameAgreementDetails
                                toggleEdit={this.toggleEdit}
                                t={t}
                                agreement={agreement}
                                departments={this.props.department.countryDepartments}
                                departmentsLoading={this.props.department.loading}
                                isEditable={isEditable}
                            />;
                        })()
                    }

                </div>
                <FrameAgreementContentList
                    isEditable={isEditable}
                    params={this.props.match.params}
                    frameAgreement={this.props.frameAgreement.agreement}
                    t={t}
                />

                <Section title={ t('Attachments') }>
                    <AgreementAttachments
                        getAgreements={this.refreshAgreement}
                        agreement={agreement}
                        submitAttachments={this.submitAttachments}
                        uploadFile={this.uploadFile}
                        deleteAttachment={this.deleteAttachment}
                        updateAttachment={this.updateAttachment}
                        isEditable={isEditable}
                    />
                </Section>

                <Section title={ t('Signatures') }>
                    <AgreementSignatures
                        signatures={agreement.signatures || []}
                        t={ t }
                        isEditable={isEditable}
                        deleteSignature={this.deleteSignature}
                        saveSignature={this.saveSignature}
                        saving={this.props.signature.saving}
                        error={this.props.signature.error}
                        customerName={customerName}
                        caverionName={caverionName}
                        refreshAgreement={this.refreshAgreement}
                    />
                </Section>

                <h2 style={{color: colors.blue, fontWeight: 'normal'}}>{t('Approval')}</h2>
                <ButtonGroup>
                    {countries && !_.isEmpty(countries.countries) && agreementCountry &&
                        <CopyAgreementComponent
                            agreement={agreement}
                            contractInfos={this.props.contractInfo.filteredContractInfos}
                            copyAgreement={this.copyAgreement}
                            error={this.props.copying.error}
                            countries={this.props.countries}
                            userProfile={this.props.userProfile}
                            departments={this.props.department}
                            loadCountryDepartments={this.loadCountryDepartments}
                            loadContractInfos={this.loadContractInfos}
                            agreementCountry={agreementCountry}
                        />
                    }
                    {agreement && agreement.agreementStatus && agreement.agreementStatus.search('draft|sent_for_approval|rejected') > -1 &&
                        <SendAgreementToApproval
                            revoke={this.revokeApprovalRequest}
                            send={this.sendApprovalRequest}
                            agreement={agreement}
                            loading={this.props.frameAgreement.loading}
                        />}
                    {agreement.agreementStatus === 'approved' &&
                        <ConfirmableButton
                            style={{backgroundColor: colors.orange}}
                            buttonText={t('set_agreement_signed')}
                            confirmButtonText={this.props.t('yes')}
                            confirmText={this.props.t('are_you_sure')}
                            action={() => this.statusUpdate('sign')}
                        />}
                    {agreement.agreementStatus === 'approved' && <ConfirmableButton
                        style={{backgroundColor: colors.brightRed}}
                        action={ () => this.statusUpdate('unapprove')}
                        confirmText={t('confirm_unapprove')}
                        buttonText={t('unapprove_agreement')}
                    />}
                    <ConfirmableButton
                        style={{backgroundColor: colors.brightRed}}
                        buttonIcon={'fa fa-trash'}
                        buttonText={t('Delete agreement')}
                        confirmButtonText={this.props.t('yes')}
                        confirmText={this.props.t('confirm_delete_agreement')}
                        action={this.deleteFrameAgreement}
                        showLoading={this.props.frameAgreement.updating}
                    />
                </ButtonGroup>
                {this.state.printingDialog &&
                    <PrintingView
                        frameAgreementId={ agreement.id}
                        closeAction={ this.closePrintingDialog }
                        lang={ language }
                        printoutType={ this.state.printoutType }
                    />
                }
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client,
        countries: state.countries,
        frameAgreement: state.frameagreement,
        profile: state.userProfile,
        department: state.department,
        download: state.download,
        contractInfo: state.contractInfo,
        signature: state.signature,
        copying: state.copying,
        userProfile: state.userProfile.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        downloadFile: (downloadType, type, id, appendix, lang, accessToken, clientName) => dispatch(download({
            downloadType,
            type,
            id,
            appendix,
            lang,
            clientName
        }, accessToken)),
        deleteFrameAgreement: (id, accessToken) => dispatch(deleteFrameAgreement(id, accessToken)),
        loadClient: (clientId, accessToken) => dispatch(loadClient(clientId, accessToken)),
        loadContractInfo: (contractInfoId, accessToken) => dispatch(loadContractInfo(contractInfoId, accessToken)),
        loadCountry: (salesArea, accessToken) => dispatch(loadCountry(salesArea, accessToken)),
        loadCountryDepartments: (salesArea, accessToken) => dispatch(loadCountryDepartments(salesArea, accessToken)),
        loadFrameAgreement: (agreementId, accessToken) => dispatch(loadFrameAgreement(agreementId, accessToken)),
        saveFrameAgreement: (agreement, accessToken) => dispatch(saveFrameAgreement(agreement, accessToken)),
        frameAgreementStatusUpdate: (id, status, accessToken) => dispatch(frameAgreementStatusUpdate(id, status, null, accessToken)),
        sendApprovalRequest: (agreementId, email, accessToken) => dispatch(frameAgreementSendForApproval(agreementId, email, accessToken)),
        revokeApprovalRequest: (agreementId, accessToken) => dispatch(frameAgreementRevokeApproval(agreementId, accessToken)),
        saveSignature: (signature, accessToken) => dispatch(saveSignature(signature, accessToken)),
        deleteSignature: (id, accessToken) => dispatch(deleteSignature(id, accessToken)),
        copyFrameAgreement: (id, targetContractInfoId, accessToken) => dispatch(copyFrameAgreement(id, targetContractInfoId, accessToken)),
        loadClientContractInfos: (clientId, accessToken) => dispatch(loadClientContractInfos(clientId, accessToken)),
        loadContractInfos: (filters, accessToken) => dispatch(loadContractInfos(filters, accessToken)),
        loadCountries: (accessToken) => dispatch(loadCountries(accessToken)),
        uploadFile: (share, dir, file, filename, accessToken) => dispatch(uploadFile(share, dir, file, filename, accessToken)),
        updateAttachment: (attachment, accessToken) => dispatch(updateAttachment(attachment, accessToken)),
        deleteAttachment: (attachment, accessToken) => dispatch(deleteAttachment(attachment, accessToken)),
    }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
export default translations(connector(FrameAgreementView));
