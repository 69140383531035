const LOAD       = 'ServiFlex/Process/LOAD',
    LOAD_SUCCESS = 'ServiFlex/Process/LOAD_SUCCESS',
    LOAD_FAIL    = 'ServiFlex/Process/LOAD_FAIL',
    SAVE_DATA       = 'ServiFlex/Process/SAVE_DATA',
    SAVE_DATA_SUCCESS = 'ServiFlex/Process/SAVE_DATA_SUCCESS',
    SAVE_DATA_FAIL    = 'ServiFlex/Process/SAVE_DATA_FAIL',
    LOAD_CONFIG = 'ServiFlex/Process/LOAD_CONFIG',
    LOAD_CONFIG_SUCCESS = 'ServiFlex/Process/LOAD_CONFIG_SUCCESS',
    LOAD_CONFIG_FAIL = 'ServiFlex/Process/LOAD_CONFIG_FAIL',
    SEND       = 'ServiFlex/Process/SEND',
    SEND_SUCCESS = 'ServiFlex/Process/SEND_SUCCESS',
    SEND_FAIL    = 'ServiFlex/Process/SEND_FAIL';

const initialState = {
    loading: false,
    data: {},
    loaded: false,
    reload: true,
    saving: false,
    config: [],
    sending: false,
    sent: false,
    sendResult: null
};

export default function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case LOAD:
            return {
                ...state,
                loading: true
            };
        case LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                reload: false,
                data: action.result
            };
        case LOAD_FAIL:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: action.error
            };
        case SAVE_DATA:
            return {
                ...state,
                saving: true
            };
        case SAVE_DATA_SUCCESS:
            return {
                ...state,
                saving: false,
                saved: true,
                result: action.result
            };
        case SAVE_DATA_FAIL:
            return {
                ...state,
                saving: false,
                saved: false,
                error: action.error
            };
        case LOAD_CONFIG:
            return {
                ...state
            };
        case LOAD_CONFIG_SUCCESS:
            return {
                ...state,
                config: action.result
            };
        case LOAD_CONFIG_FAIL:
            return {
                ...state,
                error: action.error
            };
        case SEND:
            return {
                ...state,
                sending: true
            };
        case SEND_SUCCESS:
            return {
                ...state,
                sending: false,
                sent: true,
                sendResult: action.result
            };
        case SEND_FAIL:
            return {
                ...state,
                sending: false,
                sent: false,
                error: action.error
            };
        default:
            return state;
    }
}

export function loadAgreement(uuidToken) {
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: (client) => client.get('/CccProcesses/loadAgreement', { accessToken: uuidToken }
        )
    };
}

export function saveData(data, agreementId, step, uuidToken) {
    return {
        types: [SAVE_DATA, SAVE_DATA_SUCCESS, SAVE_DATA_FAIL],
        promise: (client) => client.post('/CccProcesses/' + agreementId + '/' + step + '/save', {data, accessToken: uuidToken})
    };
}

export function loadConfig(uuidToken) {
    return {
        types: [LOAD_CONFIG, LOAD_CONFIG_SUCCESS, LOAD_CONFIG_FAIL],
        promise: (client) => client.get('/CccProcesses/config', {accessToken: uuidToken})
    };
}

export function sendForward(data, agreementId, step, uuidToken) {
    return {
        types: [SEND, SEND_SUCCESS, SEND_FAIL],
        promise: (client) => client.post('/CccProcesses/' + agreementId + '/' + step + '/submit', {data, accessToken: uuidToken})
    };
}
