import React from 'react';
import { Link } from 'react-router-dom';
import './Card.css';

/**
 * Card UI component
 */
const Card = ({to, title, ctas, children, id}) => {
    // Title part
    let cardTitle;
    if (title) {
        cardTitle = (
            <div className="card-title">
                {title}
            </div>
        );
    }

    // Body part
    const cardBody = (
        <div className="card-body">
            <div>
                {children}
            </div>
        </div>
    );

    // Card actions
    let cardCtas;
    if (ctas && ctas.length > 0) {
        cardCtas = (
            <div className="card-ctas">
                {
                    ctas.map((cta, idx) => {
                        if (cta.to) {
                            return <Link id={id + '_cta_' + idx} key={'card' + idx} to={cta.to} className="card-cta">{cta.title}</Link>;
                        }
                        else if (cta.onClick) {
                            return <button id={id + '_cta_' + idx} key={'card' + idx} className="card-cta" onClick={cta.onClick}>{cta.title}</button>
                        }
                        return <span id={id + '_cta_' + idx} key={'card' + idx} className="card-cta">{cta.title}</span>;
                    })
                }
            </div>
        );
    }

    // Render card
    if (to) {
        return (
            <Link
                id={id}
                to={to}
                className="card"
            >
                {cardTitle}
                {cardBody}
                {cardCtas}
            </Link>
        )
    }
    return (
        <div id={id} className="card">
            {cardTitle}
            {cardBody}
            {cardCtas}
        </div>
    );
};

export default Card;