import React, {Component}   from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import { reduxForm }                    from 'redux-form';
import _                                from 'lodash';
import { numberFormat } from 'helpers/stringTemplates.js';
import {
    SFInputField,
    SFSelectField,
    SFTextareaField,
    Button,
    CostNumber,
}                                       from 'components';

import {
    layout,
    measures,
    commonStyles
}                                       from 'styles/design.js';

const totalRow = { lineHeight: '4em ', fontWeight: 'bold' };
const totalRowValue = { ...layout.floatLeft, ...layout.inlineBlock };
const totalRowLabel = { ...layout.floatRight, ...layout.inlineBlock };

const reduxFormObject = {
    form: 'countryConfigForm',
    fields: [
        'id',
        'projectMargin',
        'currency',
        'exchangeRate',
        'defaultLanguage',
        'salesOrganization',
        'caverionSupplierName',
        'caverionCompanyRegNo',
        'addOnLimit',
        'costCalculation.salary',
        'costCalculation.socialCost',
        'costCalculation.projectLeader',
        'costCalculation.serviceCar',
        'costCalculation.tools',
        'costCalculation.riskFactor',
        'costCalculation.customValues[].name',
        'costCalculation.customValues[].value',
        'bronzeServiceLevelDescription',
        'silverServiceLevelDescription',
        'goldServiceLevelDescription',
        'vat',
        'subcontractingMinMargin',
        'nationalPhoneNumber',
        'clientNetDefaultPrice',
        'costCalculation.smartViewPricing[].name',
        'costCalculation.smartViewPricing[].nameEnglish',
        'costCalculation.smartViewPricing[].price',
        'costCalculation.smartViewPricing[].priceType',
        'costCalculation.smartViewPricing[].priceTarget',
    ],
};
class CountryConfigForm extends Component {

    static propTypes = {
        countryConfig: PropTypes.object.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        cancelAction: PropTypes.func.isRequired,
        submitting: PropTypes.bool.isRequired,
        languages: PropTypes.array.isRequired,
    }

    componentWillMount() {
        this.props.initializeForm(this.props.countryConfig);
    }

    componentWillUnmount() {
        this.props.destroyForm();
    }

    countHourlyPrice(costPerHour, marginPercentage) {
        //return numberFormat((costPerHour && marginPercentage) ? costPerHour * (1 + (marginPercentage / 100)) : 0);
        return numberFormat((costPerHour && marginPercentage) ? costPerHour / (1 - (marginPercentage / 100)) : 0);
    }

    handleCancel = () => {
        this.props.initializeForm(this.props.countryConfig);
        this.props.cancelAction();
    }

    render() {
        const { t, fields, values, countryConfig } = this.props;
        const costCalc = values.costCalculation;
        let costPerHour = _.sum(
            (
            [
                costCalc.projectLeader ? parseFloat(costCalc.projectLeader) : 0,
                costCalc.salary ? parseFloat(costCalc.salary) : 0,
                costCalc.serviceCar ? parseFloat(costCalc.serviceCar) : 0,
                costCalc.socialCost ? parseFloat(costCalc.socialCost) : 0,
                costCalc.tools ? parseFloat(costCalc.tools) : 0,
                costCalc.riskFactor ? parseFloat(costCalc.riskFactor) : 0
            ]
        ));
        if(costCalc.customValues.length > 0) {
            costPerHour += _.sum(
                costCalc.customValues.map((customValue) => {
                    if(customValue.value) {
                        return parseFloat(customValue.value);
                    }
                    return 0;
                })
            );
        }
        const hourlyPrice = this.countHourlyPrice(costPerHour, fields.projectMargin.value);
        return (
            <form onSubmit={ this.props.handleSubmit }>
                <input type="hidden" { ...fields.id } />
                <SFSelectField oneline formProperty= { fields.currency } fieldName={ t('currency') } selectWidth="6">
                    <option>{ t('EUR') }</option>
                    <option>{ t('SEK') }</option>
                    <option>{ t('DKK') }</option>
                    <option>{ t('NOK') }</option>
                </SFSelectField>
                <SFInputField oneline type="number" formProperty={ fields.exchangeRate } fieldName={ t('Exchange rate') } placeholder=" " maxLength="10" />
                <SFSelectField oneline formProperty={ fields.defaultLanguage } fieldName={ t('default_language') } selectWidth={ 10 }>
                    <option />
                { (() => {
                    return this.props.languages.map((lang) => {
                        return (<option key={ lang } value={ lang }>{ t(lang) }</option>);
                    });
                })()}
                </SFSelectField>
                <SFInputField oneline formProperty={ fields.salesOrganization } fieldName={ t('sales_organization') } placeholder=" " maxLength="8" />
                <SFInputField formProperty={ fields.nationalPhoneNumber } fieldName={ t('national_phone_number') } />
                <SFInputField formProperty={ fields.caverionSupplierName } fieldName={ t('caverion_unit_in_agreement') } />
                <SFInputField formProperty={ fields.caverionCompanyRegNo } fieldName={ t('company_reg_num_in_agreement') } />
                <div style = {{ marginTop: measures.double, marginBottom: measures.quadruple }}>
                    <h2 style = {{marginBottom: measures.double}}>{ t('cost_calculation') }</h2>
                    <SFInputField oneline formProperty={ fields.costCalculation.salary } fieldName={ t('salary') } placeholder="0,00" maxLength="6" type="number" />
                    <SFInputField oneline formProperty={ fields.costCalculation.socialCost } fieldName={ t('social_cost') } placeholder="0,00" maxLength="6" />
                    <SFInputField oneline formProperty={ fields.costCalculation.projectLeader } fieldName={ t('project_leader') } placeholder="0,00" maxLength="6" />
                    <SFInputField oneline formProperty={ fields.costCalculation.serviceCar } fieldName={ t('service_car') } placeholder="0,00" maxLength="6" />
                    <SFInputField oneline formProperty={ fields.costCalculation.tools } fieldName={ t('tools') } placeholder="0,00" maxLength="6" />
                    <SFInputField oneline formProperty={ fields.costCalculation.riskFactor } fieldName={ t('risk_factor') } placeholder="0,00" maxLength="6" />
                    <h3 style = {{marginBottom: measures.double}}>{ t('cost_calculation_customs') }</h3>
                    { (() => {
                        if (!fields.costCalculation.customValues) return (<div />);
                        return fields.costCalculation.customValues.map((customValue, index) => {
                            return (
                                <div key = { index } style = {{ height: '4em'}}>
                                    <div style = {layout.inlineBlock}>
                                        <SFInputField oneline formProperty={ customValue.name } placeholder={ t('custom_name') } />
                                    </div>
                                    <div style = {{ ...layout.inlineBlock, width: '1em', marginLeft: measures.standard }}>
                                        <span onClick = {() => { fields.costCalculation.customValues.removeField(index);}}><i className="fa fa-trash-o"></i></span>
                                    </div>
                                    <div style = {{ ...layout.floatRight, width: '5em'}}>
                                        <SFInputField oneline formProperty={ customValue.value } placeholder=" 0,00" maxLength="6" />
                                    </div>
                                </div>
                            );
                        });
                    })()}
                    <a href="" style = { commonStyles.tinyButton } onClick= {(event) => { fields.costCalculation.customValues.addField(); event.preventDefault(); event.stopPropagation(); }}>{t('add_custom_value')}</a>
                    <div style = { totalRow } >
                        <div style = { totalRowValue }>{ t('cost_per_hour') }:</div>
                        <div style = { totalRowLabel }>
                            <CostNumber format={ '0,0.00' }>{ costPerHour }</CostNumber>
                        </div>
                    </div>
                    <SFInputField oneline formProperty= { fields.projectMargin } fieldName={ t('project_margin') + ' (%)' } placeholder="0,00" maxLength="6" />
                    <SFInputField oneline formProperty= { fields.vat } fieldName={ 'VAT (%)' } placeholder="0" maxLength="7" />
                    <div style = { totalRow } >
                        <div style = { totalRowValue }>{ t('hourly_price') }:</div>
                        <div style = { totalRowLabel }>
                            <CostNumber>{ hourlyPrice }</CostNumber>
                        </div>
                    </div>
                </div>
                <div style = {{ marginTop: measures.quadruple}}>
                    <SFInputField oneline formProperty= { fields.addOnLimit } fieldName={ t('addon_limit') + ' (' + countryConfig.currency + ')' } placeholder="10000" />
                </div>
                <div style = {{ marginTop: measures.quadruple}}>
                    <h2>{ t('subcontracting_minimum_margin') }</h2>
                    <SFInputField oneline formProperty = { fields.subcontractingMinMargin } fieldName={t('subcontracting_minimum_margin') + ' %'} placeholder=" " maxLength="3" />
                </div>
                <div style = {{ marginTop: measures.quadruple}}>
                    <SFInputField oneline formProperty= { fields.clientNetDefaultPrice } fieldName={ `${t('SmartView yearly price')} (${countryConfig.currency})` } />
                </div>

                <div style={{marginTop: measures.quadruple}}>
                    <h2>{ t('SmartView pricing') }</h2>
                    {!fields.costCalculation.smartViewPricing ? null : _.map(fields.costCalculation.smartViewPricing, (pricing, index) => {
                        if (!pricing.priceType.value) {
                            pricing.priceType.onChange('monthly');
                        }
                        return <div style={{margin: '1rem 0', display: 'flex', flexFlow: 'row nowrap'}} key={`field${index}`}>
                            <div>
                                <SFInputField formProperty={pricing.name} fieldName={t('Label')} />
                            </div>
                            <div style={{marginLeft: measures.standard}}>
                                <SFInputField formProperty={pricing.nameEnglish} fieldName={`${t('Label')} (${t('en')})`} />
                            </div>
                            <div style={{marginLeft: measures.standard}}>
                                <div><SFInputField formProperty={pricing.price} fieldName={`${t('Price')} ${fields.currency.value}`} placeholder="0" /></div>
                            </div>
                            <div style={{marginLeft: measures.standard}}>
                                <SFSelectField formProperty={pricing.priceTarget} fieldName={t('Price target')}>
                                    <option value="building">{t('By building')}</option>
                                    <option value="agreement">{t('By agreement')}</option>
                                </SFSelectField>
                            </div>
                            <div style={{width: '1em', marginLeft: measures.standard, alignSelf: 'center'}}>
                                <span onClick = {() => { fields.costCalculation.smartViewPricing.removeField(index);}}><i className="fa fa-trash-o"></i></span>
                            </div>
                        </div>;
                    }

                    )}
                    <a href="" style = { commonStyles.tinyButton } onClick= {(event) => { fields.costCalculation.smartViewPricing.addField(); event.preventDefault(); event.stopPropagation(); }}>{t('Add SmartView price')}</a>
                </div>
                <div style = {{ marginTop: measures.quadruple}}>
                    <h2>{ t('service_level_descriptions') }</h2>
                    <SFTextareaField formProperty = { fields.bronzeServiceLevelDescription } fieldName = { t('service_level_description_bronze') } rows = { 4} />
                    <SFTextareaField formProperty = { fields.silverServiceLevelDescription } fieldName = { t('service_level_description_silver') } rows = { 4} />
                    <SFTextareaField formProperty = { fields.goldServiceLevelDescription } fieldName = { t('service_level_description_gold') } rows = { 4} />
                </div>
                <div style={{ ...layout.floatRight, ...layout.inlineBlock, clear: 'both', margin: '2em 0' }}>

                    <Button
                        variant="cancel"
                        id="CountryConfigForm_cancel"
                        onClick = { this.handleCancel }
                    >
                        {t('cancel')}
                    </Button>
                    <Button
                        id="CountryConfigForm_save"
                        onClick={ this.props.handleSubmit }
                        disabled={ this.props.submitting }
                        loading={ this.props.submitting }
                    >
                        {t('save')}
                    </Button>
                </div>
                <div style={{clear: 'both'}} />
            </form>
        );
    }
}

export default translations(reduxForm(reduxFormObject)(CountryConfigForm));
