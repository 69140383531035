export const getSmartViewTotalPrice = (agreement, smartViewPricing, clientNetCustomPrice) => {
  if (!agreement) {
      return 0;
  }
  if (!smartViewPricing || !smartViewPricing.length) {
    const {
      buildings
    } = agreement;
    if (!clientNetCustomPrice) {
      return 0;
    }
    const buildingCount = buildings ? buildings.length : 0;
    return buildingCount * (clientNetCustomPrice.value ? clientNetCustomPrice.value : clientNetCustomPrice);
  }
  const buildingCount = agreement.buildings ? agreement.buildings.length : 0;
  let perBuildingCosts = 0;
  let perAgreementCosts = 0;
  smartViewPricing.forEach(current => {
      if (!current.enabled || (typeof current.enabled === 'object' && !current.enabled.value) || !current.price || (typeof current.price === 'object' && !current.price.value)) {
          return;
      }
      if (current.priceTarget.value === 'agreement' || current.priceTarget === 'agreement') {
          perAgreementCosts = perAgreementCosts + (current.price.value ? current.price.value : current.price);
      }
      else {
          perBuildingCosts = perBuildingCosts + (current.price.value ? current.price.value : current.price);
      }
  });
  return (perBuildingCosts * buildingCount + perAgreementCosts);
};
