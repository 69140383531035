import React from 'react';
import translations from 'decorators/translations.js';
import configuredRadium from 'configuredRadium.js';
import {
    commonStyles,
    measures,
    colors,
    layout,
} from 'styles/design.js';
import _ from 'lodash';

const labelStyle = [
    layout.block,
    {
        width: '100%',
        marginBottom: measures.standard
    }
];

const validityIndicatorStyle = [
    layout.inlineBlock,
    layout.floatRight,
    layout.absolute,
    layout.verticalMiddle,
    {
        color: colors.basicGreen,
        lineHeight: '1.5em',
        fontSize: '150%',
        marginTop: '0.5em',
        marginLeft: '-1.25em'
    }
];
const invalidityIndicatorStyle = [
    layout.inlineBlock,
    layout.floatRight,
    layout.absolute,
    layout.verticalMiddle,
    {
        color: colors.basicRed,
        lineHeight: '1.5em',
        fontSize: '150%',
        marginTop: '0.5em',
        marginLeft: '-1.25em'
    }
];

const formInputStyle = [
    commonStyles.input,
    {
        paddingRight: '2em',
        marginTop: '0.5em',
        fontFamily: 'Arial'
    }
];

const mandatoryInputStyle = [
    {
        borderWidth: '2px',
    }
];

const inInvalidStateInputStyle = [
    {
        borderColor: colors.basicRed
    }
];

const validationErrorStyle = [
    {
        color: colors.basicRed
    }
];

const onelineInputStyle = [
    layout.textRight,
    layout.floatRight,
    {
        display: 'inline-block',
        marginTop: '0',
    }
];

const twoRowsInputStyle = [
    {
        width: '100%',
        display: 'block'
    }
];

const onelineLabelStyle = [
    layout.verticalMiddle,
    {
        display: 'inline-block',
        lineHeight: '2.5em',
        height: '2.5em',
    }
];

const twoRowsLabelStyle = [
    {
        paddingTop: measures.standard
    }
];

const SFTextareaField = (properties) => {
    const props = Object.assign({}, properties);
    if(!props.formProperty) {
        props.formProperty = {};
    }
    return (
        <label style={[labelStyle, props.oneline ? onelineLabelStyle : twoRowsLabelStyle]}>
            {
                (() => {
                    if (props.formProperty && (props.formProperty.error || props.error)) {
                        return <span style={validationErrorStyle}>{props.formProperty.error}</span>;
                    }
                    return props.fieldName;
                })()
            }
            {
                (() => {
                    if (props.defaultInput) {
                        return (
                            <textarea
                                id={props.id}
                                style={[
                                    formInputStyle,
                                    props.mandatory ? mandatoryInputStyle : '',
                                    props.oneline ? onelineInputStyle : twoRowsInputStyle,
                                    props.maxLength ? {width: (parseInt(props.maxLength, 10) + 2) + 'em'} : {}
                                ]}
                                rows={props.rows}
                                placeholder={props.placeholder ? props.placeholder : props.fieldName}
                                maxLength={props.maxLength}
                                onChange={props.onChange}
                                value={props.value || ''}
                                disabled={props.disabled}
                            />
                        );
                    }
                    return (
                        <textarea
                            id={props.id}
                            style={[
                                formInputStyle,
                                props.mandatory ? mandatoryInputStyle : '',
                                props.formProperty && props.formProperty.error ? inInvalidStateInputStyle : '',
                                props.oneline ? onelineInputStyle : twoRowsInputStyle,
                                props.maxLength ? {width: (parseInt(props.maxLength, 10) + 2) + 'em'} : {}
                            ]}
                            rows={props.rows}
                            placeholder={props.placeholder ? props.placeholder : props.fieldName}
                            maxLength={props.maxLength}
                            {...props.formProperty}
                            value={props.formProperty.value || ''}
                        />
                    );
                })()
            }

            {(() => {
                if (props.formProperty && (props.formProperty.error || props.error)) {
                    return <span style={invalidityIndicatorStyle}><i className="fa fa-exclamation"/></span>;
                } else if (props.formProperty && props.formProperty.touched && props.mandatory && !_.isEmpty(props.formProperty.value)) {
                    return <span style={validityIndicatorStyle}><i className="fa fa-check"/></span>;
                }
            })()
            }
        </label>
    );
};
export default translations(configuredRadium(SFTextareaField));
