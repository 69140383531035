import React, { Component }  from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import {
    measures,
    colors,
    layout
}                                       from 'styles/design.js';

const monthsContainerStyle = [
    {
        clear: 'both',
        width: '100%', // when 12 cols
        height: '4em',
        '@media only screen and (max-width: 47em)': {
            width: '100%',
            maxWidth: '32em', // when 6 cols
            height: '8em',
        },
        '@media only screen and (max-width: 36em)': {
            width: '100%',
            maxWidth: '16em', // when 3 cols
            height: '14em',
        },
        '@media only screen and (max-width: 320px)': {
            width: '100%', // when iphone 4 portrait size
        }
    }
];
const monthContainerStyle = [
    {
        float: 'left',
        width: '16.6%', // 6 cols
        '@media only screen and (min-width: 47em)': {
            width: '8.3%', // 12 cols
        },
        '@media only screen and (max-width: 36em)': {
            width: '33.3%', // 3 cols
        }
    }
];
const monthStyle = [
    layout.inlineBlock,
    layout.textCenter,
    {
        backgroundColor: colors.lightGray,
        fontSize: '0.8em',
        padding: measures.fatHalf,
        textDecoration: 'none',
        color: colors.blue,
        borderRadius: '2px',
        cursor: 'pointer',
        margin: measures.quarter,
        width: '3.75em',
        '@media only screen and (min-width: 48em)': {
            // make all months fit in one row when full sized screen
            padding: `${measures.fatHalf} ${measures.half} ${measures.fatHalf} ${measures.half}`,
        },
    }
];

const checkedStyle = [
    {
        backgroundColor: colors.blue,
        color: colors.white
    }
];

const selectedStyle = {
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: colors.lightTurquoise
};

const unSelectedStyle = {
    borderWidth: '3px',
    borderStyle: 'solid',
    borderColor: colors.white
};

const monthTranslations = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'];

class VisitPlanCalendar extends Component {

    static propTypes = {
        onMonthClickAction: PropTypes.func,
        visitPlan: PropTypes.array,
        showSelection: PropTypes.bool
    }

    onClickAction(index) {
        return () => {
            if (this.state.selectedMonthIndex === index) {
                this.setState({ selectedMonthIndex: undefined });
            } else {
                this.setState({ selectedMonthIndex: index });
            }
            if (this.props.onMonthClickAction) this.props.onMonthClickAction(index);
        };
    }

    render() {
        const visitPlan = this.props.visitPlan || [];
        const { t, showSelection } = this.props;
        return (
            <div id={this.props.id} style = { monthsContainerStyle }>
            {(() => {
                return monthTranslations.map((month, index) => {
                    return (
                        <div style={ monthContainerStyle } key={ 'monthContainer-' + index }>
                            <div
                                id={'VisitPlanCalendar_month_' + index}
                                onClick={ this.onClickAction(index).bind(this) }
                                key={ 'month-' + index }
                                style={ [ monthStyle, (showSelection && this.state.selectedMonthIndex === index) ? selectedStyle : unSelectedStyle, visitPlan[index] ? checkedStyle : []] }>
                                { visitPlan[index] } { t(month) }
                            </div>
                        </div>
                   );
                });
            })()}
            </div>
    );}
}
export default translations(configuredRadium(VisitPlanCalendar));
