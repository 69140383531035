import _ from 'lodash';
import {createReducerFromMapping} from 'redux/utils/index.js';

const SAVE = 'Signature/SAVE',
    SAVE_SUCCESS = 'Signature/SAVE_SUCCESS',
    SAVE_FAIL = 'Signature/SAVE_FAIL',
    DELETE = 'Signature/DELETE',
    DELETE_SUCCESS = 'Signature/DELETE_SUCCESS',
    DELETE_FAIL = 'Signature/DELETE_FAIL';

const initialState = {
    saving: false,
    saved: false,
    deleting: false,
    deleted: false,
    error: null,
    result: null,
};

export default createReducerFromMapping({

    [SAVE]: state => ({
                ...state,
                saving: true,
                error: null,
    }),
    [SAVE_SUCCESS]: (state, action) => ({
        ...state,
        saving: false,
        saved: true,
        result: action.result
    }),
    [SAVE_FAIL]: (state, action) => ({
        ...state,
        saving: false,
        saved: false,
        error: action.error

    }),
    [DELETE]: state => ({
        ...state,
        deleting: true,
        error: null
    }),
    [DELETE_SUCCESS]: (state, action) => ({
        ...state,
        deleting: false,
        deleted: true,
        result: action.result
    }),
    [DELETE_FAIL]: (state, action) => ({
        ...state,
        deleting: false,
        deleted: false,
        error: action.error
    })
});
export const saveSignature = (signature, accessToken) => {
    return {
        types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
        promise: client => client.patch(`/signatures/upsertSignature`, { data: signature, accessToken })
    }
}

export const deleteSignature = (signatureId, accessToken) => {
    return {
        types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
        promise: client => client.del(`/signatures/${signatureId}/deleteSignature`, { accessToken })
    }
}
