import React, {Component}                       from 'react';
import translations                             from 'decorators/translations.js';
import PropTypes                                from 'prop-types';
import {
    HoursOfServiceTable,
    HoursOfServiceEdit,
    SystemSegmentEditButton,
    SystemFormWrapper,
    SystemFormBody,
    SystemFormActions,
    Button
}                                               from 'components';
import {
    initializeNewKey,
    initializeExistingKey,
    getValues
}                                               from 'helpers/formHelper.js';
import _                                        from 'lodash';

const defaultKeys = [
    'description',
    'hours',
    'visits',
    'multiplier'
];
const idPrefix = "SystemHoursOfServiceContainer";

class SystemHoursOfServiceContainer extends Component {

    static propTypes = {
        serviceLevel: PropTypes.string.isRequired,
        system: PropTypes.object.isRequired,
        calculatedSystem: PropTypes.object,
        resetField : PropTypes.func.isRequired,
        handleCancel : PropTypes.func.isRequired,
        handleSave : PropTypes.func.isRequired,
        submitting : PropTypes.bool,
        isEditable: PropTypes.bool.isRequired
    };
    componentWillMount() {
        this.init(this.props.system);
    }

    componentWillReceiveProps(nextProps) {
        if(_.isEmpty(this.state.form) || (_.isEmpty(this.state.form.hoursOfService) && !_.isEmpty(nextProps.system.hoursOfService))) {
            this.init(nextProps.system);
        }
    }

    init(system) {
        const form = {
            hoursOfService: []
        };
        if(system.hoursOfService && _.isArray(system.hoursOfService)) {
            system.hoursOfService.map((hours, index) => {
                const obj = initializeExistingKey({keys: defaultKeys, values: hours, index, changeValue: this.changeValue});
                form.hoursOfService.push(obj);
            });
        }
        this.setState({form});
    }
    getForm() {
        return Object.assign({}, this.state.form);
    }
    changeValue = (value, index, key) => {
        const form = this.getForm();
        form.hoursOfService[index][key].value = value;
        if(['hours', 'visits', 'multiplier'].indexOf(key) !== -1) {
            const sys = Object.assign({}, this.props.system);
            if(!sys.hoursOfService) {
                sys.hoursOfService = [];
            }
            form.hoursOfService.map((hour, idx) => {
                const obj = getValues(hour);
                if(!sys.hoursOfService[idx]) {
                    sys.hoursOfService.push(obj);
                } else {
                    sys.hoursOfService[idx] = obj;
                }
            });
            this.props.calculateCosts(sys);
        }
        this.setState({form});
    };
    isDataValid(data) {
        return data && data.length > 0 && !this.props.editMode;
    }
    removeHours = (index) => {
        this.setState({removed: true});
        const form = this.getForm();
        form.hoursOfService.splice(index, 1);
        this.setState({form});
    };

    addHours = () => {
        const form = this.getForm();
        const newHour = initializeNewKey({defaultKeys, index: (form.hoursOfService.length), changeValue: this.changeValue});
        form.hoursOfService.push(newHour);
        this.setState({form});
    };
    cancel = () => {
        this.init(this.props.system);
        this.props.handleCancel();
    };
    handleSave = () => {
        const form = this.getForm();
        const system = this.props.system;
        system.hoursOfService = [];
        form.hoursOfService.map((hour) => {
            const tmp = getValues(hour);
            system.hoursOfService.push(tmp);
        });
        this.props.handleSave(system);
    };
    expandArea = () => {
        if(!this.state.removed && this.state.form.hoursOfService && this.state.form.hoursOfService.length === 0 && this.props.isEditable) {
            this.addHours();
            this.props.systemSegmentEditAction();
        }
        this.setState({expandThis: !this.state.expandThis});
    };

    renderNotEditable = () => {
        const { t, showEditButton, isEditable } = this.props;
        return (
            <div>
                <SystemSegmentEditButton
                    onClick = {this.props.systemSegmentEditAction}
                    showEditButton = {showEditButton}
                    editText = {this.state.form.hoursOfService && this.state.form.hoursOfService.length > 0 ? t('edit') : t('add_hours')}
                    isEditable = {isEditable}
                />
                <HoursOfServiceTable
                    serviceLevel={ this.props.serviceLevel }
                    calculation={ this.props.calculatedSystem ? this.props.calculatedSystem.hoursOfService : [] }
                    hoursOfService = { this.props.system.hoursOfService || [] }/>
            </div>
        );
    }

    renderEditable = () => {
        const { submitting } = this.props;
        return (
            <div>
                <div>
                    <HoursOfServiceEdit
                        serviceLevel={ this.props.serviceLevel }
                        calculation={ this.props.calculatedSystem ? this.props.calculatedSystem.hoursOfService : [] }
                        form = { this.state.form }
                        removeHours = { this.removeHours }
                        addHours = {this.addHours }
                        calculating = {this.props.calculating}
                    />
                </div>
                <SystemFormActions
                    idPrefix={ idPrefix }
                    submitting={ submitting }
                    handleSave={ this.handleSave }
                    handleCancel={ this.cancel }
                >
                    <Button
                            variant="add"
                            onClick = { this.addHours }
                        >
                            + { this.props.t('add_hours') }
                    </Button>
                </SystemFormActions>
            </div>
        );
    }

    render() {
        const t = this.props.t;
        const system = this.state.form;
        const editMode = this.props.editMode;
        const expandThis = editMode ? true : this.state.expandThis;
        const isValid = this.isDataValid(system.hoursOfService);
        let sum = 0;
        if(this.props.calculatedSystem && this.props.calculatedSystem.hoursOfService) {
            this.props.calculatedSystem.hoursOfService.map((hours) => {
                sum += (hours.cost || 0);
            });
        }
        return (
            <SystemFormWrapper
                id={ idPrefix + '_title' }
                isVisible={ expandThis }
                handleToggle={ this.expandArea }
                title={ t('title_hours_of_service') }
                cost={ sum }
                isValid={ isValid }
            >
                <SystemFormBody
                    notEditableBody={ this.renderNotEditable() }
                    editableBody={ this.renderEditable() }
                    isEditable={ editMode }
                />
            </SystemFormWrapper>
        );
    }
}

export default translations(SystemHoursOfServiceContainer);
