import React, {Component}   from 'react';
import translations from 'decorators/translations.js';
import { connect }          from 'react-redux';
import {
        loadWithIncludes as loadAgreement
}                           from 'redux/modules/agreement.js';

import configuredRadium             from 'configuredRadium.js';

import {
    isEditable
}                                   from 'helpers/agreement.js';
import { loadClient }   from 'redux/modules/client.js';
import { loadCountry } from 'redux/modules/country.js';

import {
    StandardPage,
    BreadcrumbBar,
    KitPackList,
    RichTextView,
    LoadingIndicator,
    BackLink
}                           from 'components';
import {
    commonStyles,
    layout,
    measures,
}                           from 'styles/design.js';

import _ from 'lodash';

class MonitoringView extends Component {

    componentWillMount() {
        const promiseArr = [];
        if(!this.props.client.loaded || this.props.client.client.id !== this.props.match.params.clientId) {
            promiseArr.push(this.props.dispatch(loadClient(this.props.match.params.clientId, this.props.profile.accessToken)));
        }
        if(!this.props.agreement.loaded || this.props.agreement.agreement.length === 0 || !this.props.agreement.agreement[0] || this.props.agreement.agreement[0].id !== this.props.match.params.agreementId) {
            promiseArr.push(this.props.dispatch(loadAgreement(this.props.match.params.agreementId, this.props.profile.accessToken)));
        }

        return Promise.all(promiseArr).then(() => {
            this.props.dispatch(loadCountry(this.props.client.client.salesArea, this.props.profile.accessToken));
        })
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.isSaving && nextProps.agreement.reload) {
            this.props.dispatch(loadAgreement(this.props.match.params.agreementId, this.props.profile.accessToken));
            this.setState({isSaving: false, isLoading: true});
        } else if (this.state.isLoading && nextProps.agreement.loaded) {
            this.setState({isLoading: false});
        }
    }
    /*
    static fetchData(getState, dispatch, location, params) {
        const promiseArr = [];
        if(!getState().kitpackSystems) {
            promiseArr.push(dispatch(loadKitPackSystems(params.agreementId, this.props.profile.accessToken)));
        }
        if(!getState().client.loaded || getState().client.client.id !== params.clientId) {
            promiseArr.push(dispatch(loadClient(params.clientId, this.props.profile.accessToken)));
        }
        if(!getState().agreement.loaded || getState().agreement.agreement.length === 0 || !getState().agreement.agreement[0] || getState().agreement.agreement[0].id !== params.agreementId) {
            promiseArr.push(dispatch(loadAgreement(params.agreementId, this.props.profile.accessToken)));
        }
        return Promise.all(promiseArr);
    }*/

    kitPackClicked(kitpack) {
        const params = _.get(this.props, 'match.params', {});
        if(params.clientId && params.agreementId) {
            this.props.history.push('/client/' + params.clientId + '/contractInfo/' + params.contractInfoId + '/agreement/' + params.agreementId + '/kitpack/' + kitpack.id);
        }

    }

    render() {
        const { t, client, match: { params } } = this.props;
        const agreement = this.props.agreement.agreement;
        if(!agreement) {
            return <LoadingIndicator />;
        }
        const countryConfig = this.props.countries.selectedCountry;
        const kitpacks = agreement.kitpacks;
        return (
            <StandardPage withBreadcrumb>
                <BreadcrumbBar
                    page="agreement"
                    agreement={agreement}
                    actionUrl = { '/client/' + client.client.id + '/details' }
                    linkName = { t('show_client_details') }
                    lockedText = { !isEditable(agreement) ? t('locked') : null}

                />
                {/*
                <AgreementSubNavigation urlParams={this.props.match.params}  activeMenu = { 'monitoring' } agreement={agreement} />
                */}
                {
                    client.client && client.client.id && agreement && agreement.id &&
                    <BackLink title={t('Back to agreement')} to={'/client/' + params.clientId + '/contractInfo/' + params.contractInfoId + '/agreement/' + agreement.id + '/summary'} />
                }
                <header style = { [{ marginBottom: measures.standard}] }>
                    <h1 style = { [commonStyles.pageTitle, layout.inlineBlock, layout.verticalMiddle, { paddingTop: measures.fatHalf }] } >{ t('monitoring') }</h1>
                </header>
                <div style={commonStyles.subSection}>
                    {
                        (() => {
                            if (kitpacks) {
                                return (
                                    <div style={{ marginBottom: '3em', lineHeight: 1.5 }}>
                                        <KitPackList
                                            kitpacks={ kitpacks }
                                            agreement={ agreement }
                                            kitPackClicked={ this.kitPackClicked.bind(this) }
                                            params = {this.props.match.params}
                                        />

                                        <RichTextView
                                            labelStyle={{ padding: '1em 0.5em' }}
                                            value={countryConfig.slaKitpackDescription}
                                        />
                                    </div>
                                );
                            }

                            return (
                                <div>{t('agreement_no_silver_systems')}</div>
                            );
                        })()
                    }
                </div>
            </StandardPage>
        );
    }
}



function mapStateToProps(state) {
    return {
        agreement: state.agreement,
        userProfile: state.userProfile.user,
        client: state.client,
        countries: state.countries,
        profile: state.userProfile,
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(MonitoringView)));