import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';

import ReportForAdmin from './ReportForAdmin.js';
import ReportForUser from './ReportForUser.js';

import {
    StandardPage,
    Header
} from 'components';

class ReportView extends Component {
    render() {
        const {t} = this.props;
        return (
            <StandardPage loaderVisible={ this.props.report.loading } variant="wide">
                <Header title={ t('reports') } />
                {this.props.user.isAdmin 
                    ? <ReportForAdmin />
                    : <ReportForUser />
                }
            </StandardPage>
        );
    }
}

function mapStateToProps(state) {
    return {
        report: state.report,
        user: state.userProfile.user
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(ReportView));


