import React, { Component }  from 'react';
import translations from 'decorators/translations.js';
import { connect }                      from 'react-redux';
import configuredRadium                 from 'configuredRadium.js';
import { reset }                        from 'redux-form';
import {
    update as updateAgreement,
    loadWithIncludes as loadAgreement,
    getDiff,
}                                       from 'redux/modules/agreement.js';

import { loadContractInfo, isContractInfoLoaded } from 'redux/modules/contractInfo';
import { loadCountry } from 'redux/modules/country.js';

import {
    isEditable
}                                   from 'helpers/agreement.js';
import {
    StandardPage,
    BreadcrumbBar,
    AgreementContents,
    Header,
    BackLink
}                                       from 'components/index.js';

import { loadWithType } from 'redux/modules/translations.js';
import _ from 'lodash';

class AgreementCommentsView extends Component {

    componentDidMount() {
        const promises = [];
        const params = this.props.match.params;
        const dispatch = this.props.dispatch;
        const token = this.props.profile.accessToken;
        if(!isContractInfoLoaded(this.props, params.contractInfoId)) {
            promises.push(this.props.dispatch(loadContractInfo(params.contractInfoId, token)));
        }
        if(!this.props.agreementState.loaded || !this.props.agreementState.agreement|| this.props.agreementState.agreement.id !== this.props.match.params.agreementId) {
            promises.push(this.props.dispatch(loadAgreement(params.agreementId, token)));
        }
        return Promise.all(promises).then(() => {
            const contractInfo = this.props.contractInfo.contractInfo;
            if (contractInfo) {
                this.props.dispatch(loadCountry(contractInfo.salesArea, token))
                    .then(res => {
                        if (res.result) {
                            const country = res.result;
                            const languages = ['en'];
                            const lang = country.defaultLanguage || 'en';
                            if(lang && lang !== 'en') {
                                languages.push(lang);
                            }
                            return dispatch(loadWithType(2, languages.join(','), country.id, token));
                        }
                    })
            }
        });
    }

    componentWillReceiveProps(nextProps) {
        if(!this.state.translationsLoaded && Object.keys(nextProps.translations.translatables).length > 0) {
            const data = nextProps.translations.translatables;
            const agreement = nextProps.agreementState.agreement;
            const isAddon = agreement && agreement.agreementType === 'addon';
            const translationType = isAddon ? 'addonagreement_s' : 'mainagreement_s';
            if(data) {
                const sections = _.map(_.filter(Object.keys(data), (key) => key.indexOf(translationType) !== -1 && key.indexOf('_header') !== -1), (row, idx) => {
                    return {key: row, id: (idx + 1), translations: data[row]};
                });
                this.setState({translationsLoaded: true, sections});
            }
        }
    }

    getAgreements = () => {
        const params = this.props.match.params,
            accessToken = this.props.profile.accessToken,
            dispatch = this.props.dispatch;
        dispatch(loadAgreement(params.agreementId, accessToken))
            .then(() => {
                dispatch(getDiff(params.agreementId, accessToken));
            });
    };

    handleSubmitComments = (data) => {
        const params = this.props.match.params,
            accessToken = this.props.profile.accessToken,
            dispatch = this.props.dispatch;
        return dispatch(updateAgreement(data, accessToken))
            .then(() => {
                dispatch(loadAgreement(params.agreementId, accessToken))
            })
            .then(() => {
                this.setState({ editAppendix: null });
            });
    };

    getAgreement(agreements, strId) {
        const id = parseInt(strId, 10);
        return _.find(agreements, {id});
    };

    render() {
        const { t, clientState, agreementState, countryState, match: { params }} = this.props;
        const agreement = agreementState.agreement;
        if(agreementState.loading || countryState.loading) {
            return (
                <StandardPage
                    withBreadcrumb
                    withSubNavigation
                    loaderVisible={true}
                ><div /></StandardPage>
            );
        } else if(!agreement) {
            return (
                <div style={{marginLeft: 'auto', marginRight: 'auto', width: '20em'}}>
                    <h2>
                    {t('agreement_not_found')}
                    </h2>
                </div>
            );
        }
        return (
            <StandardPage
                withBreadcrumb
            >
                <BreadcrumbBar
                    page="agreement"
                    contractInfo={this.props.contractInfo.contractInfo}
                    agreement={agreement}
                    actionUrl = { '/client/' + clientState.client.id + '/details' }
                    linkName = { t('show_client_details') }
                    lockedText = { !isEditable(agreement) ? t('locked') : null}
                />

                <BackLink
                    title={t('Back to agreement')}
                    to={ `/client/${params.clientId}/contractInfo/${params.contractInfoId}/agreement/${params.agreementId}/summary`}
                    id="SLAView_Back"
                />
                <Header id="AgreementCommentsView_Header" title={ t('comments') } />

                <AgreementContents
                    agreement={ agreement }
                    editAppendix={ this.state.editAppendix }
                    cancelEdit={ () => {
                        this.setState({ editAppendix: null });
                        this.props.dispatch(reset('agreementCommentsForm'));
                    }}
                    beginEdit={ (appendix) => {
                        return () => {
                            this.setState({ editAppendix: appendix });
                        };
                    }}
                    sections = { this.state.sections }
                    agreementTranslation = { this.props.translations.translations }
                    onSubmit={this.handleSubmitComments}
                    handleSubmitComments={this.handleSubmitComments}
                />

            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        agreementState: state.agreement,
        userProfile: state.userProfile.user,
        clientState: state.client,
        client: state.client.client,
        profile: state.userProfile,
        countryState: state.countries,
        selectedCountry: state.countries.selectedCountry,
        translations: state.translations,
        download: state.download,
        files: state.files,
        contractInfo: state.contractInfo,
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(AgreementCommentsView)));