import React, {Component}       from 'react';
import translations             from 'decorators/translations.js';
import PropTypes                from 'prop-types';
import { connect }              from 'react-redux';
import {
    SubcontractingTable,
    SubcontractingEdit,
    SystemSegmentEditButton,
    SystemFormWrapper,
    SystemFormBody,
    SystemFormActions,
    Button
}                               from 'components';
import {
    initializeNewKey,
    initializeExistingKey,
    getValues
}                               from 'helpers/formHelper.js';
import _                        from 'lodash';

const defaultKeys = [
    'margin',
    'price',
    'description',
    'multiplier'
];

const idPrefix = "SystemSubcontractingContainer";

class SystemSubcontractingContainer extends Component {

    static propTypes = {
        system: PropTypes.object.isRequired,
        calculation: PropTypes.array,
        resetField : PropTypes.func.isRequired,
        handleCancel : PropTypes.func.isRequired,
        handleSave : PropTypes.func.isRequired,
        submitting : PropTypes.bool,
        isEditable : PropTypes.bool.isRequired
    };

    componentWillMount() {
        this.init(this.props.system);
    }

    componentWillReceiveProps(nextProps) {
        if(_.isEmpty(this.state.form) || (_.isEmpty(this.state.form.subcontracting) && !_.isEmpty(nextProps.system.subcontracting))) {
            this.init(nextProps.system);
        }
    }
    init(system) {
        const form = {
            subcontracting: []
        };
        if(system.subcontracting && _.isArray(system.subcontracting)) {
            system.subcontracting.map((subcontracting, index) => {
                const obj = initializeExistingKey({keys: defaultKeys, values: subcontracting, index, changeValue: this.changeValue});
                form.subcontracting.push(obj);
            });
        }
        this.setState({form});
    }
    getForm() {
        return Object.assign({}, this.state.form);
    }
    changeValue = (rawVal, index, key) => {
        const form = this.getForm();
        const value = (key === 'margin' || key === 'price') && rawVal !== '' ? parseFloat(rawVal) : rawVal;
        form.subcontracting[index][key].value = value;
        if(['margin', 'price', 'multiplier'].indexOf(key) !== -1) {
            const sys = Object.assign({}, this.props.system);
            if(!sys.subcontracting) {
                sys.subcontracting = [];
            }
            form.subcontracting.map((subc, idx) => {
                const obj = getValues(subc);
                if(!sys.subcontracting[idx]) {
                    sys.subcontracting.push(obj);
                } else {
                    sys.subcontracting[idx] = obj;
                }
            });
            this.props.calculateCosts(sys);
        }
        this.setState({form});
    };
    isDataValid(data) {
        return data && data.length > 0 && !this.props.editMode;
    }
    remove = (index) => {
        this.setState({removed: true});
        const form = this.getForm();
        form.subcontracting.splice(index, 1);
        this.setState({form});
    };
    add = () => {
        const form = this.getForm();
        const newRow = initializeNewKey({defaultKeys, index: (form.subcontracting.length), changeValue: this.changeValue});
        form.subcontracting.push(newRow);
        this.setState({form});
    };
    cancel = () => {
        this.init(this.props.system);
        this.props.handleCancel();
    };
    handleSave = () => {
        const form = this.getForm();
        const system = this.props.system;
        system.subcontracting = [];
        form.subcontracting.map((row) => {
            const tmp = getValues(row);
            system.subcontracting.push(tmp);
        });
        this.props.handleSave(system);
    };
    expandArea = () => {
        if(!this.state.removed && this.state.form.subcontracting && this.state.form.subcontracting.length === 0 && this.props.isEditable) {
            this.add();
            this.props.systemSegmentEditAction();
        }
        this.setState({expandThis: !this.state.expandThis});
    };

    renderNotEditable({ showEditButton, calculatedSystem, isEditable, systemSegmentEditAction }) {
        return (
            <div>
                <SystemSegmentEditButton
                    onClick = {systemSegmentEditAction}
                    showEditButton = {showEditButton}
                    isEditable={isEditable}
                />
                <SubcontractingTable
                    system = { calculatedSystem }
                    calculation={ calculatedSystem ? calculatedSystem.subcontracting : [] }
                />
            </div>
        );
    }

    renderEditable({ submitting, form, t }) {
        return (
            <div>
                <div>
                    <SubcontractingEdit
                        form = { form }
                        calculation={ this.props.calculatedSystem && this.props.calculatedSystem.subcontracting ? this.props.calculatedSystem.subcontracting : [] }
                        handleRemoveSubcontracting = { this.remove }
                        resetFunction = { this.props.resetField }
                        countryConfig = { this.props.selectedCountry }/>
                </div>
                <SystemFormActions
                    idPrefix={ idPrefix }
                    submitting={ submitting }
                    handleSave={ this.handleSave }
                    handleCancel={ this.cancel }
                >
                    <Button
                        variant="add"
                        onClick = { this.add }
                    >
                        + { t('add_subcontracting') }
                    </Button>
                </SystemFormActions>
            </div>
        );
    }

    render() {
        const { t, submitting, showEditButton, calculatedSystem, isEditable, systemSegmentEditAction } = this.props;
        const editMode = this.props.editMode;
        const form = this.state.form;
        const expandThis = editMode ? true : this.state.expandThis;
        let sum = 0;
        if(calculatedSystem && calculatedSystem.subcontracting) {
            calculatedSystem.subcontracting.map((row) => {
                sum += (row.cost || 0);
            });
        }
        return (
            <SystemFormWrapper
                id={ idPrefix + '_title' }
                isVisible={ expandThis }
                handleToggle={ this.expandArea }
                title={ t('title_subcontracting') }
                cost={ sum }
                isValid={ this.isDataValid(form.subcontracting) }
            >
                <SystemFormBody
                    notEditableBody={ this.renderNotEditable({ showEditButton, calculatedSystem, isEditable, systemSegmentEditAction }) }
                    editableBody={ this.renderEditable({ submitting, form, t }) }
                    isEditable={ editMode }
                />
            </SystemFormWrapper>
        );
    }
}


function mapStateToProps(state) {
    return {
        selectedCountry: state.countries.selectedCountry
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(SystemSubcontractingContainer));
