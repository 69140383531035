import jstz         from 'jstz';
import moment       from 'moment-timezone';
import numeral      from 'numeral';

const numeralSupportedLangs = ['fi', 'no', 'de', 'en', 'lv', 'et'];
numeralSupportedLangs.map((lang) => {
    let locale = '';
    switch(lang) {
        case 'en':
            locale = null;
            break;
        default:
            locale = lang;
    }
    if(locale) {
        require('numeral/locales/' + locale);
    }

});

const getLocale = function(opts, useNavigatorLanguage) {
    if (opts && opts.locale) {
        return opts.locale.substring(0,2)
    } else if (useNavigatorLanguage) {
        return navigator.language || 'en';
    }
    return 'en';
};

export const buildingAddressLine = (building) => {
    let str = building.locationStreet || '';
    if (building.locationStreetNumber) str += ' ' + building.locationStreetNumber;
    if (building.locationZip) str += ' ' + building.locationZip;
    const tokens = [];
    if (str) tokens.push(str);
    if (building.locationCity) tokens.push(building.locationCity);
    return tokens.join(', ');
};

export const streetLine = (street, num) => {
    const tokens = [];
    if (street) tokens.push(street);
    if (num) tokens.push(num);
    return tokens.join(' ');
};

export const username = (user) => {
    if (!user) return '?';
    // graph
    if (user.givenName && user.surname) return user.givenName + ' ' + user.surname;
    if (user.mail) return user.mail;
    // jwt token
    if (user.given_name && user.family_name) return user.given_name + ' ' + user.family_name;
    if (user.email) return user.email;
    return '?';
};

export const localeDateString = (utcTime, opts = {}) => {
    if (!utcTime) return '';
    const ianaTZ = jstz.determine().name();
    const locale = getLocale(opts, true);
    if (opts.showTimeZone) {
        return moment.utc(utcTime).locale(locale).tz(ianaTZ).format('L LT z');
    }
    return moment.utc(utcTime).locale(locale).tz(ianaTZ).format('L LT');
};

export const numberFormat = (num) => {
    const number = parseFloat(num);
    return number ? number.toFixed(2) : 0.00;
};

export const localeCurrencyString = (value = 0, opts = {}) => {
    const options = opts || {};
    const formattedValue = value;
    if(!formattedValue || formattedValue === 0) {
        return formattedValue;
    }
    const locale = getLocale(options, false);
    const localeForNumeral = numeralSupportedLangs.indexOf(locale) !== -1 ? locale : 'en';
    numeral.locale(localeForNumeral);
    return numeral(formattedValue).format();
};

export const decimalRound = (value, currency) => {
    if(!value) return value;
    switch(currency) {
        case 'EUR':
            return Math.round(value * 100 ) / 100;
        default:
            return Math.round(value);
    }
};

export const localeVAT = (salesArea) => {
    return {
        finland: 'ALV',
        sweden : 'moms',
        denmark: 'moms',
        norway : 'mva.',
        germany: 'USt',
        austria: 'VAT',
        estonia: 'km',
        industria: 'ALV',
    }[salesArea] || 'VAT';
};
