import React from 'react';

import {
    layout,
    commonStyles,
    measures
} from 'styles/design';

import {
    SFTextareaField,
    SFInputField,
    SFSelectField,
    Button
} from 'components';

const FrameAgreementMaterialForm = ({materials, t, country, handleAddMaterial, handleRemoveMaterial}) => {
    return (
        <div style={commonStyles.dataParentDiv}>
            <div style={commonStyles.dataContentHeaderDiv}>
                {t('materials')}:
            </div>

            {(() => {
                return materials.map((material, index) => {
                    return (
                        <div key={'material_key_' + index}>
                            <div style={{...layout.floatRight, marginTop: measures.standard, marginRight: measures.standard}}>
                                <Button
                                    variant="delete"
                                    id={'FrameAgreementForm_material_' + index + '_delete'}
                                    onClick={() => handleRemoveMaterial(index)}
                                >
                                    <i className="fa fa-trash"/>
                                </Button>
                            </div>
                            <div>
                                <SFSelectField
                                    onChangeAction={(event) => {
                                        const value = event.target.value;
                                        if (value === 'listPrice') {
                                            material.valueType.onChange('discount');
                                            if (material.name.value === '') {
                                                material.name.onChange(t('list_price'));
                                            }
                                        } else if (
                                            value === 'other' &&
                                            (material.name.value === t('list_price') || material.name.value === t('net_price'))
                                        ) {
                                            material.name.onChange('');
                                        }
                                        if (value === 'netPrice') {
                                            material.valueType.onChange('margin');
                                            if (material.name.value === '') {
                                                material.name.onChange(t('net_price'));
                                            }
                                        }
                                        material.type.onChange(value);
                                    }
                                    }
                                    id={'FrameAgreementForm_material_' + index + '_type'}
                                    formProperty={material.type} fieldName={t('type')}
                                    selectWidth="6"
                                >
                                    <option value="">
                                        {t('select')}
                                    </option>
                                    <option id='listPrice_option' value="listPrice">
                                        {t('list_price')}
                                    </option>
                                    <option id='netPrice_option' value="netPrice">
                                        {t('net_price')}
                                    </option>
                                    <option value="other">
                                        {t('other')}
                                    </option>
                                </SFSelectField>
                            </div>
                            <div style={{
                                width: '45%',
                                ...layout.inline,
                                display: material.type.value === 'other' ? 'inline-block' : 'none'
                            }}>
                                <SFInputField
                                    id={'FrameAgreementForm_material_' + index + '_name'}
                                    fieldName={t('name')}
                                    formProperty={material.name}
                                    placeholder={t('name')}
                                />
                            </div>
                            <div>
                                {
                                    (() => {
                                        if (material.type.value === 'listPrice') {
                                            const maxDiscount = country.frameAgreementConfig.maxDiscount;
                                            return (
                                                <div>
                                                    <SFInputField
                                                        fieldName={t('discount')}
                                                        type="number"
                                                        id={'FrameAgreementForm_material_' + index + '_value'}
                                                        formProperty={material.discount}
                                                    />
                                                    {
                                                        material.valueType.value === 'discount' && parseInt(material.discount.value, 10) > maxDiscount &&
                                                        <span
                                                            id={'FrameAgreementForm_material_' + index + '_error'}>
                                                            {t('invalid_discount_limit_is') + ' ' + maxDiscount}
                                                        </span>
                                                    }
                                                </div>
                                            );
                                        } else if (material.type.value === 'netPrice') {
                                            const minMargin = country.frameAgreementConfig.minSubcontractingMargin;
                                            return (
                                                <div>
                                                    <SFInputField
                                                        fieldName={t('margin_material')}
                                                        type="number"
                                                        id={'FrameAgreementForm_material_' + index + '_value'}
                                                        formProperty={material.margin}
                                                    />
                                                    {
                                                        material.valueType.value === 'margin' && parseInt(material.margin.value, 10) < minMargin &&
                                                        <span
                                                            id={'FrameAgreementForm_material_' + index + '_error'}
                                                        >
                                                            {t('invalid_margin_limit_is') + ' ' + minMargin}
                                                        </span>
                                                    }
                                                </div>
                                            );
                                        } else if (material.type.value === 'other') {
                                            return (
                                                <SFTextareaField
                                                    id={'FrameAgreementForm_material_' + index + '_description'}
                                                    rows={4}
                                                    fieldName={t('description')}
                                                    formProperty={material.description}
                                                />
                                            );
                                        }
                                    })()
                                }
                            </div>
                        </div>
                    );
                });
            })()}
            <div style={{marginTop: measures.standard}}>
                <Button
                    variant="add"
                    id="FrameAgreementForm_buttonAddMaterial"
                    onClick={handleAddMaterial}
                >
                    {t('add_material')}
                </Button>
            </div>
        </div>
    );
};

export default FrameAgreementMaterialForm;