export const colors = {
    blue: '#002D6E',
    lightBlue: '#00B4E1',
    orange: '#FF911E',
    violet: '#9164CD',
    lightTurquoise: '#00BEB4',
    brightRed: '#FF5A5F',
    basicRed: '#E20404',
    gray: '#73787D',
    lightGray: '#E6E6E6',
    black: '#000000',
    white: '#FFFFFF',
    basicGreen: '#61AB09',
    lightGreen: '#d0e8b3',
    lightGray2: '#f2f2f2',
    unactiveText: '#828282',
    activeText: '#002f6c',
    breadcrumbText: '#00ffff',
    subNavigationBackground: 'rgba(255, 255, 255, 0.9)'
};

export const measures = {
    quarter: '0.25em',   // 4px
    fatQuarter: '0.375em',  // 6px
    half: '0.5em',    // 8px
    fatHalf: '0.75em',   // 12px
    standard: '1em',      // 16px
    semiFatStandard: '1.2em',
    fatStandard: '1.5em',    // 24px
    double: '2em',      // 32px
    triple: '3em',      // 48px
    quadruple: '4em',      // 64px
    zero: 0
};

export const layout = {
    vanish: {
        position: 'absolute',
        left: '-100em'
    },
    floatLeft: {
        float: 'left'
    },
    floatRight: {
        float: 'right'
    },
    block: {
        display: 'block'
    },
    inlineBlock: {
        display: 'inline-block'
    },
    inline: {
        display: 'inline'
    },
    absolute: {
        position: 'absolute'
    },
    relative: {
        position: 'relative'
    },
    fixed: {
        position: 'fixed'
    },
    'static': {
        position: 'static'
    },
    textCenter: {
        textAlign: 'center'
    },
    textLeft: {
        textAlign: 'left'
    },
    textRight: {
        textAlign: 'right'
    },
    verticalTop: {
        verticalAlign: 'top'
    },
    verticalMiddle: {
        verticalAlign: 'middle'
    },
    verticalSuper: {
        verticalAlign: 'super'
    },
    media: {
        overflow: 'hidden',
        zoom: 1
    },
    quarterWidth: {
        width: '25%'
    },
    halfWidth: {
        width: '50%'
    },
    fullWidth: {
        width: '100%'
    },
    textEllipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    noMargin: {
        margin: 0
    },
    hidden: {
        visibility: 'hidden'
    },
    none: {
        display: 'none'
    }
};

export const size = {
    em: (px, fontSize = '1em') => {
        return Number((parseInt(px, 10) / (parseInt(fontSize, 10) * 16))).toFixed(3) + 'em';
    },
    negate: (measure) => {
        return '-' + measure;
    },
    alpha: {
        fontSize: measures.triple,
        lineHeight: '1.25em',
        fontWeight: 'normal'
    },
    beta: {
        fontSize: '2.25em',
        lineHeight: '1.25em',
        fontWeight: 'normal'
    },
    gamma: {
        fontSize: '1.75em',
        lineHeight: '1.25em',
        fontWeight: 'normal'
    },
    delta: {
        fontSize: '1.5em',
        lineHeight: '1.25em',
        fontWeight: 'normal'
    },
    epsilon: {
        fontSize: '1.25em',
        lineHeight: '1.25em',
        fontWeight: 'normal'
    },
    zeta: {
        fontSize: '1.125em',
        lineHeight: '1.25em',
        fontWeight: 'normal'
    },
    normal: {
        fontSize: '1em',
        lineHeight: '1.25em',
        fontWeight: 'normal'
    },
    small: {
        fontSize: '0.875em',
        lineHeight: '1.25em',
        fontWeight: 'normal'
    },
    tiny: {
        fontSize: '0.8em',
        lineHeight: '1.15em',
        fontWeight: 'normal'
    },
    moreTiny: {
        fontSize: '0.7em',
        lineHeight: '1em',
        fontWeight: 'normal'
    }
};

export const fonts = {
    arial: {
        fontFamily: 'Arial',
        fontWeight: 'normal',
        color: colors.blue
    },
    sanches: {
        fontFamily: 'Sanchez-Regular',
        fontWeight: 'normal',
        color: colors.blue
    },
    plainsanches: {
        fontFamily: 'Sanchez-Regular',
        fontWeight: 'normal',
    },
    SanchesRegular: 'Sanchez-Regular'
};

export const commonStyles = {
    errorMessage: {
        paddingTop: '1em',
        paddingBottom: '1em',
        color: colors.brightRed
    },
    highlight: {
        paddingTop: '1em',
        paddingBottom: '1em',
        border: 'thin solid ' + colors.blue
    },
    headerIcon: {
        paddingRight: '1em',
        display: 'inline-block'
    },
    systemContentHeader: {
        backgroundColor: colors.lightGray,
        padding: measures.standard,
        color: colors.black,
        overflow: 'auto',
        cursor: 'pointer',
        ...fonts.plainsanches
    },
    validSystemContentHeader: {
        backgroundColor: colors.lightGreen,
        padding: measures.standard,
        color: colors.black,
        overflow: 'auto',
        cursor: 'pointer',
        ...fonts.plainsanches
    },
    smallDeleteButton: {
        cursor: 'pointer',
        fontSize: '0.75em',
        padding: '0.5em',
        border: 'none',
        borderRadius: '3px',
        backgroundColor: colors.lightGray
    },
    smallCancelButtonStyle: {
        cursor: 'pointer',
        fontSize: '0.75em',
        padding: '0.5em',
        border: 'none',
        borderRadius: '3px',
        backgroundColor: colors.white
    },
    smallSaveButton: {
        cursor: 'pointer',
        fontSize: '0.75em',
        padding: '0.5em',
        border: 'none',
        borderRadius: '3px',
        width: '5em',
        backgroundColor: colors.orange,
        color: colors.white
    },
    buttonHeaderContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    buttonHeaderButton: {
        flexShrink: 0
    },
    breadcrumbStyle: {
        fontFamily: 'Sanchez-Regular',
        fontWeight: 'normal',
        color: colors.blue,
        paddingTop: measures.standard,
        paddingBottom: measures.standard,
        paddingRight: '12em',
        width: '100%',
        textAlign: 'center',
        display: 'inline-block'
    },
    headerBarStyle: {
        backgroundColor: colors.lightGray,
        marginLeft: '2.75em'
    },
    pageTitle: {
        marginBottom: 0,
        fontSize: '1.75em',
        display: 'inline-block',
        fontFamily: 'Sanchez-Regular',
        fontWeight: 'normal',
        color: colors.blue
    },
    tableSubTitle: {
        marginBottom: measures.standard,
        fontSize: measures.semiFatStandard,
        display: 'inline-block',
        fontFamily: 'Sanchez-Regular',
        fontWeight: 'normal',
        color: colors.blue
    },
    pageSubTitle: {
        fontSize: '1.375em',
        display: 'inline-block',
        fontFamily: 'Sanchez-Regular',
        fontWeight: 'normal',
        color: colors.blue
    },
    pageSectionTitle: {
        fontSize: '1.375em',
        display: 'inline-block',
        fontFamily: 'Sanchez-Regular',
        fontWeight: 'normal',
        color: colors.blue,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: '1em',
        marginBottom: '0.5em'
    },
    input: {
        paddingRight: '0.25em',
        paddingLeft: '0.25em',
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
        boxShadow: 'none',
        WebkitAppearance: 'none',
        fontFamily: 'Arial',
        fontWeight: 'normal',
        fontSize: '1em',
        lineHeight: '1.5em',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: colors.blue,
        width: '100%'
    },
    checkbox: {
        padding: '1em 0.5em 1em 0.5em',
        boxShadow: 'none',
        fontSize: '1em',
        lineHeight: '1.5em',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: colors.blue,
        WebkitAppearance: 'none',
    },
    select: {
        paddingRight: '0.5em',
        paddingLeft: '0.5em',
        paddingTop: '0.5em',
        paddingBottom: '0.5em',
        boxShadow: 'none',
        WebkitAppearance: 'none',
        fontSize: '1em',
        lineHeight: '1.5em',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: colors.blue
    },
    button: {
        display: 'inline-block',
        border: 'none',
        fontSize: '1em',
        padding: '0.5em 0.75em 0.5em 0.75em',
        textDecoration: 'none',
        color: 'white',
        cursor: 'pointer',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        WebkitAppearance: 'none',
        borderRadius: '3px',
        textAlign: 'center',
        backgroundColor: colors.orange
    },
    tinyButton: {
        cursor: 'pointer',
        fontSize: '0.75em',
        padding: '0.5em',
        border: 'none',
        backgroundColor: colors.lightGray,
        color: colors.blue,
    },
    mediumButton: {
        cursor: 'pointer',
        fontSize: '1em',
        padding: '0.5em',
        border: 'none',
        backgroundColor: colors.lightGray,
        color: colors.blue,
    },
    grayButton: {
        display: 'inline-block',
        border: 'none',
        fontSize: '1em',
        padding: '0.5em 0.75em',
        margin: 0,
        textDecoration: 'none',
        color: colors.blue,
        cursor: 'pointer',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        WebkitAppearance: 'none',
        borderRadius: '3px',
        width: '100%',
        backgroundColor: colors.lightGray
    },
    buttonLink: {
        display: 'inline-block',
        padding: '0.5em 0.75em',
        textDecoration: 'none',
        color: 'white',
        backgroundColor: colors.orange,
        borderRadius: '3px',
        cursor: 'pointer'
    },
    bottomDivider: {
        borderBottom: '2px solid',
        borderColor: colors.lightGray
    },
    cancelButtonLink: {
        display: 'inline-block',
        fontSize: '1em',
        padding: '0.5em 0.75em',
        textDecoration: 'none',
        color: colors.blue,
        borderRadius: '3px',
        cursor: 'pointer',
        lineHeight: '1.125em'
    },
    subSection: {
        padding: '2em 0 2em 0',
        borderTop: '1px solid',
        borderColor: colors.lightGray
    },
    subSectionBottomBorder: {
        padding: '2em 0 2em 0',
        borderBottom: '1px solid',
        borderColor: colors.lightGray
    },
    deleteButtonLink: {
        display: 'inline-block',
        fontSize: '1em',
        padding: '0.5em',
        textDecoration: 'none',
        color: colors.blue,
        borderRadius: '3px',
        cursor: 'pointer',
        lineHeight: '1.125em'
    },
    confirmDeleteButtonLink: {
        backgroundColor: colors.basicGreen,
        display: 'inline-block',
        fontSize: '1em',
        padding: '0.5em 0.75em',
        textDecoration: 'none',
        color: colors.blue,
        borderRadius: '3px',
        cursor: 'pointer',
        lineHeight: '1.125em'
    },
    grayButtonLink: {
        backgroundColor: colors.lightGray,
        display: 'inline-block',
        fontSize: '1em',
        padding: '0.5em 0.75em',
        textDecoration: 'none',
        color: colors.blue,
        borderRadius: '3px',
        cursor: 'pointer',
        lineHeight: '1.125em'
    },
    errorHeader: {
        color: colors.basicRed,
        font: fonts.arial
    },
    addButtonStyle: {
        fontSize: '0.875em',
        lineHeight: '1.25em',
        fontWeight: 'normal',
        marginLeft: measures.standard,
        cursor: 'pointer',
        backgroundColor: colors.lightGray,
        padding: measures.half
    },
    dataParentDiv: {
        paddingTop: measures.half,
        paddingLeft: measures.half,
        paddingRight: measures.half,
        marginTop: measures.half
    },
    dataContentHeaderDiv: {
        backgroundColor: colors.lightBlue,
        padding: measures.half,
        marginTop: measures.standard,
        border: '1px solid ' + colors.lightGray,
        color: '#fff',
        ...fonts.plainsanches,
        fontSize: '1.15em',
    },
    disciplineNameStyle: {
        ...fonts.sanches,
        ...size.zeta,
        ...layout.inline,
        paddingLeft: measures.standard,
        paddingRight: measures.quarter
    },
    disciplineIconStyle: {
        margin: measures.half
    },
    editButtonStyle: {
        marginTop: measures.half
    }
};


['Padding', 'Margin'].forEach((type) => {
    const typeObject = {};
    typeObject[type.toLowerCase()] = '0 !important';
    commonStyles[['no', type].join('')] = typeObject;    // .noPadding { padding: 0 !important; }

    Object.keys(measures).forEach((measure) => {
        const measureObject = {};
        measureObject[type.toLowerCase()] = measures[measure];
        commonStyles[[measure, type].join('')] = measureObject; // .standardPadding { padding: 1em; }
    });

    ['Top', 'Right', 'Bottom', 'Left'].forEach((direction) => {
        const directionObject = {};
        directionObject[[type.toLowerCase(), direction].join('')] = '0 !important';
        commonStyles[['no', type, direction].join('')] = directionObject; // .noPaddingLeft { padding-left: 0 !important; }

        Object.keys(measures).forEach((measure) => {
            const measureObject = {};
            measureObject[[type.toLowerCase(), direction].join('')] = measures[measure];
            commonStyles[[measure, type, direction].join('')] = measureObject; // .standardPaddingLeft { padding-left: 1em; }
        });
    });
});

export const decorators = {
    even: (index, declaration) => {
        return index % 2 === 0 ? declaration : false;
    },
    odd: (index, declaration) => {
        return index % 2 !== 0 ? declaration : false;
    },
    coloredBorder: (color, thickness = '1px') => {
        return [thickness, 'solid', color].join(' ');
    },
    join: (values, glue = ' ') => {
        if (values && values.length) {
            return values.join(glue);
        }
    }
};

export const formStyles = {
    colStyle: (align, width) => {
        return {
            float: align,
            width: width,
            '@media only screen and (max-width: 660px)': {
                width: '100%',
                float: 'none'
            }
        };
    },
    spacer: {
        padding: '20px'
    },
    subHeader: {
        ...commonStyles.pageSubTitle,
        ...size.epsilon,
        marginBottom: measures.standard,
        marginTop: measures.triple
    }
};

