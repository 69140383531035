import qs from 'query-string';

export default (path, csrf, queryString = {}) => {

    if(csrf) {
        queryString.csrf = csrf;
    }

    return path + '?' + qs.stringify(queryString);

};
