import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import {
    Button,
    KeyValueDiv,
    RichTextView
} from 'components/index';

import {
    commonStyles,
    layout,
    measures
} from 'styles/design.js';

class CountryMonitoringInformation extends Component {

    static propTypes = {
        countryConfig: PropTypes.object.isRequired,
        languages: PropTypes.array.isRequired
    };

    render() {
        const {t} = this.props;
        const monitoring = this.props.countryConfig.monitoring ? this.props.countryConfig.monitoring : {};
        return (
            <div>
                <div>
                    <div style={commonStyles.dataParentDiv}>
                        <div style={commonStyles.dataContentHeaderDiv}>
                            {t('kitpack_basic') + ':'}
                        </div>

                        <div style={{...layout.floatRight, ...layout.inlineBlock, ...commonStyles.editButtonStyle}}>
                            <Button
                                id="CountryMonitoringInformation_edit"
                                onClick={this.props.editClicked}
                            >
                                {t('edit')}
                            </Button>
                        </div>

                        <div style={{width: '100%', marginTop: measures.double}}>
                            <KeyValueDiv
                                name={t('monitoring_yearly_remotecenter_fee') + ':'}
                                value={monitoring.yearlyRemoteCenterFee ? monitoring.yearlyRemoteCenterFee : 0 + ' ' + this.props.countryConfig.currency}
                            />

                            <KeyValueDiv
                                name={t('monitoring_installation_hours') + ':'}
                                value={monitoring.installationHours}
                            />

                            <KeyValueDiv
                                name={t('monitoring_material_cost') + ':'}
                                value={monitoring.materialCost ? monitoring.materialCost : 0 + ' ' + this.props.countryConfig.currency}
                            />

                            <KeyValueDiv
                                name={t('monitoring_margin') + ':'}
                                value={monitoring.margin ? monitoring.margin : 0 + '%'}
                            />

                            {/* This is in fact translated "Caverion Condition Control description" */}
                            <div style={{padding: measures.standard, margin: measures.standard}}>
                                <div>
                                    {t('sla_kitpack_description') + ':'}
                                </div>

                                <RichTextView
                                    labelStyle={{padding: measures.standard}}
                                    value={this.props.countryConfig.slaKitpackDescription}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default translations(CountryMonitoringInformation);
