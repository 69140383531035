import React from 'react';
import { Link } from 'react-router-dom';
import './BackLink.css';

/**
 * UI component to link back to upper level in page hierarchy
 */
const BackLink = ({to, title, id}) => {
    return (
        <div className="backlink">
            <Link id={id} className="backlink-link" to={to}>
                <i className="fa fa-chevron-left" /> {title}
            </Link>
        </div>
    );
}

export default BackLink;