import React, {Component}   from 'react';
import { connect }          from 'react-redux';
import translations from 'decorators/translations.js';
import { username }         from 'helpers/stringTemplates';
import configuredRadium             from 'configuredRadium.js';

import { Link }                     from 'react-router-dom';
import {
    colors,
    measures,
    fonts,
    commonStyles
} from 'styles/design.js';

import {
    NavigationItem
} from 'components';

import {
    load as loadTranslations,
    setLanguage,
    toggleShowKeys
} from 'redux/modules/translations';

import {
    add as addCookie
} from 'redux/modules/cookies';
import { download } from 'redux/modules/download';

import { version } from '../../../../package.json';

const navHeight = '4em';

const navigationContainerStyle = [
    {
        position: 'fixed',
        left: 0,
        top: 0,
        right: 0,
        backgroundColor: colors.orange,
        height: navHeight,
        zIndex: 100001,
        color: colors.white
    },
    fonts.arial
];

const navigationItemStyle = [
    {
        display: 'inline-block',
        position: 'relative',
        left: 0,
        lineHeight: '2',
        fontSize: '150%',
        cursor: 'pointer',
        transitionProperty: 'transform, opacity, left',
        transitionDuration: '0.4s',
        transitionDelay: '0.25s, 0.25s, 0s',
        transitionTimingFunction: 'ease',
        ':hover': {
            left: '6px'
        }
    }

];

const menuContainer = [
    {
        display: 'block',
        position: 'fixed',
        right: 0,
        top: navHeight,
        bottom: 0,
        padding: '1em 2em 1em 1em',
        minWidth: '300px',
        textAlign: 'left',
        transform: 'translateX(100%)',
        zIndex: 10,
        backgroundColor: colors.orange,
        transitionProperty: 'transform',
        transitionDuration: '0.3s'
    }
];

const menuContainerActive = [
    {
        display: 'block',
        transform: 'translateX(0)'
    }
];

const logoStyle = [
    {
        padding: '0.75em 1.5em',
        position: 'absolute',
        width: '70px',
        top: 0,
        left: 0,
        bottom: 0,
        cursor: 'pointer'
    }
];

const menuToggler = [
    {
        position: 'absolute',
        right: '0.5em',
        top: '0.5em',
        fontSize: '2em',
        cursor: 'pointer'
    }
];

const navigationIcon = [
    {
        position: 'relative',
        paddingRight: measures.half,
        paddingLeft: measures.half,
        zIndex: 11002,
        color: colors.white
    }
];
const usernameStyle = [
    {
        position: 'relative',
        display: 'inline-block',
        paddingLeft: '1em',
        color: colors.white,
        fontSize: '0.5em'
    }
];
const userManualStyle = {
    position: 'absolute',
    bottom: '1.5em',
    left: '1.5em',
    color: '#fff',
};
const userManualLinkStyle = {
    color: '#fff',
    cursor: 'pointer',
};

// Language codes
const options = ['en', 'no', 'fi', 'fi_industria', 'de', 'sv', 'da', 'cz', 'pl', 'lt', 'et', 'lv', 'ru'];

class Navigation extends Component {

    constructor(props) {
        super(props);
        this.state = { openMenu: false };
    }

    closeMenu = () => {
        this.setState({ openMenu: false });
    };

    toggleMenu = () => {
        this.setState({ openMenu: !this.state.openMenu });
    };
    selectLanguage = (event) => {
        let language = event.target.value;
        if(language === '' || language === '-') {
            language = 'en';
        }
        this.props.setLanguage(language)
            .then(() => {
                this.props.loadTranslations(language, this.props.profile.accessToken);
                this.props.addCookie(language);
            });
    };
    toggleShowKeys = () => {
        this.setState({showKeys: !this.state.showKeys});
        this.props.toggleShowKeys(!this.state.showKeys);
    };

    handleUserManualDownload = e => {
        e.preventDefault();
        this.props.downloadUserManual(this.props.profile.accessToken);
    };

    render() {
        const { user } = this.props;
        const linkTitle = 'ServiFlex+ v' + version;

        return (
            <div style={ [ { paddingBottom: navHeight } ] }>
                <nav style = { navigationContainerStyle }>

                    <div key="navLogo" style = { [ logoStyle ] }>
                        <Link id="Navigation_logo" to="/search" onClick = { this.closeMenu } title={linkTitle}>
                            <span>
                                <img alt="logo" style={ [ { height: '2.5em', width: '2.5em' } ] } src={require('images/serviflex-icon-white.svg')} />
                            </span>
                        </Link>
                    </div>
                    <div id="Navigation_toggleMenu" style={ menuToggler } onClick = { this.toggleMenu }>
                        <span style = { navigationIcon }><i className="fa fa-bars" /></span>
                    </div>
                    <div style={ [ menuContainer, this.state.openMenu ? menuContainerActive : {} ] }>
                        {user && (user.isAdmin || (user.salesAreas && user.salesAreas.length > 0)) && <div>
                            <NavigationItem
                                onClick = { this.closeMenu }
                                text={this.props.t('search')} icon="fa-search"
                                to = {'/search'}
                                openMenu = { this.state.openMenu } url="/search"
                            />
                            <div style = { this.props.client.id === undefined ? { display: 'none' } : { display : 'block' } }>
                                <NavigationItem
                                    onClick = { this.closeMenu }
                                    text={this.props.t('client') + ': ' + this.props.client.name} icon="fa-user"
                                    to = {'/client/' + this.props.client.id}
                                    openMenu = { this.state.openMenu } url="/client/ + 'this.props.client.id'"
                                />
                            </div>
                            {this.props.user && this.props.user.isAdmin && <div>
                                <NavigationItem
                                    id="NavigationItem_admin"
                                    text={this.props.t('admin')} icon="fa-wrench"
                                    to={'/admin/'}
                                    onClick = { this.closeMenu }
                                    openMenu={ this.state.openMenu } url="/admin"
                                />
                            </div>}
                            <NavigationItem text={this.props.t('clients')} icon="fa-users"
                                            to={'/clients/'}
                                            onClick = { this.closeMenu }
                                            openMenu={ this.state.openMenu } url="/clients"/>
                            <NavigationItem text={this.props.t('reports')} icon="fa-line-chart"
                                            to={'/report/'}
                                            onClick = { this.closeMenu }
                                            openMenu={ this.state.openMenu } url="/report"/>
                        </div>}
                        <div key="userInfo" style = { [ navigationItemStyle, this.state.openMenu ? { opacity: 1, transform: 'translateX(0)' } : { opacity: 0, transform: 'translateX(40px)' } ] } onClick = { this.toggleMenu } >
                            <Link id="Navigation_userinfo" to={'/userinfo'} onClick = { this.closeMenu }>
                                <span style = { [ usernameStyle ]} >{ username(this.props.user) }</span>
                            </Link>
                        </div>
                        <div>
                            <select
                                value={this.props.translations.language || ''}
                                id="Navigation_selectLanguage" style={commonStyles.select} onChange = {this.selectLanguage}>
                                <option value="">{this.props.t('select')}</option>
                                {
                                    options.map((option, idx) =>
                                        <option
                                            key={'lang_idx' + idx}
                                            value={option}
                                        >
                                            {this.props.t(option)}
                                        </option>)
                                }
                            </select>
                        </div>
                        {this.props.user && this.props.user.isAdmin &&
                            <div style={{marginTop: measures.standard}}>
                                <label>
                                    <input type="checkbox" onClick={this.toggleShowKeys} checked={this.state.showKeys}/>
                                    Show translation keys
                                </label>
                            </div>
                        }
                        <div style={ userManualStyle }>
                            <a
                                href=""
                                onClick={this.handleUserManualDownload}
                                style={userManualLinkStyle}
                            >
                                {
                                    this.props.download.loading
                                    ? <i className="fa fa-spinner fa-spin" />
                                    : <i className="fa fa-file-pdf-o" />
                                } { this.props.t('User manual') }
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        translations: state.translations,
        profile: state.userProfile,
        download: state.download
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setLanguage: (language) => {
            return dispatch(setLanguage(language));
        },
        loadTranslations: (language, token) => {
            return dispatch(loadTranslations(language, token, false));
        },
        addCookie: (language) => {
            return dispatch(addCookie('selectedLanguage', language, Infinity, '/'));
        },
        toggleShowKeys: (selected) => {
            return dispatch(toggleShowKeys(selected));
        },
        downloadUserManual: (accessToken) => {
            return dispatch(download({downloadType: 'manual', name: 'UserManual.pdf', type: 'manual'}, accessToken));
        },
    }
}
const connector = connect(mapStateToProps, mapDispatchToProps);

export default translations(connector(configuredRadium(Navigation)));