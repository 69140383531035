import React from 'react';

import {
    Button
} from 'components';

const approverContainerStyle = {
    display: 'inline-block',
    marginTop: '1em',
    marginBottom: '2em',
    padding: '1em',
    width: '100%',
    border: '1px dashed #d0d0d0'
};

const titleStyle = {
    marginBottom: '1em'
};

const descriptionStyle = {
    marginBottom: '1em'
};

const buttonsContainerStyle = {
    marginTop: '2em'
};

const FrameAgreementApprovalBar = ({agreement, user, t, push, clientId}) => {
        if(agreement.agreementStatus === 'sent_for_approval' && (user.email === agreement.approver || user.isAdmin)) {
            return (
                <div style={approverContainerStyle}>
                    <h2 style={titleStyle}>{t('agreement_approval')}</h2>
                    <p style={descriptionStyle}>{t('approve_or_reject_agreement')}</p>
                    <div style={buttonsContainerStyle}>
                        <Button
                            onClick={() => push('/client/' + clientId + '/contractInfo/' + agreement.contractInfoId + '/frameagreement/' + agreement.id + '/approve')}
                        >
                            {t('review')}
                        </Button>
                    </div>
                </div>
            );
        }
        return <div/>;
};

export default FrameAgreementApprovalBar;

