import React, {Component}            from 'react';
import { localeDateString }                     from 'helpers/stringTemplates';

import {
    colors,
} from 'styles/design.js';

const timelineGray = colors.lightGray;
const styles = {
    stepsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'relative',
        zIndex: '10'
    },
    timeline: {
        position: 'relative',
        height: '2px',
        backgroundColor: timelineGray,
        marginTop: '2.5em'
    },
    timelineCaret: {
        position: 'absolute',
        right: '-7px',
        top: '-6px',
        border: '7px solid transparent',
        borderLeftColor: timelineGray
    },
    step: {
        minWidth: '100px',
        marginTop: '1em',
        fontSize: '1em',
        fontWeight: 'bold',
        marginBottom: '0.5em'
    },
    small: {
        fontSize: '0.75em'
    }
};

class StepTimeline extends Component {

    stepIconStyle(step) {
        const iconStyle = {
            height: '2.25em',
            width: '2.25em',
            marginTop: '-1.125em',
            padding: '0.5em',
            borderRadius: '50%',
            border: '2px solid ' + timelineGray,
            fontSize: '1.5em'
        };

        if(step.step === this.props.latestStep.step) {
            iconStyle.backgroundColor = colors.white;
            iconStyle.color = colors.basicGreen;
        } else if(step.step < this.props.latestStep.step) {
            iconStyle.backgroundColor = colors.basicGreen;
            iconStyle.color = colors.white;
        } else {
            iconStyle.backgroundColor = colors.lightGray2;
            iconStyle.color = colors.white;
        }
        return iconStyle;
    }

    render() {
        const { steps, stepData } = this.props;
        return (
            <div style={{ marginTop: '6em', marginBottom: '4em' }}>
                <div style={styles.timeline}>
                    <span style={styles.timelineCaret}></span>
                </div>
                <div style={styles.stepsContainer}>
                    {
                        (() => {
                            return steps.map((step, idx) => {
                                return (
                                    <div key={'step_timeline_' + idx}>
                                        <div style={this.stepIconStyle(step)}>
                                            <i className="fa fa-check" />
                                        </div>
                                        <div style={styles.step}>Stage {idx + 1}</div>
                                        {
                                            (() => {
                                                if (stepData[idx] !== undefined && stepData[idx].created) {
                                                    return <div style={ styles.small }>Created { localeDateString(stepData[idx].created, { showTimeZone:false }) }</div>;
                                                }
                                            })()
                                        }
                                        {
                                            (() => {
                                                if (stepData[idx] !== undefined && stepData[idx].modified) {
                                                    return <div style={ styles.small }>Modified { localeDateString(stepData[idx].modified, { showTimeZone:false }) }</div>;
                                                }
                                            })()
                                        }
                                    </div>
                                );
                            });
                        })()
                    }
                </div>
            </div>
        );
    }
}
export default StepTimeline;
