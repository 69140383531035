import React, { Component } from 'react';
import translations from 'decorators/translations.js';
import { reduxForm } from 'redux-form';
import {
    SFCheckBox,
    SFInputField,
    SaveButton,
    CancelButton,
    SFSelectField,
    Button,
} from 'components';
import {
    layout,
    measures,
    commonStyles,
} from 'styles/design.js';
import _ from 'lodash';
import { getSmartViewTotalPrice } from './utils';

const mapStateToProps = (state) => ({});
const mapActionsToProps = () => ({});

const reduxFormObject = {
    form: 'SmartViewForm',
    fields: [
        'clientNet',
        'clientNetAdminEmail',
        'clientNetCustomPrice',
        'smartViewPricing[].enabled',
        'smartViewPricing[].name',
        'smartViewPricing[].nameEnglish',
        'smartViewPricing[].price',
        'smartViewPricing[].priceType',
        'smartViewPricing[].priceTarget',
    ]
};

const pricingRowStyle = {
    display: 'flex',
    flexFlow: 'row nowrawp',
    justifyContent: 'space-between',
    alignItems: 'center'
}
const pricingHeaderStyle = {
    ...pricingRowStyle,
    marginBottom: '0.5rem',
    fontWeight: 'bold',
};
const pricingBodyStyle = {
    ...pricingRowStyle
};
const priceCheckboxStyle = {
    width: '10%',
};
const priceNameStyle = {
    width: '30%',
};
const priceValueStyle = {
    width: '20%',
};
const priceTargetStyle = {
    width: '20%',
};
const priceCustomValueStyle = {
    width: '20%',
};
const totalPriceRow = {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
};

const newPricing = {
    enabled: true,
    priceTarget: 'building',
    price: 0,
    priceType: 'yearly',
};
class SmartViewForm extends Component {
    componentWillMount() {
        const {
            initializeForm,
            agreement,
            countryConfig,
        } = this.props;

        const price = this.getPrice(agreement, countryConfig);

        let smartViewPricing;

        if (countryConfig.costCalculation.smartViewPricing) {
            smartViewPricing = _.cloneDeep(agreement.smartViewPricing && agreement.smartViewPricing.length
                ? agreement.smartViewPricing
                : countryConfig.costCalculation.smartViewPricing);
        }

        const formData = {
            clientNet: agreement.clientNet,
            clientNetAdminEmail: agreement.clientNetAdminEmail,
            clientNetCustomPrice: price,
            ...(smartViewPricing && { smartViewPricing }),
        };
        initializeForm(formData);
    }

    componentWillUnmount() {
        this.props.destroyForm();
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.fields.clientNet === false && this.props.fields.clientNet === true) {

        }
    }

    handleCancel = () => {
        this.props.cancelAction();
    };

    getPrice = (agreement, countryConfig) => {
        if (agreement.clientNet) {
            return agreement.clientNetCustomPrice;
        }
        return countryConfig.clientNetDefaultPrice;
    };

    handlePricingAddClick = e => {
        e.preventDefault();
        this.props.fields.smartViewPricing.addField(newPricing);
    };

    handlePricingRemoveClick = (e, index) => {
        e.preventDefault();
        this.props.fields.smartViewPricing.removeField(index);
    };

    render() {
        const {
            fields: {
                clientNet,
                clientNetAdminEmail,
                clientNetCustomPrice,
                smartViewPricing,
            },
            handleSubmit,
            submitting,
            t,
            countryConfig,
            agreement,
            printContent,
        } = this.props;

        if (!agreement || !countryConfig) {
            return null;
        }

        const buildingCount = agreement.buildings ? agreement.buildings.length : 0;
        const smartViewPricingConfig = countryConfig && countryConfig.costCalculation && countryConfig.costCalculation.smartViewPricing;
        const smartViewTotalPrice = getSmartViewTotalPrice(agreement, smartViewPricing, clientNetCustomPrice);

        return <div style={{ marginBottom: '2em' }}>
            <div style={{ paddingTop: measures.standard, paddingBottom: measures.standard }}>
                <div style={layout.inlineBlock}>
                    <SFCheckBox formProperty={clientNet}/>
                </div>

                <div style={layout.inlineBlock}>
                    {t('client_net')}
                </div>
            </div>
            {
                clientNet.value &&
                <div>
                    <div>
                        <SFInputField formProperty={clientNetAdminEmail} fieldName={t('Admin user email')} />
                    </div>
                    {agreement.agreementType !== 'frameagreement' && <div>
                        {!!smartViewPricing && !!smartViewPricing.length ? <div>
                            <div style={{margin: '2rem 0'}}>
                                <div style={pricingHeaderStyle}>
                                    <div style={priceCheckboxStyle}></div>
                                    <div style={priceNameStyle}>{t('Label')}</div>
                                    <div style={priceValueStyle}>{t('Price / year')}</div>
                                    <div style={priceTargetStyle}>{t('Price target')}</div>
                                    <div style={priceCustomValueStyle}>{t('Custom price')} ({countryConfig.currency})</div>
                                </div>
                                {smartViewPricing.map((pricing, index) => {
                                    if (index < smartViewPricingConfig.length) {
                                        return <div key={index} style={pricingBodyStyle}>
                                            <div style={priceCheckboxStyle}><SFCheckBox formProperty={pricing.enabled} /></div>
                                            <div style={priceNameStyle}>{pricing.name.value}</div>
                                            <div style={priceValueStyle}>{smartViewPricingConfig[index] ? smartViewPricingConfig[index].price : ''} {countryConfig.currency}</div>
                                            <div style={priceTargetStyle}>{t(pricing.priceTarget.value)}</div>
                                            <div style={priceCustomValueStyle}><SFInputField oneline type="number" formProperty={ pricing.price } placeholder={countryConfig.currency} /></div>
                                        </div>;
                                    }
                                    return <div key={index} style={pricingBodyStyle}>
                                        <div style={priceCheckboxStyle}><Button variant="delete" onClick={e => this.handlePricingRemoveClick(e, index)}><i className="fa fa-trash-o"></i></Button></div>
                                        <div style={priceNameStyle}><SFInputField formProperty={pricing.name} /></div>
                                        <div style={priceValueStyle}></div>
                                        <div style={priceTargetStyle}>
                                            <SFSelectField formProperty={pricing.priceTarget}>
                                                <option value="building">{t('building')}</option>
                                                <option value="agreement">{t('agreement')}</option>
                                            </SFSelectField>
                                        </div>
                                        <div style={priceCustomValueStyle}><SFInputField type="number" formProperty={ pricing.price } placeholder={countryConfig.currency} /></div>
                                    </div>;
                                })}
                            </div>
                            <div style={{ margin: '1rem 0' }}>
                                <a href="" style = { commonStyles.tinyButton } onClick= {this.handlePricingAddClick}>{t('Add custom price module')}</a>
                            </div>
                        </div>
                        : <div>
                            <div>
                                <SFInputField type="number" formProperty={clientNetCustomPrice} fieldName={`${t('Yearly price for a single building')} (${countryConfig.currency})`} />
                            </div>
                        </div>}
                        <div style={ totalPriceRow }>
                            <div>{t('SmartView total price for {0} building(s)', buildingCount)}:</div>
                            <div>{smartViewTotalPrice} {countryConfig.currency} / {t('year')}</div>
                        </div>
                    </div>}
                </div>
            }
            <div style={{ marginTop: measures.double, marginBottom: measures.double}}>
                {printContent}
            </div>

            <div style={{ ...layout.floatRight }}>
                <div style={{ ...layout.floatRight }}>
                    <SaveButton
                        onClick={handleSubmit}
                        disabled={submitting || this.props.saving}
                        submitting={submitting || this.props.saving }
                        id="SmartViewForm_SaveButton"
                    />
                </div>

                <div style={{ ...layout.floatRight }}>
                    <CancelButton cancelAction={this.handleCancel}/>
                </div>
            </div>
        </div>
    }
}

export default translations(reduxForm(reduxFormObject, mapStateToProps, mapActionsToProps)(SmartViewForm));