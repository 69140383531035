import React, { PureComponent } from 'react';
import TabContent from './TabContent';
import TabContents from './TabContents';
import TabLabel from './TabLabel';
import TabLabels from './TabLabels';
import TabWrapper from './TabWrapper';

import './Tabs.css';

class Tabs extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: null
        };
    }

    handleTabClick(e, tab) {
        e.preventDefault();
        this.setState({
            selectedTab: tab
        });
    }

    render() {
        const {children, style, vertical, fixed, ...other} = this.props;
        const selectedTab = this.state.selectedTab;
        return (
            <TabWrapper style={style} vertical={vertical} {...other}>
                <TabLabels vertical={vertical} fixed={fixed}>
                    { children.map((child, idx) => {
                        if (child) {
                            const tab = child.props.title.toLowerCase();
                            const selected = (selectedTab === tab) || (!selectedTab && idx === 0);
                            return (
                                <TabLabel 
                                    key={'tab-label' + idx} 
                                    selected={selected}
                                    onClick={e=>this.handleTabClick(e, tab)}
                                >
                                    { child.props.title }
                                </TabLabel>
                            );
                        }
                        return null;
                    }) }
                </TabLabels>
                <TabContents>
                    { children.map((child, idx) => {
                        if (child) {
                            const tab = child.props.title.toLowerCase();
                            const selected = (selectedTab === tab) || (!selectedTab && idx === 0);
                            if (selected) {
                                return (
                                    <TabContent 
                                        key={'tab' + idx} 
                                        selected={selected}
                                    >
                                        {child}
                                    </TabContent>
                                );
                            }
                        }
                        return null;
                    }) }
                </TabContents>
            </TabWrapper>
        );
    }
}

export default Tabs;