import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import _                            from 'lodash';
import {
    layout,
    colors,
    measures
}                                   from 'styles/design.js';
import {
    SystemRepairsContainer,
    SystemMaterialContainer,
    SystemHoursOfServiceContainer,
    SystemInfoContainer,
    SystemSubcontractingContainer,
    CostNumber,
    ScrollToComponent
}                                   from 'components';

import SystemOperatingHours from './SystemOperatingHours';

class SystemContainer extends Component {

    static propTypes = {
        serviceLevel: PropTypes.string.isRequired,
        system: PropTypes.object.isRequired,
        calculatedSystem: PropTypes.object.isRequired,
        editable: PropTypes.bool.isRequired,
        resetField: PropTypes.func.isRequired,
        editAction: PropTypes.func.isRequired,
        handleCancel: PropTypes.func.isRequired,
        submitting: PropTypes.bool,
        newSystem: PropTypes.bool,
        discipline: PropTypes.object,
        deleteSystem: PropTypes.func.isRequired,
        canEdit: PropTypes.bool.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            showDetails: false
        };
    }

    componentDidMount() {
        if (this.props.newSystem) {
            this.setState({showDetails: true});
        } else if(this.props.expandSystem) {
            this.setState({showDetails: true});
        }
    }

    setSegmentEditable(name) {
        this.setState({editableSegment: name});
    }

    systemCost() {
        let sum = 0;
        const calculatedSystem = this.props.calculatedSystem;
        if (calculatedSystem) {
            if(calculatedSystem.hoursOfService) {
                calculatedSystem.hoursOfService.map((service) => {
                    sum += (service.cost || 0);
                });
            }
            if(calculatedSystem.materials) {
                calculatedSystem.materials.map((material) => {
                    sum += (material.cost || 0);
                });
            }
            if(calculatedSystem.repairs) {
                calculatedSystem.repairs.map((repair) => {
                    sum += (repair.cost || 0);
                });
            }
            if(calculatedSystem.subcontracting) {
               calculatedSystem.subcontracting.map((subcontracting) => {
                    sum += (subcontracting.cost || 0);
                });
            }
        }
        return sum;
    }

    systemSegmentEditAction = (event, segment) => {
        this.setSegmentEditable(segment);
        this.props.editAction();
        this.setState({showDetails: true});
    }

    saveAction = (system) => {
        this.props.handleSave(system);
    };

    cancelAction = () => {
        this.setSegmentEditable(undefined);
        this.props.handleCancel();
    };

    isValid(system) {
        let valid = false;
        if(system.systemID && system.equipmentType !== '' && system.systemID !== '' && system.hoursOfService && system.hoursOfService.length > 0) {
            valid = true;
        }
        return valid;
    }
    calculateCosts = (system) => {
        this.props.calculateCosts(system);
    };
    renderSystemTitle = (title) => {
        return (
            <div style={{ ...layout.textEllipsis, fontWeight: 'bold', lineHeight: '2em', minHeight:'2em' }}>
                { title }
            </div>
        )
    };

    renderSystemCost = (cost) => {
        return (
            <CostNumber style={{ float:'right', marginLeft: measures.standard }}>
                { cost }
            </CostNumber>
        )
    };
    render() {
        const {
            system,
            submitting,
            canEdit,
            serviceLevel,
            newSystem,
            resetField,
            systemIndex,
            t,
        } = this.props;
        const shouldShowDetails = this.state.showDetails || newSystem;
        const showEditButton = canEdit && !newSystem;

        const calculatedSystem = this.props.systemTotals || {};
        //const systemCost = this.systemCost();
        const systemTitle = system.systemID ? (system.systemID + (!_.isEmpty(system.equipmentType) ? ' : ' + system.equipmentType : '')) : '';
        const incomeDiff = Math.round(parseFloat(calculatedSystem.totalIncome) - parseFloat(calculatedSystem.oldIncome));
        const commonProps = {
            submitting: submitting,
            system: system,
            calculatedSystem: this.props.calculatedSystem,
            resetField: resetField,
            handleSave: this.saveAction,
            handleCancel: this.cancelAction,
            showEditButton: showEditButton,
            calculating: this.props.calculating,
            isEditable: canEdit,
            calculateCosts: this.calculateCosts
        };

        let bgColor = colors.lightGray;

        if(this.isValid(system)) {
            bgColor = colors.lightGreen;
        }

        return (
            <div style={{ marginBottom: measures.double }}>
                <div
                    id={'SystemContainer_open_' + this.props.index}
                    onClick={ () => { this.setState({ showDetails: !this.state.showDetails }); }}
                    style={{
                        backgroundColor: bgColor
                    }}
                >
                    <div style={{ marginBottom: measures.standard, padding: measures.double, cursor: 'pointer' }}>
                        { (shouldShowDetails && incomeDiff !== 0) &&
                            <div>
                                <div style={{ overflow: 'hidden' }}>
                                    { this.renderSystemCost(calculatedSystem.oldIncome) }
                                    { this.renderSystemTitle(t('original_income')) }
                                </div>
                                <div style={{ overflow: 'hidden' }}>
                                    { this.renderSystemCost(incomeDiff) }
                                    { this.renderSystemTitle(t('custom_income_difference')) }
                                </div>
                            </div>
                        }
                        { this.renderSystemCost(calculatedSystem.totalIncome) }
                        { this.renderSystemTitle(systemTitle) }
                    </div>
                </div>
                <div style={ shouldShowDetails ? {...layout.block, paddingLeft: '4em'} : {...layout.none} }>
                    { newSystem && <ScrollToComponent /> }
                    <div>
                        <SystemInfoContainer
                            index={this.props.index}
                            editMode={ this.state.editableSegment === 'details' || newSystem }
                            systemIndex={ systemIndex }
                            shouldShowDetails={ shouldShowDetails }
                            systemSegmentEditAction={ (event) => this.systemSegmentEditAction(event, 'details') }
                            deleteSystem = { this.props.deleteSystem }
                            { ...commonProps }
                        />
                    </div>
                    {
                        !newSystem &&
                        <div>
                            <SystemOperatingHours
                                editMode={ this.state.editableSegment === 'openingHours' }
                                systemSegmentEditAction={ (event) => this.systemSegmentEditAction(event, 'openingHours') }
                                { ...commonProps }
                            />
                        </div>
                    }

                    {
                        /*
                            <div style = {{ marginTop: measures.standard, marginBottom: measures.standard }}>
                            <CostNumber style={{ float:'right', lineHeight: '2em', marginLeft: measures.standard }}>{ this.systemCost() }</CostNumber>
                            <h2 style = { { lineHeight: '2em' }}>{ t('system_total') }</h2>
                            </div>
                            <div style = {{ marginTop: measures.standard, marginBottom: measures.standard }}>
                            <i className="fa fa-check-square-o"></i> { t('override_calculation') }
                            </div>
                            */
                    }
                    {
                        (()=> {
                            if(!newSystem) {
                                const materialServiceLevels = ['gold', 'silver'];
                                const showMaterials = (materialServiceLevels.indexOf(serviceLevel) !== -1);
                                const showRepairs = (serviceLevel === 'gold');
                                const showSubcontracting = true;

                                return (
                                    <div>
                                        <div>
                                            <SystemHoursOfServiceContainer
                                                serviceLevel={ this.props.serviceLevel }
                                                editMode={ this.state.editableSegment === 'hoursOfService'}
                                                systemSegmentEditAction={ (event) => this.systemSegmentEditAction(event, 'hoursOfService') }
                                                { ...commonProps }
                                            />
                                        </div>
                                        <div style={ showMaterials ? layout.block : layout.none }>
                                            <SystemMaterialContainer
                                                editMode={ this.state.editableSegment === 'materials'}
                                                systemSegmentEditAction={ (event) => this.systemSegmentEditAction(event, 'materials')}
                                                { ...commonProps }
                                            />
                                        </div>
                                        <div style={ showRepairs ? layout.block : layout.none}>
                                            <SystemRepairsContainer
                                                editMode={ this.state.editableSegment === 'repairs'}
                                                systemSegmentEditAction={ (event) => this.systemSegmentEditAction(event, 'repairs')}
                                                { ...commonProps }
                                            />
                                        </div>

                                        <div style = {showSubcontracting ? layout.block : layout.none}>
                                            <SystemSubcontractingContainer
                                                editMode={ this.state.editableSegment === 'subcontracting'}
                                                systemSegmentEditAction={(event) => this.systemSegmentEditAction(event, 'subcontracting')}
                                                { ...commonProps }
                                            />
                                        </div>
                                    </div>
                                );
                            }
                        })()
                    }
                </div>
            </div>
        );
    }
}

export default translations(SystemContainer);