import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import './AgreementDeleteContents.css';

const AgreementDeleteContents = ({
    contractInfo = {},
    agreement: {
        buildings = [],
        name,
        disciplines = [],
        attachments = [],
    } = {},
    kitpack = {},
    t
}) => {
    const kitpackBundles = _.flatten(_.map(kitpack.data, data => data.bundles));
    const clientName = contractInfo && contractInfo.client && contractInfo.client.name;
    return <div>
        <div className="agreement-delete-title">
             {t('You are about to delete following agreement and its contents')}:
        </div>
        <div className="agreement-delete-row">
            <div className="agreement-delete-label">
                {t('name')}:
            </div>
            <div className="agreement-delete-item">
                { (clientName ? `${clientName} - ` : '') + (name || t('main_agreement')) }
            </div>
        </div>
        { !_.isEmpty(buildings) && <div className="agreement-delete-row">
            <div className="agreement-delete-label">
                {t('Buildings')}:
            </div>
            <div className="agreement-delete-item">
                <ul>
                    {_.map(buildings, building =>
                        <li key={`building-${building.id}`}>
                            <div>{ building.name }</div>
                        </li>
                    )}
                </ul>
            </div>
        </div>}
        { !_.isEmpty(buildings) && <div className="agreement-delete-row">
            <div className="agreement-delete-label">
                {t('disciplines')}:
            </div>
            <div className="agreement-delete-item">
                <ul>
                    {_.map(disciplines, discipline =>
                        <li key={`discipline-${discipline.id}`}>
                            <div className="agreement-delete-item">
                                {discipline.disciplineName || t(discipline.disciplineType)}, {t('systems')}: {discipline.disciplineSystems && discipline.disciplineSystems.length}
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </div>}
        {!_.isEmpty(kitpackBundles) && <div className="agreement-delete-row">
            <div className="agreement-delete-label">
                {t('kitpack')}:
            </div>
            <div className="agreement-delete-item">
            <ul>
                {_.map(kitpackBundles, bundle =>
                    <li key={`bundle-${bundle.id}`}>{t('collector_bundle_' + bundle.type)}</li>
                )}
            </ul>
            </div>
        </div>}
        {!_.isEmpty(attachments) &&  <div className="agreement-delete-row">
            <div className="agreement-delete-label">
                {t('Attachments')}:
            </div>
            <div className="agreement-delete-item">
            <ul>
                {_.map(attachments, attachment =>
                    <li key={`attachment-${attachment.id}`}>{attachment.name}</li>
                )}
            </ul>
            </div>
        </div>}
    </div>;
};

AgreementDeleteContents.propTypes = {
    agreement: PropTypes.object.isRequired,
    kitpack: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    contractInfo: PropTypes.object.isRequired,
};

export default AgreementDeleteContents;
