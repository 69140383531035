import React, {Component}            from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium from 'configuredRadium.js';

import { CostNumber } from 'components';

import { commonStyles, measures } from 'styles/design.js';

const infoContainerStyle = [
    {
        //padding: '0em 0em 2em 1em',
        marginTop: '0.25em',
        marginBottom: measures.standard
    }
];

const infoContainerTitle = [
    commonStyles.pageSubTitle,
    {
        paddingTop: '0.75em',
        paddingBottom: '0.7em',
        fontWeight: 'bold'
    }
];
class SystemDetailsDataContainer extends Component {

    static propTypes = {
        title:      PropTypes.node.isRequired,
        expanded:   PropTypes.bool.isRequired,
        cost:       PropTypes.number
    }

    render() {
        return (
            <div style = { infoContainerStyle }>
                <h2 style = { infoContainerTitle }>{ this.props.title }</h2>
                <CostNumber style={{ float:'right' }}>{ this.props.cost }</CostNumber>
                <div style = { this.props.expanded ? { display: 'block'} : { display : 'none'}}>
                <table>
                    <tbody>
                        { this.props.children }
                    </tbody>
                </table>
                </div>
            </div>
    );}
}
export default translations(configuredRadium(SystemDetailsDataContainer));
