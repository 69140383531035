import React, {Component}       from 'react';
import translations from 'decorators/translations.js';
import { connect }              from 'react-redux';
import configuredRadium         from 'configuredRadium.js';

import {
    StandardPage,
    BreadcrumbBar,
    AdminSubNavigation,
    TranslationForm
} from 'components';

import {
    measures,
    commonStyles
} from 'styles/design.js';

import _ from 'lodash';

import { loadCountry } from 'redux/modules/country.js';
import { loadLanguages, loadWithType } from 'redux/modules/translations.js';
class FrameAgreementTranslationView extends Component {

    componentWillMount() {
        const { dispatch, match: {params}} = this.props;
        const promises = [];

        if(!this.props.countries.loaded || this.props.countries.selectedCountry.id !== params.countryId) {
            dispatch(loadCountry(params.countryId, this.props.profile.accessToken))
                .then(() => {
                    dispatch(loadWithType(2, 'en,' + this.props.selectedCountry.defaultLanguage, this.props.selectedCountry.id, this.props.profile.accessToken));
                });
        } else {
            dispatch(loadWithType(2, 'en,' + this.props.selectedCountry.defaultLanguage, this.props.selectedCountry.id, this.props.profile.accessToken));
        }
        if(this.props.translations.languages.length === 0) {
           promises.push(dispatch(loadLanguages(this.props.profile.accessToken)));
        }
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.translations.loading && nextProps.translations.loaded) {
            this.setState({translationsLoaded:true});
        }
        if(this.props.translations.saving && !nextProps.translations.saving) {
            this.setState({editing: null, savingTranslation: false, loadingTranslation: true});
            this.props.dispatch(loadWithType(2, 'en,' + this.props.selectedCountry.defaultLanguage, this.props.selectedCountry.id, this.props.profile.accessToken));
        }
    }

    /*
    static fetchData(getState, dispatch, location, params) {
        return Promise.all([
            dispatch(loadCountry(params.countryId, this.props.profile.accessToken)),
            dispatch(loadLanguages(this.props.profile.accessToken))
        ]);
    }*/
    render() {
        const localizables = _.filter(Object.keys(this.props.translations.translatables), (key) => {
            if (_.startsWith(key, 'frameagreement_terms')) {
                return false;
            }
            if(key.indexOf('frameagreement_') !== -1 && this.props.translations.translatables[key]['en-master']) {
                return key;
            }
        });
        return (
            <StandardPage withBreadcrumb withSideNavigation>
                <BreadcrumbBar
                    page="admin"
                    path={this.props.location.pathname}
                />
                <AdminSubNavigation params={this.props.match.params} activeNavi="frameAgreementConfig" />
                <header>
                    <h1 style = { commonStyles.pageTitle } >{ this.props.t(this.props.match.params.countryId) }</h1>
                </header>
                <div style = {[{ marginTop: measures.standard }]}>
                    <h2>{this.props.t('translations')}</h2>
                    <TranslationForm
                        localizables={localizables}
                        country={this.props.selectedCountry}
                    />
                </div>
            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        countries: state.countries,
        clientError: state.client.error,
        selectedCountry: state.countries.selectedCountry,
        languages: state.translations.languages,
        profile: state.userProfile,
        translations: state.translations
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(configuredRadium(FrameAgreementTranslationView)));
