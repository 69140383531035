import React, {Component}   from 'react';
import configuredRadium                 from 'configuredRadium.js';
import {reduxForm}                    from 'redux-form';
import {
    commonStyles,
    layout,
    colors,
    measures,
}                           from 'styles/design.js';
import _ from 'lodash';

import { saveDepartment } from 'redux/modules/department.js';

import {
    SFInputField,
    SaveButton,
    CancelButton
}                           from 'components';

const div70width = {
    width: '60%',
    display: 'inline-block',
    margin: measures.half
};
const div30width = {
    width: '35%',
    display: 'inline-block'
};

const reduxFormObject = {
    form: 'newDepartmentForm',
    fields: [
        'name',
        'discount',
        'address',
        'city',
        'zip',
        'localPhoneNumber'
    ]
};
class DepartmentConfigInformation extends Component {
    componentWillReceiveProps(newprops) {
        if (this.state.savingDepartment && newprops.department.reload) {
            this.setState({savingDepartment: false, loadingDepartment: true});
            this.props.loadDepartments().then(() => {
                this.setState({addNewDepartment: false});
            })
        }
    }
    toggleNewDepartment = (val) => {
        this.setState({addNewDepartment: val});
    };
    submitNewDepartment = () => {
        const department = {
            name: this.props.fields.name.value,
            defaultDiscount: this.props.fields.discount.value,
            countryConfigId: this.props.countryConfig.id
        };
        const foundDep = _.find(this.props.departments, (dep) => {
            if(dep.name === department.name) {
                return dep;
            }
        });
        if(foundDep) {
            this.setState({departmentExists: true});
        } else {
            const prom = this.props.dispatch(saveDepartment(department, this.props.profile.accessToken));
            this.setState({savingDepartment: true});
            return prom;
        }
    };
    render() {
        const {t, fields, departments} = this.props;
        return (
            <div style={commonStyles.dataParentDiv}>
                <div>
                    {
                        (() => {
                            if(this.state.addNewDepartment) {
                                return (
                                <div style = {{overflow: 'auto'}}>
                                    <div>
                                        <div style = {[div70width]}>
                                            <SFInputField formProperty = {fields.name} fieldName = {t('name')} />
                                        </div>
                                        <div style = {[div30width]}>
                                            <SFInputField formProperty = {fields.discount} fieldName = {t('discount')} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <SFInputField formProperty = {fields.address} fieldName = {t('address')} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <SFInputField formProperty = {fields.city} fieldName = {t('city')} />
                                        </div>
                                        <div style = {[div30width]}>
                                            <SFInputField formProperty = {fields.zip} fieldName = {t('zip')} />
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <SFInputField formProperty = {fields.localPhoneNumber} fieldName = {t('local_phone_number')} />
                                        </div>
                                    </div>
                                    {
                                        (() => {
                                            if(this.state.departmentExists) {
                                                return (<div style = {{color: colors.basicRed}}>{t('department_with_same_name_exists')}</div>);
                                            }
                                        })()
                                    }
                                    <div>
                                        <div style={ [layout.floatRight, layout.inlineBlock, { clear: 'both'}] }>
                                            <SaveButton onClick={ () => this.submitNewDepartment()} disabled={ this.state.savingDepartment } submitting={ this.state.savingDepartment }/>
                                            <div style = {{ display: 'inline-block', float: 'right' }}><CancelButton cancelAction = { () => this.toggleNewDepartment(false) } /></div>
                                        </div>
                                    </div>
                                </div>);
                            }
                            return (<div style = {{overflow: 'auto'}}>
                                <div>{t('select_department')}</div>
                                <div style={{display: 'inline-block', width: '90%'}}>
                                    <select style = {[commonStyles.select, {width: '95%'}]} onChange={(event) => this.setState({selectedDepartment: event.target.value})}>
                                        <option>--select--</option>
                                        {
                                            (()=> {
                                                if(departments) {
                                                    const sortDepartments = _.sortBy(departments, 'name');
                                                    return sortDepartments.map((dep, idx) => {
                                                        return (
                                                            <option key = {'depidx_' + idx} value={dep.id}>{dep.name}</option>
                                                        );
                                                    });
                                                }
                                            })()
                                        }
                                    </select>
                                </div>
                                <div style = {{display: 'inline-block', width: '10%'}}>
                                    <button style = {[commonStyles.button, {backgroundColor: colors.lightBlue}]} onClick = { (event) => this.props.selectDepartment(event,this.state.selectedDepartment) }>{t('ok')}</button>
                                </div>
                                <div style = {[layout.floatRight, layout.inlineBlock, {marginTop: measures.standard}]}>
                                    <button style = {[commonStyles.button, {backgroundColor: colors.lightBlue}]} onClick = { () => this.toggleNewDepartment(true) }>Add new department</button>
                                </div>
                                </div>);
                        })()
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        department: state.department
    };
}

export default reduxForm(reduxFormObject, mapStateToProps)(configuredRadium(DepartmentConfigInformation));
