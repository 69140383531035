import React, { Component }  from 'react';
import PropTypes from 'prop-types';
import translations from 'decorators/translations.js';
import { Link }                         from 'react-router-dom';
import configuredRadium                 from 'configuredRadium.js';

import { localeDateString }     from 'helpers/stringTemplates';

import {
    LoadingIndicator
}                               from 'components/index.js';

import {
    measures,
    colors
} from 'styles/design.js';

const listItemStyle = {
    color: colors.blue,
    padding: measures.standard,
    marginRight: measures.standard
};
const smallText = {
    marginTop: measures.half,
    fontSize: measures.fatHalf
};

class ModifiedAgreementsList extends Component {

    static propTypes = {
        agreements: PropTypes.array
    };

    getAgreementLink = (agreement) => {
        return '/client/' + agreement.client.id;
    };

    formatDateWithTimezone(date) {
        return localeDateString(date, { showTimeZone:true });
    }

    render() {
        const { t } = this.props;
        if(this.props.loading) {
            return (<LoadingIndicator visible={true} styles={{fontSize: '25px'}}/>);
        }
        return (
            <ul>
                { this.props.agreements.map((agreement, index) => {
                    return (
                        <li style={[
                            listItemStyle,
                            index % 2 === 0 ? {backgroundColor: colors.lightGray} : {}
                        ]}
                            key={'modified_list_' + index}
                        >
                            <Link to={ this.getAgreementLink(agreement) } style={{ color: colors.blue }}>
                                <div>{t('client')}: { agreement.client.name }</div>
                                <div style={{paddingTop: measures.half, paddingBottom: measures.half}}>{t(agreement.agreementType === 'main' ? 'main_agreement' : agreement.agreemnetType)} ({agreement.id}) - {t(agreement.agreementStatus)}</div>
                                <div style={ smallText }>{ this.formatDateWithTimezone(agreement.modified) }</div>
                            </Link>
                        </li>
                    );
                }) }
            </ul>
        );}
}

export default translations(configuredRadium(ModifiedAgreementsList));
