import React from 'react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import _ from 'lodash';

const containerStyles = {
    display: 'flex',
    flexFlow: 'row nowrap',
    marginRight: '-1em',
    alignItems: 'center',
    marginBottom: '1em',
};

const itemStyles = {
    flex: 1,
    marginRight: '1em',
};

const CopyAgreementFilters = ({
    handleFilterChange,
    resetFilter,
    countries,
    selectedSalesArea,
    selectedDepartment,
    t,
    selectedPerson,
    myClients,
    userProfile,
    departmentOptions,
}) => {
    if (!countries || countries.length === 0) {
        return null;
    }
    const salesAreaOptions = countries && countries.length && countries.map(area => ({
        value: area.id,
        label: t(area.id)
    }));
    const filteredSalesAreaOptions = salesAreaOptions.filter(option => option.value !== 'fingland');
    const handleCheckboxChange = event => {
        const value = event.target.checked;
        handleFilterChange('myClients', value);
        if (value === true) {
            handleFilterChange('selectedPerson', {
                value: userProfile.email,
                label: userProfile.email,
            });
        }
        else {
            handleFilterChange('selectedPerson', null);
        }
    };
    const handleSalesAreaChange = value => {
        if (value !== selectedSalesArea) {
            resetFilter('selectedDepartment');
        }
        handleFilterChange('selectedSalesArea', value);
    };

    return <div>
        <div style={ containerStyles }>
            <div style={ itemStyles }>
                <Select
                    onChange={ handleSalesAreaChange }
                    options={ filteredSalesAreaOptions }
                    placeholder={ `${t('Select salesarea')}...` }
                    value={ selectedSalesArea }
                    clearable={ false }
                />
            </div>
            <div style={ itemStyles }>
                <Select
                    onChange={ value => handleFilterChange('selectedDepartment', value) }
                    options={ departmentOptions }
                    placeholder={ `${t('Select department')}...`}
                    value={ selectedDepartment }
                    disabled={ _.isNil(selectedSalesArea) }
                />
            </div>
        </div>
        <div style={ containerStyles }>
            <div style={ itemStyles }>
                <input type="checkbox" onChange={ handleCheckboxChange } value={ myClients } /> { t('My clients') }
            </div>
        </div>
    </div>
};

export default CopyAgreementFilters;
