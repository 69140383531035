import {createReducerFromMapping} from 'redux/utils/index.js';

const UPDATE = 'ServiFlex/AgreementContent/UPDATE',
    UPDATE_FAIL = 'ServiFlex/AgreementContent/UPDATE_FAIL',
    UPDATE_SUCCESS = 'ServiFlex/AgreementContent/UPDATE_SUCCESS',
    LOAD = 'ServiFlex/AgreementContent/LOAD',
    LOAD_FAIL = 'ServiFlex/AgreementContent/LOAD_FAIL',
    LOAD_SUCCESS = 'ServiFlex/AgreementContent/LOAD_SUCCESS',
    DELETE = 'ServiFlex/AgreementContent/LOAD',
    DELETE_FAIL = 'ServiFlex/AgreementContent/LOAD_FAIL',
    DELETE_SUCCESS = 'ServiFlex/AgreementContent/LOAD_SUCCESS';;

const initialState = {
    updating: false,
    updated: false,
    reload: true,
    result: null,
    list: [],
};

export default createReducerFromMapping({
    [UPDATE]: (state, action) => ({
        ...state,
        updating: true
    }),
    [UPDATE_SUCCESS]: (state, action) => ({
        ...state,
        updating: false,
        updated: true,
        result: action.result
    }),
    [UPDATE_FAIL]: (state, action) => ({
        ...state,
        updating: false,
        updated: false,
        error: action.error
    }),
    [LOAD]: (state) => ({
        ...state,
        loading: true
    }),
    [LOAD_SUCCESS]: (state, action) => ({
        ...state,
        loading: false,
        loaded: true,
        list: action.result
    }),
    [LOAD_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
    }),
    [DELETE]: (state) => ({
        ...state,
        loading: true
    }),
    [DELETE_SUCCESS]: (state, action) => ({
        ...state,
        loading: false,
        loaded: true,
        list: action.result
    }),
    [DELETE_FAIL]: (state, action) => ({
        ...state,
        loading: false,
        loaded: false,
        error: action.error
    })
}, initialState);

export function loadAgreementContents({agreementId, frameAgreementId}, accessToken) {
    const filter = {
        where: {}
    };
    if(agreementId) {
        filter.where['agreementId'] = agreementId;
    } else if(frameAgreementId) {
        filter.where['frameAgreementId'] = frameAgreementId;
    }
    return {
        types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
        promise: client => client.get('/AgreementContents?filter=' + JSON.stringify(filter), ({accessToken}))
    }
}

export function upsertAgreementContents(data, accessToken) {
    if((!data.agreementId && !data.frameAgreementId) || !data.language || !data.section) {
        return {
            types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
            promise: () => new Promise(resolve => resolve())
        };
    }

    return {
        types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
        promise: client => client.patch('/AgreementContents', {data, accessToken})
    };
}

export function deleteAgreementContents(id, accessToken) {
    if(!id) {
        return {
            types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
            promise: () => new Promise(resolve => resolve())
        };
    }

    return {
        types: [DELETE, DELETE_SUCCESS, DELETE_FAIL],
        promise: client => client.del('/AgreementContents/' + id, {accessToken})
    };
}