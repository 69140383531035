import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {RichTextView, Button} from 'components/index';

import {
    layout,
    commonStyles,
    measures
} from 'styles/design.js';

const labelDiv = {
    marginBottom: measures.double
};

const labelContentDiv = {
    marginLeft: measures.double,
    marginTop: measures.standard,
    fontSize: '0.9em'
};

class DescriptionInformation extends Component {
    render() {
        const {t, countryConfig} = this.props;
        return (
            <div>
                <div style={commonStyles.dataParentDiv}>
                    <div style={commonStyles.dataContentHeaderDiv}>
                        {t('pricing')}:
                    </div>

                    <div style={{...layout.floatRight, marginTop: measures.half}}>
                        <Button
                            id="CountrySLADescriptionsInformation_edit"
                            onClick={this.props.editClicked}
                        >
                            {t('edit')}
                        </Button>
                    </div>
                    {/*
                    <div style={{...labelDiv, marginTop: measures.standard}}>
                        <label>{t('sla_basic_description')}:</label>
                        <RichTextView
                            labelStyle={labelContentDiv}
                            value={this.props.countryConfig.slaBasicDescription}/>
                    </div>

                    <div style={labelDiv}>
                        <label>{t('sla_responsetime_description')}:</label>
                        <RichTextView
                            labelStyle={labelContentDiv}
                            value={this.props.countryConfig.slaResponseTimeDescription}/>
                    </div>

                    <div style={labelDiv}>
                        <label>{t('sla_clientnet_description')}:</label>
                        <RichTextView
                            labelStyle={labelContentDiv}
                            value={this.props.countryConfig.slaClientNetDescription}/>
                    </div>

                    <div style={labelDiv}>
                        <label>{t('sla_yearlymeeting_description')}:</label>
                        <RichTextView
                            labelStyle={labelContentDiv}
                            value={this.props.countryConfig.slaYearlyMeetingDescription}/>
                    </div>
                    */}
                    <div style={{...labelDiv, marginTop: '1em'}}>
                        <label>{t('sla_support_247_price')}:</label>
                        <div style={labelContentDiv}>
                            {countryConfig.slaSupport247price ? countryConfig.slaSupport247price + ' ' + countryConfig.currency : '-'}
                        </div>
                    </div>
                    {/*
                    <div style={labelDiv}>
                        <label>{t('sla_support_247_description')}:</label>
                        <RichTextView
                            labelStyle={labelContentDiv}
                            value={this.props.countryConfig.slaSupport247description}/>
                    </div>
                    */}
                </div>
            </div>
        );
    }
}

export default translations(DescriptionInformation);
