import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';
import {loadCountries} from 'redux/modules/country.js';
import {save as saveClient} from 'redux/modules/client.js';
import {loadAllDepartments} from 'redux/modules/department.js';
import {loadBillingIndexes} from 'redux/modules/billingIndex.js';
import _ from 'lodash';

import {
    StandardPage,
    ClientForm,
    LoadingIndicator
} from 'components';

import {
    commonStyles
} from 'styles/design.js';


class CreateClientView extends Component {
    constructor(props) {
        super(props);
        this.state = {dataLoaded: false};
    }
    componentWillMount() {
        const promises = [];
        const dispatch = this.props.dispatch;
        promises.push(dispatch(loadCountries(this.props.profile.accessToken)));
        promises.push(dispatch(loadAllDepartments(this.props.profile.accessToken)));
        promises.push(dispatch(loadBillingIndexes(this.props.profile.accessToken)));
        return Promise.all(promises).then(() => {
            this.setState({dataLoaded: true});
        });
    }

    handleSubmit = formData => {
        const data = _.cloneDeep(formData);
        if (data.name) {
            const dispatchSave = this.props.dispatch(saveClient(data, this.props.profile.accessToken));
            dispatchSave.then((result) => {
                this.setState({submitting: false});
                if (result && !result.error) {
                    this.props.history.push('/client/' + result.result.id + '/createContractInfo/');
                }
            });
            this.setState({submitting: true});
            return dispatchSave;
        }
    };

    handleCancel = (event) => {
        this.props.history.push('/welcome');
        event.preventDefault();
    };

    render() {
        const t = this.props.t;
        return (
            <StandardPage>
                <header>
                    <h1 style={commonStyles.pageTitle}>{t('client_info')}</h1>
                </header>
                {
                    (() => {
                        if (!this.state.dataLoaded) {
                            return <LoadingIndicator visible={true}/>
                        }
                        return (
                            <ClientForm
                                cancelAction={this.handleCancel}
                                onSubmit={this.handleSubmit}
                                submitting={this.state ? this.state.submitting : false}
                                departments={this.props.department.departments}
                                countries={this.props.countries}
                                billingIndexes={this.props.billingIndexes}
                                departmentsError={this.props.department.error}
                            />
                        );
                    })()
                }
            </StandardPage>
        );
    }
}



function mapStateToProps(state) {
    return {
        profile: state.userProfile,
        countries: state.countries.countries,
        department: state.department,
        billingIndexes: state.billingIndex.all
    };
}

const connector = connect(mapStateToProps);

export default translations(connector(CreateClientView));