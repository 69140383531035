import React, {Component}               from 'react';
import translations from 'decorators/translations.js';
import { connect }                      from 'react-redux';

import {
    layout,
    size
}                                       from 'styles/design.js';

import { isEditable }                   from 'helpers/agreement.js';
import _ from 'lodash';

import {withRouter} from 'react-router-dom';

import {
    BuildingAndAgreementRow,
    RenderDiscipline,
    CreateDisciplineButton,
    Button,
    SFSelectField
} from 'components';

class DisciplinesByAgreement extends Component {
    state={};
    selectBuilding = (value, id) => {
        let buildings = this.state.selectedBuildings;
        if(!buildings) {
            buildings = {};
        }
        buildings[id] = value;
        this.setState({selectedBuildings: buildings});
    };

    renderCreateDisciplineButton = (buildings, value, agreement, t, contractInfo) => {
        return (
            <div style={layout.floatRight}>
                <div>
                    {this.props.t('select_building')}
                </div>
                <div style={{ ...layout.inlineBlock, fontSize: '0.8em'}}>
                    <SFSelectField
                        formProperty={ {
                            value,
                            onChange: (event) => this.selectBuilding(event.target.value, agreement.id)} }
                        selectWidth={ 20 }
                        style={{marginRight: '0.5em'}}
                    >
                        {
                            buildings.length > 1 && <option value="">{this.props.t('select')}</option>
                        }
                        {
                            buildings.map((b, idx) => <option key={'building_idx_' + idx} value={b.id}>{b.name}</option>)
                        }
                    </SFSelectField>
                </div>
                <div style={{ ...layout.inlineBlock, marginLeft: '1em', marginTop: '1.25em', verticalAlign: 'top' }}>
                    <Button
                        disabled={ value === null }
                        variant={value === null ? 'disabled' : null}
                        onClick={() => this.props.history.push(`/client/${contractInfo.clientId}/contractInfo/${contractInfo.id}/building/${value}/disciplines/add`)}
                    >
                        {t('add_discipline')}
                    </Button>
                </div>
                <div style={{clear: 'both'}} />
            </div>
        );
    };

    render() {
        const {
            t,
            agreement,
            contractInfo
        } = this.props;
        return (
            <div>
                <div>
                    {
                        agreement.disciplines && agreement.disciplines.length > 0 && agreement.disciplines.map((discipline, idx) =>
                            <div key={'discipline_idx_' + idx}>
                                <RenderDiscipline
                                    discipline={discipline}
                                    type="agreement"
                                    contractInfoId={contractInfo.id}
                                    clientId={contractInfo.clientId}
                                />
                            </div>
                        )
                    }
                    {
                        (() => {
                            const filteredAgreementBuildings = _.filter(this.props.buildings, {agreementId: agreement.id});
                            let selectedValue;
                            if(filteredAgreementBuildings.length === 1) {
                                selectedValue = _.first(filteredAgreementBuildings).id;
                            } else {
                                selectedValue = this.state.selectedBuildings && this.state.selectedBuildings[agreement.id] ? this.state.selectedBuildings[agreement.id] : null;
                            }
                            if(isEditable(agreement)) {
                                if(filteredAgreementBuildings.length > 0) {
                                    return this.renderCreateDisciplineButton(filteredAgreementBuildings, selectedValue, agreement, t, contractInfo);
                                }
                            }
                        })()
                    }
                </div>
                <div>
                {
                    (() => {
                        if(agreement.addons && agreement.addons.length > 0) {
                            return agreement.addons.map((addon, idx) => {
                                const link = `/client/${contractInfo.clientId}/contractInfo/${contractInfo.id}/agreement/${addon.id}/summary`;

                                return (
                                    <div key={'addon_idx_' + idx}>
                                        <BuildingAndAgreementRow
                                            link={link}
                                            hoverTitle={t('addon') + ' ' + addon.id}
                                            title={ t('addon') + ' ' + (idx + 1)}
                                            rowType={'addon'}
                                            created={addon.created}
                                            modifiedTime={addon.modifiedTime}
                                            signedTime={addon.signedTime}
                                            approvalTime={addon.approvalTime}
                                            agreementStatus={addon.agreementStatus}
                                            agreementId={addon.id}
                                            createdBy={addon.createdBy}
                                            downloadFile={this.props.downloadFile}
                                        />
                                        {
                                            (() => {
                                                return addon.disciplines.map((discipline, dIdx) => {
                                                    return <RenderDiscipline
                                                        key={'discipline_idx_' + dIdx}
                                                        discipline={discipline}
                                                        type="agreement"
                                                        contractInfoId={ contractInfo.id }
                                                        clientId={ contractInfo.clientId }
                                                    />;
                                                });
                                            })()
                                        }
                                        {
                                            (() => {
                                                const filteredBuildings = _.filter(this.props.buildings, (building) => {
                                                    return building.agreementId === addon.id || building.agreementId === addon.mainAgreementId;
                                                });
                                                let selectedValue;
                                                if(filteredBuildings.length === 1) {
                                                    selectedValue = _.first(filteredBuildings).id;
                                                } else {
                                                    selectedValue = this.state.selectedBuildings && this.state.selectedBuildings[addon.id] ? this.state.selectedBuildings[addon.id] : null;
                                                }
                                                if(isEditable(addon)) {
                                                    if(filteredBuildings.length > 0) {
                                                        return this.renderCreateDisciplineButton(filteredBuildings, selectedValue, addon, t, contractInfo);
                                                    }
                                                } else if((idx + 1) === agreement.addons.length) {
                                                    if(filteredBuildings.length > 0) {
                                                        return this.renderCreateDisciplineButton(filteredBuildings, selectedValue, addon, t, contractInfo);
                                                    }
                                                }
                                            })()
                                        }
                                        <div style={{clear: 'both'}} />
                                    </div>
                                );
                            });
                        }
                    })()
                }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        client: state.client.client,
        buildings: state.building.buildings
    }
}
const connector = connect(mapStateToProps);
export default translations(connector(withRouter(DisciplinesByAgreement)));
