import React, {Component}           from 'react';
import translations from 'decorators/translations.js';
import configuredRadium             from 'configuredRadium.js';
import {
    ListItem
}                                   from 'components';
class ListManager extends Component {
    render() {
        return (
            <ul>
                {
                    (() => {
                        if (this.props.listData) {
                            return this.props.listData.map((listItem, idx) => {
                                return (
                                    <ListItem key={ 'list_item_' + idx } listItem={ listItem } />
                                );
                            });
                        }
                    })()
                }
            </ul>
        );
    }
}

export default translations(configuredRadium(ListManager));
