
import React, { Component }  from 'react';
import translations from 'decorators/translations.js';
import PropTypes from 'prop-types';
import configuredRadium                 from 'configuredRadium.js';
import {
    VisitPlanCalendar,
}                                       from 'components';

class SFMonthSelector extends Component {

    static propTypes = {
        formProperty: PropTypes.object,
    }
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.formProperty) {
            this.setState({
                value: nextProps.formProperty.value
            });
        } else {
            this.setState({
                value: nextProps.value
            })
        }
    }

    onMonthClick = (valueIndex) => {
        this.toggleVisitPlanValue(valueIndex);
    };

    toggleVisitPlanValue(monthIndex) {
        if(this.props.editState) {
            const value = this.state.value.map((v, i) => {
                if (i === monthIndex) return !v;
                return v;
            });

            this.updateValue(value);
        }
    }

    updateValue(value) {
        this.setState({ value: value });
        if(this.props.formProperty) {
            this.props.formProperty.onChange(value);
        } else {
            this.props.onChange(null, value);
        }
    }

    render() {
        const showSelection = false;
        return (
            <div>
                <VisitPlanCalendar id={this.props.id} visitPlan={ this.state.value } onMonthClickAction={ this.onMonthClick } showSelection={ showSelection } />
            </div>
        );
    }
}

export default translations(configuredRadium(SFMonthSelector));