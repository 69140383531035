import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware }             from 'react-router-redux'
import { default as rootReducer }       from './modules/reducer.js';

import clientMiddleware from './middleware/clientMiddleware.js';

export default (client, history, initialState) => {
    const createStoreWithMiddleware = applyMiddleware(
        clientMiddleware(client),
        routerMiddleware(history)
    )(createStore);
        if(process.env.NODE_ENV === 'development') {
            return createStoreWithMiddleware(rootReducer, initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
        } else {
            return createStoreWithMiddleware(rootReducer, initialState);
        }

}
