import React, {Component} from 'react';
import translations from 'decorators/translations.js';
import {connect} from 'react-redux';

import {
    StandardPage,
    BreadcrumbBar,
    CountryConfigForm,
    CountryInformation,
    AdminSubNavigation,
    //BillingIndexForm
} from 'components';

import {
    measures,
    commonStyles
} from 'styles/design.js';

import {loadCountry, saveCountry} from 'redux/modules/country.js';
import {loadLanguages} from 'redux/modules/translations.js';

class CostCalculationConfigurationView extends Component {
    state = {};

    componentWillMount() {
        const promises = [];
        const params = this.props.match.params;
        const selectedCountry = this.props.countries.selectedCountry;
        if (!this.props.countries.loaded || !selectedCountry || this.props.countries.selectedCountry.id !== params.countryId) {
            promises.push(this.props.dispatch(loadCountry(params.countryId, this.props.profile.accessToken)));
        }
        if (!this.props.translations.loaded || this.props.translations.languages.length === 0) {
            promises.push(this.props.dispatch(loadLanguages(this.props.profile.accessToken)));
        }
        return Promise.all(promises);
    }

    componentWillReceiveProps(/*nextProps*/) {
        if (this.state.isSaving) {
            const countryId = this.props.match.params.countryId;
            this.props.dispatch(loadCountry(countryId, this.props.profile.accessToken));
            this.setState({isSaving: false, isLoading: true});
        } else if (this.state.isLoading) {
            this.setState({isLoading: false, editCountry: false});
        }
    }

    handleConfigSave = (countryConfiguration) => {
        countryConfiguration.vat = String(countryConfiguration.vat).replaceAll(',', '.');
        const dispatchSave = this.props.dispatch(saveCountry(countryConfiguration, this.props.profile.accessToken));
        this.setState({isSaving: true});
        return dispatchSave;
    }

    editCountry(value) {
        this.setState({editCountry: value});
    }

    handleCancel() {
        this.editCountry(false);
    }

    render() {
        return (
            <StandardPage
                withBreadcrumb
                withSideNavigation
                loaderVisible={this.props.countries.loading}
            >
                <BreadcrumbBar
                    page="admin"
                    path={this.props.location.pathname}
                />
                <AdminSubNavigation params={this.props.match.params}/>
                <header>
                    <h1 style={commonStyles.pageTitle}>{this.props.t(this.props.match.params.countryId)}</h1>
                </header>
                <div style={{marginTop: measures.standard}}>
                    {
                        (() => {
                            if (this.state.editCountry) {
                                return (
                                    <CountryConfigForm
                                        onSubmit={this.handleConfigSave}
                                        cancelAction={() => {
                                            this.editCountry(false);
                                        }}
                                        countryConfig={this.props.countries.selectedCountry}
                                        languages={this.props.translations.languages}
                                    />);
                            }
                            return (
                                <CountryInformation
                                    countryConfig={this.props.countries.selectedCountry}
                                    languages={this.props.translations.languages}
                                    editClicked={() => {
                                        this.editCountry(true);
                                    }}
                                />);
                        })()
                    }
                    {
                        /*this.props.countries.selectedCountry.id === this.props.match.params.countryId && !this.state.editCountry &&
                        <BillingIndexForm selectedCountry={this.props.countries.selectedCountry}/>*/
                    }

                </div>
            </StandardPage>
        );
    }
}


function mapStateToProps(state) {
    return {
        countries: state.countries,
        translations: state.translations,
        profile: state.userProfile
    };
}

const connector = connect(mapStateToProps);
export default translations(connector(CostCalculationConfigurationView));