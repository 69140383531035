import React, { Component }                 from 'react';
import PropTypes                            from 'prop-types';
import { connect }                          from 'react-redux';
import {
    DropdownToggle,
    PrintDropdownMenu
}                                           from 'components';

class PrintDropdown extends Component {

    static propTypes = {
        style: PropTypes.object,
        agreementId: PropTypes.number,
        lang: PropTypes.string,
        action: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.state = {
            isVisible: false
        };
    }

    menuAction = (event) => {
        event.preventDefault();
        this.setState({
            isVisible: !this.state.isVisible
        });
    }

    render() {
        return (
            <div className="dropdown" style={ this.props.style }>
                <DropdownToggle
                    id={this.props.id}
                    variant="secondary"
                    onClick={ (event) => this.menuAction(event) }>
                    { this.props.children ? this.props.children : this.props.t('add_new') + '...'}
                </DropdownToggle>
                <PrintDropdownMenu
                    agreementId={ this.props.agreementId }
                    action={ this.props.action }
                    isVisible={ this.state.isVisible }
                    lang={ this.props.lang }
                />
            </div>
        );}
}

function mapStateToProps(state) {
    return {
        agreement: state.agreement,
        profile: state.userProfile
    };
}

const connector = connect(mapStateToProps);
export default connector(PrintDropdown);
